import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { redirect } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContactPage = () => {
  return (
    <div>

<div className="layer"></div>
    <Header/>		
	<main>	
		<div id="hero_contact">
			<div className="container margin_60_35">
				<div className="row">
					<div className=" col-lg-8 col-md-8 mx-auto">
						<div className="box_general">
							<h3>Contacto</h3>
							<p>
								Completá el formulario y te responderemos a la brevedad.
							</p>
							<div>
								<div id="message-contact"></div>
								<form method="post" action="assets/contact.php" id="contactform">
									<div className="row">
										<div className="col-md-6 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" id="name_contact" name="name_contact" placeholder="Nombre" />
											</div>
										</div>
										<div className="col-md-6 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" id="lastname_contact" name="lastname_contact" placeholder="Apellido" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-6">
											<div className="form-group">
												<input type="email" id="email_contact" name="email_contact" className="form-control" placeholder="Email" />
											</div>
										</div>
										<div className="col-md-6 col-sm-6">
											<div className="form-group">
												<input type="text" id="phone_contact" name="phone_contact" className="form-control" placeholder="Teléfono" />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<textarea rows="5" id="message_contact" name="message_contact" className="form-control" style={{height: '100px'}} placeholder="Comentarios..."></textarea>
											</div>
										</div>
									</div>									
									<input type="submit" value="Enviar" className="btn_1 add_top_20" id="submit-contact" />
								</form>
							</div>
							
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
		
	</main>
	
	<Footer/>
	
	
	<script src="assets/validate.js"></script>
	<script src="http://maps.googleapis.com/maps/api/js"></script>
	<script src="js/mapmarker.jquery.js"></script>
	<script src="js/mapmarker_contacts_func.js"></script>
     

    </div>
  )
}

export default ContactPage