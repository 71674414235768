import React, {useState, createContext } from 'react'

export const UserContext = createContext();

export const UserProvider = ({children}) => {
    
    const [userName, setUserName] = useState('');
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    const getHoursDiff = (a,b) => {
        return Math.abs( (a-b) / 1000 /60 /60 );
    }    

    const isTokenValid = () => {
        const currentToken = sessionStorage.getItem('token');
        const tokenTimestamp =  sessionStorage.getItem('tokenTimestamp');

        const ret = (currentToken != 'undefined' && getHoursDiff(tokenTimestamp, Date.now) < 2);
        return  ret;
    }

    const getUserToken = async (user, pass) => {

        try {
            const response = await fetch(process.env.REACT_APP_API + `/getuserapitoken/${user}/${pass}`, {
                headers: {
                  "Content-type": "application/json;charset=UTF-8",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Headers": "X-Requested-With"
                },
                method: 'POST',
                body: ''});
            
            const data = await response.json();                  
            console.log('Token obtenido: ' + data.tokenAcceso);
            sessionStorage.setItem('currentToken',data.tokenAcceso);
            return data.tokenAcceso;
            
        } catch (error) {
            alert('Error: ' + error.message);
            console.log('Error getUserToken ', error.message);
        }

    }

    return(
        <UserContext.Provider value={{userName,setUserName,userLoggedIn,setUserLoggedIn,getUserToken}}>
            {children}
        </UserContext.Provider>
    );

}
