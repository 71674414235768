import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header'
import { useParams } from 'react-router-dom';
import BusRowXXOX from '../components/BusRowXXOX';
import BusRowXXOO from '../components/BusRowXXOO';
import SeatReferences from '../components/SeatReferences';
import CountryOptions from '../components/CountryOptions';
import BusRowEmpty from '../components/BusRowEmpty';
import BusRowDriver from '../components/BusRowDriver';
import PassengerModal from '../components/PassengerModal';
import { SearchContext } from '../context/SearchContext';

const Taquilla = ({tramoSeleccionado, direccion}) => {        

    const 
        {
        step,setStep,
        servicios, setServicios,
        origen,setOrigen,
        destino,setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        ida, setIda,
        vuelta, setVuelta,
        pax, setPax,
        setLoading,
        fancyDate,        
        listaPasajeros, setListaPasajeros,        
        } = useContext(SearchContext);

    const [taquilla, setTaquilla] = useState();
    const [butacas, setButacas] = useState([]); //array ( bucata, bool ocupaciópn)
    const [ultButacaSeleccionada, setUltButacaSeleccionada] = useState();    


    console.log("tramoSeleccionado: ", tramoSeleccionado);
 
    const getTaquilla = async () => {
        setLoading(true);
        try {
            let currentToken= sessionStorage.getItem('currentToken');
            console.log('GetServicios usa token:' + currentToken);
            const response = await fetch(process.env.REACT_APP_API + `/buses/servicios/${tramoSeleccionado.codigoServicio}/taquilla`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",        
                    "token": currentToken
                },
                method: 'GET' 
            });
            const data = await response.json();
            console.log(data);
            setTaquilla(data);
            parseButacas(data.mapaButacas.secciones);
            setLoading(false);
    
        } catch (error) {
            const msg= "Error " + error.message;
            alert(msg);
            console.log(msg);        
        }
    }

    const parseButacas = (secciones) =>{
        let ret= [];
        let sec1= secciones[0].items;
        let sec2= secciones[1].items;

        sec1.forEach(item => {
            ret[item.numero] = item.ocupado;
        });
        sec2.forEach(item => {
            ret[item.numero] = item.ocupado;
        });

        setButacas(ret);
        console.log(ret);                
    }

    const getAvailableClass = (index) => {
        let ret= butacas[index] ?  "bus-asiento-ocupado": "bus-asiento-disponible";
        return ret;
    }

    const tooglePassangerModal = (index) => {
        return butacas[index] ? {} : {"data-bs-toggle":"modal", "data-bs-target":"#passengerModal"+direccion}; 
    }

    const modalClick = (index) => {
        console.log("Butaca Seleccionada : ", index);
        setUltButacaSeleccionada(index);   
    }

    

    useEffect ( () => {        
        getTaquilla();
    } ,[]);

    return (
    <>              
            <div id="results">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Elegí tus <strong>Asientos</strong></h4>
                        </div>
                    </div>	
                    {/* <!-- /row --> */}
                </div>
                {/* <!-- /container --> */}
            </div>            

            <div className="container margin_60">
                {/* <!-- PASSENGER --> */}
                <div className="row">
                    <aside className="col-xl-5 col-lg-5 bus-container">
                        <div className="box_general_3 booking">
                            <form>
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <strong>Planta Baja</strong>
                                        <div className="bus">
                                            <BusRowDriver/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowXXOX a="1" b="2" c="3" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="4" b="5" c="6" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="7" b="8" c="9" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="10" b="11" c="12" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="13" b="14" c="15" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                            
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <strong>Planta Alta</strong>
                                        <div className="bus">
                                            <BusRowXXOX a="16" b="17" c="18" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                             
                                            <BusRowXXOX a="19" b="20" c="21" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="22" b="23" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="24" b="25" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="26" b="27" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="28" b="29" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                                                                     
                                            <BusRowXXOX a="30" b="31" c="32" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="33" b="34" c="35" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="36" b="37" c="38" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="39" b="40" c="41" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <SeatReferences />
                        </div>
                        {/* <!-- /box_general --> */}
                    </aside>
                    {/* <!-- /aside --> */}

                    <div className="col-xl-7 col-lg-7 passenger-container">
                        <div className="box_general_3 cart">
                            <div className="form_title mb-4">
                                <h3><strong className="container-icon-trim"><i className="icon-right-2"></i></strong>  
                                    { direccion === "ida" && <>{origen} A { destino } </>}
                                    { direccion === "vuelta" && <>{destino} A { origen } </>}
                                </h3>
                                <p>Tramo de {direccion}</p>
                            </div>

                            {/* <!--/Column Titles --> */}
                            <div className="list-item box_general wow fadeIn">
                                <div className="row text-center">
                                    <div className="col-lg-2">
                                        <small>Empresa</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Salida</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Llegada</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Comodidad</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Informacion</small>
                                    </div>
                                    <div className="col-lg-2">
                                        <small>Precio</small>
                                    </div>
                                </div>
                                <hr className="mt-0 mb-3" />
                                <div className="row">
                                    <div className="col-lg-2 col-md-12 item-company-name">
                                        <h6>{tramoSeleccionado.transportista.nombre}</h6>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>{ fancyDate(tramoSeleccionado.fechaHoraSalida) }</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>{ fancyDate(tramoSeleccionado.fechaHoraLlegada) }</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>{tramoSeleccionado.clase}</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <div>                                            
                                            Butacas: {tramoSeleccionado.butacas}<br />
                                            {/* <a href="#0" data-bs-toggle="modal" data-bs-target="#routeModal">Recorrido</a> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <div>
                                            <h6><small>$</small>{tramoSeleccionado.precio + tramoSeleccionado.serviceCharge}</h6>
                                            <small>Por Persona</small><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            {/* <!-- Passengers List --> */}
                            { listaPasajeros[direccion].length >0 &&
                            <div className="passengers-list" >
                                <h5 className="mt-5">Lista de pasajeros</h5>                                
                                
                                <div className="row text-center passenger-list-titles">
                                    <div className="col-lg-1">
                                        <small>Butaca</small>
                                    </div>
                                    <div className="col-lg-3">
                                        <small>DNI</small>
                                    </div>
                                    <div className="col-lg-4">
                                        <small>Nombre</small>
                                    </div>
                                    <div className="col-lg-4">
                                        <small>Apellido</small>
                                    </div>
                                </div>

                                { listaPasajeros[direccion].map((p) => {
                                    return (                                         
                                        <div className="row" key={p.pasajero.numeroDocumento} >
                                            <div className="col-lg-1 item-data">
                                                <p>{ p.butaca}</p>
                                            </div>
                                            <div className="col-lg-3  item-data">
                                                <p>{p.pasajero.numeroDocumento}</p>
                                            </div>
                                            <div className="col-lg-4 item-data">
                                                <p>{p.pasajero.nombre}</p>
                                            </div>
                                            <div className="col-lg-4  item-price">
                                                <p>{p.pasajero.apellido}</p>
                                                {/* <a href="1" className="btn_1 small">Quitar</a> */}
                                            </div>
                                        </div>                                                                                
                                    )})
                                }
                            </div>                            
                            }
                            {/* <!-- Passengers List --> */}

                            <hr />
                            {/* <!--End step --> */}

                            <div className="alert alert-secondary text-center" role="alert">
                                <p className="mb-0">
                                    <strong>¡IMPORTANTE!</strong><br />
                                    Si viaja con menores conozca la reglamentación vigente <a target='_blank' href="AutorizacionMenores.pdf" >aquí</a>
                                </p>
                            </div>
                            <div className="alert alert-secondary text-center" role="alert">
                                Es obligatorio viajar con el documento declarado en este pasaje según <a href="https://www.boletinoficial.gob.ar/detalleAviso/primera/153519/20161108" >Res 76-E-2016</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /col --> */}
                    
                </div>
                {/* <!-- /row --> */}
                
                <div className="alert alert-warning mt-4" role="alert" style={{display:"none"}}>
                    <div className="row">
                        <div className="col text-center d-flex align-items-center justify-content-center">
                            <i className="icon-warning-empty"></i>
                        </div>
                        <div className="col-11">
                            "Para saber los detalles de PreViaje ingresa al siguiente link" https://previaje.gob.ar/como-funciona .
                            Los requisitos sanitarios de ingreso a cada territorio debido al Covid-19 están determinados por las autoridades competentes, que pueden variar en el tiempo. Es responsabilidad del pasajero corroborar los requerimientos con la autoridad de cada jurisdicción antes del viaje. La ausencia de los requisitos es responsabilidad exclusiva del pasajero.
                        </div>
                    </div>
                </div>
                {/* <!-- /footer warning --> */}
                {/* <!-- /PASSENGER --> */}

                <hr className="separator-passengers" />
   
                

            </div>           

            <PassengerModal 
                listaPasajeros={listaPasajeros}
                setListaPasajeros={setListaPasajeros}             
                direccion={direccion}
                ultButacaSeleccionada={ultButacaSeleccionada} 
                importeFinal={tramoSeleccionado.precio + tramoSeleccionado.serviceCharge } 
                butacas={butacas} 
                setButacas={setButacas} 
                pax={pax} />
        </>
    )
}

export default Taquilla