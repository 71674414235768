import './App.css';
import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter as Router, Switch, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import Contacto from './pages/Contacto';
import Error from './pages/Error';
import ContactPage from './pages/Contacto';
import {UserContext, UserProvider} from './context/UserContext';
import { SearchContext, SearchProvider } from './context/SearchContext';
import TaquillaPage from './pages/Taquilla';
import Test from './pages/Test';

const ProtectedRoute = ({children})  => {
  const {userLoggedIn}= useContext(UserContext);
  if (!userLoggedIn){
    return <Navigate to="/login" />
  }
  return children;
}

function App() {

  // const {userName,setUserName, userLoggedIn,setUserLoggedIn, getUserToken}= useContext(UserContext);
  
/*   if (!userLoggedIn && window.location.pathname !=='/login' ) 
  {
    window.location = '/login';
  }  */

  return (
    <UserProvider>
      <SearchProvider>
        <Router>
          <div>    
            {/* <NavBar /> */}
            <Routes>
                <Route path="/" element={ <ProtectedRoute>  <HomePage />  </ProtectedRoute> }/>                                                
                <Route path="/login" element={<LoginPage />} />
                <Route path="/test" element={ <Test /> } />
                <Route path="/contacto" element={<ContactPage />} />
                <Route path="/taquilla/:servicioId/taquilla" element={<TaquillaPage />} />
                <Route path='*' element={<Error />} />
            </Routes>
          </div>
        </Router>
      </SearchProvider>
    </UserProvider>    
  );
}

export default App;
