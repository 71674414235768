import React from 'react'

const SeatReferences = () => {
  return (
    <div className="row text-center">
        <div className="col-xs-12 col-6">
            <img src="/img/bus/asiento-disponible.jpg" alt="" />
            <p className="mt-2">Disponible</p>
        </div>
        <div className="col-xs-12 col-6">
            <img src="/img/bus/asiento-no-disponible.jpg" alt="" />
            <p className="mt-2">No disponible</p>
        </div>
        <div className="col-xs-12 col-6">
            <img src="/img/bus/asiento-seleccionada.jpg" alt="" />
            <p className="mt-2">Seleccionada</p>
        </div>
        <div className="col-xs-12 col-6">
            <img src="/img/bus/asiento-ocupado.jpg" alt="" />
            <p className="mt-2">Ocupada</p>
        </div>
    </div>
  )
}

export default SeatReferences