import React, { useContext, useEffect } from 'react'
import Taquilla from './Taquilla'
import { SearchContext } from '../context/SearchContext'
import context from 'react-bootstrap/esm/AccordionContext';

const TaquillaContainer = () => {

    const {
        idaSeleccionada, vueltaSeleccionada,
        pax,
        listaPasajeros, setListaPasajeros,
        setStep,         
        loading,setLoading,
        idsReserva, setIdsReserva,
        setUrlTicket,
    } = useContext(SearchContext);

    const volverPaso2 = () => {
        //vaciamos lista pasajeros.
        setListaPasajeros({
            ida: [],
            vuelta: []
        });
        //volvemos al paso 2.
        setStep("paso2");
    }

    useEffect(() => {
        if(idsReserva["ida"] ) {
            setStep("paso4");
        }   
    },[idsReserva]);
        
    const comprarPasajes = async () => {
        if (idaSeleccionada !== undefined && pax == listaPasajeros['ida'].length && (vueltaSeleccionada === undefined || pax == listaPasajeros['vuelta'].length) ) {
          
            let currentToken= sessionStorage.getItem('currentToken');        
            //console.log('Reservar usa token:' + currentToken);
            //Reservar la ida y la vuelta si corresponde.
            
            await comprarPasaje("ida", currentToken, idaSeleccionada, listaPasajeros["ida"]);   
            if ( vueltaSeleccionada != null) {
                await comprarPasaje('vuelta',currentToken, vueltaSeleccionada, listaPasajeros["vuelta"]);
            }
            if(idsReserva["ida"] ) {
                setStep("paso4");
            }            
         }
        else {
            alert('Debe completar la lista de todos los pasajeros');
        } 

    }

    const comprarPasaje = async (tramo, token, tramoSeleccionado, listaPasajeros ) => {
        // La compra del pasaje se hace en dos etapas 
        // 1º la reserva /buses/reserva 
        // 2º la compra/ emisión de ticket /buses/reservas/{{idReserva}}/confirmacion
        
        ///////////////////////////////// 1º Reservar //////////////////////////////////
        setLoading(true);
        try {
            const response = await fetch( process.env.REACT_APP_API + `/buses/reservas`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "token": token
                },
                method: 'POST',
                body: JSON.stringify(
                    {
                        "Servicios":[
                            {
                                "codigoServicio":tramoSeleccionado.codigoServicio,
                                "items": listaPasajeros
                            }
                         ]          
                    }
            )});
            
            console.log("BODY RESERVA:");
            console.log(JSON.stringify(
                {
                    "Servicios":[
                        {
                            "codigoServicio":tramoSeleccionado.codigoServicio,
                            "items": listaPasajeros
                        }
                    ]          
                }
            ));
            
            const data = await response.json();    
            console.log(data);
            
            if(data.message) alert(data.mensaje);

            setIdsReserva(prevIdsReserva => (
                {
                ...prevIdsReserva,
                [tramo]: data.idReserva
                }
            ));         

            console.log("************ ID RESERVA " + tramo + " : " + data.idReserva);

            ///////////////////// 2º Paso, confirmación reserva! //////////////////////////////
            if (data.idReserva){
                const response = await fetch( process.env.REACT_APP_API + `/buses/reservas/${data.idReserva}/confirmacion`, {
                    headers: {
                        "Content-type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "X-Requested-With",
                        "token": token
                    },
                    method: 'POST',
                    body: JSON.stringify(
                        { 
                            "FormaDePago": 1,
                            "idMedioPago": null,
                            "Cuotas":  1,
                            "PorcentajeInteres": 0,
                            "ModoDeRecaudacion": 1,
                            "InformacionDePagoAprobado":{
                                "IdPago" : null                    
                            },
                            "DescuentoComision":{
                                "DescuentoP10":0,
                                "DescuentoPV":0
                            }	
                    }
                )});
                const dataConfirm = await response.json();    
                console.log("Confirmacion de reserva: ");
                console.log(dataConfirm); 
                if(data.message) alert(data.mensaje);
                
                setUrlTicket(prevUrlTickets => (
                    {
                    ...prevUrlTickets,
                    [tramo]: dataConfirm.items[0].urlTicket ?? ""
                    }
                ));  
            }else{
                console.log("Error: No hubo 'idReserva' en el paso previo.")
            }

        } catch (error) {
            const msg= "ERROR: " + error.message;
            //alert(msg);
            console.log(msg);
        }
        setLoading(false);
    }

/*     const confirmarReserva() => {
    

    } */

    return (
        <>

            <Taquilla tramoSeleccionado={idaSeleccionada} direccion="ida" />
            { vueltaSeleccionada &&                    
                <Taquilla tramoSeleccionado={vueltaSeleccionada} direccion="vuelta" />
            }

            <div className="box_general_3 mt-4">
                <h5 className="text-center">Subtotal: <small>$</small>{ pax *(((idaSeleccionada?.precio + idaSeleccionada?.serviceCharge ) ?? 0)  + ((vueltaSeleccionada?.precio ?? 0 + (vueltaSeleccionada?.serviceCharge ?? 0)))) }</h5>
                <hr /> 
                <div className="row">
                    <div className="col-lg-6 text-lg-start text-center mb-lg-0 mb-3">
                        <button onClick={volverPaso2} className="btn_1 outline medium"><i className="icon-left-open-2"></i> Volver a Seleccionar</button>
                    </div>
                    <div className="col-lg-6 text-lg-end text-center">                            
                        <button onClick={ async () =>  await comprarPasajes()} className="btn_1 medium">Confirmar y comprar<i className="icon-right-open-2"></i></button>
                    </div>
                </div>
            </div>

        </>    
    )
}

export default TaquillaContainer