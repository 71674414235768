import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { SearchContext } from '../context/SearchContext';

const Header = () => {
  const { userName, setUserName, userLoggedIn, setUserLoggedIn } = useContext(UserContext);
  const {step, setStep} = useContext(SearchContext);

  const handleChangePass = () => {
    setStep("changepass");
  }

  const handleLogout = () => {    
    //localStorage.removeItem('authToken'); // Example: clear auth token
    setUserName('');
    setUserLoggedIn(false);
    window.location.reload(); // Force page reload
  };  

  return (
    <header className="header_sticky">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div id="logo_home">
              <h1>
                <Link to="/login">Travelclick</Link>
              </h1>
            </div>
          </div>
          <nav className="col-lg-9 col-6">
            

            <div className="main-menu">
              <ul>
                <li><Link to='/login'>Home</Link></li>

                <li className="submenu" style={{ display: '' }}>
                  <a href="http://travelclick.com.ar" target="_blank" className="show-submenu" rel='noreferrer'>
                    Travelclick<i className="icon-down-open-mini"></i>
                  </a>
                  <ul>
                    <li><a href="http://travelclick.com.ar" target="_blank" rel='noreferrer'>Pasajes con tarjeta de crédito</a></li>
                    <li><a href="http://travelclick.com.ar/about-us/" target="_blank" rel='noreferrer'>Quienes somos</a></li>
                    <li><a href="http://travelclick.com.ar/asistencia-al-viajero/" target="_blank" rel='noreferrer'>Asistencia al Viajero</a></li>
                    <li><a href="http://travelclick.com.ar/consultas/" target="_blank" rel='noreferrer'>Consultas</a></li>
                    {process.env.NODE_ENV !== 'production' && (
                      <>
                        <li><Link to="/taquilla/57fd144c-1781-4e00-85af-a6bfe61711be_1//taquilla">Taquilla</Link></li>
                        <li><Link to="/test">Test</Link></li>
                      </>
                    )}
                  </ul>
                </li>
                <li>
                  <a target='_blank' rel='noreferrer' href="http://travelclick.com.ar/consultas/">Contacto</a>
                </li>
                {process.env.NODE_ENV !== 'production' && (
                  <li>
                    <span style={{ color: 'yellow' }}>Env: Testing!</span>
                  </li>
                )}

                {userLoggedIn ? (
                <li className='submenu'>
                  <a href="http://travelclick.com.ar" target="_blank" className="show-submenu" rel='noreferrer'>
                    {userName || 'Sindicato 1'} 
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <i className="icon-down-open-mini"></i>
                  </a>
                  <ul >                    
                    <li>
                      <a href="#0" onClick={handleChangePass}>Cambiar contraseña</a>
                    </li>  
                    <li>
                      <a href="#0" onClick={handleLogout}>Cerrar sesión.</a>
                    </li>                
                  </ul>
                </li>
                ) : ''}


                <li>
                    <a href="https://wa.me/5491137292580" target="_blank" rel="noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" style={{width: "50px", height: "50px"}} />
                    </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
