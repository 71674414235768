import React, {useState, createContext, createFactory} from 'react'

export const SearchContext = createContext();

export const SearchProvider = ({children}) => {

    const [step, setStep] = useState('paso1');
    const [servicios, setServicios] = useState();    
    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState(null);
    const [destinoId, setDestinoId] = useState(null);
    const [ida, setIda] = useState('');
    const [vuelta, setVuelta] = useState(''); 
    const [pax, setPax] = useState(0);
    const [loading, setLoading] = useState(false);               
    
    const [idaSeleccionada, setIdaSeleccionada] = useState();
    const [vueltaSeleccionada, setVueltaSeleccionada] = useState();
    const [listaPasajeros, setListaPasajeros] = useState({
        ida: [],
        vuelta: []
    }
    );
    const [idsReserva,setIdsReserva] = useState(
    {
        "ida": null,
        "vuelta": null
    });
    const [urlTicket,setUrlTicket] = useState(
        {
            "ida": null,
            "vuelta": null
        }
    );


    const handleSearch = (event) => {
        event.preventDefault();        
        console.log(`OrigenIdorigenId: ${origenId} - DestinoId: ${destinoId} - Ida: ${ida} - vuelta ${vuelta} - Pax: ${pax} `); 
        getServicios(origenId, destinoId, ida );        
        setStep('paso2');
    }

    const comprar = (servicio,tramo) => {
        if (tramo === "ida"){
            console.log("Servicio Ida: " + servicio);
            setIdaSeleccionada(servicio);        

            if (vuelta !== '') {
                getServicios(destinoId, origenId, vuelta);
                setStep("paso2b");        
            }
            else {
                setStep('paso3'); //si no hay "vuelta" vamos a la taquilla
            }
        }
        else { //vuelta
            setStep("paso3");        
            setVueltaSeleccionada(servicio);       
            console.log("Servicio Vuelta: " + servicio);
        }
    }

    const fancyDate = (d) => {
        let ret= new Date(d);
        return ret.toLocaleString();
    }    

    const getServicios = async (oriId, destId, fecha) => {
        setLoading(true);        
        let currentToken= sessionStorage.getItem('currentToken');
        console.log('GetServicios usa token:' + currentToken);

        try {
            const response = await fetch( process.env.REACT_APP_API + `/buses/busqueda/${oriId}/${destId}/${fecha}`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",        
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",  
                    "token": currentToken
                },
                method: 'GET'
            });
            const data = await response.json();
            console.log(data);
            setServicios(data);    
        } catch (error) {
            const msg= "Error " + error.message;
            alert(msg);
            console.log(msg);
        }
        
        setLoading(false);
    }
    

    return(
        <SearchContext.Provider value={
            {
                step, setStep,
                servicios, setServicios,
                origen, setOrigen,
                destino, setDestino,
                origenId, setOrigenId,
                destinoId, setDestinoId,
                ida, setIda,
                vuelta, setVuelta,
                pax, setPax,
                loading, setLoading,
                idaSeleccionada, setIdaSeleccionada,
                vueltaSeleccionada, setVueltaSeleccionada,                
                listaPasajeros, setListaPasajeros,
                handleSearch,
                comprar,
                fancyDate,
                idsReserva, setIdsReserva,
                urlTicket, setUrlTicket
            }
            }>
            {children}
        </SearchContext.Provider>
    );

}
