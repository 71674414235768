import React, {useContext, useEffect, useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { UserContext } from "../context/UserContext";
import { useNavigate } from 'react-router-dom';
import SearchBox from "../components/SearchBox";
import Results from "../components/Results";
import { SearchContext } from "../context/SearchContext";
import BuscadorHome from "../components/BuscadorHome";
import Taquilla from "../components/Taquilla";
import Gracias from "../components/Gracias";
import TaquillaContainer from "../components/TaquillaContainer";
import ChangePass from "../components/ChangePass";


const HomePage = () => {    

    const {
        step,setStep,
        loading, setLoading,    
        servicios,
        idsReserva,
        urlTicket
    } = useContext(SearchContext);

    return (    
        <div>
        <div className="layer"></div>          
            { loading &&
                <div id="preloader">
                    <div data-loader="circle-side"></div>
                </div>        
            }  
            <Header />

            { step === "paso1" &&
                <BuscadorHome />
            }

            {step === "paso2"  && servicios &&
                <Results tramo="ida" />
            }

            {step === "paso2b"  && servicios &&
                <Results tramo="vuelta" />
            }            

            {step === "paso3" &&
                <TaquillaContainer />        
            }

            {step === "paso4" && urlTicket &&
                <Gracias />

            }

            {
                step === "changepass" && 
                <ChangePass />
            }
            <Footer/>        
        </div>
    );
}

export default HomePage;