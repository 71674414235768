import React from 'react'
import { useState, useEffect} from 'react';
import CountryOptions from './CountryOptions';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import $ from 'jquery';

const PassengerModal = ({listaPasajeros, setListaPasajeros, direccion, ultButacaSeleccionada, importeFinal, butacas, setButacas, pax}) => {

    const [formValues, setFormValues] = useState({
        Butaca: ultButacaSeleccionada,
        Nombre: '',
        Apellido: '',
        NacionalidadId: '1',
        TipoDocumentoId: '1',
        DocumentoNumero: '',
        FechaNacimiento: '',
        Sexo: 'M',        
        TelefonoSinCodigo: '',
        Email: ''
    });   

    useEffect(() => {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          Butaca: ultButacaSeleccionada
        }));
      }, [ultButacaSeleccionada]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });        
    };

    const fillTestingPax= () => {
        setFormValues({ ...formValues,
            Nombre: 'Edgar',
            Apellido: 'Ale',
            NacionalidadId: '1',
            TipoDocumentoId: '1',
            DocumentoNumero: '11555666',
            FechaNacimiento: '1990-12-12',
            Sexo: 'M',
            TelefonoSinCodigo: '55556666',
            Email: 'info@excelsos.com.ar'
        });
    }

    const formatPasaje = (formVal) => { //formatea los datos como son requeridos por la api.
        return {
            "butaca": formVal.Butaca,
            "importe": importeFinal,
            "pasajero": {
                "nombre": formVal.Nombre,                
                "apellido": formVal.Apellido,
                "fechaNacimiento": formVal.FechaNacimiento,
                "tipoDocumento": formVal.TipoDocumentoId,
                "numeroDocumento": formVal.DocumentoNumero,
                "nacionalidad": formVal.NacionalidadId,
                "residencia": 1,
                "razonSocial": "qweqweqwe",
                "tipoDocumentoFiscal": 1,
                "numeroDocumentoFiscal": "123456798",
                "idCondicionImpositiva": 1,
                "ocupacion": "test",
                "estadoCivil": "test",
                "sexo": formVal.Sexo,
                "email": formVal.Email,
                "telefono": formVal.TelefonoSinCodigo
            }
        }
    }
    
    const validPax = (pax) => {
        for (let key in pax) {
            // If any value is empty, return false
            if (pax[key] === '') {
              return false;
            }
          }
          // If all values are not empty, return true
          return true;
    }

    const handlePaxSubmit = (e) => {
        e.preventDefault();
        
        console.log('Form submitted:', formValues);
        if (validPax(formValues)){

            let formatedPax = formatPasaje(formValues);

            const updatedListaPasajeros = {...listaPasajeros};        
            updatedListaPasajeros[direccion].push(formatedPax);        
            setListaPasajeros(updatedListaPasajeros);

            const updatedButacas = {...butacas};
            updatedButacas[formatedPax.butaca] = true;
            setButacas( updatedButacas );

            console.log('Lista pasajeros:' , listaPasajeros);                
            // Reset values
            setFormValues({
                Butaca:'',
                Nombre: '',
                Apellido: '',
                NacionalidadId: '1',
                TipoDocumentoId: '1',
                DocumentoNumero: '',
                FechaNacimiento: '',
                Sexo: 'M',
                TelefonoSinCodigo: '',
                Email: ''
            });        
            //cerrando el modal
            /* 
            const modal = document.getElementById('passengerModal'+direccion);
            if (modal) {
                //modal.dispatchEvent(new Event('hide.bs.modal'));
                //$(modal).hide();
                const btModal = new bootstrap.Modal(modal);
                btModal.hide();
                var modalBackdrop = document.querySelector('.modal-backdrop.fade.show');
                if (modalBackdrop)     modalBackdrop.parentNode.removeChild(modalBackdrop);


            } */

        }   else{
            alert('Debe completar todos los campos');
        }             
    };

    return (
        <>    
        <div className="modal fade" id={`passengerModal${direccion}`} tabIndex="-1" aria-labelledby="passengerModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
            <form id="form-datos-pasajero" onSubmit={handlePaxSubmit} method='post' >
                <div className="modal-header">
                <h5 className="modal-title w-100 text-center" id="passengerModalLabel">
                    Carga de pasajeros - {direccion}
                    { process.env.NODE_ENV !== 'production' &&
                        <button onClick={fillTestingPax} style={{margin: 10 }} > Fill </button>
                    }    
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                { listaPasajeros[direccion] && pax> listaPasajeros[direccion].length && 
                <>  
                <div className="modal-body">

                    
                        <div className="row mb-2">
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="Nombre">Nombre(s)</label>
                                    <input required className="form-control"                                            
                                    id="Nombre"  maxLength="27" name="Nombre" tabIndex={3} type="text"
                                    onChange={handleInputChange}
                                    value={formValues.Nombre} />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="Apellido">Apellido(s)</label>
                                    <input required className="form-control"                                           
                                    id="Apellido"  maxLength="27" name="Apellido" tabIndex="4" type="text"
                                    onChange={handleInputChange} 
                                    value={formValues.Apellido} />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-xs-12 col-sm-4">
                                <div className="form-group selector">
                                    <label htmlFor="NacionalidadId">Nacionalidad</label>
                                    <select className="form-control" id="NacionalidadId" name="NacionalidadId" tabIndex="5"
                                    onChange={handleInputChange}
                                    value={formValues.NacionalidadId}
                                    >
                                        <CountryOptions/>
                                    </select>                   
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-8">
                                <div className="row">
                                    <div className="col-12">
                                        <label>Tipo y Número de Documento</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="form-group selector">
                                            <select className="form-control"   
                                            id="TipoDocumentoId" name="TipoDocumentoId" tabIndex="6">
                                                <option value="1" data-p10-reglavalidacion="^(\d{1,2}\.\d{3}\.\d{3}|\d{1,8})$">DNI</option>
                                            </select>
                                            <input id="ActualizaDocumento" name="ActualizaDocumento" type="hidden" value="True" />
                                        </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="form-group">
                                            <input className="form-control" id="DocumentoNumero"  required 
                                            maxLength="20" name="DocumentoNumero" tabIndex="7" type="number" 
                                            onChange={handleInputChange}
                                            value={formValues.DocumentoNumero} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="FechaNacimiento">Fecha de nacimiento</label>
                                    <input className="form-control" id="FechaNacimiento" 
                                    name="FechaNacimiento" placeholder="dd/mm/aaaa"  tabIndex="10" type="date"  
                                    onChange={handleInputChange} value={formValues.FechaNacimiento} required />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <span>Sexo</span><br />
                                    <label className="mt-3">
                                        <input   id="SexoMasculino" name="Sexo" tabIndex="12" type="radio" value="M" onChange={handleInputChange} />
                                        Masculino
                                    </label>
                                    <label className="mt-3">
                                        <input id="SexoFemenino" name="Sexo" tabIndex="13" type="radio" value="F" onChange={handleInputChange} />
                                        Femenino
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <div className="row">                                        
                                        <div className="col-12">
                                            <input className="form-control" 
                                            id="TelefonoSinCodigo"  maxLength="20" minLength="4" name="TelefonoSinCodigo" tabIndex="19" type="text"
                                            onChange={handleInputChange}  value={formValues.TelefonoSinCodigo} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="telefono">Email</label>
                                    <div className="row">
                                        <div className="col-xs-12 rigpad-no">
                                            <input className="form-control"   data-val-regex="Formato Inválido"   required
                                            id="Email"  maxLength="100" minLength="2" name="Email" tabIndex="20" type="email"
                                            onChange={handleInputChange}  value={formValues.Email} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>
                <div className="modal-footer">
                    <div className="row w-100 mb-2 text-center">
                        <div className="col-12 text-center">
                            <button id="boton-cargar-datos-pasajero" type='submit' className="btn_1"  tabIndex="21" data-bs-dismiss="modal" onClick={handlePaxSubmit}>
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
                </>
                }
                {listaPasajeros[direccion] && pax <= listaPasajeros[direccion].length &&
                <>
                <div className="modal-body">
                    Todos los pasajeros ya fueron cargados.
                </div>                
                <div className="modal-footer">
                    <div className="row w-100 mb-2 text-center">
                        <div className="col-12 text-center">
                            <button id="boton-cargar-datos-pasajero" type='submit' className="btn_1"  data-bs-dismiss="modal">
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
                </>
                }
            </form>
            </div>
            </div>
        </div>        
        </>
    )
}

export default PassengerModal