import React, {useContext} from "react";
import { SearchContext } from "../context/SearchContext";


const Test = () => {

    const 
        {servicios, setServicios,
        origen,setOrigen,
        destino,setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        ida, setIda,
        vuelta, setVuelta,
        pax, setPax,
        idaSeleccionada,
        setLoading,
        fancyDate        
        } = useContext(SearchContext);

    const reservar = async () => {
        let currentToken= sessionStorage.getItem('currentToken');
        console.log('Reservar usa token:' + currentToken);

        let payload= {
            "Servicios":[
                {
                    "codigoServicio":"c1f6c62a-09e8-4048-9e9d-86845515d5da_0",
                    "items":[
                       {
                         "butaca":16,
                         "importe":39.2,
                         /*Datos Carga Pasajero:*/
                         "pasajero":{
                              "nombre": "Edgardo",
                              "apellido": "Alemis",
                              "fechaNacimiento": "1980-04-11",
                              "tipoDocumento": 3,
                              "numeroDocumento": "123456789",
                              "nacionalidad": 1,
                              "residencia": 1,
                              "razonSocial": "razonSocial 123",
                              "tipoDocumentoFiscal": 1,
                              "numeroDocumentoFiscal": "123456798",
                              "idCondicionImpositiva": 1,
                              "ocupacion": "test",
                              "estadoCivil": "soltero",
                              "sexo": "M",
                              "email": "testemail@gmail.com",
                              "telefono": "44445555"
                          }, 
          
                          "menuABordo": "alfajor"
                       }                               
                    ]
                }
             ]          
        };

        //console.log(payload.Servicios[0].items[0].pasajero);

        const response = await fetch( process.env.REACT_APP_API + `/buses/reservas/`, {
            headers: {
                "Content-type": "application/json;charset=UTF-8",        
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "X-Requested-With",  
                "token": currentToken
            },
            method: 'POST',
            body: JSON.stringify(
                payload
        )});
        const data = await response.json();
        console.log("RESERVA:" + data.idReserva); 
        
    }

    return (
        <>
            Environment API: {process.env.REACT_APP_API} <br/>

            Check console log...
            <button onClick={reservar}> request api</button>
        </>
    );
}; 

export default Test;