const data = [{"id":652,"nombre":"Campana","localidad":"Campana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.190672,"longitud":-58.945947,"ranking":775},
{"id":4057,"nombre":"Campana Acceso","localidad":"Campana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.17945,"longitud":-58.96073,"ranking":0},
{"id":10736,"nombre":"Estadio Villa Dalmine","localidad":"Campana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.16222601568304,"longitud":-58.97893120951314,"ranking":0},
{"id":10737,"nombre":"San Martin y Liniers","localidad":"Campana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.1784598678279,"longitud":-58.96188304137983,"ranking":0},
{"id":10786,"nombre":"La Carmela","localidad":"Campana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.19007393627657,"longitud":-58.945523323324934,"ranking":0},
{"id":913,"nombre":"Dolores","localidad":"Dolores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.3197159,"longitud":-57.6933922,"ranking":591},
{"id":1524,"nombre":"Dolores Acceso","localidad":"Dolores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.2951708,"longitud":-57.6900456,"ranking":0},
{"id":15471,"nombre":"Termas de Dolores","localidad":"Dolores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.3065078,"longitud":-57.6521449,"ranking":0},
{"id":7,"nombre":"La Plata","localidad":"La Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.905588,"longitud":-57.95416,"ranking":10030},
{"id":9200,"nombre":"Poblet Acceso","localidad":"La Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.067299504256646,"longitud":-57.966723358267416,"ranking":0},
{"id":14422,"nombre":"La Plata Acceso","localidad":"La Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9328734,"longitud":-57.9798766,"ranking":8},
{"id":767,"nombre":"Chascomús","localidad":"Chascomus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.578963,"longitud":-57.9832349,"ranking":823},
{"id":1425,"nombre":"Chascomús Acceso","localidad":"Chascomus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.57351,"longitud":-57.98018,"ranking":18},
{"id":9,"nombre":"Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.988207,"longitud":-57.56324,"ranking":15534},
{"id":1539,"nombre":"Av. Champagnat y Av Colon","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.984557347735354,"longitud":-57.587057099999996,"ranking":0},
{"id":1540,"nombre":"Av. Champagnat y Juan B. Justo","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.99874099805977,"longitud":-57.59805623113387,"ranking":0},
{"id":2466,"nombre":"Av. Champagnat y Av. Luro","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.97967030937869,"longitud":-57.58338626020114,"ranking":0},
{"id":5471,"nombre":"Parque Camet","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14282,"nombre":"Dia de Playa - Mogotes","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9047,"nombre":"Hoteles Zona Centro   Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9048,"nombre":"Hoteles Zona La   Perla - Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9049,"nombre":"Hoteles Zona   ExTerminal - Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9050,"nombre":"Aeropuerto Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.933206,"longitud":-57.581886,"ranking":0},
{"id":9071,"nombre":"Virasoro","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14281,"nombre":"City Tour Nocturno MDQ","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7387,"nombre":"Puerto - Abate esquina Mariluz II (Dársena B)","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.04881,"longitud":-57.539512,"ranking":0},
{"id":7388,"nombre":"Costa","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.0491393,"longitud":-57.53969189999999,"ranking":0},
{"id":8294,"nombre":"El Monolito","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9046,"nombre":"City Tour Mar del Plata","localidad":"Mar del Plata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10,"nombre":"Retiro","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.588762,"longitud":-58.372677,"ranking":73206},
{"id":11,"nombre":"Constitucion","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6276281,"longitud":-58.3784878,"ranking":0},
{"id":49,"nombre":"Liniers (Límite con Ciudadela)","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.642524,"longitud":-58.528382,"ranking":10672},
{"id":1084,"nombre":"Terminal Puerto Madero","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.602762,"longitud":-58.36823,"ranking":40},
{"id":1328,"nombre":"Teatro Colon","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6010406,"longitud":-58.3830786,"ranking":9},
{"id":1372,"nombre":"Lima 1697 Constitucion Palace Hotel","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14754,"nombre":"Plaza San Martin","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.59515362922139,"longitud":-58.37638024915285,"ranking":0},
{"id":15345,"nombre":"Diagonal Norte","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6063565,"longitud":-58.37691340000001,"ranking":9},
{"id":15380,"nombre":"Beiro y Gral Paz","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.617991401965725,"longitud":-58.53019186023807,"ranking":0},
{"id":15465,"nombre":"Terminal Subterránea de Combis(Av. 9 de Julio)","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6051,"longitud":-58.3819,"ranking":0},
{"id":9924,"nombre":"Paseo Alcorta","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10068,"nombre":"Puerto de Buenos Aires - Terminal de Cruceros","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10622,"nombre":"Pruebas","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10738,"nombre":"Plaza Italia","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.5810892373048,"longitud":-58.421064351304985,"ranking":0},
{"id":10739,"nombre":"Hotel Sheraton","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.592986688537955,"longitud":-58.372466148069925,"ranking":0},
{"id":10800,"nombre":"Dellepiane","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6499095,"longitud":-58.4641274,"ranking":719},
{"id":9192,"nombre":"Constituyentes y Beiro","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.589151473341275,"longitud":-58.48500307878822,"ranking":0},
{"id":9889,"nombre":"Postales de Buenos Aires","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9890,"nombre":"Pestana Buenos Aires Hotel - Carlos Pellegrini 877","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.59811923647646,"longitud":-58.38104820227263,"ranking":0},
{"id":9916,"nombre":"San Telmo","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9923,"nombre":"Facultad de Derecho","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8719,"nombre":"Delta Premium","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8746,"nombre":"Obelisco","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9087,"nombre":"Noches de Plata","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9172,"nombre":"Buenos Aires La Rural","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.5797194386272,"longitud":-58.420933537533884,"ranking":0},
{"id":9173,"nombre":"C.Pellegrini y Sta. Fe ","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9182,"nombre":"Centro Bdo. de Irigoyen y Av. de Mayo","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.609207978127536,"longitud":-58.38060083519281,"ranking":0},
{"id":5658,"nombre":"Belgrano","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.5605959,"longitud":-58.4545580,"ranking":0},
{"id":7103,"nombre":"Alto Palermo","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.588267,"longitud":-58.410688,"ranking":50},
{"id":7107,"nombre":"Aeroparque Jorge Newbery","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.556867,"longitud":-58.412702,"ranking":42},
{"id":7461,"nombre":"Rolon","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7934,"nombre":"Agronomia","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.595045,"longitud":-58.4945745,"ranking":0},
{"id":8718,"nombre":"Rio de la Plata","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3216,"nombre":"Estación Fluvial Puerto Madero","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.598029,"longitud":-58.36431,"ranking":0},
{"id":3287,"nombre":"Puente Saavedra","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.540001,"longitud":-58.475633,"ranking":213},
{"id":3671,"nombre":"Hotel Bauen","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3928,"nombre":"Puerto de Buenos Aires.","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6247,"longitud":-58.361883,"ranking":166},
{"id":4761,"nombre":"Centro","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.600777,"longitud":-58.380923,"ranking":0},
{"id":5657,"nombre":"Puente Pacifico","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.57813361711053,"longitud":-58.42620232658895,"ranking":0},
{"id":1374,"nombre":"Estacion de tren Mitre","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1513,"nombre":"Flores","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.628804923711115,"longitud":-58.46299149986771,"ranking":0},
{"id":1514,"nombre":"Once","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.6135222,"longitud":-58.4057289,"ranking":0},
{"id":2470,"nombre":"Hotel Sarmiento","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.605675,"longitud":-58.394418,"ranking":0},
{"id":3172,"nombre":"Santa Fe y Callao","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":-34.595937934414934,"longitud":-58.39343504484243,"ranking":0},
{"id":3175,"nombre":"Planetario","localidad":"Ciudad de Buenos Aires","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":43,"nombre":"San Isidro","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":314,"nombre":"Thames","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.50255,"longitud":-58.539945,"ranking":25},
{"id":2062,"nombre":"Hipodromo de San Isidro","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.481048,"longitud":-58.517677,"ranking":78},
{"id":4045,"nombre":"Marquez","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.496289,"longitud":-58.553947,"ranking":7},
{"id":4547,"nombre":"Fondo de la Legua","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4942558,"longitud":-58.5509463,"ranking":0},
{"id":10375,"nombre":"Nautico San Isidro (Solo Socios)","localidad":"San Isidro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14,"nombre":"Rafaela","localidad":"Rafaela","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.2412,"longitud":-61.506187,"ranking":960},
{"id":1353,"nombre":"Parador Rafaela","localidad":"Rafaela","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.2402657,"longitud":-61.5063464,"ranking":0},
{"id":10848,"nombre":"Rafaela Acceso","localidad":"Rafaela","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.3096181,"longitud":-61.51901969999999,"ranking":0},
{"id":15,"nombre":"Santiago del Estero","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.778485,"longitud":-64.263444,"ranking":6330},
{"id":1125,"nombre":"Granadero Gatica","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.870408250391552,"longitud":-62.71553341947196,"ranking":0},
{"id":1359,"nombre":"Santiago de Estero Acceso","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1674,"nombre":"El Cabure","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.021077000,"longitud":-62.336125400,"ranking":0},
{"id":1676,"nombre":"Hospital Regional","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.804900251237775,"longitud":-64.25059406633459,"ranking":0},
{"id":1677,"nombre":"Libertad y Aguirre","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15633,"nombre":"Aeropuerto Santiago del Estero","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.7561057,"longitud":-64.2988268,"ranking":0},
{"id":15634,"nombre":"Hoteles Santiago del Estero","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.7939312,"longitud":-64.256191,"ranking":0},
{"id":7551,"nombre":"El Martillo ","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7554,"nombre":"Cementerio","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9145,"nombre":"Taco Bajada","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.12669302169705,"longitud":-64.28401635322102,"ranking":0},
{"id":14756,"nombre":"San Pedro","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7511,"nombre":"Villa Nueva ","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.301199269967523,"longitud":-64.02841283998302,"ranking":0},
{"id":7537,"nombre":"Pampa Pozo ","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7543,"nombre":"La Fragua","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7546,"nombre":"Esquina","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7548,"nombre":"El Triunfo ","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7549,"nombre":"El Tajamar","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2904,"nombre":"Argentina","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3645,"nombre":"Vilmer","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.783517554441012,"longitud":-64.1500378518037,"ranking":0},
{"id":4463,"nombre":"Pozo Hondo","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.1626785,"longitud":-64.4863464,"ranking":0},
{"id":4464,"nombre":"Rapelli","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.391668257381266,"longitud":-64.50407161432874,"ranking":0},
{"id":7510,"nombre":"Vinal Pozo","localidad":"Santiago del Estero","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":16,"nombre":" Termas de Rio Hondo","localidad":"Termas de Rio Hondo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.491695,"longitud":-64.865556,"ranking":1143},
{"id":1360,"nombre":"Termas de Rio Hondo Acceso","localidad":"Termas de Rio Hondo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.5381257,"longitud":-64.79678609999999,"ranking":0},
{"id":1637,"nombre":"Mansupa","localidad":"Termas de Rio Hondo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.452431644999447,"longitud":-64.90924091450516,"ranking":0},
{"id":15635,"nombre":"Aeropuerto Termas Rio Hondo","localidad":"Termas de Rio Hondo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.5062819,"longitud":-64.9330844,"ranking":0},
{"id":15636,"nombre":"Hoteles Termas Rio Hondo","localidad":"Termas de Rio Hondo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":17,"nombre":"San Miguel de Tucuman","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.83662,"longitud":-65.1954,"ranking":7124},
{"id":1504,"nombre":"Acheral","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.121582360548683,"longitud":-65.46885134312527,"ranking":0},
{"id":1505,"nombre":"Los Puestos","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.281836654,"longitud":-65.019438333,"ranking":0},
{"id":1507,"nombre":"San Pablo","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.874730277006268,"longitud":-65.30996285301524,"ranking":0},
{"id":1508,"nombre":"San Rafael","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.97392236012681,"longitud":-65.38085839103938,"ranking":0},
{"id":1510,"nombre":"Villa Quinteros","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.25329047330921,"longitud":-65.54812860449601,"ranking":1},
{"id":8983,"nombre":"City Tour Tucuman","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8984,"nombre":"Circuito Chico Tucumán- Las Yungas","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14594,"nombre":"Ramada Paso Acceso ","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.6846542,"longitud":-64.93712099999999,"ranking":0},
{"id":7753,"nombre":"Francisco Madero","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7951,"nombre":"Cruce Bonifacio","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8980,"nombre":"Aeropuerto de Tucuman - Tte. B. Matienzo","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8981,"nombre":"Hoteles Tucuman","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.830918,"longitud":-65.20419,"ranking":0},
{"id":7737,"nombre":"Estacion Unzue","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7741,"nombre":"Primera Junta","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7743,"nombre":"Cambaceres","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7744,"nombre":"Curva","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7745,"nombre":"Gowland","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7748,"nombre":"El Pretexto","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7556,"nombre":"Boca del Tigre","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.772083396193437,"longitud":-65.1027461965173,"ranking":0},
{"id":7557,"nombre":"Banda Rio Sali ","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.837952978197418,"longitud":-65.16982046721459,"ranking":0},
{"id":7560,"nombre":"Alderetes ","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.816362326887035,"longitud":-65.13341719208167,"ranking":0},
{"id":7561,"nombre":"7 de Abril","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.29160453930096,"longitud":-64.5014101851905,"ranking":0},
{"id":7734,"nombre":"Solano ","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7735,"nombre":"Puente","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7541,"nombre":"Palomitas","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-24.898398409269696,"longitud":-64.97345148477976,"ranking":0},
{"id":7542,"nombre":"La Ramada","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.6881397,"longitud":-64.9469515,"ranking":0},
{"id":7544,"nombre":"Banda","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7545,"nombre":"Garmendia ","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7547,"nombre":"Garmendia Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.85370314727128,"longitud":-65.18561896564643,"ranking":0},
{"id":7555,"nombre":"Burruyaco","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.50057336937556,"longitud":-64.74213118497181,"ranking":0},
{"id":4353,"nombre":"Ibarra Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4354,"nombre":"Vatteone Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7513,"nombre":"Villa Burruyaco","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.500641587136354,"longitud":-64.74255671127567,"ranking":0},
{"id":7529,"nombre":"San Patricio","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3258,"nombre":"San Miguel de Tucumán Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.847021657060306,"longitud":-65.19255508320299,"ranking":0},
{"id":4350,"nombre":"Los Cuatro Caminos","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4351,"nombre":"La Manuela Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4352,"nombre":"La Larga Acceso","localidad":"San Miguel de Tucuman","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":18,"nombre":"Ciudad del Este","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.497425,"longitud":-54.666648,"ranking":1431},
{"id":4084,"nombre":"Cruce Km 30","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.48379,"longitud":-54.897909,"ranking":2},
{"id":5126,"nombre":"Ciudad del Este - Area 1","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.518606,"longitud":-54.640826,"ranking":11},
{"id":14231,"nombre":"Km 4","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.5073339,"longitud":-54.6387563,"ranking":0},
{"id":15530,"nombre":"Aeropuerto Ciudad del Este","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.4555,"longitud":-54.843592,"ranking":0},
{"id":15531,"nombre":"Hoteles Ciudad del Este","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":9079,"nombre":"Ciudad del Este - Area 4","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.533968,"longitud":-54.63334,"ranking":24},
{"id":12400,"nombre":"Km 13","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.558759,"longitud":-54.623537,"ranking":0},
{"id":12401,"nombre":"Km 20","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.4892869,"longitud":-54.7940565,"ranking":0},
{"id":14230,"nombre":"Km 7","localidad":"Ciudad del Este","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.497441,"longitud":-54.666661,"ranking":47},
{"id":768,"nombre":"Castelli","localidad":"Castelli","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.0916859,"longitud":-57.8099446,"ranking":120},
{"id":1622,"nombre":"Castelli Acceso","localidad":"Castelli","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.094698,"longitud":-57.811049,"ranking":72},
{"id":19,"nombre":"Córdoba","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.423436,"longitud":-64.174426,"ranking":44929},
{"id":1760,"nombre":"Patria y Rincon","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.405156,"longitud":-64.159429,"ranking":0},
{"id":1769,"nombre":"Santa Rita Norte","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2098,"nombre":"Av. Japon y Juan B Justo","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.33617083465716,"longitud":-64.17590354481611,"ranking":0},
{"id":2099,"nombre":"Fabrica Lia","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.35548586953733,"longitud":-64.17663971165788,"ranking":0},
{"id":3055,"nombre":"City Tour Cordoba","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10730,"nombre":"San Jose de Calasanz 125","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10731,"nombre":"Patio Olmos","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10732,"nombre":"Bv. Illia 454","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10762,"nombre":"KM 105","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10874,"nombre":"Puente 24 de Septiembre","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4159518,"longitud":-64.174071,"ranking":0},
{"id":10724,"nombre":"Av. La Voz del Interior 7551","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10725,"nombre":"Av. La Voz del Interior 6177","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10726,"nombre":"Av. Monseñor P. Cabrera 4231","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10727,"nombre":"Av. Castro Barros 625","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10728,"nombre":"Av. Santa Fe 321","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10729,"nombre":"Av. Colon 1048","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10718,"nombre":"27 de Abril 1116","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10719,"nombre":"Av. Santa Fe 342","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10720,"nombre":"Av. Castro Barros 578","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10721,"nombre":"Av. Monseñor P. Cabrera 3540","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10722,"nombre":"Diario La Voz del Interior","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10723,"nombre":"Hotel Quorum","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10674,"nombre":"Sierras Chicas","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10675,"nombre":"City Tour Cordoba con Manzana Jesuitica","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10676,"nombre":"Alta Gracia y Villa Carlos Paz","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4200042,"longitud":-64.1847719,"ranking":0},
{"id":10715,"nombre":"San Jeronimo 567","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10716,"nombre":"Plaza San Martin","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10717,"nombre":"Palacio 6 de Julio","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10053,"nombre":"Ensenada","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10055,"nombre":"Igualdad","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10669,"nombre":"Tour de Compras Cordoba","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10670,"nombre":"Caminos de la Historia Cordoba","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10672,"nombre":"Estancias Jesuiticas","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9705,"nombre":"KM 907","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9766,"nombre":"La Totorilla","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.86776309138148,"longitud":-64.1664647247198,"ranking":0},
{"id":9782,"nombre":"KM 18","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9839,"nombre":"Cerro Bola","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9846,"nombre":"KM 23","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9929,"nombre":"La Chamaca","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9660,"nombre":"Empalme La Victoria","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9661,"nombre":"El Pedacito","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.715887383,"longitud":-64.027482865,"ranking":0},
{"id":9662,"nombre":"Campo Piedras","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9663,"nombre":"La Posta Norte","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9703,"nombre":"Santa Maria Norte","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9704,"nombre":"KM 881","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8651,"nombre":"Pampa de Olaen","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.07749810224709,"longitud":-64.60021936671112,"ranking":0},
{"id":8652,"nombre":"Quebrada del Condorito","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6199783,"longitud":-64.7080859,"ranking":0},
{"id":8657,"nombre":"Tren de las Sierras","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8726,"nombre":"Ciudad de los Niños - Barrio Norte","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9444,"nombre":"KM 450","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9445,"nombre":"KM 480","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4533,"nombre":"Plaza Velez Sarfield","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.42093587452644,"longitud":-64.18873996171125,"ranking":0},
{"id":8092,"nombre":"Pasco","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.74871338437116,"longitud":-63.34316335768346,"ranking":0},
{"id":8508,"nombre":"Cordoba Centro","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.422775,"longitud":-64.175277,"ranking":1},
{"id":8509,"nombre":"Aeropuerto Ingeniero Ambrosio Taravella","localidad":"Cordoba ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.315779,"longitud":-64.214126,"ranking":0},
{"id":21,"nombre":"Baradero","localidad":"Baradero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.8207,"longitud":-59.514865,"ranking":0},
{"id":1352,"nombre":"Baradero Acceso","localidad":"Baradero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.79118726668795,"longitud":-59.5081800480111,"ranking":0},
{"id":649,"nombre":"Gualeguay","localidad":"Gualeguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.132144,"longitud":-59.316398,"ranking":11},
{"id":1812,"nombre":"Gualeguay Acceso","localidad":"Gualeguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.126386792381794,"longitud":-59.32345300899232,"ranking":0},
{"id":388,"nombre":"Gualeguaychu Parador","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.042738,"longitud":-58.620417,"ranking":102},
{"id":1815,"nombre":"Berisso Acceso","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.227941144871885,"longitud":-59.24706975366942,"ranking":0},
{"id":1817,"nombre":"Colonia Oficial","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1822,"nombre":"Gualeguaychu Acceso Norte","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.03922634826929,"longitud":-58.616697333466234,"ranking":0},
{"id":1823,"nombre":"Gualeguaychu Acceso Sur","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.16371371433248,"longitud":-59.27308076766868,"ranking":0},
{"id":1824,"nombre":"Medanos Acceso","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.437561709662155,"longitud":-59.05891452859134,"ranking":0},
{"id":15466,"nombre":"Termas de Gualeguaychu ","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.0165068,"longitud":-58.4805584,"ranking":0},
{"id":15467,"nombre":"Termas de Guaychu","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.9716103,"longitud":-58.5930674,"ranking":0},
{"id":3429,"nombre":"Gualeguaychu","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.020872,"longitud":-58.534887,"ranking":10},
{"id":4092,"nombre":"YPF 5 Esquinas","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12370,"nombre":"Aldea San Juan","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12371,"nombre":"Escriña","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1825,"nombre":"Ñancay","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1826,"nombre":"Parador El Tague","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.0431743,"longitud":-58.6204445,"ranking":2},
{"id":1827,"nombre":"Perdices","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.29953483587165,"longitud":-58.71142912275043,"ranking":0},
{"id":1828,"nombre":"Puente Internacional General San Martin","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.05687585696338,"longitud":-58.257224504197964,"ranking":0},
{"id":1829,"nombre":"Rotonda Gualeguaychu","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.042512,"longitud":-58.620077,"ranking":8},
{"id":1830,"nombre":"Sauce","localidad":"Gualeguaychu","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":442,"nombre":"San Rafael","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.605234,"longitud":-68.326929,"ranking":17741},
{"id":3286,"nombre":"Parque de la Aventura","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3322,"nombre":"Casa Juri","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.7483931,"longitud":-68.1477278,"ranking":13},
{"id":3327,"nombre":"El Sosneado","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.080529,"longitud":-69.582623,"ranking":19},
{"id":3867,"nombre":"Cañon del Atuel","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.84645417913067,"longitud":-68.51100761711126,"ranking":0},
{"id":4291,"nombre":"Risco Viajes - Avenida Hipólito Yrigoyen 284","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.614757307761195,"longitud":-68.3332336032991,"ranking":0},
{"id":9201,"nombre":"Cuesta de los Terneros","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10903,"nombre":"Aeropuerto de San Rafael","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.585753,"longitud":-68.403797,"ranking":0},
{"id":10904,"nombre":"Hoteles Centro San Rafael","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.6178141,"longitud":-68.3348783,"ranking":0},
{"id":4292,"nombre":"Los Reyunos","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.60611991475428,"longitud":-68.60811710357666,"ranking":0},
{"id":4548,"nombre":"Villa 25 de Mayo","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.5811933,"longitud":-68.5385478,"ranking":0},
{"id":4626,"nombre":"San Rafael Acceso","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.631203524901935,"longitud":-68.26932161268492,"ranking":0},
{"id":7580,"nombre":"San rafael Aept","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7642,"nombre":"Monte Coman Acceso","localidad":"San Rafael","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.59232092572001,"longitud":-67.87856557198782,"ranking":0},
{"id":26,"nombre":"Rio Cuarto","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.114091,"longitud":-64.351816,"ranking":7602},
{"id":2118,"nombre":"Rio Cuarto Universidad","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.10997907611309,"longitud":-64.30177171027827,"ranking":0},
{"id":3154,"nombre":"Rio Cuarto Acceso","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0807383,"longitud":-64.3398035,"ranking":7},
{"id":4250,"nombre":"Santa Catalina","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4297,"nombre":"Santa Catalina Acceso","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.1978385,"longitud":-64.4236378,"ranking":0},
{"id":9932,"nombre":"Las Cañitas","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.8690965,"longitud":-64.7498828,"ranking":0},
{"id":9934,"nombre":"Los Chañares","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.49335709999999,"longitud":-64.1807942,"ranking":0},
{"id":10056,"nombre":"Chacra Moreti","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10057,"nombre":"Esquinazo","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7911,"nombre":"Espinillo ","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.98473543376545,"longitud":-64.34921938769392,"ranking":0},
{"id":8736,"nombre":"Altos Fierro Acceso","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.65211273750909,"longitud":-64.31526835077628,"ranking":0},
{"id":8739,"nombre":"Rio Cuarto centro","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9763,"nombre":"La Aguada","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9931,"nombre":"Santa Rita","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.1839434,"longitud":-63.3234853,"ranking":0},
{"id":7856,"nombre":"Plaza de Mercedes","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7880,"nombre":"Gral. Soler","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7883,"nombre":"Monte de los Gauchos","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.6395677,"longitud":-63.8893888,"ranking":4},
{"id":7895,"nombre":"Colonia La Argentina","localidad":"Rio Cuarto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":916,"nombre":"San Nicolas","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.343784,"longitud":-60.225893,"ranking":19},
{"id":1362,"nombre":"Parador Figheras","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.1988683392113,"longitud":-60.491389126582426,"ranking":0},
{"id":2068,"nombre":"Parador San Nicolas","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.3636712,"longitud":-60.2525425,"ranking":1645},
{"id":2074,"nombre":"Sanchez","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3870,"nombre":"Virgen de San Nicolas","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14813,"nombre":"San Nicolas Acceso","localidad":"San Nicolas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.3858643,"longitud":-60.2173712,"ranking":0},
{"id":29,"nombre":"Alta Gracia","localidad":"Alta Gracia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.654745,"longitud":-64.446523,"ranking":1002},
{"id":8511,"nombre":"Alta Gracia Museos","localidad":"Alta Gracia","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":165,"nombre":"Cosquin","localidad":"Cosquin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.243843,"longitud":-64.467165,"ranking":700},
{"id":8763,"nombre":"Hoteles Cosquin","localidad":"Cosquin","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":32,"nombre":"Jesus Maria","localidad":"Jesus Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.980199,"longitud":-64.093801,"ranking":561},
{"id":3042,"nombre":"Jesus Maria Acceso","localidad":"Jesus Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9912773,"longitud":-64.097158,"ranking":0},
{"id":30,"nombre":"Mina Clavero","localidad":"Mina Clavero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.724061,"longitud":-65.006102,"ranking":225},
{"id":9440,"nombre":"Cañada Larga Acceso","localidad":"Mina Clavero","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9786,"nombre":"Cañada Larga ","localidad":"Mina Clavero","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":31,"nombre":"Villa Carlos Paz","localidad":"Villa Carlos Paz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.419192,"longitud":-64.493281,"ranking":2590},
{"id":12257,"nombre":"Villa Hayes","localidad":"Villa Hayes","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-23.398471,"longitud":-57.444575,"ranking":0},
{"id":131,"nombre":"Adrogue","localidad":"Adrogue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7977124,"longitud":-58.3890889,"ranking":65},
{"id":1476,"nombre":"Alberti","localidad":"Alberti","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.053265400,"longitud":-60.262606100,"ranking":0},
{"id":1477,"nombre":"Indacoechea Acceso","localidad":"Alberti","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.05493196923578,"longitud":-60.06085914794327,"ranking":0},
{"id":1479,"nombre":"Larrea Acceso","localidad":"Alberti","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.07132081984777,"longitud":-60.34891236708636,"ranking":0},
{"id":7947,"nombre":"Alberti Acceso","localidad":"Alberti","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.05332815249744,"longitud":-60.264586094229614,"ranking":0},
{"id":711,"nombre":"Ameghino","localidad":"Ameghino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5687743,"longitud":-58.6934145,"ranking":36},
{"id":2431,"nombre":"Ameghino Acceso","localidad":"Ameghino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.864754064114834,"longitud":-62.46269991291194,"ranking":0},
{"id":74,"nombre":"Arrecifes","localidad":"Arrecifes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.064356,"longitud":-60.10367,"ranking":120},
{"id":4181,"nombre":"Arrecifes Acceso","localidad":"Arrecifes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.06346800112077,"longitud":-60.076908191242914,"ranking":0},
{"id":527,"nombre":"Avellaneda","localidad":"Avellaneda","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6594535,"longitud":-58.3769335,"ranking":154},
{"id":644,"nombre":"Azul","localidad":"Azul","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.783558,"longitud":-59.851771,"ranking":1686},
{"id":1481,"nombre":"Azul Acceso","localidad":"Azul","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.7915518,"longitud":-59.8410243,"ranking":0},
{"id":10660,"nombre":"Parador La Estrella","localidad":"Azul","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.791493,"longitud":-59.8394287,"ranking":0},
{"id":556,"nombre":"Bahia Blanca","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.735982,"longitud":-62.246875,"ranking":9783},
{"id":1482,"nombre":"Base Naval","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.883737984538165,"longitud":-62.096437016925755,"ranking":0},
{"id":7917,"nombre":"Parque de Mayo","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7927,"nombre":"Berraondo","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.41036444431239,"longitud":-62.80748072961034,"ranking":0},
{"id":7930,"nombre":"Alferes San Martin","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.63237575195158,"longitud":-62.52813467187635,"ranking":0},
{"id":9836,"nombre":"Parador Alem","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10111,"nombre":"General Cerri","localidad":"Bahia Blanca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.6985268,"longitud":-62.3557286,"ranking":0},
{"id":1428,"nombre":"Bragado Acceso","localidad":"Bragado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.137317600,"longitud":-60.452377800,"ranking":31},
{"id":1488,"nombre":"Bragado","localidad":"Bragado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.112326,"longitud":-60.490408,"ranking":107},
{"id":1489,"nombre":"Olascoaga","localidad":"Bragado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.238100328512544,"longitud":-60.613099422722904,"ranking":0},
{"id":54,"nombre":"Rotonda Los Pinos","localidad":"Burzaco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8356059,"longitud":-58.4015715,"ranking":154},
{"id":3642,"nombre":"Rotonda Vapor","localidad":"Burzaco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.319599,"longitud":-57.692864,"ranking":62},
{"id":9080,"nombre":"Burzaco Centro","localidad":"Burzaco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8361157,"longitud":-58.4016182,"ranking":0},
{"id":1519,"nombre":"Colon","localidad":"Colon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.905391,"longitud":-61.108834,"ranking":94},
{"id":1520,"nombre":"El Arbolito Acceso","localidad":"Colon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.8978258,"longitud":-61.1198783,"ranking":0},
{"id":5479,"nombre":"Colon Acceso","localidad":"Colon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.9056985,"longitud":-61.1095195,"ranking":0},
{"id":626,"nombre":"Coronel Pringles","localidad":"Coronel Pringles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.991275,"longitud":-61.349285,"ranking":238},
{"id":2412,"nombre":"Coronel Pringles Acceso","localidad":"Coronel Pringles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.979606,"longitud":-61.326333,"ranking":0},
{"id":2445,"nombre":"Rotonda Suarez Pringles","localidad":"Coronel Pringles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.99873743061826,"longitud":-61.33281004824458,"ranking":0},
{"id":7725,"nombre":"Indio Rico Acceso","localidad":"Coronel Pringles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.22962218030571,"longitud":-60.80118522834958,"ranking":0},
{"id":932,"nombre":"Coronel Suarez","localidad":"Coronel Suarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.4536716,"longitud":-61.924193,"ranking":281},
{"id":171,"nombre":"Ezeiza","localidad":"Ezeiza","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.855545,"longitud":-58.5243174,"ranking":346},
{"id":7756,"nombre":"Ezeiza Acceso","localidad":"Ezeiza","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.78541264016619,"longitud":-58.521466450535854,"ranking":0},
{"id":8609,"nombre":"Aeropuerto Internacional de Ezeiza- Mtro Pistarini","localidad":"Ezeiza","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.812178,"longitud":-58.541446,"ranking":72},
{"id":56,"nombre":"Florencio Varela","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7804245,"longitud":-58.262157,"ranking":169},
{"id":739,"nombre":"Rotonda Alpargatas","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.779832,"longitud":-58.262676,"ranking":39},
{"id":1528,"nombre":"Colonia Urquiza","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9407697,"longitud":-58.1133320,"ranking":0},
{"id":4093,"nombre":"Cruce Florencio Varela","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7800617,"longitud":-58.2624139,"ranking":295},
{"id":7760,"nombre":"Bosques","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.82582120413692,"longitud":-58.2245964639702,"ranking":0},
{"id":12139,"nombre":"Ingeniero Juan Allan","localidad":"Florencio Varela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.85942060000001,"longitud":-58.2157411,"ranking":0},
{"id":484,"nombre":"Junin","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.588959,"longitud":-60.949428,"ranking":1833},
{"id":1338,"nombre":"Junin Acceso","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.60822127933854,"longitud":-60.95932534986432,"ranking":0},
{"id":1624,"nombre":"Baigorrita","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.74507745827479,"longitud":-60.98830927964936,"ranking":0},
{"id":1625,"nombre":"Zaballia","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7978,"nombre":"Agustin Roca Acceso ","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.50676404129808,"longitud":-60.866055405490066,"ranking":0},
{"id":10831,"nombre":"Agustin Roca","localidad":"Junin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":205,"nombre":"Lujan","localidad":"Lujan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.559753,"longitud":-59.119887,"ranking":499},
{"id":1652,"nombre":"Jauregui","localidad":"Lujan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.600788464563124,"longitud":-59.1735937258679,"ranking":0},
{"id":1654,"nombre":"Lujan Acceso","localidad":"Lujan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.583377900,"longitud":-59.101909700,"ranking":10},
{"id":7536,"nombre":"Cortines","localidad":"Lujan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.55287747175862,"longitud":-59.19554419148279,"ranking":0},
{"id":14611,"nombre":"Universidad de Lujan","localidad":"Lujan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5772414,"longitud":-59.0892731,"ranking":0},
{"id":208,"nombre":"Mar de Ajo","localidad":"Mar de Ajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.733003,"longitud":-56.695151,"ranking":575},
{"id":308,"nombre":"Merlo","localidad":"Merlo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.664611,"longitud":-58.729619,"ranking":383},
{"id":725,"nombre":"Miramar","localidad":"Miramar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.262934,"longitud":-57.843129,"ranking":268},
{"id":38,"nombre":"Moreno","localidad":"Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.647941000,"longitud":-58.794151000,"ranking":398},
{"id":218,"nombre":"Moreno Centro","localidad":"Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2888,"nombre":"Moreno Parador ","localidad":"Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6347837,"longitud":-58.7871015,"ranking":115},
{"id":3040,"nombre":"Cruce Castelar","localidad":"Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5851092,"longitud":-58.7490195,"ranking":119},
{"id":50,"nombre":"Moron","localidad":"Moron","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6490832,"longitud":-58.6172454,"ranking":58},
{"id":1447,"nombre":"Moron Terminal","localidad":"Moron","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6482495,"longitud":-58.609792,"ranking":369},
{"id":4711,"nombre":"Moron Shopping","localidad":"Moron","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.633918,"longitud":-58.629493,"ranking":0},
{"id":14244,"nombre":"Cementerio de Moron","localidad":"Moron","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6608646,"longitud":-58.62499769999999,"ranking":0},
{"id":224,"nombre":"Necochea","localidad":"Necochea","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.54839,"longitud":-58.730956,"ranking":1865},
{"id":1449,"nombre":"El Motivo","localidad":"Pacheco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.471973,"longitud":-58.656121,"ranking":12},
{"id":4763,"nombre":"El Talar","localidad":"Pacheco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.472769,"longitud":-58.65638,"ranking":5356},
{"id":15675,"nombre":"General Pacheco Acceso (YPF)","localidad":"Pacheco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4388716,"longitud":-58.7055228,"ranking":0},
{"id":75,"nombre":"Pergamino","localidad":"Pergamino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.913644,"longitud":-60.586542,"ranking":486},
{"id":2028,"nombre":"Fontezuela","localidad":"Pergamino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.9142884,"longitud":-60.5762583,"ranking":0},
{"id":2029,"nombre":"Maguirre Acceso","localidad":"Pergamino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.9142802,"longitud":-60.5760673,"ranking":0},
{"id":3424,"nombre":"Pergamino Acceso","localidad":"Pergamino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.932631534393664,"longitud":-60.56541924233629,"ranking":0},
{"id":7982,"nombre":"Basualdo","localidad":"Pergamino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.035387630608746,"longitud":-60.633079141722405,"ranking":0},
{"id":935,"nombre":"Puente La Noria","localidad":"Puente La Noria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.704946600,"longitud":-58.459850500,"ranking":159},
{"id":1366,"nombre":"La Noria - Zacarias","localidad":"Puente La Noria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.708616,"longitud":-58.45818,"ranking":135},
{"id":4735,"nombre":"Transradio","localidad":"Puente La Noria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":557,"nombre":"Punta Alta","localidad":"Punta Alta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.877049,"longitud":-62.070873,"ranking":744},
{"id":2058,"nombre":"San Fernando","localidad":"San Fernando","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4365842,"longitud":-58.5683061,"ranking":0},
{"id":9970,"nombre":"Aeropuerto Internacional de San Fernando","localidad":"San Fernando","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10374,"nombre":"Marina Punta Chica - San Fernando","localidad":"San Fernando","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":58,"nombre":"San Justo","localidad":"San Justo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6277955,"longitud":-58.5673345,"ranking":66},
{"id":1063,"nombre":"Rotonda San Justo","localidad":"San Justo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6854435,"longitud":-58.5605888,"ranking":528},
{"id":1635,"nombre":"Rotonda II San Justo","localidad":"San Justo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6854435,"longitud":-58.5605888,"ranking":0},
{"id":8081,"nombre":"San Justo Centro","localidad":"San Justo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6764791,"longitud":-58.5646627,"ranking":1},
{"id":40,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5331954,"longitud":-58.7023544,"ranking":175},
{"id":3457,"nombre":"Rotonda San Miguel","localidad":"San Miguel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5331243,"longitud":-58.7023397,"ranking":42},
{"id":9062,"nombre":"Santa Brigida","localidad":"San Miguel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14492,"nombre":"Barrio Sarmiento","localidad":"San Miguel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5690162,"longitud":-58.74261909999999,"ranking":0},
{"id":518,"nombre":"Tandil","localidad":"Tandil","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.320812,"longitud":-59.117382,"ranking":4648},
{"id":3656,"nombre":"Tandil Acceso","localidad":"Tandil","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1432,"nombre":"Trenque Lauquen","localidad":"Trenque Lauquen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.97806,"longitud":-62.729002,"ranking":466},
{"id":1436,"nombre":"Trenque Lauquen Acceso","localidad":"Trenque Lauquen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.0008024,"longitud":-62.7212262,"ranking":83},
{"id":2083,"nombre":"30 de Agosto Acceso","localidad":"Trenque Lauquen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.2806262,"longitud":-62.55615609999999,"ranking":0},
{"id":2085,"nombre":"La Porteña","localidad":"Trenque Lauquen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.36536464771062,"longitud":-62.71933175177838,"ranking":0},
{"id":10805,"nombre":"La Porteña Acceso","localidad":"Trenque Lauquen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.3428139,"longitud":-62.6782926,"ranking":0},
{"id":276,"nombre":"Villa Gesell","localidad":"Villa Gesell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.281889,"longitud":-57.007052,"ranking":1572},
{"id":1348,"nombre":" Los Pinos","localidad":"Villa Gesell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1703,"nombre":"Mar Azul","localidad":"Villa Gesell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.33062018671767,"longitud":-57.06076937388613,"ranking":0},
{"id":3106,"nombre":"Mar Azul Acceso","localidad":"Villa Gesell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.333712,"longitud":-57.043459,"ranking":14},
{"id":10816,"nombre":"Mar de las Pampas","localidad":"Villa Gesell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.31874130000001,"longitud":-57.0140782,"ranking":0},
{"id":443,"nombre":"General Alvear","localidad":"General Alvear","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.978982,"longitud":-67.693717,"ranking":2437},
{"id":10100,"nombre":"General Alvear Acceso","localidad":"General Alvear","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.9778194310511,"longitud":-67.66366644636332,"ranking":0},
{"id":315,"nombre":"Mendoza","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.894114,"longitud":-68.830038,"ranking":28275},
{"id":3283,"nombre":"Hotel NH Cordillera","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88753519999999,"longitud":-68.8414516,"ranking":0},
{"id":3321,"nombre":"Terneros","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.6973901,"longitud":-68.5597109,"ranking":0},
{"id":3323,"nombre":"Cohihueco","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.4874777,"longitud":-69.5990613,"ranking":0},
{"id":15500,"nombre":"Termas de Cacheuta","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15645,"nombre":"Valle de Uco","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14453,"nombre":"Wine and Deli","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8864115,"longitud":-68.8605568,"ranking":0},
{"id":14454,"nombre":"Apart Hotel Soltigua","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8837763,"longitud":-68.8577699,"ranking":0},
{"id":14455,"nombre":"Club Tapiz","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0080437,"longitud":-68.8286067,"ranking":0},
{"id":14456,"nombre":"Museo Carlos Alonso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8880853,"longitud":-68.8546126,"ranking":0},
{"id":14467,"nombre":"Club Tapiz (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0080437,"longitud":-68.8286067,"ranking":0},
{"id":10864,"nombre":"Aeropuerto de Mendoza","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.827669,"longitud":-68.803316,"ranking":0},
{"id":10870,"nombre":"Dique Benegas","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.2514296,"longitud":-68.6809588,"ranking":0},
{"id":10899,"nombre":"Lac Turismo -  Las Heras 580","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.884991,"longitud":-68.845423,"ranking":0},
{"id":10922,"nombre":"Valle de Uco Sur FD","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14452,"nombre":"Chill Inn Hostel","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.891937,"longitud":-68.8562199,"ranking":0},
{"id":8976,"nombre":"Hotel Hathor (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8977,"nombre":"Hotel Esplendor- Arena Maipu (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.97714932189112,"longitud":-68.79822506323924,"ranking":0},
{"id":8978,"nombre":"Acceso Sur y Araoz","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.00433649083962,"longitud":-68.85259668920936,"ranking":0},
{"id":10471,"nombre":"Hoteles Centro de Mendoza","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.888919635234274,"longitud":-68.84429385449161,"ranking":0},
{"id":10840,"nombre":"Aeropuerto de Mendoza Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.827705,"longitud":-68.803297,"ranking":0},
{"id":8744,"nombre":"Sarmiento Esq. Chile (Plaza Independencia)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.889438896902845,"longitud":-68.84585535525177,"ranking":0},
{"id":8750,"nombre":"Las Heras 699","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.891157,"longitud":-68.818526,"ranking":1},
{"id":8751,"nombre":"Vicente Zapata 283","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8973,"nombre":"Hotel Hathor (Mañana)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.90602585833966,"longitud":-68.8194255738719,"ranking":0},
{"id":8974,"nombre":"Hotel Amerian Executive","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8975,"nombre":"Hotel Esplendor- Arena Maipu (Mañana)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8653,"nombre":"Camino del Vino - El Rio Half Day (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8654,"nombre":"Camino del Vino - El Rio Half Day (Mañana)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8655,"nombre":"Camino del Vino - El Rio Full Day","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8665,"nombre":"Hotel Argentino","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.886816831848925,"longitud":-68.84502005008319,"ranking":0},
{"id":8717,"nombre":"Camino del Vino  Valle de Uco - Full Day","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.4568932,"longitud":-69.2126177,"ranking":0},
{"id":8742,"nombre":"City Tour Mendoza","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7338,"nombre":"Peatonal - San Martín 1070","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.89096223111698,"longitud":-68.83963694993815,"ranking":0},
{"id":7339,"nombre":"Magia del Vino","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7381,"nombre":"Los Puestos","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7605,"nombre":"Puente Azcuenaga ","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0356345,"longitud":-68.8659199,"ranking":2},
{"id":8268,"nombre":"Pedro Vargas","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8270,"nombre":"Los Huarpes Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5763,"nombre":"Hotel Fuente Mayor","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.887716,"longitud":-68.8465351,"ranking":0},
{"id":5764,"nombre":"Hotel Villaggio","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.89010289362328,"longitud":-68.84745688506372,"ranking":0},
{"id":5765,"nombre":"Centro Informacion Turistica (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5766,"nombre":"Hotel InterContinental (Tarde)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7335,"nombre":"Alta Montaña","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7337,"nombre":"Plaza Independencia Calle Chile 1119 (Escudo)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.889081052099364,"longitud":-68.84552971049541,"ranking":0},
{"id":4549,"nombre":"Hotel Diplomatic","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88928163902127,"longitud":-68.84955883026123,"ranking":0},
{"id":4772,"nombre":"Mendoza Centro","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5432,"nombre":"Hotel Aconcagua","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.89235,"longitud":-68.8471768,"ranking":0},
{"id":5433,"nombre":"Hotel MOD","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88937439445281,"longitud":-68.83284805718641,"ranking":0},
{"id":5434,"nombre":"Edificio Recoleta Colon","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8939566639673,"longitud":-68.84630207169876,"ranking":0},
{"id":3850,"nombre":"CATA Turismo","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88471967965784,"longitud":-68.84604014061168,"ranking":0},
{"id":3855,"nombre":"Inda Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4085,"nombre":"Mendoza Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4268,"nombre":"Gio Bar - Francesco Rist.  ","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4427,"nombre":"Hotel Hyatt","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88909401934019,"longitud":-68.84588674949744,"ranking":0},
{"id":4455,"nombre":"Phillips","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.20189308544427,"longitud":-68.38039118264099,"ranking":0},
{"id":3823,"nombre":"Los Pocitos Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3825,"nombre":"Plaza Chacras de Coria","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.986390746638534,"longitud":-68.88213830481477,"ranking":0},
{"id":3827,"nombre":"Hotel InterContinental (Mañana)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3838,"nombre":"Centro Informacion Turistica (Mañana)","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3843,"nombre":"Casa Juri Acceso","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3846,"nombre":"Hotel Sheraton- Hotel Huentala","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8920303,"longitud":-68.8381252,"ranking":0},
{"id":3404,"nombre":"Galpon de Lata","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.7472159,"longitud":-68.1933246,"ranking":0},
{"id":3405,"nombre":"Esc. Sec. Martin Guemes","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.048194750445,"longitud":-68.558346062974,"ranking":0},
{"id":3406,"nombre":"Esc. P. de los Andes","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3751,"nombre":"Cristo de la Quebrada","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3752,"nombre":"Hostel Campo Base - Avenida Bartolomé Mitre 946","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8913136757012,"longitud":-68.84514023544772,"ranking":0},
{"id":3753,"nombre":"Hotel Internacional","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8891521,"longitud":-68.84868039999999,"ranking":0},
{"id":3398,"nombre":"Paso nivel El Nihuil","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.0270509,"longitud":-68.6764922,"ranking":0},
{"id":3399,"nombre":"Paso nivel Monte Com","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3400,"nombre":"Puente del Rio","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3401,"nombre":"Punta Calle Larga","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.7483931,"longitud":-68.1477278,"ranking":0},
{"id":3402,"nombre":"Puente Llave Vieja","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3403,"nombre":"Linea de Palos","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3385,"nombre":"Estacion la Sal","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3387,"nombre":"Escalera Algarrobal","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.81701635450055,"longitud":-68.76139760326637,"ranking":0},
{"id":3388,"nombre":"Escuela Lopez","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3389,"nombre":"Colonia Lopez","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.8573901,"longitud":-67.867008,"ranking":6},
{"id":3396,"nombre":"R. Iselin","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3397,"nombre":"Vialidad","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3378,"nombre":"Chalet sin techo","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3380,"nombre":"Colomer","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3381,"nombre":"Coronel Rodriguez","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3382,"nombre":"C. Rubio","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3383,"nombre":"Cañada Seca","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.41700331872727,"longitud":-62.961351316489896,"ranking":0},
{"id":3384,"nombre":"El Chacai","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.3585427,"longitud":-69.5837152,"ranking":0},
{"id":3325,"nombre":"Desvio El Nihuil","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.0226174,"longitud":-68.6773621,"ranking":11},
{"id":3326,"nombre":"El Chacay","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.3585427,"longitud":-69.5837152,"ranking":0},
{"id":3328,"nombre":"Difunta Correa y San Expedito","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":3334,"nombre":"Los Pocitos","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.0315436,"longitud":-69.3968363,"ranking":0},
{"id":3336,"nombre":"Bodegas Maipu","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3377,"nombre":"Avenida Mitre","localidad":"Mendoza","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":330,"nombre":"San Martin","localidad":"San Martin","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.08596,"longitud":-68.464552,"ranking":944},
{"id":3666,"nombre":"San Martin Acceso","localidad":"San Martin","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1092,"nombre":"Aguilares","localidad":"Aguilares","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.440204,"longitud":-65.614264,"ranking":70},
{"id":3676,"nombre":"Aguilares Acceso","localidad":"Aguilares","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.425614,"longitud":-65.609088,"ranking":0},
{"id":1091,"nombre":"Concepción","localidad":"Concepcion","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.353294,"longitud":-65.595705,"ranking":91},
{"id":4138,"nombre":"Concepcion Acceso","localidad":"Concepcion","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.357108130555975,"longitud":-65.5872185176738,"ranking":0},
{"id":1089,"nombre":"Famailla","localidad":"Famailla","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.055596,"longitud":-65.400893,"ranking":6},
{"id":1093,"nombre":"Alberdi J. B.","localidad":"Juan Bautista Alberdi","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.588305,"longitud":-65.619795,"ranking":23},
{"id":4335,"nombre":"Alberdi J. B. Acceso","localidad":"Juan Bautista Alberdi","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9759,"nombre":"Av Sarmiento y Quintero","localidad":"Juan Bautista Alberdi","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.588272688269097,"longitud":-65.61979475051706,"ranking":0},
{"id":1090,"nombre":"Monteros","localidad":"Monteros","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.170307,"longitud":-65.494635,"ranking":35},
{"id":95,"nombre":"Añatuya","localidad":"Añatuya","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.4590627,"longitud":-62.8427203,"ranking":85},
{"id":1538,"nombre":"La Vina","localidad":"Añatuya","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.458916263735336,"longitud":-62.84263487056248,"ranking":0},
{"id":110,"nombre":"Bandera","localidad":"Bandera","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.8851807,"longitud":-62.2673933,"ranking":0},
{"id":97,"nombre":"Colonia Dora","localidad":"Colonia Dora","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.602543,"longitud":-62.949826,"ranking":84},
{"id":2908,"nombre":"Colonia Dora Acceso","localidad":"Colonia Dora","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.6025593,"longitud":-62.9495667,"ranking":0},
{"id":633,"nombre":"Fernandez","localidad":"Fernandez","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.925409,"longitud":-63.890846,"ranking":19},
{"id":1641,"nombre":"Fernandez Acceso","localidad":"Fernandez","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.912784368368776,"longitud":-63.89087779395591,"ranking":0},
{"id":1534,"nombre":"Frias","localidad":"Frias","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.627399,"longitud":-65.128736,"ranking":84},
{"id":629,"nombre":"Herrera","localidad":"Herrera","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.4796,"longitud":-63.067614,"ranking":46},
{"id":1521,"nombre":"Herrera Acceso","localidad":"Herrera","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.4806144,"longitud":-63.06865509999999,"ranking":0},
{"id":3158,"nombre":"Pozo Herrera Acceso","localidad":"Herrera","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":98,"nombre":"Icaño","localidad":"Icaño","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.6783605,"longitud":-62.8864162,"ranking":9},
{"id":1526,"nombre":"Icano Acceso","localidad":"Icaño","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.68032742094227,"longitud":-62.883070841004745,"ranking":0},
{"id":525,"nombre":"La Banda","localidad":"La Banda","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.735502,"longitud":-64.249376,"ranking":448},
{"id":1358,"nombre":"La Banda Acceso","localidad":"La Banda","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.722175866375846,"longitud":-64.21831638200962,"ranking":0},
{"id":3947,"nombre":"Lavalle","localidad":"Lavalle","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.195224,"longitud":-65.105052,"ranking":79},
{"id":109,"nombre":"Los Juries","localidad":"Los Juries","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.46555,"longitud":-62.108537,"ranking":0},
{"id":1129,"nombre":"Monte Quemado","localidad":"Monte Quemado","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-25.802537800,"longitud":-62.831754700,"ranking":26},
{"id":318,"nombre":"Ojo de Agua","localidad":"Ojo de Agua","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.498986,"longitud":-63.697909,"ranking":350},
{"id":3043,"nombre":"Ojo de Agua Acceso","localidad":"Ojo de Agua","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1681,"nombre":"Pampa de los Guanacos","localidad":"Pampa de Los Guanacos","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.231684400,"longitud":-61.842534500,"ranking":12},
{"id":100,"nombre":"Pinto","localidad":"Pinto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.140042,"longitud":-62.649833,"ranking":7},
{"id":1629,"nombre":"Parador Pinto","localidad":"Pinto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.143933,"longitud":-62.64734,"ranking":2},
{"id":4027,"nombre":"Pinto Acceso","localidad":"Pinto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":91,"nombre":"Quimili","localidad":"Quimili","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.644914,"longitud":-62.413445,"ranking":30},
{"id":3689,"nombre":"Quimili Cruce de Caminos (YPF)","localidad":"Quimili","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7785,"nombre":"Quimili Acceso","localidad":"Quimili","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.6566351,"longitud":-62.4101905,"ranking":0},
{"id":99,"nombre":"Real Sayana","localidad":"Real Sayana","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.8146673,"longitud":-62.84498480000001,"ranking":6},
{"id":1523,"nombre":"Real Sayana Acceso","localidad":"Real Sayana","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.8140651,"longitud":-62.8465219,"ranking":0},
{"id":3655,"nombre":"Tapso","localidad":"Tapso","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.402669200,"longitud":-65.091397800,"ranking":0},
{"id":7636,"nombre":"Tapso Acceso","localidad":"Tapso","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.402673408776828,"longitud":-65.09190773011126,"ranking":0},
{"id":108,"nombre":"Tomas Young","localidad":"Tomas Young","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.59918725044772,"longitud":-62.18528287255665,"ranking":0},
{"id":9164,"nombre":"Tomas Young Acceso","localidad":"Tomas Young","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2827,"nombre":"Tres Pozos","localidad":"Tres Pozos","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1002,"nombre":"Armstrong","localidad":"Armstrong","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7744987,"longitud":-61.6020826,"ranking":0},
{"id":104,"nombre":"Arrufo","localidad":"Arrufo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.232829,"longitud":-61.725887,"ranking":7},
{"id":3890,"nombre":"Arrufo Acceso","localidad":"Arrufo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.23254469999999,"longitud":-61.7263623,"ranking":0},
{"id":1711,"nombre":"Avellaneda","localidad":"Avellaneda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.122976,"longitud":-59.653382,"ranking":39},
{"id":1713,"nombre":"Guadalupe Acceso","localidad":"Avellaneda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.9371382,"longitud":-59.5607419,"ranking":0},
{"id":8037,"nombre":"Avellaneda Acceso","localidad":"Avellaneda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.108056390899453,"longitud":-59.65091698470962,"ranking":0},
{"id":1335,"nombre":"Brinkmann","localidad":"Brinkmann","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.864825,"longitud":-62.035336,"ranking":130},
{"id":384,"nombre":"Cañada Rosquin","localidad":"Cañada Rosquin","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.053491020850856,"longitud":-61.599001382150924,"ranking":0},
{"id":2093,"nombre":"Cañada Rosquin Acceso","localidad":"Cañada Rosquin","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.06309000648043,"longitud":-61.60527839361567,"ranking":0},
{"id":2094,"nombre":"Monigotes","localidad":"Cañada Rosquin","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.488712966549482,"longitud":-61.633699053412485,"ranking":0},
{"id":1206,"nombre":"Carlos Pellegrini","localidad":"Carlos Pellegrini","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.052325445587734,"longitud":-61.78708098372217,"ranking":0},
{"id":1287,"nombre":"Calchaqui(Estacion de Ser.)","localidad":"Calchaqui","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.883376658494004,"longitud":-60.291606576619444,"ranking":0},
{"id":4360,"nombre":"Calchaqui","localidad":"Calchaqui","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.889007,"longitud":-60.280046,"ranking":21},
{"id":9979,"nombre":"Calchaqui Acceso","localidad":"Calchaqui","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.88907,"longitud":-60.280039,"ranking":0},
{"id":103,"nombre":"Ceres","localidad":"Ceres","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.88345,"longitud":-61.944129,"ranking":10},
{"id":1752,"nombre":"Ceres Acceso","localidad":"Ceres","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.876646188840805,"longitud":-61.938713878815925,"ranking":1},
{"id":1754,"nombre":"Parador Ceres","localidad":"Ceres","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.870408,"longitud":-61.941884,"ranking":8},
{"id":2577,"nombre":"Colonia Aldao","localidad":"Colonia Aldao","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":340,"nombre":"Crespo","localidad":"Crespo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":326,"nombre":"Esperanza","localidad":"Esperanza","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.453291,"longitud":-60.931259,"ranking":218},
{"id":3900,"nombre":"Esperanza Acceso","localidad":"Esperanza","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.45296279305173,"longitud":-60.88756354718615,"ranking":3},
{"id":2578,"nombre":"Eusebia","localidad":"Eusebia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.94451459999999,"longitud":-61.8585802,"ranking":0},
{"id":2481,"nombre":"Firmat","localidad":"Firmat","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.455146,"longitud":-61.485357,"ranking":13},
{"id":4182,"nombre":"Firmat Acceso","localidad":"Firmat","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.45038,"longitud":-61.491741,"ranking":5},
{"id":349,"nombre":"Florencia","localidad":"Florencia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.042607457336562,"longitud":-59.22419817685744,"ranking":0},
{"id":9980,"nombre":"Florencia Acceso","localidad":"Florencia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.040357,"longitud":-59.223551,"ranking":59},
{"id":1332,"nombre":"Humboldt Acceso","localidad":"Humboldt","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.396873,"longitud":-61.085702,"ranking":1},
{"id":8396,"nombre":"Humboldt ","localidad":"Humboldt","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.4468503,"longitud":-60.9404498,"ranking":0},
{"id":7462,"nombre":"Las Rosas","localidad":"Las Rosas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.4756788,"longitud":-61.5789213,"ranking":0},
{"id":348,"nombre":"Las Toscas","localidad":"Las Toscas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.34804,"longitud":-59.264173,"ranking":165},
{"id":4413,"nombre":"Las Toscas Acceso","localidad":"Las Toscas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.364084,"longitud":-59.263908,"ranking":1},
{"id":343,"nombre":"Malabrigo","localidad":"Malabrigo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.358316,"longitud":-59.969694,"ranking":54},
{"id":1716,"nombre":"Malabrigo Acceso","localidad":"Malabrigo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.3445551,"longitud":-59.9684055,"ranking":0},
{"id":342,"nombre":"Margarita","localidad":"Margarita","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.6906113,"longitud":-60.2514749,"ranking":19},
{"id":1331,"nombre":"Nuevo Torino Acceso","localidad":"Nuevo Torino","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.345086,"longitud":-61.235143,"ranking":7},
{"id":3482,"nombre":"Nuevo Torino","localidad":"Nuevo Torino","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.345032700374357,"longitud":-61.235276301757466,"ranking":0},
{"id":64,"nombre":"Reconquista","localidad":"Reconquista","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.143777,"longitud":-59.650398,"ranking":650},
{"id":4178,"nombre":"Parador Reconquista","localidad":"Reconquista","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.139411,"longitud":-59.655617,"ranking":0},
{"id":9123,"nombre":"Reconquista Acceso","localidad":"Reconquista","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.154477636123453,"longitud":-59.65790900591078,"ranking":0},
{"id":9915,"nombre":"Aeropuerto de Reconquista Daniel Jukic","localidad":"Reconquista","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.20150945298788,"longitud":-59.69280833295896,"ranking":0},
{"id":46,"nombre":"Rosario","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.939371,"longitud":-60.671065,"ranking":16163},
{"id":637,"nombre":"Circunvalacion y Cordoba","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9316141,"longitud":-60.72135059999999,"ranking":0},
{"id":1387,"nombre":"Rosario Acceso","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.0147371032652,"longitud":-60.66467167142941,"ranking":0},
{"id":1721,"nombre":"Arocena Acceso","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.07407580876859,"longitud":-60.972312368889796,"ranking":0},
{"id":1722,"nombre":"Barrancas - SFE","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1723,"nombre":"Barrancas Acceso","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.23232199486411,"longitud":-60.9935916235783,"ranking":0},
{"id":15513,"nombre":"Rosario Zona Norte","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9247043,"longitud":-60.6807507,"ranking":0},
{"id":15514,"nombre":"Rosario Zona Sur","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9743529,"longitud":-60.6740062,"ranking":0},
{"id":9135,"nombre":"Av Pellegrini y Esq. Santiago","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9541498,"longitud":-60.6586977,"ranking":0},
{"id":9757,"nombre":"Oroño y 27 de Febrero","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.964974,"longitud":-60.658903,"ranking":1},
{"id":10066,"nombre":"Aeropuerto de Rosario - Islas Malvinas","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9169538,"longitud":-60.78039439999999,"ranking":1},
{"id":10067,"nombre":"Rosario Domicilio Pasajero","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10503,"nombre":"Rosario Zona Centro","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9362702,"longitud":-60.6799765,"ranking":1},
{"id":7843,"nombre":"Cuatro Esquinas","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.24795048288829,"longitud":-60.765483254212725,"ranking":0},
{"id":7931,"nombre":"Rosario Circunvalación","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7971,"nombre":"Parador Oroño","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.004011744316465,"longitud":-60.66529772925981,"ranking":0},
{"id":9072,"nombre":"Virasoro","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9134,"nombre":"Av Pellegrini y Alvear","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.95435501686985,"longitud":-60.65741888098981,"ranking":0},
{"id":2370,"nombre":"Oroño y Arijon","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.00399,"longitud":-60.665216,"ranking":122},
{"id":3159,"nombre":"Arijon","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4888,"nombre":"Segui y Oroño","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.97649,"longitud":-60.661756,"ranking":24},
{"id":1736,"nombre":"Oliveros","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1737,"nombre":"Rosario 1 (EPU)","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1738,"nombre":"Salto Grande Acceso","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.66816829874639,"longitud":-61.094876784584876,"ranking":0},
{"id":1739,"nombre":"San Fabian - SFE","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.13831655792309,"longitud":-60.981174828167795,"ranking":0},
{"id":1741,"nombre":"Timbues","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1744,"nombre":"Wilde y Cordoba","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1726,"nombre":"Desvío Arijon","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1727,"nombre":"Fraga y Cordoba","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1732,"nombre":"Gutierrez y Oroño","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.99437919981545,"longitud":-60.66557988541301,"ranking":0},
{"id":1733,"nombre":"Lagos y Arijon","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.00384586389284,"longitud":-60.6760618503251,"ranking":0},
{"id":1734,"nombre":"Lagos y Uriburu","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.991652582877215,"longitud":-60.673165220118904,"ranking":0},
{"id":1735,"nombre":"Monje","localidad":"Rosario","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1010,"nombre":"Rufino","localidad":"Rufino","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.266135,"longitud":-62.703378,"ranking":282},
{"id":1339,"nombre":"Rufino Acceso","localidad":"Rufino","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.27757357533399,"longitud":-62.717007570943856,"ranking":0},
{"id":374,"nombre":"San Cristobal","localidad":"San Cristobal","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.314041,"longitud":-61.234772,"ranking":93},
{"id":1749,"nombre":"Arrufo Acceso","localidad":"San Cristobal","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.22614408389956,"longitud":-61.72894634091082,"ranking":0},
{"id":4442,"nombre":"San Guillermo","localidad":"San Guillermo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.360267209756838,"longitud":-61.910006720934184,"ranking":0},
{"id":1756,"nombre":"Cruce La Criolla","localidad":"San Justo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1757,"nombre":"San Justo - SFE","localidad":"San Justo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.784012,"longitud":-60.59101,"ranking":126},
{"id":1758,"nombre":"San Justo Acceso","localidad":"San Justo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.800825,"longitud":-60.595949,"ranking":0},
{"id":7629,"nombre":"Luciano Leiva","localidad":"San Justo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.86763220765473,"longitud":-60.61808472990261,"ranking":0},
{"id":7630,"nombre":"Ramayon","localidad":"San Justo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":325,"nombre":"Santa Fe","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.643494,"longitud":-60.700654,"ranking":6440},
{"id":1720,"nombre":"Arocena","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.084056636464304,"longitud":-60.977383699632284,"ranking":0},
{"id":1740,"nombre":"Sauce Viejo","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.771097026852154,"longitud":-60.83724844694024,"ranking":0},
{"id":2119,"nombre":"27 de Febrero y Orono","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9649750,"longitud":-60.6585412,"ranking":0},
{"id":2121,"nombre":"Campo Garay","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2122,"nombre":"Canada Rosquin Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.05053538290057,"longitud":-61.59037593208264,"ranking":0},
{"id":14235,"nombre":"Santa Fe Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.628063,"longitud":-60.73118919999999,"ranking":0},
{"id":9180,"nombre":"Catalinas","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9181,"nombre":"Ceibalito","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10037,"nombre":"Colonia San Jose Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.48011973851115,"longitud":-59.585390925493684,"ranking":0},
{"id":10064,"nombre":"Santa Fé Domicilio Pasajero","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2149,"nombre":"Santurce","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2150,"nombre":"Seeber","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.925075735534083,"longitud":-61.973031287117564,"ranking":0},
{"id":3262,"nombre":"Jorge Cura y Lagos","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.969670195377695,"longitud":-60.66821817074834,"ranking":0},
{"id":3641,"nombre":"San Carlos Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.761894527894487,"longitud":-61.10138400481051,"ranking":0},
{"id":4145,"nombre":"Aeropuerto Sauce Viejo - SFE","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4495,"nombre":"Sauce Viejo Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2143,"nombre":"Galvez","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.030503,"longitud":-61.223768,"ranking":0},
{"id":2144,"nombre":"La Cabral","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.087452828174865,"longitud":-61.17822649167944,"ranking":0},
{"id":2145,"nombre":"Las Garzas","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.847043,"longitud":-59.499884,"ranking":17},
{"id":2146,"nombre":"Logroño","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.500692,"longitud":-61.69611,"ranking":0},
{"id":2147,"nombre":"M. Escalada","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.58035480857988,"longitud":-60.467819989001,"ranking":0},
{"id":2148,"nombre":"Puerto San Martin","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7165938,"longitud":-60.7309905,"ranking":0},
{"id":2132,"nombre":"San Bernardo Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.668171034864752,"longitud":-61.52989828683969,"ranking":0},
{"id":2133,"nombre":"San Cristobal Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.296012823316964,"longitud":-61.2231136554071,"ranking":0},
{"id":2137,"nombre":"Uriburu y Orono","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.991452,"longitud":-60.666234,"ranking":6},
{"id":2138,"nombre":"Villa Minetti Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.625556378652266,"longitud":-61.60934585059647,"ranking":0},
{"id":2141,"nombre":"Colonia Silva","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.449581575051713,"longitud":-60.430234934099104,"ranking":0},
{"id":2142,"nombre":"Gaboto","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.435245442733134,"longitud":-60.822546635422086,"ranking":0},
{"id":2123,"nombre":"Esteban Rams Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.771012427497006,"longitud":-61.49430837072109,"ranking":0},
{"id":2125,"nombre":"Independencia","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2126,"nombre":"Laguna Paiva","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.307872438236572,"longitud":-60.66020956548543,"ranking":0},
{"id":2127,"nombre":"Las Avispas Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.89639649190428,"longitud":-61.28538897775754,"ranking":0},
{"id":2129,"nombre":"O. Lagos y 27 de Febrero","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.963383,"longitud":-60.666226,"ranking":2},
{"id":2130,"nombre":"Pozo Borrado Acceso","localidad":"Santa Fe","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.220058199264717,"longitud":-61.758003836967426,"ranking":0},
{"id":1215,"nombre":"Santo Tome","localidad":"Santo Tome","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.666855,"longitud":-60.762271,"ranking":70},
{"id":3290,"nombre":"Santo Tome Acceso","localidad":"Santo Tome","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.70234169890818,"longitud":-60.80056529313543,"ranking":0},
{"id":10065,"nombre":"Santo Tomé Domicilio Pasajero","localidad":"Santo Tome","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4443,"nombre":"Suardi","localidad":"Suardi","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.5347852,"longitud":-61.9592738,"ranking":0},
{"id":327,"nombre":"Sunchales","localidad":"Sunchales ","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.944458,"longitud":-61.557737,"ranking":164},
{"id":4183,"nombre":"Sunchales Acceso","localidad":"Sunchales ","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.941079,"longitud":-61.542719,"ranking":8},
{"id":375,"nombre":"Tostado","localidad":"Tostado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.233603,"longitud":-61.766231,"ranking":120},
{"id":76,"nombre":"Venado Tuerto","localidad":"Venado Tuerto","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.735985,"longitud":-61.975593,"ranking":641},
{"id":2087,"nombre":"Venado Tuerto Acesso","localidad":"Venado Tuerto","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7234500782873,"longitud":-61.99848591286042,"ranking":0},
{"id":344,"nombre":"Vera","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.464795,"longitud":-60.213639,"ranking":71},
{"id":2151,"nombre":"Margarita Acceso","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.690806,"longitud":-60.247837,"ranking":0},
{"id":2152,"nombre":"Vera Acceso","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.4660000,"longitud":-60.2000000,"ranking":0},
{"id":7621,"nombre":"Caraguatay","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7624,"nombre":"Espin","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.53891950130831,"longitud":-60.22110276426366,"ranking":0},
{"id":7631,"nombre":"La Gallareta","localidad":"Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.58439095047716,"longitud":-60.37110968034366,"ranking":0},
{"id":2080,"nombre":"Empalme de Villa Constit","localidad":"Villa Constitucion","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2081,"nombre":"Villa Constitucion Acceso","localidad":"Villa Constitucion","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.23629296592316,"longitud":-60.35551212402427,"ranking":0},
{"id":2084,"nombre":"Villa Constitucion","localidad":"Villa Constitucion","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.2280310,"longitud":-60.3299310,"ranking":0},
{"id":7858,"nombre":"Santa Teresa","localidad":"Villa Constitucion","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.43672543967232,"longitud":-60.79047973026527,"ranking":0},
{"id":351,"nombre":"Villa Minetti","localidad":"Villa Minetti","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.623539,"longitud":-61.622516,"ranking":4},
{"id":347,"nombre":"Villa Ocampo","localidad":"Villa Ocampo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.491017,"longitud":-59.347798,"ranking":204},
{"id":4179,"nombre":"Villa Ocampo Acceso","localidad":"Villa Ocampo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.4897874,"longitud":-59.34506539999999,"ranking":8},
{"id":8395,"nombre":"Villa Trinidad Acceso","localidad":"Villa Trinidad","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.223606639550667,"longitud":-61.86672210171532,"ranking":0},
{"id":302,"nombre":"Bell Ville","localidad":"Bell Ville","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.626694,"longitud":-62.692099,"ranking":0},
{"id":3023,"nombre":"Bell Ville Acceso","localidad":"Bell Ville","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6403081,"longitud":-62.6933564,"ranking":47},
{"id":5497,"nombre":"Parador San Cayetano","localidad":"Bell Ville","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6119253,"longitud":-62.6968356,"ranking":0},
{"id":147,"nombre":"Capilla del Monte","localidad":"Capilla del Monte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.858955,"longitud":-64.526237,"ranking":1778},
{"id":2112,"nombre":"Capilla del Monte Acceso","localidad":"Capilla del Monte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4323740,"longitud":-64.2491680,"ranking":0},
{"id":8663,"nombre":"Traslado a Capilla del Monte","localidad":"Capilla del Monte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.8589772,"longitud":-64.5266523,"ranking":0},
{"id":8764,"nombre":"Hoteles Capilla del Monte","localidad":"Capilla del Monte","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4723,"nombre":"Corral de Bustos","localidad":"Corral de Bustos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.279888,"longitud":-62.185954,"ranking":67},
{"id":2109,"nombre":"Cruz Chica","localidad":"Cruz Chica","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9483530,"longitud":-64.4933020,"ranking":0},
{"id":166,"nombre":"Cruz del Eje","localidad":"Cruz del Eje","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.724818,"longitud":-64.800747,"ranking":907},
{"id":2100,"nombre":"Paso Viejo","localidad":"Cruz del Eje","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7659943,"longitud":-65.1904715,"ranking":1},
{"id":9211,"nombre":"El Cruce","localidad":"Cruz del Eje","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2110,"nombre":"Cruz Grande","localidad":"Cruz Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.94912609943779,"longitud":-64.4931090050683,"ranking":0},
{"id":497,"nombre":"Dean Funes","localidad":"Dean Funes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.425152,"longitud":-64.349153,"ranking":133},
{"id":9061,"nombre":"Parador Dean Funes","localidad":"Dean Funes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.418424259791085,"longitud":-64.3464732170105,"ranking":110},
{"id":9436,"nombre":"El Banado Ruta 60","localidad":"Dean Funes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.2812522,"longitud":-64.4456105,"ranking":0},
{"id":9437,"nombre":"La Barranca","localidad":"Dean Funes","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2675,"nombre":"Embalse","localidad":"Embalse","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.19568,"longitud":-64.40298,"ranking":323},
{"id":10760,"nombre":"Usina Atomica","localidad":"Embalse","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10761,"nombre":"Hoteles MOP","localidad":"Embalse","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1200,"nombre":"Huerta Grande","localidad":"Huerta Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.071232,"longitud":-64.489747,"ranking":41},
{"id":8756,"nombre":"Hoteles Huerta Grande","localidad":"Huerta Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.071838082431317,"longitud":-64.49954229575965,"ranking":0},
{"id":797,"nombre":"Jovita","localidad":"Jovita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.514651,"longitud":-63.940767,"ranking":64},
{"id":387,"nombre":"La Carlota","localidad":"La Carlota","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.41244,"longitud":-63.290881,"ranking":65},
{"id":1381,"nombre":"Parador La Carlota ","localidad":"La Carlota","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.41266,"longitud":-63.290889,"ranking":7},
{"id":10906,"nombre":"La Carlota Acceso","localidad":"La Carlota","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.412662,"longitud":-63.29089,"ranking":9},
{"id":189,"nombre":"la Cumbre","localidad":"La Cumbre","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.983083,"longitud":-64.494768,"ranking":524},
{"id":8765,"nombre":"Hoteles La Cumbre","localidad":"La Cumbre","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10095,"nombre":"Parador La Cumbre","localidad":"La Cumbre","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":190,"nombre":"La Falda","localidad":"La Falda","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.089637,"longitud":-64.487158,"ranking":1069},
{"id":8754,"nombre":"Hoteles la Falda","localidad":"La Falda","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8755,"nombre":"Cerro el Cuadrado","localidad":"La Falda","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.091900818315363,"longitud":-64.47037378122646,"ranking":0},
{"id":482,"nombre":"Laboulaye","localidad":"Laboulaye","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.134602,"longitud":-63.390423,"ranking":418},
{"id":2385,"nombre":"Laboulaye Acceso","localidad":"Laboulaye","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.14484815583755,"longitud":-63.39329973091634,"ranking":0},
{"id":10787,"nombre":"Caminera Laboulaye","localidad":"Laboulaye","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.134436,"longitud":-63.389958,"ranking":25},
{"id":1551,"nombre":"Las Varillas","localidad":"Las Varillas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.869647,"longitud":-62.707953,"ranking":147},
{"id":4198,"nombre":"Las Varillas Acceso","localidad":"Las Varillas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.87610479187246,"longitud":-62.70054479949845,"ranking":0},
{"id":1199,"nombre":"Los Cocos","localidad":"Los Cocos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.93693,"longitud":-64.493452,"ranking":11},
{"id":8760,"nombre":"Hoteles los Cocos","localidad":"Los Cocos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10427,"nombre":"Los Cocos Acceso","localidad":"Los Cocos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.92470092858576,"longitud":-64.52756751256526,"ranking":9},
{"id":303,"nombre":"Marcos Juarez","localidad":"Marcos Juarez","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.686902,"longitud":-62.111118,"ranking":9},
{"id":2105,"nombre":"Marcos Juarez Acceso","localidad":"Marcos Juarez","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.66771048354666,"longitud":-62.09838941547078,"ranking":0},
{"id":8260,"nombre":"General Baldissera","localidad":"Marcos Juarez","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":298,"nombre":"Pilar","localidad":"Pilar","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.68495865689806,"longitud":-63.87606148299001,"ranking":0},
{"id":2159,"nombre":"Pilar Acceso","localidad":"Pilar","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.69183941628924,"longitud":-63.8703761009333,"ranking":0},
{"id":4417,"nombre":"Quilino","localidad":"Quilino","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.215666,"longitud":-64.492885,"ranking":18},
{"id":9442,"nombre":"Estacion Quilino","localidad":"Quilino","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.211543,"longitud":-64.492582,"ranking":2},
{"id":2800,"nombre":"Rio Tercero","localidad":"Rio Tercero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.167343,"longitud":-64.118981,"ranking":782},
{"id":922,"nombre":"Santa Rosa de Calamuchita","localidad":"Santa Rosa de Calamuchita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.073757,"longitud":-64.531677,"ranking":1034},
{"id":923,"nombre":"El Corcovado","localidad":"Santa Rosa de Calamuchita","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7884,"nombre":"Santa Isabel","localidad":"Santa Rosa de Calamuchita","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9183,"nombre":"Centro Urbano","localidad":"Santa Rosa de Calamuchita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.06993326642904,"longitud":-64.53641969177885,"ranking":0},
{"id":9851,"nombre":"La Argentina","localidad":"Santa Rosa de Calamuchita","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2092,"nombre":"Salsipuedes","localidad":"Salsipuedes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.13999,"longitud":-64.290686,"ranking":116},
{"id":506,"nombre":" San Francisco","localidad":"San Francisco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.429471,"longitud":-62.09154,"ranking":2261},
{"id":2163,"nombre":"San Francisco / Frontera CBA","localidad":"San Francisco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.429504,"longitud":-62.091396,"ranking":0},
{"id":2164,"nombre":"La Francia","localidad":"San Francisco","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2165,"nombre":"San Francisco A (EPU)","localidad":"San Francisco","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":271,"nombre":"Valle Hermoso","localidad":"Valle Hermoso","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.116552,"longitud":-64.485351,"ranking":107},
{"id":8757,"nombre":"Hoteles Valle Hermoso","localidad":"Valle Hermoso","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9041,"nombre":"Parador Valle Hermoso","localidad":"Valle Hermoso","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.116559,"longitud":-64.485352,"ranking":0},
{"id":479,"nombre":"Vicuña Mackenna","localidad":"Vicuña Mackenna","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.917296,"longitud":-64.390653,"ranking":589},
{"id":1341,"nombre":"Vicuña Mackenna Acceso","localidad":"Vicuña Mackenna","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.929429500,"longitud":-64.397896500,"ranking":0},
{"id":9210,"nombre":"El Consuelo","localidad":"Vicuña Mackenna","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":88,"nombre":"Villa Dolores","localidad":"Villa Dolores","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.950667,"longitud":-65.185304,"ranking":424},
{"id":14614,"nombre":"Hospital de Villa Dolores","localidad":"Villa Dolores","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9485313,"longitud":-65.1687639,"ranking":0},
{"id":291,"nombre":"Villa Giardino","localidad":"Villa Giardino","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.050182,"longitud":-64.498956,"ranking":195},
{"id":8758,"nombre":"Hoteles Villa Giardino","localidad":"Villa Giardino","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":773,"nombre":"Villa Huidobro","localidad":"Villa Huidobro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.840516,"longitud":-64.585769,"ranking":86},
{"id":290,"nombre":"Villa Maria","localidad":"Villa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.414791,"longitud":-63.233132,"ranking":3990},
{"id":3315,"nombre":"Villa Maria Acceso","localidad":"Villa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4712,"nombre":"Villa Nueva","localidad":"Villa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10058,"nombre":"El Cruce","localidad":"Villa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3565,"nombre":"Basavilbaso","localidad":"Basavilbaso","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3839899,"longitud":-58.8818946,"ranking":0},
{"id":15468,"nombre":"Termas de Basavilbaso","localidad":"Basavilbaso","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3834105,"longitud":-58.8990429,"ranking":0},
{"id":3566,"nombre":"Bovril","localidad":"Bovril","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.340947,"longitud":-59.446604,"ranking":80},
{"id":8527,"nombre":"Bovril Acceso","localidad":"Bovril","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.3950958,"longitud":-59.4344816,"ranking":0},
{"id":3267,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.584439,"longitud":-60.07791,"ranking":10},
{"id":8528,"nombre":"Cerrito Acceso","localidad":"Cerrito","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.5907518,"longitud":-60.0821017,"ranking":2},
{"id":1442,"nombre":"Colon","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.2170852,"longitud":-58.1463392,"ranking":10000},
{"id":1610,"nombre":"Almacen Allois","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1611,"nombre":"Balneario Rocha","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.15366899164812,"longitud":-58.312350596238005,"ranking":0},
{"id":1612,"nombre":"Berduc","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.9107573847557,"longitud":-58.322439277582795,"ranking":0},
{"id":1614,"nombre":"Colon Acceso","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.257166,"longitud":-58.236725,"ranking":14},
{"id":1615,"nombre":"Colonia Hughes","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.29698906943248,"longitud":-58.24039900426515,"ranking":0},
{"id":2181,"nombre":"Villa Elisa Acceso","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.16265596938949,"longitud":-58.401020191835556,"ranking":0},
{"id":1771,"nombre":"La Quemada","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1772,"nombre":"Liebig Acceso","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.13775621580621,"longitud":-58.238908137417674,"ranking":0},
{"id":1773,"nombre":"Mabragaña","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.07420663525087,"longitud":-58.25399469232874,"ranking":0},
{"id":1774,"nombre":"Pelado","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1775,"nombre":"Pueblo Cazes","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.0020862,"longitud":-58.49781459999999,"ranking":0},
{"id":2180,"nombre":"Villa Elisa","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.163028416529,"longitud":-58.40133395215291,"ranking":0},
{"id":1616,"nombre":"Colonia San Miguel (La Rural)","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1617,"nombre":"El Palmar Acceso","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.852350034171184,"longitud":-58.322382975727905,"ranking":0},
{"id":1619,"nombre":"Hucker Acceso","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.101553261916166,"longitud":-58.24685010274186,"ranking":0},
{"id":1620,"nombre":"Humaita","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1621,"nombre":"Juan Jorge","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.00213325303417,"longitud":-58.303701823642136,"ranking":0},
{"id":1770,"nombre":"La Clarita","localidad":"Colón","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1867,"nombre":"Arroyo Colman","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.39161884373745,"longitud":-58.27886136686322,"ranking":0},
{"id":1868,"nombre":"Arroyo El Molino","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1869,"nombre":"Arroyo Urquiza","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.382277209054045,"longitud":-58.237454767183095,"ranking":0},
{"id":1871,"nombre":"Chamarrita","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.47493495806244,"longitud":-58.241688232963476,"ranking":0},
{"id":1872,"nombre":"Colonia Elia Peaje","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.66704815396061,"longitud":-58.44174208896011,"ranking":0},
{"id":12374,"nombre":"Rocamora","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12375,"nombre":"Herrera","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12376,"nombre":"Caseros","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12381,"nombre":"Hipodromo de Concepcion del Uruguay","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.4867797,"longitud":-58.3289133,"ranking":0},
{"id":14602,"nombre":"Herrera Acceso","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.4399819,"longitud":-58.6286065,"ranking":0},
{"id":7907,"nombre":"Termas de Concepcion del Uruguay","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.45072788871199,"longitud":-58.292784201146944,"ranking":0},
{"id":3029,"nombre":"Concepcion del Uruguay","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.485489,"longitud":-58.242306,"ranking":277},
{"id":1874,"nombre":"Concepcion del Uruguay Acceso","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.486601,"longitud":-58.30065,"ranking":12},
{"id":1875,"nombre":"El Resorte","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.549482336554455,"longitud":-58.35478023070159,"ranking":0},
{"id":1878,"nombre":"Los Ceibos","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1879,"nombre":"Manteros Acceso","localidad":"Concepcion del Uruguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.41090620478617,"longitud":-58.745650929831534,"ranking":0},
{"id":653,"nombre":"Concordia Acceso","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.395677,"longitud":-58.099027,"ranking":139},
{"id":1784,"nombre":"Villa Adela Acceso","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.419545052002487,"longitud":-58.10210269808622,"ranking":0},
{"id":1787,"nombre":"La Criolla Acceso","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.279098060565136,"longitud":-58.08018162371471,"ranking":0},
{"id":1788,"nombre":"Las Tejas","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.40924008299663,"longitud":-58.07958833068146,"ranking":0},
{"id":1789,"nombre":"Ledesma","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.57705578144421,"longitud":-58.180744502078724,"ranking":0},
{"id":1790,"nombre":"Monti","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1791,"nombre":"Pedernal Acceso","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.66324579938766,"longitud":-58.239381993214295,"ranking":0},
{"id":1792,"nombre":"Ruta 128","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1793,"nombre":"Salto Grande Acceso","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.209806594682153,"longitud":-57.93429159485248,"ranking":0},
{"id":3030,"nombre":"Concordia","localidad":"Concordia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.385206,"longitud":-58.012526,"ranking":0},
{"id":3567,"nombre":"Crespo","localidad":"Crespo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.0262242,"longitud":-60.30178420000001,"ranking":18},
{"id":654,"nombre":"Chajari","localidad":"Chajari","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.756111,"longitud":-57.988559,"ranking":0},
{"id":1392,"nombre":"Chajarí Acceso","localidad":"Chajari","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.756753,"longitud":-58.020031,"ranking":47},
{"id":1796,"nombre":"Diamante","localidad":"Diamante","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.069096,"longitud":-60.636796,"ranking":0},
{"id":1797,"nombre":"Las Pencas","localidad":"Diamante","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1798,"nombre":"Molino Doli","localidad":"Diamante","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.30814046824841,"longitud":-60.41929742762058,"ranking":0},
{"id":1800,"nombre":"Villa Libertador (Puigari)","localidad":"Diamante","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.0499992,"longitud":-60.4500008,"ranking":0},
{"id":12368,"nombre":"Diamante Acceso","localidad":"Diamante","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.0791889,"longitud":-60.5954143,"ranking":0},
{"id":1462,"nombre":"Federacion Acceso","localidad":"Federacion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.032327,"longitud":-58.072955,"ranking":53},
{"id":1802,"nombre":"Colonia Alemana","localidad":"Federacion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1803,"nombre":"Colonia Racedo","localidad":"Federacion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.022268542174935,"longitud":-58.03494840646947,"ranking":0},
{"id":3093,"nombre":"Federacion","localidad":"Federacion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.9809526,"longitud":-57.9232768,"ranking":0},
{"id":7677,"nombre":"Las Achiras","localidad":"Federacion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4745,"nombre":"General Campos","localidad":"General Campos","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.5273711,"longitud":-58.40758040000001,"ranking":0},
{"id":7956,"nombre":"General Campos Acceso","localidad":"General Campos","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.548215883499566,"longitud":-58.399907497297804,"ranking":0},
{"id":2804,"nombre":"Hernandarias","localidad":"Hernandarias","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.230527,"longitud":-59.985207,"ranking":14},
{"id":7901,"nombre":"Hernandarias Acceso","localidad":"Hernandarias","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.300735543374884,"longitud":-59.7967722464374,"ranking":0},
{"id":8530,"nombre":"Peaje Tunel Hernandarias","localidad":"Hernandarias","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1835,"nombre":"La Paz","localidad":"La Paz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.7416435,"longitud":-59.6462599,"ranking":0},
{"id":1836,"nombre":"La Paz Acceso","localidad":"La Paz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.745386,"longitud":-59.579843,"ranking":111},
{"id":3176,"nombre":"El Arco","localidad":"La Paz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8073,"nombre":"Yeso Oeste","localidad":"La Paz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.93649897319128,"longitud":-59.468146177236044,"ranking":0},
{"id":3569,"nombre":"Lucas Gonzalez","localidad":"Lucas Gonzalez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8494,"nombre":"Lucas Gonzalez Acceso","localidad":"Lucas Gonzalez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3986546,"longitud":-59.5140268,"ranking":0},
{"id":3570,"nombre":"Macia","localidad":"Macia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1718427,"longitud":-59.3969098,"ranking":0},
{"id":8255,"nombre":"Macia Acceso","localidad":"Macia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.182886624627,"longitud":-59.27815068886053,"ranking":0},
{"id":1837,"nombre":"20 de Setiembre","localidad":"Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.40198882137702,"longitud":-59.66890077997429,"ranking":0},
{"id":1841,"nombre":"Lucas Gonzalez","localidad":"Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1842,"nombre":"Lucas Gonzalez Acceso","localidad":"Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.41086356015533,"longitud":-59.55445912110091,"ranking":0},
{"id":1843,"nombre":"Nogoya","localidad":"Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3902207,"longitud":-59.7930793,"ranking":0},
{"id":8499,"nombre":"Villa General Ramirez","localidad":"Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":507,"nombre":"Parana","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.739593,"longitud":-60.518265,"ranking":2780},
{"id":1846,"nombre":"Camps Acceso","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.11455491027624,"longitud":-60.21529695450171,"ranking":1},
{"id":1847,"nombre":"Genolet","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1849,"nombre":"Maria Luisa Acceso","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.877370967039823,"longitud":-60.41010766213334,"ranking":0},
{"id":1852,"nombre":"Ramblon","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.842720363768187,"longitud":-60.08881388319416,"ranking":0},
{"id":1853,"nombre":"Rossier","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9751,"nombre":"Las Achiras - ERI","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10063,"nombre":"Paraná Domicilio Pasajero","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7473,"nombre":"Oro Verde","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7838,"nombre":"Gazzano","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1854,"nombre":"Tres Bocas","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.73888832751856,"longitud":-59.76688603984551,"ranking":0},
{"id":1855,"nombre":"Tres Sauces","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.85721963125221,"longitud":-59.806997023455914,"ranking":0},
{"id":1856,"nombre":"Viale Acceso","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.8578953,"longitud":-60.0270423,"ranking":0},
{"id":1857,"nombre":"Viale","localidad":"Parana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1839,"nombre":"General Ramirez Acceso","localidad":"Ramirez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1624701,"longitud":-60.18103429999999,"ranking":0},
{"id":3571,"nombre":"General Ramirez","localidad":"Ramirez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.170744878616425,"longitud":-60.19675254821777,"ranking":0},
{"id":1858,"nombre":"Gobernador Echague Acceso","localidad":"Rosario del Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.39694236258889,"longitud":-59.303526997139535,"ranking":0},
{"id":1859,"nombre":"Gobernador Mansilla Acceso","localidad":"Rosario del Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.56434257628486,"longitud":-59.32476818565838,"ranking":0},
{"id":1864,"nombre":"Rosario del Tala","localidad":"Rosario del Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3016059,"longitud":-59.1357613,"ranking":0},
{"id":1865,"nombre":"Rosario del Tala Acceso","localidad":"Rosario del Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.314744514092745,"longitud":-59.145403445289595,"ranking":0},
{"id":8375,"nombre":"Gobernador Mansilla","localidad":"Rosario del Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.54545,"longitud":-59.35494,"ranking":0},
{"id":1781,"nombre":"San Salvador","localidad":"San Salvador","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-24.221214700,"longitud":-65.272200100,"ranking":7},
{"id":2953,"nombre":"Ubajay","localidad":"Ubajay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.79494,"longitud":-58.317943,"ranking":29},
{"id":3318,"nombre":"Ubajay Acceso","localidad":"Ubajay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.795535,"longitud":-58.318086,"ranking":6},
{"id":3572,"nombre":"Urdinarrain","localidad":"Urdinarrain","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.0085889,"longitud":-58.5108947,"ranking":0},
{"id":1882,"nombre":"Victoria","localidad":"Victoria","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.617605,"longitud":-60.159414,"ranking":0},
{"id":4298,"nombre":"Victoria Acceso","localidad":"Victoria","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.62043023264372,"longitud":-60.131766285251075,"ranking":0},
{"id":12372,"nombre":"Los Cerros Acceso","localidad":"Victoria","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.8530753,"longitud":-59.8543694,"ranking":2},
{"id":3017,"nombre":"Villa Elisa Acceso","localidad":"Villa Elisa","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.153952,"longitud":-58.389199,"ranking":0},
{"id":14612,"nombre":"Termas de Villa Elisa","localidad":"Villa Elisa","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1298201,"longitud":-58.4402316,"ranking":0},
{"id":1883,"nombre":"Alperin","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1884,"nombre":"Baño Oficial (Coop.Raices)","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1885,"nombre":"Barbagelatta","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1887,"nombre":"Cinco Bocas","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1888,"nombre":"Clara Acceso","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.799153986949364,"longitud":-58.812512035135995,"ranking":0},
{"id":1890,"nombre":"Fabrica de Palmas","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7969,"nombre":"Villa Clara - ERI","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8075,"nombre":"Mojones Norte","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1899,"nombre":"Travichet","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1901,"nombre":"Villaguay","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.8591901,"longitud":-59.0318592,"ranking":0},
{"id":3019,"nombre":"Villaguay Acceso","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.807867,"longitud":-59.028646,"ranking":6},
{"id":1891,"nombre":"Ingeniero Sajaroff (La Capilla)","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.93844968648624,"longitud":-58.84513253873305,"ranking":0},
{"id":1894,"nombre":"Paso de la Laguna","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1895,"nombre":"Piñeiro","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.859490311453346,"longitud":-59.03177289880392,"ranking":0},
{"id":1896,"nombre":"Puente Gualeguay","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1897,"nombre":"Puente Villaguay","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1898,"nombre":"San Vicente Acceso","localidad":"Villaguay","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.783438733449763,"longitud":-58.76485353777457,"ranking":0},
{"id":3041,"nombre":"Andalgala","localidad":"Andalgala","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.58031714546288,"longitud":-66.31516202256242,"ranking":77},
{"id":3037,"nombre":"Belen","localidad":"Belen","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.6510218,"longitud":-67.0289200,"ranking":870},
{"id":3948,"nombre":"Las Cañas","localidad":"Las Cañas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.203992100,"longitud":-65.214934900,"ranking":7},
{"id":4199,"nombre":"Los Altos","localidad":"Los Altos","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.154684,"longitud":-65.659136,"ranking":47},
{"id":2472,"nombre":"Recreo","localidad":"Recreo","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.718114,"longitud":-65.054457,"ranking":521},
{"id":3299,"nombre":"San Antonio de La Paz","localidad":"San Antonio de la Paz","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.9300793,"longitud":-65.0925424,"ranking":39},
{"id":3311,"nombre":"Tinogasta","localidad":"Tinogasta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.068418347384128,"longitud":-67.55918707316684,"ranking":0},
{"id":4458,"nombre":"Fiambala","localidad":"Tinogasta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.69220500494991,"longitud":-67.61971022298486,"ranking":0},
{"id":9193,"nombre":"Cordobita","localidad":"Tinogasta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.307393400181798,"longitud":-67.21802922120234,"ranking":0},
{"id":2171,"nombre":"Alvear","localidad":"Alvear","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.086571,"longitud":-56.55417,"ranking":41},
{"id":3008,"nombre":"Alvear Acceso","localidad":"Alvear","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.086617,"longitud":-56.554186,"ranking":5},
{"id":651,"nombre":"Bella Vista","localidad":"Bella Vista","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.522164,"longitud":-59.041677,"ranking":888},
{"id":1558,"nombre":"Bella Vista Acceso","localidad":"Bella Vista","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4674,"nombre":"Colonia 3 de Abril","localidad":"Bella Vista","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.412088107550286,"longitud":-58.91633607333325,"ranking":0},
{"id":369,"nombre":"Corrientes","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.498808,"longitud":-58.814587,"ranking":10013},
{"id":7972,"nombre":"Tatacua Colonia","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":7},
{"id":8376,"nombre":"Paraje San Isidro ","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8407,"nombre":"Ñande Roga","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10430,"nombre":"Aeropuerto Internacional de Corrientes","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10432,"nombre":"Corrientes Centro","localidad":"Corrientes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":364,"nombre":"Curuzu Cuatia","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.78935,"longitud":-58.051045,"ranking":441},
{"id":1565,"nombre":"Baibiene Acceso","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.599538545061435,"longitud":-58.126261793975296,"ranking":0},
{"id":1566,"nombre":"Colodrero","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.203516591244036,"longitud":-58.25246537880641,"ranking":0},
{"id":1567,"nombre":"Curuzu Cuatia Acceso","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.789250400,"longitud":-58.087460700,"ranking":1},
{"id":1568,"nombre":"La Hierra","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1569,"nombre":"Las Tunas","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.80802935270823,"longitud":-58.06769823262395,"ranking":0},
{"id":4672,"nombre":"Tres Bocas","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7458,"nombre":"Pago Largo","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1570,"nombre":"Los Conquistadores","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1571,"nombre":"Miñones","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.78915853049261,"longitud":-58.051282004692645,"ranking":0},
{"id":1573,"nombre":"Solari","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4565,"nombre":"Baibiene","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.60165109975841,"longitud":-58.16542274520843,"ranking":0},
{"id":4594,"nombre":"Cazadores Correntinos","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.981946802494598,"longitud":-58.304203917046166,"ranking":0},
{"id":4603,"nombre":"Campamento","localidad":"Curuzu Cuatia","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":659,"nombre":"Chavarria","localidad":"Chavarria","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.95642706810449,"longitud":-58.57588146663667,"ranking":1},
{"id":1396,"nombre":"Chavarría Acceso","localidad":"Chavarria","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.959124,"longitud":-58.578979,"ranking":53},
{"id":367,"nombre":"Empedrado","localidad":"Empedrado","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.953411,"longitud":-58.794622,"ranking":174},
{"id":1574,"nombre":"Empedrado Acceso","localidad":"Empedrado","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.953411,"longitud":-58.794622,"ranking":75},
{"id":593,"nombre":"Esquina","localidad":"Esquina","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.020002,"longitud":-59.531214,"ranking":315},
{"id":1576,"nombre":"Concepcion Acceso","localidad":"Esquina","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.38429528597132,"longitud":-57.90590966443995,"ranking":0},
{"id":1579,"nombre":"Pueblo Libertador Acceso","localidad":"Esquina","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.217886523937114,"longitud":-59.39409127227,"ranking":0},
{"id":3419,"nombre":"Esquina Acceso","localidad":"Esquina","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8293,"nombre":"Guayaquiraro Acceso","localidad":"Esquina","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.324831980700182,"longitud":-59.50377715321179,"ranking":0},
{"id":594,"nombre":"Goya","localidad":"Goya","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.144856,"longitud":-59.263199,"ranking":2020},
{"id":1584,"nombre":"Buena Vista Acceso","localidad":"Goya","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.428210436249177,"longitud":-59.29281892873206,"ranking":0},
{"id":3420,"nombre":"Goya Acceso","localidad":"Goya","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.194237,"longitud":-59.226864,"ranking":4},
{"id":1250,"nombre":"Itati","localidad":"Itati","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.27351,"longitud":-58.242483,"ranking":51},
{"id":8398,"nombre":"Itati Acceso","localidad":"Itati","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.3493194,"longitud":-58.2348165,"ranking":9},
{"id":1585,"nombre":"Ituzaingo","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.592526,"longitud":-56.686383,"ranking":479},
{"id":3317,"nombre":"Ituzaingo Acceso","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4020,"nombre":"Playadito Acceso","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.88551210843026,"longitud":-55.92056108642662,"ranking":0},
{"id":4023,"nombre":"Villa Olivari Acceso","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.632969031009335,"longitud":-56.90084710220598,"ranking":0},
{"id":8079,"nombre":"Villa Olivari","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.632765947105426,"longitud":-56.90631937226281,"ranking":0},
{"id":8990,"nombre":"Ituzaingo con Transfer","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.593186800,"longitud":-56.686373900,"ranking":0},
{"id":4104,"nombre":"Santa Maria","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5538,"nombre":"El Itaembe","localidad":"Ituzaingo","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2172,"nombre":"La Cruz Acceso","localidad":"La Cruz","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.163867,"longitud":-56.66542,"ranking":91},
{"id":3027,"nombre":"La Cruz","localidad":"La Cruz","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.169422,"longitud":-56.652378,"ranking":15},
{"id":3086,"nombre":"Loreto","localidad":"Loreto","provincia":"Corrientes","pais":"ARGENTINA","latitud":-26.822846,"longitud":-65.202839,"ranking":2},
{"id":4097,"nombre":"Loreto Acceso","localidad":"Loreto","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.769937,"longitud":-57.28038,"ranking":11},
{"id":1589,"nombre":"Felipe Yofre Acceso","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.098791,"longitud":-58.340084,"ranking":7},
{"id":1590,"nombre":"Gauchito Gil","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.14338455546005,"longitud":-58.13700954815783,"ranking":0},
{"id":1592,"nombre":"Mercedes","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.182646,"longitud":-58.082217,"ranking":1008},
{"id":1593,"nombre":"Mercedes Acceso","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.1507523,"longitud":-58.1120116,"ranking":0},
{"id":4487,"nombre":"Santa Juana Acceso","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.4530442,"longitud":-57.592015,"ranking":22},
{"id":4488,"nombre":"Miriñay Acceso","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9086,"nombre":"Ñu Vera","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4610,"nombre":"Control","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5663,"nombre":"El Timbó","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.806002144102084,"longitud":-55.866368231356894,"ranking":0},
{"id":9082,"nombre":"Cerro Verde","localidad":"Mercedes","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1393,"nombre":"Mocoreta Acceso","localidad":"Mocoreta","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.616634,"longitud":-57.980148,"ranking":7},
{"id":3087,"nombre":"Mocoreta","localidad":"Mocoreta","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.6166337,"longitud":-57.9801478,"ranking":10},
{"id":1594,"nombre":"Arroyo Mota","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.47509295952061,"longitud":-57.98540455634481,"ranking":0},
{"id":1596,"nombre":"Juan Pujol Acceso","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.254866437718537,"longitud":-57.654073344029314,"ranking":0},
{"id":1598,"nombre":"Monte Caseros","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.251818,"longitud":-57.643129,"ranking":179},
{"id":1599,"nombre":"Monte Caseros Acceso","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.251795,"longitud":-57.643115,"ranking":16},
{"id":8056,"nombre":"Juan Pujol","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8522,"nombre":"Cruce 3 Bocas","localidad":"Monte Caseros","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.251835,"longitud":-57.643132,"ranking":0},
{"id":1463,"nombre":"Paso de los Libres","localidad":"Paso de los Libres","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.724554,"longitud":-57.094357,"ranking":653},
{"id":1604,"nombre":"Paso de los Libres Acceso","localidad":"Paso de los Libres","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.7253,"longitud":-57.093744,"ranking":19},
{"id":4596,"nombre":"Parada Pucheta","localidad":"Paso de los Libres","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.905973092715662,"longitud":-57.57420163657392,"ranking":0},
{"id":365,"nombre":"Saladas","localidad":"Saladas ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.256447,"longitud":-58.619629,"ranking":374},
{"id":1605,"nombre":"Saladas Acceso","localidad":"Saladas ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.256435,"longitud":-58.619634,"ranking":44},
{"id":15474,"nombre":"Pago de los Deseos","localidad":"Saladas ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.1892484,"longitud":-58.4314149,"ranking":0},
{"id":366,"nombre":"San Lorenzo","localidad":"San Lorenzo ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.124697,"longitud":-58.766611,"ranking":113},
{"id":1398,"nombre":"San Lorenzo (cruce de caminos)","localidad":"San Lorenzo ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.130166603336388,"longitud":-58.76309192852634,"ranking":0},
{"id":8936,"nombre":"San Lorenzo Acceso","localidad":"San Lorenzo ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.12456725753773,"longitud":-58.76658518740588,"ranking":0},
{"id":363,"nombre":"San Roque","localidad":"San Roque","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.576633,"longitud":-58.713551,"ranking":109},
{"id":1608,"nombre":"San Roque Acceso","localidad":"San Roque","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.5807246,"longitud":-58.72193899999999,"ranking":22},
{"id":4089,"nombre":"Cruce Ruta 12 y 123","localidad":"San Roque","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.858482,"longitud":-58.7343661,"ranking":1},
{"id":595,"nombre":"Santa Lucia","localidad":"Santa Lucia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.981372,"longitud":-59.100292,"ranking":162},
{"id":4289,"nombre":"Santa Lucia Acceso","localidad":"Santa Lucia","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.97152079841174,"longitud":-59.096164934799305,"ranking":0},
{"id":1466,"nombre":"Santo Tome","localidad":"Santo Tome","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.54643,"longitud":-56.043931,"ranking":269},
{"id":3428,"nombre":"Acceso Santo Tome","localidad":"Santo Tome","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.546755,"longitud":-56.043742,"ranking":116},
{"id":4014,"nombre":"Cuay Grande Acceso","localidad":"Santo Tome","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.66620558321247,"longitud":-56.288430283179856,"ranking":0},
{"id":1394,"nombre":"Solari Acceso","localidad":"Solari","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.380358,"longitud":-58.1852,"ranking":15},
{"id":4563,"nombre":"Solari","localidad":"Solari","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.3776262,"longitud":-58.1947865,"ranking":0},
{"id":81,"nombre":"Avia Terai","localidad":"Avia Terai","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.6851,"longitud":-60.728677,"ranking":34},
{"id":8497,"nombre":"Avia Terai Acceso","localidad":"Avia Terai","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.706029408017766,"longitud":-60.747314010849585,"ranking":0},
{"id":105,"nombre":"Campo Largo","localidad":"Campo Largo","provincia":"Chaco","pais":"ARGENTINA","latitud":-31.437702,"longitud":-64.498167,"ranking":26},
{"id":9176,"nombre":"Campo Largo Acceso","localidad":"Campo Largo","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.80607596465767,"longitud":-60.837490135764675,"ranking":0},
{"id":362,"nombre":"Juan Jose Castelli","localidad":"Juan Jose Castelli","provincia":"Chaco","pais":"ARGENTINA","latitud":-25.945708,"longitud":-60.630792,"ranking":41},
{"id":10435,"nombre":"City Tour Castelli","localidad":"Juan Jose Castelli","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":355,"nombre":"Coronel Du Graty","localidad":"Coronel Du Graty","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.686515,"longitud":-60.909988,"ranking":25},
{"id":83,"nombre":"Corzuela","localidad":"Corzuela","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.676003,"longitud":-60.978296,"ranking":37},
{"id":9197,"nombre":"Corzuela Acceso","localidad":"Corzuela","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.963382965116498,"longitud":-60.95795514963113,"ranking":0},
{"id":87,"nombre":"Charata","localidad":"Charata","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.218903,"longitud":-61.192329,"ranking":107},
{"id":3413,"nombre":"Charata Acceso","localidad":"Charata","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.218142,"longitud":-61.192386,"ranking":0},
{"id":90,"nombre":"Gancedo","localidad":"Gancedo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.490774,"longitud":-61.676484,"ranking":6},
{"id":4141,"nombre":"Gancedo Acceso","localidad":"Gancedo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.495088,"longitud":-61.676566,"ranking":0},
{"id":10440,"nombre":"Campo del Cielo - Meteoritos","localidad":"Gancedo","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":89,"nombre":"Pinedo","localidad":"General Pinedo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.328686,"longitud":-61.286534,"ranking":35},
{"id":3421,"nombre":"General Pinedo Cruce de Caminos (Est. YPF)","localidad":"General Pinedo","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7799,"nombre":"General Pinedo Acceso","localidad":"General Pinedo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.327723,"longitud":-61.286507,"ranking":0},
{"id":358,"nombre":"La Tigra","localidad":"La Tigra","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.106357,"longitud":-60.585796,"ranking":8},
{"id":84,"nombre":"Las Breñas","localidad":"Las Breñas ","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.08838,"longitud":-61.083623,"ranking":49},
{"id":4140,"nombre":"Las Breñas CC. Est. Shell","localidad":"Las Breñas ","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7801,"nombre":"Las Breñas Acceso","localidad":"Las Breñas ","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.0990463,"longitud":-61.0665299,"ranking":0},
{"id":920,"nombre":"Machagai","localidad":"Machagai","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.661573,"longitud":-60.059834,"ranking":86},
{"id":7803,"nombre":"Machagai Acceso","localidad":"Machagai","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.939426231612494,"longitud":-60.05818641182487,"ranking":0},
{"id":2215,"nombre":"Margarita Belen Acceso","localidad":"Margarita Belen","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.2582479,"longitud":-58.9848742,"ranking":0},
{"id":3271,"nombre":"Margarita Belen","localidad":"Margarita Belen","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.259102700,"longitud":-58.985925600,"ranking":0},
{"id":2182,"nombre":"Pampa del Infierno","localidad":"Pampa del Infierno","provincia":"Chaco","pais":"ARGENTINA","latitud":-25.967525,"longitud":-61.2188,"ranking":4},
{"id":9065,"nombre":"Pampa del Infierno Acceso","localidad":"Pampa del Infierno","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.51257136929197,"longitud":-61.17295150855731,"ranking":0},
{"id":1058,"nombre":"Presidencia de la Plaza","localidad":"Presidencia de la Plaza","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.86387,"longitud":-59.850032,"ranking":36},
{"id":4139,"nombre":"Presidencia de la Plaza Acceso","localidad":"Presidencia de la Plaza","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.006092601049055,"longitud":-59.85214975805487,"ranking":0},
{"id":106,"nombre":"Presidencia Roque Saenz Peña","localidad":"Presidencia Roque Saenz Peña","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.786549,"longitud":-60.430254,"ranking":683},
{"id":7603,"nombre":"Villa Rio Bermejito","localidad":"Presidencia Roque Saenz Peña","provincia":"Chaco","pais":"ARGENTINA","latitud":-25.643616713412314,"longitud":-60.26597021106679,"ranking":0},
{"id":10436,"nombre":"City Tour Saenz Peña","localidad":"Presidencia Roque Saenz Peña","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10441,"nombre":"Termas de Saenz Peña","localidad":"Presidencia Roque Saenz Peña","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":921,"nombre":"Quitilipi","localidad":"Quitilipi","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.801285,"longitud":-60.215724,"ranking":76},
{"id":8012,"nombre":"Colonia Aborigen ","localidad":"Quitilipi","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.955911409726422,"longitud":-60.20293450732998,"ranking":0},
{"id":7817,"nombre":"Quitilipi Acceso","localidad":"Quitilipi","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.881826584451492,"longitud":-60.222056820546044,"ranking":0},
{"id":350,"nombre":" Resistencia","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.455898,"longitud":-59.022441,"ranking":6129},
{"id":2184,"nombre":"Resistencia 1 (EPU)","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2185,"nombre":"Resistencia A (EPU)","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2186,"nombre":"Resistencia B (EPU)","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2187,"nombre":"Rotonda Resistencia","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.412765518097668,"longitud":-59.00189900275603,"ranking":0},
{"id":10443,"nombre":"Navegacion Rio Negro","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10429,"nombre":"Aeropuerto Internacional de Resistencia","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10431,"nombre":"Hoteles Resistencia","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10433,"nombre":"City Tour Resistencia","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10434,"nombre":"Resistencia y sus Encantos","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10437,"nombre":"Cabalgata Humedales Chaco","localidad":"Resistencia","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":357,"nombre":"San Bernardo","localidad":"San Bernardo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.2910556,"longitud":-60.71468309999999,"ranking":5},
{"id":15386,"nombre":"Acceso San Bernardo","localidad":"San Bernardo","provincia":"Chaco","pais":"ARGENTINA","latitud":-36.68722959407392,"longitud":-56.70185183558005,"ranking":2},
{"id":352,"nombre":"Santa Sylvina","localidad":"Santa Sylvina","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.827715,"longitud":-61.137997,"ranking":50},
{"id":2188,"nombre":"Taco Pozo","localidad":"Taco Pozo","provincia":"Chaco","pais":"ARGENTINA","latitud":-25.303908,"longitud":-63.259558,"ranking":8},
{"id":361,"nombre":"Tres Isletas","localidad":"Tres Isletas","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.198956,"longitud":-60.434123,"ranking":73},
{"id":356,"nombre":"Villa Angela","localidad":"Villa Angela","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.295677,"longitud":-60.704704,"ranking":115},
{"id":583,"nombre":"Comodoro Rivadavia","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.863257,"longitud":-67.479986,"ranking":4281},
{"id":3578,"nombre":"Astra","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.73380420009373,"longitud":-67.49518006330678,"ranking":0},
{"id":3583,"nombre":"Malaspina","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4503,"nombre":"Diadema","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.777987407927235,"longitud":-67.66771859692705,"ranking":0},
{"id":14603,"nombre":"Gobernador Costa","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.04712190000001,"longitud":-70.5982111,"ranking":0},
{"id":4518,"nombre":"Ciudadela","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.79655083023377,"longitud":-67.49699524823528,"ranking":0},
{"id":5445,"nombre":"Estancia La Begonia","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5452,"nombre":"Terminal Centro","localidad":"Comodoro Rivadavia","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":564,"nombre":"Esquel","localidad":"Esquel","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.906061,"longitud":-71.313515,"ranking":868},
{"id":4206,"nombre":"Nahuel Pan","localidad":"Esquel","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.90043345121719,"longitud":-71.15723766245446,"ranking":0},
{"id":5666,"nombre":"Aeropuerto de Esquel - Brig. Gral Antonio Parodi","localidad":"Esquel","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.905576,"longitud":-71.146996,"ranking":0},
{"id":10902,"nombre":"Hoteles Esquel","localidad":"Esquel","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14604,"nombre":"Esquel Acceso","localidad":"Esquel","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.94883340000001,"longitud":-71.2157067,"ranking":0},
{"id":3427,"nombre":"Lago Puelo","localidad":"Lago Puelo","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.062045,"longitud":-71.598226,"ranking":18},
{"id":437,"nombre":"Puerto Madryn","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.7654301,"longitud":-65.0407362,"ranking":1203},
{"id":7454,"nombre":"Avenida Julio Argentino Roca 493","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.7685171,"longitud":-65.03194080000002,"ranking":0},
{"id":7456,"nombre":"Colonia Galesa","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.2898335,"longitud":-65.4870026,"ranking":0},
{"id":7457,"nombre":"Peninsula de Valdes","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.57191566294759,"longitud":-64.28110112669364,"ranking":0},
{"id":10896,"nombre":"Aeropuerto de Puerto Madryn \"El Tehuelche\"","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.7595765,"longitud":-65.1015412,"ranking":0},
{"id":10898,"nombre":"Hoteles Puerto Madryn","localidad":"Puerto Madryn","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.76736065306901,"longitud":-65.03631777052783,"ranking":0},
{"id":584,"nombre":"Rawson","localidad":"Rawson","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.3038743,"longitud":-65.0975427,"ranking":161},
{"id":438,"nombre":"Trelew","localidad":"Trelew","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.2485788,"longitud":-65.3029382,"ranking":779},
{"id":7455,"nombre":"Calle Italia 20","localidad":"Trelew","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.25244636796678,"longitud":-65.30525691476548,"ranking":0},
{"id":10895,"nombre":"Aeropuerto de Trelew \"Almirante A. Zar\"","localidad":"Trelew","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.209287,"longitud":-65.284346,"ranking":0},
{"id":10897,"nombre":"Hoteles Trelew","localidad":"Trelew","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.2563582,"longitud":-65.3013294,"ranking":0},
{"id":672,"nombre":"Abra Pampa","localidad":"Abra Pampa","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.721523,"longitud":-65.696994,"ranking":13},
{"id":2567,"nombre":"El Carmen","localidad":"El Carmen","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.3884799813738,"longitud":-65.25794797499118,"ranking":0},
{"id":670,"nombre":"Humahuaca","localidad":"Humahuaca","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.205205,"longitud":-65.347368,"ranking":430},
{"id":668,"nombre":"La Quiaca","localidad":"La Quiaca","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.102918,"longitud":-65.599809,"ranking":199},
{"id":320,"nombre":"Ledesma","localidad":"Ledesma","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.831764,"longitud":-64.791792,"ranking":0},
{"id":1086,"nombre":"Libertador General San Martin","localidad":"Ledesma","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.810179,"longitud":-64.787828,"ranking":82},
{"id":4053,"nombre":"Libertador General San Martin Acceso","localidad":"Ledesma","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.79697,"longitud":-64.78458130000001,"ranking":0},
{"id":408,"nombre":"Palpala","localidad":"Palpala","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.266629,"longitud":-65.211199,"ranking":92},
{"id":3234,"nombre":"Palpala Acceso","localidad":"Palpala","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.26780130610695,"longitud":-65.21427537808215,"ranking":0},
{"id":407,"nombre":"Perico","localidad":"Perico","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.387242,"longitud":-65.114891,"ranking":214},
{"id":4288,"nombre":"Perico Acceso","localidad":"Perico","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.371634,"longitud":-65.0996284,"ranking":0},
{"id":8749,"nombre":"Aeropuerto Gobernador Horacio Guzman","localidad":"Perico","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.3856849,"longitud":-65.0939864,"ranking":2},
{"id":323,"nombre":"San Salvador de Jujuy","localidad":"San Salvador de Jujuy","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.2214593,"longitud":-65.2719641,"ranking":2082},
{"id":14610,"nombre":"San Salvador de Jujuy - Zona Centro","localidad":"San Salvador de Jujuy","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.1857864,"longitud":-65.2994767,"ranking":0},
{"id":8748,"nombre":"ADN Travel, Necochea 251, Local 4","localidad":"San Salvador de Jujuy","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.185361302427534,"longitud":-65.29874547167817,"ranking":0},
{"id":1911,"nombre":"San Pedro de Jujuy","localidad":"San Pedro","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.2280929,"longitud":-64.86974,"ranking":33},
{"id":4054,"nombre":"San Pedro Acceso","localidad":"San Pedro","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.229744322815524,"longitud":-64.88023732077876,"ranking":0},
{"id":669,"nombre":"Tilcara","localidad":"Tilcara","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.576409,"longitud":-65.398612,"ranking":577},
{"id":671,"nombre":"Tres Cruces","localidad":"Tres Cruces","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.917929567434417,"longitud":-65.5871552826876,"ranking":0},
{"id":160,"nombre":"Chilecito","localidad":"Chilecito","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.176219,"longitud":-67.493816,"ranking":40},
{"id":1211,"nombre":"La Rioja","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.440477,"longitud":-66.861209,"ranking":1069},
{"id":1949,"nombre":"Bazan","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.173020331263015,"longitud":-66.6648525800791,"ranking":0},
{"id":1950,"nombre":"Cebollar","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.096388499691425,"longitud":-66.57020196764543,"ranking":0},
{"id":1951,"nombre":"El Tala","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1952,"nombre":"La Canada","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1953,"nombre":"Puerto Alegre","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.320249083790404,"longitud":-66.7714973797717,"ranking":0},
{"id":10106,"nombre":"San Blas de los Sauces","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.406308005919087,"longitud":-67.0907565362196,"ranking":0},
{"id":10107,"nombre":"Cuipan","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.45976560822164,"longitud":-67.12191577990009,"ranking":0},
{"id":10108,"nombre":"Pituil","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.57842582230636,"longitud":-67.46687467081817,"ranking":0},
{"id":10109,"nombre":"Los Robles","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7666,"nombre":"Schaqui","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.476337796140694,"longitud":-67.12793087379619,"ranking":0},
{"id":9067,"nombre":"La Rioja Acceso","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.383405849265078,"longitud":-66.80637014486682,"ranking":0},
{"id":10105,"nombre":"Salicas","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.375483448456762,"longitud":-67.0708067485967,"ranking":0},
{"id":3124,"nombre":"Cruce Ruta 32 y Ruta 38","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.6214383,"longitud":-65.7609502,"ranking":0},
{"id":4402,"nombre":"Alpasinche","localidad":"La Rioja","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.315366243915943,"longitud":-67.04822073542917,"ranking":0},
{"id":1469,"nombre":"Apostoles","localidad":"Apostoles","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.918961,"longitud":-55.750272,"ranking":138},
{"id":8397,"nombre":"Estación Apostoles","localidad":"Apostoles","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.91182719912648,"longitud":-55.80879736403544,"ranking":0},
{"id":2943,"nombre":"Aristobulo del Valle","localidad":"Aristobulo del Valle","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.093704,"longitud":-54.889634,"ranking":91},
{"id":8554,"nombre":"Cuña Pirú","localidad":"Aristobulo del Valle","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.059087512543076,"longitud":-55.03943556434166,"ranking":0},
{"id":2955,"nombre":"Campo Grande","localidad":"Campo Grande","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.204345,"longitud":-54.980388,"ranking":15},
{"id":3031,"nombre":"Campo Grande Acceso","localidad":"Campo Grande","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.219195136977035,"longitud":-54.97171185586647,"ranking":0},
{"id":2945,"nombre":"Campo Viera","localidad":"Campo Viera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.328743,"longitud":-55.052595,"ranking":14},
{"id":4009,"nombre":"Campo Viera Acceso","localidad":"Campo Viera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.31880629196822,"longitud":-55.05470870992455,"ranking":0},
{"id":2944,"nombre":"Capiovi","localidad":"Capiovi","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.9268636,"longitud":-55.0601573,"ranking":36},
{"id":8938,"nombre":"Capiovi Acceso","localidad":"Capiovi","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.934639039540784,"longitud":-55.06225985186563,"ranking":0},
{"id":2940,"nombre":"Eldorado","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.424919,"longitud":-54.637457,"ranking":594},
{"id":3552,"nombre":"Eldorado Acceso","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.424324974283856,"longitud":-54.64482583832689,"ranking":0},
{"id":5662,"nombre":"Santa Teresa","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7463,"nombre":"9 De Julio","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":-34.65167662172699,"longitud":-61.583490165953016,"ranking":0},
{"id":8534,"nombre":"Cantera Fock","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8549,"nombre":"Parehá","localidad":"El Dorado","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2946,"nombre":"Jardin America","localidad":"Jardin America","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.047054,"longitud":-55.24408,"ranking":214},
{"id":4608,"nombre":"Los Teales","localidad":"Jardin America","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.061542782617476,"longitud":-55.102181268990236,"ranking":0},
{"id":8558,"nombre":"Arroyo Tabay","localidad":"Jardin America","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2947,"nombre":"Obera","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.503552,"longitud":-55.120722,"ranking":617},
{"id":3991,"nombre":"Obera - Terminal de Expreso Singer","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4571,"nombre":"Picada San Martin","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.49498,"longitud":-55.121412,"ranking":0},
{"id":7465,"nombre":"Guarani Acceso","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.51717602666186,"longitud":-55.16193805843017,"ranking":0},
{"id":7475,"nombre":"Villa Svea","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.48939088794704,"longitud":-55.1478656263049,"ranking":0},
{"id":8568,"nombre":"El Barrero","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8569,"nombre":"Villa Sarubi","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9160,"nombre":"Villa Armonia","localidad":"Obera","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1471,"nombre":"Posadas","localidad":"Posadas","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.401523,"longitud":-55.914627,"ranking":6451},
{"id":8054,"nombre":"Miguel Lanus","localidad":"Posadas","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.42713143519413,"longitud":-55.879792547914505,"ranking":0},
{"id":8550,"nombre":"Concepcion de la Sierra","localidad":"Posadas","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.9940073,"longitud":-55.5199277,"ranking":0},
{"id":8551,"nombre":"Santa Maria Acceso","localidad":"Posadas","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.314841018363044,"longitud":-55.85083138805154,"ranking":0},
{"id":2948,"nombre":"Puerto Esperanza","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.021185,"longitud":-54.604631,"ranking":115},
{"id":3893,"nombre":"Puerto Esperanza Acceso","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.026476000,"longitud":-54.586515400,"ranking":0},
{"id":8581,"nombre":"Istueta","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8582,"nombre":"Puerto Segundo","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4614,"nombre":"Arroyo Aguaray Guazu","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8580,"nombre":"Helvecia","localidad":"Puerto Esperanza","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2939,"nombre":"Puerto Iguazú","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.5981578,"longitud":-54.5705509,"ranking":1767},
{"id":4710,"nombre":"Puerto Iguazu - Urbano","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.5972533,"longitud":-54.57862859999999,"ranking":0},
{"id":10837,"nombre":"Cataratas Argentinas","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.68316781654077,"longitud":-54.45472240447998,"ranking":0},
{"id":15437,"nombre":"Paseo en Balsa - Cataratas del Iguazu","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8747,"nombre":"Grand Crucero Iguazú Hotel","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.609463799943775,"longitud":-54.56949503756252,"ranking":0},
{"id":10820,"nombre":"Hoteles Centro Puerto Iguazú","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10821,"nombre":"Hoteles Ruta 12 Puerto Iguazú","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10822,"nombre":"Hoteles Selva Yriapu","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.611363328332175,"longitud":-54.56079281299787,"ranking":0},
{"id":10824,"nombre":"Aeropuerto Internacional de Puerto Iguazú","localidad":"Puerto Iguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.731651,"longitud":-54.47572,"ranking":2},
{"id":2949,"nombre":"Puerto Rico","localidad":"Puerto Rico","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.8082049,"longitud":-55.0207007,"ranking":235},
{"id":3894,"nombre":"Puerto Rico Acceso","localidad":"Puerto Rico","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.82080663609814,"longitud":-55.02262395017757,"ranking":0},
{"id":8587,"nombre":"Mbopicuá","localidad":"Puerto Rico","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.8635686,"longitud":-55.0451377,"ranking":0},
{"id":2951,"nombre":"San Ignacio","localidad":"San Ignacio","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.259631,"longitud":-55.529694,"ranking":95},
{"id":3895,"nombre":"San Ignacio Acceso","localidad":"San Ignacio","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.260878105895745,"longitud":-55.531576917297784,"ranking":0},
{"id":8557,"nombre":"Hipolito Yrigoyen","localidad":"San Ignacio","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.2637114,"longitud":-55.5341457,"ranking":3},
{"id":9069,"nombre":"Hipolito Yrigoyen Acceso","localidad":"San Ignacio","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.09342347150262,"longitud":-55.28970992772338,"ranking":0},
{"id":10838,"nombre":"Ruinas de San Ignacio y Minas de Wanda","localidad":"San Ignacio","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1470,"nombre":"San Jose","localidad":"San Jose","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.764400841378716,"longitud":-55.78581530689138,"ranking":0},
{"id":3021,"nombre":"Cruce San Jose","localidad":"San Jose","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.692585,"longitud":-55.785936,"ranking":48},
{"id":3089,"nombre":"Puente San jose","localidad":"San Jose","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.75085298862829,"longitud":-55.77842151979505,"ranking":0},
{"id":2952,"nombre":"San Vicente","localidad":"San Vicente","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.990973,"longitud":-54.485199,"ranking":194},
{"id":2950,"nombre":"Santa Ana","localidad":"Santa Ana","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.246147,"longitud":-55.530345,"ranking":9},
{"id":3892,"nombre":"Santa Ana Cruce de Caminos","localidad":"Santa Ana","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.376712,"longitud":-55.5758858,"ranking":10},
{"id":8563,"nombre":"Santa Ana Acceso","localidad":"Santa Ana","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.37542012705141,"longitud":-55.5768476887991,"ranking":0},
{"id":2954,"nombre":"Wanda Acceso","localidad":"Wanda","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.96608,"longitud":-54.571506,"ranking":1},
{"id":3091,"nombre":"Wanda","localidad":"Wanda","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.968308,"longitud":-54.571463,"ranking":61},
{"id":522,"nombre":"Cutral Co","localidad":"Cutral Co","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.929669,"longitud":-69.225293,"ranking":640},
{"id":580,"nombre":"Junin de Los Andes","localidad":"Junin de Los Andes","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.948548,"longitud":-71.07341,"ranking":93},
{"id":3872,"nombre":"Junin de Los Andes Acceso","localidad":"Junin de Los Andes","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":521,"nombre":"Neuquen","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.957511,"longitud":-68.105466,"ranking":6679},
{"id":1970,"nombre":"Aeropuerto Neuquen - Presidente Juan Domingo Peron","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.9521647,"longitud":-68.14034389999999,"ranking":77},
{"id":1973,"nombre":"Aguada el Overo","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.31770294567235,"longitud":-70.12341076756697,"ranking":0},
{"id":1974,"nombre":"Aguada Florencia","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1975,"nombre":"Cajon Chico","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1976,"nombre":"Campana Mahuida","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.19186559626224,"longitud":-70.60645663646083,"ranking":0},
{"id":10050,"nombre":"Naunauco Acceso","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.63653084985712,"longitud":-70.15975757500281,"ranking":0},
{"id":10130,"nombre":"Las Coloradas Acceso","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.719278,"longitud":-70.574246,"ranking":3},
{"id":10595,"nombre":"Volcan Lanin y Lago Huechulafquen","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3945,"nombre":"Huayquimil","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.22921643803095,"longitud":-70.3075363288199,"ranking":0},
{"id":3946,"nombre":"Corral de Piedra","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.435074500,"longitud":-70.644514600,"ranking":0},
{"id":4451,"nombre":"Empalme Ruta 237","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.07680209999999,"longitud":-68.5910933,"ranking":0},
{"id":4452,"nombre":"Puente Pulmari","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.082805800,"longitud":-70.964212400,"ranking":0},
{"id":5484,"nombre":"Parque Industrial ","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3938,"nombre":"Taquimilan","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.475130600,"longitud":-70.211820600,"ranking":0},
{"id":3940,"nombre":"El Mangrullo","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.357130700,"longitud":-69.029846200,"ranking":0},
{"id":3941,"nombre":"Cabo Alarcon","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.519502600,"longitud":-69.295792600,"ranking":0},
{"id":3942,"nombre":"La Picaza","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.5019972,"longitud":-70.3360691,"ranking":0},
{"id":3943,"nombre":"Carral Cura Acceso","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3944,"nombre":"Sañico","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.077575500,"longitud":-68.589706400,"ranking":0},
{"id":3931,"nombre":"Cuchillo Cura","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.6111941,"longitud":-70.3506074,"ranking":0},
{"id":3932,"nombre":"Entrada El Agrio","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.370718,"longitud":-70.093769,"ranking":0},
{"id":3934,"nombre":"Churriaca","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.961788700,"longitud":-70.342889400,"ranking":0},
{"id":3935,"nombre":"Huitrin","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.334116000,"longitud":-70.074920700,"ranking":0},
{"id":3936,"nombre":"Pichi Neuquen","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.131308300,"longitud":-69.792709400,"ranking":0},
{"id":3937,"nombre":"Naunauco","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.63649816222412,"longitud":-70.15978575515378,"ranking":0},
{"id":2005,"nombre":"Santo Domingo","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.958874900,"longitud":-68.119869200,"ranking":0},
{"id":2006,"nombre":"Senillosa","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.925518,"longitud":-68.219978,"ranking":19},
{"id":2007,"nombre":"Vega Maipu","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.131308300,"longitud":-69.792709400,"ranking":0},
{"id":3930,"nombre":"La Cantera","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1998,"nombre":"Parador Neuquen","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1999,"nombre":"Plotier","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2000,"nombre":"Pozo Hualicho","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2001,"nombre":"Puente Picun Leufu","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.160747000,"longitud":-70.448305600,"ranking":0},
{"id":2002,"nombre":"Quilquihue","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.0698417,"longitud":-71.3049485,"ranking":0},
{"id":2004,"nombre":"Riscos Bayos","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1991,"nombre":"La Negra","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1992,"nombre":"Las Carpas","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.616267900,"longitud":-70.386657700,"ranking":0},
{"id":1993,"nombre":"Las Coloradas","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.559288447851806,"longitud":-70.59352549447837,"ranking":0},
{"id":1995,"nombre":"Los Cuarteles","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1996,"nombre":"Los Pozones","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.160747000,"longitud":-70.448455800,"ranking":0},
{"id":1997,"nombre":"Namuncura","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.987568000,"longitud":-68.974399600,"ranking":0},
{"id":1985,"nombre":"Covunco","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1986,"nombre":"El Salitral","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.826467800,"longitud":-70.627498600,"ranking":0},
{"id":1987,"nombre":"El Sauce","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.959408800,"longitud":-68.109741200,"ranking":0},
{"id":1988,"nombre":"Estacion Huechahue","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.719837200,"longitud":-70.303261800,"ranking":0},
{"id":1989,"nombre":"Hualcupen","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.98667281548194,"longitud":-70.62960673992745,"ranking":0},
{"id":1990,"nombre":"Huarenchenque","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1978,"nombre":"Caviahue","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1979,"nombre":"Cerro Bandera","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1980,"nombre":"Challaco","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.945525300,"longitud":-69.136276200,"ranking":0},
{"id":1981,"nombre":"China Muerta","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.98402435930134,"longitud":-68.31966265984904,"ranking":0},
{"id":1982,"nombre":"Codihue","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.46647737954004,"longitud":-70.48984621079381,"ranking":0},
{"id":1984,"nombre":"Cortaderas","localidad":"Neuquen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.390272200,"longitud":-70.157876000,"ranking":0},
{"id":560,"nombre":"Piedra del Aguila","localidad":"Piedra del Aguila","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.052574,"longitud":-70.08015,"ranking":37},
{"id":3410,"nombre":"Piedra del Aguila Acceso","localidad":"Piedra del Aguila","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.0536983,"longitud":-70.0813893,"ranking":0},
{"id":579,"nombre":"San Martin de Los Andes","localidad":"San Martin de Los Andes","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.160482,"longitud":-71.357583,"ranking":451},
{"id":9888,"nombre":"Transfer San Martin de los Andes","localidad":"San Martin de Los Andes","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10594,"nombre":"Hoteles San Martin de los Andes","localidad":"San Martin de Los Andes","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.1568798,"longitud":-71.35249350000001,"ranking":3},
{"id":2383,"nombre":"Villa La Angostura","localidad":"Villa La Angostura","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.762727,"longitud":-71.638391,"ranking":870},
{"id":524,"nombre":" Zapala","localidad":"Zapala","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.905927,"longitud":-70.068323,"ranking":463},
{"id":9750,"nombre":"Laguna Blanca","localidad":"Zapala","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.045102700,"longitud":-70.326710300,"ranking":0},
{"id":9755,"nombre":"Nireco","localidad":"Zapala","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.224706900,"longitud":-70.563082700,"ranking":0},
{"id":403,"nombre":"Cipolletti","localidad":"Cipolletti","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.942007,"longitud":-68.008066,"ranking":3066},
{"id":9972,"nombre":"Parque Central","localidad":"Cipolletti","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2012,"nombre":"Chimpay","localidad":"Chimpay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.162196,"longitud":-66.152458,"ranking":389},
{"id":2013,"nombre":"Lamarque","localidad":"Chimpay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.414518,"longitud":-65.695095,"ranking":147},
{"id":9186,"nombre":"Chimpay Acceso","localidad":"Chimpay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.16227653418868,"longitud":-66.1384938522815,"ranking":0},
{"id":9671,"nombre":"Predio Ceferino Namuncura","localidad":"Chimpay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.171511351597665,"longitud":-66.1383767785111,"ranking":0},
{"id":645,"nombre":"Choele Choel","localidad":"Choele Choel","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.290323,"longitud":-65.656615,"ranking":897},
{"id":2393,"nombre":"Choele Choel Acceso","localidad":"Choele Choel","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.28580249566558,"longitud":-65.66273601673528,"ranking":0},
{"id":563,"nombre":"El Bolson","localidad":"El Bolson","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.9625776,"longitud":-71.5385927,"ranking":958},
{"id":14626,"nombre":"El Bolson Acceso","localidad":"El Bolson","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.9314987,"longitud":-71.53637189999999,"ranking":0},
{"id":547,"nombre":"General Roca","localidad":"General Roca","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.040951,"longitud":-67.576417,"ranking":1226},
{"id":9760,"nombre":"Coronel J J Gómez","localidad":"General Roca","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.02227368380928,"longitud":-67.63498426149425,"ranking":0},
{"id":14598,"nombre":"General Roca Acceso ","localidad":"General Roca","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.0396025,"longitud":-67.6127317,"ranking":0},
{"id":3233,"nombre":"Ingeniero Huergo","localidad":"Ingeniero Huergo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.073816,"longitud":-67.238131,"ranking":38},
{"id":4480,"nombre":"Ingeniero Huergo Acceso","localidad":"Ingeniero Huergo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.0780253,"longitud":-67.244364,"ranking":0},
{"id":587,"nombre":"Las Grutas","localidad":"Las Grutas","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.791271,"longitud":-65.071478,"ranking":696},
{"id":4194,"nombre":"Las Grutas Acceso","localidad":"Las Grutas","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.749554654220084,"longitud":-65.12018540452976,"ranking":0},
{"id":15494,"nombre":"Hoteles Las Grutas","localidad":"Las Grutas","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":589,"nombre":"Rio Colorado","localidad":"Rio Colorado ","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.991659,"longitud":-64.105311,"ranking":649},
{"id":8378,"nombre":"Rio Colorado Acceso","localidad":"Rio Colorado ","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.982805923343435,"longitud":-64.11088912931773,"ranking":0},
{"id":562,"nombre":"San Carlos de Bariloche","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.130458,"longitud":-71.27197,"ranking":6156},
{"id":3051,"nombre":"Hoteles Bariloche","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3596,"nombre":"Aeropuerto de Bariloche - Tte Candelaria","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.134601,"longitud":-71.164625,"ranking":561},
{"id":3873,"nombre":"San Carlos Bariloche Acceso","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.11700613272427,"longitud":-71.22671987245842,"ranking":0},
{"id":9921,"nombre":"Valle del Challhua-co","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9922,"nombre":"Laguna Congelada","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8606,"nombre":"Cerro Tronador","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8607,"nombre":"Siete Lagos","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8608,"nombre":"Bosque de Arrayanes","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8941,"nombre":"Km 8 - Bariloche","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8942,"nombre":"Km 12 - Bariloche","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8943,"nombre":"Hotel Llao-Llao","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.05874160604836,"longitud":-71.530637961564,"ranking":0},
{"id":8601,"nombre":"Circuito Chico","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8602,"nombre":"Hostel Condor","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.04443761442938,"longitud":-62.82801646400587,"ranking":0},
{"id":8603,"nombre":"Mitre 219 Turisur","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8604,"nombre":"Cascada los Cantaros","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8605,"nombre":"Cerro Catedral","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.1685908,"longitud":-71.4402564,"ranking":0},
{"id":5665,"nombre":"Km 4 - Bariloche","localidad":"San Carlos de Bariloche","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":436,"nombre":"San Antonio Oeste","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.7331,"longitud":-64.943783,"ranking":1494},
{"id":3510,"nombre":"San Antonio Oeste Acceso","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.71274566318992,"longitud":-65.0024630394416,"ranking":0},
{"id":9695,"nombre":"Puerto Sae","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.79860606011278,"longitud":-64.88444324996418,"ranking":0},
{"id":9689,"nombre":"Cruce Puerto","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.7260261,"longitud":-64.93351559999999,"ranking":0},
{"id":9690,"nombre":"Empalme Ruta 23","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.818124,"longitud":-65.3419318,"ranking":0},
{"id":9691,"nombre":"Empalme Ruta 251","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9692,"nombre":"Las Conchillas","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9693,"nombre":"Paraje Pioppi","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9694,"nombre":"Puerto del Este","localidad":"San Antonio Oeste","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.72631170641327,"longitud":-64.93717456536369,"ranking":0},
{"id":585,"nombre":"Sierra Grande","localidad":"Sierra Grande","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.60866,"longitud":-65.353857,"ranking":666},
{"id":3659,"nombre":"Sierra Grande Acceso","localidad":"Sierra Grande","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.613765041494695,"longitud":-65.35427411476765,"ranking":0},
{"id":713,"nombre":"Viedma","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.82048,"longitud":-63.004009,"ranking":3648},
{"id":2008,"nombre":"Ascasubi","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2010,"nombre":"Origones","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9698,"nombre":"Idevi","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.813178,"longitud":-63.004009,"ranking":1},
{"id":9699,"nombre":"Paraje Nuevo Leon","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.746682431799854,"longitud":-64.24863475973721,"ranking":0},
{"id":9700,"nombre":"Paraje Oconnors","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9701,"nombre":"Paraje Winter","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.90052,"longitud":-64.463853,"ranking":0},
{"id":15493,"nombre":"Aeropuerto de Viedma","localidad":"Viedma","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.8628997,"longitud":-63.0055652,"ranking":0},
{"id":646,"nombre":"Villa Regina","localidad":"Villa Regina","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.097027,"longitud":-67.086758,"ranking":627},
{"id":675,"nombre":"Aguas Blancas","localidad":"Aguas Blancas","provincia":"Salta","pais":"ARGENTINA","latitud":-22.734855,"longitud":-64.351326,"ranking":6},
{"id":420,"nombre":"Embarcacion","localidad":"Embarcacion","provincia":"Salta","pais":"ARGENTINA","latitud":-23.20945,"longitud":-64.101969,"ranking":0},
{"id":7791,"nombre":"Embarcacion Acceso","localidad":"Embarcacion","provincia":"Salta","pais":"ARGENTINA","latitud":-23.196444,"longitud":-64.0880106,"ranking":0},
{"id":665,"nombre":"General Guemes","localidad":"General Guemes","provincia":"Salta","pais":"ARGENTINA","latitud":-24.66754,"longitud":-65.050821,"ranking":163},
{"id":2907,"nombre":"General Guemes Acceso","localidad":"General Guemes","provincia":"Salta","pais":"ARGENTINA","latitud":-24.667648809234947,"longitud":-65.05253675438342,"ranking":0},
{"id":7792,"nombre":"Campo Santo","localidad":"General Guemes","provincia":"Salta","pais":"ARGENTINA","latitud":-24.680383799360467,"longitud":-65.10149992640551,"ranking":0},
{"id":421,"nombre":"General Mosconi","localidad":"General Mosconi","provincia":"Salta","pais":"ARGENTINA","latitud":-22.595034,"longitud":-63.811929,"ranking":0},
{"id":2895,"nombre":"General Mosconi Acceso","localidad":"General Mosconi","provincia":"Salta","pais":"ARGENTINA","latitud":-22.59262355076801,"longitud":-63.80325457186561,"ranking":0},
{"id":294,"nombre":"Metan","localidad":"Metan","provincia":"Salta","pais":"ARGENTINA","latitud":-25.49546,"longitud":-64.974548,"ranking":76},
{"id":2543,"nombre":"Metan Acceso","localidad":"Metan","provincia":"Salta","pais":"ARGENTINA","latitud":-25.510893247253723,"longitud":-64.97944753654467,"ranking":0},
{"id":674,"nombre":"Oran","localidad":"Oran","provincia":"Salta","pais":"ARGENTINA","latitud":-23.128029,"longitud":-64.32846,"ranking":187},
{"id":419,"nombre":"Pichanal","localidad":"Pichanal","provincia":"Salta","pais":"ARGENTINA","latitud":-23.314077,"longitud":-64.226205,"ranking":2},
{"id":2890,"nombre":"Pichanal Acceso","localidad":"Pichanal","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2573,"nombre":"Pocitos","localidad":"Pocitos","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4491,"nombre":"Cruce Pocitos","localidad":"Pocitos","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":322,"nombre":"Rosario de la Frontera","localidad":"Rosario de la Frontera","provincia":"Salta","pais":"ARGENTINA","latitud":-25.810364,"longitud":-64.962118,"ranking":51},
{"id":2475,"nombre":"Rosario de la Frontera Acceso","localidad":"Rosario de la Frontera","provincia":"Salta","pais":"ARGENTINA","latitud":-25.814108799345814,"longitud":-64.96273951076466,"ranking":0},
{"id":321,"nombre":"Salta","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-24.795725,"longitud":-65.397913,"ranking":3950},
{"id":1685,"nombre":"Coronel Olleros","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.100569370196173,"longitud":-64.220185616997,"ranking":0},
{"id":1686,"nombre":"El Galpon","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.388586500,"longitud":-64.656815500,"ranking":0},
{"id":1687,"nombre":"El Quebrachal","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.334348800,"longitud":-64.020144900,"ranking":0},
{"id":14417,"nombre":"El Quebrachal Acceso","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.3446294,"longitud":-64.0219335,"ranking":1},
{"id":14553,"nombre":"Estacion de Trenes Salta","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-24.7771852,"longitud":-65.4112971,"ranking":0},
{"id":15435,"nombre":"Aeropuerto de Salta","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-24.844419860323285,"longitud":-65.47850695990061,"ranking":5},
{"id":15436,"nombre":"Hoteles Centro Salta","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":5},
{"id":8136,"nombre":"San Rafael","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8660,"nombre":"City Tour Salta","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8661,"nombre":"Balcarce 723","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-24.78059371684249,"longitud":-65.41149067084476,"ranking":0},
{"id":9078,"nombre":"El Galpon Acceso","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.389021190667314,"longitud":-64.65272825422834,"ranking":0},
{"id":7550,"nombre":"El potrero","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7552,"nombre":"Dique Las Colas","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7553,"nombre":"Copo Quille","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7558,"nombre":"Balboa ","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7559,"nombre":"Alte. Brown","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7590,"nombre":"Hipolito Yrigoyen ","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-23.244179512126188,"longitud":-64.27000462851558,"ranking":0},
{"id":7500,"nombre":"San Roque","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-24.844169239280028,"longitud":-65.43196686696574,"ranking":0},
{"id":7503,"nombre":"Los Tapires","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7540,"nombre":"Los Baños","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.832927771636765,"longitud":-64.91494548640294,"ranking":0},
{"id":2886,"nombre":"Yatasto","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4476,"nombre":"Ruta 34 Y 9 Comb/ SLT","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1688,"nombre":"El Tunal","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.253202070784184,"longitud":-64.39074265352589,"ranking":0},
{"id":1690,"nombre":"Gaona","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.240736600,"longitud":-64.039585600,"ranking":0},
{"id":1692,"nombre":"Gral. Ballivan","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-22.936605273585776,"longitud":-63.860696718023426,"ranking":0},
{"id":1693,"nombre":"Joaquin V.Gonzalez","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.119934,"longitud":-64.125371,"ranking":9},
{"id":1695,"nombre":"Palpala - SLA","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1697,"nombre":"Tolloche","localidad":"Salta","provincia":"Salta","pais":"ARGENTINA","latitud":-25.528062048211954,"longitud":-63.52697263393014,"ranking":0},
{"id":950,"nombre":"Salvador Mazza (Pocitos)","localidad":"Salvador Mazza","provincia":"Salta","pais":"ARGENTINA","latitud":-22.050688,"longitud":-63.687797,"ranking":8},
{"id":4056,"nombre":"Acceso Pocitos","localidad":"Salvador Mazza","provincia":"Salta","pais":"ARGENTINA","latitud":-22.053835370585993,"longitud":-63.68466939370863,"ranking":0},
{"id":422,"nombre":"Tartagal","localidad":"Tartagal","provincia":"Salta","pais":"ARGENTINA","latitud":-22.516273,"longitud":-63.794195,"ranking":23},
{"id":2896,"nombre":"Tartagal Acceso","localidad":"Tartagal","provincia":"Salta","pais":"ARGENTINA","latitud":-22.499374552098104,"longitud":-63.79430351017998,"ranking":0},
{"id":424,"nombre":"Caucete","localidad":"Caucete","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.499517,"longitud":-68.292266,"ranking":144},
{"id":2557,"nombre":"Caucete Acceso","localidad":"Caucete","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.64486626988534,"longitud":-68.28652471847917,"ranking":0},
{"id":8515,"nombre":"Villa Independencia","localidad":"Caucete","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.63309787137932,"longitud":-68.29830773966735,"ranking":0},
{"id":409,"nombre":"San Juan","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.539064,"longitud":-68.511289,"ranking":8561},
{"id":2018,"nombre":"Bermejo","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.607633134459906,"longitud":-67.65407819073755,"ranking":2},
{"id":2019,"nombre":"Empalme","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2020,"nombre":"Guayamas","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.717777778383788,"longitud":-68.13484322896966,"ranking":0},
{"id":2021,"nombre":"Marayes","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2023,"nombre":"Nueva Castilla","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12138,"nombre":"Hoteles Centro San Juan","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.53642084206618,"longitud":-68.53687955414985,"ranking":0},
{"id":15660,"nombre":"Estacion San Juan","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.5387369,"longitud":-68.5361189,"ranking":0},
{"id":8512,"nombre":"Algarrobito","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8513,"nombre":"Colonia San Pedro","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.2262547,"longitud":-67.46246479999999,"ranking":0},
{"id":8516,"nombre":"Vicia","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10102,"nombre":"Tierra Adentro","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12137,"nombre":"Aeropuerto de San Juan","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.571291029081618,"longitud":-68.42254984640924,"ranking":0},
{"id":2027,"nombre":"Villa Krausse","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.566191761121047,"longitud":-68.53435982419425,"ranking":0},
{"id":4558,"nombre":"San Expedito","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.600132,"longitud":-67.679509,"ranking":0},
{"id":8277,"nombre":"Matagusanos","localidad":"San Juan","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.245467759772346,"longitud":-68.64219246753471,"ranking":0},
{"id":478,"nombre":"Justo Daract","localidad":"Justo Daract","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.857597,"longitud":-65.181768,"ranking":56},
{"id":1342,"nombre":"Justo Daract Acceso","localidad":"Justo Daract","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.841274281284264,"longitud":-65.17172934903682,"ranking":0},
{"id":82,"nombre":"Merlo","localidad":"Merlo","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.349981,"longitud":-65.016382,"ranking":1452},
{"id":334,"nombre":"San Luis","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.294184,"longitud":-66.297006,"ranking":8537},
{"id":7382,"nombre":"Plaza Pringles - San Martín 874","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7384,"nombre":"Potrero de Los Funes","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.21974093706755,"longitud":-66.23080812346608,"ranking":0},
{"id":7385,"nombre":"Sierras y Diques","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7583,"nombre":"Cruce de La Paz","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9203,"nombre":"Daniel Donovan","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.34185390559635,"longitud":-66.22911498396908,"ranking":0},
{"id":10753,"nombre":"San Rafael","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10872,"nombre":"Puente de Las Horquetas","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":-34.1002292,"longitud":-66.7101303,"ranking":0},
{"id":15385,"nombre":"Hoteles Centro San Luis","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7632,"nombre":"Candelaria","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7635,"nombre":"Cuatro esquinas","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7637,"nombre":"Bajo Hondo","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7638,"nombre":"Ulapes","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9083,"nombre":"Pozo Cavado","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9117,"nombre":"Nueva Gaila Acceso","localidad":"San Luis","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.11789356617708,"longitud":-65.25257282442813,"ranking":0},
{"id":515,"nombre":"Union","localidad":"Union","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.152143733691105,"longitud":-65.94257018438383,"ranking":0},
{"id":2439,"nombre":"Union Acceso","localidad":"Union","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.15212,"longitud":-65.942567,"ranking":0},
{"id":469,"nombre":"Villa Mercedes","localidad":"Villa Mercedes","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.664929,"longitud":-65.466039,"ranking":3105},
{"id":2440,"nombre":"Villa Mercedes Acceso","localidad":"Villa Mercedes","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.645921806440356,"longitud":-65.45993325840946,"ranking":0},
{"id":582,"nombre":"Caleta Olivia","localidad":"Caleta Olivia","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.43924,"longitud":-67.540111,"ranking":2336},
{"id":620,"nombre":"Luis Piedrabuena","localidad":"Luis Piedrabuena","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.982616,"longitud":-68.908911,"ranking":248},
{"id":3621,"nombre":"Luis Piedrabuena Acceso","localidad":"Luis Piedrabuena","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":619,"nombre":"Puerto San Julian","localidad":"Puerto San Julian","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.304169,"longitud":-67.736117,"ranking":485},
{"id":3623,"nombre":"Puerto San Julian Acceso","localidad":"Puerto San Julian","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":621,"nombre":"Rio Gallegos","localidad":"Rio Gallegos","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.627822,"longitud":-69.2477,"ranking":1643},
{"id":14504,"nombre":"Zona Franca","localidad":"Rio Gallegos","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.70555419999999,"longitud":-69.2501621,"ranking":0},
{"id":545,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.62008,"longitud":-64.280622,"ranking":2033},
{"id":1927,"nombre":"El Carancho","localidad":"Santa Rosa","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.44542517079526,"longitud":-65.04490915444525,"ranking":0},
{"id":3665,"nombre":"Santa Rosa Acceso","localidad":"Santa Rosa","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.5977532,"longitud":-64.2246408,"ranking":0},
{"id":1515,"nombre":"Ushuaia","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":-54.808111,"longitud":-68.306071,"ranking":59},
{"id":15394,"nombre":"Aeropuerto de Ushuaia","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":-54.8393888046323,"longitud":-68.3122441000138,"ranking":1},
{"id":15388,"nombre":"Muelle Turístico","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15389,"nombre":"Isla de Lobos","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15390,"nombre":"Pingüinera","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15393,"nombre":"Hoteles Ushuaia","localidad":"Ushuaia","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":51,"nombre":"Villa Tesei - Gaona y Vergara","localidad":"Villa Tesei","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6311565,"longitud":-58.6295056,"ranking":154},
{"id":1547,"nombre":"Terminal del Oeste","localidad":"Villa Tesei","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":73,"nombre":"Pilar","localidad":"Pilar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4576829,"longitud":-58.9131775,"ranking":458},
{"id":4148,"nombre":"Villa Rosa","localidad":"Pilar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.43993210258843,"longitud":-58.879955463334255,"ranking":0},
{"id":14625,"nombre":"Pilar Acceso","localidad":"Pilar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.45412939853097,"longitud":-58.90871286392212,"ranking":2},
{"id":15648,"nombre":"Cruce Pilar","localidad":"Pilar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":77,"nombre":"Achiras","localidad":"Achiras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.184929,"longitud":-64.996847,"ranking":0},
{"id":1742,"nombre":"Achiras Acceso","localidad":"Achiras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.1839857,"longitud":-64.99642659999999,"ranking":0},
{"id":79,"nombre":"Villa del Carmen","localidad":"Villa del Carmen","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.94365974775929,"longitud":-65.04019488201259,"ranking":1},
{"id":80,"nombre":"Villa Larca","localidad":"Villa Larca","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.617892,"longitud":-64.982711,"ranking":29},
{"id":2039,"nombre":"Villa Larca Acceso","localidad":"Villa Larca","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.617826,"longitud":-64.982554,"ranking":0},
{"id":86,"nombre":"Santa Rosa del Conlara Acceso","localidad":"Santa Rosa del Conlara","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.34186420256966,"longitud":-65.17454000867589,"ranking":0},
{"id":2045,"nombre":"Santa Rosa del Conlara","localidad":"Santa Rosa del Conlara","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.342921,"longitud":-65.207069,"ranking":70},
{"id":118,"nombre":"La Paz","localidad":"La Paz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2161794,"longitud":-65.04896099999999,"ranking":10},
{"id":1380,"nombre":"Cruce La Paz","localidad":"La Paz","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":93,"nombre":"Los Hornillos","localidad":"Los Hornillos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9034363,"longitud":-64.9900756,"ranking":21},
{"id":2161,"nombre":"Los Hornillos Acceso","localidad":"Los Hornillos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":94,"nombre":"Las Rabonas","localidad":"Las Rabonas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8534352,"longitud":-64.9786143,"ranking":20},
{"id":9892,"nombre":"Las Rabonas Acceso","localidad":"Las Rabonas","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2809,"nombre":"Los Nonos","localidad":"Los Nonos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9486,"nombre":"Nono Acceso","localidad":"Los Nonos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":96,"nombre":"Cura Brochero","localidad":"Cura Brochero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9385890,"longitud":-65.1875224,"ranking":38},
{"id":115,"nombre":"Gonzalez Catan","localidad":"Gonzalez Catan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7769197,"longitud":-58.6408186,"ranking":31},
{"id":7908,"nombre":"Virrey del Pino ","localidad":"Gonzalez Catan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":114,"nombre":"Coronel Dorrego","localidad":"Coronel Dorrego","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.720729,"longitud":-61.28655,"ranking":132},
{"id":2386,"nombre":"Coronel Dorrego Acceso","localidad":"Coronel Dorrego","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.7387872,"longitud":-61.2625337,"ranking":4},
{"id":5480,"nombre":"Oriente - Plaza central","localidad":"Coronel Dorrego","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.720386,"longitud":-61.286686,"ranking":0},
{"id":113,"nombre":"Laferrere","localidad":"Laferrere","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.749705000,"longitud":-58.586049000,"ranking":25},
{"id":2813,"nombre":"Laferrere  Rocamora","localidad":"Laferrere","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":112,"nombre":"Castillo","localidad":"Castillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":107,"nombre":"Escobar","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3552786,"longitud":-58.7969945,"ranking":463},
{"id":1194,"nombre":"Escobar Acceso","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3560053,"longitud":-58.7972993,"ranking":49},
{"id":1525,"nombre":"Garin Centro","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.42180034876213,"longitud":-58.73683469740921,"ranking":0},
{"id":5488,"nombre":"Escobar Parador","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3578933,"longitud":-58.7995429,"ranking":22},
{"id":8708,"nombre":"Temaiken","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3668731610213,"longitud":-58.807372919244486,"ranking":0},
{"id":10034,"nombre":"Matheu","localidad":"Escobar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.38082825936781,"longitud":-58.8299095642859,"ranking":0},
{"id":92,"nombre":"Vilelas","localidad":"Vilelas","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.959143,"longitud":-62.615457,"ranking":0},
{"id":101,"nombre":"Malbran","localidad":"Malbran","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.34855809999999,"longitud":-62.44204380000001,"ranking":0},
{"id":1623,"nombre":"Malbran Acceso","localidad":"Malbran","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.346772180790925,"longitud":-62.43271517213488,"ranking":0},
{"id":487,"nombre":"Canals","localidad":"Canals","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.56223725839614,"longitud":-62.88017831777146,"ranking":2},
{"id":1557,"nombre":"Canals Acceso","localidad":"Canals","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.559526,"longitud":-62.875648,"ranking":48},
{"id":567,"nombre":"Arias","localidad":"Arias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.63985,"longitud":-62.402398,"ranking":77},
{"id":2108,"nombre":"Arias Acceso","localidad":"Arias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.639864,"longitud":-62.402402,"ranking":24},
{"id":1021,"nombre":"Ledesma Alejo","localidad":"Ledesma Alejo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.604999,"longitud":-62.623609,"ranking":0},
{"id":2107,"nombre":"Ledesma Alejo Acceso","localidad":"Ledesma Alejo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.60598,"longitud":-62.626695,"ranking":37},
{"id":1378,"nombre":"Tigre","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4439,"nombre":"Tigre-Agencia Cazon","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8711,"nombre":"Estacion Fluvial Tigre","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4211841,"longitud":-58.5809143,"ranking":0},
{"id":8712,"nombre":"Delta Terra","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8715,"nombre":"Lanchas Taxy","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8716,"nombre":"Amarran Sancho","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15473,"nombre":"Puerto de Frutos","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4197967,"longitud":-58.5723503,"ranking":0},
{"id":15570,"nombre":"Paseo 5 Rios","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.40428664936291,"longitud":-58.52095742025701,"ranking":0},
{"id":15571,"nombre":"Estacion Fluvial Internacional","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4211841,"longitud":-58.5809143,"ranking":0},
{"id":8720,"nombre":"Cinco Rios","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9215,"nombre":"Estacion Trenes TBA Tigre","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.423522790799524,"longitud":-58.581756949732146,"ranking":0},
{"id":9216,"nombre":"Antigua Aduana Tigre","localidad":"Tigre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":65,"nombre":"Asuncion","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.3260941,"longitud":-57.5954838,"ranking":5979},
{"id":2650,"nombre":"Cementerio del Este","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.2933597,"longitud":-57.54879769999999,"ranking":0},
{"id":2727,"nombre":"Las Lomas","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.27829,"longitud":-57.569737,"ranking":0},
{"id":2860,"nombre":"Shopping Multiplaza","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.317059,"longitud":-57.571718,"ranking":2},
{"id":2872,"nombre":"Loma Pyta","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.2543789,"longitud":-57.5463214,"ranking":6},
{"id":15527,"nombre":"Hoteles Asuncion","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":8687,"nombre":"San Vicente","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-35.036057,"longitud":-58.41323,"ranking":0},
{"id":14634,"nombre":"Mercado 4","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.2984692,"longitud":-57.6245869,"ranking":0},
{"id":15525,"nombre":"Aeropuerto de Asuncion","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.2416738,"longitud":-57.5140129,"ranking":0},
{"id":2873,"nombre":"Ñu Guazu","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.2630066,"longitud":-57.5418977,"ranking":0},
{"id":4122,"nombre":"Parada Luque","localidad":"Assunção - Asunción","provincia":"CAPITAL","pais":"Paraguay","latitud":-25.286549,"longitud":-57.499983,"ranking":36},
{"id":1384,"nombre":"Encarnacion","localidad":"Encarnacion","provincia":"Itapua","pais":"Paraguay","latitud":-27.339962,"longitud":-55.865178,"ranking":1375},
{"id":2855,"nombre":"Iglesia Catedral de Encarnación","localidad":"Encarnacion","provincia":"Itapua","pais":"Paraguay","latitud":-27.338796,"longitud":-55.867134,"ranking":0},
{"id":15528,"nombre":"Aeropuerto de Encarnacion","localidad":"Encarnacion","provincia":"Itapua","pais":"Paraguay","latitud":-27.2206958,"longitud":-55.8315679,"ranking":0},
{"id":15529,"nombre":"Hoteles Encarnacion","localidad":"Encarnacion","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14238,"nombre":"Colonia Santo Domingo","localidad":"Encarnacion","provincia":"Itapua","pais":"Paraguay","latitud":-24.3878444,"longitud":-55.1246416,"ranking":0},
{"id":52,"nombre":"Reduccion","localidad":"Reduccion","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.200025,"longitud":-63.86329,"ranking":31},
{"id":3306,"nombre":"Reduccion Acceso","localidad":"Reduccion","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4412,"nombre":"La Granja","localidad":"La Granja","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.06377,"longitud":-64.300253,"ranking":60},
{"id":62,"nombre":"San Francisco Solano","localidad":"San Francisco Solano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7914619,"longitud":-58.3115673,"ranking":74},
{"id":4789,"nombre":"San Francisco Solano Cruce","localidad":"San Francisco Solano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.791452,"longitud":-58.311685,"ranking":3},
{"id":378,"nombre":"San Pedro","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.6794868,"longitud":-59.6610630,"ranking":0},
{"id":917,"nombre":"San Pedro Acceso","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.67864,"longitud":-59.661069,"ranking":0},
{"id":1274,"nombre":"Parador La Granja","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.75527140841093,"longitud":-59.748984575271606,"ranking":0},
{"id":2075,"nombre":"Gobernador Castro Acceso","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.69681182969983,"longitud":-59.88336601792281,"ranking":0},
{"id":2076,"nombre":"Rio Tala Acceso","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.77768640067231,"longitud":-59.63326562622279,"ranking":0},
{"id":15387,"nombre":"Colprim San Pedro","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.77808740589272,"longitud":-59.638101674117664,"ranking":0},
{"id":7593,"nombre":"Rio Tala","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.78526,"longitud":-59.639742,"ranking":0},
{"id":10832,"nombre":"San Pedro Parador","localidad":"San Pedro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.75889799999999,"longitud":-59.7035443,"ranking":0},
{"id":68,"nombre":"José Falcón","localidad":"José Falcón","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-25.257907,"longitud":-57.715481,"ranking":0},
{"id":719,"nombre":"Adela","localidad":"Adela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.685511,"longitud":-57.955434,"ranking":0},
{"id":4489,"nombre":"Adela Acceso","localidad":"Adela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.6849244921878,"longitud":-57.95592931328869,"ranking":0},
{"id":2551,"nombre":"Valentin Alsina","localidad":"Valentín Alsina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6770449,"longitud":-58.4013858,"ranking":0},
{"id":3523,"nombre":"America","localidad":"America ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10668,"nombre":"America Acceso","localidad":"America ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.503378,"longitud":-62.999442,"ranking":2},
{"id":802,"nombre":"Ascencion","localidad":"Ascencion","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.231221,"longitud":-61.103387,"ranking":5},
{"id":2463,"nombre":"Ascencion Acceso","localidad":"Ascencion","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.24329239896657,"longitud":-61.09022961377874,"ranking":0},
{"id":2465,"nombre":"Azopardo","localidad":"Azopardo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.70176520640059,"longitud":-62.90169564204438,"ranking":0},
{"id":136,"nombre":"Balcarce","localidad":"Balcarce ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.832633,"longitud":-58.237108,"ranking":704},
{"id":3411,"nombre":"Balcarce Acceso","localidad":"Balcarce ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.8177332531843,"longitud":-58.220681379344086,"ranking":0},
{"id":715,"nombre":"Banderalo","localidad":"Banderalo ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2391,"nombre":"Banderalo Acceso","localidad":"Banderalo ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.03759,"longitud":-62.98973,"ranking":0},
{"id":139,"nombre":"Bernal","localidad":"Bernal ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.715528,"longitud":-58.3057082,"ranking":21},
{"id":7751,"nombre":"Berutti","localidad":"Berutti","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":141,"nombre":"Bolivar","localidad":"Bolivar ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.2365252,"longitud":-61.1220873,"ranking":536},
{"id":1485,"nombre":"Vallimanca Acceso","localidad":"Bolivar ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.32540118992903,"longitud":-61.01725973183212,"ranking":0},
{"id":1486,"nombre":"Vallimanca","localidad":"Bolivar ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.34027144067286,"longitud":-61.03520186583247,"ranking":0},
{"id":4218,"nombre":"Bolivar Acceso","localidad":"Bolivar ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.2398431,"longitud":-61.123609,"ranking":0},
{"id":435,"nombre":"Bonifacio","localidad":"Bonifacio ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.7959787,"longitud":-62.2143977,"ranking":6},
{"id":9158,"nombre":"Bonifacio Acceso","localidad":"Bonifacio ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.79612105597024,"longitud":-62.21458921988673,"ranking":0},
{"id":627,"nombre":"Cabildo","localidad":"Cabildo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6331175,"longitud":-58.4805390,"ranking":0},
{"id":4185,"nombre":"Cabildo Acceso","localidad":"Cabildo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":490,"nombre":"Capitan Sarmiento","localidad":"Capitan Sarmiento","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.16899,"longitud":-59.789303,"ranking":83},
{"id":1490,"nombre":"La Luisa Acceso","localidad":"Capitan Sarmiento","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.122069407387826,"longitud":-59.91791680229174,"ranking":0},
{"id":150,"nombre":"Carhue","localidad":"Carhue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.179888,"longitud":-62.749801,"ranking":38},
{"id":1430,"nombre":"Carlos Casares Acceso","localidad":"Carlos Casares","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.628512,"longitud":-61.359558,"ranking":86},
{"id":1493,"nombre":"Carlos Casares","localidad":"Carlos Casares","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.628663,"longitud":-61.359502,"ranking":45},
{"id":1494,"nombre":"Santo Tomas","localidad":"Carlos Casares","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.694434721353275,"longitud":-61.503330390903855,"ranking":0},
{"id":1496,"nombre":"Carlos Tejedor","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.394919,"longitud":-62.424738,"ranking":52},
{"id":1499,"nombre":"Curaru","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.63854026671168,"longitud":-62.192704051444174,"ranking":0},
{"id":1500,"nombre":"Curaru Acceso","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.629504457025206,"longitud":-62.18030359499277,"ranking":0},
{"id":1501,"nombre":"Drysdale","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.30380504473251,"longitud":-62.646547320513804,"ranking":0},
{"id":1502,"nombre":"Husares","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.52173939020937,"longitud":-62.348159784161915,"ranking":0},
{"id":3661,"nombre":"Carlos Tejedor Acceso","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.3928454,"longitud":-62.429652,"ranking":0},
{"id":7954,"nombre":"Husares Acceso","localidad":"Carlos Tejedor","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.523676934808364,"longitud":-62.345486449724355,"ranking":0},
{"id":783,"nombre":"Carmen de Areco","localidad":"Carmen de Areco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4029470,"longitud":-59.8282110,"ranking":32},
{"id":790,"nombre":"Carmen de Areco Acceso","localidad":"Carmen de Areco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.403159296823205,"longitud":-59.82811580425602,"ranking":0},
{"id":10040,"nombre":"Gouin Acceso","localidad":"Carmen de Areco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.49364238155125,"longitud":-59.80545177774192,"ranking":0},
{"id":4716,"nombre":"Casbas","localidad":"Casbas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.758658,"longitud":-62.500571,"ranking":10},
{"id":9178,"nombre":"Casbas Acceso","localidad":"Casbas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.767576,"longitud":-62.51578,"ranking":2},
{"id":600,"nombre":"Chacabuco","localidad":"Chacabuco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6414435,"longitud":-60.4603175,"ranking":531},
{"id":709,"nombre":"Chacabuco Acceso","localidad":"Chacabuco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.641331,"longitud":-60.460724,"ranking":92},
{"id":7512,"nombre":"O'Higgins","localidad":"Chacabuco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.58163162243451,"longitud":-60.700295757802095,"ranking":0},
{"id":724,"nombre":"Chapadmalal","localidad":"Chapadmalal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2094556,"longitud":-57.7016813,"ranking":46},
{"id":3408,"nombre":"RCT Residencias Cooperativas de Turismo","localidad":"Chapadmalal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1427,"nombre":"Chivilcoy Acceso","localidad":"Chivilcoy","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9206779,"longitud":-59.9860489,"ranking":35},
{"id":1509,"nombre":"Chivilcoy","localidad":"Chivilcoy","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.888467,"longitud":-60.013004,"ranking":0},
{"id":5478,"nombre":"Parador La Posta","localidad":"Chivilcoy","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9189507,"longitud":-59.9846418,"ranking":0},
{"id":889,"nombre":"City Bell","localidad":"City Bell ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.879695,"longitud":-58.060077,"ranking":4},
{"id":163,"nombre":"Ciudadela - (Límite con Liniers)","localidad":"Ciudadela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6281824,"longitud":-58.5309661,"ranking":1},
{"id":1326,"nombre":"Bingo Ciudadela","localidad":"Ciudadela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.639418900868804,"longitud":-58.53002474002146,"ranking":0},
{"id":3257,"nombre":"Claypole","localidad":"Claypole","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.79142,"longitud":-58.311909,"ranking":8},
{"id":785,"nombre":"Coronel Granada","localidad":"Coronel Granada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.804675,"longitud":-62.194974,"ranking":4},
{"id":2397,"nombre":"Coronel Granada Acesso","localidad":"Coronel Granada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-32.9881112,"longitud":-60.6429424,"ranking":0},
{"id":628,"nombre":"Coronel Vidal","localidad":"Coronel Vidal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.448371,"longitud":-57.728149,"ranking":153},
{"id":745,"nombre":"Ruta 2 Km 355","localidad":"Coronel Vidal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7955,"nombre":"Coronel Vidal Acceso","localidad":"Coronel Vidal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.4479815,"longitud":-57.7215155,"ranking":0},
{"id":732,"nombre":"Cobo","localidad":"Cobo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.7999355,"longitud":-57.63162389999999,"ranking":0},
{"id":769,"nombre":"General Conesa","localidad":"Gral. Conesa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.521297,"longitud":-57.325645,"ranking":0},
{"id":2407,"nombre":"General Conesa Acceso","localidad":"Gral. Conesa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.501716,"longitud":-57.3058224,"ranking":0},
{"id":167,"nombre":"Daireaux","localidad":"Daireaux","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.597739,"longitud":-61.751756,"ranking":39},
{"id":1522,"nombre":"Salazar","localidad":"Daireaux","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.30200739248296,"longitud":-62.201399104423864,"ranking":0},
{"id":3678,"nombre":"Daireaux Acceso","localidad":"Daireaux","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.5878674,"longitud":-61.7257411,"ranking":3},
{"id":2548,"nombre":"Darregueira","localidad":"Darregueira","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.690762,"longitud":-63.160969,"ranking":0},
{"id":3294,"nombre":"Dennehy","localidad":"Dennehy","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.351030586953044,"longitud":-60.74495503759301,"ranking":0},
{"id":2929,"nombre":"Dugan Acceso","localidad":"Duggan ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.18881223235547,"longitud":-59.617580316383346,"ranking":0},
{"id":4204,"nombre":"Espartillar","localidad":"Espartillar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.358620554692415,"longitud":-62.43697549639943,"ranking":0},
{"id":8033,"nombre":"Espartillar Acceso","localidad":"Espartillar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.34860457611885,"longitud":-62.46291207604469,"ranking":0},
{"id":459,"nombre":"Etcheverry","localidad":"Etcheverry","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0234978,"longitud":-58.0791932,"ranking":0},
{"id":4898,"nombre":"Etcheverry Acceso","localidad":"Etcheverry","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0061766,"longitud":-58.0602752,"ranking":0},
{"id":4744,"nombre":"Fatima","localidad":"Fatima","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.434302960007614,"longitud":-58.98879815943766,"ranking":0},
{"id":7749,"nombre":"French","localidad":"French","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.51862389561435,"longitud":-60.999201476010825,"ranking":0},
{"id":4715,"nombre":"Garre","localidad":"Garre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.559681,"longitud":-62.602444,"ranking":0},
{"id":10663,"nombre":"Garre Acceso","localidad":"Garre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.568693938218665,"longitud":-62.61043283098453,"ranking":3},
{"id":729,"nombre":"General Guido","localidad":"General Guido","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.642016,"longitud":-57.788059,"ranking":23},
{"id":2398,"nombre":"General Guido Acceso","localidad":"General Guido","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6417905,"longitud":-57.7876045,"ranking":9},
{"id":179,"nombre":"General Madariaga","localidad":"General Madariaga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.994836,"longitud":-57.137947,"ranking":159},
{"id":10133,"nombre":"Paraje Macedo","localidad":"General Madariaga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14451,"nombre":"Parador General Madariaga","localidad":"General Madariaga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.01804229418751,"longitud":-57.11671829223633,"ranking":18},
{"id":774,"nombre":"General Pinto","localidad":"General Pinto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7607,"longitud":-61.890343,"ranking":32},
{"id":2408,"nombre":"General Pinto Acceso","localidad":"General Pinto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-32.9881112,"longitud":-60.6429424,"ranking":0},
{"id":730,"nombre":"General Piran","localidad":"General Piran","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.274062,"longitud":-57.766881,"ranking":54},
{"id":1657,"nombre":"General Piran Acceso","localidad":"General Piran","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.2740703,"longitud":-57.766694,"ranking":0},
{"id":1541,"nombre":"General Rodriguez","localidad":"General Rodriguez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5887482,"longitud":-58.9455349,"ranking":19},
{"id":7750,"nombre":"Gorostiaga","localidad":"Gorostiaga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.84409007684376,"longitud":-59.863227036087366,"ranking":0},
{"id":517,"nombre":"General Villegas","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0328993,"longitud":-63.0121624,"ranking":296},
{"id":1542,"nombre":"Piedritas","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.773807,"longitud":-62.984686,"ranking":1},
{"id":2401,"nombre":"Cruce Villegas","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4369,"nombre":"General Villegas Acceso","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.01739635394083,"longitud":-62.993339490053,"ranking":0},
{"id":7718,"nombre":"Cañada Seca Acceso","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.42822527847437,"longitud":-62.94191069748171,"ranking":34},
{"id":7975,"nombre":"Villa Sauce","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.282393552525434,"longitud":-63.37176993231802,"ranking":0},
{"id":9830,"nombre":"Cañada Seca","localidad":"General Villegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":180,"nombre":"Guamini","localidad":"Guamini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.009919,"longitud":-62.419799,"ranking":51},
{"id":4165,"nombre":"Guamini Acceso","localidad":"Guamini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.009819,"longitud":-62.419807,"ranking":46},
{"id":735,"nombre":"Guerrero","localidad":"Guerrero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.05678152528189,"longitud":-57.825398199458384,"ranking":0},
{"id":181,"nombre":"Haedo","localidad":"Haedo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6446047,"longitud":-58.5901947,"ranking":10},
{"id":1546,"nombre":"Henderson","localidad":"Henderson","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.305268,"longitud":-61.726581,"ranking":0},
{"id":184,"nombre":"Hurlingham","localidad":"Hurlingham ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5791459,"longitud":-58.6410435,"ranking":7},
{"id":12318,"nombre":"Hurlingham KM 18","localidad":"Hurlingham ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.579269,"longitud":-58.640445,"ranking":0},
{"id":483,"nombre":"Juan Bautista Alberdi","localidad":"Juan Bautista Alberdi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.45605829546959,"longitud":-61.824425954326614,"ranking":0},
{"id":7742,"nombre":"La Dorita ","localidad":"La Dorita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.590993615,"longitud":-61.241225715,"ranking":0},
{"id":835,"nombre":"Laprida","localidad":"Laprida","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.540968,"longitud":-60.792659,"ranking":104},
{"id":3874,"nombre":"Rotonda Laprida","localidad":"Laprida","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.535851645798125,"longitud":-60.789557110418734,"ranking":0},
{"id":733,"nombre":"Las Armas","localidad":"Las Armas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6047843,"longitud":-58.3766193,"ranking":23},
{"id":1480,"nombre":"Las Armas Acceso","localidad":"Las Armas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.08728084806518,"longitud":-57.82787749129504,"ranking":0},
{"id":199,"nombre":"Las Toninas","localidad":"Las Toninas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.488368,"longitud":-56.7040873,"ranking":176},
{"id":2421,"nombre":"Las Toninas Acceso","localidad":"Las Toninas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.4895256,"longitud":-56.7114017,"ranking":2},
{"id":728,"nombre":"Lezama","localidad":"Lezama","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.877474,"longitud":-57.894792,"ranking":45},
{"id":2422,"nombre":"Lezama Acceso","localidad":"Lezama","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.871301,"longitud":-57.897526,"ranking":197},
{"id":9900,"nombre":"Cruce Lezama","localidad":"Lezama","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":756,"nombre":"Lincoln","localidad":"Lincoln","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.86743,"longitud":-61.526935,"ranking":303},
{"id":2424,"nombre":"Lincoln Acceso","localidad":"Lincoln","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8405566062063,"longitud":-61.498882400800014,"ranking":0},
{"id":1644,"nombre":"Loberia Acceso","localidad":"Loberia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.17467761342236,"longitud":-58.76592147774974,"ranking":0},
{"id":1645,"nombre":"Loberia","localidad":"Loberia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.1586741,"longitud":-58.7773241,"ranking":223},
{"id":207,"nombre":"Maipu","localidad":"Maipu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.2972861,"longitud":-60.2508786,"ranking":172},
{"id":1656,"nombre":"Maipu Acceso","localidad":"Maipu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.2972861,"longitud":-60.2508786,"ranking":0},
{"id":1530,"nombre":"Mar del Sur","localidad":"Mar del Sur","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.340996,"longitud":-57.995652,"ranking":0},
{"id":461,"nombre":"Mar del Tuyu","localidad":"Mar del Tuyu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.582993,"longitud":-56.699233,"ranking":364},
{"id":1626,"nombre":"Mar del Tuyu Sur","localidad":"Mar del Tuyu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.5826401199878,"longitud":-56.69895237460251,"ranking":0},
{"id":2427,"nombre":"Mar del Tuyu Acceso","localidad":"Mar del Tuyu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.5824807,"longitud":-56.7145248,"ranking":7},
{"id":727,"nombre":"Monasterio","localidad":"Monasterio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.763642695593084,"longitud":-57.93245911437613,"ranking":0},
{"id":1680,"nombre":"Mones Cazon","localidad":"Mones Cazon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.8068436,"longitud":-61.8855782,"ranking":0},
{"id":755,"nombre":"Monte Hermoso","localidad":"Monte Hermoso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.974657,"longitud":-61.2890846,"ranking":185},
{"id":1429,"nombre":"9 de Julio Acceso","localidad":"9 de Julio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.475769200,"longitud":-60.864708400,"ranking":24},
{"id":1475,"nombre":"Santo Unzue","localidad":"9 de Julio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.75166007779282,"longitud":-60.850551541052916,"ranking":0},
{"id":2191,"nombre":"9 de Julio","localidad":"9 de Julio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4519656,"longitud":-60.8812737,"ranking":154},
{"id":226,"nombre":"Olavarria","localidad":"Olavarria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.8954055,"longitud":-60.3141207,"ranking":2100},
{"id":1666,"nombre":"Olavarria Acceso","localidad":"Olavarria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.93599900656689,"longitud":-60.22994709822122,"ranking":0},
{"id":1668,"nombre":"Sierras Bayas Acceso","localidad":"Olavarria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.91598086988052,"longitud":-60.20333598482731,"ranking":1},
{"id":4793,"nombre":"Ostende","localidad":"Ostende ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.12864270887634,"longitud":-56.90497208723877,"ranking":0},
{"id":805,"nombre":"Paso del Rey","localidad":"Paso del Rey","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6466623,"longitud":-58.7984202,"ranking":0},
{"id":8505,"nombre":"Pavon Acceso","localidad":"Pavon ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.38959849864069,"longitud":-59.03548804408217,"ranking":0},
{"id":1431,"nombre":"Pehuajo Acceso","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.838584,"longitud":-61.87473,"ranking":26},
{"id":1669,"nombre":"Chiclana","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.73012886462977,"longitud":-61.73881166980819,"ranking":0},
{"id":1671,"nombre":"Francisco Madero","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.85969999638054,"longitud":-62.06562308877669,"ranking":0},
{"id":1675,"nombre":"Pehuajo","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.8109589,"longitud":-61.9052186,"ranking":275},
{"id":9102,"nombre":"Inocencio Sosa","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.71915346450614,"longitud":-62.10917801733126,"ranking":0},
{"id":10871,"nombre":"Francisco Madero Acceso","localidad":"Pehuajo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":933,"nombre":"Pigue","localidad":"Pigue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.59667,"longitud":-62.408736,"ranking":299},
{"id":9105,"nombre":"Pigue Acceso","localidad":"Pigue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.591628,"longitud":-62.418789,"ranking":0},
{"id":232,"nombre":"Pinamar","localidad":"Pinamar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.107098,"longitud":-56.879406,"ranking":2010},
{"id":4378,"nombre":"Primera Junta","localidad":"Primera Junta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":934,"nombre":"Puan","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.541286,"longitud":-62.759786,"ranking":26},
{"id":2035,"nombre":"Bordenave","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.80238,"longitud":-63.039526,"ranking":0},
{"id":9169,"nombre":"Bordenave Acesso","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.80794135115503,"longitud":-63.03271028168154,"ranking":0},
{"id":7647,"nombre":"Darragueira","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.68934076108556,"longitud":-63.16119034493571,"ranking":0},
{"id":7926,"nombre":"Rondeau","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.23803432300433,"longitud":-63.12512161471513,"ranking":0},
{"id":8274,"nombre":"17 de Agosto","localidad":"Puan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.911953,"longitud":-62.937439,"ranking":0},
{"id":380,"nombre":"Ramallo","localidad":"Ramallo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.4856553,"longitud":-60.0088367,"ranking":0},
{"id":2038,"nombre":"El Paraiso Acceso","localidad":"Ramallo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":33.606095,"longitud":-60.022908,"ranking":0},
{"id":2040,"nombre":"Ramallo Acceso","localidad":"Ramallo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.54882893398648,"longitud":-60.0916193787569,"ranking":0},
{"id":8095,"nombre":"Villa Ramallo","localidad":"Ramallo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.4979571,"longitud":-60.0629809,"ranking":0},
{"id":235,"nombre":"Ramos Mejia","localidad":"Ramos Mejia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6401379,"longitud":-58.5613844,"ranking":58},
{"id":764,"nombre":"Rauch","localidad":"Rauch","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.77414,"longitud":-59.089425,"ranking":229},
{"id":2435,"nombre":"Rauch Acceso","localidad":"Rauch","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.76462035055464,"longitud":-59.09025355767746,"ranking":0},
{"id":2043,"nombre":"Gonzalez Moreno Acceso","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.55741316652284,"longitud":-63.37436832194499,"ranking":0},
{"id":2044,"nombre":"Rivadavia","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4849992,"longitud":-62.97043679999999,"ranking":6},
{"id":2046,"nombre":"Fortin Olavarria","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.699626,"longitud":-63.020304,"ranking":0},
{"id":7973,"nombre":"Condarco","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.2731515609411,"longitud":-63.06589441464278,"ranking":0},
{"id":7974,"nombre":"Sansinena","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9762,"nombre":"America","localidad":"Rivadavia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4940186,"longitud":-62.9867514,"ranking":14},
{"id":244,"nombre":"Salto","localidad":"Salto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.294012,"longitud":-60.2529329,"ranking":81},
{"id":1376,"nombre":"Salto Acceso","localidad":"Salto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7601,"nombre":"Gahan","localidad":"Salto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3369324416486,"longitud":-60.101167015700156,"ranking":0},
{"id":7878,"nombre":"Indart","localidad":"Salto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.39773402125621,"longitud":-60.53748263156381,"ranking":0},
{"id":245,"nombre":"San Andres de Giles","localidad":"San Andres de Giles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4503152,"longitud":-59.4497264,"ranking":3},
{"id":791,"nombre":"San Andres de Giles Acceso","localidad":"San Andres de Giles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4503600,"longitud":-59.4357051,"ranking":0},
{"id":14631,"nombre":"Parador Bianchi","localidad":"San Andres de Giles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.452232627979015,"longitud":-59.45106840130394,"ranking":0},
{"id":566,"nombre":"San Antonio de Areco","localidad":"San Antonio de Areco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.243451,"longitud":-59.465722,"ranking":233},
{"id":3155,"nombre":"San Antonio de Areco Acceso","localidad":"San Antonio de Areco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.228494,"longitud":-59.4866856,"ranking":0},
{"id":806,"nombre":"San Antonio de Padua","localidad":"San Antonio De Padua","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.665950800,"longitud":-58.699885500,"ranking":45},
{"id":249,"nombre":"San Clemente del Tuyú","localidad":"San Clemente del Tuyú","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.384565,"longitud":-56.727522,"ranking":557},
{"id":7963,"nombre":"Termas Marinas ","localidad":"San Clemente del Tuyú","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.308437,"longitud":-56.770971,"ranking":0},
{"id":7964,"nombre":"Mundo Marino","localidad":"San Clemente del Tuyú","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.340178,"longitud":-56.746977,"ranking":0},
{"id":8292,"nombre":"San Clemente del Tuyu Acceso","localidad":"San Clemente del Tuyú","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.387795448229014,"longitud":-56.7351953915782,"ranking":0},
{"id":712,"nombre":"Santa Clara del Mar","localidad":"Santa Clara del Mar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.845825,"longitud":-57.506716,"ranking":179},
{"id":255,"nombre":"Santa Teresita","localidad":"Santa Teresita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.535722,"longitud":-56.713717,"ranking":644},
{"id":697,"nombre":"Sierra de la Ventana","localidad":"Sierra de la Ventana ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.132505,"longitud":-61.799479,"ranking":291},
{"id":7486,"nombre":"Sierra de la Ventana Acceso","localidad":"Sierra de la Ventana ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.14382159931749,"longitud":-61.79874560254431,"ranking":0},
{"id":2077,"nombre":"Suipacha Acceso","localidad":"Suipacha ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.782714170829045,"longitud":-59.68047416410419,"ranking":0},
{"id":2078,"nombre":"Suipacha","localidad":"Suipacha ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.781346400,"longitud":-59.676961900,"ranking":0},
{"id":840,"nombre":"Tornquist","localidad":"Tornquist","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.099747,"longitud":-62.217816,"ranking":375},
{"id":2762,"nombre":"Tornquist Acceso","localidad":"Tornquist","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.070262,"longitud":-62.221125,"ranking":17},
{"id":617,"nombre":"Tres Arroyos","localidad":"Tres Arroyos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.3689195,"longitud":-60.2860218,"ranking":1192},
{"id":2088,"nombre":"Claromeco Combinacion","localidad":"Tres Arroyos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.8522715,"longitud":-60.0716790,"ranking":18},
{"id":2438,"nombre":"Tres Arroyos Acceso","localidad":"Tres Arroyos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.315480493000656,"longitud":-60.2568529826187,"ranking":0},
{"id":7527,"nombre":"Barrow","localidad":"Tres Arroyos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.31246589498247,"longitud":-60.23157705966831,"ranking":0},
{"id":492,"nombre":"Tres Sargentos","localidad":"Tres Sargentos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.46921154611029,"longitud":-59.99921267329493,"ranking":0},
{"id":7889,"nombre":"Tres Sargentos Acceso","localidad":"Tres Sargentos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.43587628536435,"longitud":-59.91409812179816,"ranking":0},
{"id":338,"nombre":"Vedia","localidad":"Vedia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.49234,"longitud":-61.543367,"ranking":138},
{"id":3651,"nombre":"Vedia Acceso","localidad":"Vedia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.50959816073942,"longitud":-61.523633979767396,"ranking":0},
{"id":2983,"nombre":"Chumbicha","localidad":"Chumbicha","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.8565617,"longitud":-66.2347545,"ranking":7},
{"id":2193,"nombre":"La Merced","localidad":"La Merced","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.153807300,"longitud":-65.659017600,"ranking":20},
{"id":4200,"nombre":"Poman","localidad":"Poman","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3946223986122,"longitud":-66.22514866558278,"ranking":98},
{"id":4419,"nombre":"Poman Acceso","localidad":"Poman","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.388626764227215,"longitud":-66.23114188751958,"ranking":1},
{"id":3660,"nombre":"Saujil","localidad":"Saujil","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.174779525010287,"longitud":-66.2137527448948,"ranking":51},
{"id":10442,"nombre":"Reserva Natural Los Chaguares","localidad":"Colonia Benitez","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1750,"nombre":"Alicia","localidad":"Alicia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9387485,"longitud":-62.4648818,"ranking":0},
{"id":2737,"nombre":"Arroyo Cabral","localidad":"Arroyo Cabral","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.4895282,"longitud":-63.4034758,"ranking":43},
{"id":1753,"nombre":"Ascochinga","localidad":"Ascochinga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9418227,"longitud":-64.276105,"ranking":2},
{"id":3048,"nombre":"Ausonia","localidad":"Ausonia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6618153,"longitud":-63.2448448,"ranking":0},
{"id":534,"nombre":"Ballesteros","localidad":"Ballesteros","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.542483060757164,"longitud":-62.98235815293325,"ranking":0},
{"id":8263,"nombre":"Ballesteros Norte","localidad":"Ballesteros","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.54248749127372,"longitud":-62.98235213740261,"ranking":0},
{"id":8264,"nombre":"Ballesteros Sur","localidad":"Ballesteros","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.588764742672005,"longitud":-63.0257253832576,"ranking":0},
{"id":2158,"nombre":"Calchin","localidad":"Calchin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.66579807882668,"longitud":-63.19987846797119,"ranking":0},
{"id":2745,"nombre":"Chazon","localidad":"Chazon","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0797687,"longitud":-63.2725840,"ranking":13},
{"id":1549,"nombre":"El Arañado","localidad":"El Arañado","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.742361721186356,"longitud":-62.89405381345371,"ranking":0},
{"id":1550,"nombre":"El Fortin","localidad":"El Fortin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.965010879581097,"longitud":-62.299439059156164,"ranking":0},
{"id":2103,"nombre":"Etruria","localidad":"Etruria ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.9395398,"longitud":-63.2475329,"ranking":3},
{"id":2731,"nombre":"General Cabrera","localidad":"General Cabrera","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.813525,"longitud":-63.878986,"ranking":261},
{"id":1196,"nombre":"Gral. Roca","localidad":"General Roca","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.72011353075147,"longitud":-61.9130370003125,"ranking":0},
{"id":2101,"nombre":"La Laguna Acceso","localidad":"La Laguna","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.79757071298612,"longitud":-63.243257607081716,"ranking":0},
{"id":2102,"nombre":"Va. De Maria","localidad":"La Laguna","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3047,"nombre":"La Laguna","localidad":"La Laguna","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.80299974680369,"longitud":-63.24385388015989,"ranking":0},
{"id":2990,"nombre":"Las Higueras","localidad":"Las Higueras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0920323,"longitud":-64.2902142,"ranking":13},
{"id":8353,"nombre":"Las Higueras Acceso","localidad":"Las Higueras","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":304,"nombre":"Leones","localidad":"Leones","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6535621,"longitud":-62.2931785,"ranking":0},
{"id":1156,"nombre":"Los Cisnes","localidad":"Los Cisnes","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2757,"nombre":"Luca","localidad":"Luca","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.540469,"longitud":-63.47722599999999,"ranking":17},
{"id":2155,"nombre":"Luque","localidad":"Luque ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6435117,"longitud":-63.34368569999999,"ranking":0},
{"id":3046,"nombre":"Manfredi","localidad":"Manfredi","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.842754412260497,"longitud":-63.74422487499493,"ranking":5},
{"id":301,"nombre":"Oncativo","localidad":"Oncativo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.912197,"longitud":-63.683933,"ranking":168},
{"id":3282,"nombre":"Oncativo (YPF sobre Ruta 9)","localidad":"Oncativo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1762,"nombre":"Rio Ceballos","localidad":"Rio Ceballos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.166251,"longitud":-64.320225,"ranking":115},
{"id":9852,"nombre":"La Quebrada","localidad":"Rio Ceballos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.152166564229294,"longitud":-64.32045578956604,"ranking":0},
{"id":297,"nombre":"Rio Segundo","localidad":"Rio Segundo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.650312787863346,"longitud":-63.90276194455072,"ranking":0},
{"id":2157,"nombre":"Villa del Rosario","localidad":"Rio Segundo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.551126642916657,"longitud":-63.535086788843174,"ranking":0},
{"id":4421,"nombre":"Puente Rio Segundo","localidad":"Rio Segundo","provincia":"Córdoba","pais":"ARGENTINA","latitud":10.0028194,"longitud":-84.17758529999999,"ranking":0},
{"id":1763,"nombre":"Sacanta","localidad":"Sacanta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.665052143838032,"longitud":-63.04714955329075,"ranking":0},
{"id":470,"nombre":"Sampacho","localidad":"Sampacho","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.382933,"longitud":-64.721706,"ranking":47},
{"id":1745,"nombre":"Sampacho Acceso","localidad":"Sampacho","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.382966,"longitud":-64.721715,"ranking":161},
{"id":1768,"nombre":"San Marcos Sud","localidad":"San Marcos Sud","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.621569,"longitud":-62.47745259999999,"ranking":0},
{"id":2988,"nombre":"Empalme San Marcos Sierras","localidad":"San Marcos Sud","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6213502,"longitud":-62.4769048,"ranking":12},
{"id":1556,"nombre":"San Severo Acceso","localidad":"San Severo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.50400455812018,"longitud":-63.025882253900065,"ranking":0},
{"id":8254,"nombre":"San Severo","localidad":"San Severo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.538926874509364,"longitud":-63.04455053415237,"ranking":0},
{"id":287,"nombre":"Serrezuela","localidad":"Serrezuela","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.637014,"longitud":-65.3892802,"ranking":8},
{"id":296,"nombre":"Toledo","localidad":"Toledo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.555981557122116,"longitud":-64.00834143447624,"ranking":0},
{"id":8953,"nombre":"Villa Allende","localidad":"Villa Allende","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.2953391,"longitud":-64.2936708,"ranking":48},
{"id":924,"nombre":"Villa General Belgrano","localidad":"Villa General Belgrano","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.973822,"longitud":-64.561969,"ranking":1870},
{"id":8664,"nombre":"Traslado a Villa General Belgrano","localidad":"Villa General Belgrano","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1586,"nombre":"Lavalle","localidad":"Lavalle ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.036649,"longitud":-59.181874,"ranking":4},
{"id":1587,"nombre":"Lavalle Acceso","localidad":"Lavalle ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.036482,"longitud":-59.181833,"ranking":1},
{"id":4615,"nombre":"Cruz de los Milagros","localidad":"Lavalle ","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8291,"nombre":"Cruz de los Milagros Acceso","localidad":"Lavalle ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.832515039538468,"longitud":-59.05020549133456,"ranking":0},
{"id":1804,"nombre":"Bernardi Acceso","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.078551477128272,"longitud":-59.04899625195804,"ranking":0},
{"id":1806,"nombre":"El Pingo","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.583293625954777,"longitud":-59.893438873645614,"ranking":0},
{"id":1807,"nombre":"Federal","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.954829,"longitud":-58.786138,"ranking":69},
{"id":1808,"nombre":"La Calandria","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.79617959892,"longitud":-58.64557951328384,"ranking":0},
{"id":3998,"nombre":"Federal Acceso","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.9612034,"longitud":-58.8021042,"ranking":0},
{"id":8529,"nombre":"Cruce El Pingo","localidad":"Federal","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3575,"nombre":"Las Lomitas","localidad":"Las Lomitas","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.71076,"longitud":-60.590334,"ranking":42},
{"id":10859,"nombre":"Aeropuerto de Las Lomitas","localidad":"Las Lomitas","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.72543,"longitud":-60.5463952,"ranking":0},
{"id":1905,"nombre":"Puerto Pilcomayo","localidad":"Puerto Pilcomayo","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.372883016557587,"longitud":-57.651059492645906,"ranking":0},
{"id":1922,"nombre":"Alpachiri","localidad":"Alpachiri","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.3748680,"longitud":-63.7729318,"ranking":0},
{"id":7966,"nombre":"Alpachiri Acceso","localidad":"Alpachiri","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.36769708560249,"longitud":-63.667912153081595,"ranking":0},
{"id":9827,"nombre":"Anguil","localidad":"Anguil","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2504,"nombre":"Ataliva Roca","localidad":"Ataliva Roca","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.032131454008336,"longitud":-64.28776157842812,"ranking":0},
{"id":7968,"nombre":"Ataliva Roca Acceso","localidad":"Ataliva Roca","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.03226552770144,"longitud":-64.28859240986844,"ranking":0},
{"id":2744,"nombre":"Casa de Piedra","localidad":"Casa de Piedra","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3891,"nombre":"Casa de Piedra Acceso","localidad":"Casa de Piedra","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.155743600,"longitud":-67.143974300,"ranking":0},
{"id":1434,"nombre":"Catrilo Acceso","localidad":"Catrilo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.416788,"longitud":-63.423744,"ranking":0},
{"id":1916,"nombre":"Catrilo","localidad":"Catrilo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.406554,"longitud":-63.425684,"ranking":25},
{"id":1437,"nombre":"Eduardo Castex","localidad":"Eduardo Castex","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.916577,"longitud":-64.294275,"ranking":97},
{"id":3417,"nombre":"Eduardo Castex Acceso","localidad":"Eduardo Castex","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.91658,"longitud":-64.294283,"ranking":0},
{"id":2498,"nombre":"Embajador Martini","localidad":"Embajador Martini","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":27},
{"id":3418,"nombre":"Embajador Martini Acceso","localidad":"Embajador Martini","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.387937,"longitud":-64.286668,"ranking":0},
{"id":546,"nombre":" General Acha","localidad":"General Acha","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.366701,"longitud":-64.60332,"ranking":125},
{"id":3224,"nombre":"General Acha Acceso","localidad":"General Acha","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.361938,"longitud":-64.561866,"ranking":0},
{"id":787,"nombre":"General Pico","localidad":"General Pico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.670555,"longitud":-63.741534,"ranking":778},
{"id":1920,"nombre":"General Campos","localidad":"Guatrache","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.4601599271856,"longitud":-63.582568932495924,"ranking":0},
{"id":1921,"nombre":"Guatrache","localidad":"Guatrache","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.6702332,"longitud":-63.5391205,"ranking":0},
{"id":4203,"nombre":"Cotita","localidad":"Guatrache","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.726396938217746,"longitud":-64.06282398624658,"ranking":0},
{"id":7915,"nombre":"Cotita Acceso","localidad":"Guatrache","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.66647856166279,"longitud":-63.612638807558966,"ranking":0},
{"id":1929,"nombre":"Lihuel Calel","localidad":"Lihuel Calel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.014141382223286,"longitud":-65.58326508516771,"ranking":0},
{"id":4195,"nombre":"Lihuel Calel Acceso","localidad":"Lihuel Calel","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1435,"nombre":"Lonquimay Acceso","localidad":"Lonquimay","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.479013009352144,"longitud":-63.6117351725151,"ranking":0},
{"id":3238,"nombre":"Lonquimay","localidad":"Lonquimay","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1930,"nombre":"Luan Toro","localidad":"Loventue","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.204392584764825,"longitud":-65.10025552673953,"ranking":0},
{"id":1931,"nombre":"Telen","localidad":"Loventue","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.264409,"longitud":-65.502518,"ranking":0},
{"id":1919,"nombre":"Puelches","localidad":"Puelches","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.144392,"longitud":-65.912877,"ranking":5},
{"id":4196,"nombre":"Puelches Acceso","localidad":"Puelches","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1439,"nombre":"Quemu Quemu Acceso","localidad":"Quemu Quemu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.051714214550486,"longitud":-63.59231997186785,"ranking":0},
{"id":1934,"nombre":"Colonia Baron","localidad":"Quemu Quemu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.176880262876146,"longitud":-63.85348625836947,"ranking":0},
{"id":1935,"nombre":"Miguel Cane Acceso","localidad":"Quemu Quemu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.159916884541936,"longitud":-63.52032485503629,"ranking":0},
{"id":1936,"nombre":"Quemu Quemu","localidad":"Quemu Quemu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.064465,"longitud":-63.58434,"ranking":0},
{"id":1915,"nombre":"Miguel Riglos","localidad":"Riglos Miguel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.851857,"longitud":-63.687402,"ranking":0},
{"id":4197,"nombre":"Miguel Riglos Acceso","localidad":"Riglos Miguel","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2474,"nombre":"Santa Isabel","localidad":"Santa Isabel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.233396,"longitud":-66.935975,"ranking":50},
{"id":3990,"nombre":"Santa Isabel Acceso","localidad":"Santa Isabel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.239047492756,"longitud":-66.92910956796153,"ranking":0},
{"id":7942,"nombre":"Trili Acceso","localidad":"Trili","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.90546504658308,"longitud":-63.64628184539263,"ranking":0},
{"id":2494,"nombre":"Trili","localidad":"Trili","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2483,"nombre":"Uriburu","localidad":"Uriburu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.53766075296712,"longitud":-63.85973920731742,"ranking":0},
{"id":3653,"nombre":"Uriburu Acceso","localidad":"Uriburu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.50836641180755,"longitud":-63.85838736311228,"ranking":0},
{"id":1438,"nombre":"Winifreda Acceso","localidad":"Winifreda ","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.228243,"longitud":-64.234431,"ranking":29},
{"id":1918,"nombre":"Winifreda","localidad":"Winifreda ","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.22250803065679,"longitud":-64.27813771922442,"ranking":0},
{"id":1938,"nombre":"Castro Barros","localidad":"Castro Barros","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.5761284779059,"longitud":-65.7261637271564,"ranking":10},
{"id":158,"nombre":"Chamical","localidad":"Chamical","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.355418858191495,"longitud":-66.30966495339413,"ranking":18},
{"id":1940,"nombre":"Chañar","localidad":"Chañar","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.543163564294723,"longitud":-65.95680726316913,"ranking":2},
{"id":1955,"nombre":"Ambil","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.128252735951023,"longitud":-66.34132654968889,"ranking":0},
{"id":1956,"nombre":"Catuna","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.954101094210106,"longitud":-66.22474127773438,"ranking":3},
{"id":1957,"nombre":"Chepes","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.343001,"longitud":-66.589606,"ranking":97},
{"id":1962,"nombre":"Olpas","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.806917715988185,"longitud":-66.24146651995746,"ranking":0},
{"id":1963,"nombre":"Olta","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":1964,"nombre":"Talva","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.342997645627133,"longitud":-66.58961542930058,"ranking":0},
{"id":2562,"nombre":"Chepes Acceso","localidad":"Chepes","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.34128786166838,"longitud":-66.58863480739163,"ranking":0},
{"id":3720,"nombre":"Cuatro Esquinas Acceso","localidad":"Cuatro Esquinas","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.806927973785967,"longitud":-65.87277192352984,"ranking":0},
{"id":8502,"nombre":"Cuatro Esquinas","localidad":"Cuatro Esquinas","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.807458226924275,"longitud":-65.86793284192636,"ranking":1},
{"id":1939,"nombre":"Famatina","localidad":"Famatina","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.924159858106925,"longitud":-67.51924821604096,"ranking":0},
{"id":7665,"nombre":"Pitiul","localidad":"Famatina","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2555,"nombre":"Milagro","localidad":"Milagro","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.017422,"longitud":-65.996743,"ranking":12},
{"id":540,"nombre":"La Dormida","localidad":"La Dormida","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.3455519,"longitud":-67.9236596,"ranking":0},
{"id":4192,"nombre":"La Dormida Acceso","localidad":"La Dormida","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.34003796061403,"longitud":-67.90891092616154,"ranking":0},
{"id":332,"nombre":"La Paz","localidad":"La Paz","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.458997,"longitud":-67.553409,"ranking":149},
{"id":1965,"nombre":"La Paz Acceso","localidad":"La Paz","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.457524716378806,"longitud":-67.5592252948683,"ranking":0},
{"id":3127,"nombre":"Camino del Vino El Sol - Full Day","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0445174,"longitud":-68.7241666,"ranking":0},
{"id":3135,"nombre":"Camino del Vino Lujan Sur - Full Day","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.1153215,"longitud":-68.90269649999999,"ranking":0},
{"id":3356,"nombre":"Lujan de Cuyo","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.036455,"longitud":-68.87918,"ranking":209},
{"id":4215,"nombre":"Manantiales","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.95592019971608,"longitud":-69.29412745527331,"ranking":0},
{"id":5768,"nombre":"Camino del Vino El Sol - Half Day (Mañana)","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5769,"nombre":"Camino del Vino El Sol - Half Day (Tarde)","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5770,"nombre":"Camino del Vino Lujan Sur - Half Day (Mañana)","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5771,"nombre":"Camino del Vino Lujan Sur - Half Day (Tarde)","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9935,"nombre":"Quick Point Araoz ","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.00282994040288,"longitud":-68.8584578654607,"ranking":0},
{"id":14457,"nombre":"Estación YPF Chacras de Coria","localidad":"Lujan de Cuyo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.9871068,"longitud":-68.8844804,"ranking":0},
{"id":3511,"nombre":"Rivadavia","localidad":"Rivadavia","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.1944665,"longitud":-68.4675271,"ranking":0},
{"id":4456,"nombre":"Reduccion","localidad":"Rivadavia","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.22859207793061,"longitud":-68.54594353144654,"ranking":0},
{"id":1344,"nombre":"Santa Rosa","localidad":"Santa Rosa ","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.25453731860376,"longitud":-68.15064116097756,"ranking":0},
{"id":9137,"nombre":"Santa Rosa Acceso","localidad":"Santa Rosa ","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.237739299074676,"longitud":-68.14537290606125,"ranking":0},
{"id":721,"nombre":"Arroyito","localidad":"Arroyito","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.094231000,"longitud":-66.319999700,"ranking":0},
{"id":3004,"nombre":"Catan Lil","localidad":"Catan Lil","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.743466,"longitud":-70.602124,"ranking":0},
{"id":561,"nombre":"Confluencia","localidad":"Confluencia","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.724526800,"longitud":-71.091842700,"ranking":0},
{"id":3232,"nombre":"Cervantes","localidad":"Cervantes","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.058550100,"longitud":-67.400028700,"ranking":0},
{"id":3694,"nombre":"Chinchinales","localidad":"Chinchinales","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.11505631143224,"longitud":-66.92981269646005,"ranking":0},
{"id":402,"nombre":"Cinco Saltos","localidad":"Cinco Saltos","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.833441,"longitud":-68.073505,"ranking":687},
{"id":3414,"nombre":"Cinco Saltos Acceso","localidad":"Cinco Saltos","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.831819679038084,"longitud":-68.07339478349664,"ranking":0},
{"id":947,"nombre":"Aguaray Yacuy","localidad":"Aguaray Yacuy","provincia":"Salta","pais":"ARGENTINA","latitud":-22.239804,"longitud":-63.732372,"ranking":0},
{"id":8177,"nombre":"La Merced","localidad":"La Merced","provincia":"Salta","pais":"ARGENTINA","latitud":-24.97301717597711,"longitud":-65.48134603931865,"ranking":0},
{"id":9195,"nombre":"Coronel Sola","localidad":"Rivadavia","provincia":"Salta","pais":"ARGENTINA","latitud":-23.470324244440867,"longitud":-62.887899030982204,"ranking":0},
{"id":2031,"nombre":"Vallecito","localidad":"Vallecito","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.738255,"longitud":-67.988647,"ranking":0},
{"id":3125,"nombre":"Difunta Correa","localidad":"Vallecito","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.73807,"longitud":-67.985738,"ranking":69},
{"id":4345,"nombre":"San Expedito","localidad":"Vallecito","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2050,"nombre":"Chosme","localidad":"Chosme","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.40929221693303,"longitud":-66.8017066188994,"ranking":0},
{"id":3695,"nombre":"Chosme Acceso","localidad":"Chosme","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":601,"nombre":"Concaran","localidad":"Concaran","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.559166,"longitud":-65.244957,"ranking":64},
{"id":7770,"nombre":"Concaran Acceso","localidad":"Concaran","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.5662301727558,"longitud":-65.2068056591863,"ranking":0},
{"id":604,"nombre":" La Toma","localidad":"La Toma","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.055255,"longitud":-65.621098,"ranking":57},
{"id":7778,"nombre":"La Toma Acceso","localidad":"La Toma","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.0616229844666,"longitud":-65.63867746341515,"ranking":0},
{"id":603,"nombre":"Naschel","localidad":"Naschel","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.916414,"longitud":-65.372957,"ranking":21},
{"id":7772,"nombre":"Naschel Acceso","localidad":"Naschel","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.91926025178311,"longitud":-65.36532128696324,"ranking":0},
{"id":2831,"nombre":"San Geronimo","localidad":"San Geronimo","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.1363701,"longitud":-66.5073481,"ranking":0},
{"id":7719,"nombre":"Alcorta","localidad":"Alcorta","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.2624089,"longitud":-60.3803142,"ranking":0},
{"id":2059,"nombre":"Angelica","localidad":"Angelica","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.5504747,"longitud":-61.5455821,"ranking":0},
{"id":2733,"nombre":"Angelica Acceso","localidad":"Angelica","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.551426,"longitud":-61.531596,"ranking":31},
{"id":3672,"nombre":"Angelica - SFE","localidad":"Angelica","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7668,"nombre":"Arequito","localidad":"Arequito","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.1422698,"longitud":-61.4672808,"ranking":0},
{"id":1746,"nombre":"Arroyo Seco","localidad":"Arroyo Seco","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9537807,"longitud":-60.6515021,"ranking":0},
{"id":1747,"nombre":"Arroyo Seco Acceso","localidad":"Arroyo Seco","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.16740717110761,"longitud":-60.5288648910399,"ranking":0},
{"id":1764,"nombre":"San Jeronimo del Sauce","localidad":"Capitan Bermudez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.611062729508795,"longitud":-61.142384842457965,"ranking":0},
{"id":2091,"nombre":"Capitan Bermudez","localidad":"Capitan Bermudez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1198,"nombre":"Carcaraña","localidad":"Carcaraña","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7129946,"longitud":-60.7290231,"ranking":0},
{"id":7721,"nombre":"Carreras","localidad":"Carreras","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.6009273,"longitud":-61.3007979,"ranking":0},
{"id":2480,"nombre":"Casilda","localidad":"Casilda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.043024,"longitud":-61.165751,"ranking":23},
{"id":3663,"nombre":"Casilda Acceso","localidad":"Casilda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.053088,"longitud":-61.157396,"ranking":2},
{"id":4784,"nombre":"Casilda 4 plazas","localidad":"Casilda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.04583769014751,"longitud":-61.16694270534163,"ranking":0},
{"id":14541,"nombre":"Elortondo","localidad":"Elortondo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7080632,"longitud":-61.6097897,"ranking":0},
{"id":7701,"nombre":"Elortondo Acceso","localidad":"Elortondo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7081073,"longitud":-61.6094137,"ranking":0},
{"id":1208,"nombre":"Los Cardos","localidad":"Los Cardos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.32652223984159,"longitud":-61.63622752613202,"ranking":0},
{"id":1700,"nombre":"Maggiolo Acceso","localidad":"Maggiolo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.668561,"longitud":-62.225375,"ranking":0},
{"id":3729,"nombre":"Maggiolo","localidad":"Maggiolo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.72183438089032,"longitud":-62.24698767795657,"ranking":0},
{"id":1702,"nombre":"Melincue","localidad":"Melincue","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.6601013403872,"longitud":-61.45877847395761,"ranking":0},
{"id":2932,"nombre":"Peyrano","localidad":"Peyrano","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.53825504921089,"longitud":-60.800101630842434,"ranking":0},
{"id":9098,"nombre":"Peyrano Acceso","localidad":"Peyrano","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.53521640644871,"longitud":-60.79703081140865,"ranking":0},
{"id":1000,"nombre":"Roldan","localidad":"Roldan","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.89983962701537,"longitud":-60.91063039962056,"ranking":0},
{"id":1767,"nombre":"Roldan Acceso","localidad":"Roldan","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.92603534179241,"longitud":-60.90037237705991,"ranking":0},
{"id":941,"nombre":"San Genaro","localidad":"San Genaro","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.367403,"longitud":-61.349955,"ranking":0},
{"id":3307,"nombre":"San Genaro Acceso","localidad":"San Genaro","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.38177263315314,"longitud":-61.35427135902885,"ranking":0},
{"id":1203,"nombre":"San Jorge","localidad":"San Jorge","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.6649490,"longitud":-60.7929644,"ranking":0},
{"id":1707,"nombre":"Santa Isabel Acceso","localidad":"Santa Isabel","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7552022,"longitud":-61.9739447,"ranking":0},
{"id":2442,"nombre":"Santa Isabel","localidad":"Santa Isabel","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.893231,"longitud":-61.703205,"ranking":0},
{"id":631,"nombre":"Garza","localidad":"Garza","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.14945,"longitud":-63.539843,"ranking":13},
{"id":1682,"nombre":"Garza Acceso","localidad":"Garza","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.1500355,"longitud":-63.53916580000001,"ranking":0},
{"id":1683,"nombre":"Taboada Acceso","localidad":"Garza","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.00617839763902,"longitud":-63.746515844219864,"ranking":0},
{"id":1529,"nombre":"Mailling","localidad":"Mailin","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.48001329848064,"longitud":-63.274969026445746,"ranking":0},
{"id":3160,"nombre":"Mailing Acceso","localidad":"Mailin","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.420146608028553,"longitud":-63.19911913194039,"ranking":0},
{"id":1639,"nombre":"Palo Negro","localidad":"Palo Negro","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.675148971482397,"longitud":-62.13559491582086,"ranking":0},
{"id":102,"nombre":"Selva","localidad":"Selva","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.765383,"longitud":-62.04462,"ranking":3},
{"id":632,"nombre":"Taboada","localidad":"Taboada","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.007739300,"longitud":-63.739929200,"ranking":30},
{"id":3657,"nombre":"Taboada Cruce de Caminos","localidad":"Taboada","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8393,"nombre":"Alpachiri ","localidad":"Alpachiri ","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.33600546760816,"longitud":-65.75763907227768,"ranking":0},
{"id":1491,"nombre":"La Merced","localidad":"La Cocha","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1492,"nombre":"Limite","localidad":"La Cocha","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.768014548173724,"longitud":-65.58413483465993,"ranking":0},
{"id":1495,"nombre":"Rumi Punco Acceso","localidad":"La Cocha","provincia":"Tucumán","pais":"ARGENTINA","latitud":-28.00369998058462,"longitud":-65.57965995446885,"ranking":0},
{"id":1497,"nombre":"La Cocha","localidad":"La Cocha","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.773306,"longitud":-65.589074,"ranking":10},
{"id":1088,"nombre":"Lules","localidad":"Lules","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.925071361865797,"longitud":-65.34082115107142,"ranking":0},
{"id":1660,"nombre":"Mercedes","localidad":"Mercedes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.661179,"longitud":-59.437709,"ranking":9},
{"id":1661,"nombre":"Olivera Acceso","localidad":"Mercedes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.632127885864506,"longitud":-59.24759877065597,"ranking":0},
{"id":4069,"nombre":"Rotonda Mercedes","localidad":"Mercedes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.665815700,"longitud":-59.404583600,"ranking":5},
{"id":7953,"nombre":"Mercedes Acceso","localidad":"Mercedes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6594315,"longitud":-59.4378125,"ranking":0},
{"id":200,"nombre":"Lobos","localidad":"Lobos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.1997847,"longitud":-59.0969682,"ranking":361},
{"id":1646,"nombre":"Lobos Acceso","localidad":"Lobos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.1997847,"longitud":-59.0969682,"ranking":0},
{"id":449,"nombre":"Roque Perez","localidad":"Roque Perez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.388213,"longitud":-59.344237,"ranking":105},
{"id":2047,"nombre":"Roque Perez Acceso","localidad":"Roque Perez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.397481210116624,"longitud":-59.40874099731445,"ranking":1},
{"id":242,"nombre":"Saladillo","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6735106,"longitud":-58.5004603,"ranking":326},
{"id":2049,"nombre":"Arroyo Saladillo","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2051,"nombre":"El Mangrullo Acceso","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.74718736329129,"longitud":-60.059628489174216,"ranking":0},
{"id":2052,"nombre":"San Benito Acceso","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.67537752670453,"longitud":-59.87876949801569,"ranking":0},
{"id":2054,"nombre":"San Benito","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.667992976266945,"longitud":-59.88633256686832,"ranking":0},
{"id":7594,"nombre":"Saladillo Acceso","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6735106,"longitud":-58.5004603,"ranking":3},
{"id":7980,"nombre":"Polvaredas","localidad":"Saladillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.592524234843204,"longitud":-59.50893481872883,"ranking":0},
{"id":3100,"nombre":"General Viamonte Acceso","localidad":"General Viamonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.00664695567294,"longitud":-61.012759115002446,"ranking":0},
{"id":3537,"nombre":"General Viamonte","localidad":"General Viamonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.999079,"longitud":-61.036794,"ranking":32},
{"id":9828,"nombre":"Baigorrita","localidad":"General Viamonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":176,"nombre":"General Alvear","localidad":"General Alvear","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.026629,"longitud":-60.014841,"ranking":39},
{"id":1532,"nombre":"General Alvear Acceso","localidad":"General Alvear","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.0043081,"longitud":-59.8876862,"ranking":0},
{"id":259,"nombre":"Tapalque","localidad":"Tapalque","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.351611,"longitud":-60.03206,"ranking":14},
{"id":3669,"nombre":"Tapalque Acceso","localidad":"Tapalque","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.35968808380261,"longitud":-60.006385233870695,"ranking":0},
{"id":4894,"nombre":"30 de Agosto","localidad":"30 de Agosto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.284892,"longitud":-62.553739,"ranking":20},
{"id":1698,"nombre":"Tres Lomas","localidad":"Tres Lomas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.461484,"longitud":-62.865068,"ranking":30},
{"id":2089,"nombre":"Ingeniero Thompson Acceso","localidad":"Tres Lomas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.613207194351475,"longitud":-62.916711877243,"ranking":0},
{"id":2090,"nombre":"Ingeniero Thompson","localidad":"Tres Lomas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2055,"nombre":"Salliquelo","localidad":"Salliquelo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.751871,"longitud":-62.955155,"ranking":9},
{"id":10829,"nombre":"Quenuma Acceso","localidad":"Quenuma","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3703,"nombre":"Quenuma","localidad":"Quenuma","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.54265323932971,"longitud":-63.051710426993395,"ranking":0},
{"id":7946,"nombre":"Tres Algarrobos","localidad":"Tres Algarrobos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.193719,"longitud":-62.772731,"ranking":2},
{"id":9821,"nombre":"Piedritas","localidad":"Piedritas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10785,"nombre":"Piedritas Acceso","localidad":"Piedritas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.780509,"longitud":-63.000369,"ranking":15},
{"id":1484,"nombre":"Urdampilleta","localidad":"Urdampilleta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.430617,"longitud":-61.430088,"ranking":5},
{"id":7950,"nombre":"Urdampilleta Acceso","localidad":"Urdampilleta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.36234439819961,"longitud":-61.49154025842475,"ranking":0},
{"id":233,"nombre":"Pirovano","localidad":"Pirovano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.510531,"longitud":-61.556269,"ranking":9},
{"id":3295,"nombre":"Pirovano Acceso","localidad":"Pirovano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.459210083466395,"longitud":-61.60713293132665,"ranking":0},
{"id":182,"nombre":"Huanguelen","localidad":"Huanguelen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.057543,"longitud":-61.929535,"ranking":9},
{"id":2067,"nombre":"Villa Ballester","localidad":"San Martin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.551404900,"longitud":-58.553674200,"ranking":0},
{"id":3062,"nombre":"General San Martin","localidad":"San Martin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.580436400,"longitud":-58.525076500,"ranking":57},
{"id":9868,"nombre":"Villa Ballester - Ball Tour","localidad":"San Martin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5493533,"longitud":-58.55425020000001,"ranking":0},
{"id":72,"nombre":"Sarmiento","localidad":"Sarmiento","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2380,"nombre":"Villa de Las Rosas","localidad":"Villa de Las Rosas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.946384,"longitud":-65.049867,"ranking":270},
{"id":1472,"nombre":"25 de Mayo","localidad":"25 de Mayo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4324014,"longitud":-60.16603,"ranking":122},
{"id":7460,"nombre":"25 de Mayo Acceso","localidad":"25 de Mayo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4260457876766,"longitud":-60.129963640526725,"ranking":0},
{"id":9205,"nombre":"Diego de Alvear","localidad":"25 de Mayo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":129,"nombre":"Alejandro Korn","localidad":"Alejandro Korn","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9778822,"longitud":-58.3811088,"ranking":473},
{"id":10908,"nombre":"Alejandro Korn Cruce","localidad":"Alejandro Korn","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9894255,"longitud":-58.382157,"ranking":0},
{"id":137,"nombre":"Banfield","localidad":"Banfield","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7383726,"longitud":-58.3982099,"ranking":66},
{"id":1755,"nombre":"Bialet Masse","localidad":"Bialet Masse","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.313782,"longitud":-64.461824,"ranking":149},
{"id":10096,"nombre":"Parador Bialet Masse","localidad":"Bialet Masse","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.313727,"longitud":-64.461786,"ranking":1},
{"id":144,"nombre":"Brandsen","localidad":"Brandsen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.1639217,"longitud":-58.2346080,"ranking":689},
{"id":2477,"nombre":"Brandsen Acceso","localidad":"Brandsen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.1714089,"longitud":-58.2301762,"ranking":5},
{"id":146,"nombre":"Cañuelas","localidad":"Cañuelas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0325582,"longitud":-58.7337036,"ranking":304},
{"id":3244,"nombre":"Cañuelas Acceso","localidad":"Cañuelas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.020515,"longitud":-58.749425,"ranking":53},
{"id":3060,"nombre":"Caseros - Tres de Febrero","localidad":"Caseros","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.602234300,"longitud":-58.561876400,"ranking":20},
{"id":7731,"nombre":"Del Carril Acceso","localidad":"Del Carril","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4622843133744,"longitud":-59.582249196800326,"ranking":0},
{"id":7733,"nombre":"Del Carril","localidad":"Del Carril","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.4623289,"longitud":-59.5823522,"ranking":3},
{"id":7871,"nombre":"Ensenada","localidad":"Ensenada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.857373,"longitud":-57.907706,"ranking":0},
{"id":8154,"nombre":"Ezpeleta","localidad":"Ezpeleta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7475635,"longitud":-58.2253517,"ranking":0},
{"id":892,"nombre":"Gonnet","localidad":"Gonnet","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.87979653528051,"longitud":-58.01078491077684,"ranking":0},
{"id":1533,"nombre":"General Belgrano","localidad":"General Belgrano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.761051,"longitud":-58.495154,"ranking":336},
{"id":15470,"nombre":"Termas del Salado","localidad":"General Belgrano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.7419265,"longitud":-58.4913805,"ranking":0},
{"id":7939,"nombre":"General Belgrano Acceso","localidad":"General Belgrano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.76882910268748,"longitud":-58.51449664742846,"ranking":0},
{"id":770,"nombre":"General Lavalle","localidad":"General Lavalle","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5355358,"longitud":-58.4856575,"ranking":0},
{"id":1537,"nombre":"General Lavalle Acceso","localidad":"General Lavalle","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.4307424,"longitud":-56.9155158,"ranking":4},
{"id":7531,"nombre":"Cañada Fernandez","localidad":"General Lavalle","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":187,"nombre":"Jose C. Paz","localidad":"Jose C. Paz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5180942,"longitud":-58.7496317,"ranking":54},
{"id":1548,"nombre":"El Cruce Jose C. Paz","localidad":"Jose C. Paz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.50941558911296,"longitud":-58.72907650680173,"ranking":0},
{"id":191,"nombre":"La Lucila del Mar","localidad":"La Lucila del Mar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.659954,"longitud":-56.684718,"ranking":106},
{"id":2414,"nombre":"La Lucila Acceso","localidad":"La Lucila del Mar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6631298,"longitud":-56.70487379999999,"ranking":1},
{"id":196,"nombre":"Lanus","localidad":"Lanus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7084542,"longitud":-58.3919120,"ranking":143},
{"id":197,"nombre":"Lanus Kolkinis","localidad":"Lanus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.708112,"longitud":-58.391658,"ranking":4},
{"id":1412,"nombre":"Lanus Mery","localidad":"Lanus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.704571,"longitud":-58.416414,"ranking":0},
{"id":8058,"nombre":"Lanus Oeste ","localidad":"Lanus","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6996376,"longitud":-58.3948896,"ranking":0},
{"id":201,"nombre":"Lomas de Zamora","localidad":"Lomas de Zamora","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7590987,"longitud":-58.4028989,"ranking":198},
{"id":9875,"nombre":"Cruce Lomas","localidad":"Lomas de Zamora","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7795967,"longitud":-58.4587212,"ranking":0},
{"id":12383,"nombre":"La Salada","localidad":"Lomas de Zamora","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7193786,"longitud":-58.47408399999999,"ranking":0},
{"id":202,"nombre":"Longchamps","localidad":"Longchamps","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8596537,"longitud":-58.3899683,"ranking":21},
{"id":8023,"nombre":"Longchamps Acceso","localidad":"Longchamps","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.86231555809888,"longitud":-58.38970025247352,"ranking":0},
{"id":2479,"nombre":"Magdalena","localidad":"Magdalena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.082072,"longitud":-57.519133,"ranking":390},
{"id":9199,"nombre":"Payro Acceso","localidad":"Magdalena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.22020139828508,"longitud":-57.73716513851868,"ranking":0},
{"id":3543,"nombre":"Martin Coronado","localidad":"Martin Coronado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.589432460832704,"longitud":-58.590459480941895,"ranking":0},
{"id":1663,"nombre":"Navarro","localidad":"Navarro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.01027899541843,"longitud":-59.28033071032582,"ranking":0},
{"id":3239,"nombre":"Navarro Acceso","localidad":"Navarro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.03054354877934,"longitud":-59.29350592669594,"ranking":0},
{"id":225,"nombre":"Nonogasta","localidad":"Nonogasta","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.301126,"longitud":-67.506011,"ranking":6},
{"id":227,"nombre":"Patquia","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.04059,"longitud":-66.881292,"ranking":24},
{"id":1943,"nombre":"El Estanquito","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.415327376763074,"longitud":-66.55620851662952,"ranking":0},
{"id":1944,"nombre":"La Casilla","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.040556572718614,"longitud":-66.88073295990979,"ranking":0},
{"id":1945,"nombre":"La Cortada","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1946,"nombre":"Portezuelo","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.837762257432793,"longitud":-66.70202136039734,"ranking":0},
{"id":1947,"nombre":"San Ramon","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1948,"nombre":"Talamuyuna","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.724484874727334,"longitud":-66.85366908196701,"ranking":0},
{"id":1941,"nombre":"Bella Vista","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1942,"nombre":"Cortado","localidad":"Patquia","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.040698701562704,"longitud":-66.88079310569066,"ranking":0},
{"id":234,"nombre":"Quilmes","localidad":"Quilmes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7422127,"longitud":-58.2903024,"ranking":155},
{"id":2037,"nombre":"12 de Octubre Y Av. Calchaqui","localidad":"Quilmes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.742123,"longitud":-58.290159,"ranking":20},
{"id":12140,"nombre":"Quilmes Sur","localidad":"Quilmes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.739278,"longitud":-58.238194,"ranking":2},
{"id":8267,"nombre":"Quilmes Oeste","localidad":"Quilmes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7422127,"longitud":-58.2903024,"ranking":2},
{"id":2478,"nombre":"Ranchos","localidad":"Ranchos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.512681,"longitud":-58.331178,"ranking":147},
{"id":9810,"nombre":"Ranchos Acceso","localidad":"Ranchos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":238,"nombre":"Remedios de Escalada","localidad":"Remedios de Escalada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7273654,"longitud":-58.3958707,"ranking":13},
{"id":239,"nombre":" Rivera","localidad":"Rivera","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-30.89928,"longitud":-55.54178,"ranking":32},
{"id":240,"nombre":"Rojas","localidad":"Rojas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.199259,"longitud":-60.725747,"ranking":35},
{"id":1375,"nombre":"Rojas Acceso","localidad":"Rojas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.2024174,"longitud":-60.7184148,"ranking":0},
{"id":7979,"nombre":"Rafael Obligado Acceso","localidad":"Rojas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.372575714927855,"longitud":-60.76146959674717,"ranking":0},
{"id":246,"nombre":"San Bernardo","localidad":"San Bernardo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.686133,"longitud":-56.688457,"ranking":581},
{"id":3241,"nombre":"San Vicente","localidad":"San Vicente","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.03185,"longitud":-58.417185,"ranking":310},
{"id":4366,"nombre":"San Vicente Acceso","localidad":"San Vicente","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.04218952469849,"longitud":-58.408363970548876,"ranking":0},
{"id":10907,"nombre":"San Vicente Cruce","localidad":"San Vicente","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.98846637933444,"longitud":-58.382227420806885,"ranking":0},
{"id":258,"nombre":"Sarandi","localidad":"Sarandi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6778791,"longitud":-58.3473534,"ranking":44},
{"id":260,"nombre":"Temperley","localidad":"Temperley","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.777362,"longitud":-58.392073,"ranking":28},
{"id":1649,"nombre":"Temperley Este","localidad":"Temperley","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7786837,"longitud":-58.3942210,"ranking":0},
{"id":4155,"nombre":"San José","localidad":"Temperley","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8492,"nombre":"Temperley Oeste","localidad":"Temperley","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7678337,"longitud":-58.3792534,"ranking":0},
{"id":3229,"nombre":"El Tropezon","localidad":"Tropezon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.59201,"longitud":-58.560652,"ranking":10},
{"id":2095,"nombre":"Tuclame","localidad":"Tuclame","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7482695,"longitud":-65.23271009999999,"ranking":0},
{"id":267,"nombre":"Turdera","localidad":"Turdera","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7887697,"longitud":-58.4083495,"ranking":8},
{"id":826,"nombre":"Valeria del Mar","localidad":"Valeria del Mar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.1284599,"longitud":-56.9062078,"ranking":6},
{"id":272,"nombre":"Vichigasta","localidad":"Vichigasta","provincia":"La Rioja","pais":"ARGENTINA","latitud":-29.501148778255605,"longitud":-67.49845434204767,"ranking":18},
{"id":274,"nombre":"Villa de Soto","localidad":"Villa de Soto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.856357,"longitud":-64.986464,"ranking":117},
{"id":9712,"nombre":"El Espinillo","localidad":"Villa de Soto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9841,"nombre":"Pichanas","localidad":"Villa de Soto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.245511779797464,"longitud":-62.92510928633956,"ranking":0},
{"id":9843,"nombre":"Dique Pichanas","localidad":"Villa de Soto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":275,"nombre":"Villa Dominico","localidad":"Villa Dominico","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6910517,"longitud":-58.3349776,"ranking":0},
{"id":280,"nombre":"Wilde","localidad":"Wilde","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7043791,"longitud":-58.3174545,"ranking":142},
{"id":896,"nombre":"Villa Elisa","localidad":"Villa Elisa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.853819400,"longitud":-58.078783800,"ranking":0},
{"id":2032,"nombre":"Pila","localidad":"Pila","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.000726,"longitud":-58.144564,"ranking":27},
{"id":7938,"nombre":"Pila Acceso","localidad":"Pila","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.007436543169156,"longitud":-58.13764584143051,"ranking":0},
{"id":2113,"nombre":"Tanti","localidad":"Tanti","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.35643,"longitud":-64.590737,"ranking":109},
{"id":9457,"nombre":"Empalme Tanti","localidad":"Tanti","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3595756,"longitud":-64.5986805,"ranking":48},
{"id":299,"nombre":"Laguna Larga","localidad":"Laguna Larga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.778137,"longitud":-63.800877,"ranking":37},
{"id":300,"nombre":"Oliva","localidad":"Oliva","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.042118,"longitud":-63.569828,"ranking":73},
{"id":1555,"nombre":"San Jose de la Dormida","localidad":"San Jose de la Dormida","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.358552,"longitud":-63.950533,"ranking":75},
{"id":9126,"nombre":"San Jose de la Dormida Acceso","localidad":"San Jose de la Dormida","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.35691596091602,"longitud":-63.94115924835205,"ranking":6},
{"id":328,"nombre":"Morteros","localidad":"Morteros","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.70778,"longitud":-62.001592,"ranking":198},
{"id":331,"nombre":"Las Catitas","localidad":"Las Catitas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.294723,"longitud":-68.05069,"ranking":0},
{"id":3988,"nombre":"Las Catitas Acceso","localidad":"Las Catitas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.290242,"longitud":-68.047568,"ranking":0},
{"id":353,"nombre":"El Nochero","localidad":"El Nochero","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.22736706303401,"longitud":-61.527836322784424,"ranking":5},
{"id":10847,"nombre":"El Nochero Acceso","localidad":"El Nochero","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":354,"nombre":"Gato Colorado","localidad":"Gato Colorado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.0262,"longitud":-61.18441,"ranking":0},
{"id":359,"nombre":"La Clotilde","localidad":"La Clotilde","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":16},
{"id":3301,"nombre":"La Clotilde Acceso","localidad":"La Clotilde","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.176402,"longitud":-60.624757,"ranking":0},
{"id":370,"nombre":"Barranqueras","localidad":"Barranqueras","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.48293,"longitud":-58.947884,"ranking":45},
{"id":7998,"nombre":"Barranqueras Acceso","localidad":"Barranqueras","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.5010872,"longitud":-58.94221489999999,"ranking":0},
{"id":9196,"nombre":"Corrienbarranque","localidad":"Barranqueras","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":371,"nombre":"Tatane","localidad":"Tatane","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.396863465440976,"longitud":-58.35792466651725,"ranking":0},
{"id":10862,"nombre":"Tatane Acceso","localidad":"Tatane","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.3988948,"longitud":-58.3492249,"ranking":0},
{"id":372,"nombre":"Formosa","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.190656,"longitud":-58.194424,"ranking":3904},
{"id":1903,"nombre":"Ignacio de Loyola","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.264157552972097,"longitud":-57.72452078835181,"ranking":0},
{"id":4422,"nombre":"Puente Ignacio de Loyola","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.2781478,"longitud":-57.72315800000001,"ranking":0},
{"id":8067,"nombre":"Aeropuerto Formosa","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.214059058060165,"longitud":-58.23082965666624,"ranking":0},
{"id":9971,"nombre":"Saenz Peña","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10104,"nombre":"Los Blancos","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10110,"nombre":"Esterito","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.29940712,"longitud":-58.539447779,"ranking":0},
{"id":8531,"nombre":"El Parador","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9109,"nombre":"Presidente Yrigoyen","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.175752605864297,"longitud":-58.85260232938684,"ranking":0},
{"id":9115,"nombre":"Porton Negro","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.964653520452575,"longitud":-58.741835355233896,"ranking":0},
{"id":9140,"nombre":"Siete Palmas","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.20284419016895,"longitud":-58.33019175994655,"ranking":0},
{"id":9163,"nombre":"Villafañe","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.20467326631304,"longitud":-59.070622986616236,"ranking":0},
{"id":9214,"nombre":"El Peligro","localidad":"Formosa","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":373,"nombre":"General Lucio V. Mansilla Acceso","localidad":"General Lucio V Mansilla","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.654577,"longitud":-58.631347,"ranking":78},
{"id":8937,"nombre":"General Lucio V Mansilla","localidad":"General Lucio V Mansilla","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.651553,"longitud":-58.628358,"ranking":2},
{"id":376,"nombre":"Basail","localidad":"Basail","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.460069,"longitud":-58.982961,"ranking":2},
{"id":2183,"nombre":"Basail Acceso","localidad":"Basail","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.882878717063928,"longitud":-59.2793083190918,"ranking":11},
{"id":379,"nombre":"Villa Celina","localidad":"Villa Celina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6905902,"longitud":-58.4810218,"ranking":3},
{"id":3104,"nombre":"Ciudad Evita","localidad":"Ciudad Evita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.72241115412599,"longitud":-58.535732292919484,"ranking":0},
{"id":383,"nombre":"Isidro Casanova","localidad":"Isidro Casanova","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7204110,"longitud":-58.5697249,"ranking":0},
{"id":2771,"nombre":"Tunuyan","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.577822,"longitud":-69.011977,"ranking":883},
{"id":3654,"nombre":"Tunuyan Acceso","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.590361,"longitud":-69.015542,"ranking":0},
{"id":4538,"nombre":"Chacon","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4551,"nombre":"Los Arboles","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14305,"nombre":"Plaza Tunuyan","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.581022,"longitud":-69.01464039999999,"ranking":8},
{"id":4553,"nombre":"Colonia de Las Rosas","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4556,"nombre":"Los Silos","localidad":"Tunuyan","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2994,"nombre":"Algarrobo del Aguila","localidad":"Algarrobo del Aguila","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.407178,"longitud":-67.143548,"ranking":8},
{"id":1933,"nombre":"Cruce 25 de Mayo","localidad":"Puelen","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.7824083,"longitud":-67.7044141,"ranking":0},
{"id":2473,"nombre":"Puelen","localidad":"Puelen","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.3456829,"longitud":-67.6216176,"ranking":3},
{"id":9110,"nombre":"Puelen Acceso","localidad":"Puelen","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.34916339999999,"longitud":-67.626627,"ranking":0},
{"id":3002,"nombre":"Colonia 25 de Mayo","localidad":"25 de Mayo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.769298,"longitud":-67.717301,"ranking":324},
{"id":3698,"nombre":"Colonia 25 de Mayo Acceso","localidad":"25 de Mayo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.721297,"longitud":-67.763888,"ranking":37},
{"id":401,"nombre":"Catriel","localidad":"Catriel","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-37.876062,"longitud":-67.795445,"ranking":1125},
{"id":2015,"nombre":"Catriel Acceso","localidad":"Catriel","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-37.901541,"longitud":-67.860841,"ranking":15},
{"id":2992,"nombre":"El Portezuelo","localidad":"El Portezuelo","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.834996,"longitud":-66.697698,"ranking":4},
{"id":4295,"nombre":"El Portezuelo Acceso","localidad":"El Portezuelo","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":416,"nombre":" Catamarca","localidad":"San Fernando del Valle de Catamarca","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.475657,"longitud":-65.774664,"ranking":5177},
{"id":1717,"nombre":"San Fernando","localidad":"San Fernando del Valle de Catamarca","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.3336418,"longitud":-66.89794090000001,"ranking":0},
{"id":8275,"nombre":"San Jose","localidad":"San Fernando del Valle de Catamarca","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":417,"nombre":"Fraile Pintado","localidad":"Fraile Pintado","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.940466,"longitud":-64.801005,"ranking":1},
{"id":3308,"nombre":"Fraile Pintado Acceso","localidad":"Fraile Pintado","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.938025426691272,"longitud":-64.80159381869954,"ranking":0},
{"id":425,"nombre":"Los Andes","localidad":"Los Andes","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.826658,"longitud":-70.599507,"ranking":0},
{"id":4113,"nombre":"Los Andes Acceso","localidad":"Los Andes","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.809718,"longitud":-70.642379,"ranking":0},
{"id":426,"nombre":"Santiago de Chile","localidad":"Santiago de Chile","provincia":"Región XIII - Metropolitana","pais":"CHILE","latitud":-33.4539276,"longitud":-70.6887186,"ranking":1160},
{"id":8344,"nombre":"Maipo","localidad":"Santiago de Chile","provincia":"Región XIII - Metropolitana","pais":"CHILE","latitud":-33.72919612721522,"longitud":-70.6847514764005,"ranking":0},
{"id":8345,"nombre":"Melipilla","localidad":"Santiago de Chile","provincia":"Región XIII - Metropolitana","pais":"CHILE","latitud":-33.69730144870337,"longitud":-71.21571865370073,"ranking":0},
{"id":8346,"nombre":"Pomaire","localidad":"Santiago de Chile","provincia":"Región XIII - Metropolitana","pais":"CHILE","latitud":-33.64981618609968,"longitud":-71.15120297256001,"ranking":0},
{"id":1026,"nombre":"Viña del Mar","localidad":"Viña del Mar - Chile","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-23.423587,"longitud":-56.756009,"ranking":143},
{"id":428,"nombre":"Valparaiso","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.047004,"longitud":-71.606058,"ranking":30},
{"id":4210,"nombre":"Concon","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.922756,"longitud":-71.506945,"ranking":0},
{"id":8374,"nombre":"San Felipe","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.754101,"longitud":-70.720093,"ranking":0},
{"id":9122,"nombre":"Maitencillo","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.647738,"longitud":-71.438661,"ranking":0},
{"id":9167,"nombre":"Zapallar","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.551261,"longitud":-71.457922,"ranking":0},
{"id":8367,"nombre":"Las Cruces","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.497412,"longitud":-71.622994,"ranking":0},
{"id":8368,"nombre":"Papudo ","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.497412,"longitud":-71.622994,"ranking":0},
{"id":8370,"nombre":"Quintero","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.777155,"longitud":-71.526479,"ranking":0},
{"id":8371,"nombre":"Reñaca","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.968209,"longitud":-71.545395,"ranking":0},
{"id":8372,"nombre":"Rocas de Santo Domingo","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.635787,"longitud":-71.628759,"ranking":0},
{"id":8373,"nombre":"San Antonio Lloleo ","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.613245,"longitud":-71.614946,"ranking":0},
{"id":8319,"nombre":"San Juan Bautista ","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.638042,"longitud":-78.831287,"ranking":0},
{"id":8362,"nombre":"Algarrobo","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.3705,"longitud":-71.667317,"ranking":0},
{"id":8363,"nombre":"Cachagua","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.577366,"longitud":-71.443219,"ranking":0},
{"id":8364,"nombre":"Cartagena","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.638042,"longitud":-78.831287,"ranking":0},
{"id":8366,"nombre":"La Ligua","localidad":"Valparaiso","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.450823,"longitud":-71.230743,"ranking":0},
{"id":429,"nombre":"Tacna","localidad":"Tacna","provincia":"Tacna","pais":"Perú","latitud":-17.9946496,"longitud":-70.244278,"ranking":1010},
{"id":15649,"nombre":"Tacna - Terminal Terrestre","localidad":"Tacna","provincia":"Tacna","pais":"Perú","latitud":-18.005009,"longitud":-70.258247,"ranking":0},
{"id":7481,"nombre":"Camiara Acceso","localidad":"Tacna","provincia":"Tacna","pais":"Perú","latitud":-17.57987861637305,"longitud":-70.85975392278128,"ranking":0},
{"id":7585,"nombre":"Lima - La Victoria ","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.05982101383192,"longitud":-77.02819041835743,"ranking":1015},
{"id":7586,"nombre":"Lima - Plaza Norte","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.9621325,"longitud":-77.0635592,"ranking":1015},
{"id":15639,"nombre":"Lima - Cavassa","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0670993,"longitud":-77.0323693,"ranking":0},
{"id":15598,"nombre":"Lima - Jose Galvez","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15607,"nombre":"Lima - Zapallal","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15613,"nombre":"Lima - Terminal Marco Polo","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0093073,"longitud":-77.05756269999999,"ranking":0},
{"id":15623,"nombre":"Lima - Marco Polo","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0093073,"longitud":-77.05756269999999,"ranking":0},
{"id":15632,"nombre":"Lima - Vitarte","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0299736,"longitud":-76.9228859,"ranking":0},
{"id":15638,"nombre":"Lima - Terminal Cromotex","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0644707,"longitud":-77.03335400000002,"ranking":0},
{"id":15590,"nombre":"Metro - Los Olivos","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15591,"nombre":"La Victoria - Bauzate y Meza","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15592,"nombre":"Lima - Fundición","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15593,"nombre":"Lima - Fundición 2","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15594,"nombre":"Lima - Infantas","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15597,"nombre":"Lima - Surquillo","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.1080203,"longitud":-77.01771819999999,"ranking":0},
{"id":15546,"nombre":"Lima - Los Olivos","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.9880326,"longitud":-77.06487,"ranking":900},
{"id":15581,"nombre":"Plaza Norte LB112","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15582,"nombre":"Plaza Norte LB129","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15583,"nombre":"Lima - Puente Piedra","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15584,"nombre":"Raimondi","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15589,"nombre":"Tomas Valle - Los Olivos","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15456,"nombre":"Lima - Atocongo","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.150741568202772,"longitud":-76.97950630233507,"ranking":10},
{"id":15457,"nombre":"Lima - Yerbateros","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0642121,"longitud":-76.995835,"ranking":0},
{"id":15458,"nombre":"Luna Pizarro","localidad":"Lima","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-12.0608696,"longitud":-77.0280585,"ranking":0},
{"id":1337,"nombre":"Montevideo (Terminal Tres Cruces)","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.8925555,"longitud":-56.1651556,"ranking":426},
{"id":2692,"nombre":"Plaza Cuba","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.872312083071954,"longitud":-56.203120051928266,"ranking":8},
{"id":14675,"nombre":"Aeropuerto Internacional De Carrasco","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.8370966,"longitud":-56.0161798,"ranking":0},
{"id":14697,"nombre":"Punta de Rieles","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.8246269,"longitud":-56.1019168,"ranking":0},
{"id":7604,"nombre":"Pantanoso ","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.82143731143237,"longitud":-56.203469048186335,"ranking":0},
{"id":7675,"nombre":"Artigas ","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.893879645577165,"longitud":-56.166726053814216,"ranking":0},
{"id":8076,"nombre":"Los Troncos","localidad":"Montevideo","provincia":"Montevideo","pais":"URUGUAY","latitud":-34.811918803294546,"longitud":-56.235820575174245,"ranking":0},
{"id":3230,"nombre":"Guernica","localidad":"Guernica","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9173924,"longitud":-58.3843545,"ranking":1},
{"id":466,"nombre":"Desaguadero","localidad":"Desaguadero","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.402228,"longitud":-67.155727,"ranking":24},
{"id":2593,"nombre":"Balde Acceso","localidad":"Balde","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2987,"nombre":"Balde","localidad":"Balde","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":472,"nombre":"Berrotaran","localidad":"Berrotaran","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.452548,"longitud":-64.385864,"ranking":303},
{"id":4184,"nombre":"Berrotaran Acceso","localidad":"Berrotaran","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.452548,"longitud":-64.385864,"ranking":0},
{"id":1349,"nombre":"Jocoli","localidad":"Jocoli","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.58630614791866,"longitud":-68.66801096077107,"ranking":0},
{"id":3130,"nombre":"Jocoli Acceso","localidad":"Jocoli","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.5840572,"longitud":-68.66674619999999,"ranking":0},
{"id":3859,"nombre":"Tres Esquinas","localidad":"Tres Esquinas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.83883820881673,"longitud":-69.07297465042726,"ranking":0},
{"id":1441,"nombre":"Villa Media Agua Acceso","localidad":"Media Agua","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.9793793,"longitud":-68.441074,"ranking":0},
{"id":2034,"nombre":"Villa Media Agua","localidad":"Media Agua","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.980828,"longitud":-68.427238,"ranking":50},
{"id":10888,"nombre":"Media Agua","localidad":"Media Agua","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.98085,"longitud":-68.427076,"ranking":1},
{"id":8272,"nombre":"Talacasto","localidad":"Talacasto","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2379,"nombre":"Jachal","localidad":"San Jose de Jachal","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.246086,"longitud":-68.73971,"ranking":480},
{"id":8273,"nombre":"Niquivil","localidad":"San Jose de Jachal","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.410136038065453,"longitud":-68.6906725074681,"ranking":17},
{"id":480,"nombre":"General Levalle","localidad":"General Levalle","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.011243,"longitud":-63.92114,"ranking":192},
{"id":8379,"nombre":"General Levalle Acceso","localidad":"General Levalle","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.011328,"longitud":-63.921166,"ranking":0},
{"id":495,"nombre":"San Martin","localidad":"San Martin","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.220891110078234,"longitud":-65.77354903992563,"ranking":31},
{"id":500,"nombre":"Hughes","localidad":"Hughes","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7554916,"longitud":-61.9737225,"ranking":0},
{"id":4618,"nombre":"Hughes Acceso","localidad":"Hughes","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.797797,"longitud":-61.34395,"ranking":12},
{"id":2502,"nombre":"Fraga","localidad":"Fraga","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.500118,"longitud":-65.789364,"ranking":8},
{"id":4193,"nombre":"Fraga Acceso","localidad":"Fraga","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1346,"nombre":"Chajan","localidad":"Chajan","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.556895,"longitud":-64.999867,"ranking":0},
{"id":9184,"nombre":"Chajan Acceso","localidad":"Chajan","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.55770497535653,"longitud":-65.00133424327589,"ranking":0},
{"id":2732,"nombre":"General Deheza","localidad":"General Deheza","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.755255,"longitud":-63.793052,"ranking":188},
{"id":514,"nombre":"Bowen","localidad":"Bowen","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.994094,"longitud":-67.510272,"ranking":156},
{"id":3674,"nombre":"Bowen Acceso","localidad":"Bowen","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.97830185842369,"longitud":-67.50971490713307,"ranking":0},
{"id":516,"nombre":"Realico","localidad":"Realico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.041327,"longitud":-64.262975,"ranking":517},
{"id":1937,"nombre":"Ingeniero Luiggi","localidad":"Realico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.379734,"longitud":-64.473264,"ranking":0},
{"id":2390,"nombre":"Realico Acceso","localidad":"Realico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.053727,"longitud":-64.264632,"ranking":0},
{"id":4212,"nombre":"Parera","localidad":"Realico","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7614,"nombre":"Adolfo Van Praet","localidad":"Realico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.01674172144527,"longitud":-64.03481194232977,"ranking":0},
{"id":2747,"nombre":"La Quebrada Acceso","localidad":"La Quebrada","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2748,"nombre":"Nogoli Acceso","localidad":"Nogoli","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.92128729319607,"longitud":-66.32687856779766,"ranking":0},
{"id":2991,"nombre":"Nogoli","localidad":"Nogoli","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2749,"nombre":"San Francisco","localidad":"San Francisco","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.59146439367347,"longitud":-66.12737312177106,"ranking":0},
{"id":2976,"nombre":"Leandro N. Alem","localidad":"Leandro N Alem","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.474124,"longitud":-66.060722,"ranking":1},
{"id":8030,"nombre":"Leandro N Alem Acceso","localidad":"Leandro N Alem","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.474147813620505,"longitud":-66.06087907525908,"ranking":0},
{"id":2590,"nombre":"Lujan","localidad":"Lujan","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.367817,"longitud":-65.933019,"ranking":12},
{"id":8031,"nombre":"Lujan Acceso","localidad":"Lujan","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.36882683649792,"longitud":-65.95093947656409,"ranking":0},
{"id":2552,"nombre":"Quines","localidad":"Quines","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.237052,"longitud":-65.805722,"ranking":25},
{"id":8032,"nombre":"Quines Acceso","localidad":"Quines","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.248471905445,"longitud":-65.80623238283991,"ranking":0},
{"id":2751,"nombre":"Lafinur Acceso","localidad":"Lafinur","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.057644947779075,"longitud":-65.33491945681102,"ranking":0},
{"id":2986,"nombre":"Lafinur","localidad":"Lafinur","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.05792040467826,"longitud":-65.34201254938554,"ranking":0},
{"id":539,"nombre":"Cañada de Gomez","localidad":"Cañada de Gomez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.8127719,"longitud":-61.3967569,"ranking":0},
{"id":8041,"nombre":"Cañada de Gomez Acceso ","localidad":"Cañada de Gomez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.8103553,"longitud":-61.3944543,"ranking":0},
{"id":543,"nombre":"Huinca Renanco","localidad":"Huinca Renanco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.840767,"longitud":-64.367961,"ranking":383},
{"id":3423,"nombre":"Huinca Renanco Acceso","localidad":"Huinca Renanco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.83921465912845,"longitud":-64.36113334095515,"ranking":0},
{"id":3237,"nombre":"Centenario","localidad":"Centenario","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.826194,"longitud":-68.119974,"ranking":0},
{"id":4670,"nombre":"Vista Alegre Sur","localidad":"Centenario","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.832485,"longitud":-68.127858,"ranking":0},
{"id":1913,"nombre":"Macachin","localidad":"Macachin","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.136873,"longitud":-63.665863,"ranking":0},
{"id":1914,"nombre":"Rotonda Macachin","localidad":"Macachin","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.123059,"longitud":-63.666827,"ranking":41},
{"id":8357,"nombre":"Macachin Acceso","localidad":"Macachin","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.12383243955274,"longitud":-63.66664219572993,"ranking":0},
{"id":2726,"nombre":"Choique Acceso","localidad":"Choique","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7928,"nombre":"Choique","localidad":"Choique","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.469222569625785,"longitud":-62.69473764543268,"ranking":0},
{"id":2725,"nombre":"San German Acceso","localidad":"San German","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2975332710361,"longitud":-62.980136544798945,"ranking":0},
{"id":3668,"nombre":"San German","localidad":"San German","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.300163096596854,"longitud":-62.98274997690931,"ranking":6},
{"id":2549,"nombre":"Villa Iris","localidad":"Villa Iris","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.1718186,"longitud":-63.2363971,"ranking":3},
{"id":558,"nombre":"El Chocon","localidad":"El Chocon","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.2594912,"longitud":-68.7834936,"ranking":2},
{"id":3897,"nombre":"El Chocon Acceso","localidad":"El Chocon","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.246816,"longitud":-68.798972,"ranking":18},
{"id":14458,"nombre":"Villa El Chocon","localidad":"El Chocon","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.2604371,"longitud":-68.78255109999999,"ranking":0},
{"id":4126,"nombre":"Picun Leufu","localidad":"Picun Leufu","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.513355,"longitud":-69.292295,"ranking":74},
{"id":565,"nombre":"Solis","localidad":"Solis","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.29512747406086,"longitud":-59.32017414194121,"ranking":0},
{"id":8341,"nombre":"Solis Acceso","localidad":"Solis","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.299798765036684,"longitud":-59.32193466226829,"ranking":0},
{"id":569,"nombre":"Nueva Galia","localidad":"Nueva Galia","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.115374,"longitud":-65.258688,"ranking":9},
{"id":4175,"nombre":"Nueva Galia Acceso","localidad":"Nueva Galia","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.10384555990094,"longitud":-65.25242501289854,"ranking":0},
{"id":570,"nombre":"Rancul","localidad":"Rancul","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.072997,"longitud":-64.683441,"ranking":12},
{"id":2389,"nombre":"Rancul Acceso","localidad":"Rancul","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.08291331382129,"longitud":-64.68110096754282,"ranking":0},
{"id":8266,"nombre":"Parera","localidad":"Rancul","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2993,"nombre":"Gamay","localidad":"Gamay","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7912,"nombre":"Gamay Acceso ","localidad":"Gamay","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3929,"nombre":"Peru","localidad":"Peru","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7914,"nombre":"Peru Acceso","localidad":"Peru","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.662830868605084,"longitud":-64.13219123852737,"ranking":0},
{"id":2735,"nombre":"Bernasconi","localidad":"Bernasconi","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.90150091210677,"longitud":-63.74237438155048,"ranking":0},
{"id":7916,"nombre":"Bernasconi Acceso","localidad":"Bernasconi","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.885019880909525,"longitud":-63.7409735117088,"ranking":0},
{"id":1923,"nombre":"General San Martin","localidad":"General San Martin","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.978787,"longitud":-63.60536,"ranking":41},
{"id":3001,"nombre":"Jacinto Arauz","localidad":"Jacinto Arauz","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.085943,"longitud":-63.430862,"ranking":22},
{"id":581,"nombre":"La Rinconada","localidad":"La Rinconada","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.999258600,"longitud":-70.838920400,"ranking":0},
{"id":588,"nombre":"General Conesa","localidad":"General Conesa","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.109283,"longitud":-64.453004,"ranking":509},
{"id":5533,"nombre":"General Conesa Acceso ","localidad":"General Conesa","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.108520360543075,"longitud":-64.47459517732786,"ranking":0},
{"id":2982,"nombre":"Juan Jorba","localidad":"Juan Jorba","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.611614,"longitud":-65.269055,"ranking":0},
{"id":3313,"nombre":"Juan Jorba Acceso","localidad":"Juan Jorba","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.6209499,"longitud":-65.2967117,"ranking":0},
{"id":598,"nombre":"Uspallata","localidad":"Uspallata","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.590536,"longitud":-69.347323,"ranking":345},
{"id":4225,"nombre":"Las Bovedas","localidad":"Uspallata","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":599,"nombre":"Diego de Alvear","localidad":"Diego de Alvear","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.372169,"longitud":-62.128022,"ranking":0},
{"id":4188,"nombre":"Parador Diego de Alvear","localidad":"Diego de Alvear","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":602,"nombre":"Tilisarao","localidad":"Tilisarao","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.74012,"longitud":-65.277615,"ranking":117},
{"id":7771,"nombre":"Tilisarao Acceso","localidad":"Tilisarao","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.74106614820953,"longitud":-65.27814407112321,"ranking":0},
{"id":605,"nombre":"Zarate","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.120086,"longitud":-59.015688,"ranking":109},
{"id":1710,"nombre":"Rotonda Zarate","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.120086,"longitud":-59.015688,"ranking":8},
{"id":7591,"nombre":"Atucha","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.97538478559243,"longitud":-59.20095694129699,"ranking":0},
{"id":7592,"nombre":"Las Palmas ","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.095172,"longitud":-59.162515,"ranking":0},
{"id":9982,"nombre":"Zarate Acceso","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.12504988589542,"longitud":-59.08251278658766,"ranking":0},
{"id":10101,"nombre":"Las Palmas Acceso","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.096630104631394,"longitud":-59.162921237094295,"ranking":0},
{"id":10733,"nombre":"Anta y De la Torre","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.097372666761494,"longitud":-59.03693178248232,"ranking":0},
{"id":10734,"nombre":"Av. Mitre y Rawson","localidad":"Zarate","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.107179559270044,"longitud":-59.027677903592185,"ranking":0},
{"id":2476,"nombre":"Viña Acceso","localidad":"Viña","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.99251804825594,"longitud":-60.22628223214828,"ranking":0},
{"id":2930,"nombre":"Viña","localidad":"Viña","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.99194177696254,"longitud":-60.22741001510172,"ranking":0},
{"id":1704,"nombre":"Santa Emilia Acceso","localidad":"Santa Emilia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.83027713194798,"longitud":-61.49386494028497,"ranking":0},
{"id":2972,"nombre":"Santa Emilia","localidad":"Santa Emilia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7553630,"longitud":-61.9738204,"ranking":0},
{"id":612,"nombre":"San Miguel del Monte","localidad":"San Miguel del Monte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.436074,"longitud":-58.781779,"ranking":563},
{"id":3177,"nombre":"San Miguel del Monte Acceso","localidad":"San Miguel del Monte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.432290000,"longitud":-58.808119300,"ranking":0},
{"id":613,"nombre":"Las Flores","localidad":"Las Flores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.0170097,"longitud":-59.1013805,"ranking":480},
{"id":2418,"nombre":"Las Flores Acceso","localidad":"Las Flores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.027384,"longitud":-59.08383,"ranking":15},
{"id":5503,"nombre":"Parador Las Flores","localidad":"Las Flores","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":925,"nombre":"Pardo","localidad":"Pardo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.244307796315454,"longitud":-59.364988803863525,"ranking":0},
{"id":2430,"nombre":"Pardo Acceso","localidad":"Pardo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.258979358895175,"longitud":-59.34381202276548,"ranking":0},
{"id":615,"nombre":"Loreto","localidad":"Loreto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.301926,"longitud":-64.186324,"ranking":221},
{"id":616,"nombre":"Cachari","localidad":"Cachari","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.380896,"longitud":-59.501237,"ranking":8},
{"id":2394,"nombre":"Cachari Acceso","localidad":"Cachari","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.38724887102847,"longitud":-59.492360463657796,"ranking":8},
{"id":618,"nombre":"Benito Juarez","localidad":"Benito Juarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.67797,"longitud":-59.810392,"ranking":154},
{"id":2392,"nombre":"Benito Juarez Acceso","localidad":"Benito Juarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.6471567,"longitud":-59.8583972,"ranking":12},
{"id":623,"nombre":"Fitz Roy","localidad":"Fitz Roy","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.024986,"longitud":-67.247873,"ranking":64},
{"id":622,"nombre":"Tres Cerros","localidad":"Tres Cerros","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-48.143669,"longitud":-67.651687,"ranking":0},
{"id":625,"nombre":"Las Gaviotas","localidad":"Las Gaviotas","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":630,"nombre":"Lugones","localidad":"Lugones","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.337722,"longitud":-63.340364,"ranking":5},
{"id":1527,"nombre":"Lugones Acceso","localidad":"Lugones","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.339029513845638,"longitud":-63.33919416614177,"ranking":0},
{"id":634,"nombre":"Forres","localidad":"Forres","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.869745,"longitud":-63.976024,"ranking":16},
{"id":1655,"nombre":"Forres Acceso","localidad":"Forres","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.870244797996342,"longitud":-63.97037429879877,"ranking":0},
{"id":636,"nombre":"Nueva Esperanza","localidad":"Nueva Esperanza","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.202882,"longitud":-64.239968,"ranking":0},
{"id":1632,"nombre":"Rocamora","localidad":"La Matanza","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":647,"nombre":"Allen","localidad":"Allen","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.980527,"longitud":-67.830764,"ranking":88},
{"id":3415,"nombre":"Allen Acceso","localidad":"Allen","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.0031775,"longitud":-67.8476572,"ranking":6},
{"id":660,"nombre":"Mantilla","localidad":"Mantilla","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.7542389,"longitud":-58.65026750000001,"ranking":4},
{"id":4564,"nombre":"Mantilla Acceso","localidad":"Mantilla","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.7452533,"longitud":-58.65506809999999,"ranking":32},
{"id":2378,"nombre":"Vicente López","localidad":"Vicente Lopez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5352782,"longitud":-58.4779458,"ranking":0},
{"id":4149,"nombre":"Rafael Calzada","localidad":"Rafael Calzada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3061,"nombre":"Don Torcuato","localidad":"Don Torcuato","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4180632,"longitud":-58.5774746,"ranking":0},
{"id":1309,"nombre":"Purmamarca","localidad":"Purmamarca","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.745119,"longitud":-65.498787,"ranking":141},
{"id":4891,"nombre":"Purmamarca Acceso","localidad":"Purmamarca","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.743259,"longitud":-65.501143,"ranking":33},
{"id":2508,"nombre":"Arata","localidad":"Arata","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.639352457671585,"longitud":-64.35675678773637,"ranking":2},
{"id":2387,"nombre":"Caleufu","localidad":"Caleufu","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.59458013323698,"longitud":-64.55888884433189,"ranking":0},
{"id":684,"nombre":"Chillar","localidad":"Chillar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.31071,"longitud":-59.975852,"ranking":15},
{"id":2395,"nombre":"Chillar Acceso","localidad":"Chillar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.310861600,"longitud":-59.975786800,"ranking":6},
{"id":685,"nombre":"Gonzalez Chaves","localidad":"Gonzalez Chaves","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.029016,"longitud":-60.101955,"ranking":266},
{"id":2409,"nombre":"Gonzalez Chaves Acceso","localidad":"Gonzalez Chaves","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.01167180223012,"longitud":-60.10116296992966,"ranking":0},
{"id":686,"nombre":"General Arenales","localidad":"General Arenales","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3010942,"longitud":-61.3011145,"ranking":1},
{"id":687,"nombre":"General Lamadrid","localidad":"General Lamadrid","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.243991,"longitud":-61.258775,"ranking":116},
{"id":1536,"nombre":"Rotonda General Lamadrid","localidad":"General Lamadrid","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.24187564527772,"longitud":-61.25552729546837,"ranking":0},
{"id":10873,"nombre":"General Lamadrid Acceso","localidad":"General Lamadrid","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":681,"nombre":"Arribeños","localidad":"Arribeños","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.208895,"longitud":-61.355607,"ranking":2},
{"id":2057,"nombre":"Altura Cello","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2063,"nombre":"Frontera","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.420175107779936,"longitud":-62.062509696378484,"ranking":0},
{"id":2064,"nombre":"Humberto Primo","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.2533075,"longitud":-61.4814247,"ranking":0},
{"id":2066,"nombre":"Moises Ville","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.71688961647256,"longitud":-61.47195080250396,"ranking":0},
{"id":2070,"nombre":"Susana","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.355693637299826,"longitud":-61.51664903925264,"ranking":0},
{"id":2072,"nombre":"Virginia","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5468,"nombre":"San Vicente","localidad":"Castellanos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2411,"nombre":"Guisasola","localidad":"Guisasola Jose","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.6764288,"longitud":-61.0859166,"ranking":1},
{"id":7539,"nombre":"Guisasola Acceso","localidad":"Guisasola Jose","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.70504665132931,"longitud":-61.09237147532017,"ranking":0},
{"id":689,"nombre":"Hilario Lagos","localidad":"Hilario Lagos","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.02377,"longitud":-63.914821,"ranking":0},
{"id":2410,"nombre":"Hilario Lagos Acceso","localidad":"Hilario Lagos","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.046236661426846,"longitud":-63.91469641488506,"ranking":0},
{"id":690,"nombre":"Intendente Alvear","localidad":"Intedente Alvear","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.234948,"longitud":-63.590346,"ranking":190},
{"id":691,"nombre":"Larroude","localidad":"Larroude","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.02595198668663,"longitud":-63.58262780752765,"ranking":0},
{"id":2405,"nombre":"Larroude Acceso","localidad":"Larroude","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.04187,"longitud":-63.580968,"ranking":0},
{"id":2423,"nombre":"Libano","localidad":"Libano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.531867,"longitud":-61.287783,"ranking":0},
{"id":2426,"nombre":"Macalu","localidad":"Macalu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":694,"nombre":"Monte","localidad":"Monte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2399,"nombre":"Monte Acceso","localidad":"Monte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":695,"nombre":"Pedro Luro","localidad":"Pedro Luro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.51399,"longitud":-62.675004,"ranking":26},
{"id":4131,"nombre":"Pedro Luro Acceso","localidad":"Pedro Luro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.5020817,"longitud":-62.6675957,"ranking":195},
{"id":2436,"nombre":"Roberts","localidad":"Roberts","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":698,"nombre":"Saldungaray","localidad":"Saldungaray","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.206885,"longitud":-61.774807,"ranking":9},
{"id":4389,"nombre":"San Cayetano","localidad":"San Cayetano","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.3599258,"longitud":-59.6256131,"ranking":14},
{"id":700,"nombre":"Serrano","localidad":"Serrano","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.471737,"longitud":-63.537969,"ranking":6},
{"id":701,"nombre":"Sierra Chica","localidad":"Sierra Chica","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.8431941,"longitud":-60.2313359,"ranking":0},
{"id":704,"nombre":"Villa Cañas","localidad":"Villa Cañas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.00389,"longitud":-61.603017,"ranking":6},
{"id":7890,"nombre":"Villa Cañas Acceso","localidad":"Villa Cañas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.01027408566692,"longitud":-61.61463775351349,"ranking":0},
{"id":718,"nombre":"Aguas Verdes","localidad":"Aguas Verdes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.63743,"longitud":-56.683777,"ranking":0},
{"id":2388,"nombre":"Aguas Verdes Acceso","localidad":"Aguas Verdes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.63571161380147,"longitud":-56.70884374367769,"ranking":0},
{"id":717,"nombre":"Abbot","localidad":"Abbot","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.284452010764085,"longitud":-58.773404448063424,"ranking":0},
{"id":3416,"nombre":"Aparicio","localidad":"Aparicio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.62246935090488,"longitud":-60.882250702278384,"ranking":0},
{"id":9805,"nombre":"Aparicio Acceso","localidad":"Aparicio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.65221957057382,"longitud":-60.866356134826766,"ranking":0},
{"id":2404,"nombre":"Dufaurd","localidad":"Dufaurd","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.94413585854311,"longitud":-62.28827538225941,"ranking":0},
{"id":747,"nombre":"Carmen de Patagones","localidad":"Carmen de Patagones","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-40.801177,"longitud":-62.976584,"ranking":57},
{"id":4174,"nombre":"Carmen de Patagones Acceso","localidad":"Carmen de Patagones","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-40.793399632842004,"longitud":-62.99511023355856,"ranking":0},
{"id":4340,"nombre":"Cascallares","localidad":"Cascallares","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.492757663491865,"longitud":-60.469490845142104,"ranking":0},
{"id":2396,"nombre":"Coliqueo","localidad":"Coliqueo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.652268,"longitud":-60.540643,"ranking":0},
{"id":789,"nombre":"Dussaud","localidad":"Dussaud","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.79376071798381,"longitud":-61.778936430137975,"ranking":0},
{"id":2996,"nombre":"San Jacinto","localidad":"San Jacinto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.644553973687756,"longitud":-59.52409976628788,"ranking":0},
{"id":12316,"nombre":"San Jacinto Acceso","localidad":"San Jacinto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5558397,"longitud":-59.94844810000001,"ranking":0},
{"id":731,"nombre":"Vivorata","localidad":"Vivorata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.6635158,"longitud":-57.6651851,"ranking":0},
{"id":740,"nombre":"Samborombon","localidad":"Samborombon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.18131101018553,"longitud":-58.261490342183464,"ranking":1},
{"id":748,"nombre":"Sevigne","localidad":"Sevigne","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.203763,"longitud":-57.742992,"ranking":0},
{"id":749,"nombre":"Al Ver Veras","localidad":"Al Ver Veras","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.39011034565934,"longitud":-57.69598223062071,"ranking":0},
{"id":751,"nombre":"La Blanqueada","localidad":"La Blanqueada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":750,"nombre":"Parravicini","localidad":"Parravicini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.45996802297319,"longitud":-57.75968590741700,"ranking":0},
{"id":754,"nombre":"Sosiego","localidad":"Sosiego","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2429,"nombre":"O´Higgins Acceso","localidad":"O Higgins","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.640802,"longitud":-60.731446,"ranking":0},
{"id":792,"nombre":"Videla Dorna","localidad":"Videla Dorna","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.53875707928638,"longitud":-58.88583847344012,"ranking":0},
{"id":793,"nombre":"Parish","localidad":"Parish","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.51890385375347,"longitud":-59.6342342586154,"ranking":0},
{"id":794,"nombre":"Shaw Acceso","localidad":"Shaw","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6395854,"longitud":-59.7069054,"ranking":0},
{"id":795,"nombre":"Nieves","localidad":"Nieves","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.8285147637623,"longitud":-59.9843080526782,"ranking":0},
{"id":796,"nombre":"Hinojo","localidad":"Hinojo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.866653,"longitud":-60.167795,"ranking":0},
{"id":1049,"nombre":"Tiburcio","localidad":"Tiburcio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.34635698781593,"longitud":-61.130800276250675,"ranking":0},
{"id":7519,"nombre":"Tiburcio Acceso","localidad":"Tiburcio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":803,"nombre":"Agustina","localidad":"Agustina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.47679772104967,"longitud":-61.04554477350567,"ranking":0},
{"id":1034,"nombre":"Ituzaingo","localidad":"Ituzaingo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.659275,"longitud":-58.6645145,"ranking":63},
{"id":820,"nombre":"Real del Padre Acceso","localidad":"Real del Padre","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.854201,"longitud":-67.791896,"ranking":0},
{"id":2601,"nombre":"Real del Padre","localidad":"Real del Padre","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.840665,"longitud":-67.764506,"ranking":109},
{"id":2400,"nombre":"Villa Athuel (Villa Athuel- Acceso)","localidad":"Villa Athuel","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1073,"nombre":"Martinez","localidad":"Martinez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.487728100,"longitud":-58.503184000,"ranking":25},
{"id":3261,"nombre":"Rotonda Acassuso","localidad":"Martinez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.474299869124465,"longitud":-58.50602776839369,"ranking":0},
{"id":1075,"nombre":"Olivos","localidad":"Olivos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.502528000,"longitud":-58.495320500,"ranking":0},
{"id":7598,"nombre":"Panamericana y Pelliza","localidad":"Olivos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.51900195118989,"longitud":-58.51434079662877,"ranking":0},
{"id":9063,"nombre":"Olivos Tren de la Costa","localidad":"Olivos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.514496,"longitud":-58.488967,"ranking":19},
{"id":10376,"nombre":"Puerto de Olivos","localidad":"Olivos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":893,"nombre":"Monte Grande","localidad":"Monte Grande","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8223099,"longitud":-58.4715079,"ranking":45},
{"id":3235,"nombre":"Beccar","localidad":"Beccar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.460358300,"longitud":-58.528817900,"ranking":0},
{"id":827,"nombre":"Carilo","localidad":"Carilo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.1577244,"longitud":-56.9286431,"ranking":0},
{"id":3273,"nombre":"Lomas del Mirador","localidad":"Lomas del Mirador","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":828,"nombre":"Loma Negra","localidad":"Loma Negra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2425,"nombre":"Loma Negra Acceso","localidad":"Loma Negra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.96803821429776,"longitud":-60.2580494272599,"ranking":0},
{"id":829,"nombre":"Querandies","localidad":"Querandies","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.63142404108543,"longitud":-60.934428770989676,"ranking":0},
{"id":2468,"nombre":"Santa Luisa Acceso","localidad":"Santa Luisa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.12905692094385,"longitud":-60.417604647661285,"ranking":0},
{"id":832,"nombre":"Pourtale","localidad":"Pourtale","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.018129637039515,"longitud":-60.58802726062409,"ranking":0},
{"id":4339,"nombre":"Pourtale Acceso","localidad":"Pourtale","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.12247422796451,"longitud":-60.55035762115498,"ranking":0},
{"id":833,"nombre":"Muñoz","localidad":"Muñoz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.07740662652269,"longitud":-60.7693697603033,"ranking":0},
{"id":834,"nombre":"La Juanita","localidad":"La Juanita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1033,"nombre":"Castelar","localidad":"Castelar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6592867,"longitud":-58.6346461,"ranking":0},
{"id":837,"nombre":"El Despunte","localidad":"El Despunte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.904978613,"longitud":-58.909898884,"ranking":0},
{"id":10589,"nombre":"El Despunte Acceso","localidad":"El Despunte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":838,"nombre":"La Angelita","localidad":"La Angelita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.259512781262025,"longitud":-60.968776546539026,"ranking":0},
{"id":10591,"nombre":"La Angelita Acceso","localidad":"La Angelita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.257909768267986,"longitud":-60.97392164958935,"ranking":0},
{"id":839,"nombre":"Gorch","localidad":"Gorch","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.671786937712426,"longitud":-58.95991002812258,"ranking":0},
{"id":9816,"nombre":"Gorchs Acceso","localidad":"Gorch","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.70024391500173,"longitud":-58.889808654785156,"ranking":8},
{"id":10473,"nombre":"La Curva","localidad":"Monte Chingolo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.721663,"longitud":-58.362187,"ranking":0},
{"id":843,"nombre":"Cruce Fernandez","localidad":"Fernandez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.0149950358155,"longitud":-59.26000913229922,"ranking":0},
{"id":844,"nombre":"Lumbs","localidad":"Lumbs","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2176552,"longitud":-59.31385069999999,"ranking":7},
{"id":845,"nombre":"La Dulce Acceso","localidad":"La Dulce","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2427985260915,"longitud":-59.143920688439465,"ranking":0},
{"id":3540,"nombre":"La Dulce","localidad":"La Dulce","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":847,"nombre":"Rio Quequen","localidad":"Quequen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.52840262283997,"longitud":-58.70219413825181,"ranking":0},
{"id":2434,"nombre":"Quequen Chico","localidad":"Quequen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.56867090262817,"longitud":-58.66626094575928,"ranking":0},
{"id":2446,"nombre":"Puente Quequen Salado","localidad":"Quequen","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.603780813417764,"longitud":-60.610898316508994,"ranking":0},
{"id":855,"nombre":"Berazategui","localidad":"Berazategui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.780151,"longitud":-58.261739,"ranking":8},
{"id":7600,"nombre":"El Pato","localidad":"Berazategui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7720,"nombre":"Hudson","localidad":"Berazategui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7764976,"longitud":-58.1816134,"ranking":0},
{"id":7722,"nombre":"Villa España","localidad":"Berazategui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.77439872494741,"longitud":-58.19508720193817,"ranking":0},
{"id":7723,"nombre":"Estación Ranelagh","localidad":"Berazategui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7898486,"longitud":-58.202919,"ranking":2},
{"id":1552,"nombre":"Arroyito","localidad":"Arroyito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.422568,"longitud":-63.045267,"ranking":331},
{"id":8574,"nombre":"Arroyito Acceso","localidad":"Arroyito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4197505,"longitud":-63.0304037,"ranking":0},
{"id":2544,"nombre":"Lumbreras Acceso","localidad":"Lumbreras","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4475,"nombre":"Lumbreras","localidad":"Lumbreras","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2540,"nombre":"Saucelito","localidad":"Saucelito","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":952,"nombre":"Yuto","localidad":"Yuto","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.614348300,"longitud":-64.526846400,"ranking":2},
{"id":1389,"nombre":"Yuto Acceso","localidad":"Yuto","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.61333610146181,"longitud":-64.52444285955924,"ranking":0},
{"id":1350,"nombre":"Punta Mogotes","localidad":"Punta Mogotes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.0663996,"longitud":-57.5475752,"ranking":79},
{"id":955,"nombre":"Abra Ventana","localidad":"Abra Ventana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":9},
{"id":957,"nombre":"Raulet","localidad":"Raulet","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.895515801535694,"longitud":-61.48303033995927,"ranking":0},
{"id":962,"nombre":"Loma Verde","localidad":"Loma Verde","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.817182000,"longitud":-58.431864000,"ranking":1},
{"id":10499,"nombre":"Loma Verde Acceso","localidad":"Loma Verde","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.220912067974396,"longitud":-58.43552817631754,"ranking":1},
{"id":963,"nombre":"Udaondo","localidad":"Udaondo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.3035565,"longitud":-58.5927059,"ranking":1},
{"id":964,"nombre":"La Noria","localidad":"La Noria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.70722212451073,"longitud":-58.45475435256958,"ranking":4},
{"id":965,"nombre":"La Felicidad","localidad":"La Felicidad","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.191279821,"longitud":-57.548428871,"ranking":0},
{"id":966,"nombre":"Puente Rio Salado","localidad":"Puente Rio Salado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.318054834165636,"longitud":-61.34582205440905,"ranking":0},
{"id":967,"nombre":"Las Rosas","localidad":"Las Rosas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.89082613715393,"longitud":-58.042739881152585,"ranking":0},
{"id":968,"nombre":"Montero Acceso","localidad":"Montero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-17.33521930757636,"longitud":-63.2601462286636,"ranking":0},
{"id":969,"nombre":"Miramonte","localidad":"Miramonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.3108592827255,"longitud":-59.454263054255236,"ranking":0},
{"id":2428,"nombre":"Miramonte Acceso","localidad":"Miramonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2433,"nombre":"Piñeyro","localidad":"Piñeyro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.670651023793184,"longitud":-58.39083493061022,"ranking":0},
{"id":2403,"nombre":"Curamalal","localidad":"Curamalal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.483409087778384,"longitud":-62.104764027403405,"ranking":0},
{"id":3686,"nombre":"Saavedra","localidad":"Saavedra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.76306,"longitud":-62.352906,"ranking":0},
{"id":4166,"nombre":"Saavedra Acceso","localidad":"Saavedra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.8004377,"longitud":-62.3934736,"ranking":5},
{"id":7602,"nombre":"Goyena","localidad":"Goyena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.71775244664219,"longitud":-62.60562831188217,"ranking":0},
{"id":979,"nombre":"Los Pinos","localidad":"Los Pinos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.939932693740396,"longitud":-58.327318194001705,"ranking":0},
{"id":9808,"nombre":"Los Pinos Acceso","localidad":"Los Pinos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7885,"nombre":"Calvo Acceso","localidad":"Calvo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.78491472830839,"longitud":-61.37135227899511,"ranking":0},
{"id":4386,"nombre":"San Roman","localidad":"San Roman","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2420,"nombre":"Las Martinas","localidad":"Las Martinas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2437,"nombre":"Tres Picos","localidad":"Tres Picos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2899319,"longitud":-62.2057744,"ranking":0},
{"id":2419,"nombre":"Villa Ventana (Buenos Aires)","localidad":"Villa Ventana","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.078169,"longitud":-61.927667,"ranking":160},
{"id":2467,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.566276681667425,"longitud":-61.907900395286845,"ranking":0},
{"id":9142,"nombre":"Bahurs","localidad":"Bahurs","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2432,"nombre":"Pereda M","localidad":"Pereda M.","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2417,"nombre":"Larreta M.","localidad":"Larreta M.","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2415,"nombre":"La Rubia","localidad":"La Rubia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2413,"nombre":"Izaguirre","localidad":"Izaguirre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2402,"nombre":"Cuarteles","localidad":"Cuarteles","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1003,"nombre":"Correa","localidad":"Correa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.56631083170963,"longitud":-60.84994078330687,"ranking":0},
{"id":3274,"nombre":"El Palomar","localidad":"El Palomar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6044395,"longitud":-58.5932859,"ranking":0},
{"id":7674,"nombre":"Aveljohn","localidad":"El Palomar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10598,"nombre":"Aeropuerto de El Palomar","localidad":"El Palomar","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.605241,"longitud":-58.599633,"ranking":0},
{"id":1031,"nombre":"El Clavo","localidad":"El Clavo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1038,"nombre":"Elordi","localidad":"Elordi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.04601302103631,"longitud":-63.12149075172023,"ranking":0},
{"id":2406,"nombre":"Elordi Acceso","localidad":"Elordi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.060842850967255,"longitud":-63.13659663869064,"ranking":0},
{"id":1039,"nombre":"Uribelarrea","localidad":"Uribelarrea","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.121934,"longitud":-58.890034,"ranking":2},
{"id":1040,"nombre":"Hale","localidad":"Hale","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.00388479393557,"longitud":-60.857123415499295,"ranking":0},
{"id":7752,"nombre":"Hale Acceso","localidad":"Hale","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.99755398764486,"longitud":-60.8505617233236,"ranking":0},
{"id":1046,"nombre":"Pedernales","localidad":"Pedernales","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.266389,"longitud":-59.631328,"ranking":8},
{"id":1047,"nombre":"Riestra","localidad":"Riestra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.273975,"longitud":-59.774128,"ranking":6},
{"id":1048,"nombre":"Carabelas","localidad":"Carabelas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.037824178881856,"longitud":-60.86722524062095,"ranking":0},
{"id":1060,"nombre":"Tapiales","localidad":"Tapiales","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.701607,"longitud":-58.510751,"ranking":4},
{"id":1061,"nombre":"Del Viso","localidad":"Del Viso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.454004,"longitud":-58.79627,"ranking":0},
{"id":4000,"nombre":"Del Viso Acceso","localidad":"Del Viso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.46063565069564,"longitud":-58.80914255383898,"ranking":0},
{"id":2104,"nombre":"Tio Pujio","localidad":"Tio Pujio","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2867750224836,"longitud":-63.3546581189517,"ranking":0},
{"id":1554,"nombre":"James Craik","localidad":"James Craik","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.16117518400101,"longitud":-63.46581206876008,"ranking":0},
{"id":7667,"nombre":"James Craik Acceso","localidad":"James Craik","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.16678066728201,"longitud":-63.46101193816786,"ranking":0},
{"id":1070,"nombre":"Malargue","localidad":"Malargue","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.477803,"longitud":-69.575158,"ranking":3203},
{"id":8221,"nombre":"Complejo Valle de Las Leñas","localidad":"Malargue","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8741,"nombre":"Caverna de las Brujas","localidad":"Malargue","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":12322,"nombre":"Hoteles Centro Malargue","localidad":"Malargue","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.455793,"longitud":-69.586151,"ranking":0},
{"id":15384,"nombre":"Aeropuerto de Malargue","localidad":"Malargue","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.4834881,"longitud":-69.5846032,"ranking":0},
{"id":1071,"nombre":"Las Leñas","localidad":"Las Leñas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.146839,"longitud":-70.080773,"ranking":84},
{"id":7599,"nombre":"Paso Pehuelche","localidad":"Las Leñas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.981712558678176,"longitud":-70.39424684661853,"ranking":0},
{"id":8084,"nombre":"Las Leñas Acceso","localidad":"Las Leñas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.14664999325054,"longitud":-70.08061706512035,"ranking":0},
{"id":12323,"nombre":"Hoteles Las Leñas","localidad":"Las Leñas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3568,"nombre":"Libertador San Martin","localidad":"Libertador San Martin","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.076682817715785,"longitud":-60.46576790761481,"ranking":0},
{"id":1076,"nombre":"Garin","localidad":"Garin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.417070500,"longitud":-58.724746500,"ranking":0},
{"id":8510,"nombre":"Garin Centro","localidad":"Garin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.4217756400012,"longitud":-58.733834130169676,"ranking":0},
{"id":1087,"nombre":"Colonia Santa Rosa","localidad":"Colonia Santa Rosa","provincia":"Salta","pais":"ARGENTINA","latitud":-23.1305325,"longitud":-64.3287808,"ranking":0},
{"id":4276,"nombre":"Colonia Santa Rosa Acceso","localidad":"Colonia Santa Rosa","provincia":"Salta","pais":"ARGENTINA","latitud":-23.4050114,"longitud":-64.42584339999999,"ranking":0},
{"id":2416,"nombre":"Viticola","localidad":"Viticola","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.521923,"longitud":-62.2839867,"ranking":0},
{"id":4167,"nombre":"Viticola Acceso","localidad":"Viticola","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.52079103297493,"longitud":-62.292915897550515,"ranking":0},
{"id":8752,"nombre":"Monte Castro","localidad":"Monte Castro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1098,"nombre":"Villa Lugano","localidad":"Villa Lugano","provincia":"Capital Federal","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3162,"nombre":"Pablo Nogues","localidad":"Nogues Pablo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.500046,"longitud":-58.712072,"ranking":0},
{"id":1117,"nombre":"Casares","localidad":"Casares","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.9479187,"longitud":-62.8009312,"ranking":0},
{"id":1545,"nombre":"Casares Acceso","localidad":"Casares","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.949240284686084,"longitud":-62.800051973184615,"ranking":0},
{"id":1120,"nombre":"Tobas","localidad":"Tobas","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.123038387649167,"longitud":-62.7000559116899,"ranking":0},
{"id":1121,"nombre":"Otumpa","localidad":"Otumpa","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.32797232046848,"longitud":-62.224972520255555,"ranking":0},
{"id":1122,"nombre":"Aerolito","localidad":"Aerolito","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.233079258490346,"longitud":-62.37793088628328,"ranking":0},
{"id":1123,"nombre":"Alhuampa","localidad":"Alhuampa","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.12823777466163,"longitud":-62.54705849892332,"ranking":0},
{"id":1124,"nombre":"Tintina","localidad":"Tintina","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.030919002681223,"longitud":-62.703705076378604,"ranking":0},
{"id":1126,"nombre":"Donadeu","localidad":"Donadeu","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.723923732692942,"longitud":-62.71849397319928,"ranking":0},
{"id":1127,"nombre":"Campo Gallo","localidad":"Campo Gallo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.583854,"longitud":-62.846662,"ranking":0},
{"id":1128,"nombre":"La Union","localidad":"La Union","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.413706145099244,"longitud":-62.7904959468319,"ranking":0},
{"id":3153,"nombre":"Alejandro Roca","localidad":"Alejandro Roca","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.3489990,"longitud":-63.7179604,"ranking":29},
{"id":4449,"nombre":"Alejandro Roca Acceso","localidad":"Alejandro Roca","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.314741,"longitud":-63.709714,"ranking":11},
{"id":1158,"nombre":"Coronel Baigorria","localidad":"Coronel Baigorria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.848977300,"longitud":-64.369176800,"ranking":2},
{"id":1159,"nombre":"Gigena","localidad":"Gigena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.7551088,"longitud":-64.3390428,"ranking":0},
{"id":1160,"nombre":"Elena","localidad":"Elena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.568279,"longitud":-64.389569,"ranking":102},
{"id":8343,"nombre":"Alejandro Acceso","localidad":"Alejandro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.2827089669828,"longitud":-63.70014669544848,"ranking":0},
{"id":1347,"nombre":"Puente del Inca","localidad":"Puente del Inca","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.82661,"longitud":-69.911116,"ranking":0},
{"id":1186,"nombre":"Villa Rumipal","localidad":"Villa Rumipal","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.193153,"longitud":-64.481599,"ranking":90},
{"id":1187,"nombre":"Villa del Dique","localidad":"Villa del Dique","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.175754,"longitud":-64.45249,"ranking":135},
{"id":4420,"nombre":"Tortugas","localidad":"Tortugas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7836962,"longitud":-61.5490842,"ranking":0},
{"id":1201,"nombre":"Clucellas Acceso","localidad":"Clucellas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.45730534143786,"longitud":-61.710435511713584,"ranking":0},
{"id":8036,"nombre":"Clucellas","localidad":"Clucellas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.4873874,"longitud":-61.7177890,"ranking":0},
{"id":1205,"nombre":"Sastre","localidad":"Sastre","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7482699,"longitud":-60.7352686,"ranking":0},
{"id":1207,"nombre":"El Trebol","localidad":"El Trebol","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.1949727,"longitud":-61.7046891,"ranking":0},
{"id":1210,"nombre":"Las Parejas","localidad":"Las Parejas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.6875600,"longitud":-61.5257896,"ranking":0},
{"id":1218,"nombre":"Maria Juana","localidad":"Maria Juana","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.2524974,"longitud":-61.4816794,"ranking":0},
{"id":2362,"nombre":"Curitiba","localidad":"Curitiba","provincia":"Parana","pais":"BRASIL","latitud":-25.4366867,"longitud":-49.25741619999999,"ranking":138},
{"id":15275,"nombre":"Aeroporto do Curitiba","localidad":"Curitiba","provincia":"Parana","pais":"BRASIL","latitud":-25.3987359,"longitud":-49.2293737,"ranking":0},
{"id":1444,"nombre":"Balneario Camboriu","localidad":"Balneario Camboriú","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9944817,"longitud":-48.6474776,"ranking":99},
{"id":1257,"nombre":"Yofre","localidad":"Yofre","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.106640937544217,"longitud":-58.34036015135581,"ranking":0},
{"id":1395,"nombre":"Yofre Acceso","localidad":"Yofre","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.0990069,"longitud":-58.34135869999999,"ranking":2},
{"id":1246,"nombre":"Paso de la Patria","localidad":"Paso de la Patria","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.314797,"longitud":-58.563646,"ranking":0},
{"id":2456,"nombre":"Paso de la Patria Acceso","localidad":"Paso de la Patria","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.390454522710932,"longitud":-58.55347012931103,"ranking":7},
{"id":1247,"nombre":"San Cosme","localidad":"San Cosme","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.373601,"longitud":-58.511266,"ranking":0},
{"id":1404,"nombre":"San Cosme Cruce","localidad":"San Cosme","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.377214628984884,"longitud":-58.50901271011106,"ranking":0},
{"id":2457,"nombre":"San Cosme Acceso","localidad":"San Cosme","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.390450509265694,"longitud":-58.553681846210914,"ranking":0},
{"id":1248,"nombre":"Ensenada Grande","localidad":"Ensenada Grande","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1405,"nombre":"Ensenada Grande Acceso","localidad":"Ensenada Grande","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.367177078243742,"longitud":-58.36471335763281,"ranking":0},
{"id":1249,"nombre":"Ramada Paso","localidad":"Ramada","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.366178,"longitud":-58.299791,"ranking":9},
{"id":1406,"nombre":"Ramada Paso Acceso","localidad":"Ramada","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.36617143177071,"longitud":-58.29977093768,"ranking":0},
{"id":1251,"nombre":"La Tablada","localidad":"La Tablada","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3164,"nombre":"Rafael Castillo","localidad":"Rafael Castillo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6965549,"longitud":-58.627012,"ranking":0},
{"id":1253,"nombre":"Pontevedra","localidad":"Pontevedra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7422830,"longitud":-58.6936875,"ranking":0},
{"id":1255,"nombre":"Francisco Alvarez","localidad":"Alvarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.632647132361086,"longitud":-58.85539974807178,"ranking":0},
{"id":1262,"nombre":"Camana Acceso","localidad":"Camana","provincia":"Peru","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":7777,"nombre":"Camana","localidad":"Camana","provincia":"Peru","pais":"Perú","latitud":-16.621451864118814,"longitud":-72.70975117241846,"ranking":0},
{"id":1269,"nombre":"Clodomira","localidad":"Clodomira","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.572634687236114,"longitud":-64.13143515355861,"ranking":0},
{"id":1277,"nombre":"Recreo Acceso","localidad":"Recreo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.6520300,"longitud":-60.7321134,"ranking":0},
{"id":7625,"nombre":"Candioti","localidad":"Candiotti","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1279,"nombre":"Nelson","localidad":"Nelson","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.265257,"longitud":-60.758539,"ranking":0},
{"id":14234,"nombre":"Nelson Acceso","localidad":"Nelson","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.2828017,"longitud":-60.7590582,"ranking":0},
{"id":8506,"nombre":"Llambi Cambell","localidad":"Llambi Cambell","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.1871197009003,"longitud":-60.74309205513563,"ranking":0},
{"id":1281,"nombre":"Videla","localidad":"Videla","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.943518,"longitud":-60.658291,"ranking":0},
{"id":1284,"nombre":"La Criolla","localidad":"La Criolla","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.226835771831137,"longitud":-60.35989046694041,"ranking":0},
{"id":4161,"nombre":"Gomez Cello","localidad":"Gomez Cello","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1286,"nombre":"Vera y Pintado","localidad":"Vera y Pintado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.7861480,"longitud":-60.5995620,"ranking":0},
{"id":2140,"nombre":"Berna","localidad":"Berna","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1293,"nombre":"Las Garzas Acceso","localidad":"Las Garzas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2139,"nombre":"Arroyo Ceibal","localidad":"Arroyo Ceibal","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.7255181,"longitud":-59.47640229999999,"ranking":5},
{"id":1729,"nombre":"Fray Luis Beltran","localidad":"Fray Luis Beltran","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.78966687033592,"longitud":-60.72423439189256,"ranking":0},
{"id":1306,"nombre":"Leon","localidad":"Leon","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.038274567412,"longitud":-65.43086375029186,"ranking":0},
{"id":1307,"nombre":"Volcan","localidad":"Volcan","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.916027860315843,"longitud":-65.4666473610915,"ranking":0},
{"id":12363,"nombre":"Volcan Acceso","localidad":"Volcan","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.925664,"longitud":-65.46383829999999,"ranking":0},
{"id":1308,"nombre":"Tumbaya","localidad":"Tumbaya","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.85946539017041,"longitud":-65.46586820913512,"ranking":1},
{"id":7759,"nombre":"Parador Volcan","localidad":"Tumbaya","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.916814425829273,"longitud":-65.467152346582,"ranking":0},
{"id":1310,"nombre":"Cinaguilla","localidad":"Cinaguilla","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1311,"nombre":"Maimara","localidad":"Maimara","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.62766964324777,"longitud":-65.40944076177543,"ranking":4},
{"id":1312,"nombre":"Huacalera","localidad":"Huacalera","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.438676152461476,"longitud":-65.35044285324663,"ranking":7},
{"id":1313,"nombre":"Uquia","localidad":"Uquia","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.303705,"longitud":-65.354945,"ranking":0},
{"id":1314,"nombre":"Puente del Marquez","localidad":"Puente del Marquez","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1315,"nombre":"Pumahuasi","localidad":"Pumahuasi","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.289672214128938,"longitud":-65.68046685258307,"ranking":0},
{"id":1343,"nombre":"Jarilla Acceso","localidad":"Jarilla","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2981,"nombre":"Jarilla","localidad":"Jarilla","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.425322694555156,"longitud":-67.02803910436542,"ranking":0},
{"id":1345,"nombre":"San Agustin","localidad":"San Agustin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.97480789999999,"longitud":-64.37508530000001,"ranking":0},
{"id":2685,"nombre":"San Agustin Acceso","localidad":"San Agustin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.96934342788886,"longitud":-64.3547013616822,"ranking":0},
{"id":899,"nombre":"Rotonda Llavallol","localidad":"Llavallol","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.806634,"longitud":-58.4420058,"ranking":21},
{"id":8295,"nombre":"Llavallol","localidad":"Llavallol","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8067254,"longitud":-58.4418437,"ranking":6},
{"id":2450,"nombre":"Libertad","localidad":"Libertad","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.6908729,"longitud":-58.689652,"ranking":1},
{"id":3528,"nombre":"Boulogne","localidad":"Boulogne","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3632,"nombre":"Rio Grande","localidad":"Rio Grande","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":-53.78426,"longitud":-67.717313,"ranking":47},
{"id":1351,"nombre":"Almafuerte","localidad":"Almafuerte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.191263,"longitud":-64.255671,"ranking":581},
{"id":2686,"nombre":"Cruce Almafuerte","localidad":"Almafuerte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.18496916272028,"longitud":-64.25522232108052,"ranking":0},
{"id":1361,"nombre":"Luis Guillon","localidad":"Luis Guillon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.806691000,"longitud":-58.441526000,"ranking":54},
{"id":1699,"nombre":"Munro","localidad":"Munro","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5308344,"longitud":-58.5250819,"ranking":0},
{"id":1377,"nombre":"Carmelo","localidad":"Carmelo","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.00371503453594,"longitud":-58.28847638038013,"ranking":0},
{"id":1379,"nombre":"Hipolito Yrigoyen","localidad":"H. Irigoyen","provincia":"Salta","pais":"ARGENTINA","latitud":-23.238158,"longitud":-64.279161,"ranking":0},
{"id":1399,"nombre":"Derqui Acceso","localidad":"Derqui","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.839597238519598,"longitud":-58.76784465039366,"ranking":0},
{"id":4677,"nombre":"Derqui","localidad":"Derqui","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1401,"nombre":"San Luis del Palmar","localidad":"San Luis del Palmar","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.507996376412194,"longitud":-58.55595973057481,"ranking":0},
{"id":3541,"nombre":"Loma Hermosa","localidad":"Loma Hermosa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4150,"nombre":"Ruta 8 y Marquez","localidad":"Ruta 8 y Marquez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1413,"nombre":"Piriapolis","localidad":"Piriapolis","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.860955226466736,"longitud":-55.274532661816686,"ranking":0},
{"id":3221,"nombre":"Combinacion Piriapolis","localidad":"Piriapolis","provincia":"Maldonado","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":14680,"nombre":"Cerro del Toro","localidad":"Piriapolis","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8622505,"longitud":-55.2573188,"ranking":0},
{"id":1414,"nombre":"Punta Del Este","localidad":"Punta Del Este","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.9171326,"longitud":-54.9574757,"ranking":3},
{"id":2839,"nombre":"COMBINACION PUNTA DEL ESTE","localidad":"Punta Del Este","provincia":"Maldonado","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":8},
{"id":14677,"nombre":"Aeropuerto Internacional Laguna del Sauce","localidad":"Punta Del Este","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8608291,"longitud":-55.0981027,"ranking":0},
{"id":1415,"nombre":"Rocha","localidad":"Rocha","provincia":"Rocha","pais":"URUGUAY","latitud":-34.4878659,"longitud":-54.3236126,"ranking":0},
{"id":4173,"nombre":"Cabo Polonio","localidad":"Rocha","provincia":"Rocha","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":1416,"nombre":"La Paloma","localidad":"La Paloma","provincia":"Rocha","pais":"URUGUAY","latitud":-34.65653868240128,"longitud":-54.15866141638132,"ranking":0},
{"id":1417,"nombre":"La Pedrera","localidad":"La Pedrera","provincia":"Rocha","pais":"URUGUAY","latitud":-34.59201617459236,"longitud":-54.132598377647845,"ranking":0},
{"id":1418,"nombre":"Aguas Dulces","localidad":"Aguas Dulces","provincia":"Rocha","pais":"URUGUAY","latitud":-34.27425822686025,"longitud":-53.783363647565594,"ranking":0},
{"id":1419,"nombre":"Valizas","localidad":"Valizas","provincia":"Rocha","pais":"URUGUAY","latitud":-34.3364846242133,"longitud":-53.79056848578863,"ranking":0},
{"id":1420,"nombre":"Santa Teresa","localidad":"Santa Teresa","provincia":"Florida","pais":"URUGUAY","latitud":-33.996168924313956,"longitud":-56.23924344234148,"ranking":0},
{"id":1421,"nombre":"La Coronilla","localidad":"La Coronilla","provincia":"Rocha","pais":"URUGUAY","latitud":-33.89733878746799,"longitud":-53.516640300309334,"ranking":0},
{"id":1422,"nombre":"Chuy","localidad":"Chuy","provincia":"Rocha","pais":"URUGUAY","latitud":-33.6925861,"longitud":-53.4552933,"ranking":0},
{"id":1423,"nombre":"Pelotas","localidad":"Pelotas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.75597315304547,"longitud":-52.35983490590234,"ranking":3},
{"id":1424,"nombre":"Rafting","localidad":"Porto Alegre","provincia":"Porto Alegre","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8770,"nombre":"A.Camping","localidad":"Porto Alegre","provincia":"Porto Alegre","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8771,"nombre":"A.Pousada","localidad":"Porto Alegre","provincia":"Porto Alegre","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":1426,"nombre":"Maldonado","localidad":"Maldonado","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.917131077982404,"longitud":-54.95732703798178,"ranking":0},
{"id":2663,"nombre":"Solanas","localidad":"Maldonado","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.88718576040217,"longitud":-55.04712156229218,"ranking":0},
{"id":4151,"nombre":"Ing. Maschwitz","localidad":"Ing. Maschwitz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3542,"nombre":"Los Polvorines","localidad":"Los Polvorines","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1445,"nombre":"Isla Martin Garcia","localidad":"Isla Martin Garcia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.189854,"longitud":-58.256619,"ranking":0},
{"id":1446,"nombre":"Colonia","localidad":"Colonia del Sacramento","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.47265985547058,"longitud":-57.84304892515472,"ranking":91},
{"id":14676,"nombre":"Aeropuerto Internacional Laguna de los Patos","localidad":"Colonia del Sacramento","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.4492773,"longitud":-57.7663334,"ranking":0},
{"id":1448,"nombre":"Maciel","localidad":"Maciel","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.4566069116657,"longitud":-60.88915183640036,"ranking":0},
{"id":1451,"nombre":"Paysandu","localidad":"Paysandu","provincia":"Paysandu","pais":"URUGUAY","latitud":-32.32332073502841,"longitud":-58.08193092633314,"ranking":4},
{"id":9208,"nombre":"El Abra","localidad":"Paysandu","provincia":"Paysandu","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":1452,"nombre":"Salto","localidad":"Salto (ROU)","provincia":"Salto","pais":"URUGUAY","latitud":-31.4027647,"longitud":-58.0168209,"ranking":0},
{"id":1453,"nombre":"Termas de Arapey","localidad":"Salto (ROU)","provincia":"Salto","pais":"URUGUAY","latitud":-30.945316018394752,"longitud":-57.52638080977435,"ranking":0},
{"id":8090,"nombre":"Dayman","localidad":"Salto (ROU)","provincia":"Salto","pais":"URUGUAY","latitud":-31.454735362120683,"longitud":-57.91065168401593,"ranking":0},
{"id":7898,"nombre":"Arapey","localidad":"Termas Arapey","provincia":"Termas Arapey","pais":"URUGUAY","latitud":-30.9413055,"longitud":-57.5162987,"ranking":0},
{"id":1454,"nombre":"Tacuarembó","localidad":"Tacuarembó","provincia":"Tacuarembó","pais":"URUGUAY","latitud":-31.71305580105466,"longitud":-55.972372942644796,"ranking":4},
{"id":1455,"nombre":"Rivera","localidad":"Rivera","provincia":"Rivera","pais":"URUGUAY","latitud":-30.89935793414769,"longitud":-55.54173420188608,"ranking":4},
{"id":1457,"nombre":"Sao Gabriel","localidad":"São Gabriel","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.343298932984098,"longitud":-54.32571007207291,"ranking":7},
{"id":1458,"nombre":"Itapema","localidad":"Itapema","provincia":"Santa Catarina","pais":"BRASIL","latitud":-22.4479253,"longitud":-46.9810071,"ranking":2},
{"id":1460,"nombre":"Nueva Palmira","localidad":"Nueva Palmira","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":1468,"nombre":"Liebig","localidad":"Liebig","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.911860125039354,"longitud":-55.82297397246383,"ranking":0},
{"id":2195,"nombre":"Todd","localidad":"Bartolome Mitre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.03355243547844,"longitud":-60.15508503671371,"ranking":0},
{"id":2196,"nombre":"Todd Acceso","localidad":"Bartolome Mitre","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.03572128495646,"longitud":-60.15189597417106,"ranking":0},
{"id":2197,"nombre":"El Jaguel","localidad":"El Jaguel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8297920,"longitud":-58.4426321,"ranking":0},
{"id":2199,"nombre":"Parada Robles","localidad":"Exaltacion de la Cruz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.3737070,"longitud":-59.1321210,"ranking":0},
{"id":2201,"nombre":"La Caminera","localidad":"General Pueyrredon","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.94509228261226,"longitud":-57.58267850474338,"ranking":0},
{"id":1433,"nombre":"Pellegrini Acceso","localidad":"Pellegrini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.2763859,"longitud":-63.1509376,"ranking":31},
{"id":2202,"nombre":"De Bary","localidad":"Pellegrini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.347717006789466,"longitud":-63.25286444790696,"ranking":0},
{"id":2203,"nombre":"Pellegrini","localidad":"Pellegrini","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.266148,"longitud":-63.16441,"ranking":17},
{"id":2204,"nombre":"Parador Posta","localidad":"Posta Parador","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2207,"nombre":"Capayan","localidad":"Capayan","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.773499116591346,"longitud":-66.04835753949675,"ranking":0},
{"id":3744,"nombre":"Colonia Nueva Coneta","localidad":"Capayan","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.56749056551685,"longitud":-65.84166964128809,"ranking":0},
{"id":2211,"nombre":"Lavalle","localidad":"Lavalle","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.206441567712293,"longitud":-65.10056868986756,"ranking":0},
{"id":2212,"nombre":"Bañado de Obanta","localidad":"Santa Rosa","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.10582060658972,"longitud":-65.31855598522536,"ranking":0},
{"id":14829,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2214,"nombre":"Colonia Benitez Acceso","localidad":"1 de Mayo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.312993016315527,"longitud":-58.99592261217462,"ranking":0},
{"id":2217,"nombre":"Hermoso Campo","localidad":"2 de Abril","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.609982,"longitud":-61.344355,"ranking":9},
{"id":2221,"nombre":"General Vedia Acceso","localidad":"Bermejo","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.929674226775866,"longitud":-58.65991811365273,"ranking":0},
{"id":2222,"nombre":"Concepción del Bermejo","localidad":"Concepcion del Bermejo","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.608476500,"longitud":-60.944563200,"ranking":1},
{"id":2223,"nombre":"Santa Sylvina A (EPU)","localidad":"Fray Justo Santa M. de Oro","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.827957123945225,"longitud":-61.13803622184665,"ranking":0},
{"id":7736,"nombre":"La Escondida Acceso","localidad":"General Donovan","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.136066230335103,"longitud":-59.465155203881544,"ranking":0},
{"id":7738,"nombre":"La Verde Acceso","localidad":"General Donovan","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.16109104886915,"longitud":-59.39153091613767,"ranking":0},
{"id":2225,"nombre":"Gral. Jose de San Martin","localidad":"Lib. General San Martin","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.537926,"longitud":-59.339857,"ranking":94},
{"id":9207,"nombre":"La Eduvigis","localidad":"Lib. General San Martin","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.837138002664062,"longitud":-59.062783539172415,"ranking":0},
{"id":2226,"nombre":"Los Frentones","localidad":"Los Frentones","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.407899100,"longitud":-61.415355200,"ranking":0},
{"id":2227,"nombre":"Los Tigres","localidad":"Los Tigres","provincia":"Chaco","pais":"ARGENTINA","latitud":-25.2334239,"longitud":-62.8493314,"ranking":0},
{"id":2229,"nombre":"Rio Muerto","localidad":"Rio Muerto","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.309727300,"longitud":-61.655530900,"ranking":0},
{"id":2230,"nombre":"Aerolito","localidad":"San Fernando","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2231,"nombre":"Alhumpa","localidad":"San Fernando","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2233,"nombre":"General Gatica","localidad":"San Fernando","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2234,"nombre":"Libarona","localidad":"San Fernando","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2235,"nombre":"Napenay","localidad":"San Fernando","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.729960050451165,"longitud":-60.61730728108165,"ranking":1},
{"id":9447,"nombre":"Huascha","localidad":"Ischilin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.603332433941855,"longitud":-64.74347773119848,"ranking":0},
{"id":2242,"nombre":"Bengolea Acceso","localidad":"Juarez Celman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.03225964884524,"longitud":-63.679187130546,"ranking":0},
{"id":2244,"nombre":"Los Cisnes Acceso","localidad":"Juarez Celman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.398984364659086,"longitud":-63.475347793696585,"ranking":0},
{"id":7882,"nombre":"El Rastreador","localidad":"Juarez Celman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.66444346747626,"longitud":-63.53983541776061,"ranking":0},
{"id":7897,"nombre":"Huanchilla","localidad":"Juarez Celman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.6676904,"longitud":-63.6362817,"ranking":2},
{"id":8733,"nombre":"Juarez Celman","localidad":"Juarez Celman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.275983382292072,"longitud":-64.16384696960449,"ranking":5},
{"id":2245,"nombre":"Molinari","localidad":"Molinari","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":12},
{"id":2246,"nombre":"Rio Primero","localidad":"Rio Primero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.333325,"longitud":-63.619952,"ranking":19},
{"id":8575,"nombre":"Rio Primero Acceso","localidad":"Rio Primero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.336082639162246,"longitud":-63.61412447330369,"ranking":0},
{"id":10746,"nombre":"Ranjel","localidad":"Rio Primero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1211078,"longitud":-63.77681489999999,"ranking":0},
{"id":2721,"nombre":"San Javier","localidad":"San Javier","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.0263637,"longitud":-65.0298518,"ranking":11},
{"id":14475,"nombre":"Yacanto","localidad":"San Javier","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.0358934,"longitud":-65.0359569,"ranking":2},
{"id":14477,"nombre":"Piedra Blanca","localidad":"San Javier","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.9025232,"longitud":-64.7776605,"ranking":32},
{"id":2252,"nombre":"Santa Maria de Punilla","localidad":"Santa Maria de Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.279374,"longitud":-64.463302,"ranking":46},
{"id":7617,"nombre":"Villa Caeiro","localidad":"Santa Maria de Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.285611059359848,"longitud":-64.46556329727173,"ranking":2},
{"id":10098,"nombre":"Parador Santa Maria de Punilla","localidad":"Santa Maria de Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.277705,"longitud":-64.463155,"ranking":1},
{"id":2253,"nombre":"Totoralejos","localidad":"Totoralejos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.62043533456259,"longitud":-64.8365647762926,"ranking":0},
{"id":9645,"nombre":"Tulumba","localidad":"Tulumba","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.39551,"longitud":-64.557611,"ranking":2},
{"id":2256,"nombre":"Monte Lena","localidad":"Union","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2257,"nombre":"Villa Ani - Mi","localidad":"Villa Ani - Mi","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.019149,"longitud":-64.274054,"ranking":0},
{"id":2258,"nombre":"Aguila Negra","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2259,"nombre":"Almacen Bello","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2261,"nombre":"Brazo Largo Puente","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-34.10578188429817,"longitud":-59.00313691549341,"ranking":0},
{"id":2262,"nombre":"Caminera Brazo Largo","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-34.11780542113823,"longitud":-59.01077960286753,"ranking":0},
{"id":2263,"nombre":"Caminera Vieja","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2267,"nombre":"Paranacito","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.71636978639834,"longitud":-58.65982928673493,"ranking":0},
{"id":2268,"nombre":"Paranacito Acceso","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.58422677494643,"longitud":-58.798082716504105,"ranking":0},
{"id":2269,"nombre":"Pueblo Ibicuy","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.74689825456491,"longitud":-59.15094520242281,"ranking":0},
{"id":2270,"nombre":"Puente Paranacito","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2271,"nombre":"Puente Sagastume","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2272,"nombre":"Roldan","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.66233025800985,"longitud":-58.83553976482895,"ranking":0},
{"id":2273,"nombre":"Vidal","localidad":"Islas Del Ibicuy","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.88369946166721,"longitud":-58.90074431092259,"ranking":0},
{"id":2274,"nombre":"Herradura Acceso","localidad":"Laishi","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.47962295150563,"longitud":-58.307371471775255,"ranking":0},
{"id":10876,"nombre":"Mision Laishi","localidad":"Laishi","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.2395458,"longitud":-58.62957129999999,"ranking":0},
{"id":2276,"nombre":"Comandante Fontana","localidad":"Patino","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.333172,"longitud":-59.682516,"ranking":5},
{"id":2277,"nombre":"Estanislao del Campo","localidad":"Patino","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.048604,"longitud":-60.089942,"ranking":0},
{"id":2278,"nombre":"Ibarreta","localidad":"Patino","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.213619,"longitud":-59.857243,"ranking":5},
{"id":2279,"nombre":"Ibarreta Acceso","localidad":"Patino","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.205459414131592,"longitud":-59.85072104873036,"ranking":0},
{"id":2281,"nombre":"Pozo del Tigre","localidad":"Patino","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2283,"nombre":"Palo Santo","localidad":"Pirane","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.5563884,"longitud":-59.33262930000001,"ranking":2},
{"id":2284,"nombre":"Pirane","localidad":"Pirane","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.728995,"longitud":-59.104225,"ranking":151},
{"id":2285,"nombre":"Villa Dos Trece","localidad":"Pirane","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.184615,"longitud":-59.36239,"ranking":7},
{"id":8011,"nombre":"Pirane Acceso ","localidad":"Pirane","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.684363129023726,"longitud":-59.06792068985522,"ranking":0},
{"id":2286,"nombre":"Guemes","localidad":"Guemes","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2287,"nombre":"Mauricio Mayer","localidad":"Conhelo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.176228817198904,"longitud":-64.03215398437749,"ranking":0},
{"id":2288,"nombre":"Toay","localidad":"Toay","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.67349083014301,"longitud":-64.3789084353797,"ranking":0},
{"id":2290,"nombre":"Trenel","localidad":"Trenel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.698595,"longitud":-64.134156,"ranking":13},
{"id":8156,"nombre":"Trenel Acceso","localidad":"Trenel","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.67885621109561,"longitud":-64.12856195682164,"ranking":0},
{"id":2292,"nombre":"Bernardo de Irigoyenn","localidad":"Bernardo de Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.2737833,"longitud":-53.6664583,"ranking":1},
{"id":4078,"nombre":" Bernardo De Irigoyen","localidad":"Bernardo de Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.275206,"longitud":-53.666837,"ranking":40},
{"id":4094,"nombre":"Aduana Bernardo de Irigoyen","localidad":"Bernardo de Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5660,"nombre":"Campiñas","localidad":"Bernardo de Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8539,"nombre":"Dos Hermanas","localidad":"Bernardo de Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.295245095394097,"longitud":-53.75358560483072,"ranking":0},
{"id":2295,"nombre":"Arenal","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2296,"nombre":"Chapelco","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2297,"nombre":"Pio Proto","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.239567745761995,"longitud":-70.91532685693305,"ranking":0},
{"id":2381,"nombre":"Alumine","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.239539,"longitud":-70.915367,"ranking":28},
{"id":9749,"nombre":"Quillen","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.37371995436584,"longitud":-71.18886408249011,"ranking":0},
{"id":9752,"nombre":"Litran","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9754,"nombre":"Moquehue","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.94276482525111,"longitud":-71.32867421641247,"ranking":0},
{"id":9756,"nombre":"Norquinco","localidad":"Alumine","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2298,"nombre":"Loncopue","localidad":"Loncopue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.0704227,"longitud":-70.6108269,"ranking":0},
{"id":2299,"nombre":"Belisle","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.1833559,"longitud":-65.9556183,"ranking":14},
{"id":2300,"nombre":"Boca Toma","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2303,"nombre":"Chichinales","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.11607657242829,"longitud":-66.92980725351931,"ranking":0},
{"id":2304,"nombre":"Colonia Frias","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.252542450575206,"longitud":-64.19484618452059,"ranking":0},
{"id":2305,"nombre":"Colonia Josefa","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2306,"nombre":"Cubanea","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.593099500,"longitud":-63.568611100,"ranking":0},
{"id":9070,"nombre":"Rotonda 25 de Mayo","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2320,"nombre":"Colonia San Juan","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2321,"nombre":"Sauce Blanco","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2322,"nombre":"Stefenelli","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2323,"nombre":"Z. Oyouela","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3727,"nombre":"Colonia 25 de Mayo","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2314,"nombre":"J. J. Gomez","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2315,"nombre":"KM 1059","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2316,"nombre":"KM 1121","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2317,"nombre":"Mainque","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.070329200,"longitud":-67.306280100,"ranking":0},
{"id":2318,"nombre":"Pomona","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.485553100,"longitud":-65.606902200,"ranking":4},
{"id":2319,"nombre":"San Javier","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2307,"nombre":"Darwin","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.203092,"longitud":-65.741128,"ranking":13},
{"id":2308,"nombre":"El Dique","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-37.72136662820414,"longitud":-67.7639571070023,"ranking":0},
{"id":2309,"nombre":"El Solito","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.049891100,"longitud":-64.675397900,"ranking":0},
{"id":2310,"nombre":"Fernandez Oro","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.959683,"longitud":-67.909717,"ranking":0},
{"id":2311,"nombre":"General Godoy","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.076433,"longitud":-67.160637,"ranking":0},
{"id":2312,"nombre":"Idevi","localidad":"25 de Mayo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2324,"nombre":"Villa Aberastain","localidad":"Villa Aberastain","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.65755462250478,"longitud":-68.57974816305195,"ranking":0},
{"id":2326,"nombre":"Papagayos","localidad":"Chacabuco","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8281,"nombre":"Los Cajones","localidad":"Junin","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.02751377838624,"longitud":-65.37016536511578,"ranking":0},
{"id":7597,"nombre":"Los Molles ","localidad":"Junin","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.4391128,"longitud":-65.01004139999999,"ranking":1},
{"id":2328,"nombre":"San Pereyra","localidad":"Colonias","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.57057435208598,"longitud":-61.3768253906047,"ranking":0},
{"id":8005,"nombre":"Saa Pereyra","localidad":"Colonias","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2330,"nombre":"Chapuy Acceso","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7551281,"longitud":-61.9739114,"ranking":0},
{"id":9817,"nombre":"Lazzarino Acceso","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7695,"nombre":"Chovet Acceso","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.589672547494295,"longitud":-61.5934471066427,"ranking":0},
{"id":7705,"nombre":"Paraje Raviola","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.63011192891065,"longitud":-61.82077606385894,"ranking":0},
{"id":7708,"nombre":"Murphy","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.644987525576774,"longitud":-61.85420513715328,"ranking":0},
{"id":7712,"nombre":"Runciman","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7714,"nombre":"Christophersen","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.18766981396873,"longitud":-62.02095981473507,"ranking":0},
{"id":7683,"nombre":"San Gregorio","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.32621602866483,"longitud":-62.041558596085395,"ranking":0},
{"id":7684,"nombre":"Maria Teresa","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.3705876,"longitud":-62.1387826,"ranking":0},
{"id":7685,"nombre":"Chovet","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.59992573284062,"longitud":-61.60593584091564,"ranking":0},
{"id":7686,"nombre":"San Eduardo","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7687,"nombre":"Sancti Spiritu","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7486047,"longitud":-61.9548850,"ranking":0},
{"id":7691,"nombre":"Amenabar","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.12686113501085,"longitud":-62.42708610914121,"ranking":0},
{"id":7607,"nombre":"Cañada de Ucle","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.41332054390145,"longitud":-61.601430277705354,"ranking":0},
{"id":7676,"nombre":"Parador Lazzarino","localidad":"General Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.15127120536501,"longitud":-62.45164084326144,"ranking":0},
{"id":7626,"nombre":"Iriondo","localidad":"Iriondo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7766,"nombre":"Parador Totoras","localidad":"Iriondo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.608619041740376,"longitud":-61.161239981939346,"ranking":0},
{"id":2332,"nombre":"Landeta","localidad":"Landeta","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.01046644197764,"longitud":-62.06004390200644,"ranking":0},
{"id":3268,"nombre":"Villa Diego","localidad":"Villa Diego","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2333,"nombre":"Wheelwright","localidad":"Wheelwright","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.9806618,"longitud":-60.6444290,"ranking":0},
{"id":2334,"nombre":"Wheelwright Acceso","localidad":"Wheelwright","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.79736300989939,"longitud":-61.213466598478924,"ranking":0},
{"id":2335,"nombre":"Atamisqui","localidad":"Atamisqui","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.4938678,"longitud":-63.8159254,"ranking":0},
{"id":2336,"nombre":"Brea Pozo","localidad":"Brea Pozo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2337,"nombre":"Estacion Taboada","localidad":"Brea Pozo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.244539834589922,"longitud":-63.95227579842836,"ranking":0},
{"id":2338,"nombre":"Los Telares","localidad":"Los Telares","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.986102344064236,"longitud":-63.4368717841332,"ranking":0},
{"id":2339,"nombre":"Nueva Francia","localidad":"Nueva Francia","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.173229801068583,"longitud":-64.1997872006146,"ranking":0},
{"id":2341,"nombre":"Salavina","localidad":"Salavina","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2342,"nombre":"Sumampa","localidad":"Sumampa","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.3838433,"longitud":-63.4745314,"ranking":118},
{"id":2343,"nombre":"Suncho Corral","localidad":"Suncho Corral","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.937308500,"longitud":-63.430713400,"ranking":4},
{"id":9144,"nombre":"Suncho Corral Acceso","localidad":"Suncho Corral","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.940087901215566,"longitud":-63.439356784040896,"ranking":0},
{"id":2344,"nombre":"Agua Azul","localidad":"Agua Azul","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.38024480268003,"longitud":-64.68443966141962,"ranking":0},
{"id":2345,"nombre":"Rio Colorado Acceso","localidad":"Leales","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.139509777529653,"longitud":-65.3504190438342,"ranking":0},
{"id":10112,"nombre":"Los Puestos","localidad":"Leales","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.280937930628056,"longitud":-65.01883451425007,"ranking":0},
{"id":10113,"nombre":"Santa Rosa de Leales","localidad":"Leales","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.135864591623587,"longitud":-65.26119844863278,"ranking":0},
{"id":10114,"nombre":"Rio Colorado","localidad":"Leales","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.149390357162513,"longitud":-65.35381185592688,"ranking":0},
{"id":2346,"nombre":"Mancopa","localidad":"Mancopa","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.04076108589363,"longitud":-65.15497612085005,"ranking":0},
{"id":2347,"nombre":"Trancas Acceso","localidad":"Trancas","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.234391964126672,"longitud":-65.27454254867861,"ranking":0},
{"id":2572,"nombre":"Trancas","localidad":"Trancas","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.230932,"longitud":-65.284247,"ranking":0},
{"id":7764,"nombre":"Tapia ","localidad":"Trancas","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.5965762585502,"longitud":-65.2788030250297,"ranking":0},
{"id":4121,"nombre":"General Alvear Acceso","localidad":"Alvear","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.428206502311735,"longitud":-55.16808268088702,"ranking":0},
{"id":2167,"nombre":"Virasoro","localidad":"Virasoro","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.059924,"longitud":-56.014711,"ranking":1327},
{"id":4105,"nombre":"Aguapey Acceso","localidad":"Virasoro","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.025824721638077,"longitud":-56.01515607708113,"ranking":0},
{"id":2168,"nombre":"Liebig","localidad":"Liebig","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.911849,"longitud":-55.822963,"ranking":0},
{"id":2173,"nombre":"Villazon","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":-22.0895671,"longitud":-65.5949955,"ranking":0},
{"id":2580,"nombre":"Cotagaita","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":3304,"nombre":"Potosi","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":-19.557541075148926,"longitud":-65.76132277487241,"ranking":12},
{"id":3985,"nombre":"Tupiza","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":-21.44740060685974,"longitud":-65.71678182812845,"ranking":0},
{"id":3986,"nombre":"Vitiche","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":3987,"nombre":"Tumusla","localidad":"Potosi","provincia":"Potosi","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":2348,"nombre":"Pucon","localidad":"Pucón","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-39.276015,"longitud":-71.966757,"ranking":0},
{"id":2349,"nombre":"Temuco","localidad":"Temuco","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.716373,"longitud":-72.568951,"ranking":31},
{"id":2351,"nombre":"Valdivia","localidad":"Valdivia","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":-39.81528016071057,"longitud":-73.23540380983648,"ranking":10},
{"id":7469,"nombre":"Paillaco Acceso","localidad":"Valdivia","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":-40.073048039201666,"longitud":-72.87403346814845,"ranking":0},
{"id":8155,"nombre":"San Jose de la Mariquiña","localidad":"Valdivia","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":-39.53937070870646,"longitud":-72.96118894105858,"ranking":0},
{"id":10103,"nombre":"Panguipulli","localidad":"Valdivia","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":-39.64420128143791,"longitud":-72.33629444484308,"ranking":0},
{"id":2354,"nombre":"Capiata","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.352478,"longitud":-57.44431,"ranking":12},
{"id":12396,"nombre":"Capiata Electroban","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.352416,"longitud":-57.444298,"ranking":0},
{"id":12403,"nombre":"Capiata Ruta 1 - KM 23","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.420556,"longitud":-57.454641,"ranking":0},
{"id":12404,"nombre":"Capiata Ruta 1 - KM 16.5","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.363013,"longitud":-57.4836,"ranking":0},
{"id":12405,"nombre":"Capiata Ruta 1 - KM 17.5","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.370812,"longitud":-57.479224,"ranking":0},
{"id":14249,"nombre":"Desvio Aregua","localidad":"Capiata","provincia":"Capiata","pais":"Paraguay","latitud":-25.3623526,"longitud":-57.4281382,"ranking":0},
{"id":2356,"nombre":"Caacupe","localidad":"Caacupe","provincia":"Cordillera","pais":"Paraguay","latitud":-25.39301,"longitud":-57.146247,"ranking":30},
{"id":2357,"nombre":"Eusebio Ayala","localidad":"Eusebio Ayala","provincia":"Cordillera","pais":"Paraguay","latitud":-25.391504,"longitud":-56.9569,"ranking":0},
{"id":12600,"nombre":"Kaundy","localidad":"Eusebio Ayala","provincia":"Cordillera","pais":"Paraguay","latitud":-25.741713040573263,"longitud":-56.48425884864004,"ranking":0},
{"id":2358,"nombre":"Itacurubi","localidad":"Itacurubi de la Cordillera","provincia":"Cordillera","pais":"Paraguay","latitud":-25.461329,"longitud":-56.853121,"ranking":10},
{"id":2359,"nombre":"San Jose","localidad":"San Jose (Paraguay)","provincia":"San Jose","pais":"Paraguay","latitud":-25.533057,"longitud":-56.730308,"ranking":0},
{"id":2360,"nombre":"Coronel Oviedo","localidad":"Coronel Oviedo","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.46696,"longitud":-56.448811,"ranking":175},
{"id":12406,"nombre":"Rotonda Coronel Oviedo","localidad":"Coronel Oviedo","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.466366,"longitud":-56.450058,"ranking":0},
{"id":12556,"nombre":"Aguapety","localidad":"Coronel Oviedo","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.5776815,"longitud":-56.45777829999999,"ranking":0},
{"id":2361,"nombre":"Villarrica","localidad":"Villarrica","provincia":"Guairá","pais":"Paraguay","latitud":-25.77927,"longitud":-56.445211,"ranking":111},
{"id":12579,"nombre":"Colonia 14 de Mayo","localidad":"Villarrica","provincia":"Guairá","pais":"Paraguay","latitud":-25.816630781418894,"longitud":-56.383333000216076,"ranking":0},
{"id":10836,"nombre":"Cataratas Brasileras y Complejo Dreamland","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.520681101490798,"longitud":-54.56321094932863,"ranking":0},
{"id":10823,"nombre":"Hoteles Centro Foz do Iguaçu","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10825,"nombre":"Aeropuerto Internacional de Foz do Iguaçu","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.597743,"longitud":-54.48851519999999,"ranking":2},
{"id":10826,"nombre":"Hoteles Av. das Nações","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10833,"nombre":"Cataratas Brasileras","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.53259926388847,"longitud":-54.58714004463806,"ranking":54},
{"id":10834,"nombre":"Cataratas Brasileras y Represa Itaipu","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.447559982429564,"longitud":-54.585267538986265,"ranking":0},
{"id":10835,"nombre":"Cataratas Brasileras y Parque de Aves","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":2363,"nombre":"Foz do Iguacu","localidad":"Foz do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.520758,"longitud":-54.5632773,"ranking":938},
{"id":2364,"nombre":"Rio de Janeiro - Novo Rio","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.898699,"longitud":-43.2094408,"ranking":885},
{"id":10844,"nombre":"Ilha Grande","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.1520839,"longitud":-44.2289441,"ranking":0},
{"id":15381,"nombre":"Hoteles Zona Centro - Rio de Janeiro","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15497,"nombre":"Recreio dos Banderantes","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.0085137,"longitud":-43.4404493,"ranking":0},
{"id":15552,"nombre":"Parque das Rosas - Barra de Tijuca","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.0000703,"longitud":-43.3524578,"ranking":0},
{"id":10061,"nombre":"Alberto Torres","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10062,"nombre":"Correas","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.45369646221901,"longitud":-43.14412182296186,"ranking":0},
{"id":10445,"nombre":"Barra da Tijuca","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.006066058459854,"longitud":-43.31699046709637,"ranking":10},
{"id":10446,"nombre":"Castelo","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.90898727751832,"longitud":-43.17116444267303,"ranking":0},
{"id":10842,"nombre":"Aeropuerto Santos Dumont","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.9111438,"longitud":-43.1648755,"ranking":1},
{"id":10843,"nombre":"Hoteles Zona Sur - Rio de Janeiro","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.899200724217483,"longitud":-43.20811159847833,"ranking":0},
{"id":8930,"nombre":"Aeroporto do Rio de Janeiro - Galeao","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.805265,"longitud":-43.25662930000001,"ranking":98},
{"id":9791,"nombre":"Transfer do Galeão para Zona Sul do Rio de Janeiro","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9880,"nombre":"Transfer do Santos Dumont para Zona Sul do Rio","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9885,"nombre":"Transfer e Passeios","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10060,"nombre":"Anta","localidad":"Rio de Janeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.039456203620315,"longitud":-42.99014247965383,"ranking":0},
{"id":2365,"nombre":"Uruguayana","localidad":"Uruguaiana","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.75907,"longitud":-57.08067,"ranking":24},
{"id":2366,"nombre":"Alegrete","localidad":"Alegrete","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.77886801867481,"longitud":-55.789217948913574,"ranking":0},
{"id":2645,"nombre":"Taller (Sao Paulo)","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.552503999703994,"longitud":-46.731819451214655,"ranking":0},
{"id":2646,"nombre":"Taboão","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.606859004203706,"longitud":-46.75196021322553,"ranking":0},
{"id":2648,"nombre":"Shopping Sao Paulo","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.563624210629346,"longitud":-46.65288573347551,"ranking":0},
{"id":7326,"nombre":"Rodoviaria Guarulhos - Sao Paulo","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.448942751618194,"longitud":-46.49775660025451,"ranking":0},
{"id":8935,"nombre":"Aeroporto Internacional de Guarulhos","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.42992,"longitud":-46.49028,"ranking":44},
{"id":8962,"nombre":"Vila Mirim","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12606,"nombre":"Agencia Princesa Isabel","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6224126,"longitud":-46.682767,"ranking":0},
{"id":15261,"nombre":"Maresias","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.788920413190056,"longitud":-45.56682373809184,"ranking":0},
{"id":5889,"nombre":"Rodoviaria Jabaquara - Sao Paulo","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6463302,"longitud":-46.6423774,"ranking":1},
{"id":5890,"nombre":"Rodoviaria Barra Funda - Sao Paulo","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.5268962,"longitud":-46.6661894,"ranking":36},
{"id":6085,"nombre":"Aeroporto de Congonhas","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6267045,"longitud":-46.6596205,"ranking":2},
{"id":7325,"nombre":"Rodoviaria Tiete Sao Paulo","localidad":"San Pablo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.5168913,"longitud":-46.624804,"ranking":771},
{"id":2368,"nombre":"Torres","localidad":"Torres","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3372014038215,"longitud":-49.730524063634235,"ranking":0},
{"id":14480,"nombre":"Paraiso","localidad":"Torres","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4390444,"longitud":-49.80573450000001,"ranking":0},
{"id":14486,"nombre":"Praia de Itapeva","localidad":"Torres","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.357495,"longitud":-49.736253,"ranking":0},
{"id":14487,"nombre":"Campo Bonito","localidad":"Torres","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":2372,"nombre":"Monte Coman","localidad":"Monte Coman","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.591486,"longitud":-67.878472,"ranking":139},
{"id":2550,"nombre":"Florida","localidad":"Florida","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3924,"nombre":"Panamericana y San Martin","localidad":"Florida","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.533145,"longitud":-58.5030394,"ranking":0},
{"id":2382,"nombre":"Chos Malal","localidad":"Chos Malal","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.381567,"longitud":-70.265089,"ranking":0},
{"id":4780,"nombre":"Pampa Tril","localidad":"Chos Malal","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2384,"nombre":"Villa Traful","localidad":"Villa Traful","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.6598828,"longitud":-71.3880397,"ranking":21},
{"id":3760,"nombre":"Pichi Traful","localidad":"Villa Traful","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.4767361,"longitud":-71.6209483,"ranking":0},
{"id":3761,"nombre":"Confluencia Traful","localidad":"Villa Traful","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.724559400,"longitud":-71.092486400,"ranking":0},
{"id":7891,"nombre":"Teodelina Acceso","localidad":"Teodelina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.1879262,"longitud":-61.5265685,"ranking":0},
{"id":2441,"nombre":"Teodelina (Teodelina- Santa Fe)","localidad":"Teodelina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-34.188553,"longitud":-61.526674,"ranking":38},
{"id":2443,"nombre":"Fray Bentos","localidad":"Fray Bentos","provincia":"Río Negro (Uruguay)","pais":"URUGUAY","latitud":-33.1241243,"longitud":-58.3021725,"ranking":0},
{"id":4333,"nombre":"Fray Bentos Acceso","localidad":"Fray Bentos","provincia":"Río Negro (Uruguay)","pais":"URUGUAY","latitud":-33.11040824575823,"longitud":-58.24655845076262,"ranking":0},
{"id":2444,"nombre":"Mercedes (UR)","localidad":"Mercedes","provincia":"Soriano","pais":"URUGUAY","latitud":-33.25776976456965,"longitud":-58.02814554392902,"ranking":0},
{"id":14699,"nombre":"Mercedes KM 270","localidad":"Mercedes","provincia":"Soriano","pais":"URUGUAY","latitud":-33.2620519,"longitud":-58.01703169999999,"ranking":0},
{"id":15266,"nombre":"Aeroporto do Porto Alegre","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.9866377,"longitud":-51.1625425,"ranking":0},
{"id":8693,"nombre":"Rafting Pacote Adventure","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8695,"nombre":"Rafting Pacote Passeio","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4737,"nombre":"Barra do Ribeiro","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.29913885890228,"longitud":-30.29913885890228,"ranking":0},
{"id":2451,"nombre":"Rodoviaria Porto Alegre","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.0229372,"longitud":-51.2192642,"ranking":105},
{"id":2627,"nombre":"ICM Posto Fiscalizacao","localidad":"Porto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":2462,"nombre":"Alcantarilla","localidad":"Alcantarilla","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2484,"nombre":"Monte Nievas","localidad":"Monte Nievas","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.86428393480873,"longitud":-64.15248413625787,"ranking":0},
{"id":7944,"nombre":"Monte Nievas Acceso","localidad":"Monte Nievas","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.86595195979486,"longitud":-64.1566442089582,"ranking":0},
{"id":2485,"nombre":"Metileo","localidad":"Metileo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.7739282313659,"longitud":-63.94157161188874,"ranking":0},
{"id":2486,"nombre":"Buena Esperanza","localidad":"Buena Esperanza","provincia":"San Luis","pais":"ARGENTINA","latitud":-34.755346,"longitud":-65.271074,"ranking":0},
{"id":2487,"nombre":"Miguel Cane","localidad":"Miguel Cane","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2488,"nombre":"Dorila","localidad":"Dorila","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2489,"nombre":"Victorica","localidad":"Victorica","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.22469071923417,"longitud":-65.43049336948364,"ranking":0},
{"id":2491,"nombre":"Arizona","localidad":"Arizona","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.721423,"longitud":-65.326748,"ranking":0},
{"id":2492,"nombre":"Anchorena","localidad":"Anchorena","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.674345902170224,"longitud":-65.4211224502504,"ranking":0},
{"id":7943,"nombre":"Relmo Acceso","localidad":"Relmo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.25822608917409,"longitud":-63.452096480890944,"ranking":0},
{"id":2495,"nombre":"Fortuna","localidad":"Fortuna","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.13081153865341,"longitud":-65.3833199320653,"ranking":0},
{"id":4776,"nombre":"Fortuna Acceso","localidad":"Fortuna","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.118378315109766,"longitud":-65.39454463148061,"ranking":0},
{"id":2496,"nombre":"Bagual","localidad":"Bagual","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.14573309350593,"longitud":-65.57099592506327,"ranking":0},
{"id":2499,"nombre":"C. Gomez","localidad":"C. Gomez","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7940,"nombre":"El Soven Acceso","localidad":"El Soven","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2501,"nombre":"El Durazno","localidad":"El Durazno","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7941,"nombre":"El Durazno Acceso","localidad":"El Durazno","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.17857244434632,"longitud":-66.10488078850041,"ranking":0},
{"id":2505,"nombre":"Doblas Acceso","localidad":"Doblas","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7967,"nombre":"Doblas","localidad":"Doblas","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.121927001699454,"longitud":-64.02057833908317,"ranking":0},
{"id":2507,"nombre":"General Manuel Campos","localidad":"Campos","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.461453155026845,"longitud":-63.58541041129553,"ranking":0},
{"id":2510,"nombre":"La Maruja","localidad":"La Maruja","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14557,"nombre":"La Maruja Acceso","localidad":"La Maruja","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.6712624,"longitud":-64.9331253,"ranking":0},
{"id":2511,"nombre":"Pampa Blanca","localidad":"Pampa Blanca","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.531458426430643,"longitud":-65.07666438603539,"ranking":0},
{"id":2512,"nombre":"Reyes","localidad":"Reyes","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.142825432712836,"longitud":-65.39339916347495,"ranking":0},
{"id":2513,"nombre":"Yala","localidad":"Yala","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.121060727726856,"longitud":-65.402730943587,"ranking":0},
{"id":2514,"nombre":"Lozano","localidad":"Lozano","provincia":"Jujuy","pais":"ARGENTINA","latitud":-34.850787846221664,"longitud":-59.054009038360206,"ranking":0},
{"id":2515,"nombre":"Bárcena","localidad":"Barcena","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2516,"nombre":"Senador Perez","localidad":"Senador Perez","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.303624219307945,"longitud":-65.35471279587021,"ranking":0},
{"id":2517,"nombre":"Chorrillos","localidad":"Chorrillos","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.128901360101857,"longitud":-65.36749564558386,"ranking":0},
{"id":2518,"nombre":"Iturbe","localidad":"Iturbe","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.97770601074517,"longitud":-65.35360369192198,"ranking":0},
{"id":1912,"nombre":"Urundel","localidad":"Urundel","provincia":"Salta","pais":"ARGENTINA","latitud":-23.557857839218386,"longitud":-64.39692291728613,"ranking":0},
{"id":2520,"nombre":"CRUCE 34 (ENTRADA)","localidad":"Urundel","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2521,"nombre":"El Tabacal","localidad":"El Tabacal","provincia":"Salta","pais":"ARGENTINA","latitud":-23.259730694352587,"longitud":-64.24976687821182,"ranking":0},
{"id":14496,"nombre":"El Tabacal Acceso","localidad":"El Tabacal","provincia":"Salta","pais":"ARGENTINA","latitud":-23.2612712,"longitud":-64.2502064,"ranking":0},
{"id":2522,"nombre":"Coronel Cornejo","localidad":"Coronel Cornejo","provincia":"Salta","pais":"ARGENTINA","latitud":-22.736618533473873,"longitud":-63.81932699484256,"ranking":0},
{"id":2523,"nombre":"General Ballivian","localidad":"General Ballivian","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3684,"nombre":"General Ballivian Acceso","localidad":"General Ballivian","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2524,"nombre":"Villa San Martin","localidad":"Villa San Martin","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.3011507,"longitud":-64.1858488,"ranking":0},
{"id":2525,"nombre":"Rio Saladillo","localidad":"Rio Saladillo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2973,"nombre":"Saladillo","localidad":"Rio Saladillo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2526,"nombre":"Caimancito","localidad":"Caimancito","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.74137,"longitud":-64.590377,"ranking":2},
{"id":4647,"nombre":"Caimancito Acceso","localidad":"Caimancito","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.730641935247053,"longitud":-64.61820066628007,"ranking":0},
{"id":2527,"nombre":"Azul Pampa","localidad":"Azul Pampa","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2528,"nombre":"Puesto del Marques","localidad":"Puesto del Marquez","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.5358892,"longitud":-65.6963146,"ranking":0},
{"id":2530,"nombre":"PIQUIRENDA","localidad":"PIQUIRENDA","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2531,"nombre":"YARIGUARENDA (apeadero FCGB)","localidad":"YARIGUARENDA","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2979,"nombre":"Senda Achada","localidad":"SENDA HACHADA","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2534,"nombre":"Sausalito","localidad":"Sausalito","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2536,"nombre":"Calilegua","localidad":"Calilegua","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.774188164040915,"longitud":-64.77671066076853,"ranking":0},
{"id":14495,"nombre":"Calilegua Acceso","localidad":"Calilegua","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.7735133,"longitud":-64.7773344,"ranking":0},
{"id":2537,"nombre":"Chalican","localidad":"Chalican","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.070873,"longitud":-64.809078,"ranking":0},
{"id":2538,"nombre":"El Quemado","localidad":"El Quemado","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.104175900870164,"longitud":-64.82559424132748,"ranking":0},
{"id":2539,"nombre":"Arrayanal","localidad":"Arrayanal","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.21108351771875,"longitud":-65.26373698390829,"ranking":0},
{"id":2541,"nombre":"V. General Mitre","localidad":"V. General Mitre","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2553,"nombre":"Candelaria","localidad":"Candelaria","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.05921,"longitud":-65.811316,"ranking":1},
{"id":2554,"nombre":"Ulapes","localidad":"Ulapes","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":10},
{"id":2556,"nombre":"Desiderio Tello","localidad":"Desiderio Tello","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.2110134,"longitud":-66.31188639999999,"ranking":0},
{"id":2565,"nombre":"Veron","localidad":"M. Veron","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2566,"nombre":"Monterrico","localidad":"Monterrico","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.449064110179293,"longitud":-65.15924933140029,"ranking":0},
{"id":2568,"nombre":"Los Alisos","localidad":"Los Alisos","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3573,"nombre":"El Colorado","localidad":"El Colorado","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.310741,"longitud":-59.360828,"ranking":77},
{"id":2579,"nombre":"Seeber","localidad":"Seeber","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2581,"nombre":"La Paquita","localidad":"La Paquita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9058337,"longitud":-62.2125544,"ranking":0},
{"id":2582,"nombre":"Altos de Chipion","localidad":"Altos de Chipion","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9546238,"longitud":-62.33896809999999,"ranking":3},
{"id":2583,"nombre":"Balnearia","localidad":"Balnearia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.009205,"longitud":-62.663876,"ranking":24},
{"id":2584,"nombre":"Marull","localidad":"Marull","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9947117482521,"longitud":-62.82582244364738,"ranking":0},
{"id":7932,"nombre":"Marull Acceso","localidad":"Marull","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.9896248,"longitud":-62.8257218,"ranking":9},
{"id":2585,"nombre":"La Para","localidad":"La Para","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.893361,"longitud":-63.001062,"ranking":13},
{"id":2586,"nombre":"La Puerta","localidad":"La Puerta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.8925935,"longitud":-63.25506169999999,"ranking":11},
{"id":2588,"nombre":"Monte Cristo","localidad":"Monte Cristo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.345813,"longitud":-63.945576,"ranking":24},
{"id":4207,"nombre":"El Condor","localidad":"El Condor","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2591,"nombre":"La Chañarienta","localidad":"La Chañarienta","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.387023,"longitud":-67.012354,"ranking":3},
{"id":2592,"nombre":"Encon","localidad":"Encon","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.2149152,"longitud":-67.7986648,"ranking":9},
{"id":2728,"nombre":"Calafate","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.337345,"longitud":-72.245203,"ranking":1299},
{"id":9987,"nombre":"Aeropuerto de El Calafate Armando Tola","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.283987,"longitud":-72.053855,"ranking":408},
{"id":9988,"nombre":"Hoteles Calafate","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.336751,"longitud":-72.245262,"ranking":8},
{"id":15642,"nombre":"Hoteles Calafate (Full Day)","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15532,"nombre":"Navegacion","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15533,"nombre":"Parque Nacional Los Glaciares","localidad":"El Calafate","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2595,"nombre":"La Llave Acceso","localidad":"La Llave","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.64074383066344,"longitud":-68.00094420651804,"ranking":0},
{"id":8278,"nombre":"La Llave","localidad":"La Llave","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.64186185654714,"longitud":-68.00812012779345,"ranking":0},
{"id":8518,"nombre":"Puente Llave Vieja","localidad":"La Llave","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2985,"nombre":"Beazley","localidad":"Beazley","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.75748806160239,"longitud":-66.64339690242245,"ranking":0},
{"id":2598,"nombre":"Del Campillo","localidad":"Del Campillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.3752323,"longitud":-64.497125,"ranking":277},
{"id":3227,"nombre":"Del Campillo Acceso","localidad":"Del Campillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.38127327229757,"longitud":-64.49302275733358,"ranking":0},
{"id":2599,"nombre":"Canalejas Acceso","localidad":"Canalejas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4778,"nombre":"Canalejas","localidad":"Canalejas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.168572899873155,"longitud":-66.49962362771193,"ranking":0},
{"id":2600,"nombre":"La Maroma","localidad":"La Maroma","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.21446740961925,"longitud":-66.32987900279363,"ranking":0},
{"id":4777,"nombre":"La Maroma Acceso","localidad":"La Maroma","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.168519300491,"longitud":-66.32925582409722,"ranking":0},
{"id":2602,"nombre":"Villa Atuel","localidad":"Villa Atuel","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.834026,"longitud":-67.922816,"ranking":258},
{"id":3649,"nombre":"Villa Atuel Acceso","localidad":"Villa Atuel","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.827263,"longitud":-67.917364,"ranking":0},
{"id":2603,"nombre":"VILLA 213","localidad":"VILLA 213","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2605,"nombre":"Santiago Temple","localidad":"Santiago Temple","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.388029,"longitud":-63.418294,"ranking":0},
{"id":2606,"nombre":"Transito","localidad":"Transito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.423231,"longitud":-63.19444,"ranking":4},
{"id":2607,"nombre":"Manantiales","localidad":"Manantiales","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2609,"nombre":"Peaje La Cumbre","localidad":"Peaje La Cumbre","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.3621608,"longitud":-66.0562612,"ranking":0},
{"id":2610,"nombre":"Barra Do Ribeiro","localidad":"Barra Do Ribeiro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.29895819999999,"longitud":-51.3045735,"ranking":0},
{"id":2611,"nombre":"Chui","localidad":"Chuí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-33.69275041194332,"longitud":-53.45528917073677,"ranking":0},
{"id":2612,"nombre":"Camacua","localidad":"Camaquã","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.855054483869246,"longitud":-51.809970871759106,"ranking":0},
{"id":2613,"nombre":"Cristal","localidad":"Cristal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.99798390010285,"longitud":-52.05006083617224,"ranking":0},
{"id":15240,"nombre":"Cordeiro","localidad":"Cristal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.924238134669913,"longitud":-51.9802150823083,"ranking":0},
{"id":2614,"nombre":"Eldorado Do Sul","localidad":"Eldorado Do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.002142751162427,"longitud":-51.30771809156702,"ranking":0},
{"id":2615,"nombre":"Guaiba","localidad":"Guaíba","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.108049974572,"longitud":-51.31221588213352,"ranking":0},
{"id":2616,"nombre":"Osorio","localidad":"Osório","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.888549711217138,"longitud":-50.27404050023772,"ranking":19},
{"id":2618,"nombre":"San Lorenzo do Sul","localidad":"São Lourenço do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.362921428667583,"longitud":-51.97895952742502,"ranking":0},
{"id":2619,"nombre":"Santa Victoria do Palmar","localidad":"Santa Vitória do Palmar","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-33.5231512,"longitud":-53.3655005,"ranking":0},
{"id":2620,"nombre":"Ararangua","localidad":"Araranguá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.933096479758785,"longitud":-49.48687413570471,"ranking":0},
{"id":2621,"nombre":"Ararangua (Trevo)","localidad":"Araranguá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.93297573645384,"longitud":-49.48691178879686,"ranking":0},
{"id":2622,"nombre":"Bombinhas","localidad":"Bombinhas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.15811507778609,"longitud":-48.57375004679086,"ranking":0},
{"id":2623,"nombre":"Criciuma","localidad":"Criciúma","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.67991744026154,"longitud":-49.36994618561072,"ranking":0},
{"id":2629,"nombre":"Enseada Do Brito","localidad":"Enseada Do Brito","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.76877060403681,"longitud":-48.63109212879038,"ranking":0},
{"id":2630,"nombre":"Ferrugem","localidad":"Garopaba","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9601,"nombre":"Garopaba","localidad":"Garopaba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.027337,"longitud":-48.628229,"ranking":0},
{"id":2631,"nombre":"Gravatal","localidad":"Gravatal","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.32782293784467,"longitud":-49.06566857180737,"ranking":0},
{"id":2632,"nombre":"Imbituba","localidad":"Imbituba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.2375377,"longitud":-48.6728829,"ranking":0},
{"id":2633,"nombre":"Itajai","localidad":"Itajaí","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9145017,"longitud":-48.6942949,"ranking":0},
{"id":2634,"nombre":"Joinville","localidad":"Joinville","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.3407385,"longitud":-48.8160478,"ranking":6},
{"id":2635,"nombre":"Laguna","localidad":"Laguna","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.478714314634324,"longitud":-48.78467234625235,"ranking":0},
{"id":2636,"nombre":"Sombrio","localidad":"Sombrio","provincia":"Santa Catarina","pais":"BRASIL","latitud":-29.1030527,"longitud":-49.6376272,"ranking":0},
{"id":2637,"nombre":"Sinuelo","localidad":"Sinuelo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.51614808652204,"longitud":-48.719295190483265,"ranking":0},
{"id":2638,"nombre":"Paulo Lopes","localidad":"Paulo Lopes","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.956800341482555,"longitud":-48.67583001866949,"ranking":0},
{"id":5023,"nombre":"Tubarao","localidad":"Tubarão","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.4746093,"longitud":-49.0144436,"ranking":0},
{"id":2643,"nombre":"Juquitiba","localidad":"Juquitiba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.932065255719557,"longitud":-47.07221701959659,"ranking":0},
{"id":2644,"nombre":"Registro","localidad":"Registro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.492464992342185,"longitud":-47.83821506579096,"ranking":0},
{"id":2768,"nombre":"Paraguari","localidad":"Paraguari","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.62008,"longitud":-57.150782,"ranking":12},
{"id":14643,"nombre":"Potrerito","localidad":"Paraguari","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.6198723,"longitud":-57.2548501,"ranking":0},
{"id":2661,"nombre":"Colonia Valdense","localidad":"Colonia Valdense","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.3362589,"longitud":-57.2582625,"ranking":0},
{"id":2662,"nombre":"Rosario","localidad":"Rosario","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.31323204889899,"longitud":-57.35265344411935,"ranking":0},
{"id":2665,"nombre":"San Carlos","localidad":"San Carlos","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.79343434006996,"longitud":-54.92329589169714,"ranking":0},
{"id":14671,"nombre":"Barra de Maldonado","localidad":"San Carlos","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.86632609999999,"longitud":-54.8682184,"ranking":0},
{"id":14672,"nombre":"Balneario Buenos Aires","localidad":"San Carlos","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8920737,"longitud":-54.7910124,"ranking":0},
{"id":14673,"nombre":"Jose Ignacio","localidad":"San Carlos","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8358546,"longitud":-54.6419138,"ranking":0},
{"id":2666,"nombre":"Corralito","localidad":"Corralito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.0253891,"longitud":-64.195139,"ranking":8},
{"id":2667,"nombre":"Monte Ralo","localidad":"Monte Ralo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9107427,"longitud":-64.2415917,"ranking":1},
{"id":2668,"nombre":"Despeñaderos","localidad":"Despeñaderos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8148194,"longitud":-64.2919684,"ranking":51},
{"id":2688,"nombre":"Despeñaderos Acceso","localidad":"Despeñaderos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.822119512959695,"longitud":-64.31394255434999,"ranking":0},
{"id":2669,"nombre":"Bajo Chico","localidad":"Bajo Chico","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.721148011103693,"longitud":-64.33512748037627,"ranking":0},
{"id":2670,"nombre":"Alto Fierro","localidad":"Alto de Fierro","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2671,"nombre":"Rafael Garcia Acceso","localidad":"Rafael Garcia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.645052578700952,"longitud":-64.26258978786838,"ranking":0},
{"id":3688,"nombre":"Rafael Garcia","localidad":"Rafael Garcia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6492306,"longitud":-64.26048399999999,"ranking":0},
{"id":2672,"nombre":"El Quebracho","localidad":"El Quebracho","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.24735358722376,"longitud":-64.37726106565655,"ranking":0},
{"id":2673,"nombre":"Santa Isabel","localidad":"Santa Isabel","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":6},
{"id":2677,"nombre":"Villa del Parque","localidad":"Villa del Parque","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9132126,"longitud":-64.5242737,"ranking":12},
{"id":2678,"nombre":"El Torreon","localidad":"El Torreon","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2679,"nombre":"El Portezuelo","localidad":"El Portezuelo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.847858270009205,"longitud":-65.18930740872328,"ranking":0},
{"id":2681,"nombre":"La Cruz","localidad":"La Cruz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.3006722,"longitud":-64.4828612,"ranking":16},
{"id":10830,"nombre":"La Cruz Acceso","localidad":"La Cruz","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2682,"nombre":"Bajo Del Carmen","localidad":"Bajo Del Carmen","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2683,"nombre":"Soconcho Acceso","localidad":"Soconcho","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10662,"nombre":"Soconcho","localidad":"Soconcho","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.04600674950176,"longitud":-64.35362257182442,"ranking":0},
{"id":2684,"nombre":"Las Bajadas","localidad":"Las Bajadas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.10080753679941,"longitud":-64.32842461135606,"ranking":0},
{"id":2687,"nombre":"Bouwer Acceso","localidad":"Bouwer","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10661,"nombre":"Bouwer","localidad":"Bouwer","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5580168888279,"longitud":-64.19299130022875,"ranking":0},
{"id":2696,"nombre":"Castillos","localidad":"Castillos","provincia":"Rocha","pais":"URUGUAY","latitud":-34.19977308404681,"longitud":-53.8613279192522,"ranking":0},
{"id":2697,"nombre":"Punta del Diablo","localidad":"Punta del Diablo","provincia":"Rocha","pais":"URUGUAY","latitud":-34.036975,"longitud":-53.56212,"ranking":0},
{"id":14695,"nombre":"Punta del Diablo Acceso","localidad":"Punta del Diablo","provincia":"Rocha","pais":"URUGUAY","latitud":-34.0292887,"longitud":-53.5832111,"ranking":0},
{"id":2700,"nombre":"Young (Terminal)","localidad":"Young","provincia":"Río Negro (Uruguay)","pais":"URUGUAY","latitud":-32.697004314689714,"longitud":-57.63551250107908,"ranking":0},
{"id":9165,"nombre":"Young Acceso","localidad":"Young","provincia":"Río Negro (Uruguay)","pais":"URUGUAY","latitud":-32.69823719937437,"longitud":-57.63648586305521,"ranking":0},
{"id":2701,"nombre":"Cardona","localidad":"Cardona","provincia":"Soriano","pais":"URUGUAY","latitud":-33.872228833661445,"longitud":-57.37497200575666,"ranking":0},
{"id":2702,"nombre":"Jose Enrique Rodo","localidad":"Jose Enrique Rodo","provincia":"Soriano","pais":"URUGUAY","latitud":-33.697578873536486,"longitud":-57.53357279693991,"ranking":0},
{"id":2703,"nombre":"Palmitas","localidad":"Palmitas","provincia":"Soriano","pais":"URUGUAY","latitud":-33.5105383337687,"longitud":-57.806824636070544,"ranking":0},
{"id":2705,"nombre":"Atlantida","localidad":"Atlantida","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2706,"nombre":"El Pinar","localidad":"El Pinar","provincia":"Canelones","pais":"URUGUAY","latitud":-34.787486306852415,"longitud":-55.892283962500585,"ranking":0},
{"id":2708,"nombre":"La Floresta","localidad":"La Floresta","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2709,"nombre":"Neptunia","localidad":"Neptunia","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":8088,"nombre":"Pando","localidad":"Pando","provincia":"Canelones","pais":"URUGUAY","latitud":-34.71487306377428,"longitud":-55.967341303770965,"ranking":0},
{"id":2711,"nombre":"Parque del Plata","localidad":"Parque del Plata","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2712,"nombre":"Salinas","localidad":"Salinas","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2713,"nombre":"Shangrila","localidad":"Shangrila","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2714,"nombre":"Solymar","localidad":"Solymar","provincia":"Canelones","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":2715,"nombre":"Santa Lucia","localidad":"Santa Lucia","provincia":"Canelones","pais":"URUGUAY","latitud":-34.44852681153874,"longitud":-56.39886312157445,"ranking":0},
{"id":2716,"nombre":"Trinidad (Terminal)","localidad":"Trinidad","provincia":"Flores","pais":"URUGUAY","latitud":-33.51407522577028,"longitud":-56.90752877014072,"ranking":4},
{"id":2717,"nombre":"Ecilda Paullier","localidad":"Ecilda Paullier","provincia":"San José","pais":"URUGUAY","latitud":-34.35983426792671,"longitud":-57.05175942041329,"ranking":0},
{"id":2718,"nombre":"Libertad","localidad":"Libertad","provincia":"San José","pais":"URUGUAY","latitud":-34.6395791,"longitud":-56.6121145,"ranking":0},
{"id":7620,"nombre":"San Jose","localidad":"San Jose","provincia":"San José","pais":"URUGUAY","latitud":-34.332294596379946,"longitud":-56.71263198030664,"ranking":0},
{"id":2734,"nombre":"Plaza Huincul","localidad":"Huincul","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.929602,"longitud":-69.225261,"ranking":69},
{"id":4147,"nombre":"Acassuso","localidad":"Acassuso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3547,"nombre":"Villa Adelina","localidad":"Villa Adelina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2746,"nombre":"Garayalde","localidad":"Garayalde","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.6890676,"longitud":-66.6235614,"ranking":38},
{"id":2753,"nombre":"Las Caleras","localidad":"Las Caleras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.38884582630376,"longitud":-64.52149300330227,"ranking":0},
{"id":2755,"nombre":"Los Cedros","localidad":"Los Cedros","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5264657,"longitud":-64.28533639999999,"ranking":3},
{"id":3280,"nombre":"Los Condores","localidad":"Los Condores","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.3209826,"longitud":-64.2828174,"ranking":106},
{"id":2756,"nombre":"Los Reartes","localidad":"Los Reartes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.908565238874466,"longitud":-64.57504034042358,"ranking":83},
{"id":10803,"nombre":"Los Reartes Acceso","localidad":"Los Reartes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.920071,"longitud":-64.577205,"ranking":0},
{"id":2759,"nombre":"Moldes","localidad":"Coronel Moldes","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3226,"nombre":"Moldes Acceso","localidad":"Coronel Moldes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.5926017,"longitud":-64.41717849999999,"ranking":12},
{"id":7894,"nombre":"Paso Cabral","localidad":"Paso Cabral","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.4138492,"longitud":-64.49853120000002,"ranking":0},
{"id":9844,"nombre":"Santa Ana","localidad":"Santa Ana","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.589411563270883,"longitud":-64.35467004776001,"ranking":60},
{"id":2761,"nombre":"Tancacha","localidad":"Tancacha","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.240034,"longitud":-63.97911299999999,"ranking":36},
{"id":2763,"nombre":"Usina","localidad":"Usina","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2764,"nombre":"Villa La Merced","localidad":"Villa La Merced","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8140463,"longitud":-64.5011438,"ranking":0},
{"id":2765,"nombre":"Hernando","localidad":"Hernando","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.423358,"longitud":-63.738236,"ranking":123},
{"id":2766,"nombre":"La Bolsa","localidad":"La Bolsa","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7287146708221,"longitud":-64.42846298217773,"ranking":96},
{"id":2767,"nombre":"Bella Union","localidad":"Bella Union","provincia":"Artigas","pais":"URUGUAY","latitud":-30.26129457449926,"longitud":-57.60592886835158,"ranking":0},
{"id":2772,"nombre":"San Carlos","localidad":"San Carlos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.7782105,"longitud":-69.0606626,"ranking":0},
{"id":2773,"nombre":"Berisso","localidad":"Berisso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8709114,"longitud":-57.8791259,"ranking":0},
{"id":2784,"nombre":"Santa Rosa (Peru)","localidad":"Santa Rosa (Frontera Perú)","provincia":"Tacna","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":2774,"nombre":"Susques","localidad":"Susques","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.401179869092726,"longitud":-66.36823253743225,"ranking":0},
{"id":2775,"nombre":"Pastos Chicos","localidad":"Pastos Chicos","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.766244574015065,"longitud":-66.450902551929,"ranking":0},
{"id":2776,"nombre":"Paso de Jama","localidad":"Paso de Jama","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.23679477218442,"longitud":-67.02288565847577,"ranking":0},
{"id":2777,"nombre":"San Pedro de Atacama","localidad":"San Pedro de Atacama","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.913183,"longitud":-68.19496,"ranking":51},
{"id":2778,"nombre":"Calama","localidad":"Calama","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.449702,"longitud":-68.929103,"ranking":24},
{"id":2779,"nombre":"Quillagua","localidad":"Quillagua","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-21.656851,"longitud":-69.534194,"ranking":0},
{"id":2780,"nombre":"Pozo al Monte","localidad":"Pozo Almonte","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-20.256656,"longitud":-69.786025,"ranking":0},
{"id":2781,"nombre":"Huara","localidad":"Huara","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-19.994637,"longitud":-69.771575,"ranking":0},
{"id":2782,"nombre":"Camarones","localidad":"Camarones","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":-19.010506112114545,"longitud":-69.85892700471928,"ranking":0},
{"id":2783,"nombre":"Arica","localidad":"Arica","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":-18.47264428249602,"longitud":-70.30475043985025,"ranking":0},
{"id":4337,"nombre":"Arica Acceso","localidad":"Arica","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":9097,"nombre":"Parinacota","localidad":"Arica","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":-18.202610982131535,"longitud":-69.26842992157073,"ranking":0},
{"id":9141,"nombre":"Socoroma","localidad":"Arica","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":-18.283729980153645,"longitud":-69.5861771680744,"ranking":0},
{"id":9168,"nombre":"Putre","localidad":"Arica","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":-18.472720582134546,"longitud":-70.30450746892171,"ranking":0},
{"id":2793,"nombre":"Frontera Chile","localidad":"Chacalluta","provincia":"Región XV - Arica y Parinacota","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":2786,"nombre":"Moquegua","localidad":"Moquegua","provincia":"Moquegua","pais":"Perú","latitud":-17.191185398380128,"longitud":-70.948318122566,"ranking":0},
{"id":2788,"nombre":"Puerto Atico","localidad":"Puerto Atico","provincia":"Arequipa","pais":"Perú","latitud":-16.22813481545627,"longitud":-73.606634401425,"ranking":0},
{"id":2789,"nombre":"Chala","localidad":"Chala","provincia":"Arequipa","pais":"Perú","latitud":-15.865773231984578,"longitud":-74.24683559083665,"ranking":0},
{"id":2790,"nombre":"Ica","localidad":"Ica ","provincia":"San Jeronimo de Ica","pais":"Perú","latitud":null,"longitud":null,"ranking":20},
{"id":15544,"nombre":"Chincha Alta","localidad":"Ica ","provincia":"San Jeronimo de Ica","pais":"Perú","latitud":-13.4159747,"longitud":-76.1405667,"ranking":0},
{"id":3989,"nombre":"Nazca","localidad":"Nazca","provincia":"San Jeronimo de Ica","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":2791,"nombre":"San Vicente de Cañete","localidad":"San Vicente de Cañete","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":7488,"nombre":"San Vicente Acceso","localidad":"San Vicente de Cañete","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-13.079603165147715,"longitud":-76.38815456629099,"ranking":0},
{"id":2792,"nombre":"Nasca","localidad":"Nasca","provincia":"San Jeronimo de Ica","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":2796,"nombre":"Los Libertadores","localidad":"Libertadores","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-32.829766,"longitud":-70.093049,"ranking":0},
{"id":2797,"nombre":"Las Cuevas","localidad":"Las Cuevas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.813841,"longitud":-70.051987,"ranking":26},
{"id":2798,"nombre":"Los Horcones","localidad":"Los Horcones","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2799,"nombre":"Potrerillos","localidad":"Potrerillos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.960611,"longitud":-69.197139,"ranking":434},
{"id":2999,"nombre":"Chacritas","localidad":"Potrerillos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.96749848429034,"longitud":-69.23844080779423,"ranking":0},
{"id":2801,"nombre":"Villa Montenegro","localidad":"Villa Montenegro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.695444,"longitud":-64.4133435,"ranking":5},
{"id":2738,"nombre":"Anisacate","localidad":"Anisacate","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.72224,"longitud":-64.404048,"ranking":123},
{"id":3098,"nombre":"Cortaderas","localidad":"Cortaderas","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.506533,"longitud":-64.983882,"ranking":0},
{"id":4890,"nombre":"Cortaderas Acceso","localidad":"Cortaderas","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.506615,"longitud":-64.984062,"ranking":36},
{"id":2808,"nombre":"Los Moyes Acceso","localidad":"Los Moyes","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2810,"nombre":"Arroyo de Los Patos","localidad":"Arroyo de los Patos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7627282,"longitud":-65.0021878,"ranking":3},
{"id":2817,"nombre":"Grand Bourg","localidad":"Grand Bourg","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.484294800,"longitud":-58.726860400,"ranking":5},
{"id":2818,"nombre":"Lima Acceso","localidad":"Lima","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.0782366,"longitud":-59.2065116,"ranking":0},
{"id":2819,"nombre":"La Serena","localidad":"La Serena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2820,"nombre":"Las Totoras","localidad":"Las Totoras","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.588513222267984,"longitud":-61.173838102454575,"ranking":0},
{"id":2821,"nombre":"San Martin de las Escobas","localidad":"San Martin de las Escobas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.857744828557205,"longitud":-61.57006514086612,"ranking":0},
{"id":2823,"nombre":"Miel de Palo Acceso","localidad":"Miel de Palo","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2824,"nombre":"El Mala Cara","localidad":"El Mala Cara","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2825,"nombre":"La Telesita Acceso","localidad":"La Telesita","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2828,"nombre":"Sanvirones","localidad":"Sanvirones","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2829,"nombre":"Unquillo","localidad":"Unquillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1733280,"longitud":-64.3131036,"ranking":28},
{"id":2832,"nombre":"San Isidro","localidad":"San Isidro","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.057229,"longitud":-66.607251,"ranking":0},
{"id":2833,"nombre":"San Pedro","localidad":"San Pedro","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.966938,"longitud":-66.698301,"ranking":0},
{"id":2834,"nombre":"Araditos","localidad":"Araditos","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2835,"nombre":"El Medano","localidad":"El Medano","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.49238,"longitud":-65.721645,"ranking":0},
{"id":2836,"nombre":"San Vicente","localidad":"San Vicente","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2837,"nombre":"San Antonio","localidad":"San Antonio","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.65550447139917,"longitud":-66.87303668899744,"ranking":2},
{"id":2838,"nombre":"Eugenio Bustos","localidad":"Eugenio Bustos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.778096,"longitud":-69.060922,"ranking":771},
{"id":2977,"nombre":"Hualtaran","localidad":"Hualtaran","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.2895323,"longitud":-66.2992823,"ranking":0},
{"id":2842,"nombre":"Las Trancas","localidad":"Las Trancas","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2844,"nombre":"Punta del Medano","localidad":"Punta del Medano","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2845,"nombre":"Cruz de San Pedro","localidad":"Cruz de San Pedro","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2846,"nombre":"San Miguel","localidad":"San Miguel","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.6510377,"longitud":-68.2816441,"ranking":0},
{"id":2847,"nombre":"La Bolsa","localidad":"La Bolsa","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.6511641,"longitud":-68.2815484,"ranking":0},
{"id":2848,"nombre":"Camarico","localidad":"Camarico","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.05981841611391,"longitud":-67.98438948822759,"ranking":0},
{"id":2849,"nombre":"El Refugio","localidad":"El Refugio","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2850,"nombre":"La Chimbera","localidad":"La Chimbera","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.825981045920265,"longitud":-68.24602216923785,"ranking":0},
{"id":2851,"nombre":"Las Casuarinas","localidad":"Casuarinas","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.8113827,"longitud":-68.3267249,"ranking":14},
{"id":9179,"nombre":"Casuarinas Acceso","localidad":"Casuarinas","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.811599388643458,"longitud":-68.35462747861972,"ranking":0},
{"id":2854,"nombre":"San Jose de los Arroyos","localidad":"San Jose de los Arroyos","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.531478,"longitud":-56.730095,"ranking":12},
{"id":2852,"nombre":"Ypacarai","localidad":"Ypacarai","provincia":"Ypacarai","pais":"Paraguay","latitud":-25.402784,"longitud":-57.289832,"ranking":1},
{"id":2853,"nombre":"Barrero","localidad":"Barrero","provincia":"Barrero","pais":"Paraguay","latitud":-25.382898,"longitud":-56.966415,"ranking":0},
{"id":2889,"nombre":"Benjamin Paz","localidad":"Benjamin Paz","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.368637921525178,"longitud":-65.2940816568973,"ranking":0},
{"id":2894,"nombre":"Vipos","localidad":"Vipos","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.48113114788156,"longitud":-65.34678137043036,"ranking":0},
{"id":2893,"nombre":"Apolinario Saravia","localidad":"Apolinario Saravia","provincia":"Salta","pais":"ARGENTINA","latitud":-24.7953732,"longitud":-65.4115185,"ranking":0},
{"id":2915,"nombre":"El Bordo","localidad":"Apolinario Saravia","provincia":"Salta","pais":"ARGENTINA","latitud":-24.66216988271743,"longitud":-65.1055437332028,"ranking":0},
{"id":2892,"nombre":"Las Lajitas","localidad":"Las Lajitas","provincia":"Salta","pais":"ARGENTINA","latitud":-24.72779039536778,"longitud":-64.19541323278608,"ranking":0},
{"id":2891,"nombre":"Portezuelo","localidad":"Portezuelo","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2912,"nombre":"Los Lotes","localidad":"Los Lotes","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2913,"nombre":"Mollinedo","localidad":"Mollinedo","provincia":"Salta","pais":"ARGENTINA","latitud":-24.541522478156143,"longitud":-64.09995372890273,"ranking":0},
{"id":2914,"nombre":"Las Palmas","localidad":"Las Palmas","provincia":"Salta","pais":"ARGENTINA","latitud":-24.798651044726558,"longitud":-65.37134975866738,"ranking":0},
{"id":2885,"nombre":"Rio del Valle","localidad":"Rio del Valle","provincia":"Salta","pais":"ARGENTINA","latitud":-24.674815184931617,"longitud":-64.19973233411977,"ranking":0},
{"id":2916,"nombre":"Rio del Valle Acceso","localidad":"Rio del Valle","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2917,"nombre":"Los Tapires","localidad":"Los Tapires","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2918,"nombre":"Piquete Cabado","localidad":"Piquete Cabado","provincia":"Salta","pais":"ARGENTINA","latitud":-24.8204843043376,"longitud":-64.17824519599543,"ranking":0},
{"id":2919,"nombre":"Luis Burela","localidad":"Luis Burela","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2920,"nombre":"General Pizarro","localidad":"Pizarro","provincia":"Salta","pais":"ARGENTINA","latitud":-24.22837108786627,"longitud":-63.99119088326188,"ranking":0},
{"id":2921,"nombre":"Chaguaral","localidad":"Chaguaral","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2922,"nombre":"Tineo","localidad":"Tineo","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2923,"nombre":"La Estrella","localidad":"La Estrella","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2924,"nombre":"Matorras","localidad":"Matorras","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2925,"nombre":"Urizar","localidad":"Urizar","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2926,"nombre":"Yuchan","localidad":"Yuchan","provincia":"Salta","pais":"ARGENTINA","latitud":-23.432645875459972,"longitud":-64.14235296678783,"ranking":0},
{"id":2941,"nombre":"Candelaria","localidad":"Candelaria","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.4705059,"longitud":-55.7497627,"ranking":77},
{"id":3013,"nombre":"Candelaria Acceso","localidad":"Candelaria","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.470848600,"longitud":-55.746050500,"ranking":2},
{"id":3713,"nombre":"Bonpland","localidad":"Candelaria","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.482492538056217,"longitud":-55.47207621792627,"ranking":0},
{"id":8548,"nombre":"Martires Cruce","localidad":"Candelaria","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8552,"nombre":"Parador Candelaria","localidad":"Candelaria","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.470350741855082,"longitud":-55.750056115238145,"ranking":0},
{"id":3007,"nombre":"Cuatro Bocas Acceso","localidad":"Cuatro Bocas","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.8632514,"longitud":-61.8566865,"ranking":23},
{"id":3564,"nombre":"Cuatro Bocas","localidad":"Cuatro Bocas","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.076552,"longitud":-57.998586,"ranking":2},
{"id":2956,"nombre":"Leandro N Alem","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.628296,"longitud":-55.340416,"ranking":102},
{"id":8559,"nombre":"Villa Armonia Acceso","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.558268016171326,"longitud":-55.23410358869295,"ranking":0},
{"id":8560,"nombre":"Villa Gross Acceso","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8561,"nombre":"Villa Sommer Acceso","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9162,"nombre":"Villa Sommer","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.543424195717336,"longitud":-55.20795340221332,"ranking":0},
{"id":14587,"nombre":"Villa Gross ","localidad":"Leandro N. Alem","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.6035904,"longitud":-55.2740587,"ranking":0},
{"id":2958,"nombre":"Cerro Azul","localidad":"Cerro Azul","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.641976839975012,"longitud":-55.503543173743196,"ranking":0},
{"id":3014,"nombre":"Cerro Azul Acceso","localidad":"Cerro Azul","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.641887,"longitud":-55.503577,"ranking":0},
{"id":2959,"nombre":"Gobernador Roca","localidad":"Gobernador Roca","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.1895637,"longitud":-55.468036,"ranking":25},
{"id":4433,"nombre":"Gobernador Roca Acceso","localidad":"Gobernador Roca","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.17844149604874,"longitud":-55.45602236994308,"ranking":0},
{"id":2960,"nombre":"Ita Ibate","localidad":"Ita Ibate","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.430303,"longitud":-57.338459,"ranking":50},
{"id":8399,"nombre":"Ita Ibate Acceso","localidad":"Ita Ibate","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.430168,"longitud":-57.338427,"ranking":0},
{"id":2961,"nombre":"Puerto Mado","localidad":"Puerto Mado","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.23072919493683,"longitud":-54.629154265026074,"ranking":0},
{"id":4431,"nombre":"Puerto Mado Acceso","localidad":"Puerto Mado","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.23154042797136,"longitud":-54.62244935614021,"ranking":0},
{"id":8547,"nombre":"Magdalena","localidad":"Puerto Mado","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.238451461615828,"longitud":-54.59775686154502,"ranking":0},
{"id":2964,"nombre":"Puerto Libertad","localidad":"Puerto Libertad","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.921599,"longitud":-54.581349,"ranking":10},
{"id":4434,"nombre":"Puerto Libertad Acceso","localidad":"Puerto Libertad","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.912978,"longitud":-54.581289,"ranking":0},
{"id":2965,"nombre":"Puerto Piray","localidad":"Puerto Piray","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.4679816,"longitud":-54.7165821,"ranking":1},
{"id":4492,"nombre":"Puerto Piray Acceso","localidad":"Puerto Piray","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.49241,"longitud":-54.674586,"ranking":18},
{"id":2966,"nombre":"Acceso Yapeyu","localidad":"Yapeyu","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.470095,"longitud":-56.818606,"ranking":9},
{"id":3024,"nombre":"Yapeyu","localidad":"Yapeyu","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.4702789,"longitud":-56.8189476,"ranking":6},
{"id":2967,"nombre":"2 de Mayo","localidad":"2 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.020257,"longitud":-54.684303,"ranking":26},
{"id":2962,"nombre":"Montecarlo","localidad":"Montecarlo","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.57369,"longitud":-54.733698,"ranking":266},
{"id":3557,"nombre":"Montecarlo Acceso","localidad":"Montecarlo","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.5822485,"longitud":-54.72738520000001,"ranking":0},
{"id":2963,"nombre":"Santo Pipo","localidad":"Santo Pipo","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.141915,"longitud":-55.405863,"ranking":28},
{"id":2969,"nombre":"La Mora","localidad":"La Mora","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4779,"nombre":"La Mora Acceso","localidad":"La Mora","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.10481799298503,"longitud":-66.8405036006988,"ranking":0},
{"id":3006,"nombre":"Villa Pehuenia","localidad":"Villa Pehuenia","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.880618,"longitud":-71.187238,"ranking":17},
{"id":15461,"nombre":"Hoteles Villa Pehuenia","localidad":"Villa Pehuenia","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.88131569999999,"longitud":-71.1709261,"ranking":0},
{"id":3020,"nombre":"El Alcazar","localidad":"El Alcazar","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.71646893953082,"longitud":-54.811024223839084,"ranking":1},
{"id":4065,"nombre":"Cruce el Alcazar","localidad":"El Alcazar","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.690736400,"longitud":-54.821133600,"ranking":0},
{"id":8562,"nombre":"El Alcazar Acceso","localidad":"El Alcazar","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.713603,"longitud":-54.815956,"ranking":3},
{"id":3036,"nombre":"La Reforma","localidad":"La Reforma","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.552071300,"longitud":-66.223826400,"ranking":0},
{"id":4189,"nombre":"La Reforma Acceso","localidad":"La Reforma","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3049,"nombre":"ARMSTRONG","localidad":"ARMSTRONG","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3050,"nombre":"Mansilla","localidad":"Lucio V. Mansilla","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3054,"nombre":"Rincon","localidad":"Rincon","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3056,"nombre":"Villa del Totoral","localidad":"Totoral","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.703539,"longitud":-64.0677,"ranking":40},
{"id":9153,"nombre":"Villa del Totoral Acceso","localidad":"Totoral","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.706661076251738,"longitud":-64.05721123118231,"ranking":4},
{"id":3059,"nombre":"Ferreyra","localidad":"Ferreyra","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.464687505206378,"longitud":-64.10778584044517,"ranking":0},
{"id":3726,"nombre":"Carpinteria","localidad":"Carpinteria","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.819257,"longitud":-68.541304,"ranking":0},
{"id":3065,"nombre":"Colonia Fiscal","localidad":"Colonia Fiscal","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3072,"nombre":"Valle Fertil","localidad":"Valle Fertil","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.635908,"longitud":-67.470387,"ranking":62},
{"id":15496,"nombre":"San Agustín del Valle Fertil","localidad":"Valle Fertil","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3071,"nombre":"El Volcan","localidad":"El Volcan","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.25127093422972,"longitud":-66.19268534991448,"ranking":0},
{"id":3069,"nombre":"Pocito","localidad":"Pocitos","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.690235,"longitud":-68.586782,"ranking":20},
{"id":3068,"nombre":"Marayes","localidad":"Marayes","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3067,"nombre":"La Chañarienta","localidad":"La Chañarienta","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3066,"nombre":"Estacion Marti","localidad":"Estacion Marti","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.825576835281602,"longitud":-68.22510596452283,"ranking":0},
{"id":3074,"nombre":"Benjamin Gould","localidad":"Benjamin Gould","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3079,"nombre":"Teodolina","localidad":"Teodolina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3090,"nombre":"Santa Eufemia","localidad":"Santa Eufemia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.184613557488746,"longitud":-63.2830318056498,"ranking":0},
{"id":1653,"nombre":"Beltran Acceso","localidad":"Beltran","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.82341174632269,"longitud":-64.05543598349057,"ranking":0},
{"id":3097,"nombre":"Beltran","localidad":"Beltran","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.823943,"longitud":-64.055383,"ranking":7},
{"id":3101,"nombre":"Luis Beltran","localidad":"Luis Beltran","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.414511,"longitud":-65.695078,"ranking":65},
{"id":3983,"nombre":"Antofagasta","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-23.616063,"longitud":-70.388468,"ranking":0},
{"id":4466,"nombre":"Tocopilla","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.08574,"longitud":-70.193003,"ranking":0},
{"id":4645,"nombre":"Mejillones","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-23.097669,"longitud":-70.445715,"ranking":0},
{"id":4701,"nombre":"Antofagasta Acceso Sur","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-23.69897,"longitud":-70.408712,"ranking":0},
{"id":7996,"nombre":"Toconce","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.263453,"longitud":-68.164264,"ranking":0},
{"id":7997,"nombre":"Peine","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-23.684007,"longitud":-68.059746,"ranking":0},
{"id":9188,"nombre":"Chuquicamata","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.30833,"longitud":-68.918874,"ranking":0},
{"id":7983,"nombre":"Ayquina","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.275193,"longitud":-68.324501,"ranking":0},
{"id":7986,"nombre":"Caspana","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.330693,"longitud":-68.212557,"ranking":0},
{"id":7988,"nombre":"Chiu Chiu","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.342964,"longitud":-68.650105,"ranking":0},
{"id":7991,"nombre":"Maria Elena","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.342392,"longitud":-69.662664,"ranking":0},
{"id":7994,"nombre":"Taltal","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-22.343559,"longitud":-69.66245,"ranking":0},
{"id":7995,"nombre":"Toconao ","localidad":"Antofagasta","provincia":"Región II - Antofagasta","pais":"CHILE","latitud":-23.188944,"longitud":-68.006018,"ranking":0},
{"id":3115,"nombre":"Concepcion","localidad":"Concepción","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.814408,"longitud":-73.021796,"ranking":0},
{"id":3117,"nombre":"Chabas","localidad":"Chabas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.245361,"longitud":-61.359412,"ranking":11},
{"id":3119,"nombre":"Chillan","localidad":"Chillan","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.59701,"longitud":-72.10603,"ranking":0},
{"id":3120,"nombre":"Chañaral","localidad":"Chañaral","provincia":"Región III - Atacama","pais":"CHILE","latitud":-26.34593,"longitud":-70.621339,"ranking":0},
{"id":3129,"nombre":"Iquique","localidad":"Iquique","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-20.221214,"longitud":-70.151592,"ranking":0},
{"id":4531,"nombre":"Iquique Acceso","localidad":"Iquique","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-20.220799,"longitud":-70.13199,"ranking":0},
{"id":3133,"nombre":"Morrison","localidad":"Morrison","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.583670263340565,"longitud":-62.83327570328933,"ranking":0},
{"id":3136,"nombre":"La Serena","localidad":"La Serena","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-29.910973,"longitud":-71.256643,"ranking":3},
{"id":8313,"nombre":"La Serena Acceso","localidad":"La Serena","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-29.909786,"longitud":-71.255444,"ranking":0},
{"id":3140,"nombre":"Osorno","localidad":"Osorno","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-40.573334200,"longitud":-73.125855600,"ranking":122},
{"id":7490,"nombre":"Entre Lagos Acceso","localidad":"Osorno","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-40.6831879,"longitud":-72.6100714,"ranking":0},
{"id":7491,"nombre":"Freire Acceso","localidad":"Osorno","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-38.9375743,"longitud":-72.6218174,"ranking":0},
{"id":8330,"nombre":"Puerto Octay","localidad":"Osorno","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-40.973663483033825,"longitud":-72.8837834542944,"ranking":0},
{"id":3142,"nombre":"Puerto Montt","localidad":"Puerto Montt","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.475274400,"longitud":-72.948038600,"ranking":120},
{"id":3144,"nombre":"Puerto Varas","localidad":"Puerto Varas","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.31765997592217,"longitud":-72.99203028463296,"ranking":20},
{"id":9914,"nombre":"Puerto Varas - Acceso Sur","localidad":"Puerto Varas","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.320493800,"longitud":-73.007004300,"ranking":0},
{"id":3146,"nombre":"Reñaca Alto","localidad":"Reñaca Alto","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.008486,"longitud":-71.50033,"ranking":0},
{"id":3148,"nombre":"Tupungato","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.36605713903383,"longitud":-69.14783581592575,"ranking":0},
{"id":4506,"nombre":"Vidal","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4507,"nombre":"Nofal","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4508,"nombre":"Alvarado","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4520,"nombre":"Lugones","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4521,"nombre":"La Gloria","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4524,"nombre":"Sergi","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4537,"nombre":"Vila","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4550,"nombre":"Los Transformadores","localidad":"Tupungato","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3150,"nombre":"Villarrica","localidad":"Villarica","provincia":"Zona Norte Chile","pais":"CHILE","latitud":-39.28276112591022,"longitud":-72.23008356134066,"ranking":0},
{"id":3152,"nombre":"Holmberg","localidad":"Holmberg","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.2029794,"longitud":-64.4362002,"ranking":11},
{"id":3157,"nombre":"Los Pozos","localidad":"Los Pozos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.519318969607472,"longitud":-64.25224828726643,"ranking":5},
{"id":9496,"nombre":"Los Pozos Acceso","localidad":"Los Pozos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.5190918,"longitud":-64.2524373,"ranking":0},
{"id":3168,"nombre":"Portezuelo","localidad":"Portezuelo","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8026,"nombre":"Portezuelo Acceso","localidad":"Portezuelo","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3169,"nombre":"San Isidro","localidad":"San Isidro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.4953858,"longitud":-58.5531982,"ranking":0},
{"id":3170,"nombre":"San Ramon","localidad":"San Ramon","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.60465299410729,"longitud":-66.93242849317267,"ranking":0},
{"id":3171,"nombre":"San Martin","localidad":"San Martin","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3174,"nombre":"Sancti Spiritu","localidad":"Sancti Spiritu","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3179,"nombre":"Aldea Beleiro","localidad":"Aldea Beleiro","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.552484857381586,"longitud":-71.52330418865239,"ranking":0},
{"id":3180,"nombre":"Alto Rio Mayo","localidad":"Alto Rio Mayo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.56265824812349,"longitud":-71.29665525111113,"ranking":0},
{"id":3904,"nombre":"Cerro Dragon","localidad":"Cerro Dragon","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.740816779047705,"longitud":-68.28893183140401,"ranking":0},
{"id":3178,"nombre":"Colhuehuapi","localidad":"Colhuehuapi","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3181,"nombre":"Balmaceda","localidad":"Balmaceda","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-45.909527,"longitud":-71.69776,"ranking":0},
{"id":3433,"nombre":"Gobernador Costa","localidad":"Costa","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.046554,"longitud":-70.599339,"ranking":92},
{"id":3183,"nombre":"Coyhaique","localidad":"Coyhaique","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-45.57422,"longitud":-72.075447,"ranking":11},
{"id":3184,"nombre":"Coyhaique Alto","localidad":"Coyhaique Alto","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-45.480402,"longitud":-71.604176,"ranking":0},
{"id":3186,"nombre":"Cruce 3 / 26","localidad":"Cruce 3 / 26","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3187,"nombre":"Facundo Acceso","localidad":"Facundo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.310438,"longitud":-69.978122,"ranking":0},
{"id":8402,"nombre":"Facundo ","localidad":"Facundo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.30867545977274,"longitud":-69.97416375778587,"ranking":0},
{"id":3188,"nombre":"El Condor","localidad":"El Condor","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3189,"nombre":"El Trebol","localidad":"El Trebol","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3190,"nombre":"Guarnicion Militar","localidad":"Guarnicion Militar","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.591201500529834,"longitud":-69.00814648460734,"ranking":0},
{"id":3191,"nombre":"Hito 45","localidad":"Hito 45","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.52873037376334,"longitud":-71.56580862894849,"ranking":0},
{"id":3905,"nombre":"Hito 50","localidad":"Hito 50","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.91546463371747,"longitud":-71.64438786524642,"ranking":0},
{"id":3192,"nombre":"Jose de San Martin","localidad":"Jose de San Martin","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.049701,"longitud":-70.473682,"ranking":94},
{"id":4187,"nombre":"Jose de San Martin Acceso","localidad":"Jose de San Martin","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3193,"nombre":"La Laurita","localidad":"La Laurita","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.064153300,"longitud":-70.552825900,"ranking":0},
{"id":3194,"nombre":"Lago Blanco","localidad":"Lago Blanco","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.937446672768374,"longitud":-71.26872317376505,"ranking":0},
{"id":3195,"nombre":"Las Pulgas","localidad":"Las Pulgas","provincia":"Chubut","pais":"ARGENTINA","latitud":-38.956659800,"longitud":-68.066246500,"ranking":0},
{"id":3207,"nombre":"Los Tamariscos","localidad":"Los Tamariscos","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.0121612,"longitud":-70.0317712,"ranking":29},
{"id":3196,"nombre":"Manantiales","localidad":"Manantiales","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3197,"nombre":"Nueva Lubecka","localidad":"Nueva Lubecka","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3198,"nombre":"Pampa del Castillo","localidad":"Pampa del Castillo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.867136771766724,"longitud":-68.12526208356108,"ranking":0},
{"id":3199,"nombre":"Parada 163","localidad":"Parada 163","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3200,"nombre":"Putrachoique","localidad":"Putrachoique","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4519,"nombre":"Ricardo Rojas","localidad":"Ricardo Rojas","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.57570783213395,"longitud":-71.03508732550407,"ranking":0},
{"id":3185,"nombre":"Cruce 20 / 22","localidad":"Rio Mayo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.7061617,"longitud":-70.26097299999999,"ranking":0},
{"id":3202,"nombre":"Rio Mayo","localidad":"Rio Mayo","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.69082,"longitud":-70.254006,"ranking":71},
{"id":3203,"nombre":"Rio Senguer","localidad":"Rio Senguer","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.0410789,"longitud":-70.82398549999999,"ranking":3},
{"id":5439,"nombre":"Estancia La Begonia","localidad":"Rio Senguer","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3204,"nombre":"Sarmiento","localidad":"Sarmiento","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.581848,"longitud":-69.0696502,"ranking":589},
{"id":5455,"nombre":"Base Esap Sarmiento","localidad":"Sarmiento","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3205,"nombre":"Tecka","localidad":"Tecka","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.491256,"longitud":-70.809197,"ranking":48},
{"id":4186,"nombre":"Tecka Acceso","localidad":"Tecka","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.50823814706861,"longitud":-70.80045029768537,"ranking":0},
{"id":3206,"nombre":"Valle Hermoso","localidad":"Valle Hermoso","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.7442547,"longitud":-68.50537419999999,"ranking":5},
{"id":3208,"nombre":"Yacuiba","localidad":"Yacuiba","provincia":"Yacuiba","pais":"BOLIVIA","latitud":-22.008992024619584,"longitud":-63.672141541197554,"ranking":0},
{"id":4058,"nombre":"Yacuiba Acceso","localidad":"Yacuiba","provincia":"Yacuiba","pais":"BOLIVIA","latitud":-22.009254742733493,"longitud":-63.671909778385995,"ranking":0},
{"id":3209,"nombre":"Palmar Grande","localidad":"Palmar Grande","provincia":"Palmar Grande","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4052,"nombre":"Palmar Grande Acceso","localidad":"Palmar Grande","provincia":"Palmar Grande","pais":"BOLIVIA","latitud":-21.90350175833276,"longitud":-63.63422272580508,"ranking":0},
{"id":3214,"nombre":"Abapo","localidad":"Abapo","provincia":"Abapo","pais":"BOLIVIA","latitud":-18.907425855355314,"longitud":-63.401072578598644,"ranking":0},
{"id":3213,"nombre":"Charagua","localidad":"Charagua","provincia":"Charagua","pais":"BOLIVIA","latitud":-19.79333816661731,"longitud":-63.19997238738204,"ranking":0},
{"id":3215,"nombre":"Santa Cruz de la Sierra (Bolivia)","localidad":"Santa Cruz de la Sierra","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-17.7909830,"longitud":-63.1627253,"ranking":62},
{"id":4127,"nombre":"Boyuibe Acceso","localidad":"Santa Cruz de la Sierra","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-20.454291714293003,"longitud":-63.27829562482318,"ranking":0},
{"id":4129,"nombre":"Camiri Acceso","localidad":"Santa Cruz de la Sierra","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-20.021972886536616,"longitud":-63.53174094100387,"ranking":0},
{"id":14645,"nombre":"Palma Grande","localidad":"Santa Cruz de la Sierra","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-17.7195646,"longitud":-63.21749560000001,"ranking":0},
{"id":3211,"nombre":"Boyuibe","localidad":"Boyuibe","provincia":"Boyuibe","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":3212,"nombre":"Camiri","localidad":"Camiri","provincia":"Camiri","pais":"BOLIVIA","latitud":-20.02042080689523,"longitud":-63.532526547986556,"ranking":0},
{"id":3228,"nombre":"Ayacucho","localidad":"Ayacucho","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.160694,"longitud":-58.486395,"ranking":238},
{"id":9138,"nombre":"Ayacucho Acceso","localidad":"Ayacucho","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.12694458330991,"longitud":-58.55108013198881,"ranking":0},
{"id":3245,"nombre":"Marcos Paz","localidad":"Marcos Paz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9832,"nombre":"Crotto","localidad":"Crotto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3250,"nombre":"Mar Chiquita Acceso","localidad":"Mar Chiquita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3903,"nombre":"Mar Chiquita","localidad":"Mar Chiquita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.733724900,"longitud":-57.445798500,"ranking":0},
{"id":9831,"nombre":"Nahuel Ruca Acceso","localidad":"Mar Chiquita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.5956210378492,"longitud":-57.526465143836596,"ranking":0},
{"id":3252,"nombre":"Udaquiola Acceso","localidad":"Udaquiola","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.577108,"longitud":-58.5634993,"ranking":0},
{"id":7613,"nombre":"Udaquiola","localidad":"Udaquiola","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3253,"nombre":"Langueyu Acceso","localidad":"Langueyu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6825823253743,"longitud":-58.57506688681965,"ranking":0},
{"id":3254,"nombre":"Solanet Acceso","localidad":"Solanet","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.857785,"longitud":-58.54929,"ranking":0},
{"id":7609,"nombre":"Solanet","localidad":"Solanet","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3255,"nombre":"Veronica","localidad":"Veronica","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.386625,"longitud":-57.33679,"ranking":236},
{"id":3265,"nombre":"Glew","localidad":"Glew","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.8306965,"longitud":-58.3798133,"ranking":12},
{"id":3459,"nombre":"San Lorenzo","localidad":"San Lorenzo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.7479079,"longitud":-60.7343420,"ranking":0},
{"id":8097,"nombre":"Aldao","localidad":"San Lorenzo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3275,"nombre":"Clorinda","localidad":"Clorinda","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.2940717,"longitud":-57.721777,"ranking":911},
{"id":3276,"nombre":"Medanos","localidad":"Medanos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.8217133,"longitud":-62.7012062,"ranking":38},
{"id":3878,"nombre":"Medanos Acceso","localidad":"Medanos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.823022740230485,"longitud":-62.70166601128957,"ranking":0},
{"id":3277,"nombre":"Anzoategui","localidad":"Anzoategui","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.960607250690806,"longitud":-63.870810646235014,"ranking":0},
{"id":3278,"nombre":"Sauce Blanco","localidad":"Sauce Blanco","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.050219600,"longitud":-64.673938800,"ranking":2},
{"id":3279,"nombre":"Villa Guillermina","localidad":"Villa Guillermina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.244414916006384,"longitud":-59.451470696874715,"ranking":0},
{"id":3281,"nombre":"Malena","localidad":"Malena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.48933167303915,"longitud":-64.43233103359495,"ranking":0},
{"id":8091,"nombre":"Malena Acceso","localidad":"Malena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.485006158279425,"longitud":-64.41906577043744,"ranking":0},
{"id":8728,"nombre":"Cruce Malena","localidad":"Malena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.4882701,"longitud":-64.4190564,"ranking":4},
{"id":3298,"nombre":"Ticino","localidad":"Ticino","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.69045325055471,"longitud":-63.437907962011366,"ranking":0},
{"id":3302,"nombre":"9 de Julio","localidad":"9 de Julio","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.841666,"longitud":-58.829277,"ranking":14},
{"id":4096,"nombre":"9 de Julio Acceso","localidad":"9 de Julio","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.8443112,"longitud":-58.82185629999999,"ranking":0},
{"id":3303,"nombre":"Laguna Brava","localidad":"Laguna Brava","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.489583828605458,"longitud":-58.72036723628619,"ranking":0},
{"id":2327,"nombre":"Carpinteria Acceso","localidad":"Carpinteria","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.427290951001794,"longitud":-65.0080414056971,"ranking":0},
{"id":3063,"nombre":"Carpinteria","localidad":"Carpinteria","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.415546,"longitud":-65.011637,"ranking":0},
{"id":3305,"nombre":"Monteverico","localidad":"Monteverico","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3310,"nombre":"Puerto Deseado","localidad":"Puerto deseado","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.746707,"longitud":-65.884432,"ranking":259},
{"id":7606,"nombre":"Cañadon Seco","localidad":"Puerto deseado","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3312,"nombre":"Alto Pencoso","localidad":"Alto Pencoso","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.432360347014225,"longitud":-66.92845257628235,"ranking":0},
{"id":3673,"nombre":"Alto Pencoso Acceso","localidad":"Alto Pencoso","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.43660857796484,"longitud":-66.92471105504248,"ranking":0},
{"id":12402,"nombre":"Barrio Santa Clara","localidad":"Coronel Bogado","provincia":"Itapua","pais":"Paraguay","latitud":-27.156076,"longitud":-56.242261,"ranking":0},
{"id":3316,"nombre":"Coronel Bogado","localidad":"Coronel Bogado","provincia":"Itapua","pais":"Paraguay","latitud":-27.155629,"longitud":-56.240533,"ranking":70},
{"id":3319,"nombre":"Villa Ciudad America","localidad":"Villa Ciudad America","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.791936725508066,"longitud":-64.50870978199238,"ranking":0},
{"id":10802,"nombre":"Villa Ciudad America Acceso","localidad":"Villa Ciudad America","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.793419,"longitud":-64.510472,"ranking":0},
{"id":3335,"nombre":"Paso de las Carretas","localidad":"Paso de las Carretas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.014567,"longitud":-69.017354,"ranking":18},
{"id":3337,"nombre":"Rio Salado","localidad":"Rio Salado","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.2207616,"longitud":-69.6668653,"ranking":6},
{"id":3338,"nombre":"Puentes","localidad":"Puentes","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3339,"nombre":"Fortes","localidad":"Fortes","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3705,"nombre":"Agrelo","localidad":"Agrelo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3340,"nombre":"Anchoris","localidad":"Anchoris","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.32781212344905,"longitud":-68.9187531400865,"ranking":0},
{"id":3342,"nombre":"Bombal","localidad":"Bombal","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3343,"nombre":"Chilecito","localidad":"Chilecito","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3347,"nombre":"El Zampal","localidad":"El Zampal","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3349,"nombre":"Ferrer","localidad":"Ferrer","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.8124154,"longitud":-67.929223,"ranking":0},
{"id":2594,"nombre":"Goudge Acceso","localidad":"Goudge","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3350,"nombre":"Goudge","localidad":"Goudge","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.678695,"longitud":-68.1314379,"ranking":0},
{"id":3353,"nombre":"Los Molles","localidad":"Los Molles","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.16585,"longitud":-69.938884,"ranking":0},
{"id":3352,"nombre":"Lara","localidad":"Lara","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3351,"nombre":"La Consulta","localidad":"La Consulta","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.73652124809195,"longitud":-69.11774974475135,"ranking":0},
{"id":3354,"nombre":"Los Sauces","localidad":"Los Sauces","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3355,"nombre":"Los Tableros","localidad":"Los Tableros","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.8632204,"longitud":-67.8196604,"ranking":0},
{"id":3357,"nombre":"Negro Quemado","localidad":"Negro Quemado","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.805611544936866,"longitud":-67.94317712520605,"ranking":0},
{"id":3359,"nombre":"Medrano","localidad":"Medrano","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.179687,"longitud":-68.6141492,"ranking":0},
{"id":3362,"nombre":"Pichana","localidad":"Pichana","provincia":"Mendoza","pais":"ARGENTINA","latitud":-23.3143818,"longitud":-64.2258121,"ranking":0},
{"id":3363,"nombre":"Pobre Diablo","localidad":"Pobre Diablo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.663899414678184,"longitud":-68.35666165296286,"ranking":0},
{"id":3365,"nombre":"Resolana","localidad":"Resolana","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.53625687371358,"longitud":-68.09430647185916,"ranking":0},
{"id":3366,"nombre":"Salto de las Rosas","localidad":"Salto las Rosas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.7262484,"longitud":-68.2312051,"ranking":15},
{"id":3367,"nombre":"Sol de Mayo","localidad":"Sol de Mayo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.89483100139938,"longitud":-68.82910040353468,"ranking":2},
{"id":3368,"nombre":"El Tropezon","localidad":"El Tropezon","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3369,"nombre":"Zamora","localidad":"Zamora","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.88826533148188,"longitud":-68.84818255840447,"ranking":0},
{"id":3370,"nombre":"Puente Colorado","localidad":"Puente Colorado","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.60880604416021,"longitud":-68.46490480200154,"ranking":3},
{"id":3371,"nombre":"Diagonal","localidad":"Diagonal","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3372,"nombre":"Vuelta de Rodrigo","localidad":"Vuelta de Rodrigo","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.6317889,"longitud":-68.2460728,"ranking":0},
{"id":3373,"nombre":"Victor Weinert","localidad":"Victor Weinert","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.9835884,"longitud":-68.8599048,"ranking":17},
{"id":3842,"nombre":"Victor Weinert Acceso","localidad":"Victor Weinert","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4136,"nombre":"Florianopolis","localidad":"Florianópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.4520307,"longitud":-48.4559812,"ranking":279},
{"id":4137,"nombre":"Florianopolis Acceso - Shopping Itaguazú","localidad":"Florianópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15499,"nombre":"Aeroporto de Florianopolis","localidad":"Florianópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.6765485,"longitud":-48.54414509999999,"ranking":2},
{"id":15516,"nombre":"Hoteis do Centro Florianopolis","localidad":"Florianópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.5921584,"longitud":-48.54916,"ranking":0},
{"id":15517,"nombre":"Hoteis de Praias Florianopolis","localidad":"Florianópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.6069407,"longitud":-48.4618235,"ranking":0},
{"id":3702,"nombre":"El Chalten","localidad":"El Chalten","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.333694,"longitud":-72.883122,"ranking":1268},
{"id":10768,"nombre":"Hoteles El Chalten","localidad":"El Chalten","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10769,"nombre":"Patagonia Dreams - Av. San Martín 26 Local 2","localidad":"El Chalten","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3386,"nombre":"El Nevado","localidad":"El Nevado","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":3390,"nombre":"Padilla","localidad":"Padilla","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3391,"nombre":"Finca India","localidad":"Finca India","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.8549742,"longitud":-67.7884326,"ranking":1},
{"id":3392,"nombre":"Finca Sanchez","localidad":"Finca Sanchez","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3393,"nombre":"Garden","localidad":"Garden","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3394,"nombre":"Jaime Prats","localidad":"Jaime Prats","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.9105496,"longitud":-67.81829560000001,"ranking":10},
{"id":3395,"nombre":"La Vasconia","localidad":"La Vasconia","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.95788001204635,"longitud":-68.73861760897222,"ranking":0},
{"id":3409,"nombre":"Puerto Santa Cruz","localidad":"Santa Cruz","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.449669,"longitud":-67.52061,"ranking":129},
{"id":3933,"nombre":"El Salado","localidad":"Santa Cruz","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-37.306317400,"longitud":-70.237956000,"ranking":0},
{"id":3979,"nombre":"Aeropuerto","localidad":"Santa Cruz","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.61210692983468,"longitud":-69.30613189722271,"ranking":0},
{"id":4191,"nombre":"Puerto Santa Cruz Acceso","localidad":"Santa Cruz","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.024312767401675,"longitud":-68.53627518595147,"ranking":0},
{"id":10900,"nombre":"Puerto La Soledad","localidad":"Santa Cruz","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3425,"nombre":"El Hoyo","localidad":"El Hoyo","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.0651289,"longitud":-71.5229541,"ranking":36},
{"id":3426,"nombre":"Dolavon","localidad":"Dolavon","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.2985571,"longitud":-65.7137668,"ranking":4},
{"id":8952,"nombre":"Mendiolaza","localidad":"Mendiolaza","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.2653038,"longitud":-64.303837,"ranking":5},
{"id":8956,"nombre":"Talar - Mendiolaza","localidad":"Mendiolaza","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.25434044526225,"longitud":-64.26662921905518,"ranking":3},
{"id":4714,"nombre":"Juan Jose Paso Acceso","localidad":"Juan Jose Paso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.86459914888607,"longitud":-62.30354602878737,"ranking":0},
{"id":7754,"nombre":"Juan Jose Paso","localidad":"Juan Jose Paso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.8598396,"longitud":-62.0674324,"ranking":16},
{"id":3431,"nombre":"Epuyen","localidad":"Epuyen","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.2292244,"longitud":-71.3665545,"ranking":39},
{"id":3432,"nombre":"Gaiman","localidad":"Gaiman","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.291245,"longitud":-65.49809,"ranking":2},
{"id":7909,"nombre":"Ameghino","localidad":"Gaiman","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.690838011745086,"longitud":-66.4438263930833,"ranking":0},
{"id":3434,"nombre":"Las Chapas","localidad":"Las Chapas","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.608363700,"longitud":-66.536293000,"ranking":0},
{"id":3435,"nombre":"Las Plumas","localidad":"Las Plumas","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.7205013,"longitud":-67.2876978,"ranking":8},
{"id":3436,"nombre":"Leleque","localidad":"Leleque","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.375439,"longitud":-71.112213,"ranking":0},
{"id":3437,"nombre":"Los Altares","localidad":"Los Altares","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.885104000,"longitud":-68.412873700,"ranking":1},
{"id":3438,"nombre":"Paso de Indios","localidad":"Paso de Indios","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.861484,"longitud":-69.0503597,"ranking":7},
{"id":3439,"nombre":"Chacharramendi","localidad":"Chacharramendi","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.33378,"longitud":-65.65533,"ranking":4},
{"id":3440,"nombre":"Chelforo","localidad":"Chelforo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.086769800,"longitud":-66.522772000,"ranking":2},
{"id":9185,"nombre":"Chelforo Acceso","localidad":"Chelforo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.08718173302799,"longitud":-66.5189203648892,"ranking":0},
{"id":3441,"nombre":"Chichinales","localidad":"Chichinales","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.116701300,"longitud":-66.929354700,"ranking":5},
{"id":3443,"nombre":"El Foyel","localidad":"El Foyel","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.6548659,"longitud":-71.4600617,"ranking":4},
{"id":3445,"nombre":"Los Repollos","localidad":"Los Repollos","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.854602900,"longitud":-71.431517600,"ranking":0},
{"id":3446,"nombre":"Rio Villegas","localidad":"Rio Villegas","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.586652,"longitud":-71.491749,"ranking":46},
{"id":3447,"nombre":"Villa Mascardi","localidad":"Villa Mascardi","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.349429,"longitud":-71.509256,"ranking":0},
{"id":3451,"nombre":"Vieytes","localidad":"Vieytes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.267639,"longitud":-57.576048,"ranking":5},
{"id":3452,"nombre":"Vieytes Acceso","localidad":"Vieytes","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.27416019620486,"longitud":-57.56556057486439,"ranking":0},
{"id":3453,"nombre":"Punta Indio","localidad":"Punta Indio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.27312455426933,"longitud":-57.24785153967173,"ranking":0},
{"id":3458,"nombre":"El Cadillal","localidad":"El Cadillal","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.63528469267251,"longitud":-65.20702615242215,"ranking":0},
{"id":3461,"nombre":"Zanjon","localidad":"Zanjon","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3467,"nombre":"Zavalla","localidad":"Zavalla","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3473,"nombre":"Romang","localidad":"Romang","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.4978858,"longitud":-59.74761700000001,"ranking":2},
{"id":8839,"nombre":"Romang Acceso","localidad":"Romang","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.498973576053313,"longitud":-59.762585215364886,"ranking":0},
{"id":3474,"nombre":"Pujato","localidad":"Pujato","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.0170136,"longitud":-61.0437108,"ranking":0},
{"id":3476,"nombre":"Progreso","localidad":"Progreso","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3480,"nombre":"Perez","localidad":"Perez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3486,"nombre":"Moises Ville","localidad":"Moises Ville","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3487,"nombre":"Maria Luisa","localidad":"Maria Luisa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3489,"nombre":"Llambi Campbell","localidad":"Llambi Campbell","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.188625368434618,"longitud":-60.743429948549625,"ranking":0},
{"id":3491,"nombre":"La Pelada","localidad":"La Pelada","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3492,"nombre":"Gobernador Crespo","localidad":"Gobernador Crespo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.364011,"longitud":-60.397316,"ranking":27},
{"id":4177,"nombre":"Gobernador Crespo Acceso","localidad":"Gobernador Crespo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.370345517270906,"longitud":-60.40170969495346,"ranking":0},
{"id":3493,"nombre":"Franck","localidad":"Franck","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.586696221542006,"longitud":-60.93831680848869,"ranking":0},
{"id":8068,"nombre":"Franck Peaje","localidad":"Franck","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.6365588,"longitud":-60.9742126,"ranking":0},
{"id":14592,"nombre":"Franck Acceso","localidad":"Franck","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.5876906,"longitud":-60.938894,"ranking":0},
{"id":9746,"nombre":"Felicia","localidad":"Felicia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.243613283031024,"longitud":-61.21209791938274,"ranking":0},
{"id":3496,"nombre":"Colon","localidad":"Colon","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3498,"nombre":"Baravera","localidad":"Baravera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3500,"nombre":"Rio Turbio","localidad":"Rio Turbio","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.613797,"longitud":-69.227485,"ranking":50},
{"id":3502,"nombre":"Metan Viejo","localidad":"Metan Viejo","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3503,"nombre":"Juramento","localidad":"Juramento","provincia":"Salta","pais":"ARGENTINA","latitud":-25.1321668293169,"longitud":-65.01584549690226,"ranking":0},
{"id":3504,"nombre":"El Tala","localidad":"El Tala","provincia":"Salta","pais":"ARGENTINA","latitud":-26.1101820781267,"longitud":-65.27665913055358,"ranking":0},
{"id":3506,"nombre":"Tramo Primero","localidad":"Tramo Primero","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.032751200,"longitud":-71.300926200,"ranking":0},
{"id":3507,"nombre":"General Frias","localidad":"General Frias","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.691311900,"longitud":-63.479690600,"ranking":0},
{"id":3512,"nombre":"Ruca Malen","localidad":"Ruca Malen","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.699215,"longitud":-71.646726,"ranking":0},
{"id":3513,"nombre":"Refugio Limay","localidad":"Refugio Limay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.0555658,"longitud":-71.1486009,"ranking":0},
{"id":3514,"nombre":"Mahuida","localidad":"Mahuida","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-37.131308300,"longitud":-69.792709400,"ranking":0},
{"id":3515,"nombre":"Puente Limay","localidad":"Puente Limay","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3516,"nombre":"Padre Buodo","localidad":"Padre Buodo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.308045,"longitud":-64.287936,"ranking":5},
{"id":4190,"nombre":"Padre Buodo Acceso","localidad":"Padre Buodo","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.312441799867095,"longitud":-64.28810323622808,"ranking":17},
{"id":3517,"nombre":"Los Cohiues","localidad":"Los Cohiues","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.185113800,"longitud":-71.379718800,"ranking":0},
{"id":3519,"nombre":"Lago Hermoso","localidad":"Lago Hermoso","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.357112,"longitud":-71.448694,"ranking":0},
{"id":3520,"nombre":"Lago Gutierrez","localidad":"Lago Gutierrez","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.948336,"longitud":-71.537028,"ranking":0},
{"id":3521,"nombre":"Lago Espejo","localidad":"Lago Espejo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.650366,"longitud":-71.70596,"ranking":0},
{"id":3522,"nombre":"Algarrobo Acceso","localidad":"Algarrobo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.8708129,"longitud":-63.1095135,"ranking":12},
{"id":3526,"nombre":"Arroyo Pescado","localidad":"Arroyo Pescado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3532,"nombre":"La Veranada","localidad":"La Veranada","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.504206500,"longitud":-71.466751100,"ranking":0},
{"id":3533,"nombre":"Ingeniero Jacobacci","localidad":"Jacobacci","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.3296916,"longitud":-69.54658859999999,"ranking":720},
{"id":15353,"nombre":"Empalme KM 648","localidad":"Jacobacci","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.35219545373601,"longitud":-69.7260112288352,"ranking":0},
{"id":3534,"nombre":"Gruta de la Virgen","localidad":"Gruta de la Virgen","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.964372500,"longitud":-71.537003500,"ranking":0},
{"id":3535,"nombre":"Dina Huapi","localidad":"Dina Huapi","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.0555658,"longitud":-71.1486009,"ranking":5},
{"id":9679,"nombre":"Estancia San Ramon","localidad":"Dina Huapi","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":3},
{"id":9680,"nombre":"Perito Moreno","localidad":"Dina Huapi","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.0761937,"longitud":-70.97176,"ranking":0},
{"id":9681,"nombre":"Planta Invap","localidad":"Dina Huapi","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.07363558239306,"longitud":-71.14900021903257,"ranking":0},
{"id":3539,"nombre":"Jose Leon Suarez","localidad":"Jose Leon Suarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2009,"nombre":"Buratovich","localidad":"Mayor Buratovich","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.267898665541345,"longitud":-62.599935716212414,"ranking":0},
{"id":14473,"nombre":"Buratovich Acceso","localidad":"Mayor Buratovich","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.2591692,"longitud":-62.5969693,"ranking":0},
{"id":3548,"nombre":"Villa Bosch","localidad":"Villa Bosch","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3550,"nombre":"El Maiten","localidad":"El Maiten","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.054667437104015,"longitud":-71.16570631148032,"ranking":0},
{"id":3738,"nombre":"Florentino Ameghino","localidad":"Florentino Ameghino","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.79999836586259,"longitud":-65.74987128725557,"ranking":0},
{"id":3555,"nombre":"Azara","localidad":"Azara","provincia":"Misiones","pais":"ARGENTINA","latitud":-28.0606022,"longitud":-55.6730917,"ranking":2},
{"id":3556,"nombre":"Garhuape","localidad":"Garhuape","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.820413300,"longitud":-54.956257900,"ranking":0},
{"id":9068,"nombre":"Garhuape Acceso","localidad":"Garhuape","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3558,"nombre":"San Javier","localidad":"San Javier","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.8645182,"longitud":-55.1377634,"ranking":5},
{"id":7472,"nombre":"Itacaruare","localidad":"San Javier","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.870995349177228,"longitud":-55.262960695990735,"ranking":0},
{"id":8590,"nombre":"Itacaruare Acceso","localidad":"San Javier","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.838851237992262,"longitud":-55.218510172204425,"ranking":0},
{"id":8591,"nombre":"Mojon Grande Acceso","localidad":"San Javier","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.714293624535813,"longitud":-55.155869672391646,"ranking":0},
{"id":3559,"nombre":"San Pedro","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.624264,"longitud":-54.120962,"ranking":71},
{"id":5664,"nombre":"Sarandi","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7468,"nombre":"Arroyo Liso","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8555,"nombre":"Paraiso","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8556,"nombre":"Fracran","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8592,"nombre":"Mondori","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.362931200785795,"longitud":-54.08482761758063,"ranking":0},
{"id":8593,"nombre":"Pozo Azul","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8594,"nombre":"Puente Alto","localidad":"San Pedro","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.62427865458625,"longitud":-54.12100174882988,"ranking":0},
{"id":4424,"nombre":"Bodegas y Viñedos","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10921,"nombre":"Caminos del Vino y Olivos Maipu - HD (Mañana)","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10740,"nombre":"Caminos del Vino Maipu - Full Day","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10741,"nombre":"Caminos del Vino Maipu - Half Day (Mañana)","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10742,"nombre":"Caminos del Vino Maipu - Half Day (Tarde)","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10918,"nombre":"Caminos del Vino y Olivos Maipu - FD Vigil","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10919,"nombre":"Caminos del Vino y Olivos Maipu - FD Zuccardi  ","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10920,"nombre":"Caminos del Vino y Olivos Maipu - Half Day (Tarde)","localidad":"Maipu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4425,"nombre":"Alta Montaña","localidad":"Las Heras","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4426,"nombre":"Villavicencio","localidad":"Las Heras","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3577,"nombre":"Villa Berthet","localidad":"Villa Berthet","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.286617064906586,"longitud":-60.41547931272006,"ranking":11},
{"id":3580,"nombre":"La Salina","localidad":"La Salina","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3582,"nombre":"Languiñeo","localidad":"Languiñeo","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3584,"nombre":"Pampa de Agnia","localidad":"Pampa de Agnia","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3585,"nombre":"Pampa Salamanca","localidad":"Pampa Salamanca","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.36362962080224,"longitud":-67.37824041821145,"ranking":0},
{"id":4505,"nombre":"Pico Salamanca","localidad":"Pampa Salamanca","provincia":"Chubut","pais":"ARGENTINA","latitud":-45.36424831534188,"longitud":-67.37978754522283,"ranking":0},
{"id":3586,"nombre":"Arroyo Verde","localidad":"Arroyo Verde","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.92462215671559,"longitud":-66.1961332104821,"ranking":0},
{"id":3587,"nombre":"Villarino","localidad":"Villarino","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3589,"nombre":"Villa Maipu","localidad":"Villa Maipu","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.916088300,"longitud":-68.180848600,"ranking":0},
{"id":3590,"nombre":"Villa Llanquin","localidad":"Villa Llanquin","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.894647,"longitud":-71.040269,"ranking":0},
{"id":3591,"nombre":"Stroeder","localidad":"Stroeder","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-40.187053,"longitud":-62.62253,"ranking":0},
{"id":14472,"nombre":"Stroeder Acceso","localidad":"Stroeder","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-40.1902686,"longitud":-62.6171063,"ranking":2},
{"id":3592,"nombre":"Teniente Origone","localidad":"Teniente Origone","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.0643624,"longitud":-62.5596095,"ranking":0},
{"id":3593,"nombre":"Trevelin","localidad":"Trevelin","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.07870104037454,"longitud":-71.46382787466459,"ranking":0},
{"id":5457,"nombre":"Trevelin Paso Frontera","localidad":"Trevelin","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.17421150859671,"longitud":-71.75307021534071,"ranking":0},
{"id":3737,"nombre":"Barda del Medio","localidad":"Barda del Medio","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.727744000,"longitud":-68.143563900,"ranking":33},
{"id":3595,"nombre":"La Adela","localidad":"La Adela","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8532,"nombre":"La Adela Acceso","localidad":"La Adela","provincia":"La Pampa","pais":"ARGENTINA","latitud":-38.970286514601455,"longitud":-64.0754273706566,"ranking":0},
{"id":3597,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.840120900,"longitud":-71.534160400,"ranking":0},
{"id":3598,"nombre":"Purranque","localidad":"Purranque","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.905647600,"longitud":-73.150867200,"ranking":0},
{"id":3599,"nombre":"Villa Cacique","localidad":"Villa Cacique","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3600,"nombre":"Villa Madero","localidad":"Villa Madero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3217,"nombre":"Villalonga","localidad":"Villalonga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.916445,"longitud":-62.61508,"ranking":0},
{"id":3601,"nombre":"Villalonga Acceso","localidad":"Villalonga","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.88710104240783,"longitud":-62.663912773132324,"ranking":6},
{"id":3603,"nombre":"Camarones Acceso","localidad":"Camarones","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.79354454354584,"longitud":-65.72063864441647,"ranking":0},
{"id":4504,"nombre":"Camarones","localidad":"Camarones","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.79538520425999,"longitud":-65.70919875751356,"ranking":0},
{"id":3604,"nombre":"Las Salinas","localidad":"Las Salinas","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3605,"nombre":"Caviahue","localidad":"Caviahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.86540155567287,"longitud":-71.04972124099731,"ranking":0},
{"id":15459,"nombre":"Hoteles Caviahue","localidad":"Caviahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.87039499999999,"longitud":-71.051681,"ranking":0},
{"id":1983,"nombre":"Hoteles Copahue","localidad":"Copahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.8180543,"longitud":-71.0962436,"ranking":0},
{"id":3606,"nombre":"Copahue","localidad":"Copahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.8172114,"longitud":-71.0977834,"ranking":0},
{"id":15460,"nombre":"Galeria Comercial Copahue - Local 4","localidad":"Copahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15462,"nombre":"Termas de Copahue","localidad":"Copahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3607,"nombre":"Las Lajas","localidad":"Las Lajas","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.905918,"longitud":-70.068722,"ranking":0},
{"id":9969,"nombre":"Aeropuerto de Chapelco - Aviador Carlos Campos","localidad":"Chapelco","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.086789200,"longitud":-71.135766500,"ranking":3},
{"id":10124,"nombre":"Cerro Chapelco Acceso","localidad":"Chapelco","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.153949300,"longitud":-71.337919200,"ranking":0},
{"id":3611,"nombre":"Aguada Florencio","localidad":"Aguada Florencio","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.49818640000001,"longitud":-70.2764359,"ranking":0},
{"id":3612,"nombre":"Cerro Bandera","localidad":"Cerro Bandera","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.959342100,"longitud":-68.087768600,"ranking":0},
{"id":3613,"nombre":"Challaco","localidad":"Challaco","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3616,"nombre":"Covunco","localidad":"Covunco","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3618,"nombre":"Curarrehue","localidad":"Curarrehue","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-39.3632,"longitud":-71.584199,"ranking":0},
{"id":3633,"nombre":"Monte Aymond","localidad":"Monte Aymond","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3625,"nombre":"San Sebastian Acceso","localidad":"San Sebastian","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3628,"nombre":"Frontera San Sebastian","localidad":"San Sebastian","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3627,"nombre":"Estancia Viamonte","localidad":"Estancia Viamonte","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3630,"nombre":"Punta Arenas","localidad":"Punta Arenas","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-53.143019,"longitud":-70.914768,"ranking":46},
{"id":3637,"nombre":"Tolhuin Acceso","localidad":"Tolhuin","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3638,"nombre":"Kosovo (Aserraderos)","localidad":"Tolhuin","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3978,"nombre":"Tolhuin","localidad":"Tolhuin","provincia":"Tierra Del Fuego","pais":"ARGENTINA","latitud":-54.51047,"longitud":-67.190075,"ranking":8},
{"id":7641,"nombre":"Yerba Buena","localidad":"Yerba Buena","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.8124496,"longitud":-65.2985726,"ranking":0},
{"id":3647,"nombre":"Rincon de los Sauces","localidad":"Rincon de los Sauces","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.393886,"longitud":-68.921742,"ranking":27},
{"id":4781,"nombre":"El Porton","localidad":"Rincon de los Sauces","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.383295,"longitud":-68.924545,"ranking":0},
{"id":3648,"nombre":"VISTA ALEGRE NORTE","localidad":"VISTA ALEGRE NORTE","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3650,"nombre":"Dalmacio Velez Sarsfield","localidad":"Velez Sarsfield","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.6070021,"longitud":-63.57580249999999,"ranking":160},
{"id":3652,"nombre":"Vallecitos","localidad":"Vallecitos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.89506318093649,"longitud":-68.82927048895398,"ranking":0},
{"id":3658,"nombre":"Suco","localidad":"Suco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.43994998047826,"longitud":-64.83103710962854,"ranking":0},
{"id":9143,"nombre":"Suco Acceso","localidad":"Suco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.455821904918295,"longitud":-64.82559611998911,"ranking":0},
{"id":3664,"nombre":"Añelo","localidad":"Añelo","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.289456,"longitud":-68.79039,"ranking":74},
{"id":4668,"nombre":"Tratayen","localidad":"Añelo","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.41883054057728,"longitud":-68.63724096804116,"ranking":0},
{"id":3670,"nombre":"Barrancas","localidad":"Barrancas","provincia":"Neuquén","pais":"ARGENTINA","latitud":-36.8188929,"longitud":-69.9110201,"ranking":0},
{"id":3677,"nombre":"17 de agosto","localidad":"17 de agosto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.90930558785535,"longitud":-62.9362495089974,"ranking":0},
{"id":3679,"nombre":"Pindapoy Acceso","localidad":"Pindapoy","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8051,"nombre":"Pindapoy","localidad":"Pindapoy","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.7488597,"longitud":-55.793263,"ranking":0},
{"id":3680,"nombre":"Correntoso","localidad":"Correntoso","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3681,"nombre":"Costa de Araujo","localidad":"Costa de Araujo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3682,"nombre":"El Carancho Acceso","localidad":"El Carancho","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3687,"nombre":"RINCONADA","localidad":"RINCONADA","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3690,"nombre":"Quetrequen","localidad":"Quetrequen","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4773,"nombre":"Quetrequen Acceso","localidad":"Quetrequen","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.06531002740998,"longitud":-64.52252921032677,"ranking":0},
{"id":3691,"nombre":"Comandante Granville","localidad":"Comandante Granville","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.44431541413951,"longitud":-65.89322729803793,"ranking":0},
{"id":4286,"nombre":"Comandante Granville Acceso","localidad":"Comandante Granville","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3692,"nombre":"Cereales","localidad":"Cereales","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.813575037286284,"longitud":-63.85611237147047,"ranking":0},
{"id":3693,"nombre":"Chamaico","localidad":"Chamaico","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.049774376009914,"longitud":-64.9514874029025,"ranking":0},
{"id":4774,"nombre":"Chamaico Acceso","localidad":"Chamaico","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3696,"nombre":"Chucul","localidad":"Chucul","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0073921,"longitud":-64.166118,"ranking":35},
{"id":8369,"nombre":"Chucul Acceso","localidad":"Chucul","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.3898556,"longitud":-64.7193233,"ranking":0},
{"id":3699,"nombre":"Colpes","localidad":"Colpes","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.058468484588836,"longitud":-66.19773831592265,"ranking":6},
{"id":3700,"nombre":"El Borbollon","localidad":"El Borbollon","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3701,"nombre":"Buta Ranquil","localidad":"Buta Ranquil","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.0509259,"longitud":-69.8695826,"ranking":0},
{"id":3704,"nombre":"Punta de Agua","localidad":"Punta de Agua","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3706,"nombre":"Ugarteche","localidad":"Ugarteche","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.22269260677638,"longitud":-68.89319312788156,"ranking":0},
{"id":3707,"nombre":"Nueva Colonia","localidad":"Nueva Colonia","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3708,"nombre":"Zapata","localidad":"Zapata","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3709,"nombre":"Furno","localidad":"Furno","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3710,"nombre":"Capacho","localidad":"Capacho","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3711,"nombre":"La Cañada","localidad":"La Cañada","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3712,"nombre":"Pareditas","localidad":"Pareditas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.947015,"longitud":-69.077979,"ranking":51},
{"id":9096,"nombre":"Pareditas Acceso","localidad":"Pareditas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.94688340979645,"longitud":-69.07791261575423,"ranking":0},
{"id":3714,"nombre":"25 de Mayo","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":6},
{"id":3715,"nombre":"Santa Rita","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7474,"nombre":"San Francisco","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.377223017318006,"longitud":-54.74548072243336,"ranking":0},
{"id":8533,"nombre":"Aristobulo del Valle Chico","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8540,"nombre":"Colonia Alicia","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8541,"nombre":"Chafariz Acceso","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.378020420350207,"longitud":-54.351835250161564,"ranking":0},
{"id":8542,"nombre":"Colonia Aurora","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.47379998718679,"longitud":-54.51818496848098,"ranking":0},
{"id":8543,"nombre":"Doradito Acceso","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8544,"nombre":"Progreso Acceso","localidad":"25 de Mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3716,"nombre":"Garupa Acceso","localidad":"Garupa","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.453922300,"longitud":-55.861144100,"ranking":0},
{"id":3993,"nombre":"Garupa","localidad":"Garupa","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.446745,"longitud":-55.870835,"ranking":65},
{"id":3718,"nombre":"Bajo Hondo","localidad":"Bajo Hondo","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.68815121826791,"longitud":-66.01408831422012,"ranking":2},
{"id":3719,"nombre":"Bajo Hondo Acceso","localidad":"Bajo Hondo","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.689449567748778,"longitud":-66.01252084873617,"ranking":0},
{"id":3722,"nombre":"Baldecito Acceso","localidad":"Baldecito","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.34446928720116,"longitud":-66.19740873927059,"ranking":0},
{"id":3724,"nombre":"Alto Verde","localidad":"Alto Verde","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3725,"nombre":"Barda del Medio","localidad":"Barda del Medio","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.725548881968884,"longitud":-68.15600054720055,"ranking":0},
{"id":3284,"nombre":"Cochico","localidad":"Cochico","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.708108152387716,"longitud":-67.33591208813883,"ranking":0},
{"id":3728,"nombre":"Lomas Blancas","localidad":"Lomas Blancas","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3730,"nombre":"Pampa","localidad":"Pampa","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.6519315,"longitud":-68.61185069999999,"ranking":0},
{"id":3731,"nombre":"Quirquincho","localidad":"Quirquincho","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3732,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.3424437,"longitud":-65.20737989999999,"ranking":0},
{"id":3733,"nombre":"Vizcacheras","localidad":"Vizcacheras","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.604530844,"longitud":-65.192283364,"ranking":0},
{"id":9155,"nombre":"Las Vizcacheras Acceso","localidad":"Vizcacheras","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3736,"nombre":"Espinillo","localidad":"Espinillo","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.96745311875979,"longitud":-58.54547470758816,"ranking":0},
{"id":3741,"nombre":"Huillapima","localidad":"Huillapima","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.725874651950893,"longitud":-65.97833892062354,"ranking":2},
{"id":3742,"nombre":"Gran Guardia","localidad":"Gran Guardia","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9101,"nombre":"Gran Guardia Acceso","localidad":"Gran Guardia","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.808822044138562,"longitud":-58.87013918729884,"ranking":2},
{"id":3743,"nombre":"La Viña","localidad":"La Viña","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.003482302874026,"longitud":-65.57993946696577,"ranking":0},
{"id":3745,"nombre":"La Mendieta","localidad":"La Mendieta","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.317130793721255,"longitud":-64.96837475398995,"ranking":0},
{"id":3746,"nombre":"San Antonio","localidad":"San Antonio","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.087479348430485,"longitud":-66.73402396378948,"ranking":0},
{"id":3754,"nombre":"Junin","localidad":"Junin","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.143885895492275,"longitud":-68.48709196070071,"ranking":0},
{"id":3755,"nombre":"Palmira","localidad":"Palmira","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3756,"nombre":"La Guardia","localidad":"La Guardia","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.557214969171405,"longitud":-65.45102669592526,"ranking":7},
{"id":3757,"nombre":"Nogueira","localidad":"F. Nogueira","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.075176800,"longitud":-68.580093400,"ranking":0},
{"id":3758,"nombre":"Collon Cura","localidad":"Collon Cura","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.0723147,"longitud":-70.12269839999999,"ranking":0},
{"id":3871,"nombre":"Puente Collon Cura","localidad":"Collon Cura","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.083587900,"longitud":-70.158004800,"ranking":0},
{"id":3759,"nombre":"Alicura","localidad":"Alicura","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.589188900,"longitud":-70.843277000,"ranking":0},
{"id":3763,"nombre":"Huemul","localidad":"Huemul","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.713158900,"longitud":-71.944205800,"ranking":0},
{"id":3764,"nombre":"Pino Hachado","localidad":"Pino Hachado","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3765,"nombre":"Liucura","localidad":"Liucura","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.645235,"longitud":-71.089894,"ranking":0},
{"id":3767,"nombre":"Lonquimay","localidad":"Lonquimay","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.446586,"longitud":-71.248965,"ranking":12},
{"id":3769,"nombre":"Curacautin","localidad":"Curacautin","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.435735,"longitud":-71.893527,"ranking":0},
{"id":3770,"nombre":"Lautaro","localidad":"Lautaro","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.537912,"longitud":-72.435398,"ranking":1},
{"id":3771,"nombre":"Cuyin Manzano","localidad":"Cuyin Manzano","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.7551011,"longitud":-71.16640939999999,"ranking":0},
{"id":3896,"nombre":"Cuyín Manzano Acceso","localidad":"Cuyin Manzano","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.754537863289336,"longitud":-71.16594750213662,"ranking":0},
{"id":3772,"nombre":"Nahuel Huapi","localidad":"Nahuel Huapi","provincia":"Neuquén","pais":"ARGENTINA","latitud":-41.046168744,"longitud":-71.155148066,"ranking":0},
{"id":3775,"nombre":"Espejo","localidad":"Espejo","provincia":"Neuquén","pais":"ARGENTINA","latitud":-41.032945500,"longitud":-71.153254500,"ranking":0},
{"id":3776,"nombre":"Nochero","localidad":"Nochero","provincia":"Chaco","pais":"ARGENTINA","latitud":-28.227764100,"longitud":-61.528565900,"ranking":0},
{"id":3777,"nombre":"Paso Cardenal Samore","localidad":"Paso Cardenal Samore","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.711776400,"longitud":-71.944656400,"ranking":0},
{"id":3778,"nombre":"Frutillar","localidad":"Frutillar","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.121133500,"longitud":-73.062515300,"ranking":0},
{"id":3779,"nombre":"Cumelen","localidad":"Cumelen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.780517,"longitud":-71.612728,"ranking":0},
{"id":3780,"nombre":"Venados Grandes","localidad":"Venados Grandes","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.81623669354196,"longitud":-61.38228364220202,"ranking":0},
{"id":3782,"nombre":"Lipela","localidad":"Lipela","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.787365300,"longitud":-71.120510100,"ranking":0},
{"id":3783,"nombre":"Uzcudun","localidad":"Uzcudun","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.271631900,"longitud":-66.161041300,"ranking":0},
{"id":3784,"nombre":"La Primavera","localidad":"La Primavera","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.693369600,"longitud":-71.219159700,"ranking":0},
{"id":3785,"nombre":"La Estacada","localidad":"La Estacada","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.837316500,"longitud":-71.538081800,"ranking":0},
{"id":3786,"nombre":"Pedregoso","localidad":"Pedregoso","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.066371400,"longitud":-71.520137800,"ranking":0},
{"id":3787,"nombre":"Lepa","localidad":"Lepa","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.569517200,"longitud":-71.073474900,"ranking":0},
{"id":3788,"nombre":"El Portezuelo","localidad":"El Portezuelo","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3789,"nombre":"Moutoso","localidad":"Moutoso","provincia":"Chubut","pais":"ARGENTINA","latitud":-44.229456600,"longitud":-70.466308600,"ranking":0},
{"id":3790,"nombre":"Chacabuco","localidad":"Chacabuco","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.637408,"longitud":-70.994006,"ranking":0},
{"id":3793,"nombre":"Obispo Trejo","localidad":"Obispo Trejo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.781866,"longitud":-63.409547,"ranking":13},
{"id":14545,"nombre":"Obispo Trejo Acceso","localidad":"Obispo Trejo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7756148,"longitud":-63.4128977,"ranking":0},
{"id":3794,"nombre":"Pozo del Molle","localidad":"Pozo del Molle","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.0161780,"longitud":-62.9152436,"ranking":42},
{"id":3798,"nombre":"Juan N. Fernandez","localidad":"Juan N. Fernandez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.01442649737657,"longitud":-59.260169381630945,"ranking":0},
{"id":3799,"nombre":"Ceibas Acceso","localidad":"Ceibas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.503304,"longitud":-58.797953,"ranking":6},
{"id":8282,"nombre":"Ceibas","localidad":"Ceibas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.5020548,"longitud":-58.7986641,"ranking":0},
{"id":3800,"nombre":"Caa Cati","localidad":"Caa Cati","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.751438,"longitud":-57.619162,"ranking":53},
{"id":3804,"nombre":"Alcaraz","localidad":"Alcaraz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.458611,"longitud":-59.599406,"ranking":4},
{"id":9904,"nombre":"Alcaraz Acceso","localidad":"Alcaraz","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.480416184160838,"longitud":-59.58555009216214,"ranking":0},
{"id":3805,"nombre":"Feliciano","localidad":"Feliciano","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3806,"nombre":"Galarza","localidad":"Galarza","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.1480805,"longitud":-59.3129593,"ranking":0},
{"id":7913,"nombre":"Galarza Acceso","localidad":"Galarza","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.725555992501334,"longitud":-59.369178543080515,"ranking":0},
{"id":3807,"nombre":"Larroque","localidad":"Larroque","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.0379022,"longitud":-58.99580880000001,"ranking":0},
{"id":3808,"nombre":"Maria Grande","localidad":"Maria Grande","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.66232410916415,"longitud":-59.90119321343582,"ranking":0},
{"id":3809,"nombre":"San Jaime de la Frontera","localidad":"San Jaime de la Frontera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.335045,"longitud":-58.307784,"ranking":0},
{"id":4428,"nombre":"San Jaime de la Frontera Acceso","localidad":"San Jaime de la Frontera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3810,"nombre":"Santa Elena","localidad":"Santa Elena","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.949545,"longitud":-59.784613,"ranking":0},
{"id":4341,"nombre":"Santa Elena Acceso","localidad":"Santa Elena","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.963924528485407,"longitud":-59.76237535078283,"ranking":0},
{"id":3332,"nombre":"La Tosca","localidad":"La Tosca","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.2676821,"longitud":-68.6539042,"ranking":0},
{"id":3836,"nombre":"La Tosca Acceso","localidad":"La Tosca","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.24863374403038,"longitud":-68.67282213572975,"ranking":0},
{"id":3324,"nombre":"Cuadro Benegas","localidad":"Cuadro Benegas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.626509,"longitud":-68.431986,"ranking":16},
{"id":3816,"nombre":"Los Terneros Acceso","localidad":"Los Terneros","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3864,"nombre":"El Nihuil","localidad":"El Nihuil","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.029341,"longitud":-68.67258939999999,"ranking":137},
{"id":3819,"nombre":"Salinas El Diamante Acceso","localidad":"Salinas El Diamante","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3333,"nombre":"Los Parlamentos","localidad":"Los Parlamentos","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.0201598,"longitud":-69.3214449,"ranking":0},
{"id":3824,"nombre":"Coihueco Acceso","localidad":"Coihueco","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9190,"nombre":"Coihueco","localidad":"Coihueco","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4245,"nombre":"Vistalba","localidad":"Vistalba","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0323871,"longitud":-68.9061396,"ranking":2},
{"id":2875,"nombre":"Mburucuya","localidad":"Mburucuya","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.048466,"longitud":-58.2251,"ranking":54},
{"id":3875,"nombre":"Chacabuco","localidad":"Chacabuco","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3876,"nombre":"Icho Cruz","localidad":"Icho Cruz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.474012,"longitud":-64.540823,"ranking":80},
{"id":3975,"nombre":"28 de Noviembre","localidad":"28 de Noviembre","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.600027,"longitud":-72.22401,"ranking":25},
{"id":3879,"nombre":"Telaritos","localidad":"Telaritos","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.47448632901636,"longitud":-65.65825320270942,"ranking":0},
{"id":3880,"nombre":"Las Animas","localidad":"Las Animas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.084168563922983,"longitud":-65.76392484431645,"ranking":0},
{"id":3881,"nombre":"Los Divisaderos","localidad":"Los Divisaderos","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3882,"nombre":"Las Esquinas","localidad":"Las Esquinas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.7104605677851,"longitud":-65.77032016338171,"ranking":1},
{"id":3885,"nombre":"Tortorajelos","localidad":"Tortorajelos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3888,"nombre":"Entre Lagos","localidad":"Entre Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-40.686707400,"longitud":-72.603246000,"ranking":0},
{"id":1724,"nombre":"Coronda","localidad":"Coronda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.973388144635365,"longitud":-60.922025003351195,"ranking":0},
{"id":1725,"nombre":"Coronda Acceso","localidad":"Coronda","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.965839865925453,"longitud":-60.93900014009564,"ranking":0},
{"id":3898,"nombre":"Santiago Vidal","localidad":"Santiago Vidal","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.687025600,"longitud":-68.156583300,"ranking":0},
{"id":3899,"nombre":"Hersilla","localidad":"Hersilla","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4441,"nombre":"Hersilla Acceso","localidad":"Hersilla","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.001240682581496,"longitud":-61.830956377364295,"ranking":0},
{"id":3901,"nombre":"Los Pirpintos","localidad":"Los Pirpintos","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.138776500,"longitud":-62.061939200,"ranking":0},
{"id":9064,"nombre":"Los Pirpintos Acceso","localidad":"Los Pirpintos","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.13220016094588,"longitud":-62.070367736491974,"ranking":0},
{"id":3902,"nombre":"Villa Gral. Mitre","localidad":"Villa Gral. Mitre","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.140066516113098,"longitud":-62.64983414272498,"ranking":0},
{"id":3906,"nombre":"Caaguazu","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.459587,"longitud":-56.015715,"ranking":150},
{"id":8123,"nombre":"Caaguazu Centro","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.459611,"longitud":-56.015731,"ranking":0},
{"id":12589,"nombre":"Primero de Mayo\t","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.4800026,"longitud":-56.1155544,"ranking":0},
{"id":12590,"nombre":"Calle San Pedro","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.4773074,"longitud":-56.2720531,"ranking":0},
{"id":12591,"nombre":"Blas Garay","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.51577541088954,"longitud":-56.216581238578605,"ranking":0},
{"id":12597,"nombre":"Pueblo de Dios","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.541488871098757,"longitud":-55.88458139897057,"ranking":0},
{"id":12605,"nombre":"Agencia Gabriel Cristaldo","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":8679,"nombre":"Carayao","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.197041,"longitud":-56.39548,"ranking":1},
{"id":8689,"nombre":"Santa Rosa del Mbutuy","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-24.976497,"longitud":-56.308991,"ranking":7},
{"id":12395,"nombre":"Santa Catalina","localidad":"Caaguazu","provincia":"Caaguazú","pais":"Paraguay","latitud":-24.852144,"longitud":-56.198,"ranking":0},
{"id":3907,"nombre":"Carmen del Parana","localidad":"Carmen del Parana","provincia":"Itapua","pais":"Paraguay","latitud":-27.218898,"longitud":-56.143581,"ranking":16},
{"id":3908,"nombre":"General Delgado","localidad":"General Delgado","provincia":"Itapua","pais":"Paraguay","latitud":-27.089476,"longitud":-56.523457,"ranking":14},
{"id":3909,"nombre":"San Patricio","localidad":"San Patricio","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.970609,"longitud":-56.825236,"ranking":35},
{"id":3910,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.889551,"longitud":-56.867477,"ranking":27},
{"id":3912,"nombre":"San Ignacio","localidad":"San Ignacio Guazú","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.88706,"longitud":-57.025829,"ranking":99},
{"id":3913,"nombre":"San Juan Bautista","localidad":"San Juan Bautista","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.6716775,"longitud":-57.1445388,"ranking":20},
{"id":3914,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.535087,"longitud":-57.040019,"ranking":0},
{"id":3915,"nombre":"Villa Florida","localidad":"Villa Florida","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-26.408602,"longitud":-57.124705,"ranking":2},
{"id":3916,"nombre":"Caapucu","localidad":"Caapucu","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-26.233298,"longitud":-57.180057,"ranking":7},
{"id":3917,"nombre":"Quindy","localidad":"Quiindy","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.974865,"longitud":-57.235854,"ranking":6},
{"id":3918,"nombre":"San Roque Gonzalez","localidad":"San Roque Gonzalez","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.294507,"longitud":-57.578655,"ranking":0},
{"id":3919,"nombre":"Carapegua","localidad":"Carapegua","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.768528,"longitud":-57.244861,"ranking":29},
{"id":12398,"nombre":"Carapegua - Agencia Narcisa","localidad":"Carapegua","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.766873,"longitud":-57.237581,"ranking":0},
{"id":12399,"nombre":"Carapegua - Agencia Rolon","localidad":"Carapegua","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.770347,"longitud":-57.244614,"ranking":20},
{"id":3920,"nombre":"Yaguaron","localidad":"Yaguaron","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.562784,"longitud":-57.284254,"ranking":7},
{"id":3921,"nombre":"Ita","localidad":"Itá","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.511373,"longitud":-57.360984,"ranking":12},
{"id":2655,"nombre":"San Lorenzo Acceso","localidad":"San Lorenzo","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":3922,"nombre":"San Lorenzo","localidad":"San Lorenzo","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.342193,"longitud":-57.501274,"ranking":149},
{"id":14250,"nombre":"Curva Meyer","localidad":"San Lorenzo","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.3485017,"longitud":-57.5018708,"ranking":0},
{"id":14251,"nombre":"Yamitour","localidad":"San Lorenzo","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.3435252,"longitud":-57.5039924,"ranking":0},
{"id":3949,"nombre":"Bañado de Ovanta","localidad":"Bañado de Ovanta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.105567500,"longitud":-65.318350200,"ranking":35},
{"id":3950,"nombre":"Los Alazanes","localidad":"Los Alazanes","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.980762800,"longitud":-68.370666500,"ranking":0},
{"id":3951,"nombre":"La Colorada","localidad":"La Colorada","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3953,"nombre":"Paraje Las Horquetas","localidad":"Horquetas","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-48.20901850000001,"longitud":-71.20184739999999,"ranking":0},
{"id":3954,"nombre":"Gobernador Mayer","localidad":"Gobernador Mayer","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.35438089356189,"longitud":-70.28898758397368,"ranking":0},
{"id":3957,"nombre":"La Esperanza","localidad":"La Esperanza","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.030063,"longitud":-70.779727,"ranking":24},
{"id":3959,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.652367,"longitud":-71.385789,"ranking":0},
{"id":3960,"nombre":"Rio Bote","localidad":"Rio Bote","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3961,"nombre":"Glaciar Perito Moreno","localidad":"Glaciar Perito Moreno","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.582263,"longitud":-70.92825,"ranking":7},
{"id":3962,"nombre":"Le Marchand","localidad":"Le Marchand","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3963,"nombre":"Pico Truncado","localidad":"Pico Truncado","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.797312,"longitud":-67.94777,"ranking":896},
{"id":3965,"nombre":"Las Heras","localidad":"Las Heras","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.538093,"longitud":-68.93539,"ranking":116},
{"id":3966,"nombre":"El Pluma","localidad":"El Pluma","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3967,"nombre":"Perito Moreno","localidad":"Perito Moreno","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.582462,"longitud":-70.928281,"ranking":135},
{"id":3968,"nombre":"Los Antiguos","localidad":"Los Antiguos","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.543715,"longitud":-71.624691,"ranking":82},
{"id":3969,"nombre":"Estacion Vanguardia","localidad":"Estacion Vanguardia","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3970,"nombre":"Tapi Aike","localidad":"Tapi Aike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.057623010819924,"longitud":-71.80224593850367,"ranking":0},
{"id":3972,"nombre":"Estacion Cancha Carrera","localidad":"Estacion Cancha Carrera","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.24999,"longitud":-72.233667,"ranking":0},
{"id":3974,"nombre":"Julia Dufour","localidad":"Julia Dufour","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-51.54620387603,"longitud":-72.23570610791323,"ranking":0},
{"id":3976,"nombre":"Paraje Luz Divina","localidad":"Paraje Luz Divina","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3977,"nombre":"La Leona","localidad":"La Leona","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.808923,"longitud":-72.053082,"ranking":0},
{"id":8517,"nombre":"La Leona Acceso","localidad":"La Leona","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.81088336711112,"longitud":-72.05453229296785,"ranking":0},
{"id":3980,"nombre":"Koluel Kaike","localidad":"Koluel Kaike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.714797100,"longitud":-68.223381000,"ranking":0},
{"id":3981,"nombre":"Victoria","localidad":"Victoria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.452893,"longitud":-58.539061,"ranking":0},
{"id":3984,"nombre":"Copiapo","localidad":"Copiapó","provincia":"Región III - Atacama","pais":"CHILE","latitud":-27.3689526,"longitud":-70.3360225,"ranking":0},
{"id":3033,"nombre":"Sauce de Luna Acceso","localidad":"Sauce de Luna","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.2430049,"longitud":-59.2106316,"ranking":4},
{"id":3084,"nombre":"Sauce de Luna","localidad":"Sauce de Luna","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.236956,"longitud":-59.220872,"ranking":3},
{"id":3992,"nombre":"Garuhape","localidad":"Garuhape","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.819135,"longitud":-54.958227,"ranking":6},
{"id":4432,"nombre":"Garuhape Acceso","localidad":"Garuhape","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.820563330084042,"longitud":-54.958434239021706,"ranking":0},
{"id":3995,"nombre":"Makalle","localidad":"Makalle","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.197479200,"longitud":-59.278830900,"ranking":16},
{"id":8400,"nombre":"Makalle Acceso","localidad":"Makalle","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.197434853180148,"longitud":-59.278870124328286,"ranking":0},
{"id":3996,"nombre":"Hermoso Campo","localidad":"Hermoso Campo","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.608247990050582,"longitud":-61.346726277446436,"ranking":21},
{"id":3997,"nombre":"El Cabure","localidad":"El Cabure","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.01681915257429,"longitud":-62.334914757873044,"ranking":0},
{"id":9066,"nombre":"El Cabure Acceso","localidad":"El Cabure","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.01871122285205,"longitud":-62.34173044170567,"ranking":0},
{"id":12264,"nombre":"Terminal de Pirayu","localidad":"Pirayú","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":4003,"nombre":"Minga Guazu Acceso","localidad":"Minga Guazú","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.477637,"longitud":-54.932326,"ranking":0},
{"id":5119,"nombre":"Minga Guazu","localidad":"Minga Guazú","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.491058,"longitud":-54.760818,"ranking":0},
{"id":12587,"nombre":"Desvio KM30","localidad":"Minga Guazú","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.48380345792234,"longitud":-54.89739418029785,"ranking":0},
{"id":14245,"nombre":"Km 16","localidad":"Minga Guazú","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.490403600693448,"longitud":-54.759501814842224,"ranking":0},
{"id":4005,"nombre":"Guarapuava","localidad":"Guarapuava","provincia":"Parana","pais":"BRASIL","latitud":-25.37788,"longitud":-51.47781000000001,"ranking":2},
{"id":4006,"nombre":"Capitan Miranda Acceso","localidad":"Capitan Miranda","provincia":"Itapua","pais":"Paraguay","latitud":-27.217332,"longitud":-55.802992,"ranking":0},
{"id":4567,"nombre":"Capitan Miranda","localidad":"Capitan Miranda","provincia":"Itapua","pais":"Paraguay","latitud":-27.217233,"longitud":-55.802773,"ranking":0},
{"id":4007,"nombre":"Hohenau Acceso","localidad":"Hohenau","provincia":"Itapua","pais":"Paraguay","latitud":-27.088079,"longitud":-55.657642,"ranking":10},
{"id":8130,"nombre":"Hohenau","localidad":"Hohenau","provincia":"Itapua","pais":"Paraguay","latitud":-27.084561,"longitud":-55.650726,"ranking":0},
{"id":4011,"nombre":"Yahape","localidad":"Yahape","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.372391,"longitud":-57.655732,"ranking":76},
{"id":7343,"nombre":"Yahape Acceso","localidad":"Yahape","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.404304325883537,"longitud":-57.66108084277881,"ranking":0},
{"id":4012,"nombre":"Cascavel Acceso","localidad":"Cascavel","provincia":"Parana","pais":"BRASIL","latitud":-24.926673417102432,"longitud":-53.44617389146428,"ranking":0},
{"id":4847,"nombre":"Cascavel","localidad":"Cascavel","provincia":"Parana","pais":"BRASIL","latitud":-24.9635238,"longitud":-53.4819648,"ranking":2},
{"id":15332,"nombre":"Salto Portao","localidad":"Cascavel","provincia":"Parana","pais":"BRASIL","latitud":-25.03364,"longitud":-53.26433,"ranking":0},
{"id":4015,"nombre":"Dionisio Cerqueira Acceso","localidad":"Dionísio Cerqueira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.25792969231696,"longitud":-53.61817685513091,"ranking":0},
{"id":7727,"nombre":"Dionisio Cerqueira","localidad":"Dionísio Cerqueira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.2581358,"longitud":-53.630303,"ranking":0},
{"id":4016,"nombre":"Fernando de la Mora","localidad":"Fernando de la Mora","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.32784,"longitud":-57.544633,"ranking":9},
{"id":12397,"nombre":"Fernando de la Mora - Electroban","localidad":"Fernando de la Mora","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.32234,"longitud":-57.556054,"ranking":0},
{"id":12407,"nombre":"Barrio Mariscal Lopez","localidad":"Fernando de la Mora","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.293085,"longitud":-57.607522,"ranking":0},
{"id":15391,"nombre":"Badenia","localidad":"Fernando de la Mora","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.328658700441398,"longitud":-57.545515006005175,"ranking":2},
{"id":15392,"nombre":"Salemma","localidad":"Fernando de la Mora","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.334053110111604,"longitud":-57.536791715815085,"ranking":0},
{"id":4018,"nombre":"Guaviravi Acceso","localidad":"Guaviravi","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.371421,"longitud":-56.81745530000001,"ranking":0},
{"id":4019,"nombre":"Mariano Roque Alonso Agencia","localidad":"Mariano Roque Alonso","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.283085,"longitud":-57.488307,"ranking":57},
{"id":4123,"nombre":"Peaje Puente Remanzo","localidad":"Mariano Roque Alonso","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.177211,"longitud":-57.555078,"ranking":0},
{"id":12408,"nombre":"Barrio Moseñor Bogarin","localidad":"Mariano Roque Alonso","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.217575,"longitud":-57.530382,"ranking":0},
{"id":4021,"nombre":"Ponta Grossa Acceso","localidad":"Ponta Grossa","provincia":"Parana","pais":"BRASIL","latitud":-25.099497575406897,"longitud":-50.15786943332922,"ranking":0},
{"id":5059,"nombre":"Ponta Grossa","localidad":"Ponta Grossa","provincia":"Parana","pais":"BRASIL","latitud":-25.0953076,"longitud":-50.1694761,"ranking":0},
{"id":4112,"nombre":"Estancia Meabe Acceso","localidad":"Villa Olivari","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4024,"nombre":"Tapebicua Acceso","localidad":"Tapebicua","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.502483458717343,"longitud":-56.97576127202598,"ranking":0},
{"id":4769,"nombre":"Tapebicua","localidad":"Tapebicua","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4026,"nombre":"Cambe","localidad":"Cambé","provincia":"Parana","pais":"BRASIL","latitud":-23.2767813540657,"longitud":-51.27967961889201,"ranking":0},
{"id":5415,"nombre":"Oruro","localidad":"Oruro","provincia":"Oruro","pais":"BOLIVIA","latitud":-17.95079467752747,"longitud":-67.0955070188506,"ranking":7},
{"id":8150,"nombre":"Caracollo","localidad":"Oruro","provincia":"Oruro","pais":"BOLIVIA","latitud":-17.65086659708132,"longitud":-67.2096671905699,"ranking":0},
{"id":4036,"nombre":"Guandacaya","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4037,"nombre":"La Merced","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4038,"nombre":"La Choza","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4039,"nombre":"La Mamora","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4040,"nombre":"Nogalito","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4041,"nombre":"Padcaya","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4042,"nombre":"San Lorenzo","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4043,"nombre":"Tarija","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":-21.4550905,"longitud":-63.2032151,"ranking":0},
{"id":4044,"nombre":"Villa Montes","localidad":"Tarija","provincia":"Tarija","pais":"BOLIVIA","latitud":-21.26791743140331,"longitud":-63.45743368156147,"ranking":0},
{"id":7790,"nombre":"Boyuibe","localidad":"Boyuibe","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-20.427327007090902,"longitud":-63.285699785799,"ranking":0},
{"id":4032,"nombre":"Charagua","localidad":"Charagua","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4048,"nombre":"Charagua Acceso","localidad":"Charagua","provincia":"Santa Cruz","pais":"BOLIVIA","latitud":-19.78032031257145,"longitud":-63.18273243289895,"ranking":0},
{"id":4035,"nombre":"Emborozu","localidad":"Emborozu","provincia":"Tarija","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4046,"nombre":"Concepcion","localidad":"Concepcion","provincia":"Concepción","pais":"Paraguay","latitud":-23.398571,"longitud":-57.44422,"ranking":77},
{"id":12391,"nombre":"Paso Horqueta","localidad":"Concepcion","provincia":"Concepción","pais":"Paraguay","latitud":-23.08096,"longitud":-57.382577,"ranking":0},
{"id":3210,"nombre":"Villamontes","localidad":"Villamontes","provincia":"Villamonte","pais":"BOLIVIA","latitud":null,"longitud":null,"ranking":0},
{"id":4051,"nombre":"Villamontes Acceso","localidad":"Villamontes","provincia":"Villamonte","pais":"BOLIVIA","latitud":-21.267803204540265,"longitud":-63.46032248850456,"ranking":0},
{"id":4055,"nombre":"Rio Muerto Acceso","localidad":"Rio Muerto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.308299441075796,"longitud":-61.659472077382404,"ranking":0},
{"id":4060,"nombre":"San Miguel","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.532703,"longitud":-57.03963,"ranking":0},
{"id":8119,"nombre":"San Pedro del Parana","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.830342,"longitud":-56.207422,"ranking":11},
{"id":15357,"nombre":"Pirapey","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.649757147072297,"longitud":-55.27631573868426,"ranking":0},
{"id":12575,"nombre":"Cruce Kimex","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.2727439,"longitud":-55.2019834,"ranking":0},
{"id":12580,"nombre":"Desvio Caicisa","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.432860600264323,"longitud":-55.24046980079561,"ranking":0},
{"id":12581,"nombre":"Desvio Yatytay","localidad":"Itapua","provincia":"Itapua","pais":"Paraguay","latitud":-26.6792935,"longitud":-55.0770553,"ranking":0},
{"id":4070,"nombre":"Maringa","localidad":"Maringá","provincia":"Parana","pais":"BRASIL","latitud":-23.4171058,"longitud":-51.93780690000001,"ranking":5},
{"id":4071,"nombre":"San Alonso Acceso","localidad":"San Alonso","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.8447585,"longitud":-57.5608835,"ranking":0},
{"id":4013,"nombre":"Coronel Desiderio Sosa Acceso","localidad":"Coronel Desiderio Sosa","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.214534813905104,"longitud":-56.11472265568875,"ranking":0},
{"id":4074,"nombre":"Caza Pava Acceso","localidad":"Caza Pava","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.29144396188212,"longitud":-56.11428180086933,"ranking":0},
{"id":4075,"nombre":"Torrent Acceso","localidad":"Cuay Chico","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4077,"nombre":"San Antonio","localidad":"San Antonio","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.056360402892356,"longitud":-53.733167766463374,"ranking":0},
{"id":8588,"nombre":"Estornina Acceso","localidad":"San Antonio","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8589,"nombre":"Gramado Acceso","localidad":"San Antonio","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.055673790453508,"longitud":-53.74482969599783,"ranking":0},
{"id":4079,"nombre":"Cambai Acceso","localidad":"Cambai","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4080,"nombre":"Estingana Acceso","localidad":"Estingana","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4081,"nombre":"Paraje Cacho Martinez","localidad":"Paso Martinez","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.521883239769714,"longitud":-58.65381440584416,"ranking":0},
{"id":4082,"nombre":"Paraje Pirayu","localidad":"Pirayu","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4090,"nombre":"Estancia Casal","localidad":"Pirayu","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4118,"nombre":"Pirayu Acceso","localidad":"Pirayu","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4083,"nombre":"Empalme Ruta 114","localidad":"colonia Carlos Pelegrini","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.7388662,"longitud":-57.4775842,"ranking":0},
{"id":8521,"nombre":"Carlos Pellegrini","localidad":"colonia Carlos Pelegrini","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4086,"nombre":"Chavarria Acceso","localidad":"Nueva Esperanza","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.9597811,"longitud":-58.578301,"ranking":0},
{"id":4117,"nombre":"Paraje Nueva Esperanza","localidad":"Nueva Esperanza","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4087,"nombre":"Cantinela Acceso","localidad":"San Carlos","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.787889398565028,"longitud":-55.83072481511142,"ranking":0},
{"id":4099,"nombre":"San Carlos","localidad":"San Carlos","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.7451278,"longitud":-55.8983353,"ranking":23},
{"id":4095,"nombre":"Santiago de Liniers","localidad":"Santiago de Liniers","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.3837316,"longitud":-54.3856793,"ranking":0},
{"id":8597,"nombre":"Cruce Ciba","localidad":"Santiago de Liniers","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8598,"nombre":"Cruce Jurjo","localidad":"Santiago de Liniers","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9744,"nombre":"Santiago de Liniers Acceso","localidad":"Santiago de Liniers","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.39969136468086,"longitud":-54.389064271473444,"ranking":0},
{"id":4098,"nombre":"Beron de Astrada Acceso","localidad":"Beron de Astrada","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.548868397564313,"longitud":-57.53885975834579,"ranking":0},
{"id":7342,"nombre":"Beron de Astrada","localidad":"Beron de Astrada","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.550356666793494,"longitud":-57.52799755071919,"ranking":0},
{"id":4102,"nombre":"Iribicua","localidad":"Yacarey","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4108,"nombre":"Yacarey Acceso","localidad":"Yacarey","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.340314462745173,"longitud":-58.14419259556338,"ranking":0},
{"id":8080,"nombre":"Yacarey","localidad":"Yacarey","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.3438753,"longitud":-58.23486380000001,"ranking":0},
{"id":4107,"nombre":"Estacion Torrent","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.826412738448987,"longitud":-56.47093528148543,"ranking":0},
{"id":4560,"nombre":"Santa Rosa Acceso","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.260298,"longitud":-58.112146,"ranking":4},
{"id":4609,"nombre":"Tatacua Acceso","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.3579719,"longitud":-58.34579170000001,"ranking":3},
{"id":4676,"nombre":"Concepcion","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.387448,"longitud":-57.897472,"ranking":50},
{"id":4680,"nombre":"Santa Rosa","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.2743227,"longitud":-58.1336567,"ranking":40},
{"id":4681,"nombre":"Tabay","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8496,"nombre":"Concepcion Acceso","localidad":"Concepcion","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.384391117820872,"longitud":-57.90515253621399,"ranking":0},
{"id":4111,"nombre":"Tuyuti Acceso","localidad":"Tuyuti","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8078,"nombre":"Tuyuti","localidad":"Tuyuti","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.38405356,"longitud":-57.761257193,"ranking":0},
{"id":4114,"nombre":"Ceres Acceso","localidad":"Nueva Ceres","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.9710656,"longitud":-62.10510369999999,"ranking":0},
{"id":4119,"nombre":"Libertad Acceso","localidad":"Libertad","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.92055258000657,"longitud":-54.57552909851074,"ranking":0},
{"id":4120,"nombre":"Libertad","localidad":"Libertad","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.92163583400332,"longitud":-54.58136917824325,"ranking":0},
{"id":4132,"nombre":"Punta Maqueda","localidad":"Punta Maqueda","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4133,"nombre":"Ototel Aike","localidad":"Ototel Aike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.818244024476236,"longitud":-69.5140852038323,"ranking":0},
{"id":4134,"nombre":"Güer Aike","localidad":"Güer Aike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4135,"nombre":"Ingeniero Juarez","localidad":"Ingeniero Juarez","provincia":"Formosa","pais":"ARGENTINA","latitud":-23.897934,"longitud":-61.851435,"ranking":14},
{"id":5532,"nombre":"Lamarque","localidad":"Lamarque","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4143,"nombre":"Lago Roca","localidad":"Lago Roca","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.525047,"longitud":-72.808649,"ranking":0},
{"id":4146,"nombre":"Virreyes","localidad":"Virreyes","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4158,"nombre":"Pablo Podesta","localidad":"Podesta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4160,"nombre":"Santos Lugares","localidad":"Santos Lugares","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4162,"nombre":"Piedra Clavada","localidad":"Piedra Clavada","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.590823900,"longitud":-68.567776700,"ranking":0},
{"id":4163,"nombre":"Cañadón Seco","localidad":"Cañadón Seco","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-46.547734300,"longitud":-67.604112600,"ranking":8},
{"id":7732,"nombre":"Puerto Tirol Acceso","localidad":"Puerto Tirol","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.36073014300293,"longitud":-59.06265630414377,"ranking":0},
{"id":4164,"nombre":"Telllier","localidad":"Tellier","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.64762020549631,"longitud":-66.03855346961541,"ranking":0},
{"id":4168,"nombre":"La Paz - Bolivia","localidad":"La Paz","provincia":"La Paz","pais":"BOLIVIA","latitud":-16.4884029,"longitud":-68.1401722,"ranking":29},
{"id":4170,"nombre":"La Decision","localidad":"La Decision","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4171,"nombre":"Almirante Tres Hermanos","localidad":"Alm Tres Hermanos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4172,"nombre":"Alm. Aspiros","localidad":"Alm. Aspiros","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7644,"nombre":"Gral. San Martin Acceso","localidad":"Hucal","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.978385232047806,"longitud":-63.61298354874409,"ranking":0},
{"id":7924,"nombre":"Hucal ","localidad":"Hucal","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7925,"nombre":"Abramo","localidad":"Hucal","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.8950651955673,"longitud":-63.84927882874793,"ranking":0},
{"id":4208,"nombre":"Punta de Vacas","localidad":"Punta de Vacas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8532799,"longitud":-69.7609686,"ranking":7},
{"id":4209,"nombre":"La Calera","localidad":"La Calera","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.785458,"longitud":-71.188783,"ranking":0},
{"id":4336,"nombre":"La Calera Acceso","localidad":"La Calera","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.788383,"longitud":-71.192323,"ranking":0},
{"id":8365,"nombre":"Concon","localidad":"Concon","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-32.929755,"longitud":-71.51866,"ranking":0},
{"id":4211,"nombre":"Casablanca Acceso","localidad":"Casablanca","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.320208,"longitud":-71.41045,"ranking":0},
{"id":4213,"nombre":"El Cerrito","localidad":"El Cerrito","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4214,"nombre":"Villa de Maria del Rio Seco","localidad":"Villa de Maria del Rio Seco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.924803,"longitud":-63.643971,"ranking":73},
{"id":4216,"nombre":"Montes de Oca","localidad":"Montes de Oca","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4217,"nombre":"Leguizamon","localidad":"Leguizamon","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.206910879327054,"longitud":-62.97745308974457,"ranking":0},
{"id":4220,"nombre":"Puyehue","localidad":"Puyehue","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4334,"nombre":"Puyehue Acceso","localidad":"Puyehue","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4223,"nombre":"Alvarez Condarco","localidad":"Alvarez Condarco","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.84213521571448,"longitud":-68.81194379047761,"ranking":0},
{"id":4224,"nombre":"Arroyo Blanco","localidad":"Arroyo Blanco","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4226,"nombre":"Cacheuta","localidad":"Cacheuta","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.013013,"longitud":-69.119194,"ranking":184},
{"id":4227,"nombre":"Cordon del Plata","localidad":"Cordon del Plata","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4229,"nombre":"Dest. Lujan","localidad":"Dest. Lujan","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4230,"nombre":"Dique Cipoletti","localidad":"Dique Cipoletti","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0522737,"longitud":-68.9380472,"ranking":0},
{"id":4232,"nombre":"Esc. Ntra Sra de las Nieves","localidad":"Esc. Ntra Sra de las Nieves","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4234,"nombre":"Las Avispas","localidad":"Las Avispas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0743728,"longitud":-69.1275283,"ranking":0},
{"id":4235,"nombre":"La Estacada","localidad":"La Estacada","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4237,"nombre":"Las Vegas","localidad":"Las Vegas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.01468038238505,"longitud":-69.27228311310033,"ranking":21},
{"id":4238,"nombre":"Panamericana","localidad":"Panamericana","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4239,"nombre":"Panella","localidad":"Panella","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4240,"nombre":"Penitenciaria Almafuerte","localidad":"Almafuerte","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8780046,"longitud":-68.8593561,"ranking":210},
{"id":4241,"nombre":"Penitentes","localidad":"Penitentes","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.841001,"longitud":-69.842505,"ranking":7},
{"id":4242,"nombre":"Piedras Blancas","localidad":"Piedras Blancas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.94569627783489,"longitud":-68.87523834917346,"ranking":14},
{"id":4243,"nombre":"Polvareda","localidad":"Polvareda","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.794809,"longitud":-69.653716,"ranking":12},
{"id":4244,"nombre":"Los Puquios","localidad":"Los Puquios","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4246,"nombre":"Bransen","localidad":"Bransen","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4247,"nombre":"Natan","localidad":"Natan","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.0385816616014,"longitud":-69.00946620396115,"ranking":0},
{"id":4248,"nombre":"La Playosa","localidad":"La Playosa","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.102297,"longitud":-63.034698,"ranking":15},
{"id":9108,"nombre":"La Playosa Acceso","localidad":"La Playosa","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.09957118978637,"longitud":-63.03095052803331,"ranking":0},
{"id":4249,"nombre":"Las Perdices","localidad":"Las Perdices","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.697988,"longitud":-63.709218,"ranking":47},
{"id":4251,"nombre":"La Tranca","localidad":"La Tranca","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4252,"nombre":"Rio Seco","localidad":"Rio Seco","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4253,"nombre":"El Carrizal","localidad":"El Carrizal","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.3212486,"longitud":-68.7591186,"ranking":0},
{"id":4559,"nombre":"Reutilizar1","localidad":"El Carrizal","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4255,"nombre":"Noqueve","localidad":"Noqueve","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4256,"nombre":"Carrizal","localidad":"Carrizal","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4257,"nombre":"Villa Mazan","localidad":"Villa Mazan","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.651645773136245,"longitud":-66.54673953431673,"ranking":0},
{"id":4258,"nombre":"Estacion Mazan","localidad":"Villa Mazan","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.680880681388842,"longitud":-66.49232093867082,"ranking":0},
{"id":4259,"nombre":"El Pajonal","localidad":"El Pajonal","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.372578863121557,"longitud":-66.2984344050206,"ranking":9},
{"id":4260,"nombre":"Sijan","localidad":"Sijan","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.269408855500956,"longitud":-66.24153585663991,"ranking":7},
{"id":4261,"nombre":"Alijilan","localidad":"Alijilan","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.182620977166554,"longitud":-65.49410008431268,"ranking":0},
{"id":4262,"nombre":"Huacra","localidad":"Huacra","provincia":"Tucumán","pais":"ARGENTINA","latitud":-28.004254085,"longitud":-65.579830841,"ranking":14},
{"id":4263,"nombre":"Palo Labrado","localidad":"Palo Labrado","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.326682717184145,"longitud":-65.6132496925595,"ranking":0},
{"id":4264,"nombre":"Amadores","localidad":"Amadores","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.262001454723926,"longitud":-65.62951794612017,"ranking":0},
{"id":4265,"nombre":"Puerta Chiquita","localidad":"Puerta Chiquita","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4266,"nombre":"Parana","localidad":"Parana","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4267,"nombre":"Los Lapachos","localidad":"Los Lapachos","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4269,"nombre":"Ingeniero Andre","localidad":"Ingeniero Andre","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4270,"nombre":"General Lavalle","localidad":"General Lavalle","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4271,"nombre":"Bella Vista","localidad":"Bella Vista","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.433143,"longitud":-69.235419,"ranking":0},
{"id":4272,"nombre":"Punta Sierra Acceso","localidad":"Punta Sierra","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.73692592642978,"longitud":-69.11143384586894,"ranking":0},
{"id":4273,"nombre":"Tratayen Acceso","localidad":"Tratayen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.42008791302295,"longitud":-68.63444696046781,"ranking":0},
{"id":4274,"nombre":"Aguada Colorada Acceso","localidad":"Aguada Colorada","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4275,"nombre":"Aguada San Roque Acceso","localidad":"Aguada San Roque","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.07045617677176,"longitud":-69.06344499063671,"ranking":0},
{"id":4669,"nombre":"Aguada San Roque","localidad":"Aguada San Roque","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.998629,"longitud":-68.9226201,"ranking":0},
{"id":4277,"nombre":"Punta del Marques","localidad":"Punta Marques","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4278,"nombre":"Chosmes","localidad":"Chosmes","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4280,"nombre":"Juan Wenceslao Gez","localidad":"Juan W. Gez","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.390052186,"longitud":-66.132539332,"ranking":0},
{"id":4284,"nombre":"Juan W. Gez Acceso","localidad":"Juan W. Gez","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4281,"nombre":"Eleodoro Lobos","localidad":"Eleodoro Lobos","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4285,"nombre":"Eleodoro Lobos Acceso","localidad":"Eleodoro Lobos","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.39296575003492,"longitud":-66.01239987553379,"ranking":0},
{"id":4282,"nombre":"Liborio Luna","localidad":"Liborio Luna","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.579776056850555,"longitud":-65.63978534719607,"ranking":0},
{"id":4287,"nombre":"Liborio Luna Acceso","localidad":"Liborio Luna","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.58066758641657,"longitud":-65.64016159023926,"ranking":0},
{"id":4293,"nombre":"Inriville Acceso","localidad":"Inriville","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.94304992256661,"longitud":-62.23576829070812,"ranking":0},
{"id":8113,"nombre":"Inriville","localidad":"Inriville","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.94308071573861,"longitud":-62.23627249504587,"ranking":0},
{"id":4296,"nombre":"Justiniano Posse","localidad":"Justiniano Posse","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.87965805886919,"longitud":-62.68407510286232,"ranking":0},
{"id":4299,"nombre":"Toro Negro","localidad":"Toro Negro","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.729768402,"longitud":-66.338012519,"ranking":8},
{"id":8028,"nombre":"Toro Negro Acceso","localidad":"Toro Negro","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.72060475971899,"longitud":-66.3321684550792,"ranking":0},
{"id":4300,"nombre":"Pozo del Tala","localidad":"Pozo del Tala","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.60309170222696,"longitud":-66.29228437306574,"ranking":0},
{"id":9106,"nombre":"Pozo del Tala Acceso","localidad":"Pozo del Tala","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.60268945125558,"longitud":-66.26988921660647,"ranking":0},
{"id":4302,"nombre":"Puente de La Horqueta","localidad":"Puente de La Horqueta ","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4303,"nombre":"Puente de La Horqueta Acceso","localidad":"Puente de La Horqueta ","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8279,"nombre":"Las Horquetas","localidad":"Las Horquetas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4304,"nombre":"Las Horquetas Acceso","localidad":"Las Horquetas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4305,"nombre":"San Jose de Mayo Acceso","localidad":"San Jose de Mayo","provincia":"San José","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":4486,"nombre":"San Jose de Mayo (Terminal)","localidad":"San Jose de Mayo","provincia":"San José","pais":"URUGUAY","latitud":-34.33372695350117,"longitud":-56.715983276589185,"ranking":0},
{"id":4306,"nombre":"Loncoche","localidad":"Loncoche","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-39.37105,"longitud":-72.636897,"ranking":0},
{"id":4307,"nombre":"Lanco","localidad":"Lanco","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":-39.45087223521567,"longitud":-72.77627338153626,"ranking":0},
{"id":4308,"nombre":"San Jose de la Mariquina","localidad":"San Jose de la Mariquina","provincia":"Region XIV - Los Rios","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4309,"nombre":"Chocori","localidad":"Chocori","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.109817800,"longitud":-64.471163700,"ranking":0},
{"id":4310,"nombre":"Zanjon de Oyuela","localidad":"Zanjon de Oyuela","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.7069166,"longitud":-63.441603,"ranking":4},
{"id":4311,"nombre":"Las Vertientes","localidad":"Las Vertientes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.28295096602185,"longitud":-64.57781936462158,"ranking":0},
{"id":4312,"nombre":"Carnerillo Acceso","localidad":"Carnerillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4529,"nombre":"Carnerillo","localidad":"Carnerillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.9135399,"longitud":-64.0263985,"ranking":43},
{"id":4313,"nombre":"San Patricio del Chañar Acceso","localidad":"San Patricio del Chañar","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.6349981213115,"longitud":-68.29186898609784,"ranking":0},
{"id":5493,"nombre":"San Patricio del Chañar","localidad":"San Patricio del Chañar","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.627083594669,"longitud":-68.30058667415483,"ranking":0},
{"id":4314,"nombre":"Villa Manzano Acceso","localidad":"Villa Manzano","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.684436351168586,"longitud":-68.21220323047028,"ranking":0},
{"id":4315,"nombre":"Bardas Blancas Acceso","localidad":"Bardas Blancas","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.86294310293626,"longitud":-69.80785201661976,"ranking":0},
{"id":14608,"nombre":"Bardas Blancas","localidad":"Bardas Blancas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4316,"nombre":"La Pasarela Acceso","localidad":"La Pasarela","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4317,"nombre":"Ranquil Norte Acceso","localidad":"Ranquil Norte","provincia":"Mendoza","pais":"ARGENTINA","latitud":-36.659729964305875,"longitud":-69.82939030919648,"ranking":0},
{"id":4332,"nombre":"Ranquil Norte","localidad":"Ranquil Norte","provincia":"Mendoza","pais":"ARGENTINA","latitud":-36.657822,"longitud":-69.831744,"ranking":0},
{"id":4318,"nombre":"Butaco Acceso","localidad":"Butaco","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4319,"nombre":"Auquinco Acceso","localidad":"Auquinco","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.32182985203867,"longitud":-69.96994577564452,"ranking":0},
{"id":4782,"nombre":"Auquinco","localidad":"Auquinco","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4320,"nombre":"La Salada Acceso","localidad":"La Salada","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.30684987727151,"longitud":-70.23856928773378,"ranking":0},
{"id":4783,"nombre":"La Salada","localidad":"La Salada","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.311829102889234,"longitud":-70.25187913261293,"ranking":0},
{"id":4321,"nombre":"Bella Vista","localidad":"Bella Vista","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4896,"nombre":"Parador Bella Vista","localidad":"Bella Vista","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4322,"nombre":"El Mulato","localidad":"El Mulato","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4323,"nombre":"Parador El Zurdo","localidad":"El Zurdo","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4324,"nombre":"Parador Puente Blanco","localidad":"Puente Blanco","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4326,"nombre":"Buitreras","localidad":"Buitreras","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4327,"nombre":"Gnencros","localidad":"Gnencros","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4328,"nombre":"Turbio Viejo","localidad":"Turbio Viejo","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4329,"nombre":"Parador Rospentek Aike","localidad":"Rospentek Aike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4325,"nombre":"Puerto Natales","localidad":"Puerto Natales","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-51.728339,"longitud":-72.488034,"ranking":28},
{"id":4330,"nombre":"Dorotea","localidad":"Dorotea","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4331,"nombre":"Las Salinas","localidad":"Las Salinas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9818,"nombre":"Napaleofu Acceso","localidad":"Napaleofu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.6232414,"longitud":-58.7448506,"ranking":24},
{"id":4338,"nombre":"Napaleofu","localidad":"Napaleofu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4346,"nombre":"Villa Clarita","localidad":"Villa Clarita","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4355,"nombre":"Las Flores","localidad":"Las Flores","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4356,"nombre":"Ca.Irrustipe","localidad":"Casa Irrustipe","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4357,"nombre":"El Pescado","localidad":"El Pescado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9603719,"longitud":-57.77748749999999,"ranking":5},
{"id":4358,"nombre":"El Pino","localidad":"El Pino","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0111955,"longitud":-57.6903352,"ranking":38},
{"id":4359,"nombre":"Bavio","localidad":"Bavio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0855709,"longitud":-57.7477422,"ranking":0},
{"id":14566,"nombre":"Bavio Acceso","localidad":"Bavio","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0129355,"longitud":-57.69076450000001,"ranking":7},
{"id":4361,"nombre":"Anchorena Acceso","localidad":"Anchorena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.927073908079244,"longitud":-60.38102371062958,"ranking":0},
{"id":4362,"nombre":"Medanos","localidad":"Medanos","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4363,"nombre":"Bonifacio","localidad":"Bonifacio","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.255845174242847,"longitud":-58.121464124545604,"ranking":0},
{"id":4367,"nombre":"Pieres","localidad":"pieres","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.391317,"longitud":-58.673244,"ranking":14},
{"id":4370,"nombre":"Jeppener","localidad":"Jeppener","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.27710029071836,"longitud":-58.20217335040264,"ranking":0},
{"id":10602,"nombre":"Jeppener Acceso","localidad":"Jeppener","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.2657137,"longitud":-58.21991939999999,"ranking":0},
{"id":4372,"nombre":"Pablo Acosta","localidad":"Pablo Acosta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4373,"nombre":"Blanca Grande","localidad":"Blanca Grande","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.53385970647905,"longitud":-60.88554841448106,"ranking":0},
{"id":4374,"nombre":"Villa Sanz","localidad":"Villa Sanz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8360,"nombre":"Villa Sanz Acceso","localidad":"Villa Sanz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.96397304397168,"longitud":-60.97345400357038,"ranking":0},
{"id":4376,"nombre":"Zavalia","localidad":"Zavalia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.895302232549895,"longitud":-61.007591384151965,"ranking":0},
{"id":4375,"nombre":"Santos Unzue","localidad":"Santos Unzue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.75148052578348,"longitud":-60.85049674629769,"ranking":0},
{"id":4377,"nombre":"La Gloria","localidad":"La Gloria","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4379,"nombre":"Cortaderas","localidad":"Cortaderas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4380,"nombre":"Punta Medanos","localidad":"Punta Medanos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4381,"nombre":"La Zanja","localidad":"La Zanja","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4382,"nombre":"El Boqueron","localidad":"El Boqueron","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4383,"nombre":"Bocayuba","localidad":"Bocayuba","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4384,"nombre":"Costa Bonita","localidad":"Costa Bonita","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4385,"nombre":"Irene","localidad":"Irene","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.5552670929441,"longitud":-60.69728279166158,"ranking":0},
{"id":4387,"nombre":"Bajo Hondo","localidad":"Bajo Hondo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4388,"nombre":"Arroyo Corto","localidad":"Arroyo Corto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.558144,"longitud":-62.28653,"ranking":0},
{"id":4391,"nombre":"Tomangueyu","localidad":"Tomangueyu","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4392,"nombre":"Orense","localidad":"Orense","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.6833138,"longitud":-59.7758179,"ranking":0},
{"id":4393,"nombre":"Otamendi","localidad":"Otamendi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.11060823958516,"longitud":-57.842325993867036,"ranking":0},
{"id":12324,"nombre":"Otamendi Acceso","localidad":"Otamendi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.0877322,"longitud":-57.8753717,"ranking":0},
{"id":4394,"nombre":"San Agustin","localidad":"San Agustin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9896,"nombre":"San Agustin Cruce","localidad":"San Agustin","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4395,"nombre":"De la Garma","localidad":"De la Garma","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4396,"nombre":"Estafeta","localidad":"Estafeta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4397,"nombre":"Batan","localidad":"Batan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4398,"nombre":"Mechongue","localidad":"Mechongue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.14597312025072,"longitud":-58.224173258050094,"ranking":0},
{"id":12325,"nombre":"Mechongue Acceso","localidad":"Mechongue","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.2305122,"longitud":-58.08761939999999,"ranking":0},
{"id":4399,"nombre":"El Triunfo","localidad":"El Triunfo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2086,"nombre":"Claromeco","localidad":"Claromeco","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.851544,"longitud":-60.07191,"ranking":8},
{"id":4414,"nombre":"Londres","localidad":"Londres","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.712740013526847,"longitud":-67.13612633253443,"ranking":161},
{"id":4415,"nombre":"Piquillin","localidad":"Piquillin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.300317,"longitud":-63.759046,"ranking":1},
{"id":4416,"nombre":"Punta de los Llanos","localidad":"Punta de los Llanos","provincia":"La Rioja","pais":"ARGENTINA","latitud":-30.152995438114996,"longitud":-66.54812095896747,"ranking":0},
{"id":4429,"nombre":"Bonpland","localidad":"Bonpland","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.48255818303729,"longitud":-55.47204077624025,"ranking":0},
{"id":4430,"nombre":"Bonpland Acceso","localidad":"Bonpland","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.482759359989252,"longitud":-55.47244799731124,"ranking":0},
{"id":4435,"nombre":"Los Conquistadores","localidad":"Los Conquistadores","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.5949646170412,"longitud":-58.46431629493125,"ranking":0},
{"id":4436,"nombre":"Los Conquistadores Acceso","localidad":"Los Conquistadores","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.582402003254966,"longitud":-58.45825693563457,"ranking":0},
{"id":4437,"nombre":"Martires","localidad":"Colonia Martires","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.422101620464485,"longitud":-55.379956185658074,"ranking":0},
{"id":4438,"nombre":"Martires Acceso","localidad":"Colonia Martires","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.42171,"longitud":-55.3789356,"ranking":0},
{"id":4584,"nombre":"Arroyo Martires","localidad":"Colonia Martires","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4444,"nombre":"Chañarito","localidad":"Chañarito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.402873643272127,"longitud":-63.330568735239815,"ranking":0},
{"id":4445,"nombre":"El Fuerte","localidad":"El Fuerte","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4446,"nombre":"Jean Maire","localidad":"Jean Maire","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4453,"nombre":"Centinela Acceso","localidad":"Centinela","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4457,"nombre":"Gobernador Gregores","localidad":"Gobernador Gregores","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-48.750108,"longitud":-70.248741,"ranking":0},
{"id":15572,"nombre":"Estancia Santa Thelma","localidad":"Gobernador Gregores","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-48.5565022,"longitud":-70.4463852,"ranking":0},
{"id":4469,"nombre":"Antillas","localidad":"Antillas","provincia":"Salta","pais":"ARGENTINA","latitud":-26.11888704246020,"longitud":-64.59513366001475,"ranking":0},
{"id":4471,"nombre":"Ceibalito","localidad":"Ceibalito","provincia":"Salta","pais":"ARGENTINA","latitud":-25.10846427937402,"longitud":-64.2738203235454,"ranking":0},
{"id":4474,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4477,"nombre":"Desmochado Acceso","localidad":"Desmochado","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.780750708749594,"longitud":-59.036350719038566,"ranking":0},
{"id":4562,"nombre":"Desmochado","localidad":"Desmochado","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.7909114,"longitud":-58.9869553,"ranking":6},
{"id":4482,"nombre":"Tunalito","localidad":"Tunalito","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4483,"nombre":"Cieneguillas","localidad":"Cieneguillas","provincia":"Jujuy","pais":"ARGENTINA","latitud":-22.100706961751587,"longitud":-22.100706961751587,"ranking":0},
{"id":4490,"nombre":"Andresito","localidad":"Comandante Andresito","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.68404160185563,"longitud":-54.0449463248247,"ranking":0},
{"id":4494,"nombre":"Villa Ribera","localidad":"Villa Ribera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4500,"nombre":"Jaramillo","localidad":"Jaramillo","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.18444092249533,"longitud":-67.14489933512533,"ranking":9},
{"id":4501,"nombre":"Pastos Blancos","localidad":"Pastos Blancos","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4502,"nombre":"Zuviria","localidad":"Zuviria","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4513,"nombre":"Arboleda","localidad":"Arboleda","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4516,"nombre":"Puntilla","localidad":"La Puntilla","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4509,"nombre":"Vista Flores","localidad":"Vista Flores","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4498,"nombre":"Colonia Belgrano","localidad":"Colonia Belgrano","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4497,"nombre":"Lopez","localidad":"Lopez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4499,"nombre":"Los Sembrados","localidad":"Los Sembrados","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4496,"nombre":"San Eugenio","localidad":"San Eugenio","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4517,"nombre":"Steindl","localidad":"Steindl","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4525,"nombre":"Villa Bastias","localidad":"Villa Bastias","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14280,"nombre":"San Expedito","localidad":"Bermejo","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.5889201,"longitud":-67.65972409999999,"ranking":0},
{"id":4532,"nombre":"Monte Leña","localidad":"Monte Leña","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4534,"nombre":"Funes","localidad":"Funes","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4536,"nombre":"Ancon","localidad":"Ancon","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7999,"nombre":"Andacollo","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-30.235138,"longitud":-71.085516,"ranking":0},
{"id":8000,"nombre":"Conbarbala","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-31.176932,"longitud":-71.002202,"ranking":0},
{"id":8001,"nombre":"Coquimbo","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-29.955987,"longitud":-71.337493,"ranking":0},
{"id":8003,"nombre":"Illapel","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-31.638368,"longitud":-71.176377,"ranking":0},
{"id":8004,"nombre":"Los Vilos","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-31.900087,"longitud":-71.490213,"ranking":0},
{"id":8314,"nombre":"Ovalle","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-30.60677,"longitud":-71.194883,"ranking":0},
{"id":8315,"nombre":"Pichidangui","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-32.140307,"longitud":-71.524543,"ranking":0},
{"id":8317,"nombre":"Vicuña","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-30.0352866,"longitud":-70.7121963,"ranking":0},
{"id":9104,"nombre":"La Herradura","localidad":"Coquimbo","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-29.988855,"longitud":-71.33785,"ranking":0},
{"id":8002,"nombre":"Guanaqueros","localidad":"Guanaqueros","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-30.1973493,"longitud":-71.4224107,"ranking":0},
{"id":8316,"nombre":"Tongoy","localidad":"Tongoy","provincia":"Región IV -  Coquimbo","pais":"CHILE","latitud":-30.255774,"longitud":-71.494838,"ranking":0},
{"id":4545,"nombre":"Parador de Los Reyes","localidad":"Iriarte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.429231,"longitud":-61.947837,"ranking":0},
{"id":8498,"nombre":"Iriarte","localidad":"Iriarte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.41478251034333,"longitud":-61.92874536352001,"ranking":0},
{"id":4552,"nombre":"Capiz Alto","localidad":"Capiz Alto","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4561,"nombre":"San Miguel Acceso","localidad":"San Miguel","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.9872165,"longitud":-57.6032474,"ranking":1},
{"id":5085,"nombre":"Paraje Caiman","localidad":"San Miguel","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.037965,"longitud":-57.686472,"ranking":0},
{"id":8280,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.992357,"longitud":-57.595886,"ranking":32},
{"id":4569,"nombre":"Arroyo Magdalena","localidad":"Arroyo Magdalena","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4667,"nombre":"Arroyo Magdalena Acceso","localidad":"Arroyo Magdalena","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.238517100,"longitud":-54.597909500,"ranking":0},
{"id":4572,"nombre":"Cerro Cora Acceso","localidad":"Cerro Cora","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4573,"nombre":"San Francisco de Asis","localidad":"San Francisco de Asis","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.46260925201562,"longitud":-54.745403354158846,"ranking":0},
{"id":4574,"nombre":"Alba Posse","localidad":"Alba Posse","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.56948994005728,"longitud":-54.68389132633022,"ranking":2},
{"id":8535,"nombre":"Estancia Mavalle","localidad":"Alba Posse","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8536,"nombre":"Pindayty","localidad":"Alba Posse","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.5698231170235,"longitud":-54.682703777983335,"ranking":0},
{"id":8537,"nombre":"Tres Arroyos","localidad":"Alba Posse","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8538,"nombre":"Urutau","localidad":"Alba Posse","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4575,"nombre":"Santa Rita","localidad":"Santa Rita","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4576,"nombre":"9 de Julio","localidad":"Santa Rita","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.523083712043636,"longitud":-54.72794458365871,"ranking":0},
{"id":4578,"nombre":"Canal Torto","localidad":"Santa Rita","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8595,"nombre":"Cruce Mailau","localidad":"Santa Rita","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.47766919163841,"longitud":-54.648187630934956,"ranking":0},
{"id":8596,"nombre":"Cruce Saltiño","localidad":"Santa Rita","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4577,"nombre":"Colonia Acaragua","localidad":"Acaragua","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.481760064849595,"longitud":-54.84442146527454,"ranking":0},
{"id":4579,"nombre":"Arroyo Acaragua","localidad":"Acaragua","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.47998551527882,"longitud":-54.84557334497077,"ranking":0},
{"id":4580,"nombre":"Villa Union","localidad":"Villa Bonita","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4581,"nombre":"Villa Bonita","localidad":"Villa Bonita","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.46149187557313,"longitud":-55.86309113095821,"ranking":0},
{"id":4582,"nombre":"Campo Ramon","localidad":"Campo Ramon","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.455929746186516,"longitud":-55.02441290293834,"ranking":0},
{"id":4583,"nombre":"El Soberbio","localidad":"El Soberbio","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.300515,"longitud":-54.194115,"ranking":59},
{"id":4585,"nombre":"Arroio do Sal","localidad":"Balneário Arroio do Silva","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4586,"nombre":"Capao da Canoa","localidad":"Capão da Canoa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.756428,"longitud":-50.0194982,"ranking":0},
{"id":14578,"nombre":"Arroio Teixeira","localidad":"Capão da Canoa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.642441,"longitud":-49.957261,"ranking":0},
{"id":4588,"nombre":"Salto Angelo","localidad":"Santo Ângelo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4589,"nombre":"Ijui","localidad":"Ijuí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.3778,"longitud":-53.8954,"ranking":1},
{"id":4592,"nombre":"Cruz Alta","localidad":"Cruz Alta","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.6466,"longitud":-53.5902,"ranking":0},
{"id":4591,"nombre":"Sauce","localidad":"Sauce","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.085511435375153,"longitud":-58.7872697001481,"ranking":23},
{"id":4593,"nombre":"Rincón de Animas","localidad":"Sauce","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4598,"nombre":"Cinco Bocas","localidad":"Sauce","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4600,"nombre":"Tapera","localidad":"Sauce","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.09080964640085,"longitud":-58.6796574339962,"ranking":0},
{"id":4605,"nombre":"El Molino","localidad":"Sauce","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4597,"nombre":"Carazinho","localidad":"Carazinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.27697786932094,"longitud":-52.776561759975316,"ranking":1},
{"id":4599,"nombre":"Soledade","localidad":"Soledade","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.811427354605318,"longitud":-52.50988799116808,"ranking":0},
{"id":4601,"nombre":"Lajeado","localidad":"Lajeado","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.452565471723933,"longitud":-51.978662610054016,"ranking":0},
{"id":4602,"nombre":"Estrela","localidad":"Estrela","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.50320476671237,"longitud":-51.96504879004352,"ranking":0},
{"id":4606,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.86389368520614,"longitud":-54.47696984125934,"ranking":0},
{"id":4607,"nombre":"Tramandai","localidad":"Tramandaí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.02026725295237,"longitud":-50.14279269751868,"ranking":0},
{"id":10031,"nombre":"Tramandai Sul","localidad":"Tramandaí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.987517711813865,"longitud":-50.14118797314582,"ranking":0},
{"id":10032,"nombre":"Oasis Sul","localidad":"Tramandaí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.05407853049149,"longitud":-50.16237366276501,"ranking":0},
{"id":10115,"nombre":"Oro Verde Acceso","localidad":"Colonia Oro Verde","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.89055276252165,"longitud":-55.055502116015404,"ranking":0},
{"id":4612,"nombre":"Tres de Mayo","localidad":"Tres de mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4662,"nombre":"Tres de Mayo Acceso","localidad":"Tres de mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.762914100,"longitud":-54.889932500,"ranking":0},
{"id":9085,"nombre":"Lujan","localidad":"Tres de mayo","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4613,"nombre":"Arroyo Caraguatay","localidad":"Caraguatay","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4663,"nombre":"Caraguatay Acceso","localidad":"Caraguatay","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.623061000,"longitud":-54.744186400,"ranking":0},
{"id":8545,"nombre":"Avellaneda","localidad":"Caraguatay","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8546,"nombre":"Caraguatay","localidad":"Caraguatay","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.65728431898772,"longitud":-54.73950507244208,"ranking":0},
{"id":4616,"nombre":"Puerto Leoni","localidad":"Puerto Leoni","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.9861759,"longitud":-55.1658539,"ranking":0},
{"id":4659,"nombre":"Puerto Leoni Acceso","localidad":"Puerto Leoni","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.999413800,"longitud":-55.151968000,"ranking":0},
{"id":4619,"nombre":"Cura Palihue","localidad":"Cura Palihue","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4620,"nombre":"La Cautiva","localidad":"La Cautiva","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.979809,"longitud":-64.082529,"ranking":6},
{"id":4622,"nombre":"General Paunero","localidad":"General Paunero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.87665503558714,"longitud":-65.02488221757059,"ranking":0},
{"id":4623,"nombre":"Tres Lomas","localidad":"Tres Lomas","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4624,"nombre":"Washington","localidad":"Washington","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.87414820838321,"longitud":-64.69164527198019,"ranking":0},
{"id":4628,"nombre":"Carrodilla","localidad":"Carrodilla","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.96593461305118,"longitud":-68.86686337515735,"ranking":0},
{"id":4630,"nombre":"Calice","localidad":"Calice","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4632,"nombre":"Perdriel","localidad":"Perdriel","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4639,"nombre":"Tafi del Valle","localidad":"Tafi del Valle","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.85429841377429,"longitud":-65.70507248427035,"ranking":288},
{"id":9212,"nombre":"El Mollar","localidad":"Rosario de Lerma","provincia":"Salta","pais":"ARGENTINA","latitud":-24.985974584051853,"longitud":-65.57827520488904,"ranking":0},
{"id":4641,"nombre":"Pozo del Tigre","localidad":"Pozo del Tigre","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.9018903,"longitud":-60.3259459,"ranking":0},
{"id":4644,"nombre":"Cafayate","localidad":"Cafayate","provincia":"Salta","pais":"ARGENTINA","latitud":-26.0730798,"longitud":-65.9760520,"ranking":86},
{"id":4646,"nombre":"Rio Blanco","localidad":"Rio Blanco","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4648,"nombre":"Laborde","localidad":"Laborde","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.156282,"longitud":-62.858539,"ranking":7},
{"id":4649,"nombre":"Chucalezna Acceso","localidad":"Chucalezna","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4651,"nombre":"Arroio do Silva","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10027,"nombre":"Arroio do Sal","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.54638798120115,"longitud":-49.892270402533306,"ranking":0},
{"id":14419,"nombre":"Bom Jesus","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.6665841,"longitud":-50.4362062,"ranking":0},
{"id":14481,"nombre":"Areias Brancas","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5398277,"longitud":-49.876841,"ranking":0},
{"id":14482,"nombre":"Camboim","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5348506,"longitud":-49.8729368,"ranking":0},
{"id":14483,"nombre":"Praia Azul","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4746478,"longitud":-49.8301252,"ranking":0},
{"id":14485,"nombre":"Rondinha","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4988618,"longitud":-49.8525122,"ranking":0},
{"id":15233,"nombre":"Posto Bolao","localidad":"Arroio do Sal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.54621610374577,"longitud":-49.89223287480745,"ranking":0},
{"id":4652,"nombre":"San Jose","localidad":"San Jose","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.762514,"longitud":-58.33834439999999,"ranking":0},
{"id":4653,"nombre":"Barker","localidad":"Barker","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9814,"nombre":"Barker Acceso","localidad":"Barker","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.639072,"longitud":-59.402374,"ranking":1},
{"id":4654,"nombre":"San Manuel","localidad":"San Manuel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":47},
{"id":9811,"nombre":"San Manuel Acceso","localidad":"San Manuel","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.785668,"longitud":-58.850829,"ranking":2},
{"id":4655,"nombre":"Loreto Acceso","localidad":"Loreto","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.315844900,"longitud":-55.545072600,"ranking":0},
{"id":5525,"nombre":"Loreto","localidad":"Loreto","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.31027354134535,"longitud":-55.538920319520585,"ranking":0},
{"id":4656,"nombre":"Corpus Acceso","localidad":"Corpus","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.179337200,"longitud":-55.456870800,"ranking":0},
{"id":8553,"nombre":"Corpus","localidad":"Corpus","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.1292,"longitud":-55.512189,"ranking":0},
{"id":4657,"nombre":"Ñacanguazu Acceso","localidad":"Ñacanguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5539,"nombre":"Ñacanguazu","localidad":"Ñacanguazu","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4658,"nombre":"H. Irigoyen Acceso","localidad":"H. Irigoyen","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.455026700,"longitud":-55.858826600,"ranking":0},
{"id":4660,"nombre":"Mineral Acceso","localidad":"Mineral","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.425414100,"longitud":-55.621032700,"ranking":0},
{"id":8586,"nombre":"Puerto Mineral","localidad":"Mineral","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.954123531256368,"longitud":-55.12638976461801,"ranking":0},
{"id":4661,"nombre":"Mbopicua Acceso","localidad":"Mbopicua","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.399937000,"longitud":-55.942908500,"ranking":0},
{"id":4664,"nombre":"Colonia Victoria Acceso","localidad":"Colonia Victoria","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.454188900,"longitud":-55.862002400,"ranking":0},
{"id":5661,"nombre":"Colonia Victoria","localidad":"Colonia Victoria","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.331291505000884,"longitud":-54.62324345115122,"ranking":0},
{"id":4665,"nombre":"Delicia Acceso","localidad":"Delicia","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.193211200,"longitud":-54.588312500,"ranking":0},
{"id":8577,"nombre":"Delicia","localidad":"Delicia","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.190162412398216,"longitud":-54.588698619084134,"ranking":0},
{"id":4666,"nombre":"Bossetti Acceso","localidad":"Bossetti","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.622117400,"longitud":-54.551689600,"ranking":0},
{"id":8576,"nombre":"Puerto Bosetti","localidad":"Bossetti","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.8636553,"longitud":-54.56511219999999,"ranking":0},
{"id":4671,"nombre":"Punta Carranza","localidad":"Punta Carranza","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4683,"nombre":"Santa Juana","localidad":"Santa Juana","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4684,"nombre":"Naranjo","localidad":"Naranjo","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4685,"nombre":"El Tropezon","localidad":"El Tropezon","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.941678017496855,"longitud":-58.79084587097168,"ranking":0},
{"id":4686,"nombre":"Bajo Caracoles","localidad":"Bajo Caracoles","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.4445652,"longitud":-70.9253501,"ranking":0},
{"id":4687,"nombre":"Cerro Torre","localidad":"Cerro Torre","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4688,"nombre":"Estancias","localidad":"Estancias","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4689,"nombre":"Tres Lagos","localidad":"Tres Lagos","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-49.598071,"longitud":-71.445564,"ranking":0},
{"id":4699,"nombre":"Chaiten","localidad":"Chaiten","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-42.91565937348574,"longitud":-72.71140679096831,"ranking":0},
{"id":4694,"nombre":"Puerto Guadal","localidad":"Puerto Guadal","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-46.84463,"longitud":-72.704894,"ranking":0},
{"id":4692,"nombre":"La Junta","localidad":"La Junta","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-43.969952,"longitud":-72.400472,"ranking":0},
{"id":4690,"nombre":"Chile Chico","localidad":"Chile Chico","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-46.53805,"longitud":-71.729382,"ranking":0},
{"id":4693,"nombre":"Piedra del Gato","localidad":"Piedra del Gato","provincia":"Zona Sur Chile","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4695,"nombre":"Puyuhuapi","localidad":"Puyuhuapi","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-44.325005,"longitud":-72.557421,"ranking":0},
{"id":4696,"nombre":"Puerto Tranquilo","localidad":"Puerto Tranquilo","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-46.62342,"longitud":-72.67383,"ranking":0},
{"id":4697,"nombre":"Parque Nacional Queulat","localidad":"Parque Nacional Queulat","provincia":"Zona Sur Chile","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4698,"nombre":"Santa Lucia","localidad":"Santa Lucia","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":null,"longitud":null,"ranking":0},
{"id":4700,"nombre":"Cueva de las Manos","localidad":"Cueva de las Manos","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-47.15912848263069,"longitud":-70.65772758656745,"ranking":0},
{"id":4702,"nombre":"Comallo","localidad":"Comallo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.031803,"longitud":-70.261236,"ranking":169},
{"id":9674,"nombre":"Subida Comallo","localidad":"Comallo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.031645,"longitud":-70.263822,"ranking":0},
{"id":9677,"nombre":"Tranquera Verde","localidad":"Comallo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4703,"nombre":"Maquinchao","localidad":"Maquinchao","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.2453,"longitud":-68.695709,"ranking":141},
{"id":4704,"nombre":"Los Menucos","localidad":"Los Menucos","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.84622969999999,"longitud":-68.0902369,"ranking":292},
{"id":4706,"nombre":"Pilcaniyeu","localidad":"Pilcaniyeu","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.123931,"longitud":-70.721325,"ranking":143},
{"id":4707,"nombre":"Ramos Mexia","localidad":"Ramos Mexia","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.508673,"longitud":-67.261548,"ranking":80},
{"id":4708,"nombre":"Sierra Colorada","localidad":"Sierra Colorada","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.58649,"longitud":-67.754372,"ranking":119},
{"id":4709,"nombre":"Valcheta","localidad":"Valcheta","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.687278,"longitud":-66.144847,"ranking":125},
{"id":4713,"nombre":"Sanabria","localidad":"Sanabria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.52758608828769,"longitud":-63.248110171712604,"ranking":0},
{"id":4717,"nombre":"Santa Victoria","localidad":"Santa Victoria","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4718,"nombre":"Pascanas","localidad":"Pascanas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.1261291,"longitud":-63.04090289999999,"ranking":14},
{"id":4719,"nombre":"Wenceslao Escalante","localidad":"Wenceslao Escalante","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.175789,"longitud":-62.779513,"ranking":4},
{"id":4720,"nombre":"Monte Maiz","localidad":"Monte Maiz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.206889,"longitud":-62.601021,"ranking":29},
{"id":4721,"nombre":"Isla Verde","localidad":"Isla Verde","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.237258,"longitud":-62.399632,"ranking":21},
{"id":4722,"nombre":"Bernardo de O Higgins","localidad":"Bernardo de O Higgins","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.24792292064783,"longitud":-62.269417551558924,"ranking":0},
{"id":8735,"nombre":"O´Higgins Acceso","localidad":"Bernardo de O Higgins","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.263816653712915,"longitud":-62.27259480718202,"ranking":0},
{"id":4724,"nombre":"Azotey","localidad":"Azotey","provincia":"Concepción","pais":"Paraguay","latitud":-23.32083,"longitud":-56.489315,"ranking":0},
{"id":4725,"nombre":"Caazapá","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.196454,"longitud":-56.37071,"ranking":9},
{"id":12573,"nombre":"Vizcaino Cue","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1960767,"longitud":-56.3709821,"ranking":0},
{"id":12584,"nombre":"Sosa","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1939095,"longitud":-56.36728189999999,"ranking":0},
{"id":12592,"nombre":"Loma Linda","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.640280675976275,"longitud":-56.26034461082787,"ranking":0},
{"id":12599,"nombre":"Potrero Aguara","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-25.409880763127642,"longitud":-56.41481860803967,"ranking":0},
{"id":14633,"nombre":"Fulgencio Yegros","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.455564737337074,"longitud":-56.403768897089336,"ranking":0},
{"id":10045,"nombre":"General Morinigo","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.025572,"longitud":-56.084559,"ranking":0},
{"id":10049,"nombre":"Moises Bertoni","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.357595,"longitud":-56.488209,"ranking":0},
{"id":12559,"nombre":"Tembiapo Renda","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.2149164,"longitud":-55.3859828,"ranking":0},
{"id":12569,"nombre":"Santa Ursula","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.3944768,"longitud":-56.1172041,"ranking":0},
{"id":12570,"nombre":"Yataity","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.3769177,"longitud":-56.0663639,"ranking":0},
{"id":8118,"nombre":"Yegros","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.461588,"longitud":-56.402988,"ranking":0},
{"id":8121,"nombre":"San Juan Nepomuceno","localidad":"Caazapa","provincia":"Caazapá","pais":"Paraguay","latitud":-26.108229,"longitud":-55.941141,"ranking":15},
{"id":4726,"nombre":"Itaugua","localidad":"Itaugua","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.392231,"longitud":-57.35512,"ranking":19},
{"id":14248,"nombre":"Hospital Nacional de Itaugua","localidad":"Itaugua","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.4479985,"longitud":-57.3621203,"ranking":0},
{"id":4727,"nombre":"Ñemby","localidad":"Ñemby","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.373988,"longitud":-57.552997,"ranking":0},
{"id":4728,"nombre":"Pedro Juan Caballero","localidad":"Pedro Juan Caballero","provincia":"Amambay","pais":"Paraguay","latitud":-22.553914,"longitud":-55.74645,"ranking":386},
{"id":4729,"nombre":"Rio Verde","localidad":"Rio Verde","provincia":"San Pedro","pais":"Paraguay","latitud":-23.7001,"longitud":-56.482229,"ranking":0},
{"id":4730,"nombre":"Rotonda Limpio","localidad":"Limpio","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.177199,"longitud":-57.487048,"ranking":13},
{"id":4731,"nombre":"Santani - San Estanislao","localidad":"Santani","provincia":"San Pedro","pais":"Paraguay","latitud":-24.665656,"longitud":-56.439898,"ranking":110},
{"id":4732,"nombre":"Tebicuary","localidad":"Tebicuary","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.781344,"longitud":-56.647264,"ranking":0},
{"id":4733,"nombre":"Yby Yau","localidad":"Yby Yau","provincia":"Concepción","pais":"Paraguay","latitud":-22.963386,"longitud":-56.540563,"ranking":7},
{"id":4736,"nombre":"Cambe","localidad":"Londrina","provincia":"Parana","pais":"BRASIL","latitud":-23.29051741698222,"longitud":-51.2789034219863,"ranking":0},
{"id":5079,"nombre":"Londrina","localidad":"Londrina","provincia":"Parana","pais":"BRASIL","latitud":-23.309547391002525,"longitud":-51.14936113357544,"ranking":7},
{"id":4738,"nombre":"Itapecerica da Serra","localidad":"Itapecerica da Serra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.742296248412714,"longitud":-46.80040966881896,"ranking":0},
{"id":2642,"nombre":"Biguacu","localidad":"Biguaçu","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.491865718696726,"longitud":-48.65635495813846,"ranking":0},
{"id":4066,"nombre":"Ourinhos","localidad":"Ourinhos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.9674216,"longitud":-49.8665509,"ranking":0},
{"id":4748,"nombre":"Yuqueri","localidad":"Yuqueri","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.383503722743598,"longitud":-58.11692828756273,"ranking":0},
{"id":4754,"nombre":"Las Moscas","localidad":"Las Moscas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.09216732939153,"longitud":-58.96042392578489,"ranking":0},
{"id":4756,"nombre":"Libaros","localidad":"Libaros","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.25979680401496,"longitud":-58.90848692543088,"ranking":0},
{"id":4757,"nombre":"El Quebracho","localidad":"El Quebracho","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4760,"nombre":"Gobernador Urquiza","localidad":"Gobernador Urquiza","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2869,"nombre":"Juan Emilio Oleary","localidad":"Juan Emilio O'Leary","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.422383,"longitud":-55.377852,"ranking":9},
{"id":4115,"nombre":"Santa Rita","localidad":"Santa Rita","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.799341,"longitud":-55.091444,"ranking":3},
{"id":8600,"nombre":"Cruce Iruña","localidad":"Santa Rita","provincia":"Alto Parana","pais":"Paraguay","latitud":-26.106636,"longitud":-55.054262,"ranking":0},
{"id":4493,"nombre":"Jose Domingo Ocampos","localidad":"Jose Domingo Ocampos","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.412516,"longitud":-55.429247,"ranking":9},
{"id":4116,"nombre":"Bella Vista Acceso","localidad":"Bella Vista","provincia":"Itapua","pais":"Paraguay","latitud":-27.043142,"longitud":-55.578387,"ranking":0},
{"id":8129,"nombre":"Bella Vista","localidad":"Bella Vista","provincia":"Itapua","pais":"Paraguay","latitud":-27.043606,"longitud":-55.577864,"ranking":1},
{"id":2658,"nombre":"Ayolas Acceso","localidad":"Ayolas","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-27.39288,"longitud":-56.868048,"ranking":0},
{"id":14494,"nombre":"Ayolas","localidad":"Ayolas","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-27.3839655,"longitud":-56.8437612,"ranking":8},
{"id":4762,"nombre":"San Marcos Sierras","localidad":"San Marcos Sierras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.783341,"longitud":-64.646222,"ranking":28},
{"id":4752,"nombre":"Algorta","localidad":"Algorta","provincia":"Rio Negro","pais":"URUGUAY","latitud":-32.419785243716845,"longitud":-57.387529820609075,"ranking":0},
{"id":4753,"nombre":"Merinos","localidad":"Merinos","provincia":"Rio Negro","pais":"URUGUAY","latitud":-32.38695543397918,"longitud":-56.91233999049745,"ranking":0},
{"id":4759,"nombre":"Tres Arboles","localidad":"Tres Arboles","provincia":"Rio Negro","pais":"URUGUAY","latitud":-32.63584048710465,"longitud":-56.73149154040967,"ranking":0},
{"id":1893,"nombre":"La Capilla","localidad":"La Capilla","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.60048477070932,"longitud":-58.875181037364044,"ranking":0},
{"id":1900,"nombre":"Villa Clara","localidad":"Villa Clara","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.823590410913276,"longitud":-58.81898894819854,"ranking":0},
{"id":14267,"nombre":"Villa Clara Acceso","localidad":"Villa Clara","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.8112184,"longitud":-58.816069,"ranking":0},
{"id":4740,"nombre":"Dominguez","localidad":"Dominguez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.98653103717069,"longitud":-58.961401105775856,"ranking":0},
{"id":1892,"nombre":"Jubileo","localidad":"Jubileo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.734595247488272,"longitud":-58.63258028122509,"ranking":0},
{"id":14266,"nombre":"Jubileo Acceso","localidad":"Jubileo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.7295105,"longitud":-58.63883970000001,"ranking":0},
{"id":4741,"nombre":"Enrique Carbo","localidad":"Enrique Carbo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4742,"nombre":"Faustino Parera","localidad":"Faustino Parera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.8022739630969,"longitud":-58.88286880843579,"ranking":0},
{"id":4746,"nombre":"Irazusta","localidad":"Irazusta","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1821,"nombre":"Gilbert","localidad":"Gilbert","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.53270918481474,"longitud":-58.92888616165538,"ranking":0},
{"id":12384,"nombre":"Gilbert Acceso","localidad":"Gilbert","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.5336893,"longitud":-58.9201604,"ranking":0},
{"id":1794,"nombre":"Yerua","localidad":"Yerua","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.491804255652397,"longitud":-58.171273755453456,"ranking":0},
{"id":4747,"nombre":"Paso de los Toros","localidad":"Paso de los Toros","provincia":"Tacuarembó","pais":"URUGUAY","latitud":-32.80756436620364,"longitud":-56.51149325840284,"ranking":19},
{"id":4749,"nombre":"Chamberlian","localidad":"Chamberlian","provincia":"Tacuarembó","pais":"URUGUAY","latitud":-32.68787322633184,"longitud":-56.47711624664969,"ranking":0},
{"id":4755,"nombre":"Francia","localidad":"Francia","provincia":"Rio Negro","pais":"URUGUAY","latitud":-32.545021511209875,"longitud":-56.62377295054936,"ranking":0},
{"id":4758,"nombre":"Queguay","localidad":"Queguay","provincia":"Paysandu","pais":"URUGUAY","latitud":-32.07777837009424,"longitud":-57.921024222961805,"ranking":0},
{"id":4751,"nombre":"Guichon","localidad":"Guichon","provincia":"Paysandu","pais":"URUGUAY","latitud":-32.36044563250253,"longitud":-57.20027682393136,"ranking":0},
{"id":4764,"nombre":"La Criolla","localidad":"La Criolla","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.269932668207645,"longitud":-58.107139879536994,"ranking":0},
{"id":4765,"nombre":"Labougle","localidad":"Labougle","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.316443190792025,"longitud":-57.729998527544815,"ranking":0},
{"id":4766,"nombre":"Magnasco","localidad":"Magnasco","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4767,"nombre":"San Salvador","localidad":"San Salvador","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4768,"nombre":"Santa Ana","localidad":"Santa Ana","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4770,"nombre":"Bonpland","localidad":"Bonpland","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.820135482210624,"longitud":-57.43052182269268,"ranking":0},
{"id":15578,"nombre":"Chiclayo - Terminal Ormeño","localidad":"Chiclayo","provincia":"Chiclayo","pais":"Perú","latitud":-6.779140399999999,"longitud":-79.8354518,"ranking":0},
{"id":15579,"nombre":"Los Incas","localidad":"Chiclayo","provincia":"Chiclayo","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15600,"nombre":"Mocupe","localidad":"Chiclayo","provincia":"Chiclayo","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15404,"nombre":"Piura","localidad":"Piura","provincia":"Piura","pais":"Perú","latitud":-5.183211109917026,"longitud":-80.65281529327738,"ranking":40},
{"id":7007,"nombre":"Rio Branco","localidad":"Rio Branco","provincia":"Acre","pais":"BRASIL","latitud":-32.5823998,"longitud":-53.3780173,"ranking":2},
{"id":4797,"nombre":"Maceio","localidad":"Maceió","provincia":"Alagoas","pais":"BRASIL","latitud":-9.645909,"longitud":-35.72543,"ranking":2},
{"id":7006,"nombre":"Macapa","localidad":"Macapá","provincia":"Amapá","pais":"BRASIL","latitud":0.07305800947725233,"longitud":-51.05471346012081,"ranking":0},
{"id":8775,"nombre":"Manaus","localidad":"Manaus","provincia":"Amazonas","pais":"BRASIL","latitud":-3.0746659,"longitud":-60.02540920000001,"ranking":23},
{"id":6428,"nombre":"Ilheus","localidad":"Ilhéus","provincia":"Bahia","pais":"BRASIL","latitud":-14.796631,"longitud":-39.058734,"ranking":60},
{"id":9937,"nombre":"Banco da Vitoria","localidad":"Ilhéus","provincia":"Bahia","pais":"BRASIL","latitud":-14.77983442610474,"longitud":-39.10080025752865,"ranking":0},
{"id":9963,"nombre":"Tapera","localidad":"Ilhéus","provincia":"Bahia","pais":"BRASIL","latitud":-14.789860709813157,"longitud":-39.04396290949924,"ranking":0},
{"id":6515,"nombre":"Porto Seguro","localidad":"Porto Seguro","provincia":"Bahia","pais":"BRASIL","latitud":-16.4378259,"longitud":-39.0690022,"ranking":16},
{"id":4806,"nombre":"Salvador de Bahia","localidad":"Salvador","provincia":"Bahia","pais":"BRASIL","latitud":-12.97826538647362,"longitud":-38.46591353416443,"ranking":33},
{"id":15453,"nombre":"Aeropuerto Salvador de Bahia","localidad":"Salvador","provincia":"Bahia","pais":"BRASIL","latitud":-12.910991165559285,"longitud":-38.33140023494523,"ranking":0},
{"id":15454,"nombre":"Morro de Sao Paulo","localidad":"Salvador","provincia":"Bahia","pais":"BRASIL","latitud":-12.977747913018225,"longitud":-38.46585246374706,"ranking":0},
{"id":4810,"nombre":"Fortaleza","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.75717,"longitud":-38.53121,"ranking":59},
{"id":5669,"nombre":"Ceasa","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6746,"nombre":"Hotel Amuarama","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.7576290267430035,"longitud":-38.53083894133349,"ranking":0},
{"id":6811,"nombre":"Messejana","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.8309646754612787,"longitud":-38.500369655613746,"ranking":0},
{"id":6845,"nombre":"Pedras","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7355,"nombre":"Rodoviaria Engenheiro Joao Thome","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.7574775310181585,"longitud":-38.53116580927462,"ranking":0},
{"id":14823,"nombre":"Aeroporto Internacional de Fortaleza","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.7771555,"longitud":-38.53309660000001,"ranking":0},
{"id":14877,"nombre":"Siqueira","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15264,"nombre":"Beira Mar","localidad":"Fortaleza","provincia":"Ceará","pais":"BRASIL","latitud":-3.7571820237595044,"longitud":-38.53099023352258,"ranking":0},
{"id":4813,"nombre":"Brasilia","localidad":"Brasília","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.8306574,"longitud":-47.9487616,"ranking":2},
{"id":6080,"nombre":"Planaltina","localidad":"Brasília","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.791314259305702,"longitud":-47.882063745277726,"ranking":0},
{"id":6082,"nombre":"Sobradinho","localidad":"Brasília","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.650064743173157,"longitud":-47.78610706329346,"ranking":0},
{"id":14479,"nombre":"Plano Piloto","localidad":"Brasília","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.7934448,"longitud":-47.8834578,"ranking":0},
{"id":15251,"nombre":"Aeroporto do Brasilia","localidad":"Brasília","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.8697369,"longitud":-47.9172348,"ranking":0},
{"id":4830,"nombre":"Vitoria","localidad":"Vitória","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.320622983521268,"longitud":-40.35194635391235,"ranking":1},
{"id":15236,"nombre":"Aeroporto","localidad":"Vitória","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.2645585,"longitud":-40.28238289999999,"ranking":0},
{"id":15252,"nombre":"Praca do Papa","localidad":"Vitória","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.31664700543447,"longitud":-40.29676888463984,"ranking":0},
{"id":4946,"nombre":"Goiania","localidad":"Goiânia","provincia":"Goias","pais":"BRASIL","latitud":-16.659391,"longitud":-49.25964399999999,"ranking":0},
{"id":14551,"nombre":"Sao Miguel do Araguaia","localidad":"Goiânia","provincia":"Goias","pais":"BRASIL","latitud":-13.2764052,"longitud":-50.1669999,"ranking":0},
{"id":14552,"nombre":"Aruana","localidad":"Goiânia","provincia":"Goias","pais":"BRASIL","latitud":-14.92474,"longitud":-51.081488,"ranking":0},
{"id":10917,"nombre":"Minacu ","localidad":"Goiânia","provincia":"Goias","pais":"BRASIL","latitud":-13.5379803,"longitud":-48.22278,"ranking":0},
{"id":4877,"nombre":"Campinas","localidad":"Campinas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.9056994,"longitud":-47.0724836,"ranking":28},
{"id":14792,"nombre":"Aeroporto do Campinas","localidad":"Campinas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.008205,"longitud":-47.13756850000001,"ranking":0},
{"id":4951,"nombre":"Imperatriz","localidad":"Imperatriz","provincia":"Maranhão","pais":"BRASIL","latitud":-5.5036556,"longitud":-47.46866259999999,"ranking":0},
{"id":4843,"nombre":"Sao Luis","localidad":"São Luís","provincia":"Maranhão","pais":"BRASIL","latitud":-2.56387,"longitud":-44.24469999999999,"ranking":78},
{"id":6576,"nombre":"Cuiaba","localidad":"Cuiabá","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.58049,"longitud":-56.08983,"ranking":4},
{"id":15097,"nombre":"Coxipo","localidad":"Cuiabá","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.6321602,"longitud":-56.0506418,"ranking":0},
{"id":6846,"nombre":"Campo Grande","localidad":"Campo Grande","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.5137909,"longitud":-54.6022695,"ranking":19},
{"id":15229,"nombre":"Aeroporto","localidad":"Campo Grande","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.4686937,"longitud":-54.6741233,"ranking":0},
{"id":4818,"nombre":"Belo Horizonte","localidad":"Belo Horizonte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9140605,"longitud":-43.9417157,"ranking":12},
{"id":15279,"nombre":"Jose Candido","localidad":"Belo Horizonte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.8833868,"longitud":-43.91293659999999,"ranking":0},
{"id":15348,"nombre":"Turistico JK","localidad":"Belo Horizonte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9238353,"longitud":-43.94648919999999,"ranking":1},
{"id":15488,"nombre":"Terminal Alvares Cabral","localidad":"Belo Horizonte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9265987,"longitud":-43.9384674,"ranking":0},
{"id":5026,"nombre":"Uberlandia","localidad":"Uberlândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.9053,"longitud":-48.28501,"ranking":0},
{"id":4863,"nombre":"Teresina","localidad":"Teresina","provincia":"Piauí","pais":"BRASIL","latitud":-5.119108,"longitud":-42.78462,"ranking":1},
{"id":4800,"nombre":"Aracaju","localidad":"Aracaju","provincia":"Sergipe","pais":"BRASIL","latitud":-10.916439,"longitud":-37.088684,"ranking":2},
{"id":4837,"nombre":"Belem","localidad":"Belém","provincia":"Pará","pais":"BRASIL","latitud":-1.4489916,"longitud":-48.4662274,"ranking":1},
{"id":8788,"nombre":"Boa Vista","localidad":"Boa Vista","provincia":"Roraima","pais":"BRASIL","latitud":2.8046688,"longitud":-60.68854959999999,"ranking":23},
{"id":6022,"nombre":"Navegantes","localidad":"Navegantes","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.901005980341694,"longitud":-48.65056733021127,"ranking":0},
{"id":5006,"nombre":"Sao Jose do Rio Preto","localidad":"São José do Rio Preto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.8087569,"longitud":-49.3777599,"ranking":0},
{"id":10770,"nombre":"Sao Jose do Rio Preto (Sala Vip)","localidad":"São José do Rio Preto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.809200655454887,"longitud":-49.377347074213425,"ranking":0},
{"id":4882,"nombre":"Ribeirao Preto","localidad":"Ribeirão Preto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.1736315,"longitud":-47.81387429999999,"ranking":1},
{"id":5925,"nombre":"Entrocamento Ribeirao Preto","localidad":"Ribeirão Preto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.173884216941087,"longitud":-47.8144655106158,"ranking":0},
{"id":4845,"nombre":"Joao Pessoa","localidad":"João Pessoa","provincia":"Paraíba","pais":"BRASIL","latitud":-7.1186529,"longitud":-34.891124,"ranking":1},
{"id":15343,"nombre":"Parque Solon de Lucena","localidad":"João Pessoa","provincia":"Paraíba","pais":"BRASIL","latitud":-7.1207876,"longitud":-34.8782624,"ranking":0},
{"id":4841,"nombre":"Natal","localidad":"Natal","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.819710799999999,"longitud":-35.2345393,"ranking":2},
{"id":6549,"nombre":"Palmas","localidad":"Palmas","provincia":"Tocantins","pais":"BRASIL","latitud":-10.2567894,"longitud":-48.3129285,"ranking":0},
{"id":4853,"nombre":"Petrolina","localidad":"Petrolina","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.391730599999999,"longitud":-40.5091238,"ranking":0},
{"id":4876,"nombre":"Porto Velho","localidad":"Porto Velho","provincia":"Rondônia","pais":"BRASIL","latitud":-8.7581571,"longitud":-63.8852762,"ranking":6},
{"id":15472,"nombre":"Extrema","localidad":"Porto Velho","provincia":"Rondônia","pais":"BRASIL","latitud":-9.771862323221121,"longitud":-66.3657643533147,"ranking":0},
{"id":4854,"nombre":"Recife","localidad":"Recife","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.0635643,"longitud":-34.9820261,"ranking":1},
{"id":15293,"nombre":"Praca de Boa Viagem","localidad":"Recife","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.1321542971573,"longitud":-34.90020821484783,"ranking":0},
{"id":8446,"nombre":"Santarem","localidad":"Santarém","provincia":"Pará","pais":"BRASIL","latitud":-2.4418244,"longitud":-54.7277101,"ranking":0},
{"id":8318,"nombre":"Isla de Pascua","localidad":"Isla de Pascua","provincia":"Región V - Valparaiso","pais":"CHILE","latitud":-33.058809,"longitud":-71.535608,"ranking":0},
{"id":7981,"nombre":"El Salvador","localidad":"El Salvador","provincia":"Región III - Atacama","pais":"CHILE","latitud":-26.245546,"longitud":-69.62637,"ranking":0},
{"id":1263,"nombre":"Arequipa","localidad":"Arequipa","provincia":"Arequipa","pais":"Perú","latitud":-16.423265754226133,"longitud":-71.54450135816379,"ranking":30},
{"id":7740,"nombre":"Arequipa Acceso","localidad":"Arequipa","provincia":"Arequipa","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15612,"nombre":"Arequipa - Terminal Terrestre","localidad":"Arequipa","provincia":"Arequipa","pais":"Perú","latitud":-16.4235132,"longitud":-71.5444831,"ranking":0},
{"id":15410,"nombre":"Cusco","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":-13.534755281229197,"longitud":-71.96563391849244,"ranking":35},
{"id":15563,"nombre":"Hidroeléctrica Cusco","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15564,"nombre":"Aeropuerto Cusco","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":-13.5383473,"longitud":-71.94317819999999,"ranking":100000},
{"id":15565,"nombre":"Hoteles Cusco","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":10},
{"id":15611,"nombre":"Cusco Huancaro","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":-13.5331751,"longitud":-71.9717056,"ranking":0},
{"id":15647,"nombre":"Urcos","localidad":"Cusco","provincia":"Cuzco","pais":"Perú","latitud":-13.683802,"longitud":-71.624864,"ranking":0},
{"id":15409,"nombre":"Juliaca","localidad":"Juliaca","provincia":"Puno","pais":"Perú","latitud":-15.4902539,"longitud":-70.1206292,"ranking":35},
{"id":15509,"nombre":"Pucallpa","localidad":"Pucallpa","provincia":"Ucayali","pais":"Perú","latitud":-8.398375399999999,"longitud":-74.6052459,"ranking":2000},
{"id":15515,"nombre":"Tarapoto","localidad":"Tarapoto","provincia":"San Martin","pais":"Perú","latitud":-6.4829932,"longitud":-76.38126179999999,"ranking":2000},
{"id":15397,"nombre":"Trujillo","localidad":"Trujillo","provincia":"La Libertad","pais":"Perú","latitud":-8.137262692587617,"longitud":-79.01740816631366,"ranking":35},
{"id":15587,"nombre":"Terrapuerto Trujillo","localidad":"Trujillo","provincia":"La Libertad","pais":"Perú","latitud":-8.137574599999999,"longitud":-79.01738399999999,"ranking":0},
{"id":15588,"nombre":"Terminal Ormeño","localidad":"Trujillo","provincia":"La Libertad","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15406,"nombre":"Tumbes","localidad":"Tumbes","provincia":"Tumbes","pais":"Perú","latitud":-3.564399646813589,"longitud":-80.45630937455878,"ranking":25},
{"id":4775,"nombre":"Colonia de Gomez","localidad":"Colonia de Gomez","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9191,"nombre":"Colonia de Gomez Acceso","localidad":"Colonia de Gomez","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4790,"nombre":"Tres Esquinas","localidad":"Tres Esquinas","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.0601397,"longitud":-68.4292145,"ranking":0},
{"id":4791,"nombre":"Carmensa","localidad":"Carmensa","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.147563,"longitud":-67.654392,"ranking":11},
{"id":7937,"nombre":"Carmensa Acceso","localidad":"Carmensa","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.15701783704382,"longitud":-67.66621159596009,"ranking":0},
{"id":4792,"nombre":"Cerro Negro","localidad":"Cerro Negro","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.59689604331911,"longitud":-69.34960091150062,"ranking":27},
{"id":4795,"nombre":"Talca","localidad":"Talca","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.4301134,"longitud":-71.6472597,"ranking":0},
{"id":8336,"nombre":"Constitucion","localidad":"Talca","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.33644784971817,"longitud":-72.40454707186863,"ranking":0},
{"id":4814,"nombre":"Afonso Claudio","localidad":"Afonso Cláudio","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.07301991001617,"longitud":-41.12469719912428,"ranking":0},
{"id":4816,"nombre":"Cachoeiro de Itapemirim","localidad":"Cachoeiro de Itapemirim","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.8545921,"longitud":-41.117259,"ranking":0},
{"id":4821,"nombre":"Castelo","localidad":"Castelo","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.6010023,"longitud":-41.2061064,"ranking":0},
{"id":4822,"nombre":"Guarapari","localidad":"Guarapari","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.628231603178065,"longitud":-40.52591490245373,"ranking":0},
{"id":10464,"nombre":"Guarapari (Centro)","localidad":"Guarapari","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4824,"nombre":"Iriri","localidad":"Iriri","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.836504946295594,"longitud":-40.7200409939672,"ranking":0},
{"id":4826,"nombre":"Vargem Alta","localidad":"Vargem Alta","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.673613058238296,"longitud":-41.011104671406606,"ranking":0},
{"id":4828,"nombre":"Vila Velha","localidad":"Vila Velha ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.3424349,"longitud":-40.295343,"ranking":0},
{"id":4798,"nombre":"Cicero Dantas","localidad":"Cícero Dantas","provincia":"Bahia","pais":"BRASIL","latitud":-10.586712634395099,"longitud":-38.3798893014418,"ranking":0},
{"id":4799,"nombre":"Esplanada","localidad":"Esplanada","provincia":"Bahia","pais":"BRASIL","latitud":-11.795597,"longitud":-37.9446477,"ranking":0},
{"id":4801,"nombre":"Feira de Santana","localidad":"Feira de Santana","provincia":"Bahia","pais":"BRASIL","latitud":-12.2593475,"longitud":-38.9581627,"ranking":1},
{"id":4802,"nombre":"Itabuna","localidad":"Itabuna","provincia":"Bahia","pais":"BRASIL","latitud":-14.7923885,"longitud":-39.282803,"ranking":5},
{"id":4804,"nombre":"Olindina","localidad":"Olindina","provincia":"Bahia","pais":"BRASIL","latitud":-11.365853056767515,"longitud":-38.324260965952924,"ranking":0},
{"id":4805,"nombre":"Pojuca","localidad":"Pojuca","provincia":"Bahia","pais":"BRASIL","latitud":-12.427401965306087,"longitud":-38.3276085959435,"ranking":0},
{"id":4807,"nombre":"Senhor do Bonfim","localidad":"Senhor do Bonfim","provincia":"Bahia","pais":"BRASIL","latitud":-10.448593790869317,"longitud":-40.18563825241937,"ranking":0},
{"id":4808,"nombre":"Teixeira de Freitas","localidad":"Teixeira de Freitas ","provincia":"Bahia","pais":"BRASIL","latitud":-17.5501692,"longitud":-39.7314399,"ranking":0},
{"id":4809,"nombre":"Vitoria da Conquista","localidad":"Vitória da Conquista","provincia":"Bahia","pais":"BRASIL","latitud":-14.8535577,"longitud":-40.8441512,"ranking":5},
{"id":4811,"nombre":"Juazeiro do Norte","localidad":"Juazeiro do Norte ","provincia":"Ceará","pais":"BRASIL","latitud":-7.226509999999999,"longitud":-39.32253,"ranking":13},
{"id":4812,"nombre":"Sobral","localidad":"Sobral","provincia":"Ceará","pais":"BRASIL","latitud":-3.693253,"longitud":-40.351049,"ranking":0},
{"id":4834,"nombre":"Catalao","localidad":"Catalão","provincia":"Goias","pais":"BRASIL","latitud":-18.171862013716318,"longitud":-47.926545381742486,"ranking":0},
{"id":4836,"nombre":"Porangatu","localidad":"Porangatu","provincia":"Goias","pais":"BRASIL","latitud":-13.431896163010268,"longitud":-49.14189849665378,"ranking":0},
{"id":4838,"nombre":"Uruacu","localidad":"Uruaçu","provincia":"Goias","pais":"BRASIL","latitud":-14.533098991537386,"longitud":-49.15040140114801,"ranking":0},
{"id":4839,"nombre":"Mossoro","localidad":"Mossoró","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.185469828320958,"longitud":-37.37396990123064,"ranking":0},
{"id":4842,"nombre":"Santa Ines","localidad":"Santa Inês","provincia":"Maranhão","pais":"BRASIL","latitud":-3.6642353,"longitud":-45.38953559999999,"ranking":0},
{"id":4815,"nombre":"Timon","localidad":"Timon","provincia":"Maranhão","pais":"BRASIL","latitud":-5.089999325174302,"longitud":-42.834558635559354,"ranking":0},
{"id":4817,"nombre":"Alem Paraiba","localidad":"Além Paraíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.882401815963533,"longitud":-42.69420494653829,"ranking":0},
{"id":4819,"nombre":"Caratinga","localidad":"Caratinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.796763149810133,"longitud":-42.138315911647894,"ranking":0},
{"id":4820,"nombre":"Coronel Fabriciano","localidad":"Coronel Fabriciano","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.528764361774652,"longitud":-42.623365221714884,"ranking":2},
{"id":4944,"nombre":"Governador Valadares","localidad":"Governador Valadares","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.8591613,"longitud":-41.9461555,"ranking":0},
{"id":6096,"nombre":"Posto Duqueza","localidad":"Governador Valadares","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.858973267865192,"longitud":-41.946321306242766,"ranking":0},
{"id":6097,"nombre":"Posto Ipiranga","localidad":"Governador Valadares","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.858995089368886,"longitud":-41.94632652168004,"ranking":0},
{"id":4823,"nombre":"Ipatinga","localidad":"Ipatinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.480382649493144,"longitud":-42.523750000002735,"ranking":0},
{"id":4825,"nombre":"Mantena","localidad":"Mantena","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.784835,"longitud":-40.982538,"ranking":0},
{"id":4827,"nombre":"Muriae","localidad":"Muriaé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.128401722090423,"longitud":-21.128401722090423,"ranking":0},
{"id":4829,"nombre":"Paracatu","localidad":"Paracatu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.2173496,"longitud":-46.8814137,"ranking":0},
{"id":4831,"nombre":"Realeza","localidad":"Realeza","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.247261,"longitud":-42.149804,"ranking":0},
{"id":4832,"nombre":"Rio Casca","localidad":"Rio Casca","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.227147151726687,"longitud":-42.64894723892212,"ranking":0},
{"id":4833,"nombre":"Santos Dumont","localidad":"Santos Dumont","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.454119708061384,"longitud":-43.553281318947576,"ranking":0},
{"id":6672,"nombre":"Represa da Ponte Preta","localidad":"Santos Dumont","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.454169273427183,"longitud":-43.55273087685094,"ranking":0},
{"id":6792,"nombre":"Entroncamento Santos Dumont","localidad":"Santos Dumont","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.454156164981843,"longitud":-43.55297887528272,"ranking":0},
{"id":4835,"nombre":"Teofilo Otoni","localidad":"Teófilo Otoni","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.867969787094662,"longitud":-41.51259621684677,"ranking":1},
{"id":4840,"nombre":"Campina Grande","localidad":"Campina Grande ","provincia":"Paraíba","pais":"BRASIL","latitud":-7.23867,"longitud":-35.87115,"ranking":0},
{"id":4844,"nombre":"Guarabira","localidad":"Guarabira","provincia":"Paraíba","pais":"BRASIL","latitud":-6.8537859590516454,"longitud":-35.49578850191823,"ranking":0},
{"id":4846,"nombre":"Patos","localidad":"Patos","provincia":"Paraíba","pais":"BRASIL","latitud":-7.019303069206854,"longitud":-37.25977521364202,"ranking":0},
{"id":4849,"nombre":"Arcoverde","localidad":"Arcoverde","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.414495035195749,"longitud":-37.072682852041915,"ranking":0},
{"id":4850,"nombre":"Caruaru","localidad":"Caruaru","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.294310581378337,"longitud":-35.988155451580994,"ranking":0},
{"id":4851,"nombre":"Limoeiro","localidad":"Limoeiro","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.875139382007132,"longitud":-35.446630058085134,"ranking":0},
{"id":4852,"nombre":"Palmares","localidad":"Palmares","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.681537888478678,"longitud":-35.58471224442812,"ranking":0},
{"id":4855,"nombre":"Serra Talhada","localidad":"Serra Talhada","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.9839578091708026,"longitud":-38.29249053857789,"ranking":0},
{"id":4856,"nombre":"Surubim","localidad":"Surubim","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.83824274678675,"longitud":-35.76040552438751,"ranking":0},
{"id":4857,"nombre":"Timbauba","localidad":"Timbaúba","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.515675736973743,"longitud":-35.31164487202868,"ranking":0},
{"id":4858,"nombre":"Cristalandia do Piaui","localidad":"Cristalândia do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-10.651565486811087,"longitud":-45.18367176465723,"ranking":0},
{"id":4859,"nombre":"Floriano","localidad":"Floriano","provincia":"Piauí","pais":"BRASIL","latitud":-6.78677779932731,"longitud":-42.99222707748413,"ranking":0},
{"id":4860,"nombre":"Parnaiba","localidad":"Parnaíba","provincia":"Piauí","pais":"BRASIL","latitud":-2.9280987,"longitud":-41.7533816,"ranking":8},
{"id":4861,"nombre":"Paus","localidad":"Paus","provincia":"Piauí","pais":"BRASIL","latitud":-9.58431630605098,"longitud":-44.80660662678135,"ranking":0},
{"id":4862,"nombre":"Picos","localidad":"Picos","provincia":"Piauí","pais":"BRASIL","latitud":-7.077808640012013,"longitud":-41.46160144540515,"ranking":0},
{"id":4864,"nombre":"Campos Goytacazes","localidad":"Campos dos Goytacazes","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.764622,"longitud":-41.336546,"ranking":0},
{"id":4865,"nombre":"Conselheiro Josino","localidad":"Conselheiro Josino","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.500448767064654,"longitud":-41.34379632256545,"ranking":0},
{"id":4866,"nombre":"Correntezas","localidad":"Correntezas","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.475041995271802,"longitud":-42.3909936625905,"ranking":0},
{"id":4867,"nombre":"Duque de Caxias","localidad":"Duque de Caxias","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.792306331191007,"longitud":-22.792306331191007,"ranking":0},
{"id":5978,"nombre":"Guandu","localidad":"Guandu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4869,"nombre":"Macae","localidad":"Macaé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.3799914,"longitud":-41.7803981,"ranking":0},
{"id":4870,"nombre":"Niteroi","localidad":"Niterói","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.885551095773387,"longitud":-43.122085104282526,"ranking":0},
{"id":5843,"nombre":"Niteroi- Barcas","localidad":"Niterói","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.891266316072677,"longitud":-43.12478017618574,"ranking":0},
{"id":4871,"nombre":"Petropolis","localidad":"Petrópolis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.516355,"longitud":-43.2290185,"ranking":16},
{"id":5979,"nombre":"Piao","localidad":"Piao","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.81538106984724,"longitud":-43.02413763750976,"ranking":0},
{"id":4873,"nombre":"Rio das Ostras","localidad":"Rio das Ostras","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4932646,"longitud":-41.9165263,"ranking":0},
{"id":5825,"nombre":"Mar Do Norte","localidad":"Rio das Ostras","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.526993035679617,"longitud":-41.94573650074971,"ranking":0},
{"id":4874,"nombre":"Travessao","localidad":"travessao","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.60333088326229,"longitud":-41.317506715434746,"ranking":0},
{"id":4875,"nombre":"Volta Redonda","localidad":"Volta Redonda","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.5138045,"longitud":-44.0986306,"ranking":3},
{"id":4878,"nombre":"Jundiai","localidad":"Jundiaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.2075,"longitud":-46.8955,"ranking":0},
{"id":4879,"nombre":"Marilia","localidad":"Marília","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.2306,"longitud":-49.92289,"ranking":0},
{"id":4880,"nombre":"Miracatu","localidad":"Miracatu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.28317915079514,"longitud":-47.45656162699775,"ranking":0},
{"id":4881,"nombre":"Osasco","localidad":"Osasco","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.5249731,"longitud":-46.7741724,"ranking":0},
{"id":4883,"nombre":"Santo Andre","localidad":"Santo André","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6388711,"longitud":-46.53775539999999,"ranking":4},
{"id":4884,"nombre":"Santos","localidad":"Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.9352834,"longitud":-46.3327986,"ranking":8},
{"id":5915,"nombre":"Encruzilhada","localidad":"Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.935439511290618,"longitud":-46.333348584868936,"ranking":0},
{"id":6240,"nombre":"Ponta da Praia","localidad":"Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.9820771,"longitud":-46.2992679,"ranking":0},
{"id":6243,"nombre":"Porto de Santos","localidad":"Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.9352834,"longitud":-46.3327986,"ranking":0},
{"id":15095,"nombre":"Jose Menino","localidad":"Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.96850117442798,"longitud":-46.35213444336398,"ranking":0},
{"id":4885,"nombre":"Sao Jose dos Campos","localidad":"São José dos Campos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.2773441,"longitud":-45.9541453,"ranking":1},
{"id":4886,"nombre":"Guarai","localidad":"Guaraí","provincia":"Tocantins","pais":"BRASIL","latitud":-8.82873,"longitud":-48.5143,"ranking":0},
{"id":4887,"nombre":"San Clemente","localidad":"San Clemente","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.549533425041105,"longitud":-71.47216871422526,"ranking":0},
{"id":8798,"nombre":"Alta Floresta D'Oeste ","localidad":"Alta Floresta D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.930918388603791,"longitud":-61.994385858504316,"ranking":0},
{"id":6812,"nombre":"Ariquemes","localidad":"Ariquemes","provincia":"Rondônia","pais":"BRASIL","latitud":-9.921056717921562,"longitud":-63.03276501601963,"ranking":0},
{"id":6816,"nombre":"Cacoal","localidad":"Cacoal","provincia":"Rondônia","pais":"BRASIL","latitud":-11.422334347707698,"longitud":-61.456999060522,"ranking":2},
{"id":8467,"nombre":"Cerejeiras ","localidad":"Cerejeiras","provincia":"Rondônia","pais":"BRASIL","latitud":-13.188743477540983,"longitud":-60.82162024355338,"ranking":0},
{"id":8805,"nombre":"Colorado do Oeste","localidad":"Colorado do Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-13.12241340934055,"longitud":-60.54631737290545,"ranking":0},
{"id":8804,"nombre":"Costa Marques","localidad":"Costa Marques","provincia":"Rondônia","pais":"BRASIL","latitud":-12.432143465456178,"longitud":-64.2294595892196,"ranking":0},
{"id":8803,"nombre":"Espigao do Oeste","localidad":"Espigão D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.528705303985946,"longitud":-61.01317556098933,"ranking":0},
{"id":8466,"nombre":"Guajara Mirim","localidad":"Guajará-Mirim","provincia":"Rondônia","pais":"BRASIL","latitud":-10.776129533783385,"longitud":-65.32945674518456,"ranking":0},
{"id":6817,"nombre":"Jaru","localidad":"Jaru","provincia":"Rondônia","pais":"BRASIL","latitud":-10.428183021733687,"longitud":-62.47403907601911,"ranking":0},
{"id":6818,"nombre":"Ji Parana","localidad":"Ji-Paraná","provincia":"Rondônia","pais":"BRASIL","latitud":-10.877508623448337,"longitud":-61.95580246650229,"ranking":0},
{"id":8801,"nombre":"Machadinho do Oeste","localidad":"Machadinho D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-9.426609697235056,"longitud":-62.00220577516966,"ranking":0},
{"id":8799,"nombre":"Nova Brasilandia do Oeste","localidad":"Nova Brasilândia D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.725075711260205,"longitud":-62.312238948139935,"ranking":0},
{"id":8796,"nombre":"Ouro Preto do Oeste","localidad":"Ouro Preto do Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-10.719739096692484,"longitud":-62.26781008070479,"ranking":0},
{"id":6821,"nombre":"Pimenta Bueno","localidad":"Pimenta Bueno","provincia":"Rondônia","pais":"BRASIL","latitud":-11.67653,"longitud":-61.19057,"ranking":0},
{"id":6823,"nombre":"Presidente Medici","localidad":"Presidente Médici","provincia":"Rondônia","pais":"BRASIL","latitud":-11.166254913686545,"longitud":-61.907635378729935,"ranking":0},
{"id":7369,"nombre":"Rolim de Moura","localidad":"Rolim de Moura","provincia":"Rondônia","pais":"BRASIL","latitud":-11.727606341030658,"longitud":-61.778895074567906,"ranking":0},
{"id":8795,"nombre":"Santa Luzia do Oeste","localidad":"Santa Luzia D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.905010389489206,"longitud":-61.78115201735789,"ranking":0},
{"id":6824,"nombre":"Vilhena","localidad":"Vilhena","provincia":"Rondônia","pais":"BRASIL","latitud":-11.2740133,"longitud":-37.4473583,"ranking":0},
{"id":8793,"nombre":"Sao Miguel do Guapore","localidad":"São Miguel do Guaporé","provincia":"Rondônia","pais":"BRASIL","latitud":-11.701770512979103,"longitud":-62.7179113470827,"ranking":0},
{"id":10469,"nombre":"Nova Mamore","localidad":"Nova Mamoré","provincia":"Rondônia","pais":"BRASIL","latitud":-10.419475712081244,"longitud":-65.33586658486198,"ranking":0},
{"id":8802,"nombre":"Alvorada D'Oeste","localidad":"Alvorada D'Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.343221808375224,"longitud":-62.283100435064654,"ranking":0},
{"id":8797,"nombre":"Novo Horizonte do Oeste","localidad":"Novo Horizonte do Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-11.709922499464712,"longitud":-61.99839827674426,"ranking":0},
{"id":15443,"nombre":"Candeias do Jamari","localidad":"Candeias do Jamari","provincia":"Rondônia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7320,"nombre":"Itapua do Oeste","localidad":"Itapuã do Oeste","provincia":"Rondônia","pais":"BRASIL","latitud":-9.196078247821037,"longitud":-63.183931934004995,"ranking":0},
{"id":8800,"nombre":"Mirante da Serra","localidad":"Mirante da Serra","provincia":"Rondônia","pais":"BRASIL","latitud":-11.030670077380668,"longitud":-62.67297682933111,"ranking":0},
{"id":8794,"nombre":"Sao Francisco do Guapore","localidad":"São Francisco do Guaporé","provincia":"Rondônia","pais":"BRASIL","latitud":-12.060567908329666,"longitud":-63.56482216889576,"ranking":0},
{"id":8792,"nombre":"Seringueiras","localidad":"Seringueiras","provincia":"Rondônia","pais":"BRASIL","latitud":-11.763430337745579,"longitud":-63.03820438666799,"ranking":0},
{"id":8791,"nombre":"Theobroma","localidad":"Theobroma","provincia":"Rondônia","pais":"BRASIL","latitud":-10.253265971126199,"longitud":-62.3431553333236,"ranking":0},
{"id":8790,"nombre":"Urupa","localidad":"Urupá","provincia":"Rondônia","pais":"BRASIL","latitud":-11.126269465073024,"longitud":-62.364572094383114,"ranking":0},
{"id":8789,"nombre":"Vale do Anari","localidad":"Vale do Anari","provincia":"Rondônia","pais":"BRASIL","latitud":-9.858268180606583,"longitud":-62.17377394195647,"ranking":0},
{"id":10810,"nombre":"Acrelandia","localidad":"Acrelândia","provincia":"Acre","pais":"BRASIL","latitud":-10.074605,"longitud":-67.059063,"ranking":0},
{"id":10806,"nombre":"Assis Brasil","localidad":"Assis Brasil","provincia":"Acre","pais":"BRASIL","latitud":-10.9381631,"longitud":-69.5678562,"ranking":0},
{"id":10807,"nombre":"Brasileia","localidad":"Brasiléia","provincia":"Acre","pais":"BRASIL","latitud":-11.0089,"longitud":-68.74289999999999,"ranking":0},
{"id":10808,"nombre":"Capixaba","localidad":"Capixaba","provincia":"Acre","pais":"BRASIL","latitud":-10.5735838,"longitud":-67.6733248,"ranking":0},
{"id":10474,"nombre":"Cruzeiro do Sul","localidad":"Cruzeiro do Sul","provincia":"Acre","pais":"BRASIL","latitud":-7.631921851381309,"longitud":-72.66602426190481,"ranking":0},
{"id":10475,"nombre":"Feijo","localidad":"Feijó","provincia":"Acre","pais":"BRASIL","latitud":-8.186915764205285,"longitud":-70.35079754750608,"ranking":0},
{"id":10466,"nombre":"Campinas (Placido de Castro)","localidad":"Plácido de Castro","provincia":"Acre","pais":"BRASIL","latitud":-10.062712658155636,"longitud":-67.29995225932812,"ranking":0},
{"id":10811,"nombre":"Placido de Castro","localidad":"Plácido de Castro","provincia":"Acre","pais":"BRASIL","latitud":-10.3286864,"longitud":-67.1769387,"ranking":0},
{"id":10812,"nombre":"Rodrigues Alves","localidad":"Rodrigues Alves","provincia":"Acre","pais":"BRASIL","latitud":-7.735293,"longitud":-72.6482544,"ranking":0},
{"id":10470,"nombre":"Senador Guiomard","localidad":"Senador Guiomard","provincia":"Acre","pais":"BRASIL","latitud":-10.159493578819271,"longitud":-67.73347520652536,"ranking":0},
{"id":10809,"nombre":"Sena Madureira","localidad":"Sena Madureira","provincia":"Acre","pais":"BRASIL","latitud":-9.07474,"longitud":-68.667007,"ranking":0},
{"id":10476,"nombre":"Tarauaca","localidad":"Tarauacá","provincia":"Acre","pais":"BRASIL","latitud":-8.14260267063027,"longitud":-70.78555183872756,"ranking":0},
{"id":10813,"nombre":"Xapuri","localidad":"Xapuri","provincia":"Acre","pais":"BRASIL","latitud":-10.650825,"longitud":-68.502267,"ranking":0},
{"id":14834,"nombre":"Apui","localidad":"Apuí","provincia":"Amazonas","pais":"BRASIL","latitud":-7.202260495156274,"longitud":-59.891836513573764,"ranking":0},
{"id":14835,"nombre":"Boca do Acre","localidad":"Boca do Acre","provincia":"Amazonas","pais":"BRASIL","latitud":-8.759797974261163,"longitud":-67.38352395846056,"ranking":0},
{"id":15440,"nombre":"Canutama","localidad":"Canutama","provincia":"Amazonas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15442,"nombre":"Careiro","localidad":"Careiro","provincia":"Amazonas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8772,"nombre":"Humaita","localidad":"Humaitá","provincia":"Amazonas","pais":"BRASIL","latitud":-7.513230913290133,"longitud":-63.028937651797335,"ranking":0},
{"id":15441,"nombre":"Ipixuna","localidad":"Ipixuna","provincia":"Amazonas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8773,"nombre":"Itacoatiara","localidad":"Itacoatiara","provincia":"Amazonas","pais":"BRASIL","latitud":-3.136081027134694,"longitud":-58.445656299591064,"ranking":0},
{"id":15463,"nombre":"Manicoré - Matupi ","localidad":"Manicoré","provincia":"Amazonas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9405,"nombre":"Parintins","localidad":"Parintins","provincia":"Amazonas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10140,"nombre":"Presidente Figueiredo","localidad":"Presidente Figueiredo","provincia":"Amazonas","pais":"BRASIL","latitud":-2.0501887948450466,"longitud":-60.02614315787392,"ranking":0},
{"id":10501,"nombre":"Bonfim","localidad":"Bonfim","provincia":"Roraima","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14938,"nombre":"Canta","localidad":"Cantá","provincia":"Roraima","pais":"BRASIL","latitud":2.6110631,"longitud":-60.60018900000001,"ranking":0},
{"id":8787,"nombre":"Caracarai","localidad":"Caracaraí","provincia":"Roraima","pais":"BRASIL","latitud":1.8274457242009374,"longitud":-61.13048337759193,"ranking":0},
{"id":10401,"nombre":"Caroebe","localidad":"Caroebe","provincia":"Roraima","pais":"BRASIL","latitud":0.8810733362654055,"longitud":-59.69867603570295,"ranking":0},
{"id":8783,"nombre":"Vila Iracema","localidad":"Iracema","provincia":"Roraima","pais":"BRASIL","latitud":2.173428718813386,"longitud":-61.05078203811334,"ranking":0},
{"id":8786,"nombre":"Mucajaí","localidad":"Mucajaí","provincia":"Roraima","pais":"BRASIL","latitud":2.447123833871454,"longitud":-60.91991993546784,"ranking":0},
{"id":15005,"nombre":"Normandia","localidad":"Normandia","provincia":"Roraima","pais":"BRASIL","latitud":3.8793335336331682,"longitud":-59.634575073297455,"ranking":0},
{"id":8785,"nombre":"Pacaraima","localidad":"Pacaraima","provincia":"Roraima","pais":"BRASIL","latitud":4.4746636,"longitud":-61.14775139999999,"ranking":0},
{"id":8784,"nombre":"Rorainopolis","localidad":"Rorainópolis","provincia":"Roraima","pais":"BRASIL","latitud":0.9347278428549748,"longitud":-60.427703650842886,"ranking":0},
{"id":10402,"nombre":"Sao Joao da Baliza","localidad":"São João da Baliza","provincia":"Roraima","pais":"BRASIL","latitud":0.9486632524620852,"longitud":-59.913230175211275,"ranking":0},
{"id":10482,"nombre":"Abaetetuba","localidad":"Abaetetuba","provincia":"Pará","pais":"BRASIL","latitud":-1.726857234225114,"longitud":-48.878318216809824,"ranking":0},
{"id":7424,"nombre":"Abel Figueiredo","localidad":"Abel Figueiredo","provincia":"Pará","pais":"BRASIL","latitud":-4.955940007970215,"longitud":-48.401607691552485,"ranking":0},
{"id":10483,"nombre":"Acara","localidad":"Acará","provincia":"Pará","pais":"BRASIL","latitud":-1.963323391458436,"longitud":-48.19391842581767,"ranking":0},
{"id":10477,"nombre":"Agua Azul do Norte","localidad":"Água Azul do Norte","provincia":"Pará","pais":"BRASIL","latitud":-6.802654178531259,"longitud":-50.486100804716344,"ranking":0},
{"id":9607,"nombre":"Alenquer","localidad":"Alenquer","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9608,"nombre":"Almeirim","localidad":"Almeirim","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9241,"nombre":"Castelo dos Sonhos","localidad":"Altamira","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9246,"nombre":"Altamira","localidad":"Altamira","provincia":"Pará","pais":"BRASIL","latitud":-3.206008265040846,"longitud":-52.22078518474251,"ranking":0},
{"id":6618,"nombre":"Ananindeua","localidad":"Ananindeua","provincia":"Pará","pais":"BRASIL","latitud":-1.3672065,"longitud":-48.3717005,"ranking":0},
{"id":8429,"nombre":"Anapu","localidad":"Anapu","provincia":"Pará","pais":"BRASIL","latitud":-3.46687316415806,"longitud":-51.20453688762850,"ranking":0},
{"id":9909,"nombre":"Aurora do Para","localidad":"Aurora do Pará","provincia":"Pará","pais":"BRASIL","latitud":-2.1305466698027975,"longitud":-47.5610875029052,"ranking":0},
{"id":10484,"nombre":"Baiao","localidad":"Baião","provincia":"Pará","pais":"BRASIL","latitud":-2.791491563668446,"longitud":-49.66668354732996,"ranking":0},
{"id":8430,"nombre":"Barcarena","localidad":"Barcarena","provincia":"Pará","pais":"BRASIL","latitud":-1.5033975502199652,"longitud":-48.624946378870455,"ranking":0},
{"id":14948,"nombre":"Benevides","localidad":"Benevides","provincia":"Pará","pais":"BRASIL","latitud":-1.3609377087702954,"longitud":-48.243613212811645,"ranking":0},
{"id":7425,"nombre":"Bom Jesus do Tocantins","localidad":"Bom Jesus do Tocantins","provincia":"Pará","pais":"BRASIL","latitud":-5.045785517303216,"longitud":-48.6010294500306,"ranking":0},
{"id":10485,"nombre":"Braganca","localidad":"Bragança","provincia":"Pará","pais":"BRASIL","latitud":-1.0585156692765427,"longitud":-46.7672124670201,"ranking":0},
{"id":9245,"nombre":"Brasil Novo","localidad":"Brasil Novo","provincia":"Pará","pais":"BRASIL","latitud":-3.306868721962522,"longitud":-52.54394301551391,"ranking":0},
{"id":8431,"nombre":"Breu Branco","localidad":"Breu Branco","provincia":"Pará","pais":"BRASIL","latitud":-3.7753513485574066,"longitud":-49.56880670330842,"ranking":0},
{"id":9609,"nombre":"Breves","localidad":"Breves","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10486,"nombre":"Bujaru","localidad":"Bujaru","provincia":"Pará","pais":"BRASIL","latitud":-1.5152704436628306,"longitud":-48.04622556834563,"ranking":0},
{"id":7426,"nombre":"Cachoeira do Piria","localidad":"Cachoeira do Piriá","provincia":"Pará","pais":"BRASIL","latitud":-1.7609929475709607,"longitud":-46.543006025367646,"ranking":0},
{"id":10487,"nombre":"Cameta","localidad":"Cametá","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9873,"nombre":"Canaa dos Carajas","localidad":"Canaã dos Carajás","provincia":"Pará","pais":"BRASIL","latitud":-6.519511839401883,"longitud":-49.854173026350374,"ranking":0},
{"id":6619,"nombre":"Capanema","localidad":"Capanema","provincia":"Pará","pais":"BRASIL","latitud":-1.2053841424534242,"longitud":-47.17623240311994,"ranking":0},
{"id":10488,"nombre":"Capitao Poco","localidad":"Capitão Poço","provincia":"Pará","pais":"BRASIL","latitud":-1.7473587439001548,"longitud":-47.059492859695276,"ranking":0},
{"id":6621,"nombre":"Castanhal","localidad":"Castanhal","provincia":"Pará","pais":"BRASIL","latitud":-1.296029419551643,"longitud":-47.92210578918457,"ranking":0},
{"id":8432,"nombre":"Conceicao do Araguaia","localidad":"Conceição do Araguaia","provincia":"Pará","pais":"BRASIL","latitud":-8.275701223682564,"longitud":-49.27201842457791,"ranking":0},
{"id":10489,"nombre":"Concordia do Para","localidad":"Concórdia do Pará","provincia":"Pará","pais":"BRASIL","latitud":-1.9950765029181718,"longitud":-47.94530497157853,"ranking":0},
{"id":8433,"nombre":"Curionopolis","localidad":"Curionópolis","provincia":"Pará","pais":"BRASIL","latitud":-6.103331508905752,"longitud":-49.60128024621402,"ranking":0},
{"id":9610,"nombre":"Curralinho","localidad":"Curralinho","provincia":"Pará","pais":"BRASIL","latitud":-1.8106735886695322,"longitud":-49.79776055828147,"ranking":0},
{"id":9910,"nombre":"Curuca","localidad":"Curuçá","provincia":"Pará","pais":"BRASIL","latitud":-0.7310351592007024,"longitud":-47.85661608260419,"ranking":0},
{"id":6623,"nombre":"Dom Eliseu","localidad":"Dom Eliseu","provincia":"Pará","pais":"BRASIL","latitud":-4.295367396273361,"longitud":-47.556302728067394,"ranking":0},
{"id":8455,"nombre":"Eldorado dos Carajas","localidad":"Eldorado dos Carajás","provincia":"Pará","pais":"BRASIL","latitud":-6.1041383134017435,"longitud":-49.37184178380398,"ranking":0},
{"id":14957,"nombre":"Garrafao do Norte","localidad":"Garrafão do Norte","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9871,"nombre":"Goianesia do Para","localidad":"Goianésia do Pará","provincia":"Pará","pais":"BRASIL","latitud":-3.835854951995621,"longitud":-49.10125612698469,"ranking":0},
{"id":10478,"nombre":"Ipixuna do Para","localidad":"Ipixuna do Pará","provincia":"Pará","pais":"BRASIL","latitud":-2.5620979619115793,"longitud":-47.494157660576114,"ranking":0},
{"id":10490,"nombre":"Irituia","localidad":"Irituia","provincia":"Pará","pais":"BRASIL","latitud":-1.770823956418675,"longitud":-47.43881075862342,"ranking":0},
{"id":8434,"nombre":"Itaituba ","localidad":"Itaituba","provincia":"Pará","pais":"BRASIL","latitud":-4.344057928710844,"longitud":-55.78377705432368,"ranking":0},
{"id":8435,"nombre":"Itupiranga","localidad":"Itupiranga","provincia":"Pará","pais":"BRASIL","latitud":-5.129530895774484,"longitud":-49.33668641615796,"ranking":0},
{"id":8436,"nombre":"Jacunda","localidad":"Jacundá","provincia":"Pará","pais":"BRASIL","latitud":-4.440138900182356,"longitud":-49.11405284816157,"ranking":0},
{"id":9611,"nombre":"Juruti","localidad":"Juruti","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8437,"nombre":"Mae do Rio","localidad":"Mãe do Rio","provincia":"Pará","pais":"BRASIL","latitud":-2.0491050931340813,"longitud":-47.55062871195607,"ranking":0},
{"id":6625,"nombre":"Maraba","localidad":"Marabá","provincia":"Pará","pais":"BRASIL","latitud":-5.3488466,"longitud":-49.102498,"ranking":0},
{"id":9911,"nombre":"Marapanim","localidad":"Marapanim","provincia":"Pará","pais":"BRASIL","latitud":-0.7081664640909612,"longitud":-47.69892794891789,"ranking":0},
{"id":9912,"nombre":"Marituba","localidad":"Marituba","provincia":"Pará","pais":"BRASIL","latitud":-1.3646070755031263,"longitud":-48.358535743168986,"ranking":0},
{"id":8438,"nombre":"Medicilandia ","localidad":"Medicilândia","provincia":"Pará","pais":"BRASIL","latitud":-3.443521277514805,"longitud":-52.89095069770771,"ranking":0},
{"id":10491,"nombre":"Mocajuba","localidad":"Mocajuba","provincia":"Pará","pais":"BRASIL","latitud":-2.5807896779686974,"longitud":-49.50559787478746,"ranking":0},
{"id":8439,"nombre":"Moju","localidad":"Moju","provincia":"Pará","pais":"BRASIL","latitud":-1.8868800258712688,"longitud":-48.75459110853305,"ranking":0},
{"id":9613,"nombre":"Monte Alegre","localidad":"Monte Alegre","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10492,"nombre":"Nova Esperanca do Piria","localidad":"Nova Esperança do Piriá","provincia":"Pará","pais":"BRASIL","latitud":-2.2675626429334845,"longitud":-46.96701038016125,"ranking":0},
{"id":8453,"nombre":"Nova Ipixuna","localidad":"Nova Ipixuna","provincia":"Pará","pais":"BRASIL","latitud":-4.918497125402484,"longitud":-49.07460191521958,"ranking":0},
{"id":9235,"nombre":"Novo Progresso","localidad":"Novo Progresso","provincia":"Pará","pais":"BRASIL","latitud":-7.053064751393904,"longitud":-55.4133295223178,"ranking":0},
{"id":9244,"nombre":"Alvorada da Amazonia","localidad":"Novo Progresso","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8440,"nombre":"Novo Repartimento","localidad":"Novo Repartimento","provincia":"Pará","pais":"BRASIL","latitud":-4.245438379560729,"longitud":-49.94841371259712,"ranking":0},
{"id":9614,"nombre":"Obidos","localidad":"Óbidos","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9615,"nombre":"Oriximina","localidad":"Oriximiná","provincia":"Pará","pais":"BRASIL","latitud":-1.7696455501932007,"longitud":-55.85871865371904,"ranking":0},
{"id":10493,"nombre":"Ourem","localidad":"Ourém","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9886,"nombre":"Ourilandia do Norte ","localidad":"Ourilândia do Norte","provincia":"Pará","pais":"BRASIL","latitud":-6.74810986238056,"longitud":-51.08651207541498,"ranking":0},
{"id":8441,"nombre":"Pacaja","localidad":"Pacajá","provincia":"Pará","pais":"BRASIL","latitud":-3.8345973310401913,"longitud":-50.635426802990686,"ranking":0},
{"id":8442,"nombre":"Paragominas","localidad":"Paragominas","provincia":"Pará","pais":"BRASIL","latitud":-2.989998730662488,"longitud":-47.375445385875004,"ranking":0},
{"id":8443,"nombre":"Parauapebas","localidad":"Parauapebas","provincia":"Pará","pais":"BRASIL","latitud":-6.0763076,"longitud":-49.8847302,"ranking":0},
{"id":8444,"nombre":"Placas","localidad":"Placas","provincia":"Pará","pais":"BRASIL","latitud":-3.8696335560262076,"longitud":-54.219854941560804,"ranking":0},
{"id":9406,"nombre":"Ponta de Pedras","localidad":"Ponta de Pedras","provincia":"Pará","pais":"BRASIL","latitud":-1.3955131975439539,"longitud":-48.871710814928726,"ranking":0},
{"id":9616,"nombre":"Prainha","localidad":"Prainha","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10494,"nombre":"Quatipuru","localidad":"Quatipuru","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6629,"nombre":"Redencao ","localidad":"Redenção","provincia":"Pará","pais":"BRASIL","latitud":-8.040525971555954,"longitud":-50.02213253641776,"ranking":0},
{"id":6631,"nombre":"Rio Maria","localidad":"Rio Maria","provincia":"Pará","pais":"BRASIL","latitud":-7.311515724415718,"longitud":-50.043996397206335,"ranking":0},
{"id":6627,"nombre":"Rondon do Para","localidad":"Rondon do Pará","provincia":"Pará","pais":"BRASIL","latitud":-4.772693574905622,"longitud":-48.074552801921506,"ranking":0},
{"id":8445,"nombre":"Ruropolis","localidad":"Rurópolis","provincia":"Pará","pais":"BRASIL","latitud":-4.096195203250234,"longitud":-54.91182568450036,"ranking":0},
{"id":14958,"nombre":"Salinopolis","localidad":"Salinópolis","provincia":"Pará","pais":"BRASIL","latitud":-0.6201947999999999,"longitud":-47.3492046,"ranking":0},
{"id":9617,"nombre":"Salvaterra","localidad":"Salvaterra","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7429,"nombre":"Santa Isabel do Para","localidad":"Santa Isabel do Pará","provincia":"Pará","pais":"BRASIL","latitud":-1.2984704152426232,"longitud":-48.16435866163996,"ranking":0},
{"id":10564,"nombre":"Santa Luzia do Para","localidad":"Santa Luzia do Pará","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15062,"nombre":"Santa Maria das Barreiras","localidad":"Santa Maria das Barreiras","provincia":"Pará","pais":"BRASIL","latitud":-8.810021502140597,"longitud":-49.556817890245604,"ranking":0},
{"id":6628,"nombre":"Santa Maria","localidad":"Santa Maria do Pará","provincia":"Pará","pais":"BRASIL","latitud":-1.3437132,"longitud":-47.5782313,"ranking":0},
{"id":9478,"nombre":"Santana do Araguaia","localidad":"Santana do Araguaia","provincia":"Pará","pais":"BRASIL","latitud":-9.341776545201188,"longitud":-50.344171262172516,"ranking":0},
{"id":10174,"nombre":"Sao Domingos do Araguaia","localidad":"São Domingos do Araguaia","provincia":"Pará","pais":"BRASIL","latitud":-5.5348962328325255,"longitud":-48.73242391807648,"ranking":0},
{"id":9480,"nombre":"Sao Felix do Xingu","localidad":"São Félix do Xingu","provincia":"Pará","pais":"BRASIL","latitud":-6.642856841711204,"longitud":-51.96066471841891,"ranking":0},
{"id":14810,"nombre":"Sao Geraldo do Araguaia","localidad":"São Geraldo do Araguaia","provincia":"Pará","pais":"BRASIL","latitud":-6.390330000000001,"longitud":-48.5654,"ranking":0},
{"id":9869,"nombre":"Sao Miguel do Guama","localidad":"São Miguel do Guamá","provincia":"Pará","pais":"BRASIL","latitud":-1.622597016981455,"longitud":-47.47900290519745,"ranking":0},
{"id":5905,"nombre":"Sapucaia","localidad":"Sapucaia","provincia":"Pará","pais":"BRASIL","latitud":-6.942539999999999,"longitud":-49.6975,"ranking":0},
{"id":9488,"nombre":"Soure","localidad":"Soure","provincia":"Pará","pais":"BRASIL","latitud":-0.7298057150765244,"longitud":-48.52114406128049,"ranking":0},
{"id":8447,"nombre":"Tailandia","localidad":"Tailândia","provincia":"Pará","pais":"BRASIL","latitud":-2.9388532686476245,"longitud":-48.956874140702894,"ranking":0},
{"id":10495,"nombre":"Tome Acu","localidad":"Tomé-Açu","provincia":"Pará","pais":"BRASIL","latitud":-2.418626306103569,"longitud":-48.24387026797528,"ranking":0},
{"id":10496,"nombre":"Tracuateua","localidad":"Tracuateua","provincia":"Pará","pais":"BRASIL","latitud":-1.0746413855127197,"longitud":-46.90409288332954,"ranking":0},
{"id":9242,"nombre":"Trairao","localidad":"Trairão","provincia":"Pará","pais":"BRASIL","latitud":-4.702140395391331,"longitud":-55.997145000006036,"ranking":0},
{"id":9243,"nombre":"Caracol","localidad":"Trairão","provincia":"Pará","pais":"BRASIL","latitud":-4.699003555125835,"longitud":-55.99431868207432,"ranking":0},
{"id":8448,"nombre":"Tucuma","localidad":"Tucumã","provincia":"Pará","pais":"BRASIL","latitud":-6.756164989478071,"longitud":-51.14428701622967,"ranking":0},
{"id":8449,"nombre":"Tucurui","localidad":"Tucuruí","provincia":"Pará","pais":"BRASIL","latitud":-3.781051894902775,"longitud":-49.676886639398944,"ranking":0},
{"id":8450,"nombre":"Ulianopolis ","localidad":"Ulianópolis","provincia":"Pará","pais":"BRASIL","latitud":-3.757219277200913,"longitud":-47.4988432072994,"ranking":0},
{"id":8451,"nombre":"Uruara","localidad":"Uruará","provincia":"Pará","pais":"BRASIL","latitud":-3.715593645161652,"longitud":-53.736497879320275,"ranking":0},
{"id":10497,"nombre":"Viseu","localidad":"Viseu","provincia":"Pará","pais":"BRASIL","latitud":-1.202765974085754,"longitud":-46.14201954202,"ranking":0},
{"id":6632,"nombre":"Xinguara","localidad":"Xinguara","provincia":"Pará","pais":"BRASIL","latitud":-7.090663913793784,"longitud":-49.9369832974901,"ranking":0},
{"id":14416,"nombre":"Aguiarnopolis","localidad":"Aguiarnópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-6.5622494,"longitud":-47.47400440000001,"ranking":0},
{"id":9881,"nombre":"Alianca do Tocantins","localidad":"Aliança do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-11.305861167551114,"longitud":-48.93838937052797,"ranking":0},
{"id":8223,"nombre":"Almas","localidad":"Almas","provincia":"Tocantins","pais":"BRASIL","latitud":-11.577229359775142,"longitud":-47.17182836630678,"ranking":0},
{"id":8224,"nombre":"Alvorada","localidad":"Alvorada","provincia":"Tocantins","pais":"BRASIL","latitud":-12.484294721086485,"longitud":-49.118070568436735,"ranking":0},
{"id":14729,"nombre":"Ananas","localidad":"Ananás","provincia":"Tocantins","pais":"BRASIL","latitud":-6.363488790146967,"longitud":-48.07243277468907,"ranking":0},
{"id":14874,"nombre":"Angico","localidad":"Angico","provincia":"Tocantins","pais":"BRASIL","latitud":-6.391597493512143,"longitud":-47.86428225905741,"ranking":0},
{"id":14868,"nombre":"Aparecida do Rio Negro","localidad":"Aparecida do Rio Negro","provincia":"Tocantins","pais":"BRASIL","latitud":-9.948756254860006,"longitud":-47.986289710603884,"ranking":0},
{"id":8249,"nombre":"Araguacu ","localidad":"Araguaçu","provincia":"Tocantins","pais":"BRASIL","latitud":-12.932612914332722,"longitud":-49.8266433160467,"ranking":0},
{"id":4913,"nombre":"Araguaina","localidad":"Araguaína","provincia":"Tocantins","pais":"BRASIL","latitud":-7.1895267,"longitud":-48.22207419999999,"ranking":0},
{"id":8225,"nombre":"Araguatins ","localidad":"Araguatins","provincia":"Tocantins","pais":"BRASIL","latitud":-5.652295563622669,"longitud":-48.12109012158595,"ranking":0},
{"id":14867,"nombre":"Arapoema","localidad":"Arapoema","provincia":"Tocantins","pais":"BRASIL","latitud":-7.657909044390909,"longitud":-49.067403478752574,"ranking":0},
{"id":6546,"nombre":"Arraias","localidad":"Arraias","provincia":"Tocantins","pais":"BRASIL","latitud":-12.934570528726494,"longitud":-46.93662899640568,"ranking":0},
{"id":8226,"nombre":"Augustinopolis ","localidad":"Augustinópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-5.468685437241291,"longitud":-47.88830565979543,"ranking":0},
{"id":14750,"nombre":"Aurora do Tocantins","localidad":"Aurora do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-12.7155498,"longitud":-46.4082888,"ranking":0},
{"id":14560,"nombre":"Axixa do Tocantins","localidad":"Axixá do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-5.614095300000001,"longitud":-47.7683542,"ranking":0},
{"id":14936,"nombre":"babaculandia ","localidad":"Babaçulândia","provincia":"Tocantins","pais":"BRASIL","latitud":-7.202819014670156,"longitud":-47.75955825860092,"ranking":0},
{"id":14802,"nombre":"Bandeirantes do Tocantins","localidad":"Bandeirantes do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-7.7625113,"longitud":-48.5760186,"ranking":0},
{"id":14866,"nombre":"Barra do ouro","localidad":"Barra do Ouro","provincia":"Tocantins","pais":"BRASIL","latitud":-7.712457400702843,"longitud":-47.67461537501758,"ranking":0},
{"id":8228,"nombre":"Barrolandia ","localidad":"Barrolândia","provincia":"Tocantins","pais":"BRASIL","latitud":-9.84194502677483,"longitud":-48.7246151803163,"ranking":0},
{"id":14563,"nombre":"Brasilandia do Tocantins","localidad":"Brasilândia do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-8.390532799999999,"longitud":-48.4863866,"ranking":0},
{"id":14799,"nombre":"Brejinho de Nazare","localidad":"Brejinho de Nazaré","provincia":"Tocantins","pais":"BRASIL","latitud":-11.0148087,"longitud":-48.5639846,"ranking":0},
{"id":14865,"nombre":"Buriti do Tocantins","localidad":"Buriti do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-5.3168444634895,"longitud":-48.22658280804485,"ranking":0},
{"id":8245,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Tocantins","pais":"BRASIL","latitud":-6.114950315341177,"longitud":-47.91755563092649,"ranking":0},
{"id":14864,"nombre":"Cariri do Tocantins","localidad":"Cariri do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9461,"nombre":"Caseara","localidad":"Caseara","provincia":"Tocantins","pais":"BRASIL","latitud":-9.280773858530495,"longitud":-49.951927989377204,"ranking":0},
{"id":12352,"nombre":"Chapada da Natividade","localidad":"Chapada da Natividade","provincia":"Tocantins","pais":"BRASIL","latitud":-11.6190392,"longitud":-47.7547947,"ranking":0},
{"id":9872,"nombre":"Colinas do Tocantins","localidad":"Colinas do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-8.063428157614766,"longitud":-48.473268389386426,"ranking":0},
{"id":6547,"nombre":"Combinado","localidad":"Combinado","provincia":"Tocantins","pais":"BRASIL","latitud":-12.813946484218151,"longitud":-46.5462627305029,"ranking":0},
{"id":14931,"nombre":"Conceicao do Tocantins","localidad":"Conceição do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-12.2225799,"longitud":-47.2924071,"ranking":0},
{"id":10395,"nombre":"Couto de Magalhaes","localidad":"Couto Magalhães","provincia":"Tocantins","pais":"BRASIL","latitud":-8.359229208238437,"longitud":-49.180929908526245,"ranking":0},
{"id":8232,"nombre":"Cristalandia ","localidad":"Cristalândia","provincia":"Tocantins","pais":"BRASIL","latitud":-10.606434146099287,"longitud":-49.19750183169378,"ranking":0},
{"id":14797,"nombre":"Crixas do Tocantins","localidad":"Crixás do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-11.1021527,"longitud":-48.9202953,"ranking":0},
{"id":14500,"nombre":"Darcinopolis","localidad":"Darcinópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-6.706696,"longitud":-47.7552993,"ranking":0},
{"id":6548,"nombre":"Dianopolis","localidad":"Dianópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-11.61443215587416,"longitud":-46.829635921894315,"ranking":0},
{"id":9462,"nombre":"Divinopolis do Tocantins","localidad":"Divinópolis do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14726,"nombre":"Esperantina","localidad":"Esperantina","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4941,"nombre":"Fatima","localidad":"Fátima","provincia":"Tocantins","pais":"BRASIL","latitud":-10.75528345015565,"longitud":-48.90316300285662,"ranking":0},
{"id":8246,"nombre":"Figueiropolis ","localidad":"Figueirópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-12.139038199051727,"longitud":-49.17046563498778,"ranking":0},
{"id":8242,"nombre":"Filadelfia","localidad":"Filadélfia","provincia":"Tocantins","pais":"BRASIL","latitud":-7.337800611422939,"longitud":-47.50122040965379,"ranking":0},
{"id":14796,"nombre":"Fortaleza do Tabocao","localidad":"Fortaleza do Tabocão","provincia":"Tocantins","pais":"BRASIL","latitud":-9.060293099999999,"longitud":-48.5214228,"ranking":0},
{"id":4942,"nombre":"Gurupi","localidad":"Gurupi","provincia":"Tocantins","pais":"BRASIL","latitud":-11.7364428,"longitud":-49.0687009,"ranking":0},
{"id":14745,"nombre":"Itacaja","localidad":"Itacajá","provincia":"Tocantins","pais":"BRASIL","latitud":-8.39278868887258,"longitud":-47.770633233433394,"ranking":0},
{"id":14962,"nombre":"Jau do Tocantins","localidad":"Jaú do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-12.654517084480597,"longitud":-48.59175172988408,"ranking":0},
{"id":8233,"nombre":"Lagoa da Confusao","localidad":"Lagoa da Confusão","provincia":"Tocantins","pais":"BRASIL","latitud":-10.777007442306585,"longitud":-49.607444797084135,"ranking":0},
{"id":14564,"nombre":"Lajeado","localidad":"Lajeado","provincia":"Tocantins","pais":"BRASIL","latitud":-29.4513954,"longitud":-51.9782599,"ranking":0},
{"id":14712,"nombre":"Lavandeira","localidad":"Lavandeira","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14744,"nombre":"Luzinopolis","localidad":"Luzinópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-6.190763006716096,"longitud":-47.85783231871429,"ranking":0},
{"id":15061,"nombre":"Marianopolis do Tocantins","localidad":"Marianópolis do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14561,"nombre":"Miracema do Tocantins","localidad":"Miracema do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-9.567420199999999,"longitud":-48.4115228,"ranking":0},
{"id":4976,"nombre":"Miranorte","localidad":"Miranorte","provincia":"Tocantins","pais":"BRASIL","latitud":-9.531872316291114,"longitud":-48.5898087669023,"ranking":0},
{"id":14502,"nombre":"Palmeiras Do Tocantins","localidad":"Palmeiras do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-6.613643499999999,"longitud":-47.5464079,"ranking":0},
{"id":14793,"nombre":"Natividade","localidad":"Natividade","provincia":"Tocantins","pais":"BRASIL","latitud":-11.7123908,"longitud":-47.7263169,"ranking":0},
{"id":14725,"nombre":"Nazare","localidad":"Nazaré","provincia":"Tocantins","pais":"BRASIL","latitud":-6.3730688189070115,"longitud":-47.664560955456516,"ranking":0},
{"id":8244,"nombre":"Nova Olinda","localidad":"Nova Olinda","provincia":"Tocantins","pais":"BRASIL","latitud":-7.636939978792381,"longitud":-48.423208077167004,"ranking":0},
{"id":12416,"nombre":"Nova Rosalandia","localidad":"Nova Rosalândia","provincia":"Tocantins","pais":"BRASIL","latitud":-10.5665276,"longitud":-48.91463599999999,"ranking":0},
{"id":10398,"nombre":"Novo Alegre","localidad":"Novo Alegre","provincia":"Tocantins","pais":"BRASIL","latitud":-12.91577015182911,"longitud":-46.574432047721984,"ranking":0},
{"id":10396,"nombre":"Novo Jardim","localidad":"Novo Jardim","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14503,"nombre":"Oliveira De Fatima","localidad":"Oliveira de Fátima","provincia":"Tocantins","pais":"BRASIL","latitud":-10.709134,"longitud":-48.91082670000001,"ranking":0},
{"id":8250,"nombre":"Palmeiropolis ","localidad":"Palmeirópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-13.041288320442177,"longitud":-48.40158804339341,"ranking":0},
{"id":4992,"nombre":"Paraiso do Tocantins","localidad":"Paraíso do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-10.168869773499356,"longitud":-48.88440493240639,"ranking":0},
{"id":14743,"nombre":"Parana","localidad":"Paranã","provincia":"Tocantins","pais":"BRASIL","latitud":-12.615148252822781,"longitud":-47.87996831524292,"ranking":0},
{"id":14742,"nombre":"Pau D Arco","localidad":"Pau D'Arco","provincia":"Tocantins","pais":"BRASIL","latitud":-7.539780857646974,"longitud":-49.37291623260376,"ranking":0},
{"id":8247,"nombre":"Pedro Afonso","localidad":"Pedro Afonso","provincia":"Tocantins","pais":"BRASIL","latitud":-8.985383463356145,"longitud":-48.16689521486265,"ranking":0},
{"id":8235,"nombre":"Peixe","localidad":"Peixe","provincia":"Tocantins","pais":"BRASIL","latitud":-12.037325493348531,"longitud":-48.53947538757661,"ranking":0},
{"id":8236,"nombre":"Pequizeiro ","localidad":"Pequizeiro","provincia":"Tocantins","pais":"BRASIL","latitud":-8.59093219570006,"longitud":-48.932186024793396,"ranking":0},
{"id":8230,"nombre":"Colmeia","localidad":"Colméia","provincia":"Tocantins","pais":"BRASIL","latitud":-8.733293397587412,"longitud":-48.75289994128315,"ranking":0},
{"id":7354,"nombre":"Ponte Alta do Bom Jesus","localidad":"Ponte Alta do Bom Jesus","provincia":"Tocantins","pais":"BRASIL","latitud":-12.09021062827582,"longitud":-46.47935297077732,"ranking":0},
{"id":14801,"nombre":"Porto Alegre do Tocantins","localidad":"Porto Alegre do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-11.609999,"longitud":-47.04900199999999,"ranking":0},
{"id":6553,"nombre":"Porto Nacional","localidad":"Porto Nacional","provincia":"Tocantins","pais":"BRASIL","latitud":-10.728536155500276,"longitud":-48.4092197606496,"ranking":0},
{"id":10531,"nombre":"Presidente Kennedy","localidad":"Presidente Kennedy","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14949,"nombre":"Pugmil","localidad":"Pugmil","provincia":"Tocantins","pais":"BRASIL","latitud":-10.424882014397816,"longitud":-48.898647331623245,"ranking":0},
{"id":15226,"nombre":"Recursolandia","localidad":"Recursolândia","provincia":"Tocantins","pais":"BRASIL","latitud":-8.735990750382172,"longitud":-47.24229935596633,"ranking":0},
{"id":14724,"nombre":"Riachinho","localidad":"Riachinho","provincia":"Tocantins","pais":"BRASIL","latitud":-6.445381683228665,"longitud":-48.13608213966784,"ranking":0},
{"id":14565,"nombre":"Rio dos Bois","localidad":"Rio dos Bois","provincia":"Tocantins","pais":"BRASIL","latitud":-9.343597700000002,"longitud":-48.5353292,"ranking":0},
{"id":14740,"nombre":"Rio Sono","localidad":"Rio Sono","provincia":"Tocantins","pais":"BRASIL","latitud":-9.34533004052603,"longitud":-47.89057294935692,"ranking":0},
{"id":8251,"nombre":"Sandolandia","localidad":"Sandolândia","provincia":"Tocantins","pais":"BRASIL","latitud":-12.542686979307678,"longitud":-49.93218871652113,"ranking":0},
{"id":14738,"nombre":"Santa Maria do Tocantins","localidad":"Santa Maria do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-8.773068697564973,"longitud":-47.748616381317554,"ranking":0},
{"id":14711,"nombre":"Santa Rita do Tocantins","localidad":"Santa Rita do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-10.864726526114502,"longitud":-48.90550390545746,"ranking":0},
{"id":10397,"nombre":"Santa Rosa do Tocantins","localidad":"Santa Rosa do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-11.448335751279146,"longitud":-48.11845207212228,"ranking":0},
{"id":14943,"nombre":"Sao Miguel do Tocantins","localidad":"São Miguel do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-5.553224104452646,"longitud":-47.577985810314516,"ranking":0},
{"id":14794,"nombre":"Sao Valerio","localidad":"São Valério","provincia":"Tocantins","pais":"BRASIL","latitud":-11.971850570057208,"longitud":-48.233069172498766,"ranking":0},
{"id":10094,"nombre":"Silvanopolis","localidad":"Silvanópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-11.150725961102047,"longitud":-48.17112801712818,"ranking":0},
{"id":14795,"nombre":"Sítio Novo do Tocantins","localidad":"Sítio Novo do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-5.601854899999999,"longitud":-47.6445989,"ranking":0},
{"id":6551,"nombre":"Taguatinga","localidad":"Taguatinga","provincia":"Tocantins","pais":"BRASIL","latitud":-12.400363251658337,"longitud":-46.43782047333858,"ranking":0},
{"id":8241,"nombre":"Talisma","localidad":"Talismã","provincia":"Tocantins","pais":"BRASIL","latitud":-12.789994180039555,"longitud":-49.09171221148176,"ranking":0},
{"id":8237,"nombre":"Tocantinopolis","localidad":"Tocantinópolis","provincia":"Tocantins","pais":"BRASIL","latitud":-6.325186882278671,"longitud":-47.43354604146786,"ranking":0},
{"id":14798,"nombre":"Tupirama","localidad":"Tupirama","provincia":"Tocantins","pais":"BRASIL","latitud":-8.9754478,"longitud":-48.1909989,"ranking":0},
{"id":14730,"nombre":"Tupiratins","localidad":"Tupiratins","provincia":"Tocantins","pais":"BRASIL","latitud":-8.400307532458344,"longitud":-48.131836365175275,"ranking":0},
{"id":8238,"nombre":"Wanderlandia ","localidad":"Wanderlândia","provincia":"Tocantins","pais":"BRASIL","latitud":-6.849699637840183,"longitud":-47.96379747291966,"ranking":0},
{"id":8240,"nombre":"Xambioa","localidad":"Xambioá","provincia":"Tocantins","pais":"BRASIL","latitud":-6.411563264645309,"longitud":-48.53053744151973,"ranking":0},
{"id":4902,"nombre":"Acailandia","localidad":"Açailândia","provincia":"Maranhão","pais":"BRASIL","latitud":-4.95197,"longitud":-47.4997045,"ranking":0},
{"id":14902,"nombre":"Afonso Cunha","localidad":"Afonso Cunha","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7010,"nombre":"Alto Alegre do Maranhao ","localidad":"Alto Alegre do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-4.212833898474178,"longitud":-44.450806904750685,"ranking":0},
{"id":14601,"nombre":"Alto Parnaiba","localidad":"Alto Parnaíba","provincia":"Maranhão","pais":"BRASIL","latitud":-9.111746799999999,"longitud":-45.9333068,"ranking":0},
{"id":14903,"nombre":"Amapa do Maranhao","localidad":"Amapá do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-4.133681170210735,"longitud":-43.32368209669279,"ranking":0},
{"id":7012,"nombre":"Anapurus ","localidad":"Anapurus","provincia":"Maranhão","pais":"BRASIL","latitud":-3.672941902146036,"longitud":-43.114654048825,"ranking":0},
{"id":10479,"nombre":"Apicum Acu","localidad":"Apicum-Açu","provincia":"Maranhão","pais":"BRASIL","latitud":-1.5388750712567683,"longitud":-45.07995163043582,"ranking":0},
{"id":14901,"nombre":"Araguana","localidad":"Araguanã","provincia":"Maranhão","pais":"BRASIL","latitud":-2.953729991989758,"longitud":-45.664593592688874,"ranking":0},
{"id":7829,"nombre":"Araioses","localidad":"Araioses","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7041,"nombre":"Entrocamento Arame ","localidad":"Arame","provincia":"Maranhão","pais":"BRASIL","latitud":-4.88428683381563,"longitud":-46.01152712998044,"ranking":0},
{"id":7014,"nombre":"Arari ","localidad":"Arari","provincia":"Maranhão","pais":"BRASIL","latitud":-3.4621254444248404,"longitud":-44.775715425610045,"ranking":0},
{"id":4914,"nombre":"Bacabal","localidad":"Bacabal","provincia":"Maranhão","pais":"BRASIL","latitud":-4.224485437322875,"longitud":-44.7786588655765,"ranking":0},
{"id":7015,"nombre":"Bacabeira ","localidad":"Bacabeira","provincia":"Maranhão","pais":"BRASIL","latitud":-2.97146008104948,"longitud":-44.31696696518431,"ranking":0},
{"id":10480,"nombre":"Bacuri","localidad":"Bacuri","provincia":"Maranhão","pais":"BRASIL","latitud":-1.730024142951048,"longitud":-45.14169714062807,"ranking":0},
{"id":7096,"nombre":"Balsas","localidad":"Balsas","provincia":"Maranhão","pais":"BRASIL","latitud":-7.521078886098824,"longitud":-46.03766580371427,"ranking":0},
{"id":14759,"nombre":"Barao de Grajau","localidad":"Barão de Grajaú","provincia":"Maranhão","pais":"BRASIL","latitud":-6.754104059171847,"longitud":-43.02608587283732,"ranking":0},
{"id":9870,"nombre":"Barra do Corda","localidad":"Barra do Corda","provincia":"Maranhão","pais":"BRASIL","latitud":-5.507427892799044,"longitud":-45.238484216815316,"ranking":0},
{"id":10449,"nombre":"Barreirinhas","localidad":"Barreirinhas","provincia":"Maranhão","pais":"BRASIL","latitud":-2.7490378523507077,"longitud":-42.826398533360255,"ranking":77},
{"id":9603,"nombre":"Bela Vista","localidad":"Bela Vista do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-3.740132948761125,"longitud":-45.2523469898863,"ranking":0},
{"id":7057,"nombre":"Leite ","localidad":"Leite","provincia":"Maranhão","pais":"BRASIL","latitud":-10.99537203507447,"longitud":-37.30568172229435,"ranking":0},
{"id":15259,"nombre":"Bernardo do Mearim","localidad":"Bernardo do Mearim","provincia":"Maranhão","pais":"BRASIL","latitud":-4.62795202865144,"longitud":-44.76118946587569,"ranking":0},
{"id":7018,"nombre":"Boa Vista do Gurupi  ","localidad":"Boa Vista do Gurupi","provincia":"Maranhão","pais":"BRASIL","latitud":-1.7955978950870513,"longitud":-46.30837105963045,"ranking":0},
{"id":7020,"nombre":"Bom Jardim  ","localidad":"Bom Jardim","provincia":"Maranhão","pais":"BRASIL","latitud":-3.542123150667818,"longitud":-45.611580833087785,"ranking":0},
{"id":7019,"nombre":"Bom Jesus das Selvas ","localidad":"Bom Jesus das Selvas","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14910,"nombre":"Bom Lugar","localidad":"Bom Lugar","provincia":"Maranhão","pais":"BRASIL","latitud":-4.372655484883217,"longitud":-45.03006546704028,"ranking":0},
{"id":7023,"nombre":"Brejo","localidad":"Brejo","provincia":"Maranhão","pais":"BRASIL","latitud":-3.6829525606752718,"longitud":-42.75155129488758,"ranking":0},
{"id":7024,"nombre":"Buriticupu ","localidad":"Buriticupu","provincia":"Maranhão","pais":"BRASIL","latitud":-4.325968659059321,"longitud":-46.439170137657406,"ranking":0},
{"id":7837,"nombre":"Buritirana","localidad":"Buritirana","provincia":"Maranhão","pais":"BRASIL","latitud":-5.594221405837785,"longitud":-47.02107555338846,"ranking":0},
{"id":7026,"nombre":"Campestre do Maranhao","localidad":"Campestre do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-6.171237300153783,"longitud":-47.36526407120546,"ranking":0},
{"id":15235,"nombre":"Candido Mendes","localidad":"Cândido Mendes","provincia":"Maranhão","pais":"BRASIL","latitud":-1.4547475237992562,"longitud":-45.72783389372555,"ranking":0},
{"id":7029,"nombre":"Cantanhede ","localidad":"Cantanhede","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7028,"nombre":"Capinzal do Norte ","localidad":"Capinzal do Norte","provincia":"Maranhão","pais":"BRASIL","latitud":-4.723761693730587,"longitud":-44.32762683403318,"ranking":0},
{"id":7097,"nombre":"Carolina","localidad":"Carolina","provincia":"Maranhão","pais":"BRASIL","latitud":-7.328672105228556,"longitud":-47.4551090005503,"ranking":0},
{"id":14904,"nombre":"Carutapera","localidad":"Carutapera","provincia":"Maranhão","pais":"BRASIL","latitud":-1.5994227,"longitud":-45.9800848,"ranking":0},
{"id":4936,"nombre":"Caxias","localidad":"Caxias","provincia":"Maranhão","pais":"BRASIL","latitud":-4.8818802569718525,"longitud":-43.346121831732795,"ranking":0},
{"id":7030,"nombre":"Chapadinha ","localidad":"Chapadinha","provincia":"Maranhão","pais":"BRASIL","latitud":-3.743470231894931,"longitud":-43.358442704752235,"ranking":0},
{"id":7032,"nombre":"Codo ","localidad":"Codó","provincia":"Maranhão","pais":"BRASIL","latitud":-4.4877179,"longitud":-43.8924881,"ranking":0},
{"id":7033,"nombre":"Coelho Neto ","localidad":"Coelho Neto","provincia":"Maranhão","pais":"BRASIL","latitud":-4.264318636081882,"longitud":-43.021580705226704,"ranking":0},
{"id":7034,"nombre":"Colinas ","localidad":"Colinas","provincia":"Maranhão","pais":"BRASIL","latitud":-6.028528197603843,"longitud":-44.242778672981395,"ranking":0},
{"id":7037,"nombre":"Coroata","localidad":"Coroatá","provincia":"Maranhão","pais":"BRASIL","latitud":-4.131011242007535,"longitud":-44.130551736208645,"ranking":0},
{"id":7839,"nombre":"Cururupu","localidad":"Cururupu","provincia":"Maranhão","pais":"BRASIL","latitud":-1.8281480795120966,"longitud":-44.87239773083742,"ranking":0},
{"id":7040,"nombre":"Dom Pedro","localidad":"Dom Pedro","provincia":"Maranhão","pais":"BRASIL","latitud":-5.032017183323028,"longitud":-44.43566786954327,"ranking":0},
{"id":14911,"nombre":"Esperantinopolis","localidad":"Esperantinópolis","provincia":"Maranhão","pais":"BRASIL","latitud":-4.876105270370692,"longitud":-44.88245147063031,"ranking":0},
{"id":7043,"nombre":"Estreito","localidad":"Estreito","provincia":"Maranhão","pais":"BRASIL","latitud":-6.547784717096254,"longitud":-47.42744220590056,"ranking":0},
{"id":7045,"nombre":"Fortuna","localidad":"Fortuna","provincia":"Maranhão","pais":"BRASIL","latitud":-5.737194693388424,"longitud":-44.160609862826234,"ranking":0},
{"id":14905,"nombre":"Godofredo Viana","localidad":"Godofredo Viana","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14599,"nombre":"Governador Edison Lobao","localidad":"Governador Edison Lobão","provincia":"Maranhão","pais":"BRASIL","latitud":-5.750006399999999,"longitud":-47.3595098,"ranking":0},
{"id":14900,"nombre":"Governador Newton Bello","localidad":"Governador Newton Bello","provincia":"Maranhão","pais":"BRASIL","latitud":-3.426240591224565,"longitud":-45.66966483088019,"ranking":0},
{"id":7047,"nombre":"Governador Nunes Freire ","localidad":"Governador Nunes Freire","provincia":"Maranhão","pais":"BRASIL","latitud":-2.1313121587254584,"longitud":-45.88601121715671,"ranking":0},
{"id":7048,"nombre":"Grajau ","localidad":"Grajaú","provincia":"Maranhão","pais":"BRASIL","latitud":-5.815695764776874,"longitud":-46.13503531829785,"ranking":0},
{"id":10452,"nombre":"Humberto de Campos","localidad":"Humberto de Campos","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7049,"nombre":"Igarape do Meio ","localidad":"Igarapé do Meio","provincia":"Maranhão","pais":"BRASIL","latitud":-3.6395431632169086,"longitud":-45.183789679010644,"ranking":0},
{"id":14858,"nombre":"Igarape Grande","localidad":"Igarapé Grande","provincia":"Maranhão","pais":"BRASIL","latitud":-4.6637496,"longitud":-44.8585116,"ranking":0},
{"id":7052,"nombre":"Itapecuru Mirim ","localidad":"Itapecuru Mirim","provincia":"Maranhão","pais":"BRASIL","latitud":-3.4053474657450105,"longitud":-44.35129901544417,"ranking":0},
{"id":7053,"nombre":"Itinga do Maranhao ","localidad":"Itinga do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-4.452784671901097,"longitud":-47.53085508582217,"ranking":0},
{"id":14562,"nombre":"Joao Lisboa","localidad":"João Lisboa","provincia":"Maranhão","pais":"BRASIL","latitud":-5.4490237,"longitud":-47.40100469999999,"ranking":0},
{"id":7054,"nombre":"Junco do Maranhao ","localidad":"Junco do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-1.8778331710770024,"longitud":-46.07200056337986,"ranking":0},
{"id":7055,"nombre":"Lago da Pedra ","localidad":"Lago da Pedra","provincia":"Maranhão","pais":"BRASIL","latitud":-4.5673143933948674,"longitud":-45.124207188076994,"ranking":0},
{"id":14912,"nombre":"Lago dos Rodriguez","localidad":"Lago dos Rodrigues","provincia":"Maranhão","pais":"BRASIL","latitud":-4.614023599325351,"longitud":-44.98300435067723,"ranking":0},
{"id":14913,"nombre":"Lima Campos","localidad":"Lima Campos","provincia":"Maranhão","pais":"BRASIL","latitud":-4.520900374427039,"longitud":-44.46656038039984,"ranking":0},
{"id":14906,"nombre":"Luis Domingues","localidad":"Luís Domingues","provincia":"Maranhão","pais":"BRASIL","latitud":-1.327166842426174,"longitud":-45.89681200402243,"ranking":0},
{"id":7059,"nombre":"Maracacume","localidad":"Maracaçumé","provincia":"Maranhão","pais":"BRASIL","latitud":-2.048681579364523,"longitud":-45.961171761656765,"ranking":0},
{"id":7060,"nombre":"Maranhaozinho ","localidad":"Maranhãozinho","provincia":"Maranhão","pais":"BRASIL","latitud":-2.2441424106662407,"longitud":-45.85561778704036,"ranking":0},
{"id":7061,"nombre":"Mata Roma ","localidad":"Mata Roma","provincia":"Maranhão","pais":"BRASIL","latitud":-3.62083005246127,"longitud":-43.10913216366926,"ranking":0},
{"id":7062,"nombre":"Matoes ","localidad":"Matões","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7853,"nombre":"Matoes do Norte ","localidad":"Matões do Norte","provincia":"Maranhão","pais":"BRASIL","latitud":-3.6267388652386225,"longitud":-44.55436773958903,"ranking":0},
{"id":7098,"nombre":"Miranda do Norte","localidad":"Miranda do Norte","provincia":"Maranhão","pais":"BRASIL","latitud":-3.5639010185450637,"longitud":-44.58554489206895,"ranking":0},
{"id":7842,"nombre":"Mirinzal","localidad":"Mirinzal","provincia":"Maranhão","pais":"BRASIL","latitud":-2.0629086525724407,"longitud":-44.780624479377764,"ranking":0},
{"id":7063,"nombre":"Morros ","localidad":"Morros","provincia":"Maranhão","pais":"BRASIL","latitud":-2.86592912512709,"longitud":-44.04225250744221,"ranking":0},
{"id":9512,"nombre":"Nova Iorque","localidad":"Nova Iorque","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14760,"nombre":"Nova Olinda do Maranhão","localidad":"Nova Olinda do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-2.8080611,"longitud":-45.7036899,"ranking":0},
{"id":7099,"nombre":"Paraibano","localidad":"Paraibano","provincia":"Maranhão","pais":"BRASIL","latitud":-6.423209023247729,"longitud":-43.991474278761714,"ranking":0},
{"id":7844,"nombre":"Pastos Bons","localidad":"Pastos Bons","provincia":"Maranhão","pais":"BRASIL","latitud":-27.101787595247284,"longitud":-53.3992175153787,"ranking":0},
{"id":7070,"nombre":"Paulo Ramos ","localidad":"Paulo Ramos","provincia":"Maranhão","pais":"BRASIL","latitud":-4.444365700936441,"longitud":-45.24010537504407,"ranking":0},
{"id":7071,"nombre":"Pedreiras ","localidad":"Pedreiras","provincia":"Maranhão","pais":"BRASIL","latitud":-4.569786602316794,"longitud":-44.60477863649975,"ranking":0},
{"id":14501,"nombre":"Pedro Do Rosario","localidad":"Pedro do Rosário","provincia":"Maranhão","pais":"BRASIL","latitud":-2.968037,"longitud":-45.3483273,"ranking":0},
{"id":7072,"nombre":"Peritoro ","localidad":"Peritoró","provincia":"Maranhão","pais":"BRASIL","latitud":-4.372665023156511,"longitud":-44.33889517591409,"ranking":0},
{"id":7073,"nombre":"Pindare Mirim","localidad":"Pindaré-Mirim","provincia":"Maranhão","pais":"BRASIL","latitud":-3.611388855925975,"longitud":-45.3422723818095,"ranking":0},
{"id":7846,"nombre":"Pinheiro ","localidad":"Pinheiro","provincia":"Maranhão","pais":"BRASIL","latitud":-2.5181548,"longitud":-45.0780727,"ranking":0},
{"id":14907,"nombre":"Pio XII","localidad":"Pio XII","provincia":"Maranhão","pais":"BRASIL","latitud":-3.8903148734046984,"longitud":-45.17030926316792,"ranking":0},
{"id":7074,"nombre":"Pirapemas ","localidad":"Pirapemas","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14914,"nombre":"Pocao de Pedras","localidad":"Poção de Pedras","provincia":"Maranhão","pais":"BRASIL","latitud":-4.749301311004428,"longitud":-44.942147984778224,"ranking":0},
{"id":7075,"nombre":"Porto Franco ","localidad":"Porto Franco","provincia":"Maranhão","pais":"BRASIL","latitud":-6.353046761442115,"longitud":-47.37723112106323,"ranking":0},
{"id":7076,"nombre":"Presidente Dutra ","localidad":"Presidente Dutra","provincia":"Maranhão","pais":"BRASIL","latitud":-5.300503021463075,"longitud":-44.48820939118759,"ranking":0},
{"id":14908,"nombre":"Presidente Medici","localidad":"Presidente Médici","provincia":"Maranhão","pais":"BRASIL","latitud":-2.379509307370659,"longitud":-45.81987067431441,"ranking":0},
{"id":7101,"nombre":"Riachao","localidad":"Riachão","provincia":"Maranhão","pais":"BRASIL","latitud":-7.364957999999999,"longitud":-46.61683,"ranking":0},
{"id":14909,"nombre":"Ribamar Fiqueme","localidad":"Ribamar Fiquene","provincia":"Maranhão","pais":"BRASIL","latitud":-5.93344464405084,"longitud":-47.38346216983029,"ranking":0},
{"id":7078,"nombre":"Rosario","localidad":"Rosário","provincia":"Maranhão","pais":"BRASIL","latitud":-2.936860538883272,"longitud":-44.24658170290337,"ranking":0},
{"id":10481,"nombre":"Santa Helena","localidad":"Santa Helena","provincia":"Maranhão","pais":"BRASIL","latitud":-2.2379848190414102,"longitud":-45.298546386722805,"ranking":0},
{"id":7847,"nombre":"Santa Luzia","localidad":"Santa Luzia","provincia":"Maranhão","pais":"BRASIL","latitud":-3.9625078721363383,"longitud":-45.666515415416114,"ranking":0},
{"id":10572,"nombre":"Santa Luzia do Parua","localidad":"Santa Luzia do Paruá","provincia":"Maranhão","pais":"BRASIL","latitud":-2.547360624516466,"longitud":-45.77421760452497,"ranking":0},
{"id":7084,"nombre":"Santa Quiteria do Maranhao","localidad":"Santa Quitéria do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-3.4964234735871496,"longitud":-42.56228207054893,"ranking":0},
{"id":7085,"nombre":"Santa Rita","localidad":"Santa Rita","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7854,"nombre":"Santo Antonio dos Lopes","localidad":"Santo Antônio dos Lopes","provincia":"Maranhão","pais":"BRASIL","latitud":-4.86924716940964,"longitud":-44.35621726647786,"ranking":0},
{"id":10450,"nombre":"Sao Benedito do Rio Preto","localidad":"São Benedito do Rio Preto","provincia":"Maranhão","pais":"BRASIL","latitud":-3.335006142474353,"longitud":-43.525324874815176,"ranking":0},
{"id":7849,"nombre":"Sao Bento","localidad":"São Bento","provincia":"Maranhão","pais":"BRASIL","latitud":-2.7016453269274567,"longitud":-44.840573765994506,"ranking":0},
{"id":7080,"nombre":"Sao Bernardo","localidad":"São Bernardo","provincia":"Maranhão","pais":"BRASIL","latitud":-3.362727075279151,"longitud":-42.422878204743974,"ranking":0},
{"id":7851,"nombre":"Sao Domingos do Azeitao","localidad":"São Domingos do Azeitão","provincia":"Maranhão","pais":"BRASIL","latitud":-6.8131659043124895,"longitud":-44.645061592497385,"ranking":0},
{"id":14856,"nombre":"Sao Joao dos Patos","localidad":"São João dos Patos","provincia":"Maranhão","pais":"BRASIL","latitud":-6.496578899999999,"longitud":-43.6953507,"ranking":0},
{"id":7100,"nombre":"Sao Jose de Ribamar ","localidad":"São José de Ribamar","provincia":"Maranhão","pais":"BRASIL","latitud":-2.5586766272436057,"longitud":-44.06121971164813,"ranking":0},
{"id":5010,"nombre":"Sao Mateus do Maranhao","localidad":"São Mateus do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-4.030968446703169,"longitud":-44.4653806387334,"ranking":0},
{"id":7852,"nombre":"Sao Raimundo das Mangabeira","localidad":"São Raimundo das Mangabeiras","provincia":"Maranhão","pais":"BRASIL","latitud":-7.019268267042181,"longitud":-45.47529706947958,"ranking":0},
{"id":5821,"nombre":"Sao Vicente","localidad":"São Vicente Ferrer","provincia":"Maranhão","pais":"BRASIL","latitud":-2.8988372973842678,"longitud":-44.88237005913819,"ranking":0},
{"id":7082,"nombre":"Sitio Novo","localidad":"Sítio Novo","provincia":"Maranhão","pais":"BRASIL","latitud":-5.881979310962517,"longitud":-46.701433314748066,"ranking":0},
{"id":14857,"nombre":"Sucupira do Norte","localidad":"Sucupira do Norte","provincia":"Maranhão","pais":"BRASIL","latitud":-6.474406999999999,"longitud":-44.1923869,"ranking":0},
{"id":14600,"nombre":"Tasso Fragoso","localidad":"Tasso Fragoso","provincia":"Maranhão","pais":"BRASIL","latitud":-8.4655904,"longitud":-45.7503195,"ranking":0},
{"id":7088,"nombre":"Timbiras","localidad":"Timbiras","provincia":"Maranhão","pais":"BRASIL","latitud":-4.253795202665691,"longitud":-43.93939120289916,"ranking":0},
{"id":14915,"nombre":"Trizidela do Vale","localidad":"Trizidela do Vale","provincia":"Maranhão","pais":"BRASIL","latitud":-4.569271529232494,"longitud":-44.60478690542114,"ranking":0},
{"id":7090,"nombre":"Tutoia","localidad":"Tutóia","provincia":"Maranhão","pais":"BRASIL","latitud":-2.764995211548843,"longitud":-42.27850135982316,"ranking":0},
{"id":10451,"nombre":"Urbano Santos","localidad":"Urbano Santos","provincia":"Maranhão","pais":"BRASIL","latitud":-3.2088560246352884,"longitud":-43.40540350164947,"ranking":0},
{"id":7091,"nombre":"Vargem Grande","localidad":"Vargem Grande","provincia":"Maranhão","pais":"BRASIL","latitud":-3.5454254282452733,"longitud":-43.92179005819713,"ranking":0},
{"id":7092,"nombre":"Viana","localidad":"Viana","provincia":"Maranhão","pais":"BRASIL","latitud":-3.2112875980253244,"longitud":-44.99945184662691,"ranking":0},
{"id":7093,"nombre":"Vitoria do Mearim","localidad":"Vitória do Mearim","provincia":"Maranhão","pais":"BRASIL","latitud":-3.465159468399747,"longitud":-44.87242721824551,"ranking":0},
{"id":7094,"nombre":"Vitorino Freire","localidad":"Vitorino Freire","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7095,"nombre":"Ze Doca","localidad":"Zé Doca","provincia":"Maranhão","pais":"BRASIL","latitud":-3.263507753807553,"longitud":-45.65451020235574,"ranking":0},
{"id":10536,"nombre":"Acaua","localidad":"Acauã","provincia":"Piauí","pais":"BRASIL","latitud":-8.2146340410575,"longitud":-41.08295113842451,"ranking":0},
{"id":6930,"nombre":"Agua Branca","localidad":"Água Branca","provincia":"Piauí","pais":"BRASIL","latitud":-5.881159906266048,"longitud":-42.6396817189055,"ranking":0},
{"id":6975,"nombre":"Alegrete do Piaui","localidad":"Alegrete do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6931,"nombre":"Alto Longa","localidad":"Alto Longá","provincia":"Piauí","pais":"BRASIL","latitud":-5.251196322768097,"longitud":-42.20998782422822,"ranking":0},
{"id":6932,"nombre":"Altos","localidad":"Altos","provincia":"Piauí","pais":"BRASIL","latitud":-5.032806905401358,"longitud":-42.44183140826953,"ranking":0},
{"id":6933,"nombre":"Alvorada do Gurgueia ","localidad":"Alvorada do Gurguéia","provincia":"Piauí","pais":"BRASIL","latitud":-8.182484259815645,"longitud":-43.791639322380256,"ranking":0},
{"id":6934,"nombre":"Amarante","localidad":"Amarante","provincia":"Piauí","pais":"BRASIL","latitud":-6.246626056195946,"longitud":-42.84096663352592,"ranking":0},
{"id":10526,"nombre":"Angical do Piaui","localidad":"Angical do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-6.087331249284557,"longitud":-42.73663653377215,"ranking":0},
{"id":10548,"nombre":"Anisio de Abreu","localidad":"Anísio de Abreu","provincia":"Piauí","pais":"BRASIL","latitud":-9.185910839500753,"longitud":-43.04439956825485,"ranking":0},
{"id":10545,"nombre":"Avelino Lopes","localidad":"Avelino Lopes","provincia":"Piauí","pais":"BRASIL","latitud":-10.13556322319833,"longitud":-43.9540511372524,"ranking":0},
{"id":14567,"nombre":"Baixa Grande Do Ribeiro","localidad":"Baixa Grande do Ribeiro","provincia":"Piauí","pais":"BRASIL","latitud":-7.8665752,"longitud":-45.2099691,"ranking":0},
{"id":6936,"nombre":"Barras","localidad":"Barras","provincia":"Piauí","pais":"BRASIL","latitud":-4.242406293928544,"longitud":-42.29195815081372,"ranking":0},
{"id":14568,"nombre":"Barreiras do Piaui","localidad":"Barreiras do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-9.9239935,"longitud":-45.4765737,"ranking":0},
{"id":6937,"nombre":"Barro Duro","localidad":"Barro Duro","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6938,"nombre":"Batalha","localidad":"Batalha","provincia":"Piauí","pais":"BRASIL","latitud":-4.026493155953745,"longitud":-42.07885507587056,"ranking":0},
{"id":14569,"nombre":"Bertolinia","localidad":"Bertolínia","provincia":"Piauí","pais":"BRASIL","latitud":-7.637857999999999,"longitud":-43.954291,"ranking":0},
{"id":14935,"nombre":"Betania do Piaui","localidad":"Betânia do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4917,"nombre":"Bom Jesus","localidad":"Bom Jesus","provincia":"Piauí","pais":"BRASIL","latitud":-9.07927966438507,"longitud":-44.360169087914755,"ranking":0},
{"id":6939,"nombre":"Bom Principio","localidad":"Bom Princípio do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6940,"nombre":"Brasileira","localidad":"Brasileira","provincia":"Piauí","pais":"BRASIL","latitud":-4.13053566438028,"longitud":-41.78230083010087,"ranking":0},
{"id":7451,"nombre":"Buriti dos Lopes","localidad":"Buriti dos Lopes","provincia":"Piauí","pais":"BRASIL","latitud":-3.1734126357715957,"longitud":-41.86842031824632,"ranking":0},
{"id":6941,"nombre":"Cabeceiras do Piaui","localidad":"Cabeceiras do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-4.473308241437958,"longitud":-42.3097463206979,"ranking":0},
{"id":6942,"nombre":"Cajueiro da Praia","localidad":"Cajueiro da Praia","provincia":"Piauí","pais":"BRASIL","latitud":-2.9312915698094613,"longitud":-41.34499584022991,"ranking":0},
{"id":10541,"nombre":"Campo Grande do Piaui","localidad":"Campo Grande do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4925,"nombre":"Campo Maior","localidad":"Campo Maior","provincia":"Piauí","pais":"BRASIL","latitud":-4.8299274596005795,"longitud":-42.1702322308175,"ranking":0},
{"id":4923,"nombre":"Canto do Buriti","localidad":"Canto do Buriti","provincia":"Piauí","pais":"BRASIL","latitud":-8.110629487596498,"longitud":-42.94112114787891,"ranking":0},
{"id":6943,"nombre":"Capitao de Campos","localidad":"Capitão de Campos","provincia":"Piauí","pais":"BRASIL","latitud":-4.460967045294823,"longitud":-41.94326367055506,"ranking":0},
{"id":10546,"nombre":"Caracol","localidad":"Caracol","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14954,"nombre":"Caraubas do Piaui","localidad":"Caraúbas do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-3.4762228849665266,"longitud":-41.843570694401016,"ranking":0},
{"id":6944,"nombre":"Castelo","localidad":"Castelo do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-5.3221861622419455,"longitud":-41.566430197748794,"ranking":0},
{"id":6945,"nombre":"Cocal","localidad":"Cocal","provincia":"Piauí","pais":"BRASIL","latitud":-3.466465549218009,"longitud":-41.5667508068856,"ranking":0},
{"id":7321,"nombre":"Cocal de Telha","localidad":"Cocal de Telha","provincia":"Piauí","pais":"BRASIL","latitud":-4.556378288497721,"longitud":-41.97272781663592,"ranking":0},
{"id":6946,"nombre":"Colonia do Gurgueia","localidad":"Colônia do Gurguéia","provincia":"Piauí","pais":"BRASIL","latitud":-8.183227110554604,"longitud":-43.791627120169856,"ranking":0},
{"id":10535,"nombre":"Colonia do Piaui","localidad":"Colônia do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4929,"nombre":"Corrente","localidad":"Corrente","provincia":"Piauí","pais":"BRASIL","latitud":-10.43239,"longitud":-45.1627,"ranking":0},
{"id":6948,"nombre":"Cristino Castro","localidad":"Cristino Castro","provincia":"Piauí","pais":"BRASIL","latitud":-8.806580673139475,"longitud":-44.21578993810074,"ranking":0},
{"id":10544,"nombre":"Curimata","localidad":"Curimatá","provincia":"Piauí","pais":"BRASIL","latitud":-10.035597476369695,"longitud":-44.29999436141567,"ranking":0},
{"id":7452,"nombre":"Demerval Lobao","localidad":"Demerval Lobão","provincia":"Piauí","pais":"BRASIL","latitud":-5.357586565583148,"longitud":-42.67641630678221,"ranking":0},
{"id":10552,"nombre":"Dirceu Arcoverde","localidad":"Dirceu Arcoverde","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14916,"nombre":"Dom Expedito Lopes","localidad":"Dom Expedito Lopes","provincia":"Piauí","pais":"BRASIL","latitud":-6.9590543,"longitud":-41.6455315,"ranking":0},
{"id":6949,"nombre":"Elesbao Veloso","localidad":"Elesbão Veloso","provincia":"Piauí","pais":"BRASIL","latitud":-6.197659521027671,"longitud":-42.13165539720164,"ranking":0},
{"id":4938,"nombre":"Eliseu Martins","localidad":"Eliseu Martins","provincia":"Piauí","pais":"BRASIL","latitud":-8.104213816094553,"longitud":-43.66017308089349,"ranking":0},
{"id":6950,"nombre":"Esperantina","localidad":"Esperantina","provincia":"Piauí","pais":"BRASIL","latitud":-3.8900440311620277,"longitud":-42.23727072858001,"ranking":0},
{"id":6977,"nombre":"Francisco Macedo","localidad":"Francisco Macedo","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6952,"nombre":"Fronteiras","localidad":"Fronteiras","provincia":"Piauí","pais":"BRASIL","latitud":-7.088641997388679,"longitud":-40.616598793423385,"ranking":0},
{"id":10537,"nombre":"Geminiano","localidad":"Geminiano","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6954,"nombre":"Gilbues","localidad":"Gilbués","provincia":"Piauí","pais":"BRASIL","latitud":-9.842060231368992,"longitud":-45.347091063151886,"ranking":0},
{"id":14967,"nombre":"Ilha Grande","localidad":"Ilha Grande","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6978,"nombre":"Inhuma","localidad":"Inhuma","provincia":"Piauí","pais":"BRASIL","latitud":-6.668962592937067,"longitud":-41.7095303797459,"ranking":0},
{"id":6979,"nombre":"Ipiranga do Piaui","localidad":"Ipiranga do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-6.824807272736567,"longitud":-41.73792239848818,"ranking":0},
{"id":10529,"nombre":"Isaias Coelho","localidad":"Isaías Coelho","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10530,"nombre":"Itainopolis","localidad":"Itainópolis","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4957,"nombre":"Itaueira","localidad":"Itaueira","provincia":"Piauí","pais":"BRASIL","latitud":-7.602817072021732,"longitud":-43.02776121764214,"ranking":0},
{"id":6980,"nombre":"Jacobina do Piaui","localidad":"Jacobina do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6981,"nombre":"Jaicos","localidad":"Jaicós","provincia":"Piauí","pais":"BRASIL","latitud":-7.360431002929495,"longitud":-41.14554141157927,"ranking":0},
{"id":14934,"nombre":"Jerumenha","localidad":"Jerumenha","provincia":"Piauí","pais":"BRASIL","latitud":-7.088407904124951,"longitud":-43.50832833101571,"ranking":0},
{"id":8456,"nombre":"Joaquim Pire","localidad":"Joaquim Pires","provincia":"Piauí","pais":"BRASIL","latitud":-3.508440927378078,"longitud":-42.196382778273794,"ranking":0},
{"id":6957,"nombre":"Jose de Freitas","localidad":"José de Freitas","provincia":"Piauí","pais":"BRASIL","latitud":-4.759915757165091,"longitud":-42.575454530059616,"ranking":0},
{"id":14933,"nombre":"Julio Borges","localidad":"Júlio Borges","provincia":"Piauí","pais":"BRASIL","latitud":-10.330063304028904,"longitud":-44.23865719383374,"ranking":0},
{"id":10547,"nombre":"Jurema","localidad":"Jurema","provincia":"Piauí","pais":"BRASIL","latitud":-9.221813356823777,"longitud":-43.12903848402115,"ranking":0},
{"id":14572,"nombre":"Lagoinha do Piaui","localidad":"Lagoinha do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-5.8289026,"longitud":-42.6323559,"ranking":0},
{"id":6982,"nombre":"Lagoa do Piaui","localidad":"Lagoa do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-5.413385822067645,"longitud":-42.643936003774634,"ranking":0},
{"id":6958,"nombre":"Luis Correia","localidad":"Luís Correia","provincia":"Piauí","pais":"BRASIL","latitud":-2.882182814735977,"longitud":-41.66596758941123,"ranking":0},
{"id":6959,"nombre":"Luzilandia","localidad":"Luzilândia","provincia":"Piauí","pais":"BRASIL","latitud":-3.4706556434493523,"longitud":-42.36790341075767,"ranking":0},
{"id":14575,"nombre":"Manoel Emidio","localidad":"Manoel Emídio","provincia":"Piauí","pais":"BRASIL","latitud":-8.010296,"longitud":-43.872789,"ranking":0},
{"id":6960,"nombre":"Marcolandia","localidad":"Marcolândia","provincia":"Piauí","pais":"BRASIL","latitud":-7.441815127638569,"longitud":-40.66679771670391,"ranking":0},
{"id":14577,"nombre":"Matias Olimpio","localidad":"Matias Olímpio","provincia":"Piauí","pais":"BRASIL","latitud":-3.7151378,"longitud":-42.5543675,"ranking":0},
{"id":14944,"nombre":"Miguel Alves","localidad":"Miguel Alves","provincia":"Piauí","pais":"BRASIL","latitud":-4.1676560412928625,"longitud":-42.89264465047387,"ranking":0},
{"id":6962,"nombre":"Monsenhor Gil","localidad":"Monsenhor Gil","provincia":"Piauí","pais":"BRASIL","latitud":-5.560372213688459,"longitud":-42.61255787675716,"ranking":0},
{"id":4968,"nombre":"Monte Alegre do Piaui","localidad":"Monte Alegre do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-9.756354583449399,"longitud":-45.307854279294396,"ranking":0},
{"id":6963,"nombre":"Morro do Chapeu do Piaui","localidad":"Morro do Chapéu do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-3.7548059001309944,"longitud":-42.30569993323616,"ranking":0},
{"id":6998,"nombre":"Murici","localidad":"Murici dos Portelas","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4985,"nombre":"Nazare do Piaui","localidad":"Nazaré do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-6.9744139726530925,"longitud":-42.67243546649233,"ranking":0},
{"id":14885,"nombre":"Nazaria","localidad":"Nazária","provincia":"Piauí","pais":"BRASIL","latitud":-5.348653404014718,"longitud":-42.819703478647845,"ranking":0},
{"id":4986,"nombre":"Oeiras","localidad":"Oeiras","provincia":"Piauí","pais":"BRASIL","latitud":-7.008210008065282,"longitud":-42.12537118742407,"ranking":0},
{"id":10543,"nombre":"Parnagua","localidad":"Parnaguá","provincia":"Piauí","pais":"BRASIL","latitud":-10.224239442626518,"longitud":-44.637258021296056,"ranking":0},
{"id":10525,"nombre":"Passagem Franca do Piaui","localidad":"Passagem Franca do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6983,"nombre":"Patos do Piaui","localidad":"Patos do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-7.680420131493913,"longitud":-41.2450254017755,"ranking":0},
{"id":6984,"nombre":"Paulistana","localidad":"Paulistana","provincia":"Piauí","pais":"BRASIL","latitud":-8.135169437196250,"longitud":-41.14501921964224,"ranking":0},
{"id":7453,"nombre":"Pedro II","localidad":"Pedro II","provincia":"Piauí","pais":"BRASIL","latitud":-4.431252243318457,"longitud":-41.452088040024684,"ranking":0},
{"id":10542,"nombre":"Pio IX","localidad":"Pio IX","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6967,"nombre":"Piracuruca","localidad":"Piracuruca","provincia":"Piauí","pais":"BRASIL","latitud":-3.935133393395991,"longitud":-41.71084630510756,"ranking":0},
{"id":4995,"nombre":"Piripiri","localidad":"Piripiri","provincia":"Piauí","pais":"BRASIL","latitud":-4.272556713554501,"longitud":-41.771160003486436,"ranking":0},
{"id":6968,"nombre":"Redencao do Gurgueia","localidad":"Redenção do Gurguéia","provincia":"Piauí","pais":"BRASIL","latitud":-9.490035909029684,"longitud":-44.585739224031876,"ranking":0},
{"id":8457,"nombre":"Regeneracao ","localidad":"Regeneração","provincia":"Piauí","pais":"BRASIL","latitud":-6.2288844302453255,"longitud":-42.68650372685069,"ranking":0},
{"id":14884,"nombre":"Riacho Frio","localidad":"Riacho Frio","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14579,"nombre":"Ribeiro Goncalves","localidad":"Ribeiro Gonçalves","provincia":"Piauí","pais":"BRASIL","latitud":-7.560271,"longitud":-45.24301,"ranking":0},
{"id":10553,"nombre":"Rio Grande do Piaui","localidad":"Rio Grande do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5008,"nombre":"Santo Antonio de Lisboa","localidad":"Santo Antônio de Lisboa","provincia":"Piauí","pais":"BRASIL","latitud":-6.982744255194855,"longitud":-41.231200961027035,"ranking":0},
{"id":10549,"nombre":"Sao Braz do Piaui","localidad":"São Braz do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6969,"nombre":"Sao Goncalo do Gurgueia ","localidad":"São Gonçalo do Gurguéia","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14932,"nombre":"Sao Goncalo do Piaui","localidad":"São Gonçalo do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-6.000415989690167,"longitud":-42.704875262529356,"ranking":0},
{"id":6972,"nombre":"Sao Joao da Fronteira ","localidad":"São João da Fronteira","provincia":"Piauí","pais":"BRASIL","latitud":-3.9370586210091205,"longitud":-41.26025313565679,"ranking":0},
{"id":10554,"nombre":"Sao Joao do Piaui","localidad":"São João do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-8.360153061055227,"longitud":-42.25219363084468,"ranking":0},
{"id":10540,"nombre":"Sao Jose do Piaui","localidad":"São José do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10551,"nombre":"Sao Lourenco do Piaui","localidad":"São Lourenço do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-9.164845070091284,"longitud":-42.54798838130203,"ranking":0},
{"id":10527,"nombre":"Sao Pedro do Piaui","localidad":"São Pedro do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-5.928869365607426,"longitud":-42.72011263684331,"ranking":0},
{"id":10550,"nombre":"Sao Raimundo Nonato","localidad":"São Raimundo Nonato","provincia":"Piauí","pais":"BRASIL","latitud":-8.9964696,"longitud":-42.6824132,"ranking":0},
{"id":14580,"nombre":"Sebastiao Leal","localidad":"Sebastiao Leal","provincia":"Piauí","pais":"BRASIL","latitud":-7.5667649,"longitud":-44.0648466,"ranking":0},
{"id":6985,"nombre":"Simoes","localidad":"Simões","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10528,"nombre":"Simplicio Mendes","localidad":"Simplicio Mendes","provincia":"Piauí","pais":"BRASIL","latitud":-7.8571027099966315,"longitud":-41.90808836055188,"ranking":0},
{"id":6974,"nombre":"Uniao ","localidad":"União","provincia":"Piauí","pais":"BRASIL","latitud":-4.596959631126257,"longitud":-42.858009157841366,"ranking":0},
{"id":8458,"nombre":"Urucui","localidad":"Uruçuí","provincia":"Piauí","pais":"BRASIL","latitud":-7.2409634517462,"longitud":-44.55381519602231,"ranking":0},
{"id":5028,"nombre":"Valenca do Piaui","localidad":"Valença do Piauí","provincia":"Piauí","pais":"BRASIL","latitud":-6.405179019925467,"longitud":-41.73782269919217,"ranking":0},
{"id":9504,"nombre":"Acarape","localidad":"Acarape","provincia":"Ceará","pais":"BRASIL","latitud":-4.2249251719785335,"longitud":-38.70555452473873,"ranking":0},
{"id":5730,"nombre":"Acarau","localidad":"Acaraú","provincia":"Ceará","pais":"BRASIL","latitud":-2.885256977337362,"longitud":-40.117427821415454,"ranking":0},
{"id":6596,"nombre":"Acopiara","localidad":"Acopiara","provincia":"Ceará","pais":"BRASIL","latitud":-6.089516528471447,"longitud":-39.451126096496374,"ranking":0},
{"id":6597,"nombre":"Aiuaba","localidad":"Aiuaba","provincia":"Ceará","pais":"BRASIL","latitud":-6.570437998286699,"longitud":-40.12061996094913,"ranking":0},
{"id":6598,"nombre":"Alcantaras","localidad":"Alcântaras","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6599,"nombre":"Alto Santo","localidad":"Alto Santo","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5715,"nombre":"Amontada","localidad":"Amontada","provincia":"Ceará","pais":"BRASIL","latitud":-3.3618051737474244,"longitud":-39.830875246191994,"ranking":0},
{"id":6601,"nombre":"Antonina do Norte ","localidad":"Antonina do Norte","provincia":"Ceará","pais":"BRASIL","latitud":-6.771163249543704,"longitud":-39.98358672705673,"ranking":0},
{"id":6603,"nombre":"Apuiares","localidad":"Apuiarés","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6604,"nombre":"Aquiraz","localidad":"Aquiraz","provincia":"Ceará","pais":"BRASIL","latitud":-3.9058595730896264,"longitud":-38.38809893759219,"ranking":0},
{"id":6605,"nombre":"Aracati","localidad":"Aracati","provincia":"Ceará","pais":"BRASIL","latitud":-4.571246914006786,"longitud":-37.77434620587798,"ranking":0},
{"id":5676,"nombre":"Aracoiaba","localidad":"Aracoiaba","provincia":"Ceará","pais":"BRASIL","latitud":-4.370706949188774,"longitud":-38.80886150078057,"ranking":0},
{"id":6607,"nombre":"Ararenda","localidad":"Ararendá","provincia":"Ceará","pais":"BRASIL","latitud":-4.753151482651541,"longitud":-40.83241971723208,"ranking":0},
{"id":6608,"nombre":"Araripe","localidad":"Araripe","provincia":"Ceará","pais":"BRASIL","latitud":-7.2120348403775685,"longitud":-40.13549089231177,"ranking":0},
{"id":5702,"nombre":"Aratuba","localidad":"Aratuba","provincia":"Ceará","pais":"BRASIL","latitud":-4.418769614932092,"longitud":-39.04629828993058,"ranking":0},
{"id":6610,"nombre":"Arneiroz","localidad":"Arneiroz","provincia":"Ceará","pais":"BRASIL","latitud":-6.325056205696566,"longitud":-40.1603828470146,"ranking":0},
{"id":6612,"nombre":"Assare","localidad":"Assaré","provincia":"Ceará","pais":"BRASIL","latitud":-6.876669963379418,"longitud":-39.873995460504986,"ranking":0},
{"id":6613,"nombre":"Aurora","localidad":"Aurora","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6705,"nombre":"Acesso Aurora","localidad":"Aurora","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6614,"nombre":"Baixio","localidad":"Baixio","provincia":"Ceará","pais":"BRASIL","latitud":-6.7336893384253,"longitud":-38.722087978020824,"ranking":0},
{"id":6706,"nombre":"Acesso Baixio","localidad":"Baixio","provincia":"Ceará","pais":"BRASIL","latitud":-6.7306234475191244,"longitud":-38.71800344131422,"ranking":0},
{"id":5030,"nombre":"Riachao do Banabuiu","localidad":"Riachão do Banabuiú","provincia":"Ceará","pais":"BRASIL","latitud":-5.436954882991299,"longitud":-40.0014082708136,"ranking":0},
{"id":6615,"nombre":"Barbalha","localidad":"Barbalha","provincia":"Ceará","pais":"BRASIL","latitud":-7.309768439034839,"longitud":-39.30182085820271,"ranking":0},
{"id":5743,"nombre":"Barreira","localidad":"Barreira","provincia":"Ceará","pais":"BRASIL","latitud":-4.286382473393372,"longitud":-38.64010269025379,"ranking":0},
{"id":4920,"nombre":"Barro","localidad":"Barro","provincia":"Ceará","pais":"BRASIL","latitud":-7.179923828654948,"longitud":-38.78181893215874,"ranking":0},
{"id":6620,"nombre":"Barroquinha","localidad":"Barroquinha","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5679,"nombre":"Baturite","localidad":"Baturité","provincia":"Ceará","pais":"BRASIL","latitud":-4.3164783131858515,"longitud":-38.88236569127156,"ranking":0},
{"id":6622,"nombre":"Beberibe","localidad":"Beberibe","provincia":"Ceará","pais":"BRASIL","latitud":-4.179085267862157,"longitud":-38.13150751480256,"ranking":0},
{"id":5719,"nombre":"Bela Cruz","localidad":"Bela Cruz","provincia":"Ceará","pais":"BRASIL","latitud":-3.054848081979685,"longitud":-40.16647791659224,"ranking":0},
{"id":6626,"nombre":"Boa Viagem","localidad":"Boa Viagem","provincia":"Ceará","pais":"BRASIL","latitud":-5.1242395,"longitud":-39.7312976,"ranking":0},
{"id":4919,"nombre":"Brejo Santo","localidad":"Brejo Santo","provincia":"Ceará","pais":"BRASIL","latitud":-7.4792982161948,"longitud":-38.98097892557315,"ranking":0},
{"id":5722,"nombre":"Camocim","localidad":"Camocim","provincia":"Ceará","pais":"BRASIL","latitud":-2.9006274,"longitud":-40.8465014,"ranking":6},
{"id":6639,"nombre":"Campos Sales ","localidad":"Campos Sales","provincia":"Ceará","pais":"BRASIL","latitud":-7.078791809104991,"longitud":-40.3785202451067,"ranking":0},
{"id":4926,"nombre":"Caninde","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":-4.346849970407123,"longitud":-39.310469077541015,"ranking":0},
{"id":15099,"nombre":"Targinos","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":-4.6374629,"longitud":-39.2451908,"ranking":0},
{"id":15284,"nombre":"Caicara","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":-4.313238961629401,"longitud":-39.44653246795821,"ranking":0},
{"id":15285,"nombre":"Campos","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":-4.581020637855656,"longitud":-39.359051723476036,"ranking":0},
{"id":15286,"nombre":"Ubiracu","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15339,"nombre":"Feijao","localidad":"Canindé","provincia":"Ceará","pais":"BRASIL","latitud":-4.346896506946169,"longitud":-39.310445569311796,"ranking":0},
{"id":5682,"nombre":"Capistrano","localidad":"Capistrano","provincia":"Ceará","pais":"BRASIL","latitud":-4.469698418210122,"longitud":-38.90156774644312,"ranking":0},
{"id":7104,"nombre":"Caridade","localidad":"Caridade","provincia":"Ceará","pais":"BRASIL","latitud":-4.231801805110893,"longitud":-39.19505410466334,"ranking":0},
{"id":6641,"nombre":"Carire","localidad":"Cariré","provincia":"Ceará","pais":"BRASIL","latitud":-3.9467113310591593,"longitud":-40.4714254592849,"ranking":0},
{"id":6642,"nombre":"Carius","localidad":"Cariús","provincia":"Ceará","pais":"BRASIL","latitud":-6.534944052574296,"longitud":-39.4987987050105,"ranking":0},
{"id":6643,"nombre":"Carnaubal","localidad":"Carnaubal","provincia":"Ceará","pais":"BRASIL","latitud":-4.159481367797287,"longitud":-40.94086396175518,"ranking":0},
{"id":6644,"nombre":"Cascavel","localidad":"Cascavel","provincia":"Ceará","pais":"BRASIL","latitud":-4.130894704128944,"longitud":-38.23918213968176,"ranking":0},
{"id":6645,"nombre":"Catarina","localidad":"Catarina","provincia":"Ceará","pais":"BRASIL","latitud":-6.133598489590737,"longitud":-39.8787939301928,"ranking":0},
{"id":10623,"nombre":"Catunda","localidad":"Catunda","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5700,"nombre":"Caucaia","localidad":"Caucaia","provincia":"Ceará","pais":"BRASIL","latitud":-3.728940443607792,"longitud":-38.65707993514312,"ranking":0},
{"id":6682,"nombre":"Cedro","localidad":"Cedro","provincia":"Ceará","pais":"BRASIL","latitud":-6.604486190727927,"longitud":-39.057838847592194,"ranking":0},
{"id":6683,"nombre":"Chaval","localidad":"Chaval","provincia":"Ceará","pais":"BRASIL","latitud":-3.037672389298043,"longitud":-41.241836376102334,"ranking":0},
{"id":5668,"nombre":"Choro","localidad":"Choró","provincia":"Ceará","pais":"BRASIL","latitud":-4.84339823227468,"longitud":-39.139503771643476,"ranking":0},
{"id":5753,"nombre":"Chorozinho","localidad":"Chorozinho","provincia":"Ceará","pais":"BRASIL","latitud":-4.300163804502129,"longitud":-38.49822892770301,"ranking":0},
{"id":6708,"nombre":"Acesso Chorozinho ","localidad":"Chorozinho","provincia":"Ceará","pais":"BRASIL","latitud":-4.300179876456734,"longitud":-38.498226421238925,"ranking":0},
{"id":6686,"nombre":"Coreau","localidad":"Coreaú","provincia":"Ceará","pais":"BRASIL","latitud":-3.550951994380437,"longitud":-40.652550861243355,"ranking":0},
{"id":6692,"nombre":"Crateus","localidad":"Crateús","provincia":"Ceará","pais":"BRASIL","latitud":-5.188006741417091,"longitud":-40.67273285557642,"ranking":0},
{"id":6693,"nombre":"Crato","localidad":"Crato","provincia":"Ceará","pais":"BRASIL","latitud":-7.229169999999999,"longitud":-39.3976,"ranking":0},
{"id":5707,"nombre":"Croata","localidad":"Croatá","provincia":"Ceará","pais":"BRASIL","latitud":-4.416372107159908,"longitud":-40.90727399656619,"ranking":0},
{"id":15278,"nombre":"Sao Goncalo do Amarante","localidad":"Croatá","provincia":"Ceará","pais":"BRASIL","latitud":-3.6723483885962627,"longitud":-39.11773798235005,"ranking":0},
{"id":5720,"nombre":"Cruz","localidad":"Cruz","provincia":"Ceará","pais":"BRASIL","latitud":-2.915440552004851,"longitud":-40.17644128268306,"ranking":0},
{"id":6927,"nombre":"Deputado Irapuan Pinheiro ","localidad":"Deputado Irapuan Pinheiro","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6712,"nombre":"Eusebio","localidad":"Eusébio","provincia":"Ceará","pais":"BRASIL","latitud":-3.8900568398807978,"longitud":-38.44986278848826,"ranking":0},
{"id":6713,"nombre":"Farias Brito","localidad":"Farias Brito","provincia":"Ceará","pais":"BRASIL","latitud":-6.926277927766325,"longitud":-39.57356284683454,"ranking":0},
{"id":6715,"nombre":"Forquilha","localidad":"Forquilha","provincia":"Ceará","pais":"BRASIL","latitud":-3.7987948144806105,"longitud":-40.26382039568456,"ranking":0},
{"id":6716,"nombre":"Frecheirinha","localidad":"Frecheirinha","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6717,"nombre":"General Sampaio","localidad":"General Sampaio","provincia":"Ceará","pais":"BRASIL","latitud":-4.051439802530209,"longitud":-39.45061760906721,"ranking":0},
{"id":6718,"nombre":"Graca","localidad":"Graça","provincia":"Ceará","pais":"BRASIL","latitud":-4.047246811226224,"longitud":-40.752489728933966,"ranking":0},
{"id":5721,"nombre":"Granja","localidad":"Granja","provincia":"Ceará","pais":"BRASIL","latitud":-3.120351504074698,"longitud":-40.83546199892596,"ranking":0},
{"id":6742,"nombre":"Groairas","localidad":"Groaíras","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5673,"nombre":"Guaiuba","localidad":"Guaiúba","provincia":"Ceará","pais":"BRASIL","latitud":-4.040512598726981,"longitud":-38.636005373325254,"ranking":0},
{"id":6745,"nombre":"Guaraciaba do Norte","localidad":"Guaraciaba do Norte","provincia":"Ceará","pais":"BRASIL","latitud":-4.160902868742214,"longitud":-40.75299546302445,"ranking":0},
{"id":5703,"nombre":"Guaramiranga","localidad":"Guaramiranga","provincia":"Ceará","pais":"BRASIL","latitud":-4.261437363410289,"longitud":-38.93427371782056,"ranking":0},
{"id":6748,"nombre":"Hidrolandia","localidad":"Hidrolândia","provincia":"Ceará","pais":"BRASIL","latitud":-4.4081499710965755,"longitud":-40.40587643277168,"ranking":0},
{"id":5751,"nombre":"Horizonte","localidad":"Horizonte","provincia":"Ceará","pais":"BRASIL","latitud":-4.105401795858004,"longitud":-38.493738805040124,"ranking":0},
{"id":5756,"nombre":"Ibaretama","localidad":"Ibaretama","provincia":"Ceará","pais":"BRASIL","latitud":-4.814572704029474,"longitud":-38.82058298491673,"ranking":0},
{"id":6750,"nombre":"Ibiapina","localidad":"Ibiapina","provincia":"Ceará","pais":"BRASIL","latitud":-3.9209343125757554,"longitud":-40.88885490932582,"ranking":0},
{"id":5671,"nombre":"Ibicuitinga","localidad":"Ibicuitinga","provincia":"Ceará","pais":"BRASIL","latitud":-4.977324446758827,"longitud":-38.63782307135671,"ranking":0},
{"id":4949,"nombre":"Ico","localidad":"Icó","provincia":"Ceará","pais":"BRASIL","latitud":-6.406167505887901,"longitud":-38.864103175904546,"ranking":0},
{"id":4950,"nombre":"Iguatu","localidad":"Iguatu","provincia":"Ceará","pais":"BRASIL","latitud":-6.36316,"longitud":-39.301451,"ranking":0},
{"id":6751,"nombre":"Independencia","localidad":"Independência","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10625,"nombre":"Ipaporanga","localidad":"Ipaporanga","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6709,"nombre":"Acesso Ipaumiri","localidad":"Ipaumirim","provincia":"Ceará","pais":"BRASIL","latitud":-6.8089837266886,"longitud":-38.75527180696896,"ranking":0},
{"id":6753,"nombre":"Ipaumirim","localidad":"Ipaumirim","provincia":"Ceará","pais":"BRASIL","latitud":-6.78954141570103,"longitud":-38.71802634191882,"ranking":0},
{"id":4953,"nombre":"Ipu","localidad":"Ipu","provincia":"Ceará","pais":"BRASIL","latitud":-4.3186929694627665,"longitud":-40.70798447110409,"ranking":0},
{"id":6761,"nombre":"Ipueiras","localidad":"Ipueiras","provincia":"Ceará","pais":"BRASIL","latitud":-4.542147149773809,"longitud":-40.71442161001478,"ranking":0},
{"id":6763,"nombre":"Iracema","localidad":"Iracema","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6767,"nombre":"Iraucuba","localidad":"Irauçuba","provincia":"Ceará","pais":"BRASIL","latitud":-3.744347130965913,"longitud":-39.79300750470801,"ranking":0},
{"id":6768,"nombre":"Itaicaba","localidad":"Itaiçaba","provincia":"Ceará","pais":"BRASIL","latitud":-4.698218807368999,"longitud":-37.83152307681685,"ranking":0},
{"id":7105,"nombre":"Acesso Itaitinga ","localidad":"Itaitinga","provincia":"Ceará","pais":"BRASIL","latitud":-3.9708321755515703,"longitud":-38.53042161824383,"ranking":0},
{"id":6769,"nombre":"Itapage","localidad":"Itapagé","provincia":"Ceará","pais":"BRASIL","latitud":-3.6901666,"longitud":-39.5854192,"ranking":0},
{"id":5714,"nombre":"Itapipoca","localidad":"Itapipoca","provincia":"Ceará","pais":"BRASIL","latitud":-3.4976665633808683,"longitud":-39.57754723602274,"ranking":0},
{"id":5683,"nombre":"Itapiuna","localidad":"Itapiúna","provincia":"Ceará","pais":"BRASIL","latitud":-4.553158828287584,"longitud":-38.91583229003478,"ranking":0},
{"id":5729,"nombre":"Itarema","localidad":"Itarema","provincia":"Ceará","pais":"BRASIL","latitud":-2.9261206355443243,"longitud":-39.92583884967219,"ranking":0},
{"id":6711,"nombre":"Acesso Itatira","localidad":"Itatira","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10626,"nombre":"Itatira","localidad":"Itatira","provincia":"Ceará","pais":"BRASIL","latitud":-4.530178038696704,"longitud":-39.622211184620916,"ranking":0},
{"id":15283,"nombre":"Cachoeira","localidad":"Itatira","provincia":"Ceará","pais":"BRASIL","latitud":-4.693577708625494,"longitud":-39.44054959927919,"ranking":0},
{"id":15302,"nombre":"Barro Vermelho","localidad":"Itatira","provincia":"Ceará","pais":"BRASIL","latitud":-4.7372247580743565,"longitud":-39.578584956573394,"ranking":0},
{"id":6770,"nombre":"Jaguaribara","localidad":"Jaguaribara","provincia":"Ceará","pais":"BRASIL","latitud":-5.46053143897776,"longitud":-38.45899755189019,"ranking":0},
{"id":4958,"nombre":"Jaguaribe","localidad":"Jaguaribe","provincia":"Ceará","pais":"BRASIL","latitud":-5.884877512533614,"longitud":-38.62126145138711,"ranking":0},
{"id":15288,"nombre":"Nova Floresta","localidad":"Jaguaribe","provincia":"Ceará","pais":"BRASIL","latitud":-5.949083368620663,"longitud":-38.90519741741769,"ranking":0},
{"id":6776,"nombre":"Jardim","localidad":"Jardim","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6778,"nombre":"Jati","localidad":"Jati","provincia":"Ceará","pais":"BRASIL","latitud":-7.690525737468339,"longitud":-39.009320397362174,"ranking":0},
{"id":7360,"nombre":"Jijoca de Jericoacoara","localidad":"Jijoca de Jericoacoara","provincia":"Ceará","pais":"BRASIL","latitud":-2.7956053,"longitud":-40.5143511,"ranking":44},
{"id":6782,"nombre":"Jucas","localidad":"Jucás","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6787,"nombre":"Lavras da Mangabeira ","localidad":"Lavras da Mangabeira","provincia":"Ceará","pais":"BRASIL","latitud":-6.7523151245914805,"longitud":-38.96326431165615,"ranking":0},
{"id":6791,"nombre":"Limoeiro Do Norte","localidad":"Limoeiro do Norte","provincia":"Ceará","pais":"BRASIL","latitud":-5.151499817975372,"longitud":-38.10041652192253,"ranking":0},
{"id":6796,"nombre":"Madalena","localidad":"Madalena","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15303,"nombre":"Olho d Agua","localidad":"Maracanaú","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5692,"nombre":"Maranguape","localidad":"Maranguape","provincia":"Ceará","pais":"BRASIL","latitud":-3.8941641974194354,"longitud":-38.681766725798276,"ranking":0},
{"id":5718,"nombre":"Marco","localidad":"Marco","provincia":"Ceará","pais":"BRASIL","latitud":-3.1235901516394424,"longitud":-40.14662156964481,"ranking":0},
{"id":6802,"nombre":"Martinopole","localidad":"Martinópole","provincia":"Ceará","pais":"BRASIL","latitud":-3.22630301960914,"longitud":-40.69196928844365,"ranking":0},
{"id":6804,"nombre":"Massape","localidad":"Massapê","provincia":"Ceará","pais":"BRASIL","latitud":-3.52605660484929,"longitud":-40.34204304846886,"ranking":0},
{"id":6710,"nombre":"Acesso Mauriti ","localidad":"Mauriti","provincia":"Ceará","pais":"BRASIL","latitud":-7.3872024158617515,"longitud":-38.781447557717854,"ranking":0},
{"id":7106,"nombre":"Mauriti","localidad":"Mauriti","provincia":"Ceará","pais":"BRASIL","latitud":-7.3875467475934515,"longitud":-7.3875467475934515,"ranking":0},
{"id":6815,"nombre":"Milagres","localidad":"Milagres","provincia":"Ceará","pais":"BRASIL","latitud":-7.311659685439672,"longitud":-38.93769033401967,"ranking":0},
{"id":5748,"nombre":"Milha","localidad":"Milhã","provincia":"Ceará","pais":"BRASIL","latitud":-5.676057176786282,"longitud":-39.19517277812737,"ranking":0},
{"id":5728,"nombre":"Miraima","localidad":"Miraíma","provincia":"Ceará","pais":"BRASIL","latitud":-3.565257059663751,"longitud":-39.97033371962142,"ranking":0},
{"id":6822,"nombre":"Missao Velha","localidad":"Missão Velha","provincia":"Ceará","pais":"BRASIL","latitud":-7.249474694766981,"longitud":-39.141541174048264,"ranking":0},
{"id":5747,"nombre":"Mombaca ","localidad":"Mombaça","provincia":"Ceará","pais":"BRASIL","latitud":-5.744205154712419,"longitud":-39.62483244507165,"ranking":0},
{"id":6825,"nombre":"Monsenhor Tabosa","localidad":"Monsenhor Tabosa","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5672,"nombre":"Morada Nova","localidad":"Morada Nova","provincia":"Ceará","pais":"BRASIL","latitud":-5.113357070167255,"longitud":-38.36612674878568,"ranking":0},
{"id":6826,"nombre":"Moraujo","localidad":"Moraújo","provincia":"Ceará","pais":"BRASIL","latitud":-3.466969052308904,"longitud":-40.67851711073362,"ranking":0},
{"id":5717,"nombre":"Morrinhos","localidad":"Morrinhos","provincia":"Ceará","pais":"BRASIL","latitud":-3.226253688456272,"longitud":-40.12677410989712,"ranking":0},
{"id":6828,"nombre":"Mucambo","localidad":"Mucambo","provincia":"Ceará","pais":"BRASIL","latitud":-3.904398994447796,"longitud":-40.74564837885581,"ranking":0},
{"id":5701,"nombre":"Mulungu","localidad":"Mulungu","provincia":"Ceará","pais":"BRASIL","latitud":-4.296116059542488,"longitud":-38.984142169575925,"ranking":0},
{"id":6830,"nombre":"Nova Olinda","localidad":"Nova Olinda","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6834,"nombre":"Nova Russas","localidad":"Nova Russas","provincia":"Ceará","pais":"BRASIL","latitud":-4.7065317241899285,"longitud":-40.55596790845415,"ranking":0},
{"id":6840,"nombre":"Novo Oriente","localidad":"Novo Oriente","provincia":"Ceará","pais":"BRASIL","latitud":-5.532220936516671,"longitud":-40.777560040647906,"ranking":0},
{"id":5754,"nombre":"Ocara","localidad":"Ocara","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6841,"nombre":"Oros","localidad":"Orós","provincia":"Ceará","pais":"BRASIL","latitud":-6.249201891446846,"longitud":-38.91429490871943,"ranking":0},
{"id":5752,"nombre":"Pacajus","localidad":"Pacajus","provincia":"Ceará","pais":"BRASIL","latitud":-4.187770693551734,"longitud":-38.46549313066636,"ranking":0},
{"id":5670,"nombre":"Pacatuba","localidad":"Pacatuba","provincia":"Ceará","pais":"BRASIL","latitud":-3.9847240230308767,"longitud":-38.617331923615374,"ranking":0},
{"id":5706,"nombre":"Pacoti","localidad":"Pacoti","provincia":"Ceará","pais":"BRASIL","latitud":-4.224747760806933,"longitud":-38.920516663099285,"ranking":0},
{"id":6842,"nombre":"Pacuja","localidad":"Pacujá","provincia":"Ceará","pais":"BRASIL","latitud":-3.9792200289470823,"longitud":-40.70022635303031,"ranking":0},
{"id":5699,"nombre":"Palmacia","localidad":"Palmácia","provincia":"Ceará","pais":"BRASIL","latitud":-4.148845461921482,"longitud":-38.844685449644004,"ranking":0},
{"id":5736,"nombre":"Paracuru","localidad":"Paracuru","provincia":"Ceará","pais":"BRASIL","latitud":-3.417173267144222,"longitud":-39.03085635951198,"ranking":0},
{"id":5737,"nombre":"Paraipaba","localidad":"Paraipaba","provincia":"Ceará","pais":"BRASIL","latitud":-3.436401568087595,"longitud":-39.1452277344027,"ranking":0},
{"id":6843,"nombre":"Parambu","localidad":"Parambu","provincia":"Ceará","pais":"BRASIL","latitud":-6.214287089535375,"longitud":-40.686572505210734,"ranking":0},
{"id":10627,"nombre":"Paramoti","localidad":"Paramoti","provincia":"Ceará","pais":"BRASIL","latitud":-4.097057900989874,"longitud":-39.239118275082134,"ranking":0},
{"id":5749,"nombre":"Pedra Branca","localidad":"Pedra Branca","provincia":"Ceará","pais":"BRASIL","latitud":-5.453742911634083,"longitud":-39.71542269389517,"ranking":0},
{"id":6864,"nombre":"Penaforte","localidad":"Penaforte","provincia":"Ceará","pais":"BRASIL","latitud":-7.82700345124826,"longitud":-39.08756087822465,"ranking":0},
{"id":6865,"nombre":"Pentecoste","localidad":"Pentecoste","provincia":"Ceará","pais":"BRASIL","latitud":-3.796634733776754,"longitud":-39.26800683506363,"ranking":0},
{"id":6866,"nombre":"Pereiro","localidad":"Pereiro","provincia":"Ceará","pais":"BRASIL","latitud":-6.048323227105539,"longitud":-38.46127936257647,"ranking":0},
{"id":6867,"nombre":"Pindoretama","localidad":"Pindoretama","provincia":"Ceará","pais":"BRASIL","latitud":-4.023919488777356,"longitud":-38.3158249185343,"ranking":0},
{"id":6928,"nombre":"Piquet Carneiro","localidad":"Piquet Carneiro","provincia":"Ceará","pais":"BRASIL","latitud":-5.80363034080147,"longitud":-39.41708499931025,"ranking":0},
{"id":6868,"nombre":"Pires Ferrei","localidad":"Pires Ferreira","provincia":"Ceará","pais":"BRASIL","latitud":-4.244491696496694,"longitud":-40.645086328641035,"ranking":0},
{"id":10624,"nombre":"Poranga","localidad":"Poranga","provincia":"Ceará","pais":"BRASIL","latitud":-4.7446407415377605,"longitud":-40.9267122052207,"ranking":0},
{"id":6870,"nombre":"Porteiras","localidad":"Porteiras","provincia":"Ceará","pais":"BRASIL","latitud":-7.533302455905497,"longitud":-39.11481224743503,"ranking":0},
{"id":6871,"nombre":"Potengi","localidad":"Potengi","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6875,"nombre":"Quiterianopo","localidad":"Quiterianópolis","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5686,"nombre":"Quixada","localidad":"Quixadá","provincia":"Ceará","pais":"BRASIL","latitud":-4.973089434773578,"longitud":-39.01623819804679,"ranking":0},
{"id":14896,"nombre":"Quixelo","localidad":"Quixelô","provincia":"Ceará","pais":"BRASIL","latitud":-6.2510694145773815,"longitud":-39.20013141818877,"ranking":0},
{"id":5744,"nombre":"Quixeramobim","localidad":"Quixeramobim","provincia":"Ceará","pais":"BRASIL","latitud":-5.190858170950772,"longitud":-39.29561750964285,"ranking":0},
{"id":5675,"nombre":"Redencao ","localidad":"Redenção","provincia":"Ceará","pais":"BRASIL","latitud":-4.225105896015369,"longitud":-38.72800894089291,"ranking":0},
{"id":6878,"nombre":"Reriutaba","localidad":"Reriutaba","provincia":"Ceará","pais":"BRASIL","latitud":-4.144124251545724,"longitud":-40.58346408613346,"ranking":0},
{"id":5003,"nombre":"Russas","localidad":"Russas","provincia":"Ceará","pais":"BRASIL","latitud":-4.949333948506904,"longitud":-37.98937837839895,"ranking":0},
{"id":6879,"nombre":"Saboeiro","localidad":"Saboeiro","provincia":"Ceará","pais":"BRASIL","latitud":-6.540161149786099,"longitud":-39.908306344693635,"ranking":0},
{"id":6880,"nombre":"Salitre","localidad":"Salitre","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6881,"nombre":"Santana do Acarau","localidad":"Santana do Acaraú","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6908,"nombre":"Santa Quiteria","localidad":"Santa Quitéria","provincia":"Ceará","pais":"BRASIL","latitud":-4.3249859,"longitud":-40.1498979,"ranking":0},
{"id":6882,"nombre":"Sao Benedito","localidad":"São Benedito","provincia":"Ceará","pais":"BRASIL","latitud":-4.046529318802509,"longitud":-40.86341107074645,"ranking":0},
{"id":7362,"nombre":"Sao Goncalo do Amarante ","localidad":"São Gonçalo do Amarante","provincia":"Ceará","pais":"BRASIL","latitud":-3.611519993267666,"longitud":-38.96880178812714,"ranking":0},
{"id":5709,"nombre":"Sao Luis do Curu ","localidad":"São Luís do Curu","provincia":"Ceará","pais":"BRASIL","latitud":-3.6739167897690814,"longitud":-39.24074061602332,"ranking":0},
{"id":5745,"nombre":"Senador Pompeu","localidad":"Senador Pompeu","provincia":"Ceará","pais":"BRASIL","latitud":-5.5784176161099515,"longitud":-39.36725318530295,"ranking":0},
{"id":6905,"nombre":"Senador Sa","localidad":"Senador Sá","provincia":"Ceará","pais":"BRASIL","latitud":-3.3516544881032604,"longitud":-40.46502775407671,"ranking":0},
{"id":5746,"nombre":"Solonopole ","localidad":"Solonópole","provincia":"Ceará","pais":"BRASIL","latitud":-5.734601640119601,"longitud":-39.00708233641694,"ranking":0},
{"id":6912,"nombre":"Tamboril","localidad":"Tamboril","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5016,"nombre":"Taua","localidad":"Tauá","provincia":"Ceará","pais":"BRASIL","latitud":-6.012651422736804,"longitud":-40.29376949235296,"ranking":0},
{"id":6914,"nombre":"Tejucuoca","localidad":"Tejuçuoca","provincia":"Ceará","pais":"BRASIL","latitud":-3.9898807585387073,"longitud":-39.57971800006762,"ranking":0},
{"id":5019,"nombre":"Tiangua","localidad":"Tianguá","provincia":"Ceará","pais":"BRASIL","latitud":-3.7153338034073915,"longitud":-40.988603948311926,"ranking":0},
{"id":5739,"nombre":"Trairi","localidad":"Trairi","provincia":"Ceará","pais":"BRASIL","latitud":-3.2783006793501275,"longitud":-39.264496701510446,"ranking":0},
{"id":5713,"nombre":"Tururu","localidad":"Tururu","provincia":"Ceará","pais":"BRASIL","latitud":-3.599312859208125,"longitud":-39.436025770408804,"ranking":0},
{"id":6917,"nombre":"Ubajara","localidad":"Ubajara","provincia":"Ceará","pais":"BRASIL","latitud":-3.85232736504079,"longitud":-40.92532900290061,"ranking":0},
{"id":5710,"nombre":"Umirim","localidad":"Umirim","provincia":"Ceará","pais":"BRASIL","latitud":-3.674667894813648,"longitud":-39.33614798817056,"ranking":0},
{"id":5726,"nombre":"Uruburetama","localidad":"Uruburetama","provincia":"Ceará","pais":"BRASIL","latitud":-3.6200802877709406,"longitud":-39.50884447249074,"ranking":0},
{"id":6920,"nombre":"Uruoca","localidad":"Uruoca","provincia":"Ceará","pais":"BRASIL","latitud":-3.316641088824951,"longitud":-40.55906031655682,"ranking":0},
{"id":6921,"nombre":"Varjota","localidad":"Varjota","provincia":"Ceará","pais":"BRASIL","latitud":-4.195213048477539,"longitud":-40.4751292770592,"ranking":0},
{"id":6922,"nombre":"Varzea Alegre","localidad":"Várzea Alegre","provincia":"Ceará","pais":"BRASIL","latitud":-6.795630925919652,"longitud":-6.795630925919652,"ranking":0},
{"id":6923,"nombre":"Vicosa do Ceara","localidad":"Viçosa do Ceará","provincia":"Ceará","pais":"BRASIL","latitud":-3.576546,"longitud":-41.0940451,"ranking":0},
{"id":6766,"nombre":"Angicos","localidad":"Angicos","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.666637054789075,"longitud":-36.60075537874658,"ranking":0},
{"id":9015,"nombre":"Apodi","localidad":"Apodi","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9017,"nombre":"Areia Branca","localidad":"Areia Branca","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-4.9524130033492355,"longitud":-37.12791043972199,"ranking":0},
{"id":9042,"nombre":"Caicara do Norte","localidad":"Caiçara do Norte","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.062706323502375,"longitud":-36.04999174532497,"ranking":0},
{"id":4924,"nombre":"Caico","localidad":"Caicó","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.459421424333948,"longitud":-37.098279786602234,"ranking":0},
{"id":6793,"nombre":"Caraubas","localidad":"Caraúbas","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.780803799243028,"longitud":-37.56019281272658,"ranking":0},
{"id":9024,"nombre":"Carnaubais","localidad":"Carnaubais","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.3384607611864565,"longitud":-36.829798082671196,"ranking":0},
{"id":6764,"nombre":"Currais Novos","localidad":"Currais Novos","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.26045775073613,"longitud":-36.519415400407595,"ranking":0},
{"id":6799,"nombre":"Parnamirim","localidad":"Parnamirim","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9025,"nombre":"Espirito Santo","localidad":"Espírito Santo","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.338397822681826,"longitud":-35.314488731517564,"ranking":0},
{"id":9026,"nombre":"Fernando Pedroza","localidad":"Fernando Pedroza","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.69437516121458,"longitud":-36.529050905493655,"ranking":0},
{"id":6810,"nombre":"Goianinha","localidad":"Goianinha","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.2693625,"longitud":-35.2102514,"ranking":0},
{"id":9028,"nombre":"Governador Dix-Sept Rosado","localidad":"Governador Dix-Sept Rosado","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.456670319742414,"longitud":-37.51979815800957,"ranking":0},
{"id":9029,"nombre":"Janduis","localidad":"Janduís","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.016893209045412,"longitud":-37.406151344548455,"ranking":0},
{"id":9031,"nombre":"Jucurutu ","localidad":"Jucurutu","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.032779319324356,"longitud":-37.01725693123009,"ranking":0},
{"id":9032,"nombre":"Lajes","localidad":"Lajes","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.6977967546357196,"longitud":-36.243156045217866,"ranking":0},
{"id":6797,"nombre":"Macaiba","localidad":"Macaíba","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.859426199604996,"longitud":-35.34932088149361,"ranking":0},
{"id":9034,"nombre":"Messias Targino","localidad":"Messias Targino","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.0754869673907015,"longitud":-37.51739695062033,"ranking":0},
{"id":9036,"nombre":"Nova Cruz","localidad":"Nova Cruz","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.483247760922816,"longitud":-35.43245330326434,"ranking":0},
{"id":14761,"nombre":"Olho d'Água do Borges","localidad":"Olho-d'Água do Borges","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.954962999999999,"longitud":-37.7052044,"ranking":0},
{"id":9038,"nombre":"Parau","localidad":"Paraú","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.773727682680312,"longitud":-37.10501198811929,"ranking":0},
{"id":15115,"nombre":"Parelhas","localidad":"Parelhas","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.694399552838344,"longitud":-36.65928658306487,"ranking":0},
{"id":9040,"nombre":"Passa e Fica","localidad":"Passa e Fica","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.436014830538859,"longitud":-35.641881978925966,"ranking":0},
{"id":6803,"nombre":"Patu","localidad":"Patu","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.091290331493721,"longitud":-37.64035245388825,"ranking":0},
{"id":9037,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.841587642055007,"longitud":-35.691442509052926,"ranking":0},
{"id":12415,"nombre":"Pau dos Ferros","localidad":"Pau dos Ferros","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.1215297,"longitud":-38.2053969,"ranking":0},
{"id":9039,"nombre":"Riachuelo","localidad":"Riachuelo","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.811033194537401,"longitud":-35.82222568820634,"ranking":0},
{"id":9035,"nombre":"Santana do Matos","localidad":"Santana do Matos","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.959314052495728,"longitud":-36.65515881426655,"ranking":0},
{"id":9033,"nombre":"Santo Antonio","localidad":"Santo Antônio","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.313491820129283,"longitud":-35.48055481337772,"ranking":0},
{"id":9217,"nombre":"São Rafael","localidad":"São Rafael","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.803688865722858,"longitud":-36.883346490905176,"ranking":0},
{"id":9023,"nombre":"Tangara","localidad":"Tangará","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.1993554295996995,"longitud":-35.80141692971764,"ranking":0},
{"id":9021,"nombre":"Triunfo Potiguar","localidad":"Triunfo Potiguar","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.8695815364520865,"longitud":-37.18156992651466,"ranking":0},
{"id":9020,"nombre":"Varzea","localidad":"Várzea","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.333482387689466,"longitud":-35.372974070453374,"ranking":0},
{"id":6637,"nombre":"Agua Branca","localidad":"Água Branca","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6638,"nombre":"Aguiar","localidad":"Aguiar","provincia":"Paraíba","pais":"BRASIL","latitud":-7.093260957260238,"longitud":-38.17137960265451,"ranking":0},
{"id":4905,"nombre":"Alagoa Grande","localidad":"Alagoa Grande","provincia":"Paraíba","pais":"BRASIL","latitud":-7.0475521114942525,"longitud":-35.62748779016961,"ranking":0},
{"id":9000,"nombre":"Alagoinha","localidad":"Alagoinha","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14803,"nombre":"Sao Joao do Rio do Peixe","localidad":"São João do Rio do Peixe","provincia":"Paraíba","pais":"BRASIL","latitud":-6.724249599999999,"longitud":-38.4535034,"ranking":0},
{"id":6640,"nombre":"Aparecida ","localidad":"Aparecida","provincia":"Paraíba","pais":"BRASIL","latitud":-6.786303652149225,"longitud":-38.07336831037521,"ranking":0},
{"id":9001,"nombre":"Areia","localidad":"Areia","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10568,"nombre":"Assuncao","localidad":"Assunção","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6647,"nombre":"Bananeiras","localidad":"Bananeiras","provincia":"Paraíba","pais":"BRASIL","latitud":-6.7545851497564176,"longitud":-35.632497522736784,"ranking":0},
{"id":6648,"nombre":"Bayeux","localidad":"Bayeux","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9002,"nombre":"Belem","localidad":"Belém","provincia":"Paraíba","pais":"BRASIL","latitud":-6.701493471083063,"longitud":-35.53709292617953,"ranking":0},
{"id":6649,"nombre":"Boa Ventura","localidad":"Boa Ventura","provincia":"Paraíba","pais":"BRASIL","latitud":-7.421150053372292,"longitud":-38.2164436378528,"ranking":0},
{"id":7430,"nombre":"Bonito de Santa Fe","localidad":"Bonito de Santa Fé","provincia":"Paraíba","pais":"BRASIL","latitud":-7.3151624207688295,"longitud":-38.51339782043653,"ranking":0},
{"id":6661,"nombre":"Igaracy","localidad":"Igaracy","provincia":"Paraíba","pais":"BRASIL","latitud":-7.180329240175963,"longitud":-38.14937890883026,"ranking":0},
{"id":6650,"nombre":"Brejo do Cruz","localidad":"Brejo do Cruz","provincia":"Paraíba","pais":"BRASIL","latitud":-6.350191375651437,"longitud":-37.49402059864978,"ranking":0},
{"id":15053,"nombre":"Caapora","localidad":"Caaporã","provincia":"Paraíba","pais":"BRASIL","latitud":-7.511621277456147,"longitud":-34.93224755926765,"ranking":0},
{"id":15055,"nombre":"Cabedelo","localidad":"Cabedelo","provincia":"Paraíba","pais":"BRASIL","latitud":-7.036181074529157,"longitud":-34.84404800107529,"ranking":0},
{"id":6651,"nombre":"Cajazeiras","localidad":"Cajazeiras","provincia":"Paraíba","pais":"BRASIL","latitud":-6.887309999999999,"longitud":-38.554015,"ranking":0},
{"id":6652,"nombre":"Cajazeirinha","localidad":"Cajazeirinhas","provincia":"Paraíba","pais":"BRASIL","latitud":-6.9615475093104,"longitud":-37.79973713379469,"ranking":0},
{"id":6653,"nombre":"Catingueira","localidad":"Catingueira","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6654,"nombre":"Catole do Rocha","localidad":"Catolé do Rocha","provincia":"Paraíba","pais":"BRASIL","latitud":-6.355907004914006,"longitud":-37.746148287510856,"ranking":0},
{"id":6655,"nombre":"Conceicao","localidad":"Conceição","provincia":"Paraíba","pais":"BRASIL","latitud":-7.559062944435747,"longitud":-38.50918724784726,"ranking":0},
{"id":6656,"nombre":"Condado","localidad":"Condado","provincia":"Paraíba","pais":"BRASIL","latitud":-6.9105524103378855,"longitud":-37.600599281715525,"ranking":0},
{"id":14953,"nombre":"Congo","localidad":"Congo","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6657,"nombre":"Coremas","localidad":"Coremas","provincia":"Paraíba","pais":"BRASIL","latitud":-7.0182889035034455,"longitud":-37.9454321413027,"ranking":0},
{"id":10514,"nombre":"Cruz do Espirito Santo","localidad":"Cruz do Espírito Santo","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6755,"nombre":"Mamanguape","localidad":"Cuité de Mamanguape","provincia":"Paraíba","pais":"BRASIL","latitud":-6.836470260090361,"longitud":-35.12301998113546,"ranking":0},
{"id":10567,"nombre":"Desterro","localidad":"Desterro","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6658,"nombre":"Diamante","localidad":"Diamante","provincia":"Paraíba","pais":"BRASIL","latitud":-7.426874794913069,"longitud":-38.26578503011132,"ranking":0},
{"id":9003,"nombre":"Esperanca","localidad":"Esperança","provincia":"Paraíba","pais":"BRASIL","latitud":-7.020188903945893,"longitud":-35.8568725043354,"ranking":0},
{"id":6660,"nombre":"Ibiara","localidad":"Ibiara","provincia":"Paraíba","pais":"BRASIL","latitud":-7.497429056659106,"longitud":-38.40379139606546,"ranking":0},
{"id":6662,"nombre":"Imaculada","localidad":"Imaculada","provincia":"Paraíba","pais":"BRASIL","latitud":-7.390317396605556,"longitud":-37.51101127711935,"ranking":0},
{"id":6754,"nombre":"Inga","localidad":"Ingá","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4947,"nombre":"Itabaiana","localidad":"Itabaiana","provincia":"Paraíba","pais":"BRASIL","latitud":-7.326160183257462,"longitud":-35.33200071015713,"ranking":0},
{"id":6663,"nombre":"Itaporanga","localidad":"Itaporanga","provincia":"Paraíba","pais":"BRASIL","latitud":-7.304055143806269,"longitud":-38.15563016582309,"ranking":0},
{"id":6664,"nombre":"Jerico","localidad":"Jericó","provincia":"Paraíba","pais":"BRASIL","latitud":-6.565667580476129,"longitud":-37.795846670846714,"ranking":0},
{"id":6665,"nombre":"Juazeirinho","localidad":"Juazeirinho","provincia":"Paraíba","pais":"BRASIL","latitud":-7.069152101718895,"longitud":-36.57883381975293,"ranking":0},
{"id":7433,"nombre":"Junco do Serido","localidad":"Junco do Seridó","provincia":"Paraíba","pais":"BRASIL","latitud":-6.991730344535883,"longitud":-36.714851115398545,"ranking":0},
{"id":10558,"nombre":"Juripiranga","localidad":"Juripiranga","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6666,"nombre":"Juru","localidad":"Juru","provincia":"Paraíba","pais":"BRASIL","latitud":-7.538108724462383,"longitud":-37.8185247907252,"ranking":0},
{"id":6667,"nombre":"Malta","localidad":"Malta","provincia":"Paraíba","pais":"BRASIL","latitud":-6.9064793920081975,"longitud":-37.52174808676365,"ranking":0},
{"id":9006,"nombre":"Mamanguape","localidad":"Mamanguape","provincia":"Paraíba","pais":"BRASIL","latitud":-6.836482940466766,"longitud":-35.123024748450774,"ranking":0},
{"id":6668,"nombre":"Manaira","localidad":"Manaíra","provincia":"Paraíba","pais":"BRASIL","latitud":-7.706221851670025,"longitud":-38.15468678116145,"ranking":0},
{"id":6669,"nombre":"Mari","localidad":"Mari","provincia":"Paraíba","pais":"BRASIL","latitud":-7.053938170999576,"longitud":-35.318181289393024,"ranking":0},
{"id":6670,"nombre":"Marizopolis","localidad":"Marizópolis","provincia":"Paraíba","pais":"BRASIL","latitud":-6.844541007215964,"longitud":-38.35141713117682,"ranking":0},
{"id":10556,"nombre":"Massaranduba","localidad":"Massaranduba","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6685,"nombre":"Matureia","localidad":"Maturéia","provincia":"Paraíba","pais":"BRASIL","latitud":-7.264947652466773,"longitud":-37.3447979986568,"ranking":0},
{"id":6757,"nombre":"Mogeiro","localidad":"Mogeiro","provincia":"Paraíba","pais":"BRASIL","latitud":-7.302505299796169,"longitud":-35.47813843380693,"ranking":0},
{"id":6687,"nombre":"Monte Horebe","localidad":"Monte Horebe","provincia":"Paraíba","pais":"BRASIL","latitud":-7.214037409120951,"longitud":-38.58399901598955,"ranking":0},
{"id":6758,"nombre":"Monteiro","localidad":"Monteiro","provincia":"Paraíba","pais":"BRASIL","latitud":-7.891777544197431,"longitud":-37.123940339204864,"ranking":0},
{"id":14767,"nombre":"Olho d'Agua","localidad":"Olho d'Água","provincia":"Paraíba","pais":"BRASIL","latitud":-7.2278615,"longitud":-37.7505796,"ranking":0},
{"id":6688,"nombre":"Paulista","localidad":"Paulista","provincia":"Paraíba","pais":"BRASIL","latitud":-6.598691195895654,"longitud":-37.618439172716826,"ranking":0},
{"id":10565,"nombre":"Pedras de Fogo","localidad":"Pedras de Fogo","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6689,"nombre":"Pianco","localidad":"Piancó","provincia":"Paraíba","pais":"BRASIL","latitud":-7.199123819491163,"longitud":-37.925904360556245,"ranking":0},
{"id":6690,"nombre":"Pirpirituba","localidad":"Pirpirituba","provincia":"Paraíba","pais":"BRASIL","latitud":-6.781115040962686,"longitud":-35.496342475901606,"ranking":0},
{"id":6634,"nombre":"Pombal","localidad":"Pombal","provincia":"Paraíba","pais":"BRASIL","latitud":-6.765905673851171,"longitud":-37.79449616448975,"ranking":0},
{"id":7434,"nombre":"Princesa Isabel","localidad":"Princesa Isabel","provincia":"Paraíba","pais":"BRASIL","latitud":-7.7379592775732515,"longitud":-37.99281089181968,"ranking":0},
{"id":10555,"nombre":"Queimadas","localidad":"Queimadas","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9007,"nombre":"Remigio","localidad":"Remígio","provincia":"Paraíba","pais":"BRASIL","latitud":-6.964971552679248,"longitud":-35.793494917471406,"ranking":0},
{"id":7435,"nombre":"Riachao","localidad":"Riachão","provincia":"Paraíba","pais":"BRASIL","latitud":-6.541563825750794,"longitud":-35.660013427221266,"ranking":0},
{"id":6694,"nombre":"Salgadin","localidad":"Salgadinho","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6704,"nombre":"Santa Luzia","localidad":"Santa Luzia","provincia":"Paraíba","pais":"BRASIL","latitud":-6.87018622067522,"longitud":-36.92496451590461,"ranking":0},
{"id":7436,"nombre":"Santana dos Garrotes","localidad":"Santana dos Garrotes","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6707,"nombre":"Santa Rita","localidad":"Santa Rita","provincia":"Paraíba","pais":"BRASIL","latitud":-7.122186573206925,"longitud":-34.981192969830765,"ranking":0},
{"id":10566,"nombre":"Santa Teresinha","localidad":"Santa Teresinha","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6697,"nombre":"Sao Bento","localidad":"São Bento","provincia":"Paraíba","pais":"BRASIL","latitud":-6.503582734663787,"longitud":-37.449408738431806,"ranking":0},
{"id":6695,"nombre":"Sao Bentinho","localidad":"São Bentinho","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10372,"nombre":"Sao Joao do Cariri","localidad":"São João do Cariri","provincia":"Paraíba","pais":"BRASIL","latitud":-7.3908005961676295,"longitud":-36.532684957304575,"ranking":0},
{"id":7437,"nombre":"Sao Jose de Piranhas ","localidad":"São José de Piranhas","provincia":"Paraíba","pais":"BRASIL","latitud":-7.120144400281042,"longitud":-38.50957557227647,"ranking":0},
{"id":6698,"nombre":"Sao Mamede","localidad":"São Mamede","provincia":"Paraíba","pais":"BRASIL","latitud":-6.937209326515599,"longitud":-37.09608908068398,"ranking":0},
{"id":9012,"nombre":"Sao Sebastiao de Lagoa de Roca","localidad":"São Sebastião de Lagoa de Roça","provincia":"Paraíba","pais":"BRASIL","latitud":-7.104460670622212,"longitud":-35.86676449498768,"ranking":0},
{"id":6700,"nombre":"Sape","localidad":"Sapé","provincia":"Paraíba","pais":"BRASIL","latitud":-7.097505216109122,"longitud":-35.23105570773419,"ranking":0},
{"id":6760,"nombre":"Serra Branca","localidad":"Serra Branca","provincia":"Paraíba","pais":"BRASIL","latitud":-7.484351518503511,"longitud":-36.66193171037712,"ranking":0},
{"id":10557,"nombre":"Serra Redonda","localidad":"Serra Redonda","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6701,"nombre":"Solanea","localidad":"Solânea","provincia":"Paraíba","pais":"BRASIL","latitud":-6.757879398638979,"longitud":-35.66259341316671,"ranking":0},
{"id":6703,"nombre":"Soledade","localidad":"Soledade","provincia":"Paraíba","pais":"BRASIL","latitud":-7.0599328047360626,"longitud":-36.35530559176538,"ranking":0},
{"id":6636,"nombre":"Sousa","localidad":"Sousa","provincia":"Paraíba","pais":"BRASIL","latitud":-6.765415623351071,"longitud":-38.22093830742491,"ranking":0},
{"id":6762,"nombre":"Sume","localidad":"Sumé","provincia":"Paraíba","pais":"BRASIL","latitud":-7.672889530528535,"longitud":-36.879798562870306,"ranking":0},
{"id":9014,"nombre":"Tacima","localidad":"Tacima","provincia":"Paraíba","pais":"BRASIL","latitud":-6.48754581424938,"longitud":-35.637036575903906,"ranking":0},
{"id":6740,"nombre":"Tavares","localidad":"Tavares","provincia":"Paraíba","pais":"BRASIL","latitud":-7.635622687462186,"longitud":-37.87679414832423,"ranking":0},
{"id":6741,"nombre":"Teixeira","localidad":"Teixeira","provincia":"Paraíba","pais":"BRASIL","latitud":-7.221135864719861,"longitud":-37.25249627702739,"ranking":0},
{"id":6743,"nombre":"Uirauna","localidad":"Uiraúna","provincia":"Paraíba","pais":"BRASIL","latitud":-6.516655076708599,"longitud":-38.40656092244304,"ranking":0},
{"id":7322,"nombre":"Abreu e Lima","localidad":"Abreu e Lima","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.921609906459934,"longitud":-34.89549142399776,"ranking":0},
{"id":4903,"nombre":"Afogados da Ingazeira","localidad":"Afogados da Ingazeira","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.754322779319092,"longitud":-37.63207360288096,"ranking":0},
{"id":4904,"nombre":"Afranio","localidad":"Afrânio","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.517869384939937,"longitud":-41.00734618476428,"ranking":0},
{"id":4907,"nombre":"Agrestina","localidad":"Agrestina","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.45529026791028,"longitud":-35.94492909052592,"ranking":0},
{"id":6478,"nombre":"Aguas Belas","localidad":"Águas Belas","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.115127859073457,"longitud":-37.11935324197593,"ranking":0},
{"id":6483,"nombre":"Alagoinha","localidad":"Alagoinha","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.466772119092223,"longitud":-36.77577780794956,"ranking":0},
{"id":10523,"nombre":"Alianca","localidad":"Aliança","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5032,"nombre":"Araripina","localidad":"Araripina","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.575173115771589,"longitud":-40.5105635660102,"ranking":0},
{"id":9557,"nombre":"Barreiros","localidad":"Barreiros","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.81666355806132,"longitud":-35.188829123685586,"ranking":0},
{"id":7441,"nombre":"Belem do Sao Francisco","localidad":"Belém do São Francisco","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.751195194677079,"longitud":-38.96002321603077,"ranking":0},
{"id":6426,"nombre":"Belo Jardim","localidad":"Belo Jardim","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.3431414427198,"longitud":-36.41794321071951,"ranking":0},
{"id":10520,"nombre":"Bezerros","localidad":"Bezerros","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6486,"nombre":"Bodoco","localidad":"Bodocó","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.78042333496848,"longitud":-39.93889774827892,"ranking":0},
{"id":9218,"nombre":"Bom Conselho","localidad":"Bom Conselho","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.169775628738796,"longitud":-36.68028297969868,"ranking":0},
{"id":10517,"nombre":"Bonito","localidad":"Bonito","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10519,"nombre":"Brejinho","localidad":"Brejinho","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6487,"nombre":"Buique","localidad":"Buíque","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.624782962431496,"longitud":-37.1592483130045,"ranking":0},
{"id":10524,"nombre":"Cabo de Santo Agostinho","localidad":"Cabo de Santo Agostinho","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.239753586949522,"longitud":-34.985365855810905,"ranking":0},
{"id":4922,"nombre":"Cabroro","localidad":"Cabrobó","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.508858091332115,"longitud":-39.309838613293344,"ranking":0},
{"id":10165,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.491250817153462,"longitud":-36.23509080043184,"ranking":0},
{"id":7442,"nombre":"Calumbi","localidad":"Calumbi","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.937710061641913,"longitud":-38.15143131662169,"ranking":0},
{"id":10521,"nombre":"Camocim de Sao Felix","localidad":"Camocim de São Félix","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6490,"nombre":"Canhotinho","localidad":"Canhotinho","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.881865793163842,"longitud":-36.19424036729064,"ranking":0},
{"id":6496,"nombre":"Carnaiba","localidad":"Carnaíba","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.804353399999999,"longitud":-37.7933431,"ranking":0},
{"id":6503,"nombre":"Carpina","localidad":"Carpina","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.844139882923697,"longitud":-35.25997363711213,"ranking":0},
{"id":10516,"nombre":"Catende","localidad":"Catende","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4934,"nombre":"Cupira","localidad":"Cupira","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.612613158353266,"longitud":-35.95636558799921,"ranking":0},
{"id":6433,"nombre":"Custodia","localidad":"Custódia","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.086075255655283,"longitud":-37.650178275245935,"ranking":0},
{"id":9565,"nombre":"Escada","localidad":"Escada","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.367707762686438,"longitud":-35.23566611580497,"ranking":0},
{"id":6434,"nombre":"Exu","localidad":"Exu","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.51454334239478,"longitud":-39.724192326787744,"ranking":0},
{"id":6504,"nombre":"Flores","localidad":"Flores","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.863793089997554,"longitud":-37.975131088863535,"ranking":0},
{"id":7440,"nombre":"Floresta","localidad":"Floresta","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.601144077544665,"longitud":-38.5735618845142,"ranking":0},
{"id":9568,"nombre":"Gameleira","localidad":"Gameleira","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.58409312388496,"longitud":-35.38750211013316,"ranking":0},
{"id":6437,"nombre":"Garanhuns","localidad":"Garanhuns","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.882562338688272,"longitud":-36.486760796809286,"ranking":0},
{"id":6438,"nombre":"Goiana","localidad":"Goiana","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.559603321376472,"longitud":-35.01084744529122,"ranking":0},
{"id":4945,"nombre":"Gravata","localidad":"Gravatá","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.196669203613967,"longitud":-35.57236907403319,"ranking":0},
{"id":6509,"nombre":"Iati","localidad":"Iati","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.04155682972497,"longitud":-36.84913865466536,"ranking":0},
{"id":6511,"nombre":"Ibimirim","localidad":"Ibimirim","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.534057538287186,"longitud":-37.69519144268782,"ranking":0},
{"id":15056,"nombre":"Igarassu","localidad":"Igarassu","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.8324743,"longitud":-34.9119438,"ranking":0},
{"id":7323,"nombre":"Iguaraci","localidad":"Iguaraci","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.835120800440693,"longitud":-37.51418903584378,"ranking":0},
{"id":6513,"nombre":"Inaja","localidad":"Inajá","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.900938335872032,"longitud":-37.824743649236474,"ranking":0},
{"id":9569,"nombre":"Ipojuca","localidad":"Ipojuca","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15230,"nombre":"Porto de Galinhas","localidad":"Ipojuca","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.5018449,"longitud":-35.0048331,"ranking":0},
{"id":6514,"nombre":"Itaiba","localidad":"Itaíba","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.947216152048833,"longitud":-37.422906857498155,"ranking":0},
{"id":6516,"nombre":"Itapetim","localidad":"Itapetim","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.377587428583543,"longitud":-37.19296568503996,"ranking":0},
{"id":14952,"nombre":"Jatauba","localidad":"Jataúba","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.990679911978026,"longitud":-36.49382230481447,"ranking":0},
{"id":9225,"nombre":"Jatoba","localidad":"Jatobá","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10518,"nombre":"Joao Alfredo","localidad":"João Alfredo","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10163,"nombre":"Jupi","localidad":"Jupi","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.709287838672267,"longitud":-36.41626274800016,"ranking":0},
{"id":6443,"nombre":"Lagoa Grande","localidad":"Lagoa Grande","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.997817481675009,"longitud":-40.27208663162911,"ranking":0},
{"id":6518,"nombre":"Lajedo","localidad":"Lajedo","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.659809442074934,"longitud":-36.328859072739036,"ranking":0},
{"id":6519,"nombre":"Manari","localidad":"Manari","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.960068502514261,"longitud":-37.62194290144343,"ranking":0},
{"id":10515,"nombre":"Mirandiba","localidad":"Mirandiba","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7446,"nombre":"Nazare da Mata","localidad":"Nazaré da Mata","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.742096145983879,"longitud":-35.22624947635699,"ranking":0},
{"id":6445,"nombre":"Oroco","localidad":"Orocó","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.614891573455383,"longitud":-39.60393228352408,"ranking":0},
{"id":4988,"nombre":"Ouricuri","localidad":"Ouricuri","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.880169184857374,"longitud":-40.07792907603067,"ranking":0},
{"id":6521,"nombre":"Panelas","localidad":"Panelas","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.663783259230135,"longitud":-36.00904319251179,"ranking":0},
{"id":6447,"nombre":"Parnamiri","localidad":"Parnamirim","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.090376005957182,"longitud":-39.57573623171433,"ranking":0},
{"id":6522,"nombre":"Paudalho","localidad":"Paudalho","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.8977773502605295,"longitud":-35.17265985627065,"ranking":0},
{"id":6524,"nombre":"Pedra","localidad":"Pedra","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.500913594786196,"longitud":-36.947528496448406,"ranking":0},
{"id":6449,"nombre":"Pesqueira","localidad":"Pesqueira","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.3623098,"longitud":-36.5639743,"ranking":0},
{"id":4980,"nombre":"Petrolandia","localidad":"Petrolândia","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.984784335533721,"longitud":-38.21771677903537,"ranking":0},
{"id":6525,"nombre":"Pombos","localidad":"Pombos","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.14146739017788,"longitud":-35.396369609026905,"ranking":0},
{"id":6526,"nombre":"Quipapa","localidad":"Quipapá","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.826551563869572,"longitud":-36.011643930034154,"ranking":0},
{"id":6527,"nombre":"Quixaba","localidad":"Quixaba","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.718875620697094,"longitud":-37.84553421619336,"ranking":0},
{"id":9538,"nombre":"Ribeirão","localidad":"Ribeirão","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9537,"nombre":"Rio Formoso","localidad":"Rio Formoso","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.659958236637602,"longitud":-35.1517630235966,"ranking":0},
{"id":10522,"nombre":"Saire","localidad":"Sairé","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7439,"nombre":"Salgadinho","localidad":"Salgadinho","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.940914367349756,"longitud":-35.632666079245396,"ranking":0},
{"id":5004,"nombre":"Salgueiro","localidad":"Salgueiro","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.07943,"longitud":-39.13059,"ranking":0},
{"id":6530,"nombre":"Sanharo","localidad":"Sanharó","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.362226719731561,"longitud":-36.563859326231224,"ranking":0},
{"id":6462,"nombre":"Santa Cruz","localidad":"Santa Cruz","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.241148781366952,"longitud":-40.327626579204306,"ranking":0},
{"id":5013,"nombre":"Santa Cruz do Capibaribe","localidad":"Santa Cruz do Capibaribe","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.95970800598091,"longitud":-36.20569224745747,"ranking":0},
{"id":7324,"nombre":"Santa Maria da Boa Vista ","localidad":"Santa Maria da Boa Vista","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.799276866998978,"longitud":-39.827805027637545,"ranking":0},
{"id":14951,"nombre":"Santa Terezinha","localidad":"Santa Terezinha","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.378357826010663,"longitud":-37.479688461059965,"ranking":0},
{"id":9536,"nombre":"São José da Coroa Grande","localidad":"São José da Coroa Grande","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.897327136926794,"longitud":-35.15051778331656,"ranking":0},
{"id":7447,"nombre":"Sao Jose do Belmonte","localidad":"São José do Belmonte","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.864309930534054,"longitud":-38.759201986354675,"ranking":0},
{"id":5014,"nombre":"Sao Jose do Egito","localidad":"São José do Egito","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.471571,"longitud":-37.2745327,"ranking":0},
{"id":6535,"nombre":"Serrita","localidad":"Serrita","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.944166262693168,"longitud":-39.30040064078301,"ranking":0},
{"id":5015,"nombre":"Sertania","localidad":"Sertânia","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.066440073816935,"longitud":-37.26503846733314,"ranking":0},
{"id":6532,"nombre":"Sirinhaem ","localidad":"Sirinhaém","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.595136627103608,"longitud":-35.11211431821845,"ranking":0},
{"id":6536,"nombre":"Tabira ","localidad":"Tabira","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.594578556627675,"longitud":-37.54086256027222,"ranking":0},
{"id":6538,"nombre":"Tacaimbo","localidad":"Tacaimbó","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6539,"nombre":"Tacaratu","localidad":"Tacaratu","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.103486928057473,"longitud":-38.14740779591367,"ranking":0},
{"id":14996,"nombre":"Tamandare","localidad":"Tamandaré","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.063476854170872,"longitud":-34.98253257032727,"ranking":0},
{"id":6540,"nombre":"Toritama","localidad":"Toritama","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.323495863096559,"longitud":-36.13661233146904,"ranking":0},
{"id":6464,"nombre":"Trindade","localidad":"Trindade","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.7597722261164535,"longitud":-40.264787236914124,"ranking":0},
{"id":14789,"nombre":"Triunfo","localidad":"Triunfo","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.832440000000001,"longitud":-38.1089,"ranking":0},
{"id":6542,"nombre":"Tupanatinga","localidad":"Tupanatinga","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.749386263166581,"longitud":-37.34588827932817,"ranking":0},
{"id":5022,"nombre":"Tuparetama","localidad":"Tuparetama","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.5997304779782855,"longitud":-37.309451104383996,"ranking":0},
{"id":6544,"nombre":"Venturosa ","localidad":"Venturosa","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.577710271361033,"longitud":-36.87409388549125,"ranking":0},
{"id":5029,"nombre":"Vitoria de Santo Antao","localidad":"Vitória de Santo Antão","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.114200401510203,"longitud":-35.29681995456651,"ranking":0},
{"id":4912,"nombre":"Arapiraca","localidad":"Arapiraca","provincia":"Alagoas","pais":"BRASIL","latitud":-9.75802,"longitud":-36.64592,"ranking":0},
{"id":6996,"nombre":"Atalaia","localidad":"Atalaia","provincia":"Alagoas","pais":"BRASIL","latitud":-9.510941115811832,"longitud":-36.00440090733641,"ranking":0},
{"id":9555,"nombre":"Barra de Santo Antônio","localidad":"Barra de Santo Antônio","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9556,"nombre":"Barra de São Miguel","localidad":"Barra de São Miguel","provincia":"Alagoas","pais":"BRASIL","latitud":-9.817356758475414,"longitud":-35.87784571466023,"ranking":0},
{"id":9558,"nombre":"Batalha","localidad":"Batalha","provincia":"Alagoas","pais":"BRASIL","latitud":-9.66901094136382,"longitud":-37.13659994250497,"ranking":0},
{"id":9559,"nombre":"Belém","localidad":"Belém","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9561,"nombre":"Cacimbinhas","localidad":"Cacimbinhas","provincia":"Alagoas","pais":"BRASIL","latitud":-9.3998400781243,"longitud":-36.99058158321933,"ranking":0},
{"id":9562,"nombre":"Campo Alegre","localidad":"Campo Alegre","provincia":"Alagoas","pais":"BRASIL","latitud":-9.78106887783466,"longitud":-36.344634404137324,"ranking":0},
{"id":9563,"nombre":"Coruripe","localidad":"Coruripe","provincia":"Alagoas","pais":"BRASIL","latitud":-10.124786545736086,"longitud":-36.17573380286238,"ranking":0},
{"id":7001,"nombre":"Delmiro Gouveia ","localidad":"Delmiro Gouveia","provincia":"Alagoas","pais":"BRASIL","latitud":-9.388511981325674,"longitud":-37.98863515727016,"ranking":0},
{"id":9564,"nombre":"Dois Riachos","localidad":"Dois Riachos","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9566,"nombre":"Estrela de Alagoas","localidad":"Estrela de Alagoas","provincia":"Alagoas","pais":"BRASIL","latitud":-9.390617416457248,"longitud":-36.757202240622036,"ranking":0},
{"id":10539,"nombre":"Flexeiras","localidad":"Flexeiras","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7005,"nombre":"Igreja Nova","localidad":"Igreja Nova","provincia":"Alagoas","pais":"BRASIL","latitud":-10.131366515041341,"longitud":-36.65008223000535,"ranking":0},
{"id":9570,"nombre":"Jacaré dos Homens","localidad":"Jacaré dos Homens","provincia":"Alagoas","pais":"BRASIL","latitud":-9.632345463757641,"longitud":-37.200800408889826,"ranking":0},
{"id":9571,"nombre":"Jaramataia","localidad":"Jaramataia","provincia":"Alagoas","pais":"BRASIL","latitud":-9.6585107423153,"longitud":-37.001692563733066,"ranking":0},
{"id":9572,"nombre":"Jequiá da Praia","localidad":"Jequiá da Praia","provincia":"Alagoas","pais":"BRASIL","latitud":-10.011749233345665,"longitud":-36.02334486501611,"ranking":0},
{"id":9573,"nombre":"Joaquim Gomes","localidad":"Joaquim Gomes","provincia":"Alagoas","pais":"BRASIL","latitud":-9.142577373974126,"longitud":-35.769429331511205,"ranking":0},
{"id":9574,"nombre":"Junqueiro","localidad":"Junqueiro","provincia":"Alagoas","pais":"BRASIL","latitud":-9.928389244432774,"longitud":-36.47221835308023,"ranking":0},
{"id":9576,"nombre":"Maragogi","localidad":"Maragogi","provincia":"Alagoas","pais":"BRASIL","latitud":-9.01262546941697,"longitud":-35.22406354943686,"ranking":0},
{"id":9577,"nombre":"Maravilha","localidad":"Maravilha","provincia":"Alagoas","pais":"BRASIL","latitud":-9.231613442417475,"longitud":-37.34957300805685,"ranking":0},
{"id":9578,"nombre":"Marechal Deodoro","localidad":"Marechal Deodoro","provincia":"Alagoas","pais":"BRASIL","latitud":-9.711013518237623,"longitud":-35.89494718653522,"ranking":0},
{"id":9579,"nombre":"Maribondo","localidad":"Maribondo","provincia":"Alagoas","pais":"BRASIL","latitud":-9.582971395932107,"longitud":-36.313033563775384,"ranking":0},
{"id":9580,"nombre":"Matriz de Camaragibe","localidad":"Matriz de Camaragibe","provincia":"Alagoas","pais":"BRASIL","latitud":-9.160656483111932,"longitud":-35.53667471527892,"ranking":0},
{"id":6997,"nombre":"Messias","localidad":"Messias","provincia":"Alagoas","pais":"BRASIL","latitud":-9.394020665379399,"longitud":-35.83830691453007,"ranking":0},
{"id":9581,"nombre":"Monteirópolis","localidad":"Monteirópolis","provincia":"Alagoas","pais":"BRASIL","latitud":-9.603062531813471,"longitud":-37.24758299234137,"ranking":0},
{"id":10506,"nombre":"Novo Lino","localidad":"Novo Lino","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9582,"nombre":"Olho d'Água das Flores","localidad":"Olho d'Água das Flores","provincia":"Alagoas","pais":"BRASIL","latitud":-9.535691527553315,"longitud":-37.29440990498339,"ranking":0},
{"id":9583,"nombre":"Olho d'Água do Casado","localidad":"Olho d'Água do Casado","provincia":"Alagoas","pais":"BRASIL","latitud":-9.494148762732403,"longitud":-37.83052435177137,"ranking":0},
{"id":9584,"nombre":"Ouro Branco","localidad":"Ouro Branco","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9585,"nombre":"Palestina","localidad":"Palestina","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4994,"nombre":"Palmeira dos Indios","localidad":"Palmeira dos Índios","provincia":"Alagoas","pais":"BRASIL","latitud":-9.421912833747465,"longitud":-36.62333549207332,"ranking":0},
{"id":9586,"nombre":"Pão de Açúcar","localidad":"Pão de Açúcar","provincia":"Alagoas","pais":"BRASIL","latitud":-9.749395930297426,"longitud":-37.43518562798874,"ranking":0},
{"id":7004,"nombre":"Penedo","localidad":"Penedo","provincia":"Alagoas","pais":"BRASIL","latitud":-10.294226696004829,"longitud":-36.583198233134574,"ranking":0},
{"id":9587,"nombre":"Pilar","localidad":"Pilar","provincia":"Alagoas","pais":"BRASIL","latitud":-9.590637634934069,"longitud":-35.95269678712396,"ranking":0},
{"id":9588,"nombre":"Poço das Trincheiras","localidad":"Poço das Trincheiras","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9540,"nombre":"Porto Calvo","localidad":"Porto Calvo","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14833,"nombre":"Porto Real do Colegio","localidad":"Porto Real do Colégio","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10505,"nombre":"Rio Largo","localidad":"Rio Largo","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9226,"nombre":"Santana do Ipanema","localidad":"Santana do Ipanema","provincia":"Alagoas","pais":"BRASIL","latitud":-9.371834539709262,"longitud":-37.23755007710056,"ranking":0},
{"id":7002,"nombre":"Sao Jose da Laje","localidad":"São José da Laje","provincia":"Alagoas","pais":"BRASIL","latitud":-9.010495451041136,"longitud":-36.05831705715649,"ranking":0},
{"id":9535,"nombre":"São José da Tapera","localidad":"São José da Tapera","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9534,"nombre":"São Luís do Quitunde","localidad":"São Luís do Quitunde","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6999,"nombre":"Sao Miguel dos Campos","localidad":"São Miguel dos Campos","provincia":"Alagoas","pais":"BRASIL","latitud":-9.779614393002442,"longitud":-36.10550053170123,"ranking":0},
{"id":9533,"nombre":"Satuba","localidad":"Satuba","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9532,"nombre":"Taquarana","localidad":"Taquarana","provincia":"Alagoas","pais":"BRASIL","latitud":-9.64874271921893,"longitud":-36.49338764209631,"ranking":0},
{"id":9531,"nombre":"Teotônio Vilela","localidad":"Teotônio Vilela","provincia":"Alagoas","pais":"BRASIL","latitud":-9.90062700155223,"longitud":-36.36360222444977,"ranking":0},
{"id":7000,"nombre":"Uniao dos Palmares  ","localidad":"União dos Palmares","provincia":"Alagoas","pais":"BRASIL","latitud":-9.159414131867921,"longitud":-36.031900236871394,"ranking":0},
{"id":7003,"nombre":"Vicosa","localidad":"Viçosa","provincia":"Alagoas","pais":"BRASIL","latitud":-9.359961218330653,"longitud":-36.2536463235367,"ranking":0},
{"id":6988,"nombre":"Boquim","localidad":"Boquim","provincia":"Sergipe","pais":"BRASIL","latitud":-11.150082194195406,"longitud":-37.62289857893577,"ranking":0},
{"id":4930,"nombre":"Caninde de Sao Francisco","localidad":"Canindé de São Francisco","provincia":"Sergipe","pais":"BRASIL","latitud":-9.6670730568864,"longitud":-37.7962819940211,"ranking":0},
{"id":6990,"nombre":"Carmopolis","localidad":"Carmópolis","provincia":"Sergipe","pais":"BRASIL","latitud":-10.642858988380448,"longitud":-36.99202807111856,"ranking":0},
{"id":8672,"nombre":"Cristinapolis ","localidad":"Cristinápolis","provincia":"Sergipe","pais":"BRASIL","latitud":-11.47806868644747,"longitud":-37.75426478999407,"ranking":0},
{"id":6987,"nombre":"Estancia","localidad":"Estância","provincia":"Sergipe","pais":"BRASIL","latitud":-11.264856371492046,"longitud":-37.441604895259886,"ranking":0},
{"id":4911,"nombre":"Itabaiana","localidad":"Itabaiana","provincia":"Sergipe","pais":"BRASIL","latitud":-10.69983009343601,"longitud":-37.42892585451106,"ranking":0},
{"id":6992,"nombre":"Itaporanga d Ajuda","localidad":"Itaporanga d'Ajuda","provincia":"Sergipe","pais":"BRASIL","latitud":-10.996954720433134,"longitud":-37.30645516956846,"ranking":0},
{"id":6994,"nombre":"Lagarto ","localidad":"Lagarto","provincia":"Sergipe","pais":"BRASIL","latitud":-10.92052451586436,"longitud":-37.67166661819877,"ranking":0},
{"id":6991,"nombre":"Maruim ","localidad":"Maruim","provincia":"Sergipe","pais":"BRASIL","latitud":-10.732519159435078,"longitud":-37.08508561456109,"ranking":0},
{"id":4971,"nombre":"Monte Alegre de Sergipe","localidad":"Monte Alegre de Sergipe","provincia":"Sergipe","pais":"BRASIL","latitud":-10.027224934028764,"longitud":-37.56049260286327,"ranking":0},
{"id":6989,"nombre":"Muribeca","localidad":"Muribeca","provincia":"Sergipe","pais":"BRASIL","latitud":-10.41301192000801,"longitud":-36.958439788934626,"ranking":0},
{"id":4982,"nombre":"Nossa Senhora da Gloria","localidad":"Nossa Senhora da Glória","provincia":"Sergipe","pais":"BRASIL","latitud":-10.212887672371872,"longitud":-37.41892082002027,"ranking":0},
{"id":4981,"nombre":"Nossa Senhora das Dores","localidad":"Nossa Senhora das Dores","provincia":"Sergipe","pais":"BRASIL","latitud":-10.486105100788853,"longitud":-37.19553323546065,"ranking":0},
{"id":10533,"nombre":"Nossa Senhora do Socorro","localidad":"Nossa Senhora do Socorro","provincia":"Sergipe","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6986,"nombre":"Propria","localidad":"Propriá","provincia":"Sergipe","pais":"BRASIL","latitud":-10.228417098014988,"longitud":-36.838127539928884,"ranking":0},
{"id":10420,"nombre":"Riachao do Dantas","localidad":"Riachão do Dantas","provincia":"Sergipe","pais":"BRASIL","latitud":-11.069615132810437,"longitud":-37.72779219851263,"ranking":0},
{"id":6993,"nombre":"Salgado  ","localidad":"Salgado","provincia":"Sergipe","pais":"BRASIL","latitud":-11.024731560801692,"longitud":-37.47543815445434,"ranking":0},
{"id":10421,"nombre":"Sao Cristovao","localidad":"São Cristóvão","provincia":"Sergipe","pais":"BRASIL","latitud":-11.011187253624072,"longitud":-37.207568451319794,"ranking":0},
{"id":8670,"nombre":"Simao Dias","localidad":"Simão Dias","provincia":"Sergipe","pais":"BRASIL","latitud":-10.743445766132421,"longitud":-37.797657974020844,"ranking":0},
{"id":6995,"nombre":"Tobias Barreto  ","localidad":"Tobias Barreto","provincia":"Sergipe","pais":"BRASIL","latitud":-11.185437998237726,"longitud":-37.99881693394553,"ranking":0},
{"id":8671,"nombre":"Umbauba","localidad":"Umbaúba","provincia":"Sergipe","pais":"BRASIL","latitud":-11.382704779975217,"longitud":-37.65946362352867,"ranking":0},
{"id":10298,"nombre":"Abaira","localidad":"Abaíra","provincia":"Bahia","pais":"BRASIL","latitud":-13.252390608864117,"longitud":-41.65912412171327,"ranking":0},
{"id":14842,"nombre":"Acajutiba","localidad":"Acajutiba","provincia":"Bahia","pais":"BRASIL","latitud":-11.661699602041942,"longitud":-38.017324760215566,"ranking":0},
{"id":14722,"nombre":"Adustina","localidad":"Adustina","provincia":"Bahia","pais":"BRASIL","latitud":-10.532725300869282,"longitud":-38.11560155760822,"ranking":0},
{"id":10347,"nombre":"Agua Fria","localidad":"Água Fria","provincia":"Bahia","pais":"BRASIL","latitud":-11.869927942737732,"longitud":-38.76153392323116,"ranking":0},
{"id":10353,"nombre":"Aiquara","localidad":"Aiquara","provincia":"Bahia","pais":"BRASIL","latitud":-14.129083190049057,"longitud":-39.888236464903194,"ranking":0},
{"id":4909,"nombre":"Alagoinhas","localidad":"Alagoinhas","provincia":"Bahia","pais":"BRASIL","latitud":-12.151,"longitud":-38.401,"ranking":0},
{"id":9955,"nombre":"Santa Isabel","localidad":"Alagoinhas","provincia":"Bahia","pais":"BRASIL","latitud":-12.151085656753255,"longitud":-38.40081873972814,"ranking":0},
{"id":6325,"nombre":"Alcobaca","localidad":"Alcobaça","provincia":"Bahia","pais":"BRASIL","latitud":-17.522355944418745,"longitud":-39.195055970422274,"ranking":0},
{"id":10299,"nombre":"Amargosa","localidad":"Amargosa","provincia":"Bahia","pais":"BRASIL","latitud":-13.035036071829882,"longitud":-39.59722074699985,"ranking":0},
{"id":6327,"nombre":"Amelia Rodrigues","localidad":"Amélia Rodrigues","provincia":"Bahia","pais":"BRASIL","latitud":-12.395987874283875,"longitud":-38.75866263202878,"ranking":0},
{"id":6308,"nombre":"America Dourada","localidad":"América Dourada","provincia":"Bahia","pais":"BRASIL","latitud":-11.45364055493394,"longitud":-41.435942265879945,"ranking":0},
{"id":6328,"nombre":"Anage","localidad":"Anagé","provincia":"Bahia","pais":"BRASIL","latitud":-14.615852101026432,"longitud":-41.13083388711634,"ranking":0},
{"id":6330,"nombre":"Andarai","localidad":"Andaraí","provincia":"Bahia","pais":"BRASIL","latitud":-12.796546581639033,"longitud":-41.32777313557295,"ranking":0},
{"id":14720,"nombre":"Andorinha","localidad":"Andorinha","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10300,"nombre":"Angical","localidad":"Angical","provincia":"Bahia","pais":"BRASIL","latitud":-12.007521632007641,"longitud":-44.69419647944752,"ranking":0},
{"id":6334,"nombre":"Anguera","localidad":"Anguera","provincia":"Bahia","pais":"BRASIL","latitud":-12.204846853436004,"longitud":-39.13995435978092,"ranking":0},
{"id":10348,"nombre":"Antas","localidad":"Antas","provincia":"Bahia","pais":"BRASIL","latitud":-10.411379357842142,"longitud":-38.3308726283316,"ranking":0},
{"id":6356,"nombre":"Antonio Cardoso","localidad":"Antônio Cardoso","provincia":"Bahia","pais":"BRASIL","latitud":-12.435182862971972,"longitud":-39.120916876443864,"ranking":0},
{"id":14895,"nombre":"Antonio Goncalves","localidad":"Antônio Gonçalves","provincia":"Bahia","pais":"BRASIL","latitud":-10.5736394,"longitud":-40.2744205,"ranking":0},
{"id":14845,"nombre":"Apora","localidad":"Aporá","provincia":"Bahia","pais":"BRASIL","latitud":-11.659587665154014,"longitud":-38.07178939621008,"ranking":0},
{"id":10354,"nombre":"Apuarema","localidad":"Apuarema","provincia":"Bahia","pais":"BRASIL","latitud":-13.857485955036129,"longitud":-39.74414966455611,"ranking":0},
{"id":6357,"nombre":"Aracatu","localidad":"Aracatu","provincia":"Bahia","pais":"BRASIL","latitud":-14.430208389956984,"longitud":-41.46433289062207,"ranking":0},
{"id":14889,"nombre":"Aracas","localidad":"Araças","provincia":"Bahia","pais":"BRASIL","latitud":-12.21142725609148,"longitud":-38.20126923123051,"ranking":0},
{"id":9855,"nombre":"Araci","localidad":"Araci","provincia":"Bahia","pais":"BRASIL","latitud":-11.341746881158988,"longitud":-38.96101253647485,"ranking":0},
{"id":15249,"nombre":"Pedra Alta","localidad":"Araci","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10345,"nombre":"Aramari","localidad":"Aramari","provincia":"Bahia","pais":"BRASIL","latitud":-12.088624147025639,"longitud":-38.49700880381371,"ranking":0},
{"id":6358,"nombre":"Aratuipe","localidad":"Aratuípe","provincia":"Bahia","pais":"BRASIL","latitud":-13.078023412049733,"longitud":-39.00078344380173,"ranking":0},
{"id":10301,"nombre":"Baianopolis","localidad":"Baianópolis","provincia":"Bahia","pais":"BRASIL","latitud":-12.23303211537489,"longitud":-44.41484472505069,"ranking":0},
{"id":6364,"nombre":"Baixa Grande","localidad":"Baixa Grande","provincia":"Bahia","pais":"BRASIL","latitud":-11.960741927447746,"longitud":-40.16542594734371,"ranking":0},
{"id":14887,"nombre":"Banzae","localidad":"Banzaê","provincia":"Bahia","pais":"BRASIL","latitud":-10.5362851,"longitud":-38.603819,"ranking":0},
{"id":6281,"nombre":"Barra","localidad":"Barra","provincia":"Bahia","pais":"BRASIL","latitud":-11.090580754029467,"longitud":-43.138477301357945,"ranking":0},
{"id":10079,"nombre":"Barra da Estiva","localidad":"Barra da Estiva","provincia":"Bahia","pais":"BRASIL","latitud":-13.626029095070027,"longitud":-41.32023365022489,"ranking":0},
{"id":6369,"nombre":"Barra do Mendes","localidad":"Barra do Mendes","provincia":"Bahia","pais":"BRASIL","latitud":-11.807580733144087,"longitud":-42.05812040262188,"ranking":0},
{"id":6370,"nombre":"Barra do Rocha","localidad":"Barra do Rocha","provincia":"Bahia","pais":"BRASIL","latitud":-14.207830456113488,"longitud":-39.6011148445678,"ranking":0},
{"id":4918,"nombre":"Barreiras","localidad":"Barreiras","provincia":"Bahia","pais":"BRASIL","latitud":-12.1270664,"longitud":-45.0182625,"ranking":0},
{"id":10302,"nombre":"Barro Alto","localidad":"Barro Alto","provincia":"Bahia","pais":"BRASIL","latitud":-11.761757436464993,"longitud":-41.907666150720495,"ranking":0},
{"id":14297,"nombre":"Barrocas","localidad":"Barrocas","provincia":"Bahia","pais":"BRASIL","latitud":-11.5379326,"longitud":-39.0786904,"ranking":0},
{"id":10573,"nombre":"Belmonte","localidad":"Belmonte","provincia":"Bahia","pais":"BRASIL","latitud":-15.863120764091954,"longitud":-38.88343162929857,"ranking":0},
{"id":10355,"nombre":"Belo Campo","localidad":"Belo Campo","provincia":"Bahia","pais":"BRASIL","latitud":-15.039849996201209,"longitud":-41.258439213853116,"ranking":0},
{"id":10303,"nombre":"Biritinga","localidad":"Biritinga","provincia":"Bahia","pais":"BRASIL","latitud":-11.623772874806725,"longitud":-38.810160311855164,"ranking":0},
{"id":10356,"nombre":"Boa Nova","localidad":"Boa Nova","provincia":"Bahia","pais":"BRASIL","latitud":-14.361919084621803,"longitud":-40.20744799695768,"ranking":0},
{"id":6372,"nombre":"Boa Vista do Tupim","localidad":"Boa Vista do Tupim","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6587,"nombre":"Bom Jesus da Lapa","localidad":"Bom Jesus da Lapa","provincia":"Bahia","pais":"BRASIL","latitud":-13.2494191,"longitud":-43.3741868,"ranking":0},
{"id":10304,"nombre":"Boninal","localidad":"Boninal","provincia":"Bahia","pais":"BRASIL","latitud":-12.707714091029363,"longitud":-41.83239124516497,"ranking":0},
{"id":6310,"nombre":"Bonito","localidad":"Bonito","provincia":"Bahia","pais":"BRASIL","latitud":-11.974895673352112,"longitud":-41.26092428148502,"ranking":0},
{"id":9961,"nombre":"Travessao","localidad":"Bonito","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10167,"nombre":"Boquira","localidad":"Boquira","provincia":"Bahia","pais":"BRASIL","latitud":-12.819815538849015,"longitud":-42.72757039315657,"ranking":0},
{"id":10357,"nombre":"Brejoes","localidad":"Brejões","provincia":"Bahia","pais":"BRASIL","latitud":-13.075990816869195,"longitud":-39.96073390376272,"ranking":0},
{"id":10305,"nombre":"Brejolandia","localidad":"Brejolândia","provincia":"Bahia","pais":"BRASIL","latitud":-12.485950034480052,"longitud":-43.959052729624595,"ranking":0},
{"id":10306,"nombre":"Brotas de Macaubas","localidad":"Brotas de Macaúbas","provincia":"Bahia","pais":"BRASIL","latitud":-12.001963347703231,"longitud":-42.625710545557936,"ranking":0},
{"id":6379,"nombre":"Brumado","localidad":"Brumado","provincia":"Bahia","pais":"BRASIL","latitud":-14.219533090046049,"longitud":-41.6872157502995,"ranking":0},
{"id":6380,"nombre":"Buerarema","localidad":"Buerarema","provincia":"Bahia","pais":"BRASIL","latitud":-14.959347424340534,"longitud":-39.305555303163494,"ranking":0},
{"id":10080,"nombre":"Buritirama","localidad":"Buritirama","provincia":"Bahia","pais":"BRASIL","latitud":-10.713591372965025,"longitud":-43.631619874281306,"ranking":0},
{"id":9858,"nombre":"Cachoeira","localidad":"Cachoeira","provincia":"Bahia","pais":"BRASIL","latitud":-12.602455782807029,"longitud":-38.96722600937113,"ranking":0},
{"id":14846,"nombre":"Cacule","localidad":"Caculé","provincia":"Bahia","pais":"BRASIL","latitud":-14.489963161483754,"longitud":-42.22216508990122,"ranking":0},
{"id":10508,"nombre":"Caem","localidad":"Caém","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14581,"nombre":"Caetite","localidad":"Caetite","provincia":"Bahia","pais":"BRASIL","latitud":-14.0654976,"longitud":-42.4903157,"ranking":0},
{"id":6311,"nombre":"Cafarnaum","localidad":"Cafarnaum","provincia":"Bahia","pais":"BRASIL","latitud":-11.691592716846273,"longitud":-41.469538899462485,"ranking":0},
{"id":6383,"nombre":"Cairu","localidad":"Cairu","provincia":"Bahia","pais":"BRASIL","latitud":-13.485065305068703,"longitud":-39.048054332972455,"ranking":0},
{"id":6384,"nombre":"Camacan","localidad":"Camacan","provincia":"Bahia","pais":"BRASIL","latitud":-15.415363516217452,"longitud":-39.48687983312842,"ranking":0},
{"id":9967,"nombre":"Sao Joao do Panelinha","localidad":"Camacan","provincia":"Bahia","pais":"BRASIL","latitud":-15.374533905781831,"longitud":-39.49111187406762,"ranking":0},
{"id":4921,"nombre":"Camacari","localidad":"Camaçari","provincia":"Bahia","pais":"BRASIL","latitud":-12.70179223927067,"longitud":-38.3191680807074,"ranking":0},
{"id":6385,"nombre":"Camamu","localidad":"Camamu","provincia":"Bahia","pais":"BRASIL","latitud":-13.946442,"longitud":-39.101603,"ranking":0},
{"id":10507,"nombre":"Campo Alegre de Lourdes","localidad":"Campo Alegre de Lourdes","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9501,"nombre":"Campo Formoso","localidad":"Campo Formoso","provincia":"Bahia","pais":"BRASIL","latitud":-10.509824983839096,"longitud":-40.32736092741922,"ranking":0},
{"id":6282,"nombre":"Canarana","localidad":"Canarana","provincia":"Bahia","pais":"BRASIL","latitud":-11.68531054419086,"longitud":-41.76615081518372,"ranking":0},
{"id":9949,"nombre":"Paz de Salobro","localidad":"Canarana","provincia":"Bahia","pais":"BRASIL","latitud":-11.684945482528118,"longitud":-41.766166728835884,"ranking":0},
{"id":6386,"nombre":"Canavieiras","localidad":"Canavieiras","provincia":"Bahia","pais":"BRASIL","latitud":-15.674940239125764,"longitud":-38.957681593992234,"ranking":0},
{"id":14291,"nombre":"Candeal","localidad":"Candeal","provincia":"Bahia","pais":"BRASIL","latitud":-11.8101391,"longitud":-39.1221529,"ranking":0},
{"id":9860,"nombre":"Candeias","localidad":"Candeias","provincia":"Bahia","pais":"BRASIL","latitud":-12.668142937195965,"longitud":-38.53814820817264,"ranking":0},
{"id":14891,"nombre":"Cansancao","localidad":"Cansanção","provincia":"Bahia","pais":"BRASIL","latitud":-10.673184558929302,"longitud":-39.50256060883035,"ranking":0},
{"id":9502,"nombre":"Canudos","localidad":"Canudos","provincia":"Bahia","pais":"BRASIL","latitud":-9.896921359456478,"longitud":-39.027077986561395,"ranking":0},
{"id":10307,"nombre":"Capela do Alto Alegre","localidad":"Capela do Alto Alegre","provincia":"Bahia","pais":"BRASIL","latitud":-11.66814256704086,"longitud":-39.838353081683614,"ranking":0},
{"id":4927,"nombre":"Capim Grosso","localidad":"Capim Grosso","provincia":"Bahia","pais":"BRASIL","latitud":-11.387633625765504,"longitud":-40.014667461696035,"ranking":1},
{"id":14847,"nombre":"Caraibas","localidad":"Caraíbas","provincia":"Bahia","pais":"BRASIL","latitud":-13.628499863091779,"longitud":-42.15601598433863,"ranking":0},
{"id":6388,"nombre":"Caravelas","localidad":"Caravelas","provincia":"Bahia","pais":"BRASIL","latitud":-17.73390555442045,"longitud":-39.26227438034304,"ranking":0},
{"id":15265,"nombre":"Cardeal da Silva","localidad":"Cardeal da Silva","provincia":"Bahia","pais":"BRASIL","latitud":-11.942085276628369,"longitud":-37.94927917762507,"ranking":0},
{"id":14848,"nombre":"Carinhanha","localidad":"Carinhanha","provincia":"Bahia","pais":"BRASIL","latitud":-14.300788613328802,"longitud":-43.76582703093036,"ranking":0},
{"id":10509,"nombre":"Casa Nova","localidad":"Casa Nova","provincia":"Bahia","pais":"BRASIL","latitud":-9.169484854762695,"longitud":-40.97276945259168,"ranking":0},
{"id":10141,"nombre":"Castro Alves","localidad":"Castro Alves","provincia":"Bahia","pais":"BRASIL","latitud":-12.762652470434517,"longitud":-39.43110945209279,"ranking":0},
{"id":6589,"nombre":"Catu","localidad":"Catu","provincia":"Bahia","pais":"BRASIL","latitud":-12.367896668416321,"longitud":-38.38442265878267,"ranking":0},
{"id":10308,"nombre":"Caturama","localidad":"Caturama","provincia":"Bahia","pais":"BRASIL","latitud":-13.331931024177686,"longitud":-42.29415105166364,"ranking":0},
{"id":6390,"nombre":"Central","localidad":"Central","provincia":"Bahia","pais":"BRASIL","latitud":-11.133829538651879,"longitud":-42.10956488722595,"ranking":0},
{"id":9490,"nombre":"Chorrocho","localidad":"Chorrochó","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10349,"nombre":"Cipo","localidad":"Cipó","provincia":"Bahia","pais":"BRASIL","latitud":-11.094943226493509,"longitud":-38.50963457776088,"ranking":0},
{"id":6391,"nombre":"Coaraci","localidad":"Coaraci","provincia":"Bahia","pais":"BRASIL","latitud":-14.638690565902662,"longitud":-39.54834809117222,"ranking":0},
{"id":10411,"nombre":"Conceicao da Feira","localidad":"Conceição da Feira","provincia":"Bahia","pais":"BRASIL","latitud":-12.500345377299535,"longitud":-39.00044749917492,"ranking":0},
{"id":12134,"nombre":"Conceicao do Almeida","localidad":"Conceição do Almeida","provincia":"Bahia","pais":"BRASIL","latitud":-12.7758111,"longitud":-39.1714446,"ranking":0},
{"id":14886,"nombre":"Conceicao do Coite","localidad":"Conceição do Coité","provincia":"Bahia","pais":"BRASIL","latitud":-11.56997435423521,"longitud":-39.2753680122685,"ranking":0},
{"id":10142,"nombre":"Conceicao do Jacuipe","localidad":"Conceição do Jacuípe","provincia":"Bahia","pais":"BRASIL","latitud":-12.328917789913202,"longitud":-38.76209174268753,"ranking":0},
{"id":10358,"nombre":"Condeuba","localidad":"Condeúba","provincia":"Bahia","pais":"BRASIL","latitud":-14.895024146943886,"longitud":-41.96848132481475,"ranking":0},
{"id":10081,"nombre":"Contendas do Sincora","localidad":"Contendas do Sincorá","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10344,"nombre":"Coracao de Maria","localidad":"Coração de Maria","provincia":"Bahia","pais":"BRASIL","latitud":-12.247711098842412,"longitud":-38.74803750284171,"ranking":0},
{"id":14849,"nombre":"Cordeiros","localidad":"Cordeiros","provincia":"Bahia","pais":"BRASIL","latitud":-15.038628930224743,"longitud":-41.93524140844659,"ranking":0},
{"id":6283,"nombre":"Correntina","localidad":"Correntina","provincia":"Bahia","pais":"BRASIL","latitud":-13.346012754153719,"longitud":-44.63819756805475,"ranking":0},
{"id":10309,"nombre":"Cotegipe","localidad":"Cotegipe","provincia":"Bahia","pais":"BRASIL","latitud":-12.036407157130748,"longitud":-44.264293897404706,"ranking":0},
{"id":10339,"nombre":"Cravolandia","localidad":"Cravolândia","provincia":"Bahia","pais":"BRASIL","latitud":-13.35407036700188,"longitud":-39.810346329811054,"ranking":0},
{"id":14843,"nombre":"Crisopolis","localidad":"Crisópolis","provincia":"Bahia","pais":"BRASIL","latitud":-11.512185750998587,"longitud":-38.14913681565177,"ranking":0},
{"id":6284,"nombre":"Cristopolis","localidad":"Cristópolis","provincia":"Bahia","pais":"BRASIL","latitud":-12.233414772188759,"longitud":-44.414702460246204,"ranking":0},
{"id":8673,"nombre":"Cruz das Almas","localidad":"Cruz das Almas","provincia":"Bahia","pais":"BRASIL","latitud":-12.664217089172356,"longitud":-39.11300581259357,"ranking":0},
{"id":10359,"nombre":"Dario Meira","localidad":"Dário Meira","provincia":"Bahia","pais":"BRASIL","latitud":-14.43653120368326,"longitud":-39.9053331231486,"ranking":0},
{"id":10570,"nombre":"Dias d'Avila","localidad":"Dias d'Ávila","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10310,"nombre":"Dom Basilio","localidad":"Dom Basílio","provincia":"Bahia","pais":"BRASIL","latitud":-13.760715566839902,"longitud":-41.771327685594535,"ranking":0},
{"id":12135,"nombre":"Dom Macedo Costa","localidad":"Dom Macedo Costa","provincia":"Bahia","pais":"BRASIL","latitud":-12.9062226,"longitud":-39.19130190000001,"ranking":0},
{"id":10311,"nombre":"Elisio Medrado","localidad":"Elísio Medrado","provincia":"Bahia","pais":"BRASIL","latitud":-12.945117509754752,"longitud":-39.52040912293627,"ranking":0},
{"id":10360,"nombre":"Encruzilhada","localidad":"Encruzilhada","provincia":"Bahia","pais":"BRASIL","latitud":-15.688198794222929,"longitud":-40.74272753988074,"ranking":0},
{"id":8674,"nombre":"Entre Rios","localidad":"Entre Rios","provincia":"Bahia","pais":"BRASIL","latitud":-11.946278892904695,"longitud":-38.06894457574361,"ranking":0},
{"id":10510,"nombre":"Euclides da Cunha","localidad":"Euclides da Cunha","provincia":"Bahia","pais":"BRASIL","latitud":-10.502927269141273,"longitud":-39.01072461718358,"ranking":0},
{"id":4939,"nombre":"Eunapolis","localidad":"Eunápolis","provincia":"Bahia","pais":"BRASIL","latitud":-16.370746639176225,"longitud":-39.560044611840055,"ranking":0},
{"id":9956,"nombre":"Santa Isabel","localidad":"Eunápolis","provincia":"Bahia","pais":"BRASIL","latitud":-16.370642173468248,"longitud":-39.5602367037114,"ranking":0},
{"id":14719,"nombre":"Fatima","localidad":"Fátima","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9503,"nombre":"Filadelfia","localidad":"Filadélfia","provincia":"Bahia","pais":"BRASIL","latitud":-10.739960751745517,"longitud":-40.13084939579494,"ranking":0},
{"id":6395,"nombre":"Firmino Alves ","localidad":"Firmino Alves","provincia":"Bahia","pais":"BRASIL","latitud":-14.987391850457916,"longitud":-39.92310794390502,"ranking":0},
{"id":6397,"nombre":"Floresta Azul","localidad":"Floresta Azul","provincia":"Bahia","pais":"BRASIL","latitud":-14.858429130162484,"longitud":-39.658985438175215,"ranking":0},
{"id":4940,"nombre":"Formosa do Rio Preto","localidad":"Formosa do Rio Preto","provincia":"Bahia","pais":"BRASIL","latitud":-11.048001872140492,"longitud":-45.204961174390284,"ranking":0},
{"id":6401,"nombre":"Gandu","localidad":"Gandu","provincia":"Bahia","pais":"BRASIL","latitud":-13.74311,"longitud":-39.4836039,"ranking":0},
{"id":6406,"nombre":"Gaviao","localidad":"Gavião","provincia":"Bahia","pais":"BRASIL","latitud":-11.474533163207196,"longitud":-39.783225018690594,"ranking":0},
{"id":9958,"nombre":"Santo Inacio","localidad":"Gentio do Ouro","provincia":"Bahia","pais":"BRASIL","latitud":-11.43238780322598,"longitud":-42.50870991179823,"ranking":0},
{"id":10312,"nombre":"Gentio do Ouro","localidad":"Gentio do Ouro","provincia":"Bahia","pais":"BRASIL","latitud":-11.432405964437121,"longitud":-42.508706893397935,"ranking":0},
{"id":10361,"nombre":"Gongogi","localidad":"Gongogi","provincia":"Bahia","pais":"BRASIL","latitud":-14.323439560810527,"longitud":-39.46837797181114,"ranking":0},
{"id":6408,"nombre":"Governador Mangabeira ","localidad":"Governador Mangabeira","provincia":"Bahia","pais":"BRASIL","latitud":-12.5979129,"longitud":-39.0426515,"ranking":0},
{"id":6412,"nombre":"Guanambi","localidad":"Guanambi","provincia":"Bahia","pais":"BRASIL","latitud":-14.21232193039836,"longitud":-42.76483876276929,"ranking":0},
{"id":10574,"nombre":"Guaratinga","localidad":"Guaratinga","provincia":"Bahia","pais":"BRASIL","latitud":-16.583009094011157,"longitud":-39.78026395115575,"ranking":0},
{"id":14837,"nombre":"Heliopolis","localidad":"Heliopolis","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6413,"nombre":"Iacu","localidad":"Iaçu","provincia":"Bahia","pais":"BRASIL","latitud":-12.766917558781696,"longitud":-40.205505798700486,"ranking":0},
{"id":6415,"nombre":"Ibicarai","localidad":"Ibicaraí","provincia":"Bahia","pais":"BRASIL","latitud":-14.859946410367398,"longitud":-39.5848560288614,"ranking":0},
{"id":6416,"nombre":"Ibicoara","localidad":"Ibicoara","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6418,"nombre":"Ibicui","localidad":"Ibicuí","provincia":"Bahia","pais":"BRASIL","latitud":-14.842201215732388,"longitud":-39.98744447407617,"ranking":0},
{"id":6419,"nombre":"Ibipeba","localidad":"Ibipeba","provincia":"Bahia","pais":"BRASIL","latitud":-11.644389827521236,"longitud":-42.009177632052086,"ranking":0},
{"id":10313,"nombre":"Ibipitanga","localidad":"Ibipitanga","provincia":"Bahia","pais":"BRASIL","latitud":-12.874309622276455,"longitud":-42.478315096271224,"ranking":0},
{"id":10314,"nombre":"Ibiquera","localidad":"Ibiquera","provincia":"Bahia","pais":"BRASIL","latitud":-12.650566463492153,"longitud":-40.93413239123256,"ranking":0},
{"id":6420,"nombre":"Ibirapitanga","localidad":"Ibirapitanga","provincia":"Bahia","pais":"BRASIL","latitud":-14.160148537256825,"longitud":-39.36737591424075,"ranking":0},
{"id":10575,"nombre":"Ibirapua","localidad":"Ibirapuã","provincia":"Bahia","pais":"BRASIL","latitud":-17.68673708789981,"longitud":-40.10932310277113,"ranking":0},
{"id":6421,"nombre":"Ibirataia","localidad":"Ibirataia","provincia":"Bahia","pais":"BRASIL","latitud":-14.064613563356767,"longitud":-39.639490780161644,"ranking":0},
{"id":6285,"nombre":"Ibitiara","localidad":"Ibitiara","provincia":"Bahia","pais":"BRASIL","latitud":-12.652492351921413,"longitud":-42.21841055439964,"ranking":0},
{"id":6422,"nombre":"Ibitita","localidad":"Ibititá","provincia":"Bahia","pais":"BRASIL","latitud":-11.544501105108255,"longitud":-41.97886306702764,"ranking":0},
{"id":6312,"nombre":"Ibotirama","localidad":"Ibotirama","provincia":"Bahia","pais":"BRASIL","latitud":-12.18127484228063,"longitud":-43.20794763167844,"ranking":0},
{"id":14292,"nombre":"Ichu","localidad":"Ichu","provincia":"Bahia","pais":"BRASIL","latitud":-11.750185,"longitud":-39.1859397,"ranking":0},
{"id":6425,"nombre":"Igrapiuna","localidad":"Igrapiúna","provincia":"Bahia","pais":"BRASIL","latitud":-13.818979667109831,"longitud":-39.14270046264345,"ranking":0},
{"id":6427,"nombre":"Iguai","localidad":"Iguaí","provincia":"Bahia","pais":"BRASIL","latitud":-11.544502210023728,"longitud":-41.97886419475752,"ranking":0},
{"id":8675,"nombre":"Inhambupe ","localidad":"Inhambupe","provincia":"Bahia","pais":"BRASIL","latitud":-11.788590971298744,"longitud":-38.351289402846504,"ranking":0},
{"id":10315,"nombre":"Ipecaeta","localidad":"Ipecaetá","provincia":"Bahia","pais":"BRASIL","latitud":-12.315861320067352,"longitud":-39.307088778459715,"ranking":0},
{"id":6430,"nombre":"Ipiau","localidad":"Ipiaú","provincia":"Bahia","pais":"BRASIL","latitud":-14.141062417256396,"longitud":-39.7247484887528,"ranking":0},
{"id":6431,"nombre":"Ipira","localidad":"Ipirá","provincia":"Bahia","pais":"BRASIL","latitud":-12.159937517234981,"longitud":-39.74656540469378,"ranking":0},
{"id":10082,"nombre":"Ipupiara","localidad":"Ipupiara","provincia":"Bahia","pais":"BRASIL","latitud":-11.820438371766059,"longitud":-42.610117803281184,"ranking":0},
{"id":10362,"nombre":"Irajuba","localidad":"Irajuba","provincia":"Bahia","pais":"BRASIL","latitud":-13.251802048378797,"longitud":-40.084334662827274,"ranking":0},
{"id":6432,"nombre":"Iramaia","localidad":"Iramaia","provincia":"Bahia","pais":"BRASIL","latitud":-13.292114137074469,"longitud":-40.958604246506546,"ranking":0},
{"id":10083,"nombre":"Iraquara","localidad":"Iraquara","provincia":"Bahia","pais":"BRASIL","latitud":-12.245226774980917,"longitud":-41.614143575210356,"ranking":0},
{"id":10337,"nombre":"Irara","localidad":"Irará","provincia":"Bahia","pais":"BRASIL","latitud":-12.04578372947134,"longitud":-38.76494287154406,"ranking":0},
{"id":6286,"nombre":"Irece","localidad":"Irecê","provincia":"Bahia","pais":"BRASIL","latitud":-11.302763407701597,"longitud":-41.84841676812649,"ranking":0},
{"id":6436,"nombre":"Itabela","localidad":"Itabela","provincia":"Bahia","pais":"BRASIL","latitud":-16.57107207180474,"longitud":-39.56125330278782,"ranking":0},
{"id":9948,"nombre":"Montinho","localidad":"Itabela","provincia":"Bahia","pais":"BRASIL","latitud":-16.57112219955147,"longitud":-39.561252547408955,"ranking":0},
{"id":6287,"nombre":"Itaberaba","localidad":"Itaberaba","provincia":"Bahia","pais":"BRASIL","latitud":-12.531133905109654,"longitud":-40.30376387400285,"ranking":0},
{"id":6439,"nombre":"Itacare","localidad":"Itacaré","provincia":"Bahia","pais":"BRASIL","latitud":-14.281,"longitud":-38.9956,"ranking":39},
{"id":6440,"nombre":"Itaete","localidad":"Itaeté","provincia":"Bahia","pais":"BRASIL","latitud":-12.980170706741035,"longitud":-40.96640453331304,"ranking":0},
{"id":10363,"nombre":"Itagi","localidad":"Itagi","provincia":"Bahia","pais":"BRASIL","latitud":-14.161312472276183,"longitud":-40.00601853242079,"ranking":0},
{"id":10364,"nombre":"Itagiba","localidad":"Itagibá","provincia":"Bahia","pais":"BRASIL","latitud":-14.290602285645656,"longitud":-39.84537212288555,"ranking":0},
{"id":6446,"nombre":"itagimirim","localidad":"Itagimirim","provincia":"Bahia","pais":"BRASIL","latitud":-16.08748268196173,"longitud":-39.61663361605118,"ranking":0},
{"id":6442,"nombre":"Itaguacu da Bahia","localidad":"Itaguaçu da Bahia","provincia":"Bahia","pais":"BRASIL","latitud":-11.01150499530728,"longitud":-42.39905475480707,"ranking":0},
{"id":6448,"nombre":"Itajuipe","localidad":"Itajuípe","provincia":"Bahia","pais":"BRASIL","latitud":-14.675531954652893,"longitud":-39.37681572039059,"ranking":0},
{"id":6450,"nombre":"Itamaraju","localidad":"Itamaraju","provincia":"Bahia","pais":"BRASIL","latitud":-17.03355,"longitud":-39.532079,"ranking":0},
{"id":6451,"nombre":"Itambe","localidad":"Itambé","provincia":"Bahia","pais":"BRASIL","latitud":-15.245385465693294,"longitud":-40.62000086388086,"ranking":0},
{"id":6452,"nombre":"Itanhem","localidad":"Itanhém","provincia":"Bahia","pais":"BRASIL","latitud":-17.166584431786166,"longitud":-40.330427155584516,"ranking":0},
{"id":10164,"nombre":"Itaparica","localidad":"Itaparica","provincia":"Bahia","pais":"BRASIL","latitud":-12.899991487860895,"longitud":-38.67287671734775,"ranking":0},
{"id":10576,"nombre":"Itapebi","localidad":"Itapebi","provincia":"Bahia","pais":"BRASIL","latitud":-15.967532013538754,"longitud":-39.535110048329564,"ranking":0},
{"id":6453,"nombre":"Itapetinga","localidad":"Itapetinga","provincia":"Bahia","pais":"BRASIL","latitud":-15.2417778609805,"longitud":-40.235062184410516,"ranking":0},
{"id":10317,"nombre":"Itapicuru","localidad":"Itapicuru","provincia":"Bahia","pais":"BRASIL","latitud":-11.307253983576269,"longitud":-38.21934570213698,"ranking":0},
{"id":6454,"nombre":"Itaquara","localidad":"Itaquara","provincia":"Bahia","pais":"BRASIL","latitud":-13.45238204581236,"longitud":-39.938588073992356,"ranking":0},
{"id":8666,"nombre":"Itarantim","localidad":"Itarantim","provincia":"Bahia","pais":"BRASIL","latitud":-15.652112245825197,"longitud":-40.05788326119834,"ranking":0},
{"id":6455,"nombre":"Itatim","localidad":"Itatim","provincia":"Bahia","pais":"BRASIL","latitud":-12.706276319617961,"longitud":-39.697279759344035,"ranking":0},
{"id":10084,"nombre":"Itirucu","localidad":"Itiruçu","provincia":"Bahia","pais":"BRASIL","latitud":-13.539832183972322,"longitud":-40.1484729009761,"ranking":0},
{"id":14718,"nombre":"Itiuba","localidad":"Itiúba","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6458,"nombre":"Itororo","localidad":"Itororó","provincia":"Bahia","pais":"BRASIL","latitud":-15.120219990404197,"longitud":-40.05950074798125,"ranking":0},
{"id":10085,"nombre":"Ituacu","localidad":"Ituaçu","provincia":"Bahia","pais":"BRASIL","latitud":-13.813125129672482,"longitud":-41.298426691069466,"ranking":0},
{"id":6459,"nombre":"Itubera","localidad":"Ituberá","provincia":"Bahia","pais":"BRASIL","latitud":-13.731228912739434,"longitud":-39.14511303166086,"ranking":0},
{"id":14850,"nombre":"Jacaraci","localidad":"Jacaraci","provincia":"Bahia","pais":"BRASIL","latitud":-14.846850209687984,"longitud":-42.433162874041194,"ranking":0},
{"id":6313,"nombre":"Jacobina","localidad":"Jacobina","provincia":"Bahia","pais":"BRASIL","latitud":-11.18450926063195,"longitud":-40.521021823920485,"ranking":0},
{"id":9941,"nombre":"Catuaba","localidad":"Jacobina","provincia":"Bahia","pais":"BRASIL","latitud":-11.184703800514662,"longitud":-40.52074220002068,"ranking":0},
{"id":6461,"nombre":"Jaguaquara","localidad":"Jaguaquara","provincia":"Bahia","pais":"BRASIL","latitud":-13.526210886353276,"longitud":-39.971388864540906,"ranking":0},
{"id":9505,"nombre":"Jaguarari","localidad":"Jaguarari","provincia":"Bahia","pais":"BRASIL","latitud":-10.256354651877388,"longitud":-40.19261636572179,"ranking":0},
{"id":10340,"nombre":"Jaguaripe","localidad":"Jaguaripe","provincia":"Bahia","pais":"BRASIL","latitud":-13.115302551449497,"longitud":-38.89709117386529,"ranking":0},
{"id":14844,"nombre":"Jandaira","localidad":"Jandaíra","provincia":"Bahia","pais":"BRASIL","latitud":-15.038611100358048,"longitud":-41.935246895666886,"ranking":0},
{"id":4960,"nombre":"Jequie","localidad":"Jequié","provincia":"Bahia","pais":"BRASIL","latitud":-13.861233102872111,"longitud":-40.089064414451805,"ranking":0},
{"id":4961,"nombre":"Jeremoabo","localidad":"Jeremoabo","provincia":"Bahia","pais":"BRASIL","latitud":-10.076464666571283,"longitud":-38.34698458861399,"ranking":0},
{"id":6465,"nombre":"Jiquirica","localidad":"Jiquiriçá","provincia":"Bahia","pais":"BRASIL","latitud":-13.253949392435418,"longitud":-39.571976507911465,"ranking":0},
{"id":10365,"nombre":"Jitauna","localidad":"Jitaúna","provincia":"Bahia","pais":"BRASIL","latitud":-14.013529574910693,"longitud":-39.89205470325315,"ranking":0},
{"id":6467,"nombre":"Joao Dourado","localidad":"João Dourado","provincia":"Bahia","pais":"BRASIL","latitud":-11.343681875962195,"longitud":-41.66415326268886,"ranking":0},
{"id":6314,"nombre":"Juazeiro","localidad":"Juazeiro","provincia":"Bahia","pais":"BRASIL","latitud":-9.43731,"longitud":-40.49687,"ranking":1},
{"id":10577,"nombre":"Jucurucu","localidad":"Jucuruçu","provincia":"Bahia","pais":"BRASIL","latitud":-16.843355416403032,"longitud":-40.159698172854704,"ranking":0},
{"id":10318,"nombre":"Jussara","localidad":"Jussara","provincia":"Bahia","pais":"BRASIL","latitud":-11.041268572595897,"longitud":-41.96920472078541,"ranking":0},
{"id":10319,"nombre":"Jussiape","localidad":"Jussiape","provincia":"Bahia","pais":"BRASIL","latitud":-13.517961746587602,"longitud":-41.592377254652405,"ranking":0},
{"id":10366,"nombre":"Lafaiete Coutinho","localidad":"Lafaiete Coutinho","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6469,"nombre":"Laje","localidad":"Laje","provincia":"Bahia","pais":"BRASIL","latitud":-13.180389190719092,"longitud":-39.41713690283134,"ranking":0},
{"id":6470,"nombre":"Lajedao","localidad":"Lajedão","provincia":"Bahia","pais":"BRASIL","latitud":-17.614257499436995,"longitud":-40.34321699575138,"ranking":0},
{"id":6471,"nombre":"Lajedinho","localidad":"Lajedinho","provincia":"Bahia","pais":"BRASIL","latitud":-12.355508595956262,"longitud":-40.903861769140924,"ranking":0},
{"id":10086,"nombre":"Lajedo do Tabocal","localidad":"Lajedo do Tabocal","provincia":"Bahia","pais":"BRASIL","latitud":-13.473874706689502,"longitud":-40.22134547978397,"ranking":0},
{"id":14293,"nombre":"Lamarao","localidad":"Lamarão","provincia":"Bahia","pais":"BRASIL","latitud":-11.7923143,"longitud":-38.8852407,"ranking":0},
{"id":6472,"nombre":"Lapao","localidad":"Lapão","provincia":"Bahia","pais":"BRASIL","latitud":-11.382491133107377,"longitud":-41.83428536238384,"ranking":0},
{"id":6289,"nombre":"Lencois","localidad":"Lençóis","provincia":"Bahia","pais":"BRASIL","latitud":-12.56044,"longitud":-41.39084,"ranking":17},
{"id":14892,"nombre":"Licinio de Almeida","localidad":"Licínio de Almeida","provincia":"Bahia","pais":"BRASIL","latitud":-14.680557835231296,"longitud":-42.511335776796145,"ranking":0},
{"id":9075,"nombre":"Livramento","localidad":"Livramento de Nossa Senhora","provincia":"Bahia","pais":"BRASIL","latitud":-13.65675769808109,"longitud":-41.84860728509393,"ranking":0},
{"id":9942,"nombre":"Livramento Do Brumado","localidad":"Livramento de Nossa Senhora","provincia":"Bahia","pais":"BRASIL","latitud":-13.656873445198018,"longitud":-41.84847026877931,"ranking":0},
{"id":10320,"nombre":"Livramento de Nossa Senhora","localidad":"Livramento de Nossa Senhora","provincia":"Bahia","pais":"BRASIL","latitud":-13.65687760131219,"longitud":-41.8484786532305,"ranking":0},
{"id":6290,"nombre":"Luis Eduardo Magalhaes","localidad":"Luís Eduardo Magalhães","provincia":"Bahia","pais":"BRASIL","latitud":-12.0965,"longitud":-45.8109,"ranking":0},
{"id":6473,"nombre":"Macajuba","localidad":"Macajuba","provincia":"Bahia","pais":"BRASIL","latitud":-12.13708445669711,"longitud":-40.36003564148591,"ranking":0},
{"id":8667,"nombre":"Macarani ","localidad":"Macarani","provincia":"Bahia","pais":"BRASIL","latitud":-15.56418576604931,"longitud":-40.42637354026879,"ranking":0},
{"id":6291,"nombre":"Macaubas","localidad":"Macaúbas","provincia":"Bahia","pais":"BRASIL","latitud":-13.01376622777116,"longitud":-42.686809878760364,"ranking":0},
{"id":9861,"nombre":"Madre de Deus","localidad":"Madre de Deus","provincia":"Bahia","pais":"BRASIL","latitud":-12.665546763474923,"longitud":-38.532328441437,"ranking":0},
{"id":8668,"nombre":"Maiquinique","localidad":"Maiquinique","provincia":"Bahia","pais":"BRASIL","latitud":-15.617331322187379,"longitud":-40.26274254968996,"ranking":0},
{"id":6474,"nombre":"Mairi","localidad":"Mairi","provincia":"Bahia","pais":"BRASIL","latitud":-11.709214274982152,"longitud":-40.14821880284722,"ranking":0},
{"id":6476,"nombre":"Manoel Vitorino","localidad":"Manoel Vitorino","provincia":"Bahia","pais":"BRASIL","latitud":-14.14545612058631,"longitud":-40.243513125752,"ranking":0},
{"id":10321,"nombre":"Mansidao","localidad":"Mansidão","provincia":"Bahia","pais":"BRASIL","latitud":-10.719063818369321,"longitud":-44.036115680311816,"ranking":0},
{"id":10087,"nombre":"Maracas","localidad":"Maracás","provincia":"Bahia","pais":"BRASIL","latitud":-13.438117488829093,"longitud":-40.43359212550923,"ranking":0},
{"id":10352,"nombre":"Maragogipe","localidad":"Maragogipe","provincia":"Bahia","pais":"BRASIL","latitud":-12.778652144844312,"longitud":-38.91905084566284,"ranking":0},
{"id":15262,"nombre":"Coqueiros","localidad":"Maragogipe","provincia":"Bahia","pais":"BRASIL","latitud":-12.712769686566714,"longitud":-38.93947355614811,"ranking":0},
{"id":6477,"nombre":"Marcionilio Souza","localidad":"Marcionílio Souza","provincia":"Bahia","pais":"BRASIL","latitud":-13.002806125978202,"longitud":-40.533606245331214,"ranking":0},
{"id":9962,"nombre":"Teixeira do Progresso","localidad":"Mascote","provincia":"Bahia","pais":"BRASIL","latitud":-15.722493956739893,"longitud":-39.45894859409611,"ranking":0},
{"id":9966,"nombre":"Sao Joao do Paraiso","localidad":"Mascote","provincia":"Bahia","pais":"BRASIL","latitud":-15.606328230281312,"longitud":-39.43263275126792,"ranking":0},
{"id":6479,"nombre":"Medeiros Neto","localidad":"Medeiros Neto","provincia":"Bahia","pais":"BRASIL","latitud":-17.3817151,"longitud":-40.2235665,"ranking":0},
{"id":9506,"nombre":"Miguel Calmon","localidad":"Miguel Calmon","provincia":"Bahia","pais":"BRASIL","latitud":-11.425798829437584,"longitud":-40.59306431201853,"ranking":0},
{"id":6480,"nombre":"Milagres","localidad":"Milagres","provincia":"Bahia","pais":"BRASIL","latitud":-12.878571919347598,"longitud":-39.8573674181783,"ranking":0},
{"id":14851,"nombre":"Monte Santo","localidad":"Monte Santo","provincia":"Bahia","pais":"BRASIL","latitud":-10.440391746236655,"longitud":-39.32388309890078,"ranking":0},
{"id":10322,"nombre":"Morpara","localidad":"Morpará","provincia":"Bahia","pais":"BRASIL","latitud":-11.5596955390767,"longitud":-43.28040060320078,"ranking":0},
{"id":6316,"nombre":"Morro do Chapeu","localidad":"Morro do Chapéu","provincia":"Bahia","pais":"BRASIL","latitud":-11.548696688978183,"longitud":-41.159300026469616,"ranking":0},
{"id":9947,"nombre":"Mira Serra","localidad":"Morro do Chapéu","provincia":"Bahia","pais":"BRASIL","latitud":-11.548697888357632,"longitud":-41.159303675639684,"ranking":0},
{"id":14852,"nombre":"Mortugaba","localidad":"Mortugaba","provincia":"Bahia","pais":"BRASIL","latitud":-15.026535536778136,"longitud":-42.37042423378737,"ranking":0},
{"id":6482,"nombre":"Mucuge","localidad":"Mucugê","provincia":"Bahia","pais":"BRASIL","latitud":-13.00910566599678,"longitud":-41.371124979678925,"ranking":0},
{"id":6488,"nombre":"Mucuri","localidad":"Mucuri","provincia":"Bahia","pais":"BRASIL","latitud":-18.078684906656367,"longitud":-39.547038503290736,"ranking":0},
{"id":6489,"nombre":"Mulungu do Morro","localidad":"Mulungu do Morro","provincia":"Bahia","pais":"BRASIL","latitud":-11.964993680690574,"longitud":-41.63871966841241,"ranking":0},
{"id":6491,"nombre":"Mundo Novo","localidad":"Mundo Novo","provincia":"Bahia","pais":"BRASIL","latitud":-11.864516898850624,"longitud":-40.47068029770808,"ranking":0},
{"id":10341,"nombre":"Muniz Ferreira","localidad":"Muniz Ferreira","provincia":"Bahia","pais":"BRASIL","latitud":-12.99861843382667,"longitud":-39.112471867147356,"ranking":0},
{"id":10323,"nombre":"Muquem de Sao Francisco","localidad":"Muquém de São Francisco","provincia":"Bahia","pais":"BRASIL","latitud":-12.06700819770492,"longitud":-43.54652942955237,"ranking":0},
{"id":10351,"nombre":"Muritiba","localidad":"Muritiba","provincia":"Bahia","pais":"BRASIL","latitud":-12.605728695263071,"longitud":-39.01426886378309,"ranking":0},
{"id":6492,"nombre":"Mutuipe","localidad":"Mutuípe","provincia":"Bahia","pais":"BRASIL","latitud":-13.226843822659426,"longitud":-39.50661418714708,"ranking":0},
{"id":6494,"nombre":"Nazare ","localidad":"Nazaré","provincia":"Bahia","pais":"BRASIL","latitud":-13.034245552532049,"longitud":-39.0204462149598,"ranking":0},
{"id":6497,"nombre":"Nilo Pecanha","localidad":"Nilo Peçanha","provincia":"Bahia","pais":"BRASIL","latitud":-13.606934601752886,"longitud":-39.10214802442874,"ranking":0},
{"id":6498,"nombre":"Nova Canaa","localidad":"Nova Canaã","provincia":"Bahia","pais":"BRASIL","latitud":-14.792099518261018,"longitud":-40.14763212699894,"ranking":0},
{"id":10324,"nombre":"Nova Fatima","localidad":"Nova Fátima","provincia":"Bahia","pais":"BRASIL","latitud":-11.60062084669919,"longitud":-39.636425327869155,"ranking":0},
{"id":10367,"nombre":"Nova Itarana","localidad":"Nova Itarana","provincia":"Bahia","pais":"BRASIL","latitud":-13.02623190082041,"longitud":-40.066553620021466,"ranking":0},
{"id":10325,"nombre":"Nova Redencao","localidad":"Nova Redenção","provincia":"Bahia","pais":"BRASIL","latitud":-12.818587138751884,"longitud":-41.06864845433598,"ranking":0},
{"id":10326,"nombre":"Nova Soure","localidad":"Nova Soure","provincia":"Bahia","pais":"BRASIL","latitud":-11.24051721108871,"longitud":-38.479279556097914,"ranking":0},
{"id":6499,"nombre":"Nova Vicosa","localidad":"Nova Viçosa","provincia":"Bahia","pais":"BRASIL","latitud":-17.898256218006686,"longitud":-39.37168031697466,"ranking":0},
{"id":10088,"nombre":"Novo Horizonte","localidad":"Novo Horizonte","provincia":"Bahia","pais":"BRASIL","latitud":-12.810253511323197,"longitud":-42.16845158175,"ranking":0},
{"id":14888,"nombre":"Novo Triunfo","localidad":"Novo Triunfo","provincia":"Bahia","pais":"BRASIL","latitud":-10.337315692003878,"longitud":-38.41938901150174,"ranking":0},
{"id":10168,"nombre":"Oliveira dos Brejinhos","localidad":"Oliveira dos Brejinhos","provincia":"Bahia","pais":"BRASIL","latitud":-12.319917491966958,"longitud":-42.88606255586104,"ranking":0},
{"id":10346,"nombre":"Ouricangas","localidad":"Ouriçangas","provincia":"Bahia","pais":"BRASIL","latitud":-12.003643478692425,"longitud":-38.621018095090015,"ranking":0},
{"id":14893,"nombre":"Palmas de Monte Alto","localidad":"Palmas de Monte Alto","provincia":"Bahia","pais":"BRASIL","latitud":-14.267177818896126,"longitud":-43.1634505957055,"ranking":0},
{"id":6293,"nombre":"Palmeiras","localidad":"Palmeiras","provincia":"Bahia","pais":"BRASIL","latitud":-25.4225218,"longitud":-49.9955446,"ranking":1},
{"id":6506,"nombre":"Paramirim","localidad":"Paramirim","provincia":"Bahia","pais":"BRASIL","latitud":-13.45590999915994,"longitud":-42.24205132380626,"ranking":0},
{"id":14839,"nombre":"Paripiranga","localidad":"Paripiranga","provincia":"Bahia","pais":"BRASIL","latitud":-10.683733781469792,"longitud":-37.86100711362766,"ranking":0},
{"id":4990,"nombre":"Paulo Afonso","localidad":"Paulo Afonso","provincia":"Bahia","pais":"BRASIL","latitud":-9.398833905963846,"longitud":-38.23191805294043,"ranking":0},
{"id":10089,"nombre":"Pe de Serra","localidad":"Pé de Serra","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10327,"nombre":"Piata","localidad":"Piatã","provincia":"Bahia","pais":"BRASIL","latitud":-13.150821329207902,"longitud":-41.77538491057166,"ranking":0},
{"id":14894,"nombre":"Pilao Arcado","localidad":"Pilão Arcado","provincia":"Bahia","pais":"BRASIL","latitud":-10.0634033,"longitud":-42.44185359999999,"ranking":0},
{"id":14853,"nombre":"Pindai","localidad":"Pindaí","provincia":"Bahia","pais":"BRASIL","latitud":-14.491638230222353,"longitud":-42.69097844114335,"ranking":0},
{"id":10297,"nombre":"Pindobacu","localidad":"Pindobaçu","provincia":"Bahia","pais":"BRASIL","latitud":-10.74297568163872,"longitud":-40.361621957856315,"ranking":0},
{"id":10090,"nombre":"Pintadas","localidad":"Pintadas","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9950,"nombre":"Piraí do Norte","localidad":"Piraí do Norte","provincia":"Bahia","pais":"BRASIL","latitud":-13.759356054024439,"longitud":-39.37587742781604,"ranking":0},
{"id":10368,"nombre":"Piripa","localidad":"Piripá","provincia":"Bahia","pais":"BRASIL","latitud":-14.94373927597883,"longitud":-41.715357262937474,"ranking":0},
{"id":6508,"nombre":"Piritiba","localidad":"Piritiba","provincia":"Bahia","pais":"BRASIL","latitud":-11.7336882089072,"longitud":-40.5597312294159,"ranking":0},
{"id":10369,"nombre":"Planaltino","localidad":"Planaltino","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6510,"nombre":"Planalto","localidad":"Planalto","provincia":"Bahia","pais":"BRASIL","latitud":-14.67008018560575,"longitud":-40.47476947892933,"ranking":0},
{"id":4991,"nombre":"Pocoes","localidad":"Poções","provincia":"Bahia","pais":"BRASIL","latitud":-14.52449795984238,"longitud":-40.376594915524095,"ranking":0},
{"id":9509,"nombre":"Ponto Novo","localidad":"Ponto Novo","provincia":"Bahia","pais":"BRASIL","latitud":-10.864470864636882,"longitud":-40.127775428293106,"ranking":0},
{"id":8669,"nombre":"Potiragua","localidad":"Potiraguá","provincia":"Bahia","pais":"BRASIL","latitud":-15.595616795403282,"longitud":-39.87111129712169,"ranking":0},
{"id":6520,"nombre":"Prado","localidad":"Prado","provincia":"Bahia","pais":"BRASIL","latitud":-17.340157808466238,"longitud":-39.22620402197424,"ranking":0},
{"id":10328,"nombre":"Presidente Dutra","localidad":"Presidente Dutra","provincia":"Bahia","pais":"BRASIL","latitud":-11.294836288860544,"longitud":-41.98732130178874,"ranking":0},
{"id":6523,"nombre":"Presidente Tancredo Neves","localidad":"Presidente Tancredo Neves","provincia":"Bahia","pais":"BRASIL","latitud":-13.452504658375576,"longitud":-39.42081341704647,"ranking":0},
{"id":6528,"nombre":"Queimadas","localidad":"Queimadas","provincia":"Bahia","pais":"BRASIL","latitud":-10.978575946347371,"longitud":-39.627278146459965,"ranking":0},
{"id":9862,"nombre":"Quijingue","localidad":"Quijingue","provincia":"Bahia","pais":"BRASIL","latitud":-10.752998121920216,"longitud":-39.20738060918846,"ranking":0},
{"id":14855,"nombre":"Quixabeira","localidad":"Quixabeira","provincia":"Bahia","pais":"BRASIL","latitud":-11.410901734355182,"longitud":-40.12783586450743,"ranking":0},
{"id":9936,"nombre":"ARGOIM","localidad":"Rafael Jambeiro","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10370,"nombre":"Rafael Jambeiro","localidad":"Rafael Jambeiro","provincia":"Bahia","pais":"BRASIL","latitud":-12.407770101008733,"longitud":-39.501056154847184,"ranking":0},
{"id":5001,"nombre":"Remanso","localidad":"Remanso","provincia":"Bahia","pais":"BRASIL","latitud":-9.626161224888875,"longitud":-42.08705182184396,"ranking":0},
{"id":6297,"nombre":"Riachao das Neves ","localidad":"Riachão das Neves","provincia":"Bahia","pais":"BRASIL","latitud":-11.753035736693647,"longitud":-44.913859290602765,"ranking":0},
{"id":5000,"nombre":"Riachao do Jacuipe","localidad":"Riachão do Jacuípe","provincia":"Bahia","pais":"BRASIL","latitud":-11.814242456619318,"longitud":-39.37903642852508,"ranking":0},
{"id":14582,"nombre":"Riacho de Santana","localidad":"Riacho de Santana","provincia":"Bahia","pais":"BRASIL","latitud":-13.6110997,"longitud":-42.9340382,"ranking":0},
{"id":6590,"nombre":"Ribeira do Pombal","localidad":"Ribeira do Pombal","provincia":"Bahia","pais":"BRASIL","latitud":-10.84192750570379,"longitud":-38.53198153298536,"ranking":0},
{"id":10329,"nombre":"Rio de Contas","localidad":"Rio de Contas","provincia":"Bahia","pais":"BRASIL","latitud":-13.580746251057548,"longitud":-41.81277736562836,"ranking":0},
{"id":14841,"nombre":"Rio Real","localidad":"Rio Real","provincia":"Bahia","pais":"BRASIL","latitud":-11.483021688147568,"longitud":-37.93106205299826,"ranking":0},
{"id":6545,"nombre":"Ruy Barbosa","localidad":"Ruy Barbosa","provincia":"Bahia","pais":"BRASIL","latitud":-12.280526844779299,"longitud":-40.49606793867721,"ranking":0},
{"id":10338,"nombre":"Salinas da Margarida","localidad":"Salinas da Margarida","provincia":"Bahia","pais":"BRASIL","latitud":-12.875356474559682,"longitud":-38.75973011127254,"ranking":0},
{"id":10143,"nombre":"Santa Barbara","localidad":"Santa Bárbara","provincia":"Bahia","pais":"BRASIL","latitud":-11.948441744626361,"longitud":-38.97766901820273,"ranking":0},
{"id":10330,"nombre":"Santa Cruz Cabralia","localidad":"Santa Cruz Cabrália","provincia":"Bahia","pais":"BRASIL","latitud":-16.284636039221308,"longitud":-39.02357283988991,"ranking":0},
{"id":9954,"nombre":"Santa Cruz da Vitória","localidad":"Santa Cruz da Vitória","provincia":"Bahia","pais":"BRASIL","latitud":-14.960164863641886,"longitud":-39.81039460280969,"ranking":0},
{"id":6557,"nombre":"Santa Ines","localidad":"Santa Inês","provincia":"Bahia","pais":"BRASIL","latitud":-13.290632109192213,"longitud":-39.82087672234828,"ranking":0},
{"id":14294,"nombre":"Santaluz","localidad":"Santaluz","provincia":"Bahia","pais":"BRASIL","latitud":-11.2541895,"longitud":-39.3750664,"ranking":0},
{"id":10161,"nombre":"Santa Maria da Vitoria","localidad":"Santa Maria da Vitória","provincia":"Bahia","pais":"BRASIL","latitud":-13.387474734554475,"longitud":-44.20584158707464,"ranking":0},
{"id":10091,"nombre":"Santana","localidad":"Santana","provincia":"Bahia","pais":"BRASIL","latitud":-12.987145281581371,"longitud":-44.037696843577876,"ranking":0},
{"id":6298,"nombre":"Santa Rita de Cassia","localidad":"Santa Rita de Cássia","provincia":"Bahia","pais":"BRASIL","latitud":-11.004520797777678,"longitud":-44.521553628995576,"ranking":0},
{"id":10144,"nombre":"Santa Teresinha","localidad":"Santa Teresinha","provincia":"Bahia","pais":"BRASIL","latitud":-12.769842459492654,"longitud":-39.524064635880116,"ranking":0},
{"id":9863,"nombre":"Santo Amaro","localidad":"Santo Amaro","provincia":"Bahia","pais":"BRASIL","latitud":-12.555280525398215,"longitud":-38.70311104389507,"ranking":0},
{"id":6558,"nombre":"Santo Antonio de Jesus","localidad":"Santo Antônio de Jesus","provincia":"Bahia","pais":"BRASIL","latitud":-12.9797287,"longitud":-39.2809255,"ranking":0},
{"id":6559,"nombre":"Santo Estevao ","localidad":"Santo Estêvão","provincia":"Bahia","pais":"BRASIL","latitud":-12.443721069138919,"longitud":-39.24357990011171,"ranking":0},
{"id":10331,"nombre":"Sao Desiderio","localidad":"São Desidério","provincia":"Bahia","pais":"BRASIL","latitud":-12.36338860368195,"longitud":-44.9749245244795,"ranking":0},
{"id":9864,"nombre":"Sao Felix","localidad":"São Félix","provincia":"Bahia","pais":"BRASIL","latitud":-12.605657162596131,"longitud":-38.9693434250998,"ranking":0},
{"id":10332,"nombre":"Sao Felix do Coribe","localidad":"São Félix do Coribe","provincia":"Bahia","pais":"BRASIL","latitud":-13.406901226790396,"longitud":-44.18639839959922,"ranking":0},
{"id":12136,"nombre":"Sao Felipe","localidad":"São Felipe","provincia":"Bahia","pais":"BRASIL","latitud":-12.8472346,"longitud":-39.0885186,"ranking":0},
{"id":10145,"nombre":"Sao Francisco do Conde","localidad":"São Francisco do Conde","provincia":"Bahia","pais":"BRASIL","latitud":-12.627030439149863,"longitud":-38.681593060635535,"ranking":0},
{"id":10333,"nombre":"Sao Gabriel","localidad":"São Gabriel","provincia":"Bahia","pais":"BRASIL","latitud":-11.231442827147813,"longitud":-41.8861961479204,"ranking":0},
{"id":10146,"nombre":"Sao Goncalo dos Campos","localidad":"São Gonçalo dos Campos","provincia":"Bahia","pais":"BRASIL","latitud":-12.436715628747523,"longitud":-38.948635087496115,"ranking":0},
{"id":9965,"nombre":"Sao Jose da Vitoria","localidad":"São José da Vitória","provincia":"Bahia","pais":"BRASIL","latitud":-15.079974535242954,"longitud":-39.33924562782239,"ranking":0},
{"id":9964,"nombre":"Sao Jose do Jacuipe","localidad":"São José do Jacuípe","provincia":"Bahia","pais":"BRASIL","latitud":-11.50263255290471,"longitud":-40.02423929659066,"ranking":0},
{"id":10343,"nombre":"Sao Miguel das Matas","localidad":"São Miguel das Matas","provincia":"Bahia","pais":"BRASIL","latitud":-13.045969100827062,"longitud":-39.460232594374084,"ranking":0},
{"id":6552,"nombre":"Sapeacu","localidad":"Sapeaçu","provincia":"Bahia","pais":"BRASIL","latitud":-12.73140297991977,"longitud":-39.18700263408832,"ranking":0},
{"id":14295,"nombre":"Satiro Dias","localidad":"Sátiro Dias","provincia":"Bahia","pais":"BRASIL","latitud":-11.6052143,"longitud":-38.5817036,"ranking":0},
{"id":9865,"nombre":"Saubara","localidad":"Saubara","provincia":"Bahia","pais":"BRASIL","latitud":-12.73967539156326,"longitud":-38.76649820768691,"ranking":0},
{"id":10296,"nombre":"Saude","localidad":"Saúde","provincia":"Bahia","pais":"BRASIL","latitud":-10.94590448454763,"longitud":-40.41962779706549,"ranking":0},
{"id":6304,"nombre":"Seabra","localidad":"Seabra","provincia":"Bahia","pais":"BRASIL","latitud":-12.429691788385668,"longitud":-41.771273391436125,"ranking":0},
{"id":10334,"nombre":"Serra Dourada","localidad":"Serra Dourada","provincia":"Bahia","pais":"BRASIL","latitud":-12.753124737293104,"longitud":-43.95060364615528,"ranking":0},
{"id":6555,"nombre":"Serra Preta","localidad":"Serra Preta","provincia":"Bahia","pais":"BRASIL","latitud":-12.159423598525409,"longitud":-39.333375392114874,"ranking":0},
{"id":6592,"nombre":"Serrinha","localidad":"Serrinha","provincia":"Bahia","pais":"BRASIL","latitud":-11.6699239,"longitud":-39.0048814,"ranking":0},
{"id":6556,"nombre":"Serrolandia ","localidad":"Serrolândia","provincia":"Bahia","pais":"BRASIL","latitud":-11.417754159364748,"longitud":-40.29091256945664,"ranking":0},
{"id":9867,"nombre":"Simoes Filho","localidad":"Simões Filho","provincia":"Bahia","pais":"BRASIL","latitud":-12.788009190814698,"longitud":-38.40531692026298,"ranking":0},
{"id":14840,"nombre":"Sitio do Quinto","localidad":"Sítio do Quinto","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6320,"nombre":"Souto Soares","localidad":"Souto Soares","provincia":"Bahia","pais":"BRASIL","latitud":-12.088390633723556,"longitud":-41.64814547715497,"ranking":0},
{"id":10335,"nombre":"Tabocas do Brejo Velho","localidad":"Tabocas do Brejo Velho","provincia":"Bahia","pais":"BRASIL","latitud":-12.704446954125025,"longitud":-44.00849898554549,"ranking":0},
{"id":10092,"nombre":"Tanhacu","localidad":"Tanhaçu","provincia":"Bahia","pais":"BRASIL","latitud":-14.019382639731367,"longitud":-41.24698113239983,"ranking":0},
{"id":14296,"nombre":"Tanquinho","localidad":"Tanquinho","provincia":"Bahia","pais":"BRASIL","latitud":-11.9780121,"longitud":-39.1100939,"ranking":0},
{"id":6560,"nombre":"Taperoa","localidad":"Taperoá","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6561,"nombre":"Tapiramuta","localidad":"Tapiramutá","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9866,"nombre":"Teodoro Sampaio","localidad":"Teodoro Sampaio","provincia":"Bahia","pais":"BRASIL","latitud":-12.307499216304377,"longitud":-38.63703072787977,"ranking":0},
{"id":9953,"nombre":"Rumo","localidad":"Teodoro Sampaio","provincia":"Bahia","pais":"BRASIL","latitud":-12.17453889432307,"longitud":-38.6483788655811,"ranking":0},
{"id":10147,"nombre":"Teofilandia","localidad":"Teofilândia","provincia":"Bahia","pais":"BRASIL","latitud":-11.488541497766176,"longitud":-38.99760680510921,"ranking":0},
{"id":6563,"nombre":"Teolandia","localidad":"Teolândia","provincia":"Bahia","pais":"BRASIL","latitud":-13.602086177690323,"longitud":-39.4923534136565,"ranking":0},
{"id":10350,"nombre":"Terra Nova","localidad":"Terra Nova","provincia":"Bahia","pais":"BRASIL","latitud":-12.409173331249143,"longitud":-38.616311137548394,"ranking":0},
{"id":10371,"nombre":"Tremedal","localidad":"Tremedal","provincia":"Bahia","pais":"BRASIL","latitud":-14.971127299857214,"longitud":-41.401455602188186,"ranking":0},
{"id":6594,"nombre":"Tucano","localidad":"Tucano","provincia":"Bahia","pais":"BRASIL","latitud":-10.96153486964439,"longitud":-38.788676115639284,"ranking":0},
{"id":9859,"nombre":"Caldas do Jorro","localidad":"Tucano","provincia":"Bahia","pais":"BRASIL","latitud":-11.035112555258703,"longitud":-38.79685403945747,"ranking":0},
{"id":9510,"nombre":"UAUA","localidad":"Uauá","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6564,"nombre":"Ubaira","localidad":"Ubaíra","provincia":"Bahia","pais":"BRASIL","latitud":-13.262938857869441,"longitud":-39.661911938166334,"ranking":0},
{"id":6565,"nombre":"Ubaitaba","localidad":"Ubaitaba","provincia":"Bahia","pais":"BRASIL","latitud":-14.308717929939107,"longitud":-39.32313346048981,"ranking":1},
{"id":6566,"nombre":"Ubata","localidad":"Ubatã","provincia":"Bahia","pais":"BRASIL","latitud":-14.211933377719765,"longitud":-39.51855076063332,"ranking":0},
{"id":10336,"nombre":"Uibai","localidad":"Uibaí","provincia":"Bahia","pais":"BRASIL","latitud":-11.334767457211289,"longitud":-42.131754458228315,"ranking":0},
{"id":6568,"nombre":"Umburanas ","localidad":"Umburanas","provincia":"Bahia","pais":"BRASIL","latitud":-10.72318868759453,"longitud":-41.31718752675216,"ranking":0},
{"id":6569,"nombre":"Una","localidad":"Una","provincia":"Bahia","pais":"BRASIL","latitud":-15.294330960096293,"longitud":-39.07249778503491,"ranking":0},
{"id":14854,"nombre":"Urandi","localidad":"Urandi","provincia":"Bahia","pais":"BRASIL","latitud":-14.7678438345308,"longitud":-42.66105874200082,"ranking":0},
{"id":6570,"nombre":"Urucuca","localidad":"Uruçuca","provincia":"Bahia","pais":"BRASIL","latitud":-14.593353235654813,"longitud":-39.27875011324675,"ranking":0},
{"id":6571,"nombre":"Utinga ","localidad":"Utinga","provincia":"Bahia","pais":"BRASIL","latitud":-12.077438329729524,"longitud":-41.09352051818708,"ranking":0},
{"id":9952,"nombre":"Riachao do Utinga","localidad":"Utinga","provincia":"Bahia","pais":"BRASIL","latitud":-12.03992333718652,"longitud":-41.14325282830931,"ranking":0},
{"id":6572,"nombre":"Valenca","localidad":"Valença","provincia":"Bahia","pais":"BRASIL","latitud":-13.3759559,"longitud":-39.076738,"ranking":22},
{"id":6581,"nombre":"Varzea da Roca","localidad":"Várzea da Roça","provincia":"Bahia","pais":"BRASIL","latitud":-11.607512814246183,"longitud":-40.137356796069774,"ranking":0},
{"id":6580,"nombre":"Varzea do Poco","localidad":"Várzea do Poço","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6321,"nombre":"Varzea Nova","localidad":"Várzea Nova","provincia":"Bahia","pais":"BRASIL","latitud":-11.255229535360163,"longitud":-40.9429695424977,"ranking":0},
{"id":10342,"nombre":"Varzedo","localidad":"Varzedo","provincia":"Bahia","pais":"BRASIL","latitud":-12.977288543003336,"longitud":-39.39481704389335,"ranking":0},
{"id":6583,"nombre":"Wagner","localidad":"Wagner","provincia":"Bahia","pais":"BRASIL","latitud":-12.227574692084351,"longitud":-41.192412218942465,"ranking":0},
{"id":10093,"nombre":"Wanderley","localidad":"Wanderley","provincia":"Bahia","pais":"BRASIL","latitud":-12.119043412845492,"longitud":-43.889103170252774,"ranking":0},
{"id":9959,"nombre":"Wenceslau Guimaraes","localidad":"Wenceslau Guimarães","provincia":"Bahia","pais":"BRASIL","latitud":-13.730249484870768,"longitud":-39.4835745344704,"ranking":0},
{"id":6584,"nombre":"Xique Xique","localidad":"Xique-Xique","provincia":"Bahia","pais":"BRASIL","latitud":-10.820439587223465,"longitud":-42.723864716382884,"ranking":0},
{"id":9951,"nombre":"Queimadinha","localidad":"Xique-Xique","provincia":"Bahia","pais":"BRASIL","latitud":-10.820518321177348,"longitud":-42.72385430610175,"ranking":0},
{"id":10282,"nombre":"Abadia dos Dourados","localidad":"Abadia dos Dourados","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.469798363666722,"longitud":-47.40458410565014,"ranking":0},
{"id":9287,"nombre":"Abaete","localidad":"Abaeté","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.15578859474579,"longitud":-45.44271209751006,"ranking":0},
{"id":5351,"nombre":"Abre Campo","localidad":"Abre Campo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.300330035893417,"longitud":-42.47800187434419,"ranking":0},
{"id":5275,"nombre":"Acaica","localidad":"Acaiaca","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.363250593779416,"longitud":-43.142444932228685,"ranking":0},
{"id":5332,"nombre":"Acesso Acaiaca","localidad":"Acaiaca","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9619,"nombre":"Acucena","localidad":"Açucena","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.075014785862788,"longitud":-42.54684055984574,"ranking":0},
{"id":5371,"nombre":"Agua Boa","localidad":"Água Boa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.9954470493426,"longitud":-42.39216204321813,"ranking":0},
{"id":5142,"nombre":"Agua Comprida","localidad":"Água Comprida","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.057546797130616,"longitud":-48.10947889216487,"ranking":0},
{"id":15147,"nombre":"Aguas Formosas","localidad":"Águas Formosas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.08359181241045,"longitud":-40.938354312435656,"ranking":0},
{"id":6729,"nombre":"Aimores","localidad":"Aimorés","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.496008400000004,"longitud":-41.07128018218077,"ranking":0},
{"id":10284,"nombre":"Aiuruoca","localidad":"Aiuruoca","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.975645020458668,"longitud":-44.603631471532246,"ranking":0},
{"id":5153,"nombre":"Alfenas","localidad":"Alfenas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.4507315,"longitud":-45.9487366,"ranking":0},
{"id":9383,"nombre":"Alfredo Vasconcelos","localidad":"Alfredo Vasconcelos","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6730,"nombre":"Almenara","localidad":"Almenara","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.173198234033165,"longitud":-40.69687405384587,"ranking":0},
{"id":15148,"nombre":"Alpercata","localidad":"Alpercata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.97995165981799,"longitud":-41.98520588439555,"ranking":0},
{"id":5191,"nombre":"Alpinopolis","localidad":"Alpinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.863411356300496,"longitud":-46.38429152731905,"ranking":0},
{"id":5193,"nombre":"Alterosa","localidad":"Alterosa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.25335412768925,"longitud":-46.1431048601858,"ranking":0},
{"id":15149,"nombre":"Alto Caparao","localidad":"Alto Caparaó","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.440314638583253,"longitud":-41.868221439973944,"ranking":0},
{"id":6127,"nombre":"Alvorada","localidad":"Alvorada de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.731920115096884,"longitud":-43.36449997434674,"ranking":0},
{"id":5112,"nombre":"Andradas","localidad":"Andradas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.069342309487098,"longitud":-46.573617099377586,"ranking":0},
{"id":9335,"nombre":"Cachoeira de Pajeu","localidad":"Cachoeira de Pajeú","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5928,"nombre":"Andrelandia","localidad":"Andrelândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.746391916572055,"longitud":-44.311804987103315,"ranking":0},
{"id":6210,"nombre":"Antonio Dias","localidad":"Antônio Dias","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.653926466850272,"longitud":-42.86805469810722,"ranking":0},
{"id":5324,"nombre":"Arcai","localidad":"Araçaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5330,"nombre":"Acesso Aracai","localidad":"Araçaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10119,"nombre":"Aracai","localidad":"Araçaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.201100895697373,"longitud":-44.24900456792763,"ranking":0},
{"id":5398,"nombre":"Aracuai","localidad":"Araçuaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.848419039708617,"longitud":-42.06628040442178,"ranking":0},
{"id":4906,"nombre":"Araguari","localidad":"Araguari","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.63954626512858,"longitud":-48.17493562429742,"ranking":0},
{"id":10172,"nombre":"Arapora","localidad":"Araporã","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.43000326169314,"longitud":-49.191048806517855,"ranking":0},
{"id":15137,"nombre":"Arapua","localidad":"Arapuá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.034501244546437,"longitud":-46.1548551942305,"ranking":0},
{"id":9284,"nombre":"Araujos","localidad":"Araújos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.948737776376067,"longitud":-45.16404341904598,"ranking":0},
{"id":6070,"nombre":"Araxa","localidad":"Araxá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.5844275,"longitud":-46.94297659999999,"ranking":0},
{"id":10285,"nombre":"Arceburgo","localidad":"Arceburgo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.36422510437693,"longitud":-46.94144471551466,"ranking":0},
{"id":14284,"nombre":"Arcos","localidad":"Arcos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.2943,"longitud":-45.5392,"ranking":0},
{"id":5194,"nombre":"Areado","localidad":"Areado","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.357041044410323,"longitud":-46.14733157572505,"ranking":0},
{"id":6130,"nombre":"Argirita","localidad":"Argirita","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.62632896236893,"longitud":-42.8286204535643,"ranking":0},
{"id":6222,"nombre":"Entroncamento Argirita","localidad":"Argirita","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10261,"nombre":"Arinos","localidad":"Arinos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.909630168122169,"longitud":-46.11334822249732,"ranking":0},
{"id":5319,"nombre":"Astolfo Dutra ","localidad":"Astolfo Dutra","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.307846444701344,"longitud":-42.85963662863349,"ranking":0},
{"id":9333,"nombre":"Augusto de Lima","localidad":"Augusto de Lima","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5148,"nombre":"Baependi","localidad":"Baependi","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.956343923374693,"longitud":-44.8907963438964,"ranking":0},
{"id":9621,"nombre":"Baldim","localidad":"Baldim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.290372644980998,"longitud":-43.95540484588065,"ranking":0},
{"id":14285,"nombre":"Bambui","localidad":"Bambuí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.0156729,"longitud":-45.9689089,"ranking":0},
{"id":6223,"nombre":"Entroncamento Bandeira","localidad":"Bandeira","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5160,"nombre":"Bandeira do Sul ","localidad":"Bandeira do Sul","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.72967311739457,"longitud":-46.38399521822313,"ranking":0},
{"id":5218,"nombre":"Barao de Cocais Acceso","localidad":"Barão de Cocais","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5219,"nombre":"Barao de Cocais","localidad":"Barão de Cocais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.94141270775389,"longitud":-43.47872125061871,"ranking":0},
{"id":4915,"nombre":"Barbacena","localidad":"Barbacena","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.211659648379555,"longitud":-43.751779376176295,"ranking":0},
{"id":5170,"nombre":"Barroso","localidad":"Barroso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.184663737257292,"longitud":-43.97393560421977,"ranking":0},
{"id":15136,"nombre":"Bela Vista de Minas","localidad":"Bela Vista de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.828767248832666,"longitud":-43.09708935930089,"ranking":0},
{"id":6137,"nombre":"Belmiro Braga","localidad":"Belmiro Braga","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9622,"nombre":"Belo Oriente","localidad":"Belo Oriente","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.22048918524291,"longitud":-42.49140810966548,"ranking":0},
{"id":15335,"nombre":"Boa Morte","localidad":"Belo Vale","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.439390938142104,"longitud":-20.439390938142104,"ranking":0},
{"id":10279,"nombre":"Belo Vale","localidad":"Belo Vale","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.40934532919627,"longitud":-44.02642682873959,"ranking":0},
{"id":15333,"nombre":"Rocas Novas","localidad":"Belo Vale","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.428224328945394,"longitud":-44.112050752072996,"ranking":0},
{"id":5377,"nombre":"Berilo","localidad":"Berilo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.95119445615841,"longitud":-42.462530044712764,"ranking":0},
{"id":15135,"nombre":"Bertopolis","localidad":"Bertópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5200,"nombre":"Acesso Betim","localidad":"Betim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.96285897223501,"longitud":-44.162451642109616,"ranking":0},
{"id":14278,"nombre":"Betim","localidad":"Betim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9898511,"longitud":-44.1901044,"ranking":1},
{"id":5934,"nombre":"Bicas","localidad":"Bicas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.71763566011543,"longitud":-43.064478173484346,"ranking":0},
{"id":10262,"nombre":"Biquinhas","localidad":"Biquinhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.782550129119855,"longitud":-45.50186716678838,"ranking":0},
{"id":5157,"nombre":"Boa Esperanca","localidad":"Boa Esperança","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.095221487987295,"longitud":-45.561283148898866,"ranking":0},
{"id":8878,"nombre":"Bocaiuva","localidad":"Bocaiúva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.1109841,"longitud":-43.8203276,"ranking":0},
{"id":9285,"nombre":"Bom Despacho","localidad":"Bom Despacho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.734618381306124,"longitud":-45.252620219779715,"ranking":0},
{"id":10116,"nombre":"Bom Jardim de Minas","localidad":"Bom Jardim de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.947474346260176,"longitud":-44.192555072679816,"ranking":0},
{"id":6149,"nombre":"Bom Jesus da Penha","localidad":"Bom Jesus da Penha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5215,"nombre":"Bom Jesus do Amparo Acceso","localidad":"Bom Jesus do Amparo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9623,"nombre":"Bom Jesus do Amparo","localidad":"Bom Jesus do Amparo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.70793768239598,"longitud":-43.476311862115594,"ranking":0},
{"id":5350,"nombre":"Bom Jesus do Galho","localidad":"Bom Jesus do Galho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.829549347236245,"longitud":-42.3182923680894,"ranking":0},
{"id":5204,"nombre":"Bom Sucesso","localidad":"Bom Sucesso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.030079872904654,"longitud":-44.751877818059235,"ranking":0},
{"id":5388,"nombre":"Acesso Bonfim","localidad":"Bonfim","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9624,"nombre":"Bonfim","localidad":"Bonfim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.33013798718638,"longitud":-44.24099327078139,"ranking":0},
{"id":10263,"nombre":"Bonfinopolis de Minas","localidad":"Bonfinópolis de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.57021888193618,"longitud":-45.98788334375668,"ranking":0},
{"id":5094,"nombre":"Borda da Mata","localidad":"Borda da Mata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.271247490740972,"longitud":-46.16679591655824,"ranking":0},
{"id":10286,"nombre":"Botelhos","localidad":"Botelhos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.653061457518103,"longitud":-46.39439227742739,"ranking":0},
{"id":5420,"nombre":"Brasilandia de Minas","localidad":"Brasilândia de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.009228749161142,"longitud":-46.00807800842782,"ranking":0},
{"id":9334,"nombre":"Brasília de Minas","localidad":"Brasília de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.211722218074907,"longitud":-44.4319263525877,"ranking":0},
{"id":14945,"nombre":"Bras Pires","localidad":"Brás Pires","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.84767561996758,"longitud":-43.24389914085426,"ranking":0},
{"id":5283,"nombre":"Braunas Acesso","localidad":"Braúnas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9625,"nombre":"Braunas","localidad":"Braúnas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.05776342775127,"longitud":-42.71908610335204,"ranking":0},
{"id":5234,"nombre":"Brasopolis ","localidad":"Brasópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.474492380535445,"longitud":-45.61388805648399,"ranking":0},
{"id":9626,"nombre":"Brumadinho","localidad":"Brumadinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.142423130706774,"longitud":-44.199979620312995,"ranking":0},
{"id":5297,"nombre":"Bueno Brandao","localidad":"Bueno Brandão","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.438057532911863,"longitud":-46.347251856119904,"ranking":0},
{"id":8884,"nombre":"Buenopolis","localidad":"Buenópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.872923482055178,"longitud":-44.172988844885644,"ranking":0},
{"id":5374,"nombre":"Buritis","localidad":"Buritis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.629690002559357,"longitud":-46.42936515593425,"ranking":0},
{"id":15134,"nombre":"Buritizeiro","localidad":"Buritizeiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.364036,"longitud":-44.96332109999999,"ranking":0},
{"id":10287,"nombre":"Cabo Verde","localidad":"Cabo Verde","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.473858739696563,"longitud":-46.398909288966166,"ranking":0},
{"id":10264,"nombre":"Cachoeira da Prata","localidad":"Cachoeira da Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.52477513476549,"longitud":-44.44873948251805,"ranking":0},
{"id":5128,"nombre":"Cachoeira de Minas","localidad":"Cachoeira de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.351673220425248,"longitud":-45.783981804606654,"ranking":0},
{"id":5409,"nombre":"Caetanopolis ","localidad":"Caetanópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.300481025733475,"longitud":-44.41490282333554,"ranking":0},
{"id":5210,"nombre":"Caete Acceso","localidad":"Caeté","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9627,"nombre":"Caete","localidad":"Caeté","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.896833580504186,"longitud":-43.670916762525515,"ranking":0},
{"id":15057,"nombre":"Caiana","localidad":"Caiana","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.69573819072957,"longitud":-41.92449903136988,"ranking":0},
{"id":5108,"nombre":"Caldas","localidad":"Caldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.777761525585,"longitud":-46.604086931323344,"ranking":0},
{"id":5266,"nombre":"Camanducaia","localidad":"Camanducaia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.739770032755008,"longitud":-46.131981787096414,"ranking":0},
{"id":6809,"nombre":"Cambui","localidad":"Cambuí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.607473753169483,"longitud":-46.058171228919605,"ranking":0},
{"id":5117,"nombre":"Cambuquira","localidad":"Cambuquira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.849300604066336,"longitud":-45.296474220683734,"ranking":0},
{"id":10166,"nombre":"Campanario","localidad":"Campanário","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.238910205781686,"longitud":-41.72933981732864,"ranking":0},
{"id":5115,"nombre":"Campanha","localidad":"Campanha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.830574975118328,"longitud":-45.398589213926215,"ranking":0},
{"id":5167,"nombre":"Acesso Campanha","localidad":"Campanha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.83049769013568,"longitud":-45.39860159643321,"ranking":0},
{"id":5159,"nombre":"Campestre","localidad":"Campestre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.709735841388177,"longitud":-46.25102088961504,"ranking":0},
{"id":5162,"nombre":"Acesso Campestre","localidad":"Campestre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.709605349216837,"longitud":-46.25121372144668,"ranking":0},
{"id":6075,"nombre":"Campina Verde","localidad":"Campina Verde","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.543067962686038,"longitud":-49.483351022418866,"ranking":0},
{"id":6807,"nombre":"Campo Belo","localidad":"Campo Belo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.89204369916539,"longitud":-45.265897261237804,"ranking":0},
{"id":5130,"nombre":"Campo do Meio ","localidad":"Campo do Meio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.10946168842997,"longitud":-45.83074169500445,"ranking":0},
{"id":15133,"nombre":"Campo Florido","localidad":"Campo Florido","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.7664375,"longitud":-48.5735969,"ranking":0},
{"id":15132,"nombre":"Campos Altos","localidad":"Campos Altos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.6965354,"longitud":-46.1672045,"ranking":0},
{"id":6671,"nombre":"Ponte das Amoras","localidad":"Campos Gerais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.239780802597604,"longitud":-45.75893805709615,"ranking":0},
{"id":5158,"nombre":"Campos Gerais ","localidad":"Campos Gerais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.23969389160069,"longitud":-45.758953580028816,"ranking":0},
{"id":6076,"nombre":"Canapolis","localidad":"Canápolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.728017075093994,"longitud":-49.19511750666745,"ranking":0},
{"id":9628,"nombre":"Candeias","localidad":"Candeias","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.773780803473418,"longitud":-45.27090095856131,"ranking":0},
{"id":5104,"nombre":"Cantagalo","localidad":"Cantagalo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.525498367491494,"longitud":-42.62703306568491,"ranking":0},
{"id":6220,"nombre":"Entroncamento Caparao","localidad":"Caparaó","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.41866471763396,"longitud":-41.967237831749586,"ranking":0},
{"id":15150,"nombre":"Caparao","localidad":"Caparaó","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.524778905715117,"longitud":-41.904916413354,"ranking":0},
{"id":10139,"nombre":"Capela Nova","localidad":"Capela Nova","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.923768582618063,"longitud":-43.61796692363356,"ranking":0},
{"id":5373,"nombre":"Capelinha","localidad":"Capelinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.692517718301566,"longitud":-17.692517718301566,"ranking":0},
{"id":5248,"nombre":"Capetinga","localidad":"Capetinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.62039039758967,"longitud":-47.06086178273336,"ranking":0},
{"id":6732,"nombre":"Capim Branco","localidad":"Capim Branco","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.54765006210463,"longitud":-44.13033282280583,"ranking":0},
{"id":15060,"nombre":"Capinopolis","localidad":"Capinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.6839145,"longitud":-49.5737107,"ranking":0},
{"id":10457,"nombre":"Capitao Andrade","localidad":"Capitão Andrade","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9336,"nombre":"Capitao Eneas","localidad":"Capitão Enéas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.322689217540653,"longitud":-43.7156023316271,"ranking":0},
{"id":5184,"nombre":"Capitolio ","localidad":"Capitólio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.614594494044198,"longitud":-46.05232608328153,"ranking":0},
{"id":5199,"nombre":"Entrada Capitolio ","localidad":"Capitólio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.61447792929215,"longitud":-46.05232816299118,"ranking":0},
{"id":15151,"nombre":"Caputira","localidad":"Caputira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.1752325,"longitud":-42.26832479999999,"ranking":0},
{"id":15131,"nombre":"Carai","localidad":"Caraí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.186289420858383,"longitud":-41.696328771448655,"ranking":0},
{"id":9384,"nombre":"Caranaiba","localidad":"Caranaíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.03484662940521,"longitud":-44.13655819483515,"ranking":0},
{"id":5337,"nombre":"Carandai","localidad":"Carandaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.950851288620743,"longitud":-43.79970218240063,"ranking":0},
{"id":4928,"nombre":"Carangola","localidad":"Carangola","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.735068598576024,"longitud":-42.03106377367601,"ranking":0},
{"id":5385,"nombre":"Acesso Carbonita","localidad":"Carbonita","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5386,"nombre":"Carbonita","localidad":"Carbonita","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.526327600692873,"longitud":-43.0141483213497,"ranking":0},
{"id":5113,"nombre":"Careacu","localidad":"Careaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.046512729926285,"longitud":-45.69765753135853,"ranking":0},
{"id":5272,"nombre":"Acesso Careacu","localidad":"Careaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.04650873397626,"longitud":-45.69765532381817,"ranking":0},
{"id":6733,"nombre":"Carlos Chagas","localidad":"Carlos Chagas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.70953750366429,"longitud":-40.761968387427366,"ranking":0},
{"id":9629,"nombre":"Carmesia","localidad":"Carmésia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5264,"nombre":"Carmo Da Cachoeira","localidad":"Carmo da Cachoeira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.459629702064493,"longitud":-45.21945348736434,"ranking":0},
{"id":5304,"nombre":"Acesso Carmo da Cachoeira","localidad":"Carmo da Cachoeira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.459771167990944,"longitud":-45.21950756545834,"ranking":0},
{"id":6179,"nombre":"Carmo da Mata","localidad":"Carmo da Mata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.560344587681232,"longitud":-44.869488527115465,"ranking":0},
{"id":5127,"nombre":"Carmo de Minas","localidad":"Carmo de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.11987437997421,"longitud":-45.1341109784421,"ranking":0},
{"id":5300,"nombre":"Acesso Carmo de Minas","localidad":"Carmo de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15130,"nombre":"Carmo do Paranaiba","localidad":"Carmo do Paranaíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.99823,"longitud":-46.31034,"ranking":0},
{"id":5192,"nombre":"Carmo do Rio Claro","localidad":"Carmo do Rio Claro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.970496816316388,"longitud":-46.12189978418294,"ranking":0},
{"id":6227,"nombre":"Entroncamento Carmopolis de Minas","localidad":"Carmópolis de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9630,"nombre":"Carmopolis de Minas","localidad":"Carmópolis de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.539495816287978,"longitud":-44.63429128304773,"ranking":0},
{"id":5139,"nombre":"Carvalhopolis","localidad":"Carvalhópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.778578238646084,"longitud":-45.84214216229842,"ranking":0},
{"id":5202,"nombre":"Carvalhos","localidad":"Carvalhos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.99850224975994,"longitud":-44.46380047339073,"ranking":0},
{"id":5190,"nombre":"Cassia","localidad":"Cássia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.585018997270062,"longitud":-46.919409619265906,"ranking":0},
{"id":4932,"nombre":"Cataguases","localidad":"Cataguases","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.391967639864646,"longitud":-42.690177567634215,"ranking":0},
{"id":5222,"nombre":"Catas Altas","localidad":"Catas Altas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.07641310408766,"longitud":-43.40635903920425,"ranking":0},
{"id":9388,"nombre":"Catas Altas da Noruega","localidad":"Catas Altas da Noruega","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9337,"nombre":"Catuji ","localidad":"Catuji","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.300809041136592,"longitud":-41.51675078053884,"ranking":0},
{"id":5121,"nombre":"Caxambu","localidad":"Caxambu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.986151293870698,"longitud":-44.93932241652035,"ranking":0},
{"id":10560,"nombre":"Central de Minas","localidad":"Central de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.761153202716656,"longitud":-41.30686150272041,"ranking":0},
{"id":6077,"nombre":"Centralina","localidad":"Centralina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.59238327871185,"longitud":-49.19764648773465,"ranking":0},
{"id":5376,"nombre":"Chapada do Norte","localidad":"Chapada do Norte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.088196791067674,"longitud":-42.5380465496549,"ranking":0},
{"id":9338,"nombre":"Chapada Gaucha","localidad":"Chapada Gaúcha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.296409014969282,"longitud":-45.633234630586614,"ranking":0},
{"id":14937,"nombre":"Chiador","localidad":"Chiador","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.003707571937984,"longitud":-43.0574204351212,"ranking":0},
{"id":9631,"nombre":"Claro dos Pocoes","localidad":"Claro dos Poções","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.07653939226535,"longitud":-44.20588830491466,"ranking":0},
{"id":9290,"nombre":"Claudio","localidad":"Cláudio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.44171886058909,"longitud":-44.76027453156203,"ranking":0},
{"id":5341,"nombre":"Coimbra","localidad":"Coimbra","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.857443317936763,"longitud":-42.80008712732863,"ranking":0},
{"id":9632,"nombre":"Coluna","localidad":"Coluna","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.233522959219663,"longitud":-42.84111003561721,"ranking":0},
{"id":6079,"nombre":"Comendador Gomes","localidad":"Comendador Gomes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.735549357972015,"longitud":-49.06443584211257,"ranking":0},
{"id":5422,"nombre":"Acesso Conceicao da Aparecida","localidad":"Conceição da Aparecida","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.094578616510507,"longitud":-46.20309491645,"ranking":0},
{"id":6191,"nombre":"Conceicao da Aparecida","localidad":"Conceição da Aparecida","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.09359892243565,"longitud":-46.201449643235335,"ranking":0},
{"id":15129,"nombre":"Conceicao das Alagoas","localidad":"Conceição das Alagoas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9240349,"longitud":-48.3738058,"ranking":0},
{"id":9530,"nombre":"Conceição do Mato Dentro","localidad":"Conceição do Mato Dentro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.04164896393983,"longitud":-43.423715756866194,"ranking":0},
{"id":5927,"nombre":"Conceicao do Rio Verde","localidad":"Conceição do Rio Verde","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.88293727831473,"longitud":-45.08609102420113,"ranking":0},
{"id":6229,"nombre":"Entrada Conceicao do Rio Verde","localidad":"Conceição do Rio Verde","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.882949240389255,"longitud":-45.086092935563016,"ranking":0},
{"id":5129,"nombre":"Conceicao dos Ouros","localidad":"Conceição dos Ouros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.414795730678723,"longitud":-45.798420777894236,"ranking":0},
{"id":5321,"nombre":"Confins","localidad":"Confins","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.631101062820424,"longitud":-43.96265984492258,"ranking":0},
{"id":5322,"nombre":"Aeroporto Confins ","localidad":"Confins","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.63419571394594,"longitud":-43.965352765222526,"ranking":0},
{"id":5105,"nombre":"Congonhal","localidad":"Congonhal","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.151867127492736,"longitud":-46.04457305698734,"ranking":0},
{"id":5334,"nombre":"Acesso Congonhas","localidad":"Congonhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6790,"nombre":"Congonhas","localidad":"Congonhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.511234656461067,"longitud":-43.843049932377525,"ranking":0},
{"id":10171,"nombre":"Congonhas do Norte","localidad":"Congonhas do Norte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.507747412416947,"longitud":-43.8435111326274,"ranking":0},
{"id":15128,"nombre":"Conquista","localidad":"Conquista","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.937247945110183,"longitud":-47.5463330418975,"ranking":0},
{"id":4931,"nombre":"Conselheiro Lafaiete","localidad":"Conselheiro Lafaiete","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.657950673120276,"longitud":-43.79158833218112,"ranking":0},
{"id":5336,"nombre":"Acesso Conselheiro Lafaiete","localidad":"Conselheiro Lafaiete","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6734,"nombre":"Conselheiro Pena","localidad":"Conselheiro Pena","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.170196539745056,"longitud":-41.468392761771206,"ranking":0},
{"id":5313,"nombre":"Contagem ","localidad":"Contagem","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.938356538457292,"longitud":-44.02892553327325,"ranking":0},
{"id":5144,"nombre":"Coqueiral","localidad":"Coqueiral","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.186639641777628,"longitud":-45.44694773545071,"ranking":0},
{"id":5156,"nombre":"Entrada Coqueiral","localidad":"Coqueiral","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.186652870585775,"longitud":-45.44694970447088,"ranking":0},
{"id":15126,"nombre":"Coracao de Jesus","localidad":"Coração de Jesus","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.688948,"longitud":-44.3617771,"ranking":0},
{"id":5328,"nombre":"Cordisburgo","localidad":"Cordisburgo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.125261541832725,"longitud":-44.32048650649492,"ranking":0},
{"id":5141,"nombre":"Cordislandia","localidad":"Cordislândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.79215230669776,"longitud":-45.70158565409126,"ranking":0},
{"id":6230,"nombre":"Entroncamento Cordislandia","localidad":"Cordislândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8883,"nombre":"Corinto","localidad":"Corinto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.365966492054902,"longitud":-44.458448626445794,"ranking":0},
{"id":9633,"nombre":"Coroaci","localidad":"Coroaci","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8425,"nombre":"Coromandel","localidad":"Coromandel","provincia":"Minas Gerais","pais":"BRASIL","latitud":-33.0308372,"longitud":-63.6733747,"ranking":0},
{"id":5378,"nombre":"Coronel Murta","localidad":"Coronel Murta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.619269104453405,"longitud":-16.619269104453405,"ranking":0},
{"id":9634,"nombre":"Corrego Danta","localidad":"Córrego Danta","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5245,"nombre":"Corrego Fundo","localidad":"Córrego Fundo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.451882088290326,"longitud":-45.55084488866616,"ranking":0},
{"id":5246,"nombre":"Acesso Corrego Fundo","localidad":"Córrego Fundo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.46208010300325,"longitud":-45.53293092633526,"ranking":0},
{"id":5381,"nombre":"Couto de Magalhaes de Minas","localidad":"Couto de Magalhães de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.073637859456305,"longitud":-43.47099459195857,"ranking":0},
{"id":15152,"nombre":"Crisolita","localidad":"Crisólita","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.23726370436232,"longitud":-40.91639329466534,"ranking":0},
{"id":10562,"nombre":"Cristais","localidad":"Cristais","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5314,"nombre":"Acesso Cristiano Otoni ","localidad":"Cristiano Otoni","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9385,"nombre":"Cristiano Otoni","localidad":"Cristiano Otoni","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.828429105352466,"longitud":-43.82425528983379,"ranking":0},
{"id":5125,"nombre":"Cristina","localidad":"Cristina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.206302305034303,"longitud":-45.263596247029895,"ranking":0},
{"id":9635,"nombre":"Crucilandia","localidad":"Crucilândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.39402632166459,"longitud":-44.336947426126905,"ranking":0},
{"id":5166,"nombre":"Cruzilia","localidad":"Cruzília","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.845412205979162,"longitud":-44.81701073291197,"ranking":0},
{"id":9339,"nombre":"Curral de Dentro","localidad":"Curral de Dentro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.937065315197522,"longitud":-41.855956797551336,"ranking":0},
{"id":4935,"nombre":"Curvelo","localidad":"Curvelo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.75324,"longitud":-44.43085,"ranking":0},
{"id":5384,"nombre":"Datas","localidad":"Datas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.451433428631297,"longitud":-43.6555199421849,"ranking":0},
{"id":5226,"nombre":"Delfim Moreira","localidad":"Delfim Moreira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.509842503245665,"longitud":-45.28414990687597,"ranking":0},
{"id":5257,"nombre":"Delfinopolis","localidad":"Delfinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.344824648011855,"longitud":-46.85549193954677,"ranking":0},
{"id":5937,"nombre":"Descoberto","localidad":"Descoberto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.45645953234387,"longitud":-42.96930731462226,"ranking":0},
{"id":9636,"nombre":"Desterro de Entre Rios","localidad":"Desterro de Entre Rios","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5276,"nombre":"Diamantina","localidad":"Diamantina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.246918660025845,"longitud":-43.60149621963501,"ranking":0},
{"id":10117,"nombre":"Desembargador Otoni","localidad":"Diamantina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.246896916166104,"longitud":-43.60094570970246,"ranking":0},
{"id":5260,"nombre":"Diogo de Vasconcelos","localidad":"Diogo de Vasconcelos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.48647495255965,"longitud":-43.19900152335589,"ranking":0},
{"id":15125,"nombre":"Dionisio","localidad":"Dionísio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.84213432643589,"longitud":-42.77657444556379,"ranking":0},
{"id":5311,"nombre":"Divinesia ","localidad":"Divinésia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.99035152725286,"longitud":-43.004843261600705,"ranking":0},
{"id":5355,"nombre":"Divino","localidad":"Divino","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.61537860164624,"longitud":-42.1479257069111,"ranking":0},
{"id":5417,"nombre":"Divino das Laranjeiras","localidad":"Divino das Laranjeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.730551308596674,"longitud":-41.48909859149816,"ranking":0},
{"id":9637,"nombre":"Divinolandia de Minas","localidad":"Divinolândia de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.801540377523008,"longitud":-42.61401964744621,"ranking":0},
{"id":5180,"nombre":"Acesso Divinopolis","localidad":"Divinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.12789404190091,"longitud":-44.884679950802315,"ranking":0},
{"id":5310,"nombre":"Divinopolis","localidad":"Divinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.12778287525373,"longitud":-44.884825170352215,"ranking":0},
{"id":9340,"nombre":"Divisa Alegre","localidad":"Divisa Alegre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.723360753874351,"longitud":-41.34345948803886,"ranking":0},
{"id":10120,"nombre":"Dom Cavati","localidad":"Dom Cavati","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.37563697581794,"longitud":-42.10750973784086,"ranking":0},
{"id":9529,"nombre":"Dom Joaquim","localidad":"Dom Joaquim","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5244,"nombre":"Dom Vicoso","localidad":"Dom Viçoso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.252794856308725,"longitud":-45.15919330928186,"ranking":0},
{"id":5320,"nombre":"Dona Eusebia","localidad":"Dona Eusébia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.319515284552157,"longitud":-42.81147359389312,"ranking":0},
{"id":15502,"nombre":"Dores de Campos","localidad":"Dores de Campos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.1110885,"longitud":-44.0209194,"ranking":0},
{"id":9248,"nombre":"Dores de Guanhaes","localidad":"Dores de Guanhães","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.057097911229025,"longitud":-42.930283949026,"ranking":0},
{"id":9286,"nombre":"Dores do Indaia","localidad":"Dores do Indaiá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.46790273340212,"longitud":-45.60211969386506,"ranking":0},
{"id":9088,"nombre":"Dores do Turvo","localidad":"Dores do Turvo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.975021082009672,"longitud":-43.18898677752859,"ranking":0},
{"id":5151,"nombre":"Eloi Mendes","localidad":"Elói Mendes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.608483104487924,"longitud":-45.56516279055043,"ranking":0},
{"id":5262,"nombre":"Acesso Eloi Mendes","localidad":"Elói Mendes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.608584714333425,"longitud":-45.56516641473386,"ranking":0},
{"id":10559,"nombre":"Engenheiro Caldas","localidad":"Engenheiro Caldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.1989208379623,"longitud":-42.04707920312618,"ranking":0},
{"id":9341,"nombre":"Engenheiro Navarro","localidad":"Engenheiro Navarro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.286751023167017,"longitud":-43.95149314021124,"ranking":0},
{"id":15153,"nombre":"Entre Folhas","localidad":"Entre Folhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.62605388965064,"longitud":-42.232412017047196,"ranking":0},
{"id":10155,"nombre":"Entre Rios de Minas","localidad":"Entre Rios de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.66908633972149,"longitud":-44.06252959142709,"ranking":0},
{"id":5342,"nombre":"Ervalia","localidad":"Ervália","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.837441705181003,"longitud":-42.65707430689456,"ranking":0},
{"id":9249,"nombre":"Esmeraldas","localidad":"Esmeraldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.232159353699068,"longitud":-43.021811203920095,"ranking":0},
{"id":4937,"nombre":"Espera Feliz","localidad":"Espera Feliz","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.647086352091375,"longitud":-41.9092160623643,"ranking":0},
{"id":9342,"nombre":"Espinosa","localidad":"Espinosa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-14.930523249385123,"longitud":-42.820287817732506,"ranking":0},
{"id":6267,"nombre":"Espirito Santo do Dourado","localidad":"Espírito Santo do Dourado","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.049310982909862,"longitud":-45.95060807816011,"ranking":0},
{"id":6813,"nombre":"Estiva","localidad":"Estiva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.46787385625785,"longitud":-46.010703081831444,"ranking":0},
{"id":9549,"nombre":"Estrela Dalva","localidad":"Estrela Dalva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.745875403519285,"longitud":-42.460026536372986,"ranking":0},
{"id":8873,"nombre":"Estrela do Sul","localidad":"Estrela do Sul","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.738663750272014,"longitud":-47.69214204323305,"ranking":0},
{"id":5938,"nombre":"Eugenopolis","localidad":"Eugenópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.097607759920567,"longitud":-42.18351169729186,"ranking":0},
{"id":5269,"nombre":"Extrema ","localidad":"Extrema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.856273815568922,"longitud":-46.32498234771991,"ranking":0},
{"id":10645,"nombre":"Entrada Extrema","localidad":"Extrema","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5383,"nombre":"Felicio dos Santos","localidad":"Felício dos Santos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.07662802388535,"longitud":-43.246590724710416,"ranking":0},
{"id":5382,"nombre":"Sao Goncalo do Rio Preto","localidad":"São Gonçalo do Rio Preto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.004955711417136,"longitud":-43.382570704770394,"ranking":0},
{"id":10265,"nombre":"Felixlandia","localidad":"Felixlândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.75317855735224,"longitud":-44.90322339871714,"ranking":0},
{"id":15154,"nombre":"Fernandes Tourinho","localidad":"Fernandes Tourinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9250,"nombre":"Ferros","localidad":"Ferros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.231985168728336,"longitud":-43.02183142760475,"ranking":0},
{"id":5356,"nombre":"Fervedouro","localidad":"Fervedouro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.727586891336692,"longitud":-42.28068166711663,"ranking":0},
{"id":5187,"nombre":"Entrada Formiga","localidad":"Formiga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.46742084305245,"longitud":-45.432086269530565,"ranking":0},
{"id":5309,"nombre":"Formiga","localidad":"Formiga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.4681534,"longitud":-45.4322893,"ranking":0},
{"id":15300,"nombre":"Baioes","localidad":"Formiga","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10121,"nombre":"Fortuna de Minas","localidad":"Fortuna de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.56129981320912,"longitud":-44.447386944353795,"ranking":0},
{"id":15155,"nombre":"Francisco Badaro ","localidad":"Francisco Badaró","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.99454836871296,"longitud":-42.34703096202248,"ranking":0},
{"id":9343,"nombre":"Francisco Dumont","localidad":"Francisco Dumont","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.313054555038068,"longitud":-44.22822614292709,"ranking":0},
{"id":9344,"nombre":"Francisco Sa","localidad":"Francisco Sá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.476506717556834,"longitud":-43.4961907052911,"ranking":0},
{"id":9257,"nombre":"Franciscopolis","localidad":"Franciscópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10458,"nombre":"Frei Inocencio","localidad":"Frei Inocêncio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.564191819553137,"longitud":-41.90735451289652,"ranking":0},
{"id":6083,"nombre":"Fronteira","localidad":"Fronteira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.281759598244022,"longitud":-49.203941146783436,"ranking":0},
{"id":6084,"nombre":"Frutal","localidad":"Frutal","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.017452627498784,"longitud":-48.93130034196972,"ranking":0},
{"id":9258,"nombre":"Funilandia","localidad":"Funilândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.3682070981725,"longitud":-44.05784607519608,"ranking":0},
{"id":6736,"nombre":"Galileia","localidad":"Galiléia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.999332155555223,"longitud":-41.53873507339377,"ranking":0},
{"id":8880,"nombre":"Gameleiras","localidad":"Gameleiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9259,"nombre":"Glaucilandia","localidad":"Glaucilândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.823460854730538,"longitud":-43.68442877941519,"ranking":0},
{"id":6737,"nombre":"Goiabeira","localidad":"Goiabeira","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5243,"nombre":"Goncalves","localidad":"Gonçalves","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.659245069242477,"longitud":-45.854510664204064,"ranking":0},
{"id":9263,"nombre":"Gonzaga","localidad":"Gonzaga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.82361978835288,"longitud":-42.4782077979558,"ranking":0},
{"id":5280,"nombre":"Gouveia","localidad":"Gouveia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.450434257527295,"longitud":-43.7406494966066,"ranking":0},
{"id":5368,"nombre":"Guanhaes","localidad":"Guanhães","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.773983036258937,"longitud":-18.773983036258937,"ranking":0},
{"id":5293,"nombre":"Guape","localidad":"Guapé","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9345,"nombre":"Guaraciama","localidad":"Guaraciama","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10288,"nombre":"Guaranesia","localidad":"Guaranésia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.303478420571857,"longitud":-46.79325062798456,"ranking":0},
{"id":5950,"nombre":"Guarani","localidad":"Guarani","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.347982812266267,"longitud":-43.04724030398786,"ranking":0},
{"id":8879,"nombre":"Guarda-Mor","localidad":"Guarda-Mor","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.770030660486192,"longitud":-47.09161213628526,"ranking":0},
{"id":5197,"nombre":"Guaxupe","localidad":"Guaxupé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.305385593483596,"longitud":-46.72500513507809,"ranking":0},
{"id":5347,"nombre":"Guidoval","localidad":"Guidoval","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.15154361120017,"longitud":-42.798025895692824,"ranking":0},
{"id":8875,"nombre":"Guimarania ","localidad":"Guimarânia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.841779,"longitud":-46.7946553,"ranking":0},
{"id":5348,"nombre":"Guiricema","localidad":"Guiricema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.00820131394421,"longitud":-42.71899690186479,"ranking":0},
{"id":5135,"nombre":"Heliodora","localidad":"Heliodora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.064561769905648,"longitud":-45.54944138252092,"ranking":0},
{"id":5306,"nombre":"Acesso Heliodora","localidad":"Heliodora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.2544480047208,"longitud":-45.00615221719476,"ranking":0},
{"id":14468,"nombre":"Iapu","localidad":"Iapu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.4380927,"longitud":-42.2144865,"ranking":0},
{"id":8872,"nombre":"Ibia","localidad":"Ibiá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.492449872932912,"longitud":-46.54962073157824,"ranking":0},
{"id":15122,"nombre":"Ibiai","localidad":"Ibiaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.862008982900637,"longitud":-44.91472215084949,"ranking":0},
{"id":9346,"nombre":"Ibiracatu","localidad":"Ibiracatu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.701855360641325,"longitud":-44.03381560279985,"ranking":0},
{"id":5255,"nombre":"Ibiraci ","localidad":"Ibiraci","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.466656452108275,"longitud":-47.12369080596358,"ranking":0},
{"id":5256,"nombre":"Acesso Ibiraci ","localidad":"Ibiraci","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.466631388917484,"longitud":-47.123685923972246,"ranking":0},
{"id":6272,"nombre":"Ibitiura de Minas","localidad":"Ibitiúra de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.068909178959892,"longitud":-46.44352604766308,"ranking":0},
{"id":9347,"nombre":"Icarai de Minas","localidad":"Icaraí de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5201,"nombre":"Entrada Igarape","localidad":"Igarapé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.052293684982928,"longitud":-44.32124911175671,"ranking":0},
{"id":14286,"nombre":"Iguatama","localidad":"Iguatama","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.1789098,"longitud":-45.7148709,"ranking":0},
{"id":5291,"nombre":"Ilicinea","localidad":"Ilicínea","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5093,"nombre":"Inconfidentes","localidad":"Inconfidentes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.32122384979495,"longitud":-46.32613704323912,"ranking":0},
{"id":9348,"nombre":"Indaiabira","localidad":"Indaiabira","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10283,"nombre":"Indianopolis","localidad":"Indianópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-23.47854966762682,"longitud":-52.6982921746281,"ranking":0},
{"id":4952,"nombre":"Inhapim","localidad":"Inhapim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.5491386,"longitud":-42.1235723,"ranking":0},
{"id":5410,"nombre":"Inhauma","localidad":"Inhaúma","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.495413263541195,"longitud":-44.392759381954406,"ranking":0},
{"id":5274,"nombre":"Inimutaba","localidad":"Inimutaba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.734123365143628,"longitud":-44.3668529621617,"ranking":0},
{"id":14469,"nombre":"Ipaba","localidad":"Ipaba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.4142933,"longitud":-42.415049,"ranking":0},
{"id":5361,"nombre":"Ipanema","localidad":"Ipanema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.80828904805466,"longitud":-41.71575750365472,"ranking":0},
{"id":5107,"nombre":"Ipuiuna","localidad":"Ipuiúna","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.098537241797196,"longitud":-46.18812179191354,"ranking":0},
{"id":8877,"nombre":"Irai de Minas ","localidad":"Iraí de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.9853059,"longitud":-47.4649119,"ranking":0},
{"id":5216,"nombre":"Itabira Acceso","localidad":"Itabira","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5364,"nombre":"Itabira","localidad":"Itabira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.62676310406593,"longitud":-43.23099732398987,"ranking":0},
{"id":5285,"nombre":"Itabirito Acesso","localidad":"Itabirito","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5286,"nombre":"Itabirito","localidad":"Itabirito","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.261764357231705,"longitud":-43.78489396198999,"ranking":0},
{"id":9267,"nombre":"Itacambira","localidad":"Itacambira","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9349,"nombre":"Itacarambi","localidad":"Itacarambi","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.104650259960671,"longitud":-44.09547365919875,"ranking":0},
{"id":5137,"nombre":"Itaguara","localidad":"Itaguara","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.392077261241955,"longitud":-44.487700842270016,"ranking":0},
{"id":15156,"nombre":"Itaipe","localidad":"Itaipé","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5103,"nombre":"Itajuba","localidad":"Itajubá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.423848440935323,"longitud":-45.46037907257207,"ranking":0},
{"id":5390,"nombre":"Itamarandiba ","localidad":"Itamarandiba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.85883175056039,"longitud":-42.861239827207534,"ranking":0},
{"id":4954,"nombre":"Itambacuri","localidad":"Itambacuri","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.033882266146122,"longitud":-41.68060075600291,"ranking":0},
{"id":9268,"nombre":"Itambe do Mato Dentro","localidad":"Itambé do Mato Dentro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.413730584982403,"longitud":-43.32179445076739,"ranking":0},
{"id":10289,"nombre":"Itamogi","localidad":"Itamogi","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.078675883846863,"longitud":-47.04785527040054,"ranking":0},
{"id":6806,"nombre":"Itamonte","localidad":"Itamonte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.27824735713857,"longitud":-44.87119293505557,"ranking":0},
{"id":5147,"nombre":"Itanhandu","localidad":"Itanhandu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.300916774297992,"longitud":-44.93558777589524,"ranking":0},
{"id":10281,"nombre":"Itanhomi","localidad":"Itanhomi","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.171550420958916,"longitud":-41.86269858042183,"ranking":0},
{"id":6738,"nombre":"Itaobim","localidad":"Itaobim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.561306867326714,"longitud":-41.5037814604597,"ranking":0},
{"id":6088,"nombre":"Itapagipe","localidad":"Itapagipe","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.899997961569717,"longitud":-49.36622171305332,"ranking":0},
{"id":6239,"nombre":"Entroncamento Itapecerica","localidad":"Itapecerica","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.468548251023517,"longitud":-45.12297671176439,"ranking":0},
{"id":9283,"nombre":"Itapecerica","localidad":"Itapecerica","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.470778243951873,"longitud":-45.12252146375334,"ranking":0},
{"id":10561,"nombre":"Itapeva","localidad":"Itapeva","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5188,"nombre":"Itau de Minas","localidad":"Itaú de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.74479954108309,"longitud":-46.75159916470472,"ranking":0},
{"id":6242,"nombre":"Entroncamento Itau","localidad":"Itaú de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.74459968724544,"longitud":-46.7517073325069,"ranking":0},
{"id":5179,"nombre":"Entrada Itauna ","localidad":"Itaúna","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.06435380141885,"longitud":-44.573488638127316,"ranking":0},
{"id":7327,"nombre":"Itauna","localidad":"Itaúna","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.07157170519709,"longitud":-44.57087699524812,"ranking":0},
{"id":9089,"nombre":"Itaverava","localidad":"Itaverava","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.676256256224782,"longitud":-43.60996140369617,"ranking":0},
{"id":15157,"nombre":"Itinga","localidad":"Itinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.612988038426526,"longitud":-41.76792178776934,"ranking":0},
{"id":12423,"nombre":"Itueta","localidad":"Itueta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.3906027,"longitud":-41.2231665,"ranking":0},
{"id":6089,"nombre":"Ituiutaba","localidad":"Ituiutaba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.9742831,"longitud":-49.4552008,"ranking":0},
{"id":5173,"nombre":"Itumirim ","localidad":"Itumirim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.31625376229581,"longitud":-44.87100598620711,"ranking":0},
{"id":6090,"nombre":"Iturama","localidad":"Iturama","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.724388446808792,"longitud":-50.19249905359249,"ranking":0},
{"id":5172,"nombre":"Itutinga","localidad":"Itutinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.295927814765196,"longitud":-44.65661809358596,"ranking":0},
{"id":9269,"nombre":"Jaboticatubas","localidad":"Jaboticatubas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15121,"nombre":"Jacinto","localidad":"Jacinto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.15022890363782,"longitud":-40.29598892122023,"ranking":0},
{"id":6278,"nombre":"Jacui","localidad":"Jacuí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.016013913460586,"longitud":-46.741697422918314,"ranking":0},
{"id":5091,"nombre":"Jacutinga","localidad":"Jacutinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.28584920140286,"longitud":-46.609982516184736,"ranking":0},
{"id":9350,"nombre":"Jaiba","localidad":"Jaíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.351659515895683,"longitud":-43.66755268438589,"ranking":0},
{"id":9351,"nombre":"Janauba","localidad":"Janaúba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.808442489042514,"longitud":-43.30465750279835,"ranking":1},
{"id":9352,"nombre":"Januaria ","localidad":"Januária","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.4784,"longitud":-44.3688,"ranking":0},
{"id":9270,"nombre":"Japaraiba ","localidad":"Japaraíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.114774441617467,"longitud":-45.561404412096834,"ranking":0},
{"id":9353,"nombre":"Japonvar","localidad":"Japonvar","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.997487075554861,"longitud":-44.27316697739259,"ranking":0},
{"id":8885,"nombre":"Jequitai","localidad":"Jequitaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.234661818461685,"longitud":-44.44201040172655,"ranking":0},
{"id":9271,"nombre":"Jequitiba","localidad":"Jequitibá","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6771,"nombre":"Jequitinhonha","localidad":"Jequitinhonha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.43993176302171,"longitud":-41.00969360501531,"ranking":0},
{"id":5164,"nombre":"Jesuania","localidad":"Jesuânia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.9981195323353,"longitud":-45.287107507477400,"ranking":0},
{"id":15158,"nombre":"Joaima","localidad":"Joaíma","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.6584819,"longitud":-41.0286881,"ranking":0},
{"id":9272,"nombre":"Joanesia ","localidad":"Joanésia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.17545918091309,"longitud":-42.68183180567362,"ranking":0},
{"id":4962,"nombre":"Joao Monlevade","localidad":"João Monlevade","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.840176900922987,"longitud":-43.19657220246685,"ranking":0},
{"id":4963,"nombre":"Joao Pinheiro","localidad":"João Pinheiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.740737598958333,"longitud":-46.17594395018796,"ranking":0},
{"id":9354,"nombre":"Joaquim Felicio","localidad":"Joaquim Felício","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.75888607463869,"longitud":-44.17080663214136,"ranking":0},
{"id":9273,"nombre":"Jose Raydan","localidad":"José Raydan","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.220896862071335,"longitud":-42.49796157291071,"ranking":0},
{"id":9300,"nombre":"Nova Uniao","localidad":"Nova União","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.72433543099474,"longitud":-43.584232654935434,"ranking":0},
{"id":5177,"nombre":"Entrada Juatuba ","localidad":"Juatuba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.95717694948095,"longitud":-44.336737175509,"ranking":0},
{"id":15297,"nombre":"Francelinos","localidad":"Juatuba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.971935965447702,"longitud":-44.2840139718943,"ranking":0},
{"id":4959,"nombre":"Juiz de Fora","localidad":"Juiz de Fora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.738205,"longitud":-43.374256,"ranking":1},
{"id":15228,"nombre":"Posto Salvaterra","localidad":"Juiz de Fora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.81804295707069,"longitud":-43.37693147375482,"ranking":0},
{"id":15301,"nombre":"Centro","localidad":"Juiz de Fora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.738783124130453,"longitud":-21.738783124130453,"ranking":0},
{"id":9275,"nombre":"Juramento","localidad":"Juramento","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6279,"nombre":"Juruaia","localidad":"Juruaia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.253486055396337,"longitud":-46.58324977492072,"ranking":0},
{"id":10646,"nombre":"Entrada Juruaia","localidad":"Juruaia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15159,"nombre":"Ladainha","localidad":"Ladainha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.62642109153792,"longitud":-41.736615273235415,"ranking":0},
{"id":9282,"nombre":"Lagoa da Prata","localidad":"Lagoa da Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.02634608079313,"longitud":-45.53999435313615,"ranking":0},
{"id":9355,"nombre":"Lagoa dos Patos","localidad":"Lagoa dos Patos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.98191657113427,"longitud":-44.58019002325431,"ranking":0},
{"id":6675,"nombre":"Rio Dourado","localidad":"Lagoa Dourada","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.91502016515953,"longitud":-44.07323954803223,"ranking":0},
{"id":10156,"nombre":"Lagoa Dourada","localidad":"Lagoa Dourada","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.91492132295592,"longitud":-44.073054502717795,"ranking":0},
{"id":15120,"nombre":"Lagoa Formosa","localidad":"Lagoa Formosa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.7753605,"longitud":-46.4121596,"ranking":0},
{"id":9356,"nombre":"Lagoa Grande","localidad":"Lagoa Grande","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5316,"nombre":"Lagoa Santa","localidad":"Lagoa Santa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.62883605631679,"longitud":-43.89925958073334,"ranking":0},
{"id":6772,"nombre":"Lajinha","localidad":"Lajinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.1514236,"longitud":-41.6211721,"ranking":0},
{"id":5163,"nombre":"Lambari","localidad":"Lambari","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.97027121215221,"longitud":-45.348818451732704,"ranking":0},
{"id":5258,"nombre":"Acesso Lambari","localidad":"Lambari","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.972189571981854,"longitud":-45.34712956767596,"ranking":0},
{"id":5317,"nombre":"Laranjal","localidad":"Laranjal","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.365932661448106,"longitud":-42.47489132490479,"ranking":0},
{"id":6245,"nombre":"Entroncamento Laranjal","localidad":"Laranjal","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.365959685335614,"longitud":-42.474652091854274,"ranking":0},
{"id":15119,"nombre":"Lassance","localidad":"Lassance","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.8866441,"longitud":-44.5765757,"ranking":0},
{"id":5168,"nombre":"Lavras","localidad":"Lavras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.253402,"longitud":-45.006394,"ranking":0},
{"id":5303,"nombre":"Entrada Lavras","localidad":"Lavras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.2544480047208,"longitud":-21.2544480047208,"ranking":0},
{"id":9276,"nombre":"Leandro Ferreira","localidad":"Leandro Ferreira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.71676742924316,"longitud":-45.02436865230587,"ranking":0},
{"id":4964,"nombre":"Leopoldina","localidad":"Leopoldina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.531854543864785,"longitud":-42.63481800270654,"ranking":0},
{"id":9357,"nombre":"Lontra","localidad":"Lontra","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9358,"nombre":"Luislandia  ","localidad":"Luislândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.11735758374511,"longitud":-44.58777780732308,"ranking":0},
{"id":6071,"nombre":"Luz","localidad":"Luz","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.79402130201037,"longitud":-45.68234257970458,"ranking":0},
{"id":15160,"nombre":"Machacalis","localidad":"Machacalis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.078070807471356,"longitud":-40.71735862919701,"ranking":0},
{"id":5138,"nombre":"Machado","localidad":"Machado","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.68168402432823,"longitud":-45.91329149772992,"ranking":0},
{"id":6288,"nombre":"Madre de Deus de Minas","localidad":"Madre de Deus de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.483040861464698,"longitud":-44.32930079285945,"ranking":0},
{"id":9277,"nombre":"Malacacheta","localidad":"Malacacheta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.843294030814125,"longitud":-42.08133306916919,"ranking":0},
{"id":9359,"nombre":"Mamonas","localidad":"Mamonas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9360,"nombre":"Manga","localidad":"Manga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-14.753821184801632,"longitud":-43.93763113494497,"ranking":0},
{"id":4973,"nombre":"Manhuacu","localidad":"Manhuaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.24868942699843,"longitud":-42.02957019209862,"ranking":0},
{"id":4974,"nombre":"Manhumirim","localidad":"Manhumirim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.35482594722907,"longitud":-41.95100366565287,"ranking":0},
{"id":6067,"nombre":"Maravilhas","localidad":"Maravilhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.52039284686358,"longitud":-44.682103724619445,"ranking":0},
{"id":5951,"nombre":"Mar De Espanha","localidad":"Mar de Espanha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.863977088405594,"longitud":-43.01420416702535,"ranking":0},
{"id":5220,"nombre":"Maria da Fe","localidad":"Maria da Fé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.307954259288472,"longitud":-45.37268774741713,"ranking":0},
{"id":5428,"nombre":"Mariana","localidad":"Mariana","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.388821871587893,"longitud":-43.42677431192006,"ranking":0},
{"id":10459,"nombre":"Marilac","localidad":"Marilac","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.506985817346656,"longitud":-42.08224477399755,"ranking":0},
{"id":9280,"nombre":"Mario Campos","localidad":"Mário Campos","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6296,"nombre":"Maripa","localidad":"Maripá de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.697070182869325,"longitud":-42.96368504420541,"ranking":0},
{"id":9293,"nombre":"Marlieria","localidad":"Marliéria","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.712062851739304,"longitud":-42.73420011368295,"ranking":0},
{"id":5281,"nombre":"Marmelopolis","localidad":"Marmelópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.449210545417888,"longitud":-45.1647954813007,"ranking":0},
{"id":10266,"nombre":"Martinho Campos","localidad":"Martinho Campos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.328865535838496,"longitud":-45.23600067557665,"ranking":0},
{"id":6773,"nombre":"Martins Soares","localidad":"Martins Soares","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.257229700704524,"longitud":-41.877387547254195,"ranking":0},
{"id":9294,"nombre":"Materlandia","localidad":"Materlândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.473672448477192,"longitud":-43.05828832053681,"ranking":0},
{"id":5178,"nombre":"Entrada Mateus Leme","localidad":"Mateus Leme","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.997868426581384,"longitud":-44.44404116558872,"ranking":0},
{"id":5929,"nombre":"Matias Barbosa","localidad":"Matias Barbosa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.868930458561568,"longitud":-43.31843725072892,"ranking":0},
{"id":9361,"nombre":"Matias Cardoso","localidad":"Matias Cardoso","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5352,"nombre":"Acesso Matipo","localidad":"Matipó","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5353,"nombre":"Matipo","localidad":"Matipó","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.284991355553725,"longitud":-42.34160084936799,"ranking":0},
{"id":9362,"nombre":"Mato Verde","localidad":"Mato Verde","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.390598925890632,"longitud":-42.858523850952935,"ranking":0},
{"id":5411,"nombre":"Matozinhos","localidad":"Matozinhos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.564150886539913,"longitud":-44.07845652644355,"ranking":0},
{"id":15118,"nombre":"Matutina","localidad":"Matutina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.224261915025192,"longitud":-45.97424853506526,"ranking":0},
{"id":4970,"nombre":"Medina","localidad":"Medina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.232175984811743,"longitud":-41.47644161439076,"ranking":0},
{"id":5323,"nombre":"Merces","localidad":"Mercês","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.19704585918876,"longitud":-43.34142351291982,"ranking":0},
{"id":5340,"nombre":"Acesso Merces ","localidad":"Mercês","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9295,"nombre":"Mesquita","localidad":"Mesquita","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.220809614709434,"longitud":-42.60656406237193,"ranking":0},
{"id":5375,"nombre":"Minas Novas","localidad":"Minas Novas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.21723492999418,"longitud":-42.59604020052068,"ranking":0},
{"id":10267,"nombre":"Minduri","localidad":"Minduri","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.68146049604288,"longitud":-44.60035536747224,"ranking":0},
{"id":9363,"nombre":"Mirabela","localidad":"Mirabela","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.25555819983846,"longitud":-44.165174377602625,"ranking":0},
{"id":6300,"nombre":"Miradouro","localidad":"Miradouro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.89256801043914,"longitud":-42.34016046348302,"ranking":0},
{"id":5318,"nombre":"Mirai","localidad":"Miraí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.195478291109136,"longitud":-42.611271610547746,"ranking":0},
{"id":9296,"nombre":"Moeda","localidad":"Moeda","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.3345892914234,"longitud":-44.05177528364974,"ranking":0},
{"id":9297,"nombre":"Moema","localidad":"Moema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.843260994456095,"longitud":-45.41100530915563,"ranking":0},
{"id":5402,"nombre":"Monjolos ","localidad":"Monjolos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.324137154705134,"longitud":-18.324137154705134,"ranking":0},
{"id":5296,"nombre":"Monsenhor Paulo","localidad":"Monsenhor Paulo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9364,"nombre":"Montalvania","localidad":"Montalvânia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-14.42567963983242,"longitud":-44.37019960198705,"ranking":0},
{"id":6093,"nombre":"Monte Alegre","localidad":"Monte Alegre de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.866995646822406,"longitud":-48.87366974230153,"ranking":0},
{"id":15212,"nombre":"Trevão","localidad":"Monte Alegre de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.8778221,"longitud":-49.0560669,"ranking":0},
{"id":9365,"nombre":"Monte Azul","localidad":"Monte Azul","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.158205794663496,"longitud":-42.86758334382375,"ranking":0},
{"id":5195,"nombre":"Monte Belo","localidad":"Monte Belo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.32495578098963,"longitud":-46.3699938041173,"ranking":0},
{"id":8874,"nombre":"Monte Carmelo","localidad":"Monte Carmelo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.72064462610881,"longitud":-47.50124691208412,"ranking":0},
{"id":10290,"nombre":"Monte Santo de Minas","localidad":"Monte Santo de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.1888380738015,"longitud":-46.974731152579594,"ranking":0},
{"id":8881,"nombre":"Montes Claros","localidad":"Montes Claros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.7421133,"longitud":-43.8676801,"ranking":0},
{"id":5097,"nombre":"Monte Siao","localidad":"Monte Sião","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.43264964888741,"longitud":-46.57242777436086,"ranking":0},
{"id":9366,"nombre":"Montezuma","localidad":"Montezuma","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10268,"nombre":"Morada Nova de Minas","localidad":"Morada Nova de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.60396600267801,"longitud":-45.35590112595497,"ranking":0},
{"id":9298,"nombre":"Morro do Pilar","localidad":"Morro do Pilar","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.22311960263338,"longitud":-43.38096070296161,"ranking":0},
{"id":6814,"nombre":"Munhoz","localidad":"Munhoz","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.61174707107379,"longitud":-46.36213308174373,"ranking":0},
{"id":15117,"nombre":"Mutum","localidad":"Mutum","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.81692126933688,"longitud":-41.43534030101511,"ranking":0},
{"id":5196,"nombre":"Muzambinho","localidad":"Muzambinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.377542593288222,"longitud":-46.52603259742882,"ranking":0},
{"id":9299,"nombre":"Nacip Raydan","localidad":"Nacip Raydan","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.45786395636565,"longitud":-42.24921426595085,"ranking":0},
{"id":4979,"nombre":"Nanuque","localidad":"Nanuque","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.837292301641735,"longitud":-40.34838510661277,"ranking":0},
{"id":6775,"nombre":"Naque","localidad":"Naque","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.224684403386668,"longitud":-42.3241017975775,"ranking":0},
{"id":5136,"nombre":"Natercia","localidad":"Natércia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.11964430375292,"longitud":-45.51259197244609,"ranking":0},
{"id":5171,"nombre":"Nazareno","localidad":"Nazareno","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.219448816581636,"longitud":-44.61359561570559,"ranking":0},
{"id":5273,"nombre":"Acesso Nazareno","localidad":"Nazareno","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.22175242004265,"longitud":-44.60850157105711,"ranking":0},
{"id":5155,"nombre":"Nepomuceno","localidad":"Nepomuceno","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.23235205877462,"longitud":-45.23173915258415,"ranking":0},
{"id":4983,"nombre":"Nova Era","localidad":"Nova Era","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.767070538173975,"longitud":-43.03359730072373,"ranking":0},
{"id":9386,"nombre":"Nova Lima","localidad":"Nova Lima","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.98393667470312,"longitud":-43.84421752487697,"ranking":0},
{"id":8876,"nombre":"Nova Ponte","localidad":"Nova Ponte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.1678063,"longitud":-47.675818,"ranking":0},
{"id":9367,"nombre":"Nova Porteirinha","localidad":"Nova Porteirinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6305,"nombre":"Nova Resende","localidad":"Nova Resende","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8882,"nombre":"Nova Serrana ","localidad":"Nova Serrana","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.872017268293206,"longitud":-44.97968468334241,"ranking":0},
{"id":9301,"nombre":"Novo Cruzeiro","localidad":"Novo Cruzeiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.467038316200924,"longitud":-41.87976429726812,"ranking":0},
{"id":15161,"nombre":"Novo Oriente de Minas","localidad":"Novo Oriente de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.415168647863773,"longitud":-41.217050999772475,"ranking":0},
{"id":9302,"nombre":"Olhos Dagua","localidad":"Olhos-d'Água","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.404099496871645,"longitud":-43.57184544860126,"ranking":0},
{"id":10291,"nombre":"Olimpio Noronha","localidad":"Olímpio Noronha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.065704348270238,"longitud":-45.26317590485985,"ranking":0},
{"id":5301,"nombre":"Acesso Oliveira","localidad":"Oliveira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.71307761587991,"longitud":-44.80339087332198,"ranking":0},
{"id":6307,"nombre":"Oliveira","localidad":"Oliveira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.696394457416524,"longitud":-44.823110050287966,"ranking":1},
{"id":5957,"nombre":"Ouro Branco","localidad":"Ouro Branco","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.521389638331808,"longitud":-43.694931518404466,"ranking":0},
{"id":5092,"nombre":"Ouro Fino","localidad":"Ouro Fino","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.2772501,"longitud":-46.3723944,"ranking":0},
{"id":4987,"nombre":"Ouro Preto","localidad":"Ouro Preto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.3807444,"longitud":-43.5075632,"ranking":0},
{"id":15298,"nombre":"Miguel Burnier","localidad":"Ouro Preto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.436496231640852,"longitud":-43.773343293099174,"ranking":0},
{"id":15299,"nombre":"Santo Antonio do Leite","localidad":"Ouro Preto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.359590554573042,"longitud":-20.359590554573042,"ranking":0},
{"id":6777,"nombre":"Padre Paraiso","localidad":"Padre Paraíso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.07802219728736,"longitud":-41.486110155905585,"ranking":0},
{"id":5294,"nombre":"Paineiras","localidad":"Paineiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.90250865798599,"longitud":-45.53501423353115,"ranking":0},
{"id":5186,"nombre":"Pains ","localidad":"Pains","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.366399699556403,"longitud":-45.662383149624496,"ranking":0},
{"id":5331,"nombre":"Paiva","localidad":"Paiva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.288351042038684,"longitud":-43.41980572877207,"ranking":0},
{"id":6309,"nombre":"Palma","localidad":"Palma","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.377514788894924,"longitud":-42.31812108685026,"ranking":0},
{"id":10269,"nombre":"Papagaios","localidad":"Papagaios","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.453530295339416,"longitud":-44.74311069922093,"ranking":0},
{"id":9288,"nombre":"Para de Minas","localidad":"Pará de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.882615759210328,"longitud":-44.606072384094915,"ranking":0},
{"id":5152,"nombre":"Paraguacu","localidad":"Paraguaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.549890032004942,"longitud":-45.73668012668334,"ranking":0},
{"id":5263,"nombre":"Acesso Paraguacu","localidad":"Paraguaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.54990176162763,"longitud":-45.73667448695911,"ranking":0},
{"id":5131,"nombre":"Paraisopolis","localidad":"Paraisópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.55650540548466,"longitud":-45.77290654010285,"ranking":0},
{"id":5241,"nombre":"Paraopeba","localidad":"Paraopeba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.278293492604337,"longitud":-44.4143991187722,"ranking":0},
{"id":9304,"nombre":"Passabem","localidad":"Passabém","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.352556260615753,"longitud":-43.13822866577748,"ranking":0},
{"id":5908,"nombre":"Passa Quatro","localidad":"Passa Quatro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.391471601538367,"longitud":-44.96511190185121,"ranking":0},
{"id":9303,"nombre":"Passa Tempo","localidad":"Passa Tempo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.65189545121621,"longitud":-44.494265511765136,"ranking":0},
{"id":5185,"nombre":"Passos","localidad":"Passos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.72541,"longitud":-46.62009,"ranking":0},
{"id":5254,"nombre":"Acesso Passos","localidad":"Passos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.722138955688365,"longitud":-46.62002229726217,"ranking":0},
{"id":6101,"nombre":"Patos Minas","localidad":"Patos de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.604055026415693,"longitud":-46.520012754120835,"ranking":0},
{"id":6103,"nombre":"Patrocinio","localidad":"Patrocínio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.928053847279315,"longitud":-47.000827098419144,"ranking":0},
{"id":5960,"nombre":"Patrocinio Do Muriae","localidad":"Patrocínio do Muriaé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.147702221620637,"longitud":-42.20406829745646,"ranking":0},
{"id":9305,"nombre":"Paulistas","localidad":"Paulistas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.427988484488814,"longitud":-42.869238765001505,"ranking":0},
{"id":15162,"nombre":"Pavao","localidad":"Pavão","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.433382733321228,"longitud":-41.00365065428548,"ranking":0},
{"id":9306,"nombre":"Pecanha","localidad":"Peçanha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.545726727173765,"longitud":-42.56276763159376,"ranking":0},
{"id":6779,"nombre":"Pedra Azul","localidad":"Pedra Azul","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.004223624417477,"longitud":-41.287098126994785,"ranking":0},
{"id":9307,"nombre":"Pedra do Indaia","localidad":"Pedra do Indaiá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.25681385274907,"longitud":-45.20801728125252,"ranking":0},
{"id":15296,"nombre":"Betania","localidad":"Pedra do Indaiá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.25671994240678,"longitud":-45.20806332881085,"ranking":0},
{"id":15058,"nombre":"Pedra Dourada","localidad":"Pedra Dourada","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.8318080661133,"longitud":-42.15591817800586,"ranking":0},
{"id":5124,"nombre":"Pedralva","localidad":"Pedralva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.245317362032473,"longitud":-45.465704395661746,"ranking":0},
{"id":9368,"nombre":"Pedras de Maria da Cruz","localidad":"Pedras de Maria da Cruz","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8889,"nombre":"Pedrinopolis","localidad":"Pedrinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.2161188,"longitud":-47.4566348,"ranking":0},
{"id":10122,"nombre":"Pedro Leopoldo","localidad":"Pedro Leopoldo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.61922469894741,"longitud":-44.03984063916527,"ranking":0},
{"id":10270,"nombre":"Pequeri","localidad":"Pequeri","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.832922311747623,"longitud":-43.11887820846177,"ranking":0},
{"id":10271,"nombre":"Pequi","localidad":"Pequi","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8891,"nombre":"Perdizes","localidad":"Perdizes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.34889415117314,"longitud":-47.29503078254711,"ranking":0},
{"id":5236,"nombre":"Acesso Perdoes","localidad":"Perdões","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.106316475204892,"longitud":-45.09285003153019,"ranking":0},
{"id":5240,"nombre":"Perdoes ","localidad":"Perdões","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.10608264531447,"longitud":-45.09294118768332,"ranking":0},
{"id":9309,"nombre":"Piedade dos Gerais","localidad":"Piedade dos Gerais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.470368046880186,"longitud":-44.22418898491011,"ranking":0},
{"id":5181,"nombre":"Pimenta","localidad":"Pimenta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.483548039428985,"longitud":-45.80706443109321,"ranking":0},
{"id":5182,"nombre":"Entrada Pimenta","localidad":"Pimenta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.483354125246763,"longitud":-45.80717730010644,"ranking":0},
{"id":9310,"nombre":"Piracema","localidad":"Piracema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.509592842074408,"longitud":-44.478068604365596,"ranking":0},
{"id":15114,"nombre":"Pirajuba","localidad":"Pirajuba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.9076861,"longitud":-48.7009692,"ranking":0},
{"id":9090,"nombre":"Piranga","localidad":"Piranga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.686167784296178,"longitud":-43.2985744144413,"ranking":0},
{"id":5102,"nombre":"Piranguinho","localidad":"Piranguinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.402561433028875,"longitud":-45.534107631413114,"ranking":0},
{"id":5961,"nombre":"Pirapetinga","localidad":"Pirapetinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.65919231653314,"longitud":-42.34429635556339,"ranking":2},
{"id":6105,"nombre":"Pirapora","localidad":"Pirapora","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.34798180691655,"longitud":-44.944607322601684,"ranking":0},
{"id":5344,"nombre":"Acesso Pirauba","localidad":"Piraúba","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5408,"nombre":"Pirauba ","localidad":"Piraúba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.272073800685078,"longitud":-43.02523849757649,"ranking":0},
{"id":9292,"nombre":"Pitangui","localidad":"Pitangui","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.686688159120028,"longitud":-44.89530157818859,"ranking":0},
{"id":5183,"nombre":"Piumhi","localidad":"Piumhi","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.467335942322194,"longitud":-45.9522449429566,"ranking":0},
{"id":14262,"nombre":"Planura","localidad":"Planura","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.1409518,"longitud":-48.7045171,"ranking":0},
{"id":10292,"nombre":"Poco Fundo","localidad":"Poço Fundo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.77541752790049,"longitud":-45.96467948150308,"ranking":0},
{"id":5109,"nombre":"Pocos de Caldas","localidad":"Poços de Caldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.78637341972025,"longitud":-46.558451163533455,"ranking":0},
{"id":6295,"nombre":"Marco Divisorio","localidad":"Poços de Caldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.845396851467036,"longitud":-46.680658194884394,"ranking":0},
{"id":5362,"nombre":"Pocrane","localidad":"Pocrane","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.67130569422366,"longitud":-19.67130569422366,"ranking":0},
{"id":10272,"nombre":"Pompeu","localidad":"Pompéu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.23305061704435,"longitud":-45.00141628231633,"ranking":0},
{"id":4996,"nombre":"Ponte Nova","localidad":"Ponte Nova","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.41333011170751,"longitud":-42.90332705767915,"ranking":0},
{"id":15113,"nombre":"Ponto Chique","localidad":"Ponto Chique","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10280,"nombre":"Ponto dos Volantes","localidad":"Ponto dos Volantes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.723051771587933,"longitud":-41.504591217465446,"ranking":0},
{"id":9369,"nombre":"Porteirinha","localidad":"Porteirinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.752848411601613,"longitud":-43.03128115995567,"ranking":0},
{"id":9091,"nombre":"Porto Firme","localidad":"Porto Firme","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.672178832596618,"longitud":-43.0847077122347,"ranking":0},
{"id":9311,"nombre":"Pote","localidad":"Poté","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.80735713335746,"longitud":-41.78968253453302,"ranking":0},
{"id":5095,"nombre":"Pouso Alegre","localidad":"Pouso Alegre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.237014875111583,"longitud":-45.93517543320464,"ranking":0},
{"id":5145,"nombre":"Pouso Alto","localidad":"Pouso Alto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.211144613567253,"longitud":-44.984328979835674,"ranking":0},
{"id":15501,"nombre":"Prados","localidad":"Prados","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.0573013,"longitud":-44.0785784,"ranking":0},
{"id":5111,"nombre":"Prata","localidad":"Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.301509788344607,"longitud":-48.9171322103288,"ranking":0},
{"id":5189,"nombre":"Pratapolis","localidad":"Pratápolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.74317532806969,"longitud":-46.85854423350003,"ranking":0},
{"id":14303,"nombre":"Presidente Bernardes","localidad":"Presidente Bernardes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.7685508,"longitud":-43.18705019999999,"ranking":0},
{"id":5278,"nombre":"Presidente Juscelino","localidad":"Presidente Juscelino","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.64748111053051,"longitud":-18.64748111053051,"ranking":0},
{"id":5279,"nombre":"Presidente Juscelino Acesso","localidad":"Presidente Juscelino","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5395,"nombre":"Presidente Kubitschek","localidad":"Presidente Kubitschek","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.615827202939553,"longitud":-43.56120894794286,"ranking":0},
{"id":6126,"nombre":"Alto Jequitiba","localidad":"Alto Jequitibá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.431419389988626,"longitud":-41.95979398258229,"ranking":0},
{"id":9312,"nombre":"Prudente de Morais","localidad":"Prudente de Morais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.48049448486063,"longitud":-44.15483481281586,"ranking":0},
{"id":5349,"nombre":"Raul Soares","localidad":"Raul Soares","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.10161640623973,"longitud":-42.45403579799638,"ranking":0},
{"id":9511,"nombre":"Recreio","localidad":"Recreio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.52929162465952,"longitud":-42.46895488261134,"ranking":0},
{"id":6673,"nombre":"Reduto","localidad":"Reduto","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5372,"nombre":"Resplendor","localidad":"Resplendor","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.325471198188886,"longitud":-41.25377887603359,"ranking":0},
{"id":5963,"nombre":"Ressaquinha","localidad":"Ressaquinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.064347996275703,"longitud":-43.7659072599704,"ranking":0},
{"id":9370,"nombre":"Riachinho","localidad":"Riachinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.22471636093322,"longitud":-45.99822935946186,"ranking":0},
{"id":9371,"nombre":"Riacho dos Machados","localidad":"Riacho dos Machados","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5427,"nombre":"Acesso Ribeirao das Neves","localidad":"Ribeirão das Neves","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14881,"nombre":"Ribeirao das Neves","localidad":"Ribeirão das Neves","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.77325977947274,"longitud":-44.081273953270596,"ranking":0},
{"id":10158,"nombre":"Ribeirao Vermelho","localidad":"Ribeirão Vermelho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.107038162837593,"longitud":-45.092870075080846,"ranking":0},
{"id":15112,"nombre":"Rio Doce","localidad":"Rio Doce","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.24632896908978,"longitud":-42.894388791320225,"ranking":0},
{"id":9092,"nombre":"Rio Espera","localidad":"Rio Espera","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.856388590238144,"longitud":-43.47377836575934,"ranking":0},
{"id":9313,"nombre":"Rio Manso","localidad":"Rio Manso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.25867010160096,"longitud":-44.31265265174491,"ranking":0},
{"id":5964,"nombre":"Rio Novo","localidad":"Rio Novo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.48240317423652,"longitud":-43.13328791544858,"ranking":0},
{"id":8887,"nombre":"Rio Paranaiba","localidad":"Rio Paranaíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.1945074,"longitud":-46.2419641,"ranking":0},
{"id":5343,"nombre":"Rio Pomba","localidad":"Rio Pomba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.27868111617448,"longitud":-43.175710608168956,"ranking":0},
{"id":5419,"nombre":"Rio Vermelho","localidad":"Rio Vermelho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.293184150549298,"longitud":-43.01090668924848,"ranking":0},
{"id":6676,"nombre":"Entroncamento Ritapolis","localidad":"Ritápolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.027266463797957,"longitud":-44.3212235466867,"ranking":0},
{"id":15054,"nombre":"Ritapolis","localidad":"Ritápolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.02163323514829,"longitud":-44.319272969433705,"ranking":0},
{"id":8890,"nombre":"Romaria ","localidad":"Romaria","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.884338973515963,"longitud":-47.58817978349514,"ranking":0},
{"id":9372,"nombre":"Rubelita","localidad":"Rubelita","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14879,"nombre":"Sabara","localidad":"Sabará","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.879164572714323,"longitud":-43.79620345013958,"ranking":0},
{"id":9314,"nombre":"Sabinopolis","localidad":"Sabinópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.666691953512576,"longitud":-43.08160494096862,"ranking":0},
{"id":10073,"nombre":"Sacramento","localidad":"Sacramento","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9373,"nombre":"Salinas","localidad":"Salinas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.165282669989054,"longitud":-42.310962951595535,"ranking":0},
{"id":9374,"nombre":"Salto da Divisa","localidad":"Salto da Divisa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.998189458480086,"longitud":-39.94613831075696,"ranking":0},
{"id":5418,"nombre":"Acesso Santa Barbara","localidad":"Santa Bárbara","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.960996436784633,"longitud":-43.41033645612517,"ranking":0},
{"id":5424,"nombre":"Santa Barbara","localidad":"Santa Bárbara","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.960963264028386,"longitud":-43.41029770469057,"ranking":0},
{"id":10563,"nombre":"Santa Barbara do Leste","localidad":"Santa Bárbara do Leste","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.977956549435962,"longitud":-42.14161923209939,"ranking":0},
{"id":8426,"nombre":"Santa Barbara do Tugurio","localidad":"Santa Bárbara do Tugúrio","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.24729944746842,"longitud":-43.55807926022355,"ranking":0},
{"id":6786,"nombre":"Santa Cruz de Minas","localidad":"Santa Cruz de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.12289661343694,"longitud":-44.21335626890984,"ranking":0},
{"id":9315,"nombre":"Santa Efigenia de Minas","localidad":"Santa Efigênia de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.822507178120695,"longitud":-42.43328066516794,"ranking":0},
{"id":8893,"nombre":"Santa Fe de Minas","localidad":"Santa Fé de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15146,"nombre":"Santa Helena de Minas","localidad":"Santa Helena de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.939726866353222,"longitud":-40.68280827375541,"ranking":0},
{"id":8888,"nombre":"Santa Juliana","localidad":"Santa Juliana","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.3129,"longitud":-47.5271,"ranking":0},
{"id":9316,"nombre":"Santa Luzia","localidad":"Santa Luzia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.937265232175687,"longitud":-48.22982339904929,"ranking":0},
{"id":5354,"nombre":"Santa Margarida","localidad":"Santa Margarida","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.379029750550636,"longitud":-42.25520130095144,"ranking":0},
{"id":5366,"nombre":"Santa Maria de Itabira","localidad":"Santa Maria de Itabira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.449085037977916,"longitud":-43.11423749668103,"ranking":0},
{"id":15106,"nombre":"Santa Maria do Salto","localidad":"Santa Maria do Salto","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5370,"nombre":"Santa Maria do Suacui","localidad":"Santa Maria do Suaçuí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.18829723461664,"longitud":-42.41929994707649,"ranking":0},
{"id":6679,"nombre":"Santana da Vargem","localidad":"Santana da Vargem","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.245643747260203,"longitud":-21.245643747260203,"ranking":0},
{"id":9317,"nombre":"Santana de Pirapama","localidad":"Santana de Pirapama","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.009096666490834,"longitud":-44.04262515849111,"ranking":0},
{"id":10274,"nombre":"Santana do Deserto","localidad":"Santana do Deserto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.955146432180893,"longitud":-43.16735512860824,"ranking":0},
{"id":9387,"nombre":"Santana do Jacare","localidad":"Santana do Jacaré","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5358,"nombre":"Santana do Manhuacu","localidad":"Santana do Manhuaçu","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9318,"nombre":"Santana do Paraiso","localidad":"Santana do Paraíso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.370101420978443,"longitud":-42.551287092021475,"ranking":0},
{"id":9319,"nombre":"Santana do Riacho","localidad":"Santana do Riacho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.169869024302294,"longitud":-43.715709571887345,"ranking":0},
{"id":5299,"nombre":"Santa Rita de Caldas ","localidad":"Santa Rita de Caldas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.028200627733455,"longitud":-46.33663071538454,"ranking":0},
{"id":10273,"nombre":"Santa Rita de Jacutinga","localidad":"Santa Rita de Jacutinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.152946033754052,"longitud":-44.091370951269184,"ranking":0},
{"id":6805,"nombre":"Santa Rita de Minas","localidad":"Santa Rita de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.145556884270796,"longitud":-44.08835442988367,"ranking":0},
{"id":5101,"nombre":"Santa Rita do Sapacui","localidad":"Santa Rita do Sapucaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.254383201740566,"longitud":-45.70463915922477,"ranking":0},
{"id":6115,"nombre":"Santa Vitoria","localidad":"Santa Vitória","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.844966957591815,"longitud":-50.130762645621985,"ranking":0},
{"id":5203,"nombre":"Santo Antonio do Amparo","localidad":"Santo Antônio do Amparo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.947039583825866,"longitud":-44.913821416477575,"ranking":0},
{"id":5302,"nombre":"Acesso Santo Antonio do Amparo","localidad":"Santo Antônio do Amparo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.95448122068307,"longitud":-44.912747683017,"ranking":0},
{"id":10169,"nombre":"Santo Antonio do Itambe","localidad":"Santo Antônio do Itambé","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.465893780654095,"longitud":-43.30581811404017,"ranking":0},
{"id":10578,"nombre":"Santo Antonio do Jacinto","localidad":"Santo Antônio do Jacinto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.534293862807075,"longitud":-40.175242782245014,"ranking":0},
{"id":6674,"nombre":"Santo Antonio do Retiro","localidad":"Santo Antônio do Retiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9320,"nombre":"Santo Antonio do Rio Abaixo","localidad":"Santo Antônio do Rio Abaixo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5227,"nombre":"Sao Domingos do Prata Acceso","localidad":"São Domingos do Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5229,"nombre":"Vargem Linda","localidad":"São Domingos do Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15105,"nombre":"Sao Domingos do Prata","localidad":"São Domingos do Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.8642601,"longitud":-42.9656143,"ranking":0},
{"id":15103,"nombre":"Sao Francisco","localidad":"São Francisco","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.9547012,"longitud":-44.8672663,"ranking":0},
{"id":9321,"nombre":"Sao Francisco de Paula","localidad":"São Francisco de Paula","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.710742595584275,"longitud":-44.987020106210885,"ranking":0},
{"id":15104,"nombre":"Sao Francisco de Sales","localidad":"São Francisco de Sales","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.8614716,"longitud":-49.7687919,"ranking":0},
{"id":5360,"nombre":"Sao Geraldo","localidad":"São Geraldo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.925823123818798,"longitud":-42.833673592959585,"ranking":0},
{"id":15100,"nombre":"Sao Goncalo do Abaete","localidad":"São Gonçalo do Abaeté","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.336872811656477,"longitud":-45.834972836648014,"ranking":0},
{"id":5114,"nombre":"Sao Goncalo do Sapucai","localidad":"São Gonçalo do Sapucaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.893680065679913,"longitud":-45.58978879698847,"ranking":0},
{"id":5305,"nombre":"Entrada Sao Goncalo do Sapucai ","localidad":"São Gonçalo do Sapucaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.89818850954306,"longitud":-45.58807637628577,"ranking":0},
{"id":8894,"nombre":"Sao Gotardo","localidad":"São Gotardo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.310135731087588,"longitud":-46.05257466846368,"ranking":0},
{"id":5259,"nombre":"Sao Joao da Lagoa","localidad":"São João da Lagoa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.800137155128088,"longitud":-44.31711581094032,"ranking":0},
{"id":10293,"nombre":"Sao Joao da Mata","localidad":"São João da Mata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.93363625142365,"longitud":-45.925988173839,"ranking":0},
{"id":9375,"nombre":"Sao Joao da Ponte","localidad":"São João da Ponte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.930895854954159,"longitud":-44.007958986462235,"ranking":0},
{"id":9376,"nombre":"Sao Joao das Missoes","localidad":"São João das Missões","provincia":"Minas Gerais","pais":"BRASIL","latitud":-14.881527821511549,"longitud":-44.08312730122268,"ranking":0},
{"id":5423,"nombre":"Sao Joao del Rei","localidad":"São João del Rei","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.1259718,"longitud":-44.2467197,"ranking":2},
{"id":15098,"nombre":"Sao Joao do Manteninha ","localidad":"São João do Manteninha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.725936954317028,"longitud":-41.16094159539142,"ranking":0},
{"id":15144,"nombre":"Sao Joao do Oriente","localidad":"São João do Oriente","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.339103777147756,"longitud":-42.153931203660996,"ranking":0},
{"id":9377,"nombre":"Sao Joao do Paraiso","localidad":"São João do Paraíso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.320605694229403,"longitud":-42.01723730280501,"ranking":0},
{"id":5217,"nombre":"Sao Joao Evangelista","localidad":"São João Evangelista","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.5495585,"longitud":-42.7667651,"ranking":0},
{"id":5968,"nombre":"Sao Joao Nepomuceno","localidad":"São João Nepomuceno","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.535089913244846,"longitud":-43.01431834475918,"ranking":0},
{"id":5315,"nombre":"Acesso Sao Jose da Lapa","localidad":"São José da Lapa","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10462,"nombre":"Sao Jose da Safira","localidad":"São José da Safira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.33020823861972,"longitud":-42.1418440055399,"ranking":0},
{"id":5123,"nombre":"Sao Jose do Alegre","localidad":"São José do Alegre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.330413858236486,"longitud":-45.528850641145446,"ranking":0},
{"id":5230,"nombre":"Sao Jose do Goiabal Acceso","localidad":"São José do Goiabal","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5231,"nombre":"Sao Jose do Goiabal","localidad":"São José do Goiabal","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.92559400981555,"longitud":-42.70749705807603,"ranking":0},
{"id":9322,"nombre":"Sao Jose do Jacuri","localidad":"São José do Jacuri","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.277292745881738,"longitud":-42.672279746385016,"ranking":0},
{"id":5122,"nombre":"Sao Lourenco","localidad":"São Lourenço","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.125685451712744,"longitud":-45.03797753428509,"ranking":0},
{"id":6719,"nombre":"Sao Pedro da Uniao","localidad":"São Pedro da União","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5369,"nombre":"Sao Pedro do Suacui","localidad":"São Pedro do Suaçuí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.363439673098004,"longitud":-42.606655531728855,"ranking":0},
{"id":9378,"nombre":"Sao Romao","localidad":"São Romão","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.36832104823632,"longitud":-45.072827146582966,"ranking":0},
{"id":6720,"nombre":"Sao Sebastiao da Bela Vista","localidad":"São Sebastião da Bela Vista","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.158154908388312,"longitud":-45.75455918651654,"ranking":0},
{"id":10461,"nombre":"Sao Sebastiao do Maranhao","localidad":"São Sebastião do Maranhão","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5198,"nombre":"Sao Sebastiao do Paraiso","localidad":"São Sebastião do Paraíso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.9171482452962,"longitud":-46.964091616965824,"ranking":0},
{"id":9323,"nombre":"Sao Sebastiao do Rio Preto","localidad":"São Sebastião do Rio Preto","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6721,"nombre":"Entroncamento Sao Tiago","localidad":"São Tiago","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.911054677483687,"longitud":-44.50855006620343,"ranking":0},
{"id":9324,"nombre":"Sao Tiago","localidad":"São Tiago","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.911117510709058,"longitud":-44.508606416498466,"ranking":0},
{"id":10076,"nombre":"Sao Tomas de Aquino","localidad":"São Tomás de Aquino","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.782872360132856,"longitud":-47.099003460413144,"ranking":0},
{"id":5271,"nombre":"Sao Thome das Letra","localidad":"São Thomé das Letras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.719185023973186,"longitud":-44.98085218145502,"ranking":0},
{"id":10275,"nombre":"Sao Vicente de Minas","localidad":"São Vicente de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.700032168615465,"longitud":-44.44361064685685,"ranking":0},
{"id":5233,"nombre":"Sapucai Mirim","localidad":"Sapucaí-Mirim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.743905945032974,"longitud":-45.73953195952048,"ranking":0},
{"id":9325,"nombre":"Sardoa","localidad":"Sardoá","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9326,"nombre":"Sarzedo","localidad":"Sarzedo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.034926247722783,"longitud":-44.13660308254637,"ranking":0},
{"id":9329,"nombre":"Setubinha","localidad":"Setubinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.600837620378833,"longitud":-42.16192688620615,"ranking":0},
{"id":10276,"nombre":"Senador Cortes","localidad":"Senador Cortes","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5312,"nombre":"Senador Firmino","localidad":"Senador Firmino","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.916987646482387,"longitud":-43.10015385514562,"ranking":0},
{"id":5106,"nombre":"Senador Jose Bento","localidad":"Senador José Bento","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.166718761079796,"longitud":-46.17633908552964,"ranking":0},
{"id":5298,"nombre":"Acesso Senador Jose Bento","localidad":"Senador José Bento","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.16421946498185,"longitud":-46.17768513691232,"ranking":0},
{"id":5389,"nombre":"Senador Modestino Goncalves","localidad":"Senador Modestino Gonçalves","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.9472341320441,"longitud":-43.224803707317214,"ranking":0},
{"id":9093,"nombre":"Senhora de Oliveira","localidad":"Senhora de Oliveira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.79374786923723,"longitud":-43.34398795656453,"ranking":0},
{"id":5367,"nombre":"Senhora do Porto","localidad":"Senhora do Porto","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.892578939314195,"longitud":-43.08396628133802,"ranking":0},
{"id":9328,"nombre":"Serra Azul de Minas","localidad":"Serra Azul de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6785,"nombre":"Serra dos Aimores","localidad":"Serra dos Aimorés","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.78669142383916,"longitud":-40.24769753160571,"ranking":0},
{"id":8886,"nombre":"Serra do Salitre","localidad":"Serra do Salitre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.1128259,"longitud":-46.6806259,"ranking":0},
{"id":10294,"nombre":"Serrania","localidad":"Serrania","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.549689867786356,"longitud":-46.049480163125075,"ranking":0},
{"id":5397,"nombre":"Serro","localidad":"Serro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.60610397545845,"longitud":-43.389684105028046,"ranking":0},
{"id":5238,"nombre":"Sete Lagoas","localidad":"Sete Lagoas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.46749,"longitud":-44.24431999999999,"ranking":0},
{"id":10295,"nombre":"Silvianopolis","localidad":"Silvianópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.029876737495442,"longitud":-45.835646960985756,"ranking":0},
{"id":6795,"nombre":"Simao Pereira","localidad":"Simão Pereira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.96557007005408,"longitud":-43.31628427783465,"ranking":0},
{"id":5357,"nombre":"Simonesia","localidad":"Simonésia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.121397018758948,"longitud":-42.00092568474572,"ranking":0},
{"id":15143,"nombre":"Sobralia","localidad":"Sobrália","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5910,"nombre":"Soledade de Minas","localidad":"Soledade de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.06059923007748,"longitud":-45.04526247407789,"ranking":0},
{"id":9389,"nombre":"Tabuleiro","localidad":"Tabuleiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.357186104469758,"longitud":-43.25034210917436,"ranking":0},
{"id":6053,"nombre":"Taio","localidad":"Taiobeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.81819525827592,"longitud":-42.230037744977224,"ranking":0},
{"id":10138,"nombre":"Taiobeiras","localidad":"Taiobeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.818388862633753,"longitud":-42.230126422042844,"ranking":0},
{"id":10123,"nombre":"Tarumirim","localidad":"Tarumirim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.281996221926775,"longitud":-42.006809351535466,"ranking":0},
{"id":5338,"nombre":"Teixeiras","localidad":"Teixeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.65092013400215,"longitud":-42.858821356920096,"ranking":0},
{"id":5020,"nombre":"Timoteo","localidad":"Timóteo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.536639,"longitud":-42.650061,"ranking":0},
{"id":15096,"nombre":"Tiros","localidad":"Tiros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.002588117077902,"longitud":-45.96514748000489,"ranking":0},
{"id":5345,"nombre":"Tocantins","localidad":"Tocantins","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.169839056645696,"longitud":-43.01923954882298,"ranking":0},
{"id":6819,"nombre":"Toledo","localidad":"Toledo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.740651032502,"longitud":-46.376334689454524,"ranking":0},
{"id":5363,"nombre":"Tombos","localidad":"Tombos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.910261659584442,"longitud":-42.026505169983615,"ranking":0},
{"id":6151,"nombre":"Campo Limpo","localidad":"Três Corações","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.700136572480496,"longitud":-45.259536727941125,"ranking":0},
{"id":6264,"nombre":"Entroncamento Tres Coracoes","localidad":"Três Corações","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.700147524036606,"longitud":-45.25954069633998,"ranking":0},
{"id":6725,"nombre":"Tres Coracoes","localidad":"Três Corações","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.7001,"longitud":-45.2595,"ranking":0},
{"id":5021,"nombre":"Tres Marias","localidad":"Três Marias","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.202408752913538,"longitud":-45.2328255289391,"ranking":0},
{"id":5165,"nombre":"Tres Pontas ","localidad":"Três Pontas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.369880954206693,"longitud":-45.505387930069894,"ranking":0},
{"id":10463,"nombre":"Tumiritinga","localidad":"Tumiritinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6117,"nombre":"Tupaciguara","localidad":"Tupaciguara","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.604094744472008,"longitud":-48.686932384114996,"ranking":0},
{"id":5387,"nombre":"Acesso Turmalina ","localidad":"Turmalina","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5393,"nombre":"Turmalina ","localidad":"Turmalina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.28718910961172,"longitud":-42.72524052614027,"ranking":0},
{"id":6726,"nombre":"Turvolandia","localidad":"Turvolândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.875617372069506,"longitud":-45.78755279469991,"ranking":0},
{"id":5346,"nombre":"Uba","localidad":"Ubá","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.118236194920275,"longitud":-42.93846960853107,"ranking":0},
{"id":9379,"nombre":"Ubai","localidad":"Ubaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.36040635116937,"longitud":-46.90490308145329,"ranking":0},
{"id":14470,"nombre":"Ubaporanga","localidad":"Ubaporanga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.6366769,"longitud":-42.1086236,"ranking":0},
{"id":5025,"nombre":"Uberaba","localidad":"Uberaba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.762883997092246,"longitud":-47.94546134780806,"ranking":1},
{"id":6099,"nombre":"Posto Antares","localidad":"Uberaba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.764505043028063,"longitud":-47.97575953334795,"ranking":0},
{"id":6072,"nombre":"Unai","localidad":"Unaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.3603774,"longitud":-46.9050303,"ranking":0},
{"id":5333,"nombre":"Urucania","localidad":"Urucânia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.352357517970365,"longitud":-42.73752647589113,"ranking":0},
{"id":10277,"nombre":"Urucuia","localidad":"Urucuia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.128770948583092,"longitud":-45.74024779396025,"ranking":0},
{"id":15142,"nombre":"Vargem Alegre","localidad":"Vargem Alegre","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.60675349571666,"longitud":-42.299632748801116,"ranking":0},
{"id":9330,"nombre":"Vargem Bonita","localidad":"Vargem Bonita","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9380,"nombre":"Vargem Grande do Rio Pardo","localidad":"Vargem Grande do Rio Pardo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.401587202222656,"longitud":-42.30752913973029,"ranking":0},
{"id":5149,"nombre":"Varginha","localidad":"Varginha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.564042956002016,"longitud":-45.44748139037441,"ranking":0},
{"id":8895,"nombre":"Varjao de Minas ","localidad":"Varjão de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.37908423749921,"longitud":-46.032068686416764,"ranking":0},
{"id":14956,"nombre":"Varzea da Palma","localidad":"Várzea da Palma","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.6026904,"longitud":-44.7325193,"ranking":0},
{"id":9381,"nombre":"Varzelandia ","localidad":"Varzelândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.701690115982839,"longitud":-44.033735438794054,"ranking":0},
{"id":10278,"nombre":"Vazante","localidad":"Vazante","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.99029191896656,"longitud":-46.907027503240286,"ranking":0},
{"id":9382,"nombre":"Verdelandia ","localidad":"Verdelândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.70182437523585,"longitud":-44.03381560279985,"ranking":0},
{"id":5391,"nombre":"Veredinha","localidad":"Veredinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.397795948005463,"longitud":-42.7364031403579,"ranking":0},
{"id":5392,"nombre":"Acesso Veredinha","localidad":"Veredinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14955,"nombre":"Verissimo","localidad":"Veríssimo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.666419278738644,"longitud":-48.308471222301804,"ranking":0},
{"id":15141,"nombre":"Vermelho Novo","localidad":"Vermelho Novo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.03559237150539,"longitud":-42.26719837088045,"ranking":0},
{"id":10170,"nombre":"Vespasiano","localidad":"Vespasiano","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.695313644661123,"longitud":-43.916813388403426,"ranking":0},
{"id":5339,"nombre":"Vicosa ","localidad":"Viçosa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.7543203,"longitud":-42.8774681,"ranking":0},
{"id":10460,"nombre":"Mathias Lobato","localidad":"Mathias Lobato","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.573336690347357,"longitud":-41.91529913921312,"ranking":0},
{"id":10118,"nombre":"Virgem da Lapa","localidad":"Virgem da Lapa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.811950461047424,"longitud":-42.35638296764302,"ranking":0},
{"id":9331,"nombre":"Virginopolis ","localidad":"Virginópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.823065589008976,"longitud":-42.70636525413372,"ranking":0},
{"id":9332,"nombre":"Virgolandia","localidad":"Virgolândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6788,"nombre":"Visconde do Rio Branco","localidad":"Visconde do Rio Branco","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.0090941466732,"longitud":-42.83230561707157,"ranking":0},
{"id":5970,"nombre":"Volta Grande","localidad":"Volta Grande","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.774171805957288,"longitud":-42.533673213870905,"ranking":0},
{"id":6728,"nombre":"Wenceslau Braz","localidad":"Wenceslau Braz","provincia":"Minas Gerais","pais":"BRASIL","latitud":-23.87070111793124,"longitud":-49.80202927382547,"ranking":0},
{"id":6109,"nombre":"Aguia Branca","localidad":"Águia Branca","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.216966261442323,"longitud":-40.27602309233552,"ranking":0},
{"id":12427,"nombre":"Agua Doce do Norte","localidad":"Água Doce do Norte","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4908,"nombre":"Alegre","localidad":"Alegre","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.762676979973783,"longitud":-41.530231026045186,"ranking":0},
{"id":9940,"nombre":"Carolina","localidad":"Alfredo Chaves","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.53278153571454,"longitud":-40.837598793785666,"ranking":0},
{"id":6110,"nombre":"Alto Rio Novo","localidad":"Alto Rio Novo","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.056693781116024,"longitud":-41.01590180755906,"ranking":0},
{"id":12513,"nombre":"Monte Carmelo","localidad":"Alto Rio Novo","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4900,"nombre":"Anchieta","localidad":"Anchieta","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.81321283446625,"longitud":-20.81321283446625,"ranking":0},
{"id":10511,"nombre":"Apiaca","localidad":"Apiacá","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6112,"nombre":"Aracruz","localidad":"Aracruz","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.82184729401459,"longitud":-40.27719431646234,"ranking":0},
{"id":9960,"nombre":"Vila do Riacho","localidad":"Aracruz","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.821674188576175,"longitud":-40.2772070489966,"ranking":0},
{"id":15295,"nombre":"Barra do Riacho","localidad":"Aracruz","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.829200643066294,"longitud":-40.06173252375827,"ranking":0},
{"id":8820,"nombre":"Atilio Vivacqua","localidad":"Atilio Vivacqua","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.915764536632135,"longitud":-41.19506099144635,"ranking":0},
{"id":6120,"nombre":"Baixo Guandu","localidad":"Baixo Guandu","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.52077579190122,"longitud":-41.0152324699918,"ranking":0},
{"id":12522,"nombre":"Entroncamento Alto Lage","localidad":"Baixo Guandu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12548,"nombre":"Trv. BR 259","localidad":"Baixo Guandu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9247,"nombre":"Santa Rosa","localidad":"Baixo Guandu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12514,"nombre":"Fazenda Milagre","localidad":"Baixo Guandu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6123,"nombre":"Barra de Sao Francisco","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.755489687841898,"longitud":-40.892168702789654,"ranking":0},
{"id":12468,"nombre":"Espera Que Vem","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12515,"nombre":"Fazenda Sao Pedro","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12516,"nombre":"Polo Industrial","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12523,"nombre":"Maquina","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12524,"nombre":"Represa","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12550,"nombre":"Fazenda Cachoeira Bonita","localidad":"Barra de São Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6131,"nombre":"Boa Esperanca","localidad":"Boa Esperança","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.540302118067306,"longitud":-40.29855137192441,"ranking":0},
{"id":4916,"nombre":"Bom Jesus do Norte","localidad":"Bom Jesus do Norte","provincia":"Espirito Santo","pais":"BRASIL","latitud":-21.1347038403929,"longitud":-41.67795178311398,"ranking":0},
{"id":6152,"nombre":"Brejetuba","localidad":"Brejetuba","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.145117538350583,"longitud":-41.28818028696585,"ranking":0},
{"id":6160,"nombre":"Acesso Brejetuba","localidad":"Brejetuba","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.144980191502423,"longitud":-41.288368955623326,"ranking":0},
{"id":10569,"nombre":"Cariacica","localidad":"Cariacica","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12525,"nombre":"Posto Fiscal","localidad":"Cariacica","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6241,"nombre":"Santana","localidad":"Cariacica","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9261,"nombre":"Oriente","localidad":"Cariacica","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.326397063841654,"longitud":-20.326397063841654,"ranking":0},
{"id":4998,"nombre":"Colatina","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.537672,"longitud":-40.6338137,"ranking":0},
{"id":6122,"nombre":"Barbados","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.533646907554353,"longitud":-40.62824091274756,"ranking":0},
{"id":12433,"nombre":"Angelo Frechiani","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12551,"nombre":"Fazenda Gigante","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12474,"nombre":"Barra do Liberdade","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12479,"nombre":"Tardin","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12517,"nombre":"Fazenda Sao Braz","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12518,"nombre":"Fazenda Marianeli","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12519,"nombre":"Entroncamento Friza","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12539,"nombre":"Trevo de Barbados","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12463,"nombre":"Colegio Edessa","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12464,"nombre":"15 de Outubro","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12466,"nombre":"Roda D'Agua","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12469,"nombre":"Escola Agricola","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12472,"nombre":"Pontal","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12473,"nombre":"Campostrini","localidad":"Colatina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7372,"nombre":"Conceicao da Barra","localidad":"Conceição da Barra","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.591947961046976,"longitud":-39.73112239252915,"ranking":0},
{"id":7371,"nombre":"Conceicao do Castelo","localidad":"Conceição do Castelo","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.365467650947654,"longitud":-41.242597430103736,"ranking":0},
{"id":9944,"nombre":"Domingos Martins","localidad":"Domingos Martins","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.35919685194869,"longitud":-40.65939789902528,"ranking":0},
{"id":6174,"nombre":"Ecoporanga","localidad":"Ecoporanga","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.371908222368837,"longitud":-40.83061854990985,"ranking":0},
{"id":9957,"nombre":"Santa Luzia do Norte","localidad":"Ecoporanga","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.204450879282152,"longitud":-40.60489195517977,"ranking":0},
{"id":12475,"nombre":"Alto Muritiba","localidad":"Ecoporanga","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12476,"nombre":"Osvaldo Cruz","localidad":"Ecoporanga","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12477,"nombre":"Itaperuna","localidad":"Ecoporanga","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6175,"nombre":"Fundao","localidad":"Fundão","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.93350856437158,"longitud":-40.405259724522004,"ranking":0},
{"id":12428,"nombre":"Governador Lindenberg","localidad":"Governador Lindenberg","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12546,"nombre":"Fazenda Ferreguetti","localidad":"Governador Lindenberg","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12552,"nombre":"Corr. Oriente","localidad":"Governador Lindenberg","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4943,"nombre":"Guacui","localidad":"Guaçuí","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.77078003992931,"longitud":-41.67431049632803,"ranking":0},
{"id":4948,"nombre":"Ibatiba","localidad":"Ibatiba","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.235476611547092,"longitud":-41.507376241458736,"ranking":0},
{"id":6178,"nombre":"Ibiracu ","localidad":"Ibiraçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.82309111752175,"longitud":-40.36994302633472,"ranking":0},
{"id":10538,"nombre":"Ibitirama","localidad":"Ibitirama","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6180,"nombre":"Iconha","localidad":"Iconha","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.793645410679815,"longitud":-40.81044913547444,"ranking":0},
{"id":6182,"nombre":"Irupi","localidad":"Irupi","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.342401426751582,"longitud":-41.642931850090804,"ranking":0},
{"id":12450,"nombre":"Itaguacu","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12451,"nombre":"Itaimbe","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12480,"nombre":"Palmeiras","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12481,"nombre":"Laranjal","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12482,"nombre":"Miguel Tomazine","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12483,"nombre":"Gersino Coser","localidad":"Itaguaçu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10513,"nombre":"Itapemirim","localidad":"Itapemirim","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12425,"nombre":"Itarana","localidad":"Itarana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12485,"nombre":"Limoeiro","localidad":"Itarana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12486,"nombre":"Rizzi","localidad":"Itarana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12521,"nombre":"Fazenda Madalao","localidad":"Itarana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12526,"nombre":"Fazenda Toniato","localidad":"Itarana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4956,"nombre":"Iuna","localidad":"Iúna","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.348306681338652,"longitud":-41.53557976470971,"ranking":2},
{"id":6186,"nombre":"Jaguare","localidad":"Jaguaré","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.9076684883947,"longitud":-40.07003680275195,"ranking":0},
{"id":10162,"nombre":"Jeronimo Monteiro","localidad":"Jerônimo Monteiro","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.793549467473344,"longitud":-41.40070218760077,"ranking":0},
{"id":6187,"nombre":"Joao Neiva","localidad":"João Neiva","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.757000072386965,"longitud":-40.379998593174186,"ranking":0},
{"id":9943,"nombre":"Cavalinho","localidad":"João Neiva","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.75609122457415,"longitud":-40.38012562574149,"ranking":0},
{"id":7374,"nombre":"Laranja da Terra","localidad":"Laranja da Terra","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.902501086965422,"longitud":-41.057648599074355,"ranking":0},
{"id":6153,"nombre":"Canivete","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.402381845089845,"longitud":-40.06773560821463,"ranking":0},
{"id":6189,"nombre":"Linhares","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.406073,"longitud":-40.06469999999999,"ranking":0},
{"id":12531,"nombre":"Entroncamento Bagueira","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12536,"nombre":"Rio da Prata","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12555,"nombre":"Entrocamento BR 248","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12487,"nombre":"KM 15","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12488,"nombre":"Bonicenha","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12527,"nombre":"Fazenda Barranco","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12528,"nombre":"Assentamento Sezinio","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12529,"nombre":"Fazenda Batista","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12530,"nombre":"Dr. Carlos","localidad":"Linhares","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6190,"nombre":"Mantenopolis","localidad":"Mantenópolis","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.860625,"longitud":-41.12254300000001,"ranking":0},
{"id":12532,"nombre":"Placa","localidad":"Mantenópolis","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12542,"nombre":"Sao Jose","localidad":"Mantenópolis","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12543,"nombre":"Santa Luzia","localidad":"Mantenópolis","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4977,"nombre":"Marataizes","localidad":"Marataízes","provincia":"Espirito Santo","pais":"BRASIL","latitud":-21.049595173257348,"longitud":-40.83098077177293,"ranking":1},
{"id":6193,"nombre":"Marechal Floriano","localidad":"Marechal Floriano","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.410409258231912,"longitud":-40.67524243770433,"ranking":0},
{"id":6194,"nombre":"Marilandia","localidad":"Marilândia","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.41391712619219,"longitud":-19.41391712619219,"ranking":0},
{"id":4972,"nombre":"Mimoso do Sul","localidad":"Mimoso do Sul","provincia":"Espirito Santo","pais":"BRASIL","latitud":-21.062931572357257,"longitud":-41.36794229042467,"ranking":0},
{"id":6197,"nombre":"Montanha","localidad":"Montanha","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.127515778907103,"longitud":-18.127515778907103,"ranking":0},
{"id":6198,"nombre":"Mucurici","localidad":"Mucurici","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.09648380074934,"longitud":-40.521139389272655,"ranking":0},
{"id":6200,"nombre":"Muniz Freire","localidad":"Muniz Freire","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.46318071105078,"longitud":-41.41189792151263,"ranking":0},
{"id":4978,"nombre":"Muqui","localidad":"Muqui","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.95398026135186,"longitud":-41.3496121469856,"ranking":0},
{"id":6204,"nombre":"Nova Venecia","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.707954581127677,"longitud":-40.40107148099442,"ranking":0},
{"id":12489,"nombre":"Agapi","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12490,"nombre":"Barra de Boa Vista","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12491,"nombre":"Cedrolandia","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12492,"nombre":"Guararema","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12553,"nombre":"Corr. Guarani","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12493,"nombre":"Cristalino","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12494,"nombre":"Sao Goncalo","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12495,"nombre":"Veloso","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12533,"nombre":"Fazenda Lima","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12534,"nombre":"Fazenda Vermelho","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12549,"nombre":"Trev. ES-137 / BR-381","localidad":"Nova Venécia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6205,"nombre":"Pancas","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.222971363872837,"longitud":-40.85349615420387,"ranking":0},
{"id":12496,"nombre":"Braganca","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12498,"nombre":"Shuatz","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12499,"nombre":"Alto Pancas","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12500,"nombre":"Augustao","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12544,"nombre":"Laginha","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12545,"nombre":"Entrada de Laginha","localidad":"Pancas","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6211,"nombre":"Pedro Canario","localidad":"Pedro Canário","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.300481856599557,"longitud":-39.95781862783607,"ranking":0},
{"id":6219,"nombre":"Pinheiros","localidad":"Pinheiros","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.41216582309324,"longitud":-40.2139976404329,"ranking":0},
{"id":4993,"nombre":"Piuma","localidad":"Piúma","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.8358114544173,"longitud":-40.72154197116411,"ranking":0},
{"id":6228,"nombre":"Ponto Belo","localidad":"Ponto Belo","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.123672372753052,"longitud":-40.53923184606196,"ranking":0},
{"id":10512,"nombre":"Presidente Kennedy","localidad":"Presidente Kennedy","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12429,"nombre":"Sao Jorge do Tiradentes","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12435,"nombre":"Rio Bananal","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12501,"nombre":"Sangalia","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12502,"nombre":"Simao","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12503,"nombre":"Tiradentes","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12535,"nombre":"Sao Paulo","localidad":"Rio Bananal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5002,"nombre":"Rio Novo do Sul","localidad":"Rio Novo do Sul","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.864695821941968,"longitud":-40.933588692555645,"ranking":0},
{"id":12426,"nombre":"Santa Leopoldina","localidad":"Santa Leopoldina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12434,"nombre":"Barra de Mangarai","localidad":"Santa Leopoldina","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12424,"nombre":"Santa Maria de Jetiba","localidad":"Santa Maria de Jetibá","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12506,"nombre":"Caldeirão","localidad":"Santa Maria de Jetibá","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12507,"nombre":"Sao Sebastiao","localidad":"Santa Maria de Jetibá","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12439,"nombre":"Santa Teresa","localidad":"Santa Teresa","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12508,"nombre":"Divisa","localidad":"Santa Teresa","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12509,"nombre":"Boca do Mato","localidad":"Santa Teresa","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7376,"nombre":"Sao Domingos do Norte","localidad":"São Domingos do Norte","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.14250088029499,"longitud":-40.621618499915904,"ranking":0},
{"id":6246,"nombre":"Sao Gabriel da Palha","localidad":"São Gabriel da Palha","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.01846099046478,"longitud":-40.535054707729465,"ranking":0},
{"id":6086,"nombre":"Sao Jose do Calcado","localidad":"São José do Calçado","provincia":"Espirito Santo","pais":"BRASIL","latitud":-21.028591971378905,"longitud":-41.65233060489495,"ranking":0},
{"id":5011,"nombre":"Sao Mateus","localidad":"São Mateus","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.7157,"longitud":-39.8569,"ranking":0},
{"id":9945,"nombre":"Guriri","localidad":"São Mateus","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.71565844210602,"longitud":-39.856732496263284,"ranking":0},
{"id":7380,"nombre":"Sao Roque do Canaa","localidad":"São Roque do Canaã","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.74036519505572,"longitud":-40.657921333425435,"ranking":0},
{"id":9946,"nombre":"Jacaraipe","localidad":"Serra","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.123248249051464,"longitud":-40.304594057951256,"ranking":0},
{"id":10571,"nombre":"Serra ","localidad":"Serra","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.123094473442425,"longitud":-40.30450951355441,"ranking":0},
{"id":6253,"nombre":"Sooretama","localidad":"Sooretama","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.190697868771093,"longitud":-40.09544670620692,"ranking":0},
{"id":5027,"nombre":"Venda Nova do Imigrante","localidad":"Venda Nova do Imigrante","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.33411999159027,"longitud":-41.12924685453845,"ranking":0},
{"id":7113,"nombre":"Venda Nova","localidad":"Venda Nova do Imigrante","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.332547068885283,"longitud":-41.129600135610936,"ranking":0},
{"id":9968,"nombre":"Sao Joao de Vicosa","localidad":"Venda Nova do Imigrante","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.334017129302957,"longitud":-41.129200477708544,"ranking":0},
{"id":6265,"nombre":"Viana","localidad":"Viana","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.354200845428085,"longitud":-40.41744700823371,"ranking":0},
{"id":6270,"nombre":"Vila Pavao","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.621940820818825,"longitud":-40.60544238479086,"ranking":0},
{"id":12554,"nombre":"Corr. Peneira","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12510,"nombre":"Chapadinha","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12511,"nombre":"Praca Rica","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12512,"nombre":"Todos os Santos","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12537,"nombre":"Fazenda Trevisani","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12538,"nombre":"Fazenda Calmon Neto","localidad":"Vila Pavão","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5776,"nombre":"Angra dos Reis","localidad":"Angra dos Reis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.00286120264166,"longitud":-44.30414915084839,"ranking":81},
{"id":15287,"nombre":"Pereque","localidad":"Angra dos Reis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.020426278051517,"longitud":-44.534887436831305,"ranking":0},
{"id":5777,"nombre":"Aperibe","localidad":"Aperibé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.62692196441048,"longitud":-42.10167405771002,"ranking":0},
{"id":5778,"nombre":"Araruama","localidad":"Araruama","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.87330851060763,"longitud":-42.33578502955376,"ranking":0},
{"id":5958,"nombre":"Areal","localidad":"Areal","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.235493542107182,"longitud":-43.10206493072846,"ranking":0},
{"id":5784,"nombre":"Buzios","localidad":"Armação dos Búzios","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.7590655,"longitud":-41.8882512,"ranking":188},
{"id":5779,"nombre":"Arraial do Cabo","localidad":"Arraial do Cabo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.967167,"longitud":-42.024016,"ranking":31},
{"id":5780,"nombre":"Barra do Pirai","localidad":"Barra do Piraí","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.47084434527075,"longitud":-43.82477438222794,"ranking":0},
{"id":4997,"nombre":"Barra Mansa","localidad":"Barra Mansa","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.545957,"longitud":-44.16868950000001,"ranking":1},
{"id":15003,"nombre":"Belford Roxo","localidad":"Belford Roxo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.760710536692923,"longitud":-43.403464858950095,"ranking":0},
{"id":5782,"nombre":"Bom Jardim","localidad":"Bom Jardim","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.152469381099113,"longitud":-42.4179654493308,"ranking":0},
{"id":5783,"nombre":"Bom Jesus Itabapoana","localidad":"Bom Jesus do Itabapoana","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.1346407,"longitud":-41.6779806,"ranking":0},
{"id":5785,"nombre":"Cabo Frio","localidad":"Cabo Frio","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.8820406,"longitud":-42.0298015,"ranking":6},
{"id":5807,"nombre":"Jardim Esperanca","localidad":"Cabo Frio","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.88206451613616,"longitud":-42.02993531019085,"ranking":0},
{"id":5786,"nombre":"Cachoeiras De Macacu","localidad":"Cachoeiras de Macacu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.465825171177514,"longitud":-42.654494221557385,"ranking":0},
{"id":5788,"nombre":"Cambuci","localidad":"Cambuci","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.57707267924263,"longitud":-41.912539178933415,"ranking":0},
{"id":5793,"nombre":"Carapebus","localidad":"Carapebus","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.195962315575443,"longitud":-41.66404280455182,"ranking":0},
{"id":9548,"nombre":"Comendador Levy Gasparian","localidad":"Comendador Levy Gasparian","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5792,"nombre":"Cantagalo","localidad":"Cantagalo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.981026004270394,"longitud":-42.36557219815134,"ranking":0},
{"id":5794,"nombre":"Cardoso Moreira","localidad":"Cardoso Moreira","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.488945146487136,"longitud":-41.61611770503149,"ranking":0},
{"id":5795,"nombre":"Casimiro De Abreu","localidad":"Casimiro de Abreu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.477274554397688,"longitud":-42.20193556379476,"ranking":0},
{"id":5796,"nombre":"Conceicao de Macabu","localidad":"Conceição de Macabu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.08246273785299,"longitud":-41.868278531178895,"ranking":0},
{"id":5797,"nombre":"Cordeiro","localidad":"Cordeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.02777766963008,"longitud":-42.362456100035146,"ranking":0},
{"id":7378,"nombre":"Engenheiro Paulo de Fronti","localidad":"Engenheiro Paulo de Frontin","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.549413644532386,"longitud":-43.67797036830033,"ranking":0},
{"id":14727,"nombre":"Guapimirim","localidad":"Guapimirim","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.52087247554955,"longitud":-42.979543160899,"ranking":0},
{"id":14732,"nombre":"Iguaba Grande","localidad":"Iguaba Grande","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.8408186,"longitud":-42.2117993,"ranking":0},
{"id":5800,"nombre":"Itaborai","localidad":"Itaboraí","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.737369372664144,"longitud":-42.835959947533894,"ranking":0},
{"id":5944,"nombre":"Itaguai","localidad":"Itaguaí","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.870338372943884,"longitud":-43.769000113776286,"ranking":2},
{"id":5801,"nombre":"Italva","localidad":"Italva","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.431023065825663,"longitud":-41.69048971985389,"ranking":0},
{"id":5802,"nombre":"Itaocara","localidad":"Itaocara","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.666039006003476,"longitud":-42.07907235514629,"ranking":0},
{"id":4955,"nombre":"Itaperuna","localidad":"Itaperuna","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.2004972,"longitud":-41.898813,"ranking":0},
{"id":5772,"nombre":"Itatiaia","localidad":"Itatiaia","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.49019634089424,"longitud":-44.567913548160206,"ranking":0},
{"id":9240,"nombre":"Penedo","localidad":"Itatiaia","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.443026161132494,"longitud":-44.522700284081566,"ranking":0},
{"id":4868,"nombre":"Guandu","localidad":"Japeri","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5774,"nombre":"Japeri","localidad":"Japeri","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.643454269314645,"longitud":-43.66060744262362,"ranking":0},
{"id":10534,"nombre":"Laje do Muriae","localidad":"Laje do Muriaé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5814,"nombre":"Macuco","localidad":"Macuco","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.98659659005028,"longitud":-42.25486217669609,"ranking":0},
{"id":5816,"nombre":"Mage","localidad":"Magé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.654078155725692,"longitud":-43.038772023699536,"ranking":0},
{"id":5943,"nombre":"Mangaratiba","localidad":"Mangaratiba","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.96140637562558,"longitud":-44.041941760975476,"ranking":3},
{"id":5946,"nombre":"Itacuruca","localidad":"Mangaratiba","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.9380707744893,"longitud":-44.039307164895,"ranking":0},
{"id":14997,"nombre":"Marica","localidad":"Maricá","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.9160048,"longitud":-42.81526179999999,"ranking":0},
{"id":5924,"nombre":"Mendes","localidad":"Mendes","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.525866309724705,"longitud":-43.73181250121726,"ranking":0},
{"id":5930,"nombre":"Miguel Pereira","localidad":"Miguel Pereira","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.46154681835219,"longitud":-43.482385884080884,"ranking":0},
{"id":4975,"nombre":"Miracema","localidad":"Miracema","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.41608436805006,"longitud":-42.20162298652606,"ranking":0},
{"id":5838,"nombre":"Natividade","localidad":"Natividade","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.043174076504243,"longitud":-41.97767405102135,"ranking":0},
{"id":5956,"nombre":"Nilopolis","localidad":"Nilópolis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.81031441495814,"longitud":-43.41431021661289,"ranking":0},
{"id":10444,"nombre":"Nova Friburgo (Norte)","localidad":"Nova Friburgo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":4984,"nombre":"Nova Iguacu","localidad":"Nova Iguaçu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.757864075251437,"longitud":-43.4558529781546,"ranking":1},
{"id":5922,"nombre":"Paracambi","localidad":"Paracambi","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.616952938670643,"longitud":-43.71494092071611,"ranking":0},
{"id":5975,"nombre":"Paraiba do Sul","localidad":"Paraíba do Sul","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.148269604250732,"longitud":-43.28538721792741,"ranking":0},
{"id":5942,"nombre":"Paraty","localidad":"Paraty","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.2213543,"longitud":-44.7191894,"ranking":178},
{"id":15250,"nombre":"Trindade","localidad":"Paraty","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-23.221765711484665,"longitud":-44.71894206687128,"ranking":0},
{"id":5932,"nombre":"Paty do Alferes","localidad":"Paty do Alferes","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.406290207340085,"longitud":-43.42216648025674,"ranking":0},
{"id":5935,"nombre":"Pirai","localidad":"Piraí","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.629527495650294,"longitud":-43.89707357400542,"ranking":6},
{"id":5860,"nombre":"Porciuncula","localidad":"Porciúncula","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-20.96355896152061,"longitud":-42.038699889112586,"ranking":0},
{"id":15002,"nombre":"Queimados","localidad":"Queimados","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.712885190831187,"longitud":-43.56290872052915,"ranking":0},
{"id":5866,"nombre":"Quissama","localidad":"Quissamã","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.103539441041473,"longitud":-41.46964377630237,"ranking":0},
{"id":4999,"nombre":"Resende","localidad":"Resende","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4598,"longitud":-44.43931,"ranking":3},
{"id":15336,"nombre":"Manejo","localidad":"Resende","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4597989,"longitud":-44.439304835581986,"ranking":0},
{"id":5871,"nombre":"Rio Bonito","localidad":"Rio Bonito","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.709779111664925,"longitud":-42.62733328919193,"ranking":0},
{"id":5948,"nombre":"Rio Claro","localidad":"Rio Claro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.726183612403396,"longitud":-44.12930215890068,"ranking":0},
{"id":14993,"nombre":"Rio das Flores","localidad":"Rio das Flores","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.167576414765605,"longitud":-43.58024790527365,"ranking":0},
{"id":5875,"nombre":"Santa Maria Madalena","localidad":"Santa Maria Madalena","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.961101297794457,"longitud":-42.010694542295006,"ranking":0},
{"id":5005,"nombre":"Santo Antonio de Padua","localidad":"Santo Antônio de Pádua","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.54840294097092,"longitud":-42.175343949870346,"ranking":0},
{"id":5880,"nombre":"Sao Francisco De Itabapoana","localidad":"São Francisco de Itabapoana","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.474376095092648,"longitud":-41.117378062064766,"ranking":0},
{"id":5879,"nombre":"Sao Fidelis","localidad":"São Fidélis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.645800830481804,"longitud":-41.748167703082686,"ranking":0},
{"id":4872,"nombre":"Piao","localidad":"São Gonçalo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5775,"nombre":"Alcantara","localidad":"São Gonçalo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.82023566212115,"longitud":-43.001084988118535,"ranking":0},
{"id":5012,"nombre":"Sao Joao da Barra","localidad":"São João da Barra","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.633213653435416,"longitud":-21.633213653435416,"ranking":0},
{"id":5891,"nombre":"Sao Joao de Meriti","localidad":"São João de Meriti","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.798871207322915,"longitud":-43.37260020268255,"ranking":0},
{"id":5896,"nombre":"Sao Jose de Uba","localidad":"São José de Ubá","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.36342047910777,"longitud":-41.93936732894198,"ranking":0},
{"id":9553,"nombre":"São José do Vale do Rio Preto","localidad":"São José do Vale do Rio Preto","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.14931744266556,"longitud":-42.920910024784746,"ranking":0},
{"id":5900,"nombre":"Sao Pedro da Aldeia","localidad":"São Pedro da Aldeia","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.833029585506488,"longitud":-42.10478373874304,"ranking":2},
{"id":15038,"nombre":"Sao Sebastiao do Alto","localidad":"São Sebastião do Alto","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.956444,"longitud":-42.1347484,"ranking":0},
{"id":5906,"nombre":"Sapucaia","localidad":"Sapucaia","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.9944680514193,"longitud":-42.913381229335684,"ranking":0},
{"id":5912,"nombre":"Saquarema","localidad":"Saquarema","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.9298036,"longitud":-42.4883899,"ranking":0},
{"id":15059,"nombre":"Seropedica","localidad":"Seropédica","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.742952570305416,"longitud":-43.70377756964594,"ranking":0},
{"id":10157,"nombre":"Tangua","localidad":"Tanguá","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.736018170797426,"longitud":-42.72440523369135,"ranking":0},
{"id":5976,"nombre":"Teresopolis","localidad":"Teresópolis","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4163238,"longitud":-42.9701592,"ranking":1},
{"id":5081,"nombre":"Tres Rios","localidad":"Três Rios","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.114170935823243,"longitud":-43.20584251803926,"ranking":0},
{"id":5940,"nombre":"Valenca","localidad":"Valença","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.247538306160955,"longitud":-43.70778795606755,"ranking":0},
{"id":5773,"nombre":"Vassouras","localidad":"Vassouras","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.404678650133796,"longitud":-43.665126452502314,"ranking":0},
{"id":8916,"nombre":"Adamantina","localidad":"Adamantina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.68475470734259,"longitud":-51.070014191021905,"ranking":0},
{"id":10250,"nombre":"Aguai","localidad":"Aguaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.05752958303307,"longitud":-46.982233061176125,"ranking":0},
{"id":5806,"nombre":"Aguas da Prata","localidad":"Águas da Prata","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.93569821468296,"longitud":-46.71554656984618,"ranking":0},
{"id":5895,"nombre":"Aguas de Lindoia","localidad":"Águas de Lindóia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.47935708967218,"longitud":-46.63535815841531,"ranking":0},
{"id":8986,"nombre":"Aguas de Santa Barbara","localidad":"Águas de Santa Bárbara","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.879028184740545,"longitud":-49.23948788522469,"ranking":0},
{"id":10175,"nombre":"Aguas de Sao Pedro","localidad":"Águas de São Pedro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.600655898748126,"longitud":-47.87439289703158,"ranking":0},
{"id":12310,"nombre":"Agudos","localidad":"Agudos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.4658439,"longitud":-48.9885531,"ranking":0},
{"id":8198,"nombre":"Alambari","localidad":"Alambari","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.551118322838146,"longitud":-47.90067195268858,"ranking":0},
{"id":8212,"nombre":"Altair","localidad":"Altair","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.52394367612537,"longitud":-49.05772832953662,"ranking":0},
{"id":8842,"nombre":"Altinopolis","localidad":"Altinópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.02293557224254,"longitud":-47.37235261593059,"ranking":0},
{"id":10149,"nombre":"Aluminio","localidad":"Alumínio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.535154811722293,"longitud":-47.261277886282,"ranking":0},
{"id":5898,"nombre":"Alvares Machado","localidad":"Álvares Machado","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.076326814712594,"longitud":-51.466976926036416,"ranking":0},
{"id":12356,"nombre":"Alvinlandia ","localidad":"Alvinlândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.4415777,"longitud":-49.7638902,"ranking":0},
{"id":4910,"nombre":"Americana","localidad":"Americana","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.73439599054664,"longitud":-47.3105695,"ranking":0},
{"id":10247,"nombre":"Americo Brasiliense","localidad":"Américo Brasiliense","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.72266905083651,"longitud":-48.10259546545751,"ranking":0},
{"id":10176,"nombre":"Analandia","localidad":"Analândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.13142205293668,"longitud":-47.65703458874976,"ranking":0},
{"id":5585,"nombre":"Andradina","localidad":"Andradina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.90255160216704,"longitud":-51.37776338853204,"ranking":0},
{"id":8646,"nombre":"Angatuba ","localidad":"Angatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.48958630746934,"longitud":-48.41037689637272,"ranking":0},
{"id":8647,"nombre":"Apiai","localidad":"Apiaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.508102141323395,"longitud":-48.84886122965109,"ranking":0},
{"id":5835,"nombre":"Aracariguama","localidad":"Araçariguama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.44265042160914,"longitud":-47.061968737869286,"ranking":0},
{"id":5901,"nombre":"Aracatuba","localidad":"Araçatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.216518295273207,"longitud":-50.43701836660659,"ranking":0},
{"id":10177,"nombre":"Aracoiaba da Serra","localidad":"Araçoiaba da Serra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.504763022181955,"longitud":-47.61865461106101,"ranking":0},
{"id":5854,"nombre":"Aramina ","localidad":"Aramina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.087881681849836,"longitud":-47.78799085670162,"ranking":0},
{"id":8987,"nombre":"Arandu","localidad":"Arandu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.134173137112512,"longitud":-49.051255623077196,"ranking":0},
{"id":12334,"nombre":"Arapei","localidad":"Arapeí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.67209,"longitud":-44.44759000000001,"ranking":0},
{"id":5307,"nombre":"Araraquara ","localidad":"Araraquara","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.8071638,"longitud":-48.1745475,"ranking":0},
{"id":5867,"nombre":"Araras","localidad":"Araras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.3589139,"longitud":-47.39155969999999,"ranking":0},
{"id":5945,"nombre":"Fazenda Arco-Iris","localidad":"Arco-Íris","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.77121475002156,"longitud":-50.4661779714754,"ranking":0},
{"id":12335,"nombre":"Areias","localidad":"Areias","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5829619,"longitud":-44.702429,"ranking":0},
{"id":12311,"nombre":"Areiopolis","localidad":"Areiópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.6716824,"longitud":-48.6647385,"ranking":0},
{"id":5829,"nombre":"Ariranha ","localidad":"Ariranha","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.188034894667048,"longitud":-48.78690733919501,"ranking":0},
{"id":10178,"nombre":"Artur Nogueira","localidad":"Artur Nogueira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.573443720027857,"longitud":-47.17166396399205,"ranking":0},
{"id":12336,"nombre":"Aruja","localidad":"Arujá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.3947053,"longitud":-46.3241244,"ranking":0},
{"id":5902,"nombre":"Assis","localidad":"Assis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.656522013437606,"longitud":-50.41789350572494,"ranking":0},
{"id":6254,"nombre":"Atibaia","localidad":"Atibaia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.114830985047632,"longitud":-46.55810641254683,"ranking":0},
{"id":10683,"nombre":"Auriflama","localidad":"Auriflama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.6838124,"longitud":-50.5509557,"ranking":0},
{"id":10405,"nombre":"Avai","localidad":"Avaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.156339469819322,"longitud":-49.33223820612665,"ranking":0},
{"id":9251,"nombre":"Avanhandava","localidad":"Avanhandava","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.465542806082922,"longitud":-49.944824234373385,"ranking":0},
{"id":8855,"nombre":"Avare","localidad":"Avaré","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.09593587856738,"longitud":-48.928404502978466,"ranking":3},
{"id":10532,"nombre":"Bady Bassitt","localidad":"Bady Bassitt","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10684,"nombre":"Balsamo","localidad":"Bálsamo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.73286418334222,"longitud":-49.58279706526709,"ranking":0},
{"id":12337,"nombre":"Bananal","localidad":"Bananal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.68096,"longitud":-44.32218,"ranking":0},
{"id":9522,"nombre":"Barão de Antonina","localidad":"Barão de Antonina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.630903153346118,"longitud":-49.55906424563066,"ranking":0},
{"id":9252,"nombre":"Bariri","localidad":"Bariri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.511151430467283,"longitud":-46.8722868382892,"ranking":0},
{"id":9526,"nombre":"Barra Bonita","localidad":"Barra Bonita","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.48596106321128,"longitud":-48.54878208384035,"ranking":0},
{"id":5868,"nombre":"Barretos","localidad":"Barretos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.55446741071248,"longitud":-48.57864566608898,"ranking":0},
{"id":10454,"nombre":"Barrinha","localidad":"Barrinha","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.19181527160023,"longitud":-48.16401509671244,"ranking":0},
{"id":10179,"nombre":"Barueri","localidad":"Barueri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.51116697965185,"longitud":-46.87227744328596,"ranking":0},
{"id":8997,"nombre":"Bastos","localidad":"Bastos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.92086891141436,"longitud":-50.72927124080271,"ranking":0},
{"id":5251,"nombre":"Batatais","localidad":"Batatais","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.886355561144512,"longitud":-47.58429067337567,"ranking":0},
{"id":5920,"nombre":"Entrocamento Batatais","localidad":"Batatais","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.88650209660708,"longitud":-47.58430220465289,"ranking":0},
{"id":5870,"nombre":"Bauru","localidad":"Bauru","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.312621897250654,"longitud":-49.06863212585449,"ranking":8},
{"id":5872,"nombre":"Bebedouro","localidad":"Bebedouro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.93807863047761,"longitud":-48.48896444896641,"ranking":0},
{"id":9253,"nombre":"Bento de Abreu","localidad":"Bento de Abreu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.27200707624222,"longitud":-50.81211940140902,"ranking":0},
{"id":8843,"nombre":"Bernardino de Campos","localidad":"Bernardino de Campos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.017559687696806,"longitud":-49.47349344500648,"ranking":0},
{"id":8641,"nombre":"Bertioga ","localidad":"Bertioga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.804127316568675,"longitud":-46.0806372152865,"ranking":0},
{"id":15093,"nombre":"Riviera de Sao Lourenco","localidad":"Bertioga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.79562,"longitud":-46.02402,"ranking":0},
{"id":9055,"nombre":"Birigui","localidad":"Birigui","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.292956390933384,"longitud":-50.34755500614553,"ranking":0},
{"id":9254,"nombre":"Boa Esperança do Sul","localidad":"Boa Esperança do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.99275487482713,"longitud":-48.389419572983144,"ranking":0},
{"id":9400,"nombre":"Bocaina","localidad":"Bocaina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.1420148906852,"longitud":-48.52267281026654,"ranking":0},
{"id":10180,"nombre":"Bofete","localidad":"Bofete","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.100622854933867,"longitud":-48.26371577816367,"ranking":0},
{"id":5873,"nombre":"Boituva","localidad":"Boituva","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.284425525391075,"longitud":-47.67578253321923,"ranking":0},
{"id":9255,"nombre":"Boracéia","localidad":"Boracéia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.196398795371767,"longitud":-48.77859656108963,"ranking":0},
{"id":8996,"nombre":"Borborema","localidad":"Borborema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.621315817030236,"longitud":-49.07793504071218,"ranking":0},
{"id":5874,"nombre":"Botucatu","localidad":"Botucatu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.87811526143006,"longitud":-48.44619789055028,"ranking":0},
{"id":5267,"nombre":"Braganca Paulista ","localidad":"Bragança Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.94066670750903,"longitud":-46.53910258898079,"ranking":0},
{"id":5907,"nombre":"Acesso Brauna","localidad":"Braúna","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.50164368561475,"longitud":-50.316149447492734,"ranking":0},
{"id":5921,"nombre":"Entrocamento Brauna","localidad":"Braúna","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.501585677832338,"longitud":-50.316158367939245,"ranking":0},
{"id":5808,"nombre":"Brodowski","localidad":"Brodowski","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.98957332778156,"longitud":-47.65962817035402,"ranking":0},
{"id":8965,"nombre":"Brotas","localidad":"Brotas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.27712255245205,"longitud":-48.122491987701096,"ranking":0},
{"id":8649,"nombre":"Buri ","localidad":"Buri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.798572190119312,"longitud":-48.59447520774077,"ranking":0},
{"id":10069,"nombre":"Buritizal","localidad":"Buritizal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.193949573973516,"longitud":-47.70775970673404,"ranking":0},
{"id":5817,"nombre":"Cacapava ","localidad":"Caçapava","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.11012368736763,"longitud":-45.708228124490326,"ranking":0},
{"id":5818,"nombre":"Cachoeira Paulista","localidad":"Cachoeira Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.661986229478504,"longitud":-45.00782366874823,"ranking":0},
{"id":10251,"nombre":"Caconde","localidad":"Caconde","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.533102530314807,"longitud":-46.65009847770272,"ranking":0},
{"id":9256,"nombre":"Cafelândia","localidad":"Cafelândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.806390241142896,"longitud":-49.6046838872098,"ranking":0},
{"id":5909,"nombre":"Caiua","localidad":"Caiuá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.831586641825368,"longitud":-51.985912618044516,"ranking":0},
{"id":6271,"nombre":"Cajati","localidad":"Cajati","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.725544954797236,"longitud":-48.111238124693074,"ranking":0},
{"id":8197,"nombre":"Cajuru ","localidad":"Cajuru","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.276093166446884,"longitud":-47.29782432511118,"ranking":0},
{"id":14964,"nombre":"Campina do Monte Alegre","localidad":"Campina do Monte Alegre","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.59260046018916,"longitud":-48.48261368681732,"ranking":0},
{"id":5836,"nombre":"Campos do Jordao","localidad":"Campos do Jordão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.7247,"longitud":-45.58009999999999,"ranking":0},
{"id":10181,"nombre":"Cananeia","localidad":"Cananéia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-25.000533176706433,"longitud":-47.93400620215635,"ranking":0},
{"id":12338,"nombre":"Canas","localidad":"Canas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.70251,"longitud":-45.05237,"ranking":0},
{"id":5911,"nombre":"Candido Mota","localidad":"Cândido Mota","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.74698790585849,"longitud":-50.382135133013634,"ranking":0},
{"id":8847,"nombre":"Canitar","localidad":"Canitar","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.00826806733204,"longitud":-49.79158824918651,"ranking":0},
{"id":5068,"nombre":"Capao Bonito","localidad":"Capão Bonito","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.001715113594283,"longitud":-48.34488175586062,"ranking":0},
{"id":14330,"nombre":"Capao Bonito Do Sul","localidad":"Capão Bonito","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10182,"nombre":"Capela do Alto","localidad":"Capela do Alto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.468479660038966,"longitud":-47.73252244189543,"ranking":0},
{"id":10183,"nombre":"Capivari","localidad":"Capivari","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.008545152979284,"longitud":-47.52353650454497,"ranking":0},
{"id":5837,"nombre":"Caraguatatuba","localidad":"Caraguatatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.623705,"longitud":-45.4267063,"ranking":0},
{"id":10685,"nombre":"Cardoso","localidad":"Cardoso","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.081584480233683,"longitud":-49.91160210643479,"ranking":0},
{"id":10252,"nombre":"Casa Branca","localidad":"Casa Branca","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.77805548910584,"longitud":-47.07432029476199,"ranking":0},
{"id":9260,"nombre":"Castilho","localidad":"Castilho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.86820487492499,"longitud":-51.48755695096493,"ranking":0},
{"id":5824,"nombre":"Catanduva ","localidad":"Catanduva","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.125091539756898,"longitud":-48.98434017628908,"ranking":0},
{"id":12351,"nombre":"Cerqueira Cesar","localidad":"Cerqueira César","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.0343869,"longitud":-49.1638959,"ranking":0},
{"id":10248,"nombre":"Cerquilho","localidad":"Cerquilho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.159805678191102,"longitud":-47.74619405232046,"ranking":0},
{"id":10184,"nombre":"Cesario Lange","localidad":"Cesário Lange","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.223268308679323,"longitud":-47.95171112353806,"ranking":0},
{"id":10185,"nombre":"Charqueada","localidad":"Charqueada","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.51070283395512,"longitud":-47.773490013653415,"ranking":0},
{"id":8218,"nombre":"Colina ","localidad":"Colina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.714832756695643,"longitud":-48.53479989058704,"ranking":0},
{"id":5876,"nombre":"Colombia","localidad":"Colômbia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.17678633619812,"longitud":-48.68911396508893,"ranking":0},
{"id":10186,"nombre":"Conchal","localidad":"Conchal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.34297591278306,"longitud":-47.17086170492173,"ranking":0},
{"id":10187,"nombre":"Conchas","localidad":"Conchas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.016992256413936,"longitud":-48.00283986281981,"ranking":0},
{"id":10188,"nombre":"Cordeiropolis","localidad":"Cordeirópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.48306468286919,"longitud":-47.46060109391753,"ranking":0},
{"id":9262,"nombre":"Coroados","localidad":"Coroados","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.356582501490486,"longitud":-50.28457504755191,"ranking":0},
{"id":8869,"nombre":"Coronel Macedo","localidad":"Coronel Macedo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6327361887427,"longitud":-49.30921119199599,"ranking":0},
{"id":8967,"nombre":"Cosmopolis","localidad":"Cosmópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.643789172936884,"longitud":-47.1999106657538,"ranking":0},
{"id":10686,"nombre":"Cosmorama","localidad":"Cosmorama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.482150416350713,"longitud":-49.779835353255116,"ranking":0},
{"id":8200,"nombre":"Cotia","localidad":"Cotia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.607574119327058,"longitud":-46.92103861970922,"ranking":0},
{"id":10070,"nombre":"Cristais Paulista","localidad":"Cristais Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.406922594401436,"longitud":-47.42133337607278,"ranking":0},
{"id":5913,"nombre":"Cruzalia ","localidad":"Cruzália","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.743944350853635,"longitud":-50.79248751358871,"ranking":0},
{"id":5819,"nombre":"Cruzeiro ","localidad":"Cruzeiro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.583174073978213,"longitud":-44.96214748188053,"ranking":0},
{"id":5839,"nombre":"Cubatao","localidad":"Cubatão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.888130719527776,"longitud":-46.429133362794225,"ranking":0},
{"id":15292,"nombre":"Casqueiro","localidad":"Cubatão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.888147660296614,"longitud":-46.42913169041619,"ranking":0},
{"id":8193,"nombre":"Descalvado ","localidad":"Descalvado","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.90394873255029,"longitud":-47.618494897367356,"ranking":0},
{"id":5812,"nombre":"Diadema","localidad":"Diadema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.688510857065484,"longitud":-46.625331169022274,"ranking":0},
{"id":10253,"nombre":"Divinolandia","localidad":"Divinolândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.661628017245334,"longitud":-46.73608664232947,"ranking":0},
{"id":8964,"nombre":"Dois Corregos","localidad":"Dois Córregos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.3704302,"longitud":-48.3871218,"ranking":0},
{"id":10687,"nombre":"Dolcinopolis","localidad":"Dolcinópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.12135392193702,"longitud":-50.51562750054071,"ranking":0},
{"id":9264,"nombre":"Dourados","localidad":"Dourados","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.114179595099092,"longitud":-48.313619365711155,"ranking":0},
{"id":8998,"nombre":"Dracena","localidad":"Dracena","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.491299002630146,"longitud":-51.53880211585906,"ranking":0},
{"id":12312,"nombre":"Duartina","localidad":"Duartina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.416027,"longitud":-49.4116706,"ranking":0},
{"id":5914,"nombre":"Echapora","localidad":"Echaporã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.430563871535128,"longitud":-50.206687733716244,"ranking":0},
{"id":10212,"nombre":"Eldorado","localidad":"Eldorado","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.520438207758456,"longitud":-48.10958992727056,"ranking":0},
{"id":10213,"nombre":"Elias Fausto","localidad":"Elias Fausto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.060635760489816,"longitud":-47.304973336754685,"ranking":0},
{"id":9887,"nombre":"Embu das Artes","localidad":"Embu das Artes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6559,"longitud":-46.85275,"ranking":4},
{"id":10214,"nombre":"Engenheiro Coelho","localidad":"Engenheiro Coelho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.4905982756313,"longitud":-47.21180147621223,"ranking":0},
{"id":9917,"nombre":"Espirito Santo do Pinhal","localidad":"Espírito Santo do Pinhal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.195795391265893,"longitud":-46.75130372931208,"ranking":0},
{"id":8989,"nombre":"Espirito Santo do Turvo","localidad":"Espírito Santo do Turvo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.6960336,"longitud":-49.42904919999999,"ranking":0},
{"id":10688,"nombre":"Estrela d'Oeste","localidad":"Estrela d'Oeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.284272185140438,"longitud":-50.40122545733054,"ranking":0},
{"id":5939,"nombre":"Estrela do Norte","localidad":"Estrela do Norte","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.488087167454506,"longitud":-51.66014505873046,"ranking":0},
{"id":5941,"nombre":"Euclides da Cunha Paulista","localidad":"Euclides da Cunha Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.556561850920698,"longitud":-52.594613383493986,"ranking":0},
{"id":8866,"nombre":"Fartura","localidad":"Fartura","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.3873545,"longitud":-49.50664680000001,"ranking":0},
{"id":10243,"nombre":"Fernandopolis","localidad":"Fernandópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.273594653166533,"longitud":-50.25295403820147,"ranking":0},
{"id":8968,"nombre":"Ferraz de Vasconcelos","localidad":"Ferraz de Vasconcelos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.51178880240673,"longitud":-46.36601443402991,"ranking":0},
{"id":10689,"nombre":"Floreal","localidad":"Floreal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.678111224267422,"longitud":-50.14807244377772,"ranking":0},
{"id":8999,"nombre":"Florida Paulista","localidad":"Flórida Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.61362080912785,"longitud":-51.170958860376814,"ranking":0},
{"id":5247,"nombre":"Franca","localidad":"Franca","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.5356778,"longitud":-47.40351,"ranking":0},
{"id":5919,"nombre":"Entrocamento Franca","localidad":"Franca","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12313,"nombre":"Galia","localidad":"Gália","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.2941278,"longitud":-49.5543354,"ranking":0},
{"id":5952,"nombre":"Garca","localidad":"Garça","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.21172111293571,"longitud":-49.66023685681943,"ranking":0},
{"id":5832,"nombre":"Gaviao Peixoto","localidad":"Gavião Peixoto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.837469633396978,"longitud":-48.4904984626734,"ranking":0},
{"id":10690,"nombre":"General Salgado","localidad":"General Salgado","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.643071091628567,"longitud":-50.35895613336506,"ranking":0},
{"id":9056,"nombre":"Getulina","localidad":"Getulina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.79949643916333,"longitud":-49.930032945029716,"ranking":0},
{"id":9265,"nombre":"Glicério","localidad":"Glicério","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.38127871879043,"longitud":-50.209320970170964,"ranking":0},
{"id":9274,"nombre":"Guaiçara","localidad":"Guaiçara","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.623255768373994,"longitud":-49.79702519423394,"ranking":0},
{"id":9278,"nombre":"Guaimbê","localidad":"Guaimbê","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.91015892249444,"longitud":-49.89543340851353,"ranking":0},
{"id":8219,"nombre":"Guaira","localidad":"Guaíra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.32503106987343,"longitud":-48.31239954897963,"ranking":0},
{"id":8650,"nombre":"Guapiara","localidad":"Guapiara","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.189360261758214,"longitud":-48.53387529273213,"ranking":0},
{"id":5856,"nombre":"Guara","localidad":"Guará","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.427279164578877,"longitud":-47.82954114798573,"ranking":0},
{"id":10406,"nombre":"Guaracaí","localidad":"Guaraçaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.032222556026603,"longitud":-51.207940874679096,"ranking":0},
{"id":8216,"nombre":"Guaraci ","localidad":"Guaraci","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.49501218536541,"longitud":-48.94278238076604,"ranking":0},
{"id":10691,"nombre":"Guarani d'Oeste","localidad":"Guarani d'Oeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.075701112873247,"longitud":-50.34076727924323,"ranking":0},
{"id":9057,"nombre":"Guaranta","localidad":"Guarantã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.89908118732291,"longitud":-49.589588139565244,"ranking":0},
{"id":9291,"nombre":"Guararapes","localidad":"Guararapes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.256928226601474,"longitud":-50.64197053965305,"ranking":0},
{"id":12339,"nombre":"Guararema","localidad":"Guararema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.4128,"longitud":-46.0451,"ranking":0},
{"id":5176,"nombre":"Guaratingueta","localidad":"Guaratinguetá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.819933109915382,"longitud":-45.19528148413903,"ranking":0},
{"id":10215,"nombre":"Guarei","localidad":"Guareí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.369304284398268,"longitud":-48.185069199633205,"ranking":0},
{"id":5840,"nombre":"Guaruja","localidad":"Guarujá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.984418886940546,"longitud":-46.26661684868958,"ranking":1},
{"id":15094,"nombre":"Vicente de Carvalho","localidad":"Guarujá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.9407411,"longitud":-46.3064255,"ranking":0},
{"id":5954,"nombre":"Guarulhos","localidad":"Guarulhos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.42481,"longitud":-46.47994,"ranking":2},
{"id":10692,"nombre":"Guzolandia","localidad":"Guzolândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.647832795480355,"longitud":-50.662756145088366,"ranking":0},
{"id":14960,"nombre":"Herculandia","localidad":"Herculândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.0042,"longitud":-50.38787,"ranking":0},
{"id":10216,"nombre":"Hortolandia","localidad":"Hortolândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.867041869991024,"longitud":-47.22554049486499,"ranking":0},
{"id":9918,"nombre":"Iacanga","localidad":"Iacanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.89205016935197,"longitud":-49.021495343434246,"ranking":0},
{"id":12309,"nombre":"Iacri","localidad":"Iacri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.8623828,"longitud":-50.6913528,"ranking":0},
{"id":5959,"nombre":"Iaras","localidad":"Iaras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.876440870786148,"longitud":-49.159078100035146,"ranking":0},
{"id":9920,"nombre":"Ibate","localidad":"Ibaté","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.954515064996883,"longitud":-48.00032114950367,"ranking":0},
{"id":5827,"nombre":"Ibira (Sao Paulo / BRASIL)","localidad":"Ibirá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.078734819986966,"longitud":-49.244396808513386,"ranking":0},
{"id":5962,"nombre":"Ibirarema","localidad":"Ibirarema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.811792544408437,"longitud":-50.06887156839507,"ranking":0},
{"id":5967,"nombre":"Ibitinga","localidad":"Ibitinga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.75591935758442,"longitud":-48.83348571130918,"ranking":0},
{"id":8201,"nombre":"Ibiuna","localidad":"Ibiúna","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.65133439996416,"longitud":-47.223197482179515,"ranking":0},
{"id":10693,"nombre":"Icem","localidad":"Icém","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.336844978832826,"longitud":-49.19635509219849,"ranking":0},
{"id":6081,"nombre":"Iepe","localidad":"Iepê","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.659986154848003,"longitud":-51.07329114400837,"ranking":0},
{"id":9527,"nombre":"Igaraçu do Tietê","localidad":"Igaraçu do Tietê","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5093964674498,"longitud":-48.56015029728019,"ranking":0},
{"id":5858,"nombre":"Igarapava","localidad":"Igarapava","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.043414974485202,"longitud":-47.76270286685408,"ranking":0},
{"id":8970,"nombre":"Igarata","localidad":"Igaratá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.20386223388164,"longitud":-46.155719136804166,"ranking":0},
{"id":6273,"nombre":"Iguape","localidad":"Iguape","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.695243281169912,"longitud":-47.5634889460323,"ranking":0},
{"id":14959,"nombre":"Ilhabela","localidad":"Ilhabela","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.819951388698072,"longitud":-45.37628555990768,"ranking":22},
{"id":10217,"nombre":"Ilha Comprida","localidad":"Ilha Comprida","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.72557334982414,"longitud":-47.530889291895576,"ranking":0},
{"id":5586,"nombre":"Ilha Solteira","localidad":"Ilha Solteira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.434783924959405,"longitud":-51.3392370357614,"ranking":0},
{"id":6244,"nombre":"Indaiatuba","localidad":"Indaiatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-18.562981,"longitud":-51.1311207,"ranking":0},
{"id":10694,"nombre":"Indiapora","localidad":"Indiaporã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.725572072038393,"longitud":-47.53089979490645,"ranking":0},
{"id":9018,"nombre":"Inubia Paulista","localidad":"Inúbia Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.766149632297942,"longitud":-50.9604539939497,"ranking":0},
{"id":9390,"nombre":"Ipaussu","localidad":"Ipaussu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.05339472684037,"longitud":-49.62305662491251,"ranking":0},
{"id":10218,"nombre":"Ipero","localidad":"Iperó","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.34774338287757,"longitud":-47.689889331507814,"ranking":0},
{"id":10219,"nombre":"Ipeuna","localidad":"Ipeúna","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.433776189950194,"longitud":-47.71426583331529,"ranking":0},
{"id":10220,"nombre":"Iporanga","localidad":"Iporanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.584068858153703,"longitud":-48.5923919874331,"ranking":0},
{"id":8205,"nombre":"Ipuã","localidad":"Ipuã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.44453902115369,"longitud":-48.0117851027261,"ranking":0},
{"id":10221,"nombre":"Iracemapolis","localidad":"Iracemápolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.585022030512594,"longitud":-47.518064352929756,"ranking":0},
{"id":9016,"nombre":"Irapuru","localidad":"Irapuru","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.56960115575869,"longitud":-51.349201508692566,"ranking":0},
{"id":8644,"nombre":"Itabera","localidad":"Itaberá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.85769767426086,"longitud":-49.143435097741964,"ranking":0},
{"id":8857,"nombre":"Itai","localidad":"Itaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.41657,"longitud":-49.09155,"ranking":0},
{"id":8961,"nombre":"Itanhaem","localidad":"Itanhaém","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.184418497670816,"longitud":-46.8181267658802,"ranking":0},
{"id":5069,"nombre":"Itapetininga","localidad":"Itapetininga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.58136,"longitud":-48.03261999999999,"ranking":0},
{"id":5071,"nombre":"Itapeva ","localidad":"Itapeva","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.769621450559015,"longitud":-46.22445386008931,"ranking":0},
{"id":14995,"nombre":"Itapevi","localidad":"Itapevi","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5087,"nombre":"Itapira","localidad":"Itapira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.429407259047224,"longitud":-46.82301586075853,"ranking":0},
{"id":8991,"nombre":"Itapolis ","localidad":"Itápolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.593024712497584,"longitud":-48.822821097595835,"ranking":1},
{"id":8645,"nombre":"Itaporanga ","localidad":"Itaporanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.708103661080806,"longitud":-49.48445620021602,"ranking":0},
{"id":9289,"nombre":"Itapuí","localidad":"Itapuí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.234044268397888,"longitud":-48.723134281407496,"ranking":0},
{"id":9391,"nombre":"Itapura","localidad":"Itapura","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.64220260974946,"longitud":-51.508002404459056,"ranking":0},
{"id":12340,"nombre":"Itaquaquecetuba","localidad":"Itaquaquecetuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.5119136,"longitud":-46.3657584,"ranking":0},
{"id":5070,"nombre":"Itarare","localidad":"Itararé","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.112548108182498,"longitud":-49.33758130712542,"ranking":0},
{"id":10222,"nombre":"Itariri","localidad":"Itariri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.289648650064855,"longitud":-47.17493775554021,"ranking":0},
{"id":5268,"nombre":"Itatiba ","localidad":"Itatiba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.009000877675664,"longitud":-46.841850718504304,"ranking":0},
{"id":10223,"nombre":"Itatinga","localidad":"Itatinga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.106582735522387,"longitud":-48.61292811616895,"ranking":0},
{"id":10224,"nombre":"Itirapina","localidad":"Itirapina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.249266592269393,"longitud":-47.819849828388335,"ranking":0},
{"id":5249,"nombre":"Itirapua","localidad":"Itirapuã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.6463313904621,"longitud":-47.22296565075818,"ranking":0},
{"id":10254,"nombre":"Itobi","localidad":"Itobi","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.7348195788486,"longitud":-46.97176218921052,"ranking":0},
{"id":5878,"nombre":"Itu","localidad":"Itu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.260165078273634,"longitud":-47.29604719489394,"ranking":1},
{"id":10225,"nombre":"Itupeva","localidad":"Itupeva","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.151176849430108,"longitud":-47.05773184894104,"ranking":0},
{"id":5859,"nombre":"Ituverava","localidad":"Ituverava","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.33925440866336,"longitud":-47.7761886879,"ranking":0},
{"id":8210,"nombre":"Jaborandi ","localidad":"Jaborandi","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.694580486180254,"longitud":-48.412631689229336,"ranking":0},
{"id":5882,"nombre":"Jaboticabal ","localidad":"Jaboticabal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.2655,"longitud":-48.3161,"ranking":0},
{"id":5883,"nombre":"Jacarei","localidad":"Jacareí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.277172139700244,"longitud":-45.95432200635467,"ranking":0},
{"id":6274,"nombre":"Jacupiranga","localidad":"Jacupiranga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.701943938477772,"longitud":-48.00577672917933,"ranking":0},
{"id":10244,"nombre":"Jales","localidad":"Jales","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.27574870592137,"longitud":-50.54790125669853,"ranking":0},
{"id":12341,"nombre":"Jambeiro","localidad":"Jambeiro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.257834025381527,"longitud":-45.68816546034751,"ranking":0},
{"id":14994,"nombre":"Jandira","localidad":"Jandira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.553188222030016,"longitud":-46.9056265737402,"ranking":0},
{"id":5884,"nombre":"Jau","localidad":"Jaú","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.298298956650545,"longitud":-48.560079005994794,"ranking":1},
{"id":6094,"nombre":"Joao Ramalho ","localidad":"João Ramalho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.252499989591623,"longitud":-50.76681033152892,"ranking":0},
{"id":6095,"nombre":"Jose Bonifacio","localidad":"José Bonifácio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.05488274739597,"longitud":-49.69084008872006,"ranking":0},
{"id":9013,"nombre":"Junqueiropolis","localidad":"Junqueirópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.50756240648915,"longitud":-51.43335477339431,"ranking":0},
{"id":8191,"nombre":"Juquia","localidad":"Juquiá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.3220896044478,"longitud":-47.62556386185007,"ranking":0},
{"id":10226,"nombre":"Laranjal Paulista","localidad":"Laranjal Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.043535482421486,"longitud":-47.837910725976606,"ranking":0},
{"id":9392,"nombre":"Lavínia","localidad":"Lavínia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.16612959600715,"longitud":-51.038302602713244,"ranking":0},
{"id":12342,"nombre":"Lavrinhas","localidad":"Lavrinhas","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5697049,"longitud":-44.9041677,"ranking":0},
{"id":8192,"nombre":"Leme","localidad":"Leme","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.189672555965327,"longitud":-47.39615243014833,"ranking":0},
{"id":12314,"nombre":"Lencois Paulista","localidad":"Lençóis Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5952072,"longitud":-48.7990989,"ranking":0},
{"id":4966,"nombre":"Limeira","localidad":"Limeira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.56521,"longitud":-47.39821999999999,"ranking":0},
{"id":6133,"nombre":"Lindóia ","localidad":"Lindóia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5274969785583,"longitud":-46.6462579832844,"ranking":0},
{"id":4965,"nombre":"Lins","localidad":"Lins","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.672803673905623,"longitud":-49.747906549548425,"ranking":0},
{"id":5174,"nombre":"Lorena ","localidad":"Lorena","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.740252178406692,"longitud":-45.1195550152061,"ranking":0},
{"id":10227,"nombre":"Louveira","localidad":"Louveira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.0908768509904,"longitud":-46.946249643484606,"ranking":0},
{"id":8914,"nombre":"Lucelia","localidad":"Lucélia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.718723047938887,"longitud":-51.0154394027032,"ranking":0},
{"id":15089,"nombre":"Luis Antonio","localidad":"Luís Antônio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.55181461325387,"longitud":-47.702434961667684,"ranking":0},
{"id":8848,"nombre":"Lupercio","localidad":"Lupércio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.384760279635383,"longitud":-49.76260841864485,"ranking":0},
{"id":8921,"nombre":"Lutecia","localidad":"Lutécia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.2970289,"longitud":-50.398706,"ranking":0},
{"id":10695,"nombre":"Magda","localidad":"Magda","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10228,"nombre":"Mairinque","localidad":"Mairinque","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.532235409080144,"longitud":-47.13218038955939,"ranking":0},
{"id":6257,"nombre":"Mairipora","localidad":"Mairiporã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.316776945733853,"longitud":-46.58379949636977,"ranking":0},
{"id":12354,"nombre":"Manduri","localidad":"Manduri","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.0001343,"longitud":-49.32132490000001,"ranking":0},
{"id":6102,"nombre":"Maraba Paulista","localidad":"Marabá Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.11237835540482,"longitud":-51.96502770666662,"ranking":0},
{"id":6106,"nombre":"Maracai","localidad":"Maracaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.615028938174486,"longitud":-50.66819983886952,"ranking":0},
{"id":6108,"nombre":"Maracai Acesso","localidad":"Maracaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.626211543360128,"longitud":-50.66746924230545,"ranking":0},
{"id":6114,"nombre":"Martinpolis","localidad":"Martinópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.148518288209438,"longitud":-51.17019681803869,"ranking":0},
{"id":8214,"nombre":"Matao","localidad":"Matão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.599396534380215,"longitud":-48.36520537534921,"ranking":0},
{"id":6256,"nombre":"Maua","localidad":"Mauá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.66727564985202,"longitud":-46.46003885203112,"ranking":0},
{"id":8220,"nombre":"Miguelopolis","localidad":"Miguelópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.17866425291771,"longitud":-48.0244091587015,"ranking":0},
{"id":12315,"nombre":"Mineiros do Tiete","localidad":"Mineiros do Tietê","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.4119216,"longitud":-48.4506078,"ranking":0},
{"id":10696,"nombre":"Mira Estrela","localidad":"Mira Estrela","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9393,"nombre":"Mirandópolis","localidad":"Mirandópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.132784001370037,"longitud":-51.105434265000056,"ranking":0},
{"id":6248,"nombre":"Mirante do Paranapanema","localidad":"Mirante do Paranapanema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.292609790511897,"longitud":-51.90520301842233,"ranking":0},
{"id":10697,"nombre":"Mirassol","localidad":"Mirassol","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.813869920619553,"longitud":-49.51416205359944,"ranking":0},
{"id":10255,"nombre":"Mococa","localidad":"Mococa","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.46475467314356,"longitud":-47.00017828039611,"ranking":0},
{"id":5841,"nombre":"Mogi das Cruzes","localidad":"Mogi das Cruzes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.51470786288221,"longitud":-46.18354341116447,"ranking":0},
{"id":10229,"nombre":"Mombuca","localidad":"Mombuca","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.927903211829474,"longitud":-47.56459948955853,"ranking":0},
{"id":5844,"nombre":"Mongagua","localidad":"Mongaguá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.09985772435354,"longitud":-46.63743282554539,"ranking":0},
{"id":8994,"nombre":"Monte Alto","localidad":"Monte Alto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.2596867,"longitud":-48.49770789999999,"ranking":0},
{"id":10698,"nombre":"Monte Aprazivel","localidad":"Monte Aprazível","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.77489100938248,"longitud":-49.7116264568434,"ranking":0},
{"id":10453,"nombre":"Monte Azul Paulista","localidad":"Monte Azul Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.897232242471212,"longitud":-48.63494967928043,"ranking":0},
{"id":9394,"nombre":"Monte Castelo","localidad":"Monte Castelo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.298657683557746,"longitud":-51.56994795531352,"ranking":0},
{"id":12343,"nombre":"Monteiro Lobato","localidad":"Monteiro Lobato","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.9556942,"longitud":-45.8397462,"ranking":0},
{"id":10230,"nombre":"Monte Mor","localidad":"Monte Mor","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.95111958105661,"longitud":-47.31287206558761,"ranking":0},
{"id":8204,"nombre":"Morro Agudo","localidad":"Morro Agudo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.728973617356967,"longitud":-48.056012931495545,"ranking":0},
{"id":9395,"nombre":"Murutinga do Sul","localidad":"Murutinga do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.9920166624063,"longitud":-51.27820188939895,"ranking":0},
{"id":6118,"nombre":"Nantes","localidad":"Nantes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.619870145165898,"longitud":-51.237609806888784,"ranking":0},
{"id":6119,"nombre":"Narandiba","localidad":"Narandiba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.40805484610382,"longitud":-51.52536221789549,"ranking":0},
{"id":8969,"nombre":"Nazare Paulista","localidad":"Nazaré Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.183478745488408,"longitud":-46.40172665414322,"ranking":0},
{"id":10699,"nombre":"Nhandeara","localidad":"Nhandeara","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.692027553207616,"longitud":-50.04690421598808,"ranking":0},
{"id":8995,"nombre":"Nova Europa","localidad":"Nova Europa","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.77996227807861,"longitud":-48.56081867530042,"ranking":0},
{"id":10153,"nombre":"Nova Granada","localidad":"Nova Granada","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.535627782864612,"longitud":-49.321405743627466,"ranking":0},
{"id":15000,"nombre":"Nova Guataporanga","localidad":"Nova Guataporanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.332232457291926,"longitud":-51.64664033946976,"ranking":0},
{"id":9396,"nombre":"Nova Independência","localidad":"Nova Independência","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.10612221393405,"longitud":-51.49169221836487,"ranking":0},
{"id":5265,"nombre":"Nova Odessa","localidad":"Nova Odessa","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.7820042240031,"longitud":-47.29194770761436,"ranking":0},
{"id":8993,"nombre":"Novo Horizonte","localidad":"Novo Horizonte","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10071,"nombre":"Nuporanga","localidad":"Nuporanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.731014963864638,"longitud":-47.754379434575995,"ranking":0},
{"id":8849,"nombre":"Ocaucu","localidad":"Ocauçu","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5885,"nombre":"Olimpia","localidad":"Olímpia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.73841439992967,"longitud":-48.910784292908225,"ranking":1},
{"id":8851,"nombre":"Oriente","localidad":"Oriente","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.152241553890406,"longitud":-50.09450647533528,"ranking":0},
{"id":10152,"nombre":"Orindiuva","localidad":"Orindiúva","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.181503570960178,"longitud":-49.34993865187731,"ranking":0},
{"id":5861,"nombre":"Orlandia","localidad":"Orlândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.726792727270567,"longitud":-47.88493769152337,"ranking":0},
{"id":8853,"nombre":"Oscar Bressane","localidad":"Oscar Bressane","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.320964093312888,"longitud":-50.27997639228145,"ranking":0},
{"id":6125,"nombre":"Osvaldo Cruz ","localidad":"Osvaldo Cruz","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.79484338527682,"longitud":-50.87753487344363,"ranking":0},
{"id":10700,"nombre":"Ouroeste","localidad":"Ouroeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-19.996159658356568,"longitud":-50.376289273898124,"ranking":0},
{"id":5575,"nombre":"Ouro Verde ","localidad":"Ouro Verde","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.49007625890353,"longitud":-51.69898098722272,"ranking":0},
{"id":9011,"nombre":"Pacaembu","localidad":"Pacaembu","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10154,"nombre":"Palestina","localidad":"Palestina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.390242982175383,"longitud":-49.43353604973817,"ranking":0},
{"id":10701,"nombre":"Palmares Paulista","localidad":"Palmares Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.08322324524913,"longitud":-48.80294160952523,"ranking":0},
{"id":6251,"nombre":"Palmital","localidad":"Palmital","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.787889575880143,"longitud":-50.2130997805406,"ranking":0},
{"id":9010,"nombre":"Panorama","localidad":"Panorama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.356100792761897,"longitud":-51.85850308951752,"ranking":0},
{"id":6129,"nombre":"Paraguacu Paulista","localidad":"Paraguaçu Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.415924035794106,"longitud":-50.573911227561425,"ranking":0},
{"id":12344,"nombre":"Paraibuna","localidad":"Paraibuna","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.3877,"longitud":-45.6644,"ranking":0},
{"id":8985,"nombre":"Paranapanema","localidad":"Paranapanema","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.44200124954079,"longitud":-48.881381751331084,"ranking":0},
{"id":10702,"nombre":"Paranapua","localidad":"Paranapuã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.105733313327836,"longitud":-50.587527462210744,"ranking":0},
{"id":6132,"nombre":"Parapua","localidad":"Parapuã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.781685044656783,"longitud":-50.789897242460434,"ranking":0},
{"id":6276,"nombre":"Pariquera Acu","localidad":"Pariquera-Açu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.7139723424746,"longitud":-47.88412542964015,"ranking":0},
{"id":5250,"nombre":"Patrocinio Paulista ","localidad":"Patrocínio Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.641270805989155,"longitud":-47.27978104967535,"ranking":0},
{"id":5253,"nombre":"Acesso Patrocinio Paulista ","localidad":"Patrocínio Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9009,"nombre":"Pauliceia","localidad":"Paulicéia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.311597368103886,"longitud":-51.84225304223559,"ranking":0},
{"id":8966,"nombre":"Paulinia","localidad":"Paulínia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.782582853777516,"longitud":-47.15463474115062,"ranking":6},
{"id":10151,"nombre":"Paulo de Faria","localidad":"Paulo de Faria","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.02840276653657,"longitud":-49.40695545855397,"ranking":0},
{"id":9606,"nombre":"Pederneiras","localidad":"Pederneiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.347082071945493,"longitud":-48.78047680073448,"ranking":0},
{"id":6258,"nombre":"Pedra Bela ","localidad":"Pedra Bela","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5810,"nombre":"Pedregulho","localidad":"Pedregulho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.25150543980817,"longitud":-47.481853903313855,"ranking":0},
{"id":6138,"nombre":"Pedrinhas Paulista","localidad":"Pedrinhas Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.81798960146375,"longitud":-50.795767262562,"ranking":0},
{"id":10231,"nombre":"Pedro de Toledo","localidad":"Pedro de Toledo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.277500773605073,"longitud":-47.233585954394066,"ranking":0},
{"id":6139,"nombre":"Penapolis","localidad":"Penápolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.420253562036233,"longitud":-50.08346069005554,"ranking":0},
{"id":9397,"nombre":"Pereira Barreto","localidad":"Pereira Barreto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.645109946353777,"longitud":-51.110780002722485,"ranking":0},
{"id":10249,"nombre":"Pereiras","localidad":"Pereiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.075380373664593,"longitud":-47.975252189184815,"ranking":0},
{"id":5845,"nombre":"Peruibe","localidad":"Peruíbe","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.31898824360447,"longitud":-47.005741934776324,"ranking":0},
{"id":8202,"nombre":"Piedade","localidad":"Piedade","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.706976450580918,"longitud":-47.42327674734822,"ranking":0},
{"id":5846,"nombre":"Pilar do Sul ","localidad":"Pilar do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.81174829002898,"longitud":-47.71957822151959,"ranking":0},
{"id":5223,"nombre":"Pindamonhangaba","localidad":"Pindamonhangaba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.936647117232862,"longitud":-45.462978843264644,"ranking":0},
{"id":5826,"nombre":"Pindorama","localidad":"Pindorama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.188025956562807,"longitud":-48.90532278009352,"ranking":0},
{"id":6260,"nombre":"Pinhalzinho","localidad":"Pinhalzinho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.78215079237268,"longitud":-46.584688005131895,"ranking":0},
{"id":6140,"nombre":"Piquerobi","localidad":"Piquerobi","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.886640863923937,"longitud":-51.73194661804362,"ranking":0},
{"id":5833,"nombre":"Piquete ","localidad":"Piquete","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.612335242270014,"longitud":-45.184031835767044,"ranking":0},
{"id":5080,"nombre":"Piracicaba","localidad":"Piracicaba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.73122160545215,"longitud":-47.64797313917002,"ranking":0},
{"id":5917,"nombre":"Entrocamento Piraju","localidad":"Piraju","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.197866946064924,"longitud":-49.385209723838926,"ranking":0},
{"id":8865,"nombre":"Piraju","localidad":"Piraju","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.19812273003862,"longitud":-49.3853945731462,"ranking":0},
{"id":9398,"nombre":"Pirajuí","localidad":"Pirajuí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.992337687521516,"longitud":-49.45509156217844,"ranking":0},
{"id":10703,"nombre":"Pirangi","localidad":"Pirangi","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.09293707146275,"longitud":-48.66446910974526,"ranking":0},
{"id":6142,"nombre":"Pirapozinho","localidad":"Pirapozinho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.277803421136436,"longitud":-51.50487437414349,"ranking":0},
{"id":5205,"nombre":"Pirassununga ","localidad":"Pirassununga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.99665209404926,"longitud":-47.431051626953625,"ranking":0},
{"id":5623,"nombre":"Piratininga ","localidad":"Piratininga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.410396009968157,"longitud":-49.13419347571994,"ranking":0},
{"id":8207,"nombre":"Pitangueiras","localidad":"Pitangueiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.007645181696,"longitud":-48.21955473433577,"ranking":0},
{"id":12357,"nombre":"Poa","localidad":"Poá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.524122669152753,"longitud":-46.34222626626463,"ranking":0},
{"id":10704,"nombre":"Poloni","localidad":"Poloni","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.784733675905407,"longitud":-49.81732907653865,"ranking":0},
{"id":8850,"nombre":"Pompeia","localidad":"Pompéia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.108009254154016,"longitud":-50.172235762099625,"ranking":0},
{"id":8206,"nombre":"Pontal","localidad":"Pontal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.026208944540333,"longitud":-48.03759499687551,"ranking":0},
{"id":10705,"nombre":"Pontalinda","localidad":"Pontalinda","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10232,"nombre":"Porangaba","localidad":"Porangaba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.179554818675804,"longitud":-48.121307768114875,"ranking":0},
{"id":10233,"nombre":"Porto Feliz","localidad":"Porto Feliz","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.218354445269092,"longitud":-47.521511160346265,"ranking":0},
{"id":5270,"nombre":"Porto Ferreira ","localidad":"Porto Ferreira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.858069772907477,"longitud":-47.487525274105025,"ranking":0},
{"id":15102,"nombre":"Tude Bastos","localidad":"Praia Grande","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.9984726,"longitud":-46.4139966,"ranking":0},
{"id":5823,"nombre":"Praia Grande","localidad":"Praia Grande","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.02879,"longitud":-46.4965,"ranking":0},
{"id":9399,"nombre":"Presidente Alves","localidad":"Presidente Alves","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6161,"nombre":"Presidente Bernardes","localidad":"Presidente Bernardes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.006655891205416,"longitud":-51.55456978920526,"ranking":0},
{"id":6162,"nombre":"Presidente Epitacio","localidad":"Presidente Epitácio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.76126595115851,"longitud":-52.10470691123419,"ranking":0},
{"id":5886,"nombre":"Presidente Prudente","localidad":"Presidente Prudente","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.1310648,"longitud":-51.3872353,"ranking":0},
{"id":6163,"nombre":"Presidente Venceslau","localidad":"Presidente Venceslau","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.875365500646883,"longitud":-51.84542029106113,"ranking":0},
{"id":9058,"nombre":"Promissao","localidad":"Promissão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.533579063034473,"longitud":-49.855328294972054,"ranking":0},
{"id":10234,"nombre":"Quadra","localidad":"Quadra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.302320427434054,"longitud":-48.04404204452277,"ranking":0},
{"id":6165,"nombre":"Quata","localidad":"Quatá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.243393081996974,"longitud":-50.70450458959971,"ranking":0},
{"id":6166,"nombre":"Queluz","localidad":"Queluz","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.537167986690786,"longitud":-44.77320867865927,"ranking":0},
{"id":8852,"nombre":"Quintana","localidad":"Quintana","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.069997917435323,"longitud":-50.30521145448983,"ranking":0},
{"id":6168,"nombre":"Rancharia","localidad":"Rancharia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.234131670330363,"longitud":-50.8894668561819,"ranking":0},
{"id":6169,"nombre":"Regente Feijo","localidad":"Regente Feijó","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.221552385994606,"longitud":-51.30468751517052,"ranking":0},
{"id":8992,"nombre":"Reginopolis","localidad":"Reginópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.88859358025,"longitud":-49.22850021732837,"ranking":0},
{"id":14965,"nombre":"Ribeira","localidad":"Ribeira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.657245660263573,"longitud":-49.0078414865443,"ranking":0},
{"id":6171,"nombre":"Ribeirao Bonito","localidad":"Ribeirão Bonito","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.069426335271924,"longitud":-48.174369552423535,"ranking":0},
{"id":6172,"nombre":"Ribeirao dos Indios","localidad":"Ribeirão dos Índios","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.838419771290692,"longitud":-51.60148753334276,"ranking":0},
{"id":9401,"nombre":"Ribeirão Grande","localidad":"Ribeirão Grande","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.097287363126828,"longitud":-48.36241646265128,"ranking":0},
{"id":5847,"nombre":"Ribeirao Pires","localidad":"Ribeirão Pires","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.712218795304462,"longitud":-46.41577505888175,"ranking":0},
{"id":10072,"nombre":"Rifaina","localidad":"Rifaina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.078617809725763,"longitud":-47.42609647662009,"ranking":0},
{"id":6173,"nombre":"Rinopolis","localidad":"Rinópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.72349845664792,"longitud":-50.721435220419586,"ranking":0},
{"id":5887,"nombre":"Rio Claro","localidad":"Rio Claro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.4170927,"longitud":-47.5758214,"ranking":0},
{"id":10235,"nombre":"Rio das Pedras","localidad":"Rio das Pedras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.842876457093112,"longitud":-47.60880150665565,"ranking":0},
{"id":10150,"nombre":"Riolandia","localidad":"Riolândia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-19.98207396612152,"longitud":-49.67949201269351,"ranking":0},
{"id":6177,"nombre":"Rosana","localidad":"Rosana","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.582237437733568,"longitud":-53.058036215469464,"ranking":0},
{"id":5225,"nombre":"Roseira","localidad":"Roseira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.8998303128068,"longitud":-45.3048859410251,"ranking":0},
{"id":8211,"nombre":"Sales Oliveira","localidad":"Sales Oliveira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.775099415972363,"longitud":-47.83995093861528,"ranking":0},
{"id":12345,"nombre":"Salesopolis","localidad":"Salesópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.52973,"longitud":-45.84946,"ranking":0},
{"id":14961,"nombre":"Saltinho","localidad":"Saltinho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.846541353528327,"longitud":-47.680577292074986,"ranking":0},
{"id":14448,"nombre":"Salto","localidad":"Salto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.1968046,"longitud":-47.3018678,"ranking":0},
{"id":5848,"nombre":"Salto de Pirapora ","localidad":"Salto de Pirapora","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.645523663687758,"longitud":-47.57556303943177,"ranking":0},
{"id":6199,"nombre":"Salto Grande","localidad":"Salto Grande","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.886208571846115,"longitud":-49.98266175428934,"ranking":0},
{"id":6202,"nombre":"Sandovalina","localidad":"Sandovalina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.456300678377726,"longitud":-51.76446731694657,"ranking":0},
{"id":5828,"nombre":"Santa Adelia","localidad":"Santa Adélia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.23976294239414,"longitud":-48.80556669965276,"ranking":0},
{"id":10706,"nombre":"Santa Albertina","localidad":"Santa Albertina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.030826151927023,"longitud":-50.730588197558745,"ranking":0},
{"id":6207,"nombre":"Santa Barbara D'Oeste","localidad":"Santa Bárbara d'Oeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.76498024065038,"longitud":-47.4349464973669,"ranking":0},
{"id":12346,"nombre":"Santa Branca","localidad":"Santa Branca","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.39539,"longitud":-45.88963,"ranking":0},
{"id":10455,"nombre":"Santa Cruz da Esperanca","localidad":"Santa Cruz da Esperança","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.291787170247577,"longitud":-47.430234019606644,"ranking":0},
{"id":10456,"nombre":"Santa Cruz das Palmeiras","localidad":"Santa Cruz das Palmeiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.823574120960004,"longitud":-47.246809279551535,"ranking":0},
{"id":8844,"nombre":"Santa Cruz do Rio Pardo","localidad":"Santa Cruz do Rio Pardo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.90272906332648,"longitud":-49.622661114880515,"ranking":0},
{"id":10245,"nombre":"Santa Fe do Sul","localidad":"Santa Fé do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.21683330520733,"longitud":-50.928200355439955,"ranking":0},
{"id":8971,"nombre":"Santa Isabel","localidad":"Santa Isabel","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.3097377382208,"longitud":-46.23172043743261,"ranking":0},
{"id":12364,"nombre":"Santa Maria da Serra","localidad":"Santa Maria da Serra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.5700448,"longitud":-48.158791,"ranking":0},
{"id":9005,"nombre":"Santa Mercedes","localidad":"Santa Mercedes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.35208144769729,"longitud":-51.75097716372257,"ranking":0},
{"id":10709,"nombre":"Santana da Ponte Pensa","localidad":"Santana da Ponte Pensa","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.253487404415615,"longitud":-50.79681747307167,"ranking":0},
{"id":10707,"nombre":"Santa Rita d'Oeste","localidad":"Santa Rita d'Oeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.14473549943991,"longitud":-50.83189000965321,"ranking":0},
{"id":5834,"nombre":"Santa Rita do Passa Quatro","localidad":"Santa Rita do Passa Quatro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.70878716007957,"longitud":-47.48454154328961,"ranking":0},
{"id":8196,"nombre":"Santa Rosa de Viterbo","localidad":"Santa Rosa de Viterbo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.49539389285154,"longitud":-47.38655443783532,"ranking":0},
{"id":10708,"nombre":"Santa Salete","localidad":"Santa Salete","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.245892735765633,"longitud":-50.68933318820066,"ranking":0},
{"id":6201,"nombre":"Santo Anastacio ","localidad":"Santo Anastácio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.972317330272038,"longitud":-51.65130512861546,"ranking":0},
{"id":10074,"nombre":"Santo Antonio da Alegria","localidad":"Santo Antônio da Alegria","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.089323545071533,"longitud":-47.15339411805824,"ranking":0},
{"id":10256,"nombre":"Santo Antonio do Jardim","localidad":"Santo Antônio do Jardim","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.119834449784516,"longitud":-46.685077690193225,"ranking":0},
{"id":5228,"nombre":"Santo Antonio do Pinhal","localidad":"Santo Antônio do Pinhal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.82569016304845,"longitud":-45.666389029108174,"ranking":0},
{"id":7319,"nombre":"Santopolis do Aguapei","localidad":"Santópolis do Aguapeí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.638498779543475,"longitud":-50.499346521221234,"ranking":0},
{"id":5232,"nombre":"Sao Bento do Sapucai","localidad":"São Bento do Sapucaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.686726028450806,"longitud":-45.7335000562953,"ranking":0},
{"id":5619,"nombre":"Sao Bernardo do Campo ","localidad":"São Bernardo do Campo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6987381,"longitud":-46.5532615,"ranking":0},
{"id":5815,"nombre":"Sao Caetano do Sul","localidad":"São Caetano do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.61051,"longitud":-46.5703,"ranking":2},
{"id":5308,"nombre":"Sao Carlos ","localidad":"São Carlos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.005639574822982,"longitud":-47.88953160478255,"ranking":1},
{"id":5804,"nombre":"Sao Joao da Boa Vista","localidad":"São João da Boa Vista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.9892631,"longitud":-46.7928119,"ranking":2},
{"id":15291,"nombre":"Sao Joao do Pau d Alho","localidad":"São João do Pau d'Alho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.269312659577896,"longitud":-51.66867600750184,"ranking":0},
{"id":5862,"nombre":"Sao Joaquim da Barra","localidad":"São Joaquim da Barra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.57918687766996,"longitud":-47.85817268064412,"ranking":0},
{"id":10075,"nombre":"Sao Jose da Bela Vista","localidad":"São José da Bela Vista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.589776605196185,"longitud":-20.589776605196185,"ranking":0},
{"id":12347,"nombre":"Sao Jose do Barreiro","localidad":"São José do Barreiro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.64349,"longitud":-44.58036,"ranking":0},
{"id":10257,"nombre":"Sao Jose do Rio Pardo","localidad":"São José do Rio Pardo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.59558678595348,"longitud":-46.892601766412334,"ranking":0},
{"id":14584,"nombre":"Sao Lourenco Da Serra","localidad":"São Lourenço da Serra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.8545367,"longitud":-46.94505480000001,"ranking":0},
{"id":9528,"nombre":"São Manuel","localidad":"São Manuel","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.735060404207744,"longitud":-48.57054218919149,"ranking":0},
{"id":5849,"nombre":"Sao Miguel Arcanjo ","localidad":"São Miguel Arcanjo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.878337297306516,"longitud":-47.99725773448678,"ranking":0},
{"id":8960,"nombre":"Sao Pedro","localidad":"São Pedro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.553146913278777,"longitud":-47.91091432542217,"ranking":0},
{"id":5811,"nombre":"Sao Roque","localidad":"São Roque","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.532208064396247,"longitud":-47.13201617238081,"ranking":3},
{"id":5850,"nombre":"Sao Sebastiao","localidad":"São Sebastião","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.8043171,"longitud":-45.40483039999999,"ranking":2},
{"id":15280,"nombre":"Barra do Una","localidad":"São Sebastião","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.773205978910955,"longitud":-45.62365413774622,"ranking":0},
{"id":10258,"nombre":"Sao Sebastiao da Grama","localidad":"São Sebastião da Grama","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.716863891871004,"longitud":-46.82589039607728,"ranking":0},
{"id":8195,"nombre":"Sao Simao","localidad":"São Simão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.47728334556432,"longitud":-47.55882628375965,"ranking":0},
{"id":7112,"nombre":"Sao Vicente","localidad":"São Vicente","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.972508796483947,"longitud":-46.40054683499036,"ranking":0},
{"id":10236,"nombre":"Sarapui","localidad":"Sarapuí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.641653693490653,"longitud":-47.8172530809954,"ranking":0},
{"id":12367,"nombre":"Sarutaia","localidad":"Sarutaiá","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.2765883,"longitud":-49.4758579,"ranking":0},
{"id":10077,"nombre":"Serrana","localidad":"Serrana","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.21560688301509,"longitud":-47.59276069541411,"ranking":0},
{"id":6277,"nombre":"Sete Barras","localidad":"Sete Barras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-24.381818452406712,"longitud":-47.92909358241229,"ranking":0},
{"id":8199,"nombre":"Severinia ","localidad":"Severínia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.813226527662376,"longitud":-48.80268502336674,"ranking":0},
{"id":12348,"nombre":"Silveiras","localidad":"Silveiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.6656786,"longitud":-44.85272,"ranking":0},
{"id":6263,"nombre":"Socorro","localidad":"Socorro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.577052990483317,"longitud":-46.52630701665206,"ranking":0},
{"id":5072,"nombre":"Sorocaba","localidad":"Sorocaba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.50658,"longitud":-47.45645,"ranking":5},
{"id":9402,"nombre":"Sud Mennucci","localidad":"Sud Mennucci","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10237,"nombre":"Sumare","localidad":"Sumaré","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.81796351462351,"longitud":-47.267659550305126,"ranking":0},
{"id":6255,"nombre":"Suzano","localidad":"Suzano","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.534192993362996,"longitud":-46.30663830047319,"ranking":0},
{"id":9919,"nombre":"Tabatinga","localidad":"Tabatinga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.73535366270636,"longitud":-48.69174621928426,"ranking":0},
{"id":10238,"nombre":"Taboao da Serra","localidad":"Taboão da Serra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.606811484938497,"longitud":-46.751951428360705,"ranking":0},
{"id":5931,"nombre":"Entroncamento Taciba","localidad":"Taciba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.390137199397408,"longitud":-51.28826959888828,"ranking":0},
{"id":6232,"nombre":"Usina Capivara","localidad":"Taciba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.390211860085167,"longitud":-51.28826537546304,"ranking":0},
{"id":8988,"nombre":"Taguai","localidad":"Taguaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.4523214,"longitud":-49.4004688,"ranking":0},
{"id":8194,"nombre":"Tambau","localidad":"Tambaú","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.711702276349857,"longitud":-47.27150076069443,"ranking":0},
{"id":10710,"nombre":"Tanabi","localidad":"Tanabi","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.62369588508338,"longitud":-49.64619116042804,"ranking":0},
{"id":8203,"nombre":"Tapirai","localidad":"Tapiraí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.96455704552388,"longitud":-47.50625267458808,"ranking":0},
{"id":10259,"nombre":"Tapiratiba","localidad":"Tapiratiba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.466733147453233,"longitud":-46.7524518180513,"ranking":0},
{"id":9060,"nombre":"Taquaritinga","localidad":"Taquaritinga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.41141253314211,"longitud":-48.49921625419769,"ranking":0},
{"id":8858,"nombre":"Taquarituba","localidad":"Taquarituba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.542255,"longitud":-49.24732299999999,"ranking":0},
{"id":15001,"nombre":"Taquarivai","localidad":"Taquarivaí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.92636608441516,"longitud":-48.700564194809736,"ranking":0},
{"id":6212,"nombre":"Tarabai","localidad":"Tarabai","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.3038437676562,"longitud":-51.56408381637735,"ranking":0},
{"id":6216,"nombre":"Taruma","localidad":"Tarumã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.747389800874384,"longitud":-50.581132072377,"ranking":0},
{"id":5813,"nombre":"Tatui","localidad":"Tatuí","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.3474334,"longitud":-47.84403520000001,"ranking":2},
{"id":5017,"nombre":"Taubate","localidad":"Taubaté","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.020123987654387,"longitud":-45.53561089057606,"ranking":0},
{"id":6218,"nombre":"Teodoro Sampaio ","localidad":"Teodoro Sampaio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.529010514467508,"longitud":-52.168326150716126,"ranking":0},
{"id":5888,"nombre":"Tiete","localidad":"Tietê","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.516144083141004,"longitud":-46.624214334903755,"ranking":0},
{"id":10239,"nombre":"Torre de Pedra","localidad":"Torre de Pedra","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.247109800763344,"longitud":-48.19291590156243,"ranking":0},
{"id":8963,"nombre":"Torrinha","localidad":"Torrinha","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.427892910817462,"longitud":-48.165141799445834,"ranking":0},
{"id":9403,"nombre":"Trabiju","localidad":"Trabiju","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6268,"nombre":"Tremembe","localidad":"Tremembé","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.963697008391826,"longitud":-45.54775787612772,"ranking":0},
{"id":10711,"nombre":"Tres Fronteiras","localidad":"Três Fronteiras","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8913,"nombre":"Tupa","localidad":"Tupã","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.940563215348,"longitud":-50.5218931304044,"ranking":0},
{"id":5592,"nombre":"Tupi Paulista","localidad":"Tupi Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.386983477468668,"longitud":-51.57101099025981,"ranking":0},
{"id":10712,"nombre":"Turmalina","localidad":"Turmalina","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.052074788995373,"longitud":-50.476054562138756,"ranking":0},
{"id":5851,"nombre":"Ubatuba","localidad":"Ubatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.4357806,"longitud":-45.07513609999999,"ranking":96},
{"id":15277,"nombre":"Maranduba","localidad":"Ubatuba","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.536438995216358,"longitud":-45.22827144457527,"ranking":0},
{"id":10714,"nombre":"Urania","localidad":"Urânia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.246622062514543,"longitud":-50.64788833807892,"ranking":0},
{"id":5830,"nombre":"Urupes","localidad":"Urupês","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.199209708353553,"longitud":-49.294078675800435,"ranking":0},
{"id":10240,"nombre":"Valinhos","localidad":"Valinhos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.9723621,"longitud":-46.9960353,"ranking":0},
{"id":9404,"nombre":"Valparaíso","localidad":"Valparaíso","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.22911856856947,"longitud":-50.86570368224447,"ranking":0},
{"id":10260,"nombre":"Vargem Grande do Sul","localidad":"Vargem Grande do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.838236401860023,"longitud":-46.905782172718816,"ranking":0},
{"id":14791,"nombre":"Vargem Grande Paulista","localidad":"Vargem Grande Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.6038982,"longitud":-47.0263838,"ranking":0},
{"id":10241,"nombre":"Varzea Paulista","localidad":"Várzea Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.21043689084487,"longitud":-46.82510551746852,"ranking":0},
{"id":6235,"nombre":"Vera Cruz","localidad":"Vera Cruz","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.21963181985258,"longitud":-49.82647506398024,"ranking":0},
{"id":10242,"nombre":"Vinhedo","localidad":"Vinhedo","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.02723661175718,"longitud":-46.98213544998,"ranking":0},
{"id":10078,"nombre":"Viradouro","localidad":"Viradouro","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.87593183194246,"longitud":-48.297806023877364,"ranking":0},
{"id":10713,"nombre":"Vista Alegre do Alto","localidad":"Vista Alegre do Alto","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.173701058303926,"longitud":-48.630436812270275,"ranking":0},
{"id":5853,"nombre":"Votorantim","localidad":"Votorantim","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.539814845141645,"longitud":-47.446308231997186,"ranking":0},
{"id":10246,"nombre":"Votuporanga","localidad":"Votuporanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.41080216799884,"longitud":-49.964414256020824,"ranking":0},
{"id":8846,"nombre":"Chavantes","localidad":"Chavantes","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.0411,"longitud":-49.7074,"ranking":0},
{"id":8904,"nombre":"Abatia","localidad":"Abatiá","provincia":"Parana","pais":"BRASIL","latitud":-23.3120297,"longitud":-50.3079968,"ranking":0},
{"id":10925,"nombre":"Adrianopolis","localidad":"Adrianópolis","provincia":"Parana","pais":"BRASIL","latitud":-24.6592991,"longitud":-48.9905144,"ranking":0},
{"id":7167,"nombre":"Altamira do Parana","localidad":"Altamira do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-24.79945677814536,"longitud":-52.71512016520838,"ranking":0},
{"id":7172,"nombre":"Altonia","localidad":"Altônia","provincia":"Parana","pais":"BRASIL","latitud":-23.87416948613876,"longitud":-53.89411751970257,"ranking":0},
{"id":7170,"nombre":"Alto Parana","localidad":"Alto Paraná","provincia":"Parana","pais":"BRASIL","latitud":-23.13214038614772,"longitud":-52.322006358496516,"ranking":0},
{"id":7171,"nombre":"Alto Piquiri","localidad":"Alto Piquiri","provincia":"Parana","pais":"BRASIL","latitud":-24.027403339154958,"longitud":-53.44192258891393,"ranking":0},
{"id":7173,"nombre":"Alvorada do Sul","localidad":"Alvorada do Sul","provincia":"Parana","pais":"BRASIL","latitud":-22.77856083766407,"longitud":-51.23220431317951,"ranking":0},
{"id":7174,"nombre":"Amapora","localidad":"Amaporã","provincia":"Parana","pais":"BRASIL","latitud":-23.096301128768058,"longitud":-52.784029663146065,"ranking":0},
{"id":7175,"nombre":"Ampere","localidad":"Ampére","provincia":"Parana","pais":"BRASIL","latitud":-25.921753,"longitud":-53.481032,"ranking":0},
{"id":7176,"nombre":"Andira","localidad":"Andirá","provincia":"Parana","pais":"BRASIL","latitud":-23.04522344194378,"longitud":-50.23052074315471,"ranking":0},
{"id":7177,"nombre":"Angulo","localidad":"Ângulo","provincia":"Parana","pais":"BRASIL","latitud":-23.195231904603332,"longitud":-51.916627117244865,"ranking":0},
{"id":7178,"nombre":"Antonina","localidad":"Antonina","provincia":"Parana","pais":"BRASIL","latitud":-25.427764775596206,"longitud":-48.70987010863198,"ranking":0},
{"id":15290,"nombre":"Cacatu","localidad":"Antonina","provincia":"Parana","pais":"BRASIL","latitud":-25.323300830721198,"longitud":-48.7511765400349,"ranking":0},
{"id":7179,"nombre":"Apucarana","localidad":"Apucarana","provincia":"Parana","pais":"BRASIL","latitud":-23.5478003,"longitud":-51.44902399999999,"ranking":3},
{"id":7180,"nombre":"Arapongas","localidad":"Arapongas","provincia":"Parana","pais":"BRASIL","latitud":-23.407134061197926,"longitud":-51.428567972615056,"ranking":0},
{"id":8648,"nombre":"Arapoti ","localidad":"Arapoti","provincia":"Parana","pais":"BRASIL","latitud":-24.144290211092468,"longitud":-49.823734018889326,"ranking":0},
{"id":7181,"nombre":"Araruna","localidad":"Araruna","provincia":"Parana","pais":"BRASIL","latitud":-23.930544770220695,"longitud":-52.496873570248134,"ranking":0},
{"id":7182,"nombre":"Araucaria","localidad":"Araucária","provincia":"Parana","pais":"BRASIL","latitud":-25.5931670598798,"longitud":-49.40703278913283,"ranking":0},
{"id":7184,"nombre":"Assai","localidad":"Assaí","provincia":"Parana","pais":"BRASIL","latitud":-23.37555272471687,"longitud":-50.847633891437454,"ranking":0},
{"id":7185,"nombre":"Assis Chateaubriand","localidad":"Assis Chateaubriand","provincia":"Parana","pais":"BRASIL","latitud":-24.4041824,"longitud":-53.5169219,"ranking":0},
{"id":7186,"nombre":"Astorga","localidad":"Astorga","provincia":"Parana","pais":"BRASIL","latitud":-23.236710698211354,"longitud":-51.66085961316312,"ranking":0},
{"id":7187,"nombre":"Atalaia","localidad":"Atalaia","provincia":"Parana","pais":"BRASIL","latitud":-23.151709133982365,"longitud":-52.050799537602025,"ranking":0},
{"id":7188,"nombre":"Bandeirantes","localidad":"Bandeirantes","provincia":"Parana","pais":"BRASIL","latitud":-23.111376056025556,"longitud":-50.3670493603483,"ranking":0},
{"id":7189,"nombre":"Barbosa Ferraz","localidad":"Barbosa Ferraz","provincia":"Parana","pais":"BRASIL","latitud":-24.02767574327701,"longitud":-52.008932962167584,"ranking":0},
{"id":5075,"nombre":"Barracao","localidad":"Barracão","provincia":"Parana","pais":"BRASIL","latitud":-26.256655144768732,"longitud":-53.628354654598944,"ranking":0},
{"id":8905,"nombre":"Barra do Jacare","localidad":"Barra do Jacaré","provincia":"Parana","pais":"BRASIL","latitud":-23.11347,"longitud":-50.17884,"ranking":0},
{"id":7191,"nombre":"Bela Vista do Paraiso","localidad":"Bela Vista do Paraíso","provincia":"Parana","pais":"BRASIL","latitud":-22.999118276651966,"longitud":-51.19349711491695,"ranking":0},
{"id":8459,"nombre":"Bituruna","localidad":"Bituruna","provincia":"Parana","pais":"BRASIL","latitud":-26.15725356059434,"longitud":-51.554890690879034,"ranking":0},
{"id":7193,"nombre":"Boa Esperanca","localidad":"Boa Esperança","provincia":"Parana","pais":"BRASIL","latitud":-24.247167878326092,"longitud":-52.785995104519166,"ranking":0},
{"id":14963,"nombre":"Bocaiuva do Sul","localidad":"Bocaiúva do Sul","provincia":"Parana","pais":"BRASIL","latitud":-25.1990625,"longitud":-49.1152504,"ranking":0},
{"id":7413,"nombre":"Bom Sucesso ","localidad":"Bom Sucesso","provincia":"Parana","pais":"BRASIL","latitud":-23.70931081455567,"longitud":-51.762883162598335,"ranking":0},
{"id":7194,"nombre":"Borrazopolis","localidad":"Borrazópolis","provincia":"Parana","pais":"BRASIL","latitud":-23.9398993954313,"longitud":-51.58459460782655,"ranking":0},
{"id":7196,"nombre":"Braganey","localidad":"Braganey","provincia":"Parana","pais":"BRASIL","latitud":-24.816193920661174,"longitud":-53.11926710084761,"ranking":0},
{"id":7197,"nombre":"Brasilandia do Sul","localidad":"Brasilândia do Sul","provincia":"Parana","pais":"BRASIL","latitud":-24.20122621427534,"longitud":-53.52984772932375,"ranking":0},
{"id":7198,"nombre":"Cafeara","localidad":"Cafeara","provincia":"Parana","pais":"BRASIL","latitud":-22.789064370125256,"longitud":-51.71352489474397,"ranking":0},
{"id":7264,"nombre":"Cafelandia","localidad":"Cafelândia","provincia":"Parana","pais":"BRASIL","latitud":-24.623678903412625,"longitud":-53.32249843190207,"ranking":0},
{"id":7199,"nombre":"Cafezal do Sul","localidad":"Cafezal do Sul","provincia":"Parana","pais":"BRASIL","latitud":-23.90611082123978,"longitud":-53.51521430404406,"ranking":0},
{"id":7200,"nombre":"California","localidad":"Califórnia","provincia":"Parana","pais":"BRASIL","latitud":-23.66938373646186,"longitud":-51.3566171153152,"ranking":0},
{"id":7201,"nombre":"Cambara","localidad":"Cambará","provincia":"Parana","pais":"BRASIL","latitud":-23.03559696049144,"longitud":-50.07744192771476,"ranking":0},
{"id":7202,"nombre":"Cambira","localidad":"Cambira","provincia":"Parana","pais":"BRASIL","latitud":-23.596167687151464,"longitud":-51.579715379458364,"ranking":0},
{"id":7203,"nombre":"Campina da Lagoa","localidad":"Campina da Lagoa","provincia":"Parana","pais":"BRASIL","latitud":-24.59313,"longitud":-52.80594,"ranking":0},
{"id":7265,"nombre":"Campo Bonito","localidad":"Campo Bonito","provincia":"Parana","pais":"BRASIL","latitud":-25.03339341289002,"longitud":-52.99577820090504,"ranking":0},
{"id":7138,"nombre":"Campo do Tenente","localidad":"Campo do Tenente","provincia":"Parana","pais":"BRASIL","latitud":-25.976859070597204,"longitud":-49.6860128298588,"ranking":0},
{"id":7205,"nombre":"Campo Largo","localidad":"Campo Largo","provincia":"Parana","pais":"BRASIL","latitud":-25.455532422604982,"longitud":-49.52445508472808,"ranking":0},
{"id":5583,"nombre":"Campo Mourao","localidad":"Campo Mourão","provincia":"Parana","pais":"BRASIL","latitud":-24.043975350253557,"longitud":-52.39583730697632,"ranking":0},
{"id":7206,"nombre":"Candido de Abreu","localidad":"Cândido de Abreu","provincia":"Parana","pais":"BRASIL","latitud":-24.568844138788055,"longitud":-51.3395543165219,"ranking":0},
{"id":7266,"nombre":"Candoi","localidad":"Candói","provincia":"Parana","pais":"BRASIL","latitud":-25.57657413987936,"longitud":-52.04686075802472,"ranking":0},
{"id":7140,"nombre":"Cantagalo","localidad":"Cantagalo","provincia":"Parana","pais":"BRASIL","latitud":-25.377894671567102,"longitud":-52.124475586505106,"ranking":0},
{"id":7258,"nombre":"Capanema","localidad":"Capanema","provincia":"Parana","pais":"BRASIL","latitud":-25.67301604321089,"longitud":-53.807197276219554,"ranking":0},
{"id":7257,"nombre":"Capitao Leonidas Marques","localidad":"Capitão Leônidas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.490327774684104,"longitud":-53.605250756454595,"ranking":0},
{"id":15323,"nombre":"Alto Cacula","localidad":"Capitão Leônidas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.532844,"longitud":-53.598158,"ranking":0},
{"id":7267,"nombre":"Carambei","localidad":"Carambeí","provincia":"Parana","pais":"BRASIL","latitud":-24.954466018319994,"longitud":-50.11439979849259,"ranking":0},
{"id":8896,"nombre":"Carlopolis","localidad":"Carlópolis","provincia":"Parana","pais":"BRASIL","latitud":-23.4269149,"longitud":-49.7227787,"ranking":0},
{"id":7268,"nombre":"Castro","localidad":"Castro","provincia":"Parana","pais":"BRASIL","latitud":-24.79519356087579,"longitud":-50.010184000892195,"ranking":0},
{"id":7141,"nombre":"Catanduvas","localidad":"Catanduvas","provincia":"Parana","pais":"BRASIL","latitud":-25.202385434962913,"longitud":-53.15152832809201,"ranking":0},
{"id":15330,"nombre":"Rio Adelaide","localidad":"Catanduvas","provincia":"Parana","pais":"BRASIL","latitud":-25.25619,"longitud":-53.15319,"ranking":0},
{"id":15331,"nombre":"Encruzo Catanduvas","localidad":"Catanduvas","provincia":"Parana","pais":"BRASIL","latitud":-25.0972,"longitud":-53.12898999999999,"ranking":0},
{"id":10928,"nombre":"Cerro Azul","localidad":"Cerro Azul","provincia":"Parana","pais":"BRASIL","latitud":-24.82192,"longitud":-49.26153,"ranking":0},
{"id":7142,"nombre":"Ceu Azul","localidad":"Céu Azul","provincia":"Parana","pais":"BRASIL","latitud":-25.155199658413277,"longitud":-53.850112938625806,"ranking":0},
{"id":7269,"nombre":"Chopinzinho","localidad":"Chopinzinho","provincia":"Parana","pais":"BRASIL","latitud":-25.85488560695919,"longitud":-52.53440679880577,"ranking":0},
{"id":7244,"nombre":"Cianorte","localidad":"Cianorte","provincia":"Parana","pais":"BRASIL","latitud":-23.662035842694664,"longitud":-52.610385094469116,"ranking":0},
{"id":5561,"nombre":"Clevelandia","localidad":"Clevelândia","provincia":"Parana","pais":"BRASIL","latitud":-26.405765727464452,"longitud":-52.35177956260839,"ranking":0},
{"id":7208,"nombre":"Colorado","localidad":"Colorado","provincia":"Parana","pais":"BRASIL","latitud":-22.8414344866639,"longitud":-51.975049180873356,"ranking":0},
{"id":8898,"nombre":"Congonhinhas","localidad":"Congonhinhas","provincia":"Parana","pais":"BRASIL","latitud":-23.54872932607492,"longitud":-50.55522218917538,"ranking":0},
{"id":8911,"nombre":"Conselheiro Mairinck","localidad":"Conselheiro Mairinck","provincia":"Parana","pais":"BRASIL","latitud":-23.621870271813968,"longitud":-50.16714552254461,"ranking":0},
{"id":12349,"nombre":"Contenda","localidad":"Contenda","provincia":"Parana","pais":"BRASIL","latitud":-25.6839371,"longitud":-49.53322319999999,"ranking":0},
{"id":5060,"nombre":"Corbelia ","localidad":"Corbélia","provincia":"Parana","pais":"BRASIL","latitud":-24.798597189888643,"longitud":-53.29767776569406,"ranking":0},
{"id":7209,"nombre":"Cornelio Procopio","localidad":"Cornélio Procópio","provincia":"Parana","pais":"BRASIL","latitud":-23.173600121678565,"longitud":-50.6278041719882,"ranking":0},
{"id":7270,"nombre":"Coronel Vivida","localidad":"Coronel Vivida","provincia":"Parana","pais":"BRASIL","latitud":-25.998591212921582,"longitud":-52.56627667378038,"ranking":0},
{"id":8626,"nombre":"Cruzeiro do Oeste","localidad":"Cruzeiro do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-23.78410284337259,"longitud":-53.07399495848366,"ranking":0},
{"id":7211,"nombre":"Cruzeiro do Sul","localidad":"Cruzeiro do Sul","provincia":"Parana","pais":"BRASIL","latitud":-22.966116272088254,"longitud":-52.161789317666994,"ranking":0},
{"id":8460,"nombre":"Cruz Machado","localidad":"Cruz Machado","provincia":"Parana","pais":"BRASIL","latitud":-26.019280699904563,"longitud":-51.350854780348484,"ranking":0},
{"id":14449,"nombre":"Cruzmaltina","localidad":"Cruzmaltina","provincia":"Parana","pais":"BRASIL","latitud":-23.990808,"longitud":-51.4431327,"ranking":0},
{"id":8611,"nombre":"Curiuva","localidad":"Curiúva","provincia":"Parana","pais":"BRASIL","latitud":-24.031351593355787,"longitud":-50.46091073149392,"ranking":0},
{"id":7410,"nombre":"Diamante do Darte","localidad":"Diamante do Norte","provincia":"Parana","pais":"BRASIL","latitud":-22.660354883274298,"longitud":-52.864724931521096,"ranking":0},
{"id":7143,"nombre":"Diamante D Oeste","localidad":"Diamante D'Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.944853035915987,"longitud":-54.10593602591612,"ranking":0},
{"id":7161,"nombre":"Dois Vizinhos","localidad":"Dois Vizinhos","provincia":"Parana","pais":"BRASIL","latitud":-25.7480571,"longitud":-53.0513996,"ranking":0},
{"id":15322,"nombre":"Sao Valentim ","localidad":"Dois Vizinhos","provincia":"Parana","pais":"BRASIL","latitud":-25.8278,"longitud":-53.13732,"ranking":0},
{"id":15329,"nombre":"Boa Vista","localidad":"Dois Vizinhos","provincia":"Parana","pais":"BRASIL","latitud":-25.76228,"longitud":-52.98636,"ranking":0},
{"id":14875,"nombre":"Doutor Camargo","localidad":"Doutor Camargo","provincia":"Parana","pais":"BRASIL","latitud":-23.5564228,"longitud":-52.2172826,"ranking":0},
{"id":15063,"nombre":"Eneas Marques","localidad":"Enéas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.94589,"longitud":-53.16082,"ranking":0},
{"id":15321,"nombre":"Rio Gamela","localidad":"Enéas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.92602,"longitud":-53.17579,"ranking":0},
{"id":15328,"nombre":"Encruzo Santa Lucia","localidad":"Enéas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.92619,"longitud":-53.11501,"ranking":0},
{"id":15334,"nombre":"Alto Bela Vista","localidad":"Enéas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.89023,"longitud":-53.11804,"ranking":0},
{"id":15351,"nombre":"Barra Bonita","localidad":"Enéas Marques","provincia":"Parana","pais":"BRASIL","latitud":-25.94581686519933,"longitud":-53.160732191470935,"ranking":0},
{"id":7245,"nombre":"Engenheiro Beltrao","localidad":"Engenheiro Beltrão","provincia":"Parana","pais":"BRASIL","latitud":-23.798327718257838,"longitud":-52.2607222738268,"ranking":0},
{"id":14812,"nombre":"Entre Rios do Oeste","localidad":"Entre Rios do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.7058341,"longitud":-54.2457135,"ranking":0},
{"id":7272,"nombre":"Espigao Alto do Iguacu","localidad":"Espigão Alto do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.457485603856345,"longitud":-52.918470965762744,"ranking":0},
{"id":10189,"nombre":"Farol","localidad":"Farol","provincia":"Parana","pais":"BRASIL","latitud":-24.096358337264547,"longitud":-52.62660184924089,"ranking":0},
{"id":7144,"nombre":"Faxinal","localidad":"Faxinal","provincia":"Parana","pais":"BRASIL","latitud":-24.00031897112751,"longitud":-51.329129547074004,"ranking":0},
{"id":7145,"nombre":"Fazenda Rio Grande","localidad":"Fazenda Rio Grande","provincia":"Parana","pais":"BRASIL","latitud":-25.645574172983487,"longitud":-49.311701442200864,"ranking":0},
{"id":8616,"nombre":"Fenix","localidad":"Fênix","provincia":"Parana","pais":"BRASIL","latitud":-23.919508973309522,"longitud":-51.98137010853325,"ranking":0},
{"id":7273,"nombre":"Fernandes Pinheiro","localidad":"Fernandes Pinheiro","provincia":"Parana","pais":"BRASIL","latitud":-25.416454415489365,"longitud":-50.54880762324054,"ranking":0},
{"id":9523,"nombre":"Figueira","localidad":"Figueira","provincia":"Parana","pais":"BRASIL","latitud":-23.850847553132905,"longitud":-50.3994643716182,"ranking":0},
{"id":14923,"nombre":"Flor da Serra do Sul","localidad":"Flor da Serra do Sul","provincia":"Parana","pais":"BRASIL","latitud":-26.2592079,"longitud":-53.311695,"ranking":0},
{"id":8612,"nombre":"Floresta","localidad":"Floresta","provincia":"Parana","pais":"BRASIL","latitud":-23.612661486446267,"longitud":-52.082717487133436,"ranking":0},
{"id":7213,"nombre":"Florestopolis","localidad":"Florestópolis","provincia":"Parana","pais":"BRASIL","latitud":-22.867980040234897,"longitud":-51.390949714779296,"ranking":0},
{"id":7214,"nombre":"Florida","localidad":"Flórida","provincia":"Parana","pais":"BRASIL","latitud":-21.61359088568447,"longitud":-51.170980318048585,"ranking":0},
{"id":7246,"nombre":"Formosa do Oeste","localidad":"Formosa do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.29585474209162,"longitud":-53.315003228842066,"ranking":0},
{"id":12326,"nombre":"Francisco Alves","localidad":"Francisco Alves","provincia":"Parana","pais":"BRASIL","latitud":-24.07292,"longitud":-53.84741,"ranking":0},
{"id":5572,"nombre":"Francisco Beltrao","localidad":"Francisco Beltrão","provincia":"Parana","pais":"BRASIL","latitud":-26.1064224,"longitud":-53.0470542,"ranking":0},
{"id":7274,"nombre":"Foz do Jordao","localidad":"Foz do Jordão","provincia":"Parana","pais":"BRASIL","latitud":-25.73838937107088,"longitud":-52.11979354943351,"ranking":0},
{"id":8461,"nombre":"General Carneiro","localidad":"General Carneiro","provincia":"Parana","pais":"BRASIL","latitud":-26.424020859513192,"longitud":-51.314936931442745,"ranking":0},
{"id":14984,"nombre":"Godoy Moreira","localidad":"Godoy Moreira","provincia":"Parana","pais":"BRASIL","latitud":-24.19324279198018,"longitud":-51.92350557437013,"ranking":0},
{"id":7162,"nombre":"Goioere","localidad":"Goioerê","provincia":"Parana","pais":"BRASIL","latitud":-24.189750735053693,"longitud":-53.02985850256089,"ranking":0},
{"id":10190,"nombre":"Grandes Rios","localidad":"Grandes Rios","provincia":"Parana","pais":"BRASIL","latitud":-24.144028026568055,"longitud":-51.507361006258755,"ranking":0},
{"id":7163,"nombre":"Guaira","localidad":"Guaíra","provincia":"Parana","pais":"BRASIL","latitud":-24.0832093,"longitud":-54.2566014,"ranking":0},
{"id":14971,"nombre":"Guairaca","localidad":"Guairaçá","provincia":"Parana","pais":"BRASIL","latitud":-24.0832055,"longitud":-54.2564145,"ranking":0},
{"id":7275,"nombre":"Guamiranga","localidad":"Guamiranga","provincia":"Parana","pais":"BRASIL","latitud":-25.1900358622457,"longitud":-50.81161634681327,"ranking":0},
{"id":8906,"nombre":"Guapirama","localidad":"Guapirama","provincia":"Parana","pais":"BRASIL","latitud":-23.514454557233577,"longitud":-50.03904648630308,"ranking":0},
{"id":14972,"nombre":"Guaraci","localidad":"Guaraci","provincia":"Parana","pais":"BRASIL","latitud":-22.9697257,"longitud":-51.6454383,"ranking":0},
{"id":5061,"nombre":"Guaraniacu","localidad":"Guaraniaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.102293344393477,"longitud":-52.86729655037355,"ranking":0},
{"id":10929,"nombre":"Guaraquecaba","localidad":"Guaraqueçaba","provincia":"Parana","pais":"BRASIL","latitud":-25.29681,"longitud":-48.32545,"ranking":0},
{"id":7215,"nombre":"Guaratuba","localidad":"Guaratuba","provincia":"Parana","pais":"BRASIL","latitud":-25.8773273,"longitud":-48.5844374,"ranking":1},
{"id":8901,"nombre":"Ibaiti","localidad":"Ibaiti","provincia":"Parana","pais":"BRASIL","latitud":-23.84921750078272,"longitud":-50.18368316977112,"ranking":0},
{"id":7146,"nombre":"Ibema","localidad":"Ibema","provincia":"Parana","pais":"BRASIL","latitud":-25.116569563360734,"longitud":-53.005764928782625,"ranking":0},
{"id":8910,"nombre":"Ibipora","localidad":"Ibiporã","provincia":"Parana","pais":"BRASIL","latitud":-23.26811,"longitud":-51.04386,"ranking":0},
{"id":7409,"nombre":"Icaraima","localidad":"Icaraíma","provincia":"Parana","pais":"BRASIL","latitud":-23.38970184854981,"longitud":-53.62249476874674,"ranking":0},
{"id":7216,"nombre":"Iguaracu","localidad":"Iguaraçu","provincia":"Parana","pais":"BRASIL","latitud":-23.19875710011057,"longitud":-51.82484868770978,"ranking":0},
{"id":7278,"nombre":"Imbau","localidad":"Imbaú","provincia":"Parana","pais":"BRASIL","latitud":-24.44653862181207,"longitud":-50.75973617358378,"ranking":0},
{"id":7279,"nombre":"Imbituva","localidad":"Imbituva","provincia":"Parana","pais":"BRASIL","latitud":-25.226137537021877,"longitud":-50.599587899371095,"ranking":0},
{"id":7280,"nombre":"Inacio Martins","localidad":"Inácio Martins","provincia":"Parana","pais":"BRASIL","latitud":-25.574111683114925,"longitud":-51.07440913146141,"ranking":0},
{"id":7217,"nombre":"Inaja","localidad":"Inajá","provincia":"Parana","pais":"BRASIL","latitud":-22.75234135924919,"longitud":-52.19748670316075,"ranking":0},
{"id":7281,"nombre":"Ipiranga","localidad":"Ipiranga","provincia":"Parana","pais":"BRASIL","latitud":-25.022604809559176,"longitud":-50.587137069220695,"ranking":0},
{"id":7318,"nombre":"Ipora","localidad":"Iporã","provincia":"Parana","pais":"BRASIL","latitud":-24.004663220504245,"longitud":-53.713365211627206,"ranking":0},
{"id":10191,"nombre":"Iracema do Oeste","localidad":"Iracema do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.4248839,"longitud":-53.3453277,"ranking":0},
{"id":7147,"nombre":"Irati","localidad":"Irati","provincia":"Parana","pais":"BRASIL","latitud":-25.4657741,"longitud":-50.6384714,"ranking":0},
{"id":10192,"nombre":"Iretama","localidad":"Iretama","provincia":"Parana","pais":"BRASIL","latitud":-24.42312875766755,"longitud":-52.10106324066314,"ranking":0},
{"id":7218,"nombre":"Itaguaje","localidad":"Itaguajé","provincia":"Parana","pais":"BRASIL","latitud":-22.619290102348742,"longitud":-51.96506253400775,"ranking":0},
{"id":15004,"nombre":"Itaipulandia","localidad":"Itaipulândia","provincia":"Parana","pais":"BRASIL","latitud":-25.13961201490368,"longitud":-54.2953031145886,"ranking":0},
{"id":8900,"nombre":"Itambaraca","localidad":"Itambaracá","provincia":"Parana","pais":"BRASIL","latitud":-23.01484815025727,"longitud":-50.40718806703065,"ranking":0},
{"id":7282,"nombre":"Itapejara D Oeste","localidad":"Itapejara d'Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.96536,"longitud":-52.81639,"ranking":0},
{"id":7219,"nombre":"Itauna do Sul","localidad":"Itaúna do Sul","provincia":"Parana","pais":"BRASIL","latitud":-22.728904348755865,"longitud":-52.88572813282293,"ranking":0},
{"id":7283,"nombre":"Ivai","localidad":"Ivaí","provincia":"Parana","pais":"BRASIL","latitud":-25.01000527370724,"longitud":-50.8596374910305,"ranking":0},
{"id":7114,"nombre":"Ivaipora","localidad":"Ivaiporã","provincia":"Parana","pais":"BRASIL","latitud":-24.248646063094707,"longitud":-51.67409196468104,"ranking":0},
{"id":8903,"nombre":"Jaboti","localidad":"Jaboti","provincia":"Parana","pais":"BRASIL","latitud":-23.74250488446674,"longitud":-50.075495075815155,"ranking":0},
{"id":8908,"nombre":"Jacarezinho ","localidad":"Jacarezinho","provincia":"Parana","pais":"BRASIL","latitud":-23.173459,"longitud":-49.978378,"ranking":0},
{"id":7220,"nombre":"Jaguapita","localidad":"Jaguapitã","provincia":"Parana","pais":"BRASIL","latitud":-23.110767249264608,"longitud":-51.531628573840706,"ranking":0},
{"id":8642,"nombre":"Jaguariaiva ","localidad":"Jaguariaíva","provincia":"Parana","pais":"BRASIL","latitud":-24.25912988917376,"longitud":-49.72068595189436,"ranking":0},
{"id":7249,"nombre":"Jandaia do Sul","localidad":"Jandaia do Sul","provincia":"Parana","pais":"BRASIL","latitud":-23.600310940030315,"longitud":-51.646293167262,"ranking":0},
{"id":7247,"nombre":"Janiopolis","localidad":"Janiópolis","provincia":"Parana","pais":"BRASIL","latitud":-24.14012865823113,"longitud":-52.7765606425329,"ranking":0},
{"id":8902,"nombre":"Japira","localidad":"Japira","provincia":"Parana","pais":"BRASIL","latitud":-23.8098628472224,"longitud":-50.139858078462645,"ranking":0},
{"id":10193,"nombre":"Jardim Alegre","localidad":"Jardim Alegre","provincia":"Parana","pais":"BRASIL","latitud":-24.176485095533256,"longitud":-51.69227475716509,"ranking":0},
{"id":7221,"nombre":"Jardim Olinda","localidad":"Jardim Olinda","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8897,"nombre":"Jataizinho","localidad":"Jataizinho","provincia":"Parana","pais":"BRASIL","latitud":-23.26081576826298,"longitud":-50.97709745872226,"ranking":0},
{"id":7248,"nombre":"Jesuitas","localidad":"Jesuítas","provincia":"Parana","pais":"BRASIL","latitud":-24.377045418018387,"longitud":-53.3851454844636,"ranking":0},
{"id":8907,"nombre":"Joaquim Tavora","localidad":"Joaquim Távora","provincia":"Parana","pais":"BRASIL","latitud":-23.498183421057437,"longitud":-49.922497630423315,"ranking":0},
{"id":14880,"nombre":"Juranda","localidad":"Juranda","provincia":"Parana","pais":"BRASIL","latitud":-24.421465,"longitud":-52.843882,"ranking":0},
{"id":8624,"nombre":"Jussara ","localidad":"Jussara","provincia":"Parana","pais":"BRASIL","latitud":-23.61968191301457,"longitud":-52.46869651921875,"ranking":0},
{"id":14973,"nombre":"Kalore","localidad":"Kaloré","provincia":"Parana","pais":"BRASIL","latitud":-23.824588761047583,"longitud":-51.66796518518667,"ranking":0},
{"id":8462,"nombre":"Lapa ","localidad":"Lapa","provincia":"Parana","pais":"BRASIL","latitud":-25.76710994512559,"longitud":-49.719642514013714,"ranking":0},
{"id":10194,"nombre":"Laranjal","localidad":"Laranjal","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5062,"nombre":"Laranjeiras do Sul","localidad":"Laranjeiras do Sul","provincia":"Parana","pais":"BRASIL","latitud":-25.402856,"longitud":-52.408643,"ranking":0},
{"id":15320,"nombre":"Rio Xagu","localidad":"Laranjeiras do Sul","provincia":"Parana","pais":"BRASIL","latitud":-25.32501,"longitud":-52.51445,"ranking":0},
{"id":10195,"nombre":"Lidianopolis","localidad":"Lidianópolis","provincia":"Parana","pais":"BRASIL","latitud":-24.105355654038128,"longitud":-51.65514707217876,"ranking":0},
{"id":10794,"nombre":"Lindoeste","localidad":"Lindoeste","provincia":"Parana","pais":"BRASIL","latitud":-25.26238397669556,"longitud":-53.575785083905274,"ranking":0},
{"id":7164,"nombre":"Loanda","localidad":"Loanda","provincia":"Parana","pais":"BRASIL","latitud":-22.936122367118664,"longitud":-53.13899146201975,"ranking":0},
{"id":7222,"nombre":"Lobato","localidad":"Lobato","provincia":"Parana","pais":"BRASIL","latitud":-23.00676908555316,"longitud":-51.95233315463183,"ranking":0},
{"id":10196,"nombre":"Luiziana","localidad":"Luiziana","provincia":"Parana","pais":"BRASIL","latitud":-24.285231707230224,"longitud":-52.278067368685214,"ranking":0},
{"id":14974,"nombre":"Lunardelli","localidad":"Lunardelli","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7223,"nombre":"Lupionopolis","localidad":"Lupionópolis","provincia":"Parana","pais":"BRASIL","latitud":-22.7535632783394,"longitud":-51.66127067547611,"ranking":0},
{"id":7284,"nombre":"Mallet","localidad":"Mallet","provincia":"Parana","pais":"BRASIL","latitud":-25.885869171676283,"longitud":-50.828656710616194,"ranking":0},
{"id":8614,"nombre":"Mambore","localidad":"Mamborê","provincia":"Parana","pais":"BRASIL","latitud":-24.31539921473342,"longitud":-52.528750245309844,"ranking":0},
{"id":7225,"nombre":"Mandaguacu","localidad":"Mandaguaçu","provincia":"Parana","pais":"BRASIL","latitud":-23.331346321199234,"longitud":-52.114581356961715,"ranking":0},
{"id":7226,"nombre":"Mandaguari","localidad":"Mandaguari","provincia":"Parana","pais":"BRASIL","latitud":-23.523078741274453,"longitud":-51.68019619570381,"ranking":0},
{"id":7149,"nombre":"Mandirituba","localidad":"Mandirituba","provincia":"Parana","pais":"BRASIL","latitud":-25.77744649271765,"longitud":-49.32516339316901,"ranking":0},
{"id":7285,"nombre":"Mangueirinha","localidad":"Mangueirinha","provincia":"Parana","pais":"BRASIL","latitud":-25.939943236376564,"longitud":-52.172590728209705,"ranking":0},
{"id":7250,"nombre":"Manoel Ribas","localidad":"Manoel Ribas","provincia":"Parana","pais":"BRASIL","latitud":-24.521017200641275,"longitud":-51.67153337381197,"ranking":0},
{"id":5064,"nombre":"Marechal Candido Rondon","localidad":"Marechal Cândido Rondon","provincia":"Parana","pais":"BRASIL","latitud":-24.55356,"longitud":-54.05439999999999,"ranking":0},
{"id":7227,"nombre":"Marialva","localidad":"Marialva","provincia":"Parana","pais":"BRASIL","latitud":-23.48532801657405,"longitud":-51.798217588266525,"ranking":0},
{"id":10197,"nombre":"Marilandia do Sul","localidad":"Marilândia do Sul","provincia":"Parana","pais":"BRASIL","latitud":-23.74571253804586,"longitud":-51.30201083149971,"ranking":0},
{"id":8622,"nombre":"Mariluz ","localidad":"Mariluz","provincia":"Parana","pais":"BRASIL","latitud":-24.005710254741754,"longitud":-53.1462507761599,"ranking":0},
{"id":5562,"nombre":"Mariopolis","localidad":"Mariópolis","provincia":"Parana","pais":"BRASIL","latitud":-26.35549977130315,"longitud":-52.554659289526334,"ranking":0},
{"id":7286,"nombre":"Maripa","localidad":"Maripá","provincia":"Parana","pais":"BRASIL","latitud":-24.418250238457542,"longitud":-53.82926213148592,"ranking":0},
{"id":5567,"nombre":"Marmeleiro","localidad":"Marmeleiro","provincia":"Parana","pais":"BRASIL","latitud":-26.151235820290484,"longitud":-53.02289279347258,"ranking":0},
{"id":14985,"nombre":"Marquinho","localidad":"Marquinho","provincia":"Parana","pais":"BRASIL","latitud":-25.112418102237427,"longitud":-52.2595866193122,"ranking":0},
{"id":14975,"nombre":"Marumbi","localidad":"Marumbi","provincia":"Parana","pais":"BRASIL","latitud":-23.705488948472205,"longitud":-51.64255732135552,"ranking":0},
{"id":7150,"nombre":"Matelandia","localidad":"Matelândia","provincia":"Parana","pais":"BRASIL","latitud":-25.241615474063067,"longitud":-53.97653722580822,"ranking":0},
{"id":10926,"nombre":"Caioba","localidad":"Matinhos","provincia":"Parana","pais":"BRASIL","latitud":-25.8331841,"longitud":-48.5378486,"ranking":0},
{"id":10927,"nombre":"Matinhos","localidad":"Matinhos","provincia":"Parana","pais":"BRASIL","latitud":-25.80754502866046,"longitud":-48.552079439195964,"ranking":0},
{"id":10198,"nombre":"Maua da Serra","localidad":"Mauá da Serra","provincia":"Parana","pais":"BRASIL","latitud":-23.90317406274521,"longitud":-51.224930390610076,"ranking":0},
{"id":5063,"nombre":"Medianeira","localidad":"Medianeira","provincia":"Parana","pais":"BRASIL","latitud":-25.29445,"longitud":-54.0963927,"ranking":0},
{"id":7287,"nombre":"Mercedes","localidad":"Mercedes","provincia":"Parana","pais":"BRASIL","latitud":-24.45046298842658,"longitud":-54.16438006148118,"ranking":0},
{"id":7288,"nombre":"Missal","localidad":"Missal","provincia":"Parana","pais":"BRASIL","latitud":-25.0949497081665,"longitud":-54.24835753527708,"ranking":0},
{"id":8621,"nombre":"Moreira Sales","localidad":"Moreira Sales","provincia":"Parana","pais":"BRASIL","latitud":-24.045732991069006,"longitud":-53.012017752390804,"ranking":0},
{"id":10930,"nombre":"Morretes","localidad":"Morretes","provincia":"Parana","pais":"BRASIL","latitud":-25.48213,"longitud":-48.83052,"ranking":0},
{"id":7229,"nombre":"Munhoz de Melo","localidad":"Munhoz de Melo","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7262,"nombre":"Nova Aurora","localidad":"Nova Aurora","provincia":"Parana","pais":"BRASIL","latitud":-24.524880049944514,"longitud":-53.262185558314954,"ranking":0},
{"id":10199,"nombre":"Nova Cantu","localidad":"Nova Cantu","provincia":"Parana","pais":"BRASIL","latitud":-24.668997017114986,"longitud":-52.58193855227213,"ranking":0},
{"id":7231,"nombre":"Nova Esperanca","localidad":"Nova Esperança","provincia":"Parana","pais":"BRASIL","latitud":-23.181589193596356,"longitud":-52.20352512513907,"ranking":0},
{"id":14987,"nombre":"Nova Esperanca do Sudoeste","localidad":"Nova Esperança do Sudoeste","provincia":"Parana","pais":"BRASIL","latitud":-23.159315354359066,"longitud":-52.21302293003823,"ranking":0},
{"id":15272,"nombre":"Gaviao","localidad":"Nova Esperança do Sudoeste","provincia":"Parana","pais":"BRASIL","latitud":-25.878456378000113,"longitud":-53.2813638387586,"ranking":0},
{"id":8899,"nombre":"Nova Fatima","localidad":"Nova Fátima","provincia":"Parana","pais":"BRASIL","latitud":-23.43637,"longitud":-50.55881,"ranking":0},
{"id":7151,"nombre":"Nova Laranjeiras","localidad":"Nova Laranjeiras","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15254,"nombre":"Rio Pereira","localidad":"Nova Laranjeiras","provincia":"Parana","pais":"BRASIL","latitud":-25.306554850327682,"longitud":-52.54348866090404,"ranking":0},
{"id":15271,"nombre":"Posto Dos Indios","localidad":"Nova Laranjeiras","provincia":"Parana","pais":"BRASIL","latitud":-25.314940689702606,"longitud":-52.59954115009098,"ranking":0},
{"id":7232,"nombre":"Nova Londrina","localidad":"Nova Londrina","provincia":"Parana","pais":"BRASIL","latitud":-22.75988090206555,"longitud":-52.98333441334124,"ranking":0},
{"id":14976,"nombre":"Nova Santa Barbara","localidad":"Nova Santa Bárbara","provincia":"Parana","pais":"BRASIL","latitud":-23.5897376,"longitud":-50.762606,"ranking":0},
{"id":7290,"nombre":"Nova Santa Rosa","localidad":"Nova Santa Rosa","provincia":"Parana","pais":"BRASIL","latitud":-24.46756983261615,"longitud":-53.95590798611055,"ranking":0},
{"id":7289,"nombre":"Nova Prata do Iguacu","localidad":"Nova Prata do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.635989449682214,"longitud":-53.3449896570713,"ranking":0},
{"id":10200,"nombre":"Nova Tebas","localidad":"Nova Tebas","provincia":"Parana","pais":"BRASIL","latitud":-24.437944776200943,"longitud":-51.94779467080094,"ranking":0},
{"id":7255,"nombre":"Ortigueira","localidad":"Ortigueira","provincia":"Parana","pais":"BRASIL","latitud":-24.208708728413587,"longitud":-50.92602830265425,"ranking":0},
{"id":15051,"nombre":"Ouro Verde do Oeste","localidad":"Ouro Verde do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.7738439,"longitud":-53.9023969,"ranking":0},
{"id":8623,"nombre":"Paicandu","localidad":"Paiçandu","provincia":"Parana","pais":"BRASIL","latitud":-23.462699897862926,"longitud":-52.047804250904505,"ranking":0},
{"id":5614,"nombre":"Palmas","localidad":"Palmas","provincia":"Parana","pais":"BRASIL","latitud":-26.491370116621972,"longitud":-51.990161684746795,"ranking":0},
{"id":7291,"nombre":"Palmeira","localidad":"Palmeira","provincia":"Parana","pais":"BRASIL","latitud":-25.422329116065104,"longitud":-49.995048738160556,"ranking":0},
{"id":10201,"nombre":"Palmital","localidad":"Palmital","provincia":"Parana","pais":"BRASIL","latitud":-24.890240035828096,"longitud":-52.21156677956275,"ranking":0},
{"id":5568,"nombre":"Palotina","localidad":"Palotina","provincia":"Parana","pais":"BRASIL","latitud":-24.285346,"longitud":-53.837107,"ranking":0},
{"id":8618,"nombre":"Paraiso do Norte","localidad":"Paraíso do Norte","provincia":"Parana","pais":"BRASIL","latitud":-23.283514230051637,"longitud":-52.60428342727688,"ranking":0},
{"id":7233,"nombre":"Paranacity","localidad":"Paranacity","provincia":"Parana","pais":"BRASIL","latitud":-22.930028173719116,"longitud":-52.152469086803855,"ranking":0},
{"id":5065,"nombre":"Paranagua","localidad":"Paranaguá","provincia":"Parana","pais":"BRASIL","latitud":-25.52326583507215,"longitud":-48.507936925389394,"ranking":0},
{"id":15289,"nombre":"Alexandra","localidad":"Paranaguá","provincia":"Parana","pais":"BRASIL","latitud":-25.55687081963019,"longitud":-48.62714188205589,"ranking":0},
{"id":7234,"nombre":"Paranapoema","localidad":"Paranapoema","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7235,"nombre":"Paranavai","localidad":"Paranavaí","provincia":"Parana","pais":"BRASIL","latitud":-23.090589275439484,"longitud":-52.463821901973525,"ranking":0},
{"id":7292,"nombre":"Pato Bragado","localidad":"Pato Bragado","provincia":"Parana","pais":"BRASIL","latitud":-24.629765129194787,"longitud":-54.228133004608566,"ranking":0},
{"id":5565,"nombre":"Pato Branco","localidad":"Pato Branco","provincia":"Parana","pais":"BRASIL","latitud":-26.22215493480787,"longitud":-52.68069980697561,"ranking":0},
{"id":7293,"nombre":"Paulo Frontin","localidad":"Paulo Frontin","provincia":"Parana","pais":"BRASIL","latitud":-26.04012860960289,"longitud":-50.83461718388771,"ranking":0},
{"id":7251,"nombre":"Peabiru","localidad":"Peabiru","provincia":"Parana","pais":"BRASIL","latitud":-23.917383310694238,"longitud":-52.34458755623722,"ranking":0},
{"id":12327,"nombre":"Perobal","localidad":"Perobal","provincia":"Parana","pais":"BRASIL","latitud":-23.89468,"longitud":-53.40862,"ranking":0},
{"id":8628,"nombre":"Perola","localidad":"Pérola","provincia":"Parana","pais":"BRASIL","latitud":-23.79830403316251,"longitud":-53.670574900973335,"ranking":0},
{"id":7259,"nombre":"Perola D Oeste","localidad":"Pérola d'Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.828480452443298,"longitud":-53.74239187038329,"ranking":0},
{"id":8912,"nombre":"Pinhalao","localidad":"Pinhalão","provincia":"Parana","pais":"BRASIL","latitud":-23.788731488307814,"longitud":-50.05792091726334,"ranking":0},
{"id":8465,"nombre":"Pinhao ","localidad":"Pinhão","provincia":"Parana","pais":"BRASIL","latitud":-25.694365965415276,"longitud":-51.661871597223914,"ranking":0},
{"id":7295,"nombre":"Pirai do Sul","localidad":"Piraí do Sul","provincia":"Parana","pais":"BRASIL","latitud":-24.54205011080386,"longitud":-49.93842664522883,"ranking":0},
{"id":7252,"nombre":"Pitanga","localidad":"Pitanga","provincia":"Parana","pais":"BRASIL","latitud":-24.764082914289265,"longitud":-51.766601465639,"ranking":0},
{"id":14977,"nombre":"Pitangueiras","localidad":"Pitangueiras","provincia":"Parana","pais":"BRASIL","latitud":-21.0071498,"longitud":-48.219713,"ranking":0},
{"id":8630,"nombre":"Planaltina do Parana","localidad":"Planaltina do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-23.023695873515994,"longitud":-52.91376567332216,"ranking":0},
{"id":7260,"nombre":"Planalto","localidad":"Planalto","provincia":"Parana","pais":"BRASIL","latitud":-25.671462859030694,"longitud":-53.80650253637286,"ranking":0},
{"id":10931,"nombre":"Pontal do Parana","localidad":"Pontal do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.6956499,"longitud":-48.4776063,"ranking":0},
{"id":10932,"nombre":"Pontal do Sul","localidad":"Pontal do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.5788582,"longitud":-48.36116759999999,"ranking":0},
{"id":10933,"nombre":"Ipanema","localidad":"Pontal do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.65635,"longitud":-48.4476056,"ranking":0},
{"id":10934,"nombre":"Praia de Leste","localidad":"Pontal do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.69562,"longitud":-48.47756,"ranking":0},
{"id":7236,"nombre":"Porecatu","localidad":"Porecatu","provincia":"Parana","pais":"BRASIL","latitud":-22.751798347289014,"longitud":-51.37245245961777,"ranking":0},
{"id":7296,"nombre":"Porto Amazonas","localidad":"Porto Amazonas","provincia":"Parana","pais":"BRASIL","latitud":-25.552342868572396,"longitud":-49.89843870077491,"ranking":0},
{"id":14986,"nombre":"Porto Rico","localidad":"Porto Rico","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8463,"nombre":"Porto Vitoria ","localidad":"Porto Vitória","provincia":"Parana","pais":"BRASIL","latitud":-26.159960386883707,"longitud":-51.2296283556902,"ranking":0},
{"id":7408,"nombre":"Prado Ferreira","localidad":"Prado Ferreira","provincia":"Parana","pais":"BRASIL","latitud":-23.0417722,"longitud":-51.4442398,"ranking":0},
{"id":7297,"nombre":"Pranchita","localidad":"Pranchita","provincia":"Parana","pais":"BRASIL","latitud":-26.02199919291966,"longitud":-53.73959534596742,"ranking":0},
{"id":7207,"nombre":"Presidente Castelo Branco","localidad":"Presidente Castelo Branco","provincia":"Parana","pais":"BRASIL","latitud":-23.278986446482172,"longitud":-52.152868549902344,"ranking":0},
{"id":7253,"nombre":"Prudentopolis","localidad":"Prudentópolis","provincia":"Parana","pais":"BRASIL","latitud":-25.211719145076895,"longitud":-50.975342249765056,"ranking":0},
{"id":10202,"nombre":"Quarto Centenario","localidad":"Quarto Centenário","provincia":"Parana","pais":"BRASIL","latitud":-24.278090644384125,"longitud":-53.07665781614526,"ranking":0},
{"id":8915,"nombre":"Quatigua","localidad":"Quatiguá","provincia":"Parana","pais":"BRASIL","latitud":-23.56849,"longitud":-49.91373,"ranking":0},
{"id":7298,"nombre":"Quatro Barras","localidad":"Quatro Barras","provincia":"Parana","pais":"BRASIL","latitud":-25.367954879960518,"longitud":-49.07368909113458,"ranking":0},
{"id":9236,"nombre":"Quatro Pontes","localidad":"Quatro Pontes","provincia":"Parana","pais":"BRASIL","latitud":-24.576722802570288,"longitud":-53.98145536806105,"ranking":0},
{"id":5613,"nombre":"Quedas do Iguacu","localidad":"Quedas do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.45420498271061,"longitud":-52.912455673795286,"ranking":0},
{"id":15325,"nombre":"Salto Osorio ","localidad":"Quedas do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.5203048,"longitud":-53.02937559999999,"ranking":0},
{"id":15326,"nombre":"Linha Iguacu","localidad":"Quedas do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.48006,"longitud":-52.96291,"ranking":0},
{"id":15327,"nombre":"Fazendinha","localidad":"Quedas do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.40728,"longitud":-53.01808,"ranking":0},
{"id":8632,"nombre":"Querencia do Norte","localidad":"Querência do Norte","provincia":"Parana","pais":"BRASIL","latitud":-23.085023258975326,"longitud":-53.487221468122534,"ranking":0},
{"id":8615,"nombre":"Quinta do Sol","localidad":"Quinta do Sol","provincia":"Parana","pais":"BRASIL","latitud":-23.85031109466364,"longitud":-52.13022412771347,"ranking":0},
{"id":7152,"nombre":"Quitandinha","localidad":"Quitandinha","provincia":"Parana","pais":"BRASIL","latitud":-25.865216596311647,"longitud":-49.5012250893939,"ranking":0},
{"id":12360,"nombre":"Rancho Alegre","localidad":"Rancho Alegre","provincia":"Parana","pais":"BRASIL","latitud":-23.0695211,"longitud":-50.9157199,"ranking":0},
{"id":5580,"nombre":"Realeza","localidad":"Realeza","provincia":"Parana","pais":"BRASIL","latitud":-25.772938983012484,"longitud":-53.53251318988185,"ranking":0},
{"id":15269,"nombre":"Sao Roque","localidad":"Realeza","provincia":"Parana","pais":"BRASIL","latitud":-25.718015835404753,"longitud":-53.53857753798529,"ranking":0},
{"id":15270,"nombre":"Marmelandia","localidad":"Realeza","provincia":"Parana","pais":"BRASIL","latitud":-25.575138426396304,"longitud":-53.56610822228716,"ranking":0},
{"id":15318,"nombre":"Sertaneja do Sudoeste","localidad":"Realeza","provincia":"Parana","pais":"BRASIL","latitud":-25.693693,"longitud":-53.55501899999999,"ranking":0},
{"id":7299,"nombre":"Reboucas","localidad":"Rebouças","provincia":"Parana","pais":"BRASIL","latitud":-25.620912091024223,"longitud":-50.697816085780964,"ranking":0},
{"id":7300,"nombre":"Renascenca","localidad":"Renascença","provincia":"Parana","pais":"BRASIL","latitud":-26.156848712052614,"longitud":-52.97102242540633,"ranking":0},
{"id":7301,"nombre":"Reserva","localidad":"Reserva","provincia":"Parana","pais":"BRASIL","latitud":-24.652894537818185,"longitud":-50.84742380363529,"ranking":0},
{"id":10204,"nombre":"Reserva do Iguacu","localidad":"Reserva do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.83494635395747,"longitud":-52.02279747825072,"ranking":0},
{"id":8917,"nombre":"Ribeirao Claro","localidad":"Ribeirão Claro","provincia":"Parana","pais":"BRASIL","latitud":-23.1964047,"longitud":-49.7513447,"ranking":0},
{"id":8918,"nombre":"Ribeirao do Pinhal","localidad":"Ribeirão do Pinhal","provincia":"Parana","pais":"BRASIL","latitud":-23.40137,"longitud":-50.35961,"ranking":0},
{"id":7302,"nombre":"Rio Azul","localidad":"Rio Azul","provincia":"Parana","pais":"BRASIL","latitud":-25.725499046109483,"longitud":-50.79200797815957,"ranking":0},
{"id":10205,"nombre":"Rio Bom","localidad":"Rio Bom","provincia":"Parana","pais":"BRASIL","latitud":-23.763901801845297,"longitud":-51.407468057383944,"ranking":0},
{"id":10206,"nombre":"Rio Branco do Ivai","localidad":"Rio Branco do Ivaí","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14999,"nombre":"Rio Branco do Sul","localidad":"Rio Branco do Sul","provincia":"Parana","pais":"BRASIL","latitud":-25.18431098051101,"longitud":-49.31232080662746,"ranking":0},
{"id":8464,"nombre":"Rio Negro","localidad":"Rio Negro","provincia":"Parana","pais":"BRASIL","latitud":-26.09477984326588,"longitud":-49.79236378363649,"ranking":0},
{"id":7165,"nombre":"Rolandia","localidad":"Rolândia","provincia":"Parana","pais":"BRASIL","latitud":-23.309951306728646,"longitud":-51.36842927383663,"ranking":3},
{"id":10207,"nombre":"Roncador","localidad":"Roncador","provincia":"Parana","pais":"BRASIL","latitud":-24.600176268903258,"longitud":-52.27468187489222,"ranking":0},
{"id":8619,"nombre":"Rondon ","localidad":"Rondon","provincia":"Parana","pais":"BRASIL","latitud":-23.41402829293404,"longitud":-52.765949169419024,"ranking":0},
{"id":10208,"nombre":"Rosario do Ivai","localidad":"Rosário do Ivaí","provincia":"Parana","pais":"BRASIL","latitud":-24.255514861789404,"longitud":-51.25077359581068,"ranking":0},
{"id":9524,"nombre":"Salto do Itararé","localidad":"Salto do Itararé","provincia":"Parana","pais":"BRASIL","latitud":-23.607179463044908,"longitud":-49.630979238269994,"ranking":0},
{"id":7303,"nombre":"Salto do Lontra","localidad":"Salto do Lontra","provincia":"Parana","pais":"BRASIL","latitud":-25.781006342143826,"longitud":-53.31149414490349,"ranking":0},
{"id":15258,"nombre":"Pinhal da Vargem ","localidad":"Salto do Lontra","provincia":"Parana","pais":"BRASIL","latitud":-25.801059017564206,"longitud":-53.28015351409672,"ranking":0},
{"id":15317,"nombre":"Brandao","localidad":"Salto do Lontra","provincia":"Parana","pais":"BRASIL","latitud":-25.740291696457543,"longitud":-53.334545493483155,"ranking":0},
{"id":8919,"nombre":"Santa Amelia","localidad":"Santa Amélia","provincia":"Parana","pais":"BRASIL","latitud":-23.26645,"longitud":-50.42404,"ranking":0},
{"id":14979,"nombre":"Santa Cecilia do Pavao","localidad":"Santa Cecília do Pavão","provincia":"Parana","pais":"BRASIL","latitud":-23.5174003,"longitud":-50.7855695,"ranking":0},
{"id":14876,"nombre":"Santa Cruz do Monte Castelo","localidad":"Santa Cruz de Monte Castelo","provincia":"Parana","pais":"BRASIL","latitud":-22.9647398,"longitud":-53.2944212,"ranking":0},
{"id":14942,"nombre":"Santa Fe","localidad":"Santa Fé","provincia":"Parana","pais":"BRASIL","latitud":-23.041071856321963,"longitud":-51.80676976171387,"ranking":0},
{"id":7153,"nombre":"Santa Helena","localidad":"Santa Helena","provincia":"Parana","pais":"BRASIL","latitud":-24.860034149510483,"longitud":-54.33028237449109,"ranking":0},
{"id":7160,"nombre":"Santa Ines","localidad":"Santa Inês","provincia":"Parana","pais":"BRASIL","latitud":-22.641065541555378,"longitud":-51.902894373764035,"ranking":0},
{"id":8631,"nombre":"Santa Isabel do Ivai","localidad":"Santa Isabel do Ivaí","provincia":"Parana","pais":"BRASIL","latitud":-23.006779984960975,"longitud":-53.19034198548373,"ranking":0},
{"id":7304,"nombre":"Santa Izabel do Oeste","localidad":"Santa Izabel do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.81964486412293,"longitud":-53.483888828152246,"ranking":0},
{"id":15256,"nombre":"Rio da Prata","localidad":"Santa Izabel do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.829748834156078,"longitud":-53.3873179883197,"ranking":0},
{"id":10798,"nombre":"Santa Lucia","localidad":"Santa Lúcia","provincia":"Parana","pais":"BRASIL","latitud":-25.406513965390253,"longitud":-53.56917465582293,"ranking":0},
{"id":10209,"nombre":"Santa Maria do Oeste","localidad":"Santa Maria do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-24.932576343566247,"longitud":-51.879844054078866,"ranking":0},
{"id":14980,"nombre":"Santa Mariana","localidad":"Santa Mariana","provincia":"Parana","pais":"BRASIL","latitud":-23.1497055,"longitud":-50.5138068,"ranking":0},
{"id":8920,"nombre":"Santana do Itarare","localidad":"Santana do Itararé","provincia":"Parana","pais":"BRASIL","latitud":-23.7563692,"longitud":-49.6291946,"ranking":0},
{"id":7154,"nombre":"Santa Tereza do Oeste","localidad":"Santa Tereza do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.052113012791278,"longitud":-53.6159812156685,"ranking":0},
{"id":7155,"nombre":"Santa Terezinha de Itaipu","localidad":"Santa Terezinha de Itaipu","provincia":"Parana","pais":"BRASIL","latitud":-25.43962821900713,"longitud":-54.39609684526511,"ranking":0},
{"id":8909,"nombre":"Santo Antonio da Platina","localidad":"Santo Antônio da Platina","provincia":"Parana","pais":"BRASIL","latitud":-23.28922,"longitud":-50.06106,"ranking":0},
{"id":7254,"nombre":"Santo Antonio do Caiua","localidad":"Santo Antônio do Caiuá","provincia":"Parana","pais":"BRASIL","latitud":-22.73976303814653,"longitud":-52.3473708317575,"ranking":0},
{"id":14981,"nombre":"Santo Antonio do Paraiso","localidad":"Santo Antônio do Paraíso","provincia":"Parana","pais":"BRASIL","latitud":-23.1439116,"longitud":-50.514304,"ranking":0},
{"id":7261,"nombre":"Santo Antonio do Sudoeste","localidad":"Santo Antônio do Sudoeste","provincia":"Parana","pais":"BRASIL","latitud":-26.075504958534175,"longitud":-53.72532527958017,"ranking":0},
{"id":7238,"nombre":"Santo Inacio","localidad":"Santo Inácio","provincia":"Parana","pais":"BRASIL","latitud":-22.69655267955993,"longitud":-51.79179693184387,"ranking":0},
{"id":15274,"nombre":"Sao Jeronimo da Serra","localidad":"São Jerônimo da Serra","provincia":"Parana","pais":"BRASIL","latitud":-23.722762954074007,"longitud":-50.73899140587837,"ranking":0},
{"id":7305,"nombre":"Sao Joao","localidad":"São João","provincia":"Parana","pais":"BRASIL","latitud":-25.824704027577937,"longitud":-52.722016833725625,"ranking":0},
{"id":7239,"nombre":"Sao Joao do Caiua","localidad":"São João do Caiuá","provincia":"Parana","pais":"BRASIL","latitud":-22.84902893735575,"longitud":-52.338339217491665,"ranking":0},
{"id":7256,"nombre":"Sao Joao do Ivai","localidad":"São João do Ivaí","provincia":"Parana","pais":"BRASIL","latitud":-23.993989870362903,"longitud":-51.82030082203414,"ranking":0},
{"id":7306,"nombre":"Sao Joao do Triunfo","localidad":"São João do Triunfo","provincia":"Parana","pais":"BRASIL","latitud":-25.68753014929494,"longitud":-50.30199373390706,"ranking":0},
{"id":7307,"nombre":"Sao Jorge D Oeste","localidad":"São Jorge d'Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.705002086401212,"longitud":-52.91810377763617,"ranking":0},
{"id":8629,"nombre":"Sao Jorge do Patrocinio","localidad":"São Jorge do Patrocínio","provincia":"Parana","pais":"BRASIL","latitud":-23.761544739395998,"longitud":-53.885400050472995,"ranking":0},
{"id":7139,"nombre":"Campo Largo Roseira","localidad":"São José dos Pinhais","provincia":"Parana","pais":"BRASIL","latitud":-25.52989087214191,"longitud":-49.20460299563079,"ranking":0},
{"id":12366,"nombre":"Sao Jose dos Pinhais","localidad":"São José dos Pinhais","provincia":"Parana","pais":"BRASIL","latitud":-25.52989,"longitud":-49.20492,"ranking":0},
{"id":10210,"nombre":"Sao Manoel do Parana","localidad":"São Manoel do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-23.395167452345493,"longitud":-52.64478253653564,"ranking":0},
{"id":7240,"nombre":"Sao Mateus do Sul","localidad":"São Mateus do Sul","provincia":"Parana","pais":"BRASIL","latitud":-25.867421752435742,"longitud":-50.38359128834328,"ranking":0},
{"id":7156,"nombre":"Sao Miguel do Iguacu","localidad":"São Miguel do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.343311826330993,"longitud":-54.23488599814878,"ranking":0},
{"id":7309,"nombre":"Sao Pedro do Iguacu","localidad":"São Pedro do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-24.93329657934444,"longitud":-53.857702365907286,"ranking":0},
{"id":8617,"nombre":"Sao Pedro do Ivai","localidad":"São Pedro do Ivaí","provincia":"Parana","pais":"BRASIL","latitud":-23.865829903197245,"longitud":-51.862978940189336,"ranking":0},
{"id":14982,"nombre":"Sao Sebastiao da Amoreira","localidad":"São Sebastião da Amoreira","provincia":"Parana","pais":"BRASIL","latitud":-23.464655587583497,"longitud":-50.76074320537103,"ranking":0},
{"id":14983,"nombre":"Sapopema","localidad":"Sapopema","provincia":"Parana","pais":"BRASIL","latitud":-23.9099771,"longitud":-50.5766277,"ranking":0},
{"id":9487,"nombre":"Sarandi","localidad":"Sarandi","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10797,"nombre":"Saudade do Iguacu","localidad":"Saudade do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.69241500170395,"longitud":-52.61805829751671,"ranking":0},
{"id":8643,"nombre":"Senges ","localidad":"Sengés","provincia":"Parana","pais":"BRASIL","latitud":-24.113365427247334,"longitud":-49.462682773683106,"ranking":0},
{"id":7403,"nombre":"Serranopolis do Iguacu","localidad":"Serranópolis do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-25.380069432728234,"longitud":-54.05390585311889,"ranking":0},
{"id":9059,"nombre":"Sertanopolis","localidad":"Sertanópolis","provincia":"Parana","pais":"BRASIL","latitud":-23.058061450547896,"longitud":-51.03742008346024,"ranking":0},
{"id":8922,"nombre":"Siqueira Campos","localidad":"Siqueira Campos","provincia":"Parana","pais":"BRASIL","latitud":-23.689107778280746,"longitud":-49.823740450329346,"ranking":0},
{"id":14450,"nombre":"Tamarana","localidad":"Tamarana","provincia":"Parana","pais":"BRASIL","latitud":-23.72246,"longitud":-51.09370999999999,"ranking":0},
{"id":8625,"nombre":"Tapejara ","localidad":"Tapejara","provincia":"Parana","pais":"BRASIL","latitud":-23.735622711854532,"longitud":-52.88303191815525,"ranking":0},
{"id":7311,"nombre":"Teixeira Soares","localidad":"Teixeira Soares","provincia":"Parana","pais":"BRASIL","latitud":-25.367410439554426,"longitud":-50.45744980003516,"ranking":0},
{"id":7312,"nombre":"Telemaco Borba","localidad":"Telêmaco Borba","provincia":"Parana","pais":"BRASIL","latitud":-24.329662,"longitud":-50.6265141,"ranking":0},
{"id":7166,"nombre":"Terra Boa","localidad":"Terra Boa","provincia":"Parana","pais":"BRASIL","latitud":-23.767704430196343,"longitud":-52.45026120736196,"ranking":0},
{"id":7241,"nombre":"Terra Rica","localidad":"Terra Rica","provincia":"Parana","pais":"BRASIL","latitud":-22.726525570666897,"longitud":-52.62057573649074,"ranking":0},
{"id":7313,"nombre":"Terra Roxa","localidad":"Terra Roxa","provincia":"Parana","pais":"BRASIL","latitud":-24.167106641313165,"longitud":-54.09738229661737,"ranking":0},
{"id":7314,"nombre":"Tibagi","localidad":"Tibagi","provincia":"Parana","pais":"BRASIL","latitud":-24.510627181286058,"longitud":-50.41434468652543,"ranking":0},
{"id":5066,"nombre":"Toledo","localidad":"Toledo","provincia":"Parana","pais":"BRASIL","latitud":-24.721266420873814,"longitud":-53.73804152028243,"ranking":0},
{"id":8923,"nombre":"Tomazina","localidad":"Tomazina","provincia":"Parana","pais":"BRASIL","latitud":-23.77564264149768,"longitud":-49.95815297130121,"ranking":0},
{"id":7399,"nombre":"Tres Barras do Parana","localidad":"Três Barras do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.420491326387964,"longitud":-53.18669151340027,"ranking":0},
{"id":15324,"nombre":"Santo Antonio","localidad":"Três Barras do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.34022,"longitud":-53.17186,"ranking":0},
{"id":15350,"nombre":"Sede Alemao","localidad":"Três Barras do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-25.421260490028157,"longitud":-53.186898753967704,"ranking":0},
{"id":14966,"nombre":"Tunas do Parana","localidad":"Tunas do Paraná","provincia":"Parana","pais":"BRASIL","latitud":-24.97223323551973,"longitud":-49.08550326246925,"ranking":0},
{"id":8620,"nombre":"Tuneiras do Oeste","localidad":"Tuneiras do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-23.869923578224487,"longitud":-52.87758935349049,"ranking":0},
{"id":12328,"nombre":"Tupassi","localidad":"Tupãssi","provincia":"Parana","pais":"BRASIL","latitud":-24.58028,"longitud":-53.51212,"ranking":0},
{"id":10211,"nombre":"Turvo","localidad":"Turvo","provincia":"Parana","pais":"BRASIL","latitud":-25.0483149,"longitud":-51.53940859999999,"ranking":0},
{"id":5581,"nombre":"Ubirata","localidad":"Ubiratã","provincia":"Parana","pais":"BRASIL","latitud":-24.543224705810452,"longitud":-52.99137273121844,"ranking":0},
{"id":5578,"nombre":"Umuarama","localidad":"Umuarama","provincia":"Parana","pais":"BRASIL","latitud":-23.764302643388795,"longitud":-53.31663985388429,"ranking":0},
{"id":5067,"nombre":"Uniao da Vitoria","localidad":"União da Vitória","provincia":"Parana","pais":"BRASIL","latitud":-26.23565418558521,"longitud":-51.0870480172596,"ranking":0},
{"id":7242,"nombre":"Uniflor","localidad":"Uniflor","provincia":"Parana","pais":"BRASIL","latitud":-23.08560308099754,"longitud":-52.15828540339923,"ranking":0},
{"id":9525,"nombre":"Uraí","localidad":"Uraí","provincia":"Parana","pais":"BRASIL","latitud":-23.20240632526275,"longitud":-50.79977156696298,"ranking":0},
{"id":8925,"nombre":"Wenceslau Braz","localidad":"Wenceslau Braz","provincia":"Parana","pais":"BRASIL","latitud":-23.87075998453025,"longitud":-49.80204000266136,"ranking":0},
{"id":8924,"nombre":"Ventania","localidad":"Ventania","provincia":"Parana","pais":"BRASIL","latitud":-24.246727677695304,"longitud":-50.24526280245379,"ranking":0},
{"id":7157,"nombre":"Vera Cruz do Oeste","localidad":"Vera Cruz do Oeste","provincia":"Parana","pais":"BRASIL","latitud":-25.0563088264653,"longitud":-53.876996489144275,"ranking":0},
{"id":15127,"nombre":"Vere","localidad":"Verê","provincia":"Parana","pais":"BRASIL","latitud":-25.88098110099582,"longitud":-52.90811886981912,"ranking":0},
{"id":14998,"nombre":"Doutor Ulysses","localidad":"Doutor Ulysses","provincia":"Parana","pais":"BRASIL","latitud":-24.5672519,"longitud":-49.420333,"ranking":0},
{"id":7158,"nombre":"Virmond","localidad":"Virmond","provincia":"Parana","pais":"BRASIL","latitud":-25.378473870327444,"longitud":-52.20284671296386,"ranking":0},
{"id":7317,"nombre":"Vitorino","localidad":"Vitorino","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8627,"nombre":"Xambre","localidad":"Xambrê","provincia":"Parana","pais":"BRASIL","latitud":-23.73406963385322,"longitud":-53.49166259701164,"ranking":0},
{"id":5560,"nombre":"Abelardo Luz","localidad":"Abelardo Luz","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.5757513866572,"longitud":-52.334547484682346,"ranking":0},
{"id":7648,"nombre":"Agua Doce","localidad":"Água Doce","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.998885657376853,"longitud":-51.55331783169545,"ranking":0},
{"id":7650,"nombre":"Aguas de Chapeco","localidad":"Águas de Chapecó","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.074702208990328,"longitud":-52.98502459910817,"ranking":0},
{"id":7651,"nombre":"Aguas Mornas","localidad":"Águas Mornas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.696108583099267,"longitud":-48.82094978783791,"ranking":0},
{"id":5988,"nombre":"Alfredo Wagner","localidad":"Alfredo Wagner","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.69907674700033,"longitud":-49.3350351270111,"ranking":0},
{"id":15111,"nombre":"Alto Bela Vista","localidad":"Alto Bela Vista","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.4318642,"longitud":-51.9079954,"ranking":0},
{"id":14950,"nombre":"Anchieta","localidad":"Anchieta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.5369671,"longitud":-53.33169669999999,"ranking":0},
{"id":5990,"nombre":"Apiuna","localidad":"Apiúna","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.03368844113885,"longitud":-49.38727201813401,"ranking":0},
{"id":7654,"nombre":"Arabuta","localidad":"Arabutã","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5991,"nombre":"Araquari","localidad":"Araquari","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.370531517696524,"longitud":-48.70680355047124,"ranking":0},
{"id":7662,"nombre":"Arroio Trinta","localidad":"Arroio Trinta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.930757076420083,"longitud":-51.33561970183961,"ranking":0},
{"id":8188,"nombre":"Arvoredo","localidad":"Arvoredo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0750116162478,"longitud":-52.45968605971447,"ranking":0},
{"id":5993,"nombre":"Ascurra ","localidad":"Ascurra","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.95699802033031,"longitud":-49.374410091297875,"ranking":0},
{"id":7693,"nombre":"Barra Bonita","localidad":"Barra Bonita","provincia":"Santa Catarina","pais":"BRASIL","latitud":-22.48591693555782,"longitud":-48.54879455720584,"ranking":0},
{"id":5994,"nombre":"Barra Velha","localidad":"Barra Velha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.637435460573776,"longitud":-48.69092791288656,"ranking":0},
{"id":7697,"nombre":"Benedito Novo","localidad":"Benedito Novo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.76949737313038,"longitud":-49.36856220364314,"ranking":0},
{"id":5078,"nombre":"Blumenau","localidad":"Blumenau","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.89527,"longitud":-49.0718,"ranking":4},
{"id":6042,"nombre":"Salto Weissbach","localidad":"Blumenau","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.906066244256085,"longitud":-49.132267746685514,"ranking":0},
{"id":6059,"nombre":"Vila Itoupava","localidad":"Blumenau","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.911794882206127,"longitud":-49.0807661040485,"ranking":0},
{"id":14946,"nombre":"Bocaina do Sul ","localidad":"Bocaina do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.73901348437504,"longitud":-49.947794487577156,"ranking":0},
{"id":5995,"nombre":"Bom Jardim Da Serra","localidad":"Bom Jardim da Serra","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.34359255639071,"longitud":-49.70013477678173,"ranking":0},
{"id":5557,"nombre":"Bom Jesus","localidad":"Bom Jesus","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.734473172900085,"longitud":-52.390290757232144,"ranking":0},
{"id":8186,"nombre":"Bom Jesus do Oeste","localidad":"Bom Jesus do Oeste","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5996,"nombre":"Bom Retiro","localidad":"Bom Retiro","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.797340813832513,"longitud":-49.48669219973499,"ranking":0},
{"id":5997,"nombre":"Braco do Norte","localidad":"Braço do Norte","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.276860867042842,"longitud":-49.167620013698205,"ranking":0},
{"id":5998,"nombre":"Braco do Trombudo","localidad":"Braço do Trombudo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.35673314731767,"longitud":-49.8834115204614,"ranking":0},
{"id":8189,"nombre":"Brunopolis","localidad":"Brunópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.31186645172758,"longitud":-50.83797240258676,"ranking":0},
{"id":5999,"nombre":"Brusque","localidad":"Brusque","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.098713866019768,"longitud":-48.91298915280543,"ranking":0},
{"id":6000,"nombre":"Cacador","localidad":"Caçador","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.77211115008231,"longitud":-51.01534423253017,"ranking":0},
{"id":5618,"nombre":"Caibi","localidad":"Caibi","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0770254821449,"longitud":-53.2497105449288,"ranking":0},
{"id":14947,"nombre":"Calmon","localidad":"Calmon","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.6009421,"longitud":-51.1025521,"ranking":0},
{"id":7709,"nombre":"Capao Alto","localidad":"Capão Alto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.937788390081725,"longitud":-50.51071261042096,"ranking":0},
{"id":7702,"nombre":"Campo Alegre","localidad":"Campo Alegre","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.191174088574225,"longitud":-49.26005152462428,"ranking":0},
{"id":7704,"nombre":"Campo Ere","localidad":"Campo Erê","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.395886225550033,"longitud":-53.08919583255018,"ranking":0},
{"id":5058,"nombre":"Campos Novos","localidad":"Campos Novos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.393398803893238,"longitud":-51.21410275960311,"ranking":0},
{"id":7645,"nombre":"Canelinha","localidad":"Canelinha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.266236813835167,"longitud":-48.76416566428937,"ranking":0},
{"id":7706,"nombre":"Canoinhas ","localidad":"Canoinhas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.18395934053399,"longitud":-50.38622774117401,"ranking":0},
{"id":7710,"nombre":"Capinzal ","localidad":"Capinzal","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.34300192748528,"longitud":-51.613462460257814,"ranking":0},
{"id":10159,"nombre":"Catanduvas","localidad":"Catanduvas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.06755383070739,"longitud":-51.66223857281655,"ranking":0},
{"id":7920,"nombre":"Caxambu do Sul","localidad":"Caxambu do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.16157042969177,"longitud":-52.87956482703686,"ranking":0},
{"id":6064,"nombre":"Chapeco","localidad":"Chapecó","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0881806,"longitud":-52.6140944,"ranking":0},
{"id":5547,"nombre":"Concordia","localidad":"Concórdia","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.227571,"longitud":-52.01864819999999,"ranking":0},
{"id":7716,"nombre":"Coronel Freitas","localidad":"Coronel Freitas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9037307596254,"longitud":-52.70237905216016,"ranking":0},
{"id":7922,"nombre":"Coronel Martins","localidad":"Coronel Martins","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6003,"nombre":"Corupa","localidad":"Corupá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.426198021267506,"longitud":-49.24284193144266,"ranking":0},
{"id":6002,"nombre":"Correia Pinto","localidad":"Correia Pinto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.590586867136953,"longitud":-50.362333379041885,"ranking":0},
{"id":6065,"nombre":"Cunha Pora","localidad":"Cunha Porã","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.896301002889114,"longitud":-53.17099094382763,"ranking":0},
{"id":5057,"nombre":"Curitibanos","localidad":"Curitibanos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.29640075513884,"longitud":-50.586903738977846,"ranking":0},
{"id":7717,"nombre":"Descanso ","localidad":"Descanso","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.82424412454405,"longitud":-53.501349213443014,"ranking":0},
{"id":7728,"nombre":"Doutor Pedrinho","localidad":"Doutor Pedrinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7773,"nombre":"Erval Velho","localidad":"Erval Velho","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.27646051691928,"longitud":-51.44203208125902,"ranking":0},
{"id":7779,"nombre":"Faxinal dos Guedes","localidad":"Faxinal dos Guedes","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.856270846787282,"longitud":-52.26164610208528,"ranking":0},
{"id":10792,"nombre":"Formosa do Sul","localidad":"Formosa do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.648115848078287,"longitud":-52.793015002601834,"ranking":0},
{"id":6004,"nombre":"Fraiburgo","localidad":"Fraiburgo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.030455737654766,"longitud":-50.91446539150986,"ranking":0},
{"id":7782,"nombre":"Galvao","localidad":"Galvão","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4542915094549,"longitud":-52.69226474501362,"ranking":0},
{"id":6005,"nombre":"Garuva","localidad":"Garuva","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.029460355890787,"longitud":-48.858519265464224,"ranking":0},
{"id":6006,"nombre":"Gaspar","localidad":"Gaspar","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.92660382306083,"longitud":-48.96660577074832,"ranking":0},
{"id":6066,"nombre":"Guaraciaba","localidad":"Guaraciaba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.60134633593874,"longitud":-53.52247737977873,"ranking":0},
{"id":5053,"nombre":"Guaramirim","localidad":"Guaramirim","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.472557949974295,"longitud":-48.991823361969196,"ranking":0},
{"id":7788,"nombre":"Guaruja do Sul","localidad":"Guarujá do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.387370999999998,"longitud":-53.52637708472675,"ranking":0},
{"id":8181,"nombre":"Guatambu","localidad":"Guatambú","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.13302364025101,"longitud":-52.78331613517535,"ranking":0},
{"id":6007,"nombre":"Ibiam","localidad":"Ibiam","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.17894614985979,"longitud":-51.238206224731755,"ranking":0},
{"id":7789,"nombre":"Ibicare","localidad":"Ibicaré","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.090058282765057,"longitud":-51.365087121138856,"ranking":0},
{"id":6008,"nombre":"Ibirama","localidad":"Ibirama","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.058348513751742,"longitud":-49.518089823675005,"ranking":0},
{"id":8187,"nombre":"Icara ","localidad":"Içara","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.712482009049392,"longitud":-49.30316316095016,"ranking":0},
{"id":6009,"nombre":"Ilhota","localidad":"Ilhota","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.89973173404421,"longitud":-48.827295898440184,"ranking":0},
{"id":7802,"nombre":"Imbuia ","localidad":"Imbuia","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.4907226813657,"longitud":-49.41954920784591,"ranking":0},
{"id":5055,"nombre":"Indaial","localidad":"Indaial","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.8916552543234,"longitud":-49.240404719044854,"ranking":0},
{"id":7804,"nombre":"Iomere","localidad":"Iomerê","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.00142112335894,"longitud":-51.2372632106959,"ranking":0},
{"id":7806,"nombre":"Ipira","localidad":"Ipira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.419347636700167,"longitud":-51.77514226617578,"ranking":0},
{"id":7807,"nombre":"Ipora do Oeste","localidad":"Iporã do Oeste","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9872623225506,"longitud":-53.533412002594034,"ranking":0},
{"id":7808,"nombre":"Ipuacu","localidad":"Ipuaçu","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7810,"nombre":"Ipumirim","localidad":"Ipumirim","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.075704896515486,"longitud":-52.13793468451732,"ranking":0},
{"id":7815,"nombre":"Iraceminha ","localidad":"Iraceminha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.821128118309502,"longitud":-53.273837640768285,"ranking":0},
{"id":5551,"nombre":"Irani","localidad":"Irani","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.02333111534175,"longitud":-51.894982314616236,"ranking":0},
{"id":7818,"nombre":"Irineopolis","localidad":"Irineópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7820,"nombre":"Ita","localidad":"Itá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.273906900064542,"longitud":-52.34198708102295,"ranking":0},
{"id":7822,"nombre":"Itaiopolis","localidad":"Itaiópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.337795344610644,"longitud":-49.90732274362089,"ranking":0},
{"id":7824,"nombre":"Itapiranga","localidad":"Itapiranga","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.174261849161596,"longitud":-53.71248519277882,"ranking":0},
{"id":8633,"nombre":"Itapoa","localidad":"Itapoá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.06831815258156,"longitud":-48.61336645420367,"ranking":0},
{"id":6011,"nombre":"Ituporanga","localidad":"Ituporanga","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.41690099496033,"longitud":-49.59716303701632,"ranking":0},
{"id":6012,"nombre":"Jabora","localidad":"Jaborá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.170746668696875,"longitud":-51.7361058882764,"ranking":0},
{"id":5054,"nombre":"Jaragua do Sul","localidad":"Jaraguá do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4853974,"longitud":-49.07007249999999,"ranking":0},
{"id":6013,"nombre":"Joacaba","localidad":"Joaçaba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.178530706655057,"longitud":-51.52317523956299,"ranking":0},
{"id":8182,"nombre":"Jupia","localidad":"Jupiá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.397928782892937,"longitud":-52.727722951239464,"ranking":0},
{"id":7827,"nombre":"Lacerdopolis ","localidad":"Lacerdópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.261812271059796,"longitud":-51.554765951779956,"ranking":0},
{"id":6014,"nombre":"Lages","localidad":"Lages","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.820524708290936,"longitud":-50.315019571887774,"ranking":0},
{"id":6015,"nombre":"Laurentino","localidad":"Laurentino","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.222498276067814,"longitud":-49.733314828617914,"ranking":0},
{"id":6016,"nombre":"Lauro Muller","localidad":"Lauro Muller","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.392065918923848,"longitud":-49.38895417663573,"ranking":0},
{"id":7828,"nombre":"Lebon Regis","localidad":"Lebon Régis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.934529948140806,"longitud":-50.68983549399796,"ranking":0},
{"id":6017,"nombre":"Lontras","localidad":"Lontras","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.16916441365792,"longitud":-49.543012957509454,"ranking":0},
{"id":7830,"nombre":"Luzerna ","localidad":"Luzerna","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.133324104594085,"longitud":-51.471823242041836,"ranking":0},
{"id":6018,"nombre":"Mafra","localidad":"Mafra","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.11325686845924,"longitud":-49.80050432209598,"ranking":0},
{"id":7831,"nombre":"Major Vieira","localidad":"Major Vieira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.364879363557797,"longitud":-50.32783123165863,"ranking":0},
{"id":6068,"nombre":"Maravilha","localidad":"Maravilha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.765929271990522,"longitud":-53.17548329682442,"ranking":0},
{"id":6019,"nombre":"Massaranduba","localidad":"Massaranduba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.61023985600253,"longitud":-49.009357892664845,"ranking":0},
{"id":15013,"nombre":"Matos Costa","localidad":"Matos Costa","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4717131,"longitud":-51.1526566,"ranking":0},
{"id":7832,"nombre":"Modelo ","localidad":"Modelo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.777891555590735,"longitud":-53.050303717299805,"ranking":0},
{"id":7833,"nombre":"Mondai","localidad":"Mondaí","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.101828221123025,"longitud":-53.39919526450439,"ranking":0},
{"id":6020,"nombre":"Monte Carlo","localidad":"Monte Carlo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.218887592014628,"longitud":-50.978382627385066,"ranking":0},
{"id":6021,"nombre":"Monte Castelo","localidad":"Monte Castelo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.462099810720158,"longitud":-50.22622405450112,"ranking":0},
{"id":9234,"nombre":"Nova Erechim","localidad":"Nova Erechim","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.89934028055881,"longitud":-52.91202959984497,"ranking":0},
{"id":15014,"nombre":"Nova Trento","localidad":"Nova Trento","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2862966,"longitud":-48.9215816,"ranking":0},
{"id":15015,"nombre":"Novo Horizonte","localidad":"Novo Horizonte","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4457009,"longitud":-52.8341681,"ranking":0},
{"id":6023,"nombre":"Orleans","localidad":"Orleans","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.361387610305293,"longitud":-49.29368025523918,"ranking":0},
{"id":6024,"nombre":"Otacilio Costa","localidad":"Otacílio Costa","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.4937,"longitud":-50.1215,"ranking":0},
{"id":15050,"nombre":"Ouro Verde","localidad":"Ouro Verde","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.6949361,"longitud":-52.3112475,"ranking":0},
{"id":6025,"nombre":"Palhoca","localidad":"Palhoça","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.629395403135625,"longitud":-48.65496137544,"ranking":0},
{"id":15276,"nombre":"Praia da Pinheira","localidad":"Palhoça","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.88832288618322,"longitud":-48.58934458260358,"ranking":0},
{"id":7834,"nombre":"Palma Sola","localidad":"Palma Sola","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.35297123880628,"longitud":-53.274830018694104,"ranking":0},
{"id":6026,"nombre":"Palmeira","localidad":"Palmeira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.57859236617095,"longitud":-50.159442843845824,"ranking":0},
{"id":5570,"nombre":"Palmitos ","localidad":"Palmitos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.066948675230627,"longitud":-53.16582530609563,"ranking":0},
{"id":6027,"nombre":"Papanduva","localidad":"Papanduva","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.410033239335583,"longitud":-50.14645946253041,"ranking":0},
{"id":8183,"nombre":"Paraiso","localidad":"Paraíso","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.61915107740027,"longitud":-53.677472576553356,"ranking":0},
{"id":6028,"nombre":"Penha","localidad":"Penha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.774438913235073,"longitud":-48.64366744432019,"ranking":0},
{"id":7835,"nombre":"Peritiba","localidad":"Peritiba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.370409155238807,"longitud":-51.90250103591908,"ranking":0},
{"id":7836,"nombre":"Pinhalzinho ","localidad":"Pinhalzinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.838249589648814,"longitud":-52.98674488434758,"ranking":0},
{"id":6030,"nombre":"Pinheiro Preto","localidad":"Pinheiro Preto","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7840,"nombre":"Piratuba","localidad":"Piratuba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.419294690704344,"longitud":-51.77514729289886,"ranking":0},
{"id":7845,"nombre":"Planalto Alegre","localidad":"Planalto Alegre","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.07331183348863,"longitud":-52.86703443034934,"ranking":0},
{"id":5074,"nombre":"Pomerode","localidad":"Pomerode","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.73900184384787,"longitud":-49.17365184783264,"ranking":0},
{"id":6032,"nombre":"Ponte Alta","localidad":"Ponte Alta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.482944354813476,"longitud":-50.38064665113962,"ranking":0},
{"id":6033,"nombre":"Ponte Alta Do Norte","localidad":"Ponte Alta do Norte","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.15963115075736,"longitud":-50.466445884205314,"ranking":0},
{"id":5553,"nombre":"Ponte Serrada","localidad":"Ponte Serrada","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.873462771860172,"longitud":-52.01562503255796,"ranking":0},
{"id":6034,"nombre":"Porto Belo","localidad":"Porto Belo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.158168729700943,"longitud":-48.57370746581229,"ranking":0},
{"id":14447,"nombre":"Porto Uniao","localidad":"Porto União","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.2355775,"longitud":-51.0856811,"ranking":1},
{"id":6035,"nombre":"Pouso Redondo","localidad":"Pouso Redondo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.257209695644946,"longitud":-49.933575104439065,"ranking":0},
{"id":9874,"nombre":"Praia Grande","localidad":"Praia Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5611,"nombre":"Quilombo","localidad":"Quilombo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.72457848631404,"longitud":-52.71849798460334,"ranking":0},
{"id":6037,"nombre":"Rancho Queimado","localidad":"Rancho Queimado","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.67286111146811,"longitud":-49.01263543932972,"ranking":0},
{"id":7850,"nombre":"Rio das Antas","localidad":"Rio das Antas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.8980277280909,"longitud":-51.07481764492439,"ranking":0},
{"id":6039,"nombre":"Rio Do oeste","localidad":"Rio do Oeste","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.193302174053887,"longitud":-49.79738099456618,"ranking":0},
{"id":5056,"nombre":"Rio do Sul","localidad":"Rio do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2359555014295,"longitud":-49.646189069172294,"ranking":0},
{"id":6040,"nombre":"Rio Negrinho","localidad":"Rio Negrinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.24605073796592,"longitud":-49.521028544939,"ranking":0},
{"id":5607,"nombre":"Riqueza ","localidad":"Riqueza","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.071257538754384,"longitud":-53.32503267863341,"ranking":0},
{"id":6041,"nombre":"Rodeio","localidad":"Rodeio","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.931311408754933,"longitud":-49.369862089616696,"ranking":0},
{"id":7919,"nombre":"Romelandia","localidad":"Romelândia","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.67980713345707,"longitud":-53.32467884451441,"ranking":0},
{"id":7863,"nombre":"Saltinho ","localidad":"Saltinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.605455996829,"longitud":-53.058774997986546,"ranking":0},
{"id":7864,"nombre":"Salto Veloso","localidad":"Salto Veloso","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.903454205040468,"longitud":-51.404841103052824,"ranking":0},
{"id":6043,"nombre":"Santa Cecilia","localidad":"Santa Cecília","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.962886414046164,"longitud":-50.417097010603605,"ranking":0},
{"id":7865,"nombre":"Santa Helena","localidad":"Santa Helena","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.939287583235725,"longitud":-53.62029819984234,"ranking":0},
{"id":8782,"nombre":"Santa Rosa do Sul","localidad":"Santa Rosa do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-29.136936062767372,"longitud":-49.7148238418231,"ranking":0},
{"id":15016,"nombre":"Santa Terezinha","localidad":"Santa Terezinha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.77745964478753,"longitud":-50.00702017718453,"ranking":0},
{"id":6044,"nombre":"Santo Amaro da Imperatriz ","localidad":"Santo Amaro da Imperatriz","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.68368178612916,"longitud":-48.75930453745438,"ranking":0},
{"id":6045,"nombre":"São Bento do Sul","localidad":"São Bento do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.24669998341888,"longitud":-49.38659656213364,"ranking":0},
{"id":7643,"nombre":"Sao Carlos","localidad":"São Carlos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.084677292015467,"longitud":-53.00417130458841,"ranking":0},
{"id":7866,"nombre":"Sao Domingos","localidad":"São Domingos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.556220045860673,"longitud":-52.52828077877479,"ranking":0},
{"id":6047,"nombre":"Sao Francisco do Sul","localidad":"São Francisco do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.249436124468836,"longitud":-48.63560907001818,"ranking":0},
{"id":7860,"nombre":"Sao Joao Batista","localidad":"São João Batista","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.277890485057576,"longitud":-48.852364405268894,"ranking":0},
{"id":6048,"nombre":"Sao Joao do Itaperiu","localidad":"São João do Itaperiú","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.618920406472476,"longitud":-48.767522174521076,"ranking":0},
{"id":6049,"nombre":"Sao Joaquim","localidad":"São Joaquim","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.291266570845487,"longitud":-49.94520413456525,"ranking":0},
{"id":7361,"nombre":"Sao Jose","localidad":"São José","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.595067142108327,"longitud":-48.6059142356986,"ranking":0},
{"id":6069,"nombre":"Sao Jose do Cedro","localidad":"São José do Cedro","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.453407350188627,"longitud":-53.496438496358515,"ranking":0},
{"id":7861,"nombre":"Sao Jose do Cerrito","localidad":"São José do Cerrito","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.665530907951908,"longitud":-50.58185714726008,"ranking":0},
{"id":5594,"nombre":"Sao Lourenco do Oeste","localidad":"São Lourenço do Oeste","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.36329225776685,"longitud":-52.86517954736814,"ranking":0},
{"id":6050,"nombre":"Sao Ludgero","localidad":"São Ludgero","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6062,"nombre":"Sao Miguel do Oeste","localidad":"São Miguel do Oeste","provincia":"Santa Catarina","pais":"BRASIL","latitud":-22.234155,"longitud":-50.889506,"ranking":0},
{"id":7867,"nombre":"Saudades ","localidad":"Saudades","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.926314657396325,"longitud":-53.00672227375972,"ranking":0},
{"id":7868,"nombre":"Seara ","localidad":"Seara","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.15538472122023,"longitud":-52.3107834967226,"ranking":0},
{"id":7869,"nombre":"Serra Alta","localidad":"Serra Alta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.731746499663,"longitud":-53.04203601920244,"ranking":0},
{"id":8185,"nombre":"Sul Brasil","localidad":"Sul Brasil","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6054,"nombre":"Tangara","localidad":"Tangará","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.10099685739747,"longitud":-51.24963582037745,"ranking":0},
{"id":7921,"nombre":"Tigrinhos","localidad":"Tigrinhos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.686922322213725,"longitud":-53.157059236438414,"ranking":0},
{"id":6055,"nombre":"Tijucas","localidad":"Tijucas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.240223037668265,"longitud":-48.63326713142433,"ranking":1},
{"id":6056,"nombre":"Timbo","localidad":"Timbó","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.85340689716943,"longitud":-49.27076822322489,"ranking":0},
{"id":8184,"nombre":"Timbo Grande","localidad":"Timbó Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.617989040403092,"longitud":-50.6625343460823,"ranking":0},
{"id":7870,"nombre":"Tres Barras","localidad":"Três Barras","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.10817320158572,"longitud":-50.31796318410966,"ranking":0},
{"id":7874,"nombre":"Treze Tilias","localidad":"Treze Tílias","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.000425279216426,"longitud":-51.41182709581649,"ranking":0},
{"id":6057,"nombre":"Trombudo Central","localidad":"Trombudo Central","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.311783582737686,"longitud":-49.824150589852124,"ranking":0},
{"id":7876,"nombre":"Urubici ","localidad":"Urubici","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.013867792413176,"longitud":-49.59207304863681,"ranking":0},
{"id":6063,"nombre":"Urussanga","localidad":"Urussanga","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.524010759799864,"longitud":-49.31763121713561,"ranking":0},
{"id":7879,"nombre":"Vargeao ","localidad":"Vargeão","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.861265057381004,"longitud":-52.15790014574795,"ranking":0},
{"id":7881,"nombre":"Vargem Bonita","localidad":"Vargem Bonita","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.00760292597867,"longitud":-51.73689969417362,"ranking":0},
{"id":7918,"nombre":"Vidal Ramos","localidad":"Vidal Ramos","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5577,"nombre":"Videira","localidad":"Videira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.005803840935574,"longitud":-51.154690708243855,"ranking":0},
{"id":5554,"nombre":"Xanxere","localidad":"Xanxerê","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.872997397190314,"longitud":-52.40359467717624,"ranking":0},
{"id":5544,"nombre":"Xaxim","localidad":"Xaxim","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.965360944930165,"longitud":-52.538303213600905,"ranking":0},
{"id":14365,"nombre":"Alecrim","localidad":"Alecrim","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.659963437093115,"longitud":-54.75926947992148,"ranking":0},
{"id":14369,"nombre":"Alpestre","localidad":"Alpestre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.2504929,"longitud":-53.0357762,"ranking":0},
{"id":14348,"nombre":"Alto Alegre","localidad":"Alto Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.7762238,"longitud":-52.9922167,"ranking":0},
{"id":14510,"nombre":"Alto Feliz","localidad":"Alto Feliz","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3835828,"longitud":-51.3132664,"ranking":0},
{"id":14464,"nombre":"Amaral Ferrador","localidad":"Amaral Ferrador","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.8767788,"longitud":-52.2553457,"ranking":0},
{"id":14371,"nombre":"Ametista Do Sul","localidad":"Ametista do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.3609834,"longitud":-53.1850269,"ranking":0},
{"id":14373,"nombre":"Andre Da Rocha","localidad":"André da Rocha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.6315746,"longitud":-51.572619,"ranking":0},
{"id":14511,"nombre":"Anta Gorda","localidad":"Anta Gorda","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.9691972,"longitud":-52.0050169,"ranking":0},
{"id":14576,"nombre":"Antonio Prado","localidad":"Antônio Prado","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.8571349,"longitud":-51.2808789,"ranking":0},
{"id":14350,"nombre":"Ararica","localidad":"Araricá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6159355,"longitud":-50.9278915,"ranking":0},
{"id":14512,"nombre":"Arroio do Meio","localidad":"Arroio do Meio","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4023182,"longitud":-51.9432687,"ranking":0},
{"id":5980,"nombre":"Arroio dos Ratos","localidad":"Arroio dos Ratos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.0897217,"longitud":-51.7270498,"ranking":0},
{"id":14345,"nombre":"Arroio Grande","localidad":"Arroio Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-32.2372985,"longitud":-53.0868354,"ranking":0},
{"id":14513,"nombre":"Arvorezinha","localidad":"Arvorezinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.8719437,"longitud":-52.1808697,"ranking":0},
{"id":6831,"nombre":"Bage","localidad":"Bagé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.32063869612462,"longitud":-54.094380897794004,"ranking":0},
{"id":5634,"nombre":"Balneario Pinhal","localidad":"Balneário Pinhal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.2467,"longitud":-50.2319,"ranking":0},
{"id":14514,"nombre":"Barao","localidad":"Barão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3730009,"longitud":-51.4956183,"ranking":0},
{"id":10789,"nombre":"Barao de Cotegipe","localidad":"Barão de Cotegipe","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.6213112783557,"longitud":-52.37750156846633,"ranking":0},
{"id":5981,"nombre":"Barracao","localidad":"Barracão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.672388509010435,"longitud":-51.45741005092561,"ranking":0},
{"id":14364,"nombre":"Barra do Guarita","localidad":"Barra do Guarita","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.1903611,"longitud":-53.7124096,"ranking":0},
{"id":14420,"nombre":"Barra do Quarai","localidad":"Barra do Quaraí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.20912629999999,"longitud":-57.5514218,"ranking":0},
{"id":14375,"nombre":"Barra Funda","localidad":"Barra Funda","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.923499,"longitud":-53.0402551,"ranking":0},
{"id":14362,"nombre":"Barros Cassal","localidad":"Barros Cassal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0926884,"longitud":-52.5941448,"ranking":0},
{"id":6832,"nombre":"Bento Goncalves","localidad":"Bento Gonçalves","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.161495336445256,"longitud":-51.52171774433786,"ranking":0},
{"id":9882,"nombre":"Boa Vista das Missoes","localidad":"Boa Vista das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.66186348361816,"longitud":-53.314399664712944,"ranking":0},
{"id":5985,"nombre":"Boa Vista do Burica","localidad":"Boa Vista do Buricá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.6711604972638,"longitud":-54.10760876733926,"ranking":0},
{"id":14515,"nombre":"Boa Vista do Sul","localidad":"Boa Vista do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3297589,"longitud":-51.683591,"ranking":0},
{"id":5649,"nombre":"Bom Jesus","localidad":"Bom Jesus","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.668357969382832,"longitud":-50.43600006247073,"ranking":0},
{"id":14381,"nombre":"Bom Principio","localidad":"Bom Princípio","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.49135309999999,"longitud":-51.3580474,"ranking":0},
{"id":14361,"nombre":"Bom Progresso","localidad":"Bom Progresso","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.5527811,"longitud":-53.8639269,"ranking":0},
{"id":14516,"nombre":"Bom Retiro do Sul","localidad":"Bom Retiro do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.600918,"longitud":-51.9335251,"ranking":0},
{"id":14342,"nombre":"Bossoroca","localidad":"Bossoroca","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.7313374,"longitud":-54.9094369,"ranking":0},
{"id":15052,"nombre":"Bozano","localidad":"Bozano","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.3684339,"longitud":-53.7715934,"ranking":0},
{"id":8678,"nombre":"Butia","localidad":"Butiá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.120706984374348,"longitud":-51.9617705116069,"ranking":0},
{"id":15316,"nombre":"Minas do Butia","localidad":"Butiá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.120736383890907,"longitud":-51.96181293586511,"ranking":0},
{"id":7364,"nombre":"Cacapava do Sul","localidad":"Caçapava do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.514494094345043,"longitud":-53.49282468755624,"ranking":0},
{"id":14340,"nombre":"Cacequi","localidad":"Cacequi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.8777367,"longitud":-54.8224962,"ranking":0},
{"id":6835,"nombre":"Cachoeira do Sul","localidad":"Cachoeira do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.03600988825368,"longitud":-52.913266120484636,"ranking":0},
{"id":14328,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.94576159999999,"longitud":-51.0990195,"ranking":0},
{"id":5077,"nombre":"Cacique Doble","localidad":"Cacique Doble","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.76992869308901,"longitud":-51.6619292282664,"ranking":0},
{"id":14360,"nombre":"Caibate","localidad":"Caibaté","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.2886131,"longitud":-54.6400915,"ranking":0},
{"id":15243,"nombre":"Ouro Verde","localidad":"Cambará do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.95911270928535,"longitud":-50.0525855502165,"ranking":0},
{"id":5627,"nombre":"Cambara do Sul","localidad":"Cambará do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.049642696473597,"longitud":-50.14649964232796,"ranking":0},
{"id":14331,"nombre":"Campestre da Serra","localidad":"Campestre da Serra","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.7946335,"longitud":-51.0961317,"ranking":0},
{"id":14359,"nombre":"Campina das Missoes","localidad":"Campina das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.9485021,"longitud":-54.7529506,"ranking":0},
{"id":5653,"nombre":"Campo Bom","localidad":"Campo Bom","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.682119840931488,"longitud":-51.045896413849974,"ranking":0},
{"id":5633,"nombre":"Campo Novo","localidad":"Campo Novo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.66650154494898,"longitud":-53.805327420318534,"ranking":0},
{"id":14358,"nombre":"Campos Borges","localidad":"Campos Borges","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.9484532,"longitud":-54.7531331,"ranking":0},
{"id":10400,"nombre":"Candelaria","localidad":"Candelária","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.670458865474135,"longitud":-52.7890408451228,"ranking":0},
{"id":14357,"nombre":"Candido Godoi","localidad":"Cândido Godói","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.9485021,"longitud":-54.7529506,"ranking":0},
{"id":14334,"nombre":"Candiota","localidad":"Candiota","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.5606199,"longitud":-53.6757915,"ranking":0},
{"id":5625,"nombre":"Canela","localidad":"Canela","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.365316272120378,"longitud":-50.8139785522624,"ranking":0},
{"id":5620,"nombre":"Cangucu","localidad":"Canguçu","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.39923411330064,"longitud":-52.67770414708227,"ranking":0},
{"id":8694,"nombre":"Rafting Pacote Adventure","localidad":"Canoas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8696,"nombre":"Rafting Pacote Passeio","localidad":"Canoas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8768,"nombre":"A.Camping","localidad":"Canoas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8769,"nombre":"A.Pousada","localidad":"Canoas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10160,"nombre":"Canoas","localidad":"Canoas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.904114033259205,"longitud":-51.179215484338044,"ranking":0},
{"id":14332,"nombre":"Capao Bonito Do Sul","localidad":"Capão Bonito do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1301195,"longitud":-51.3918452,"ranking":0},
{"id":14383,"nombre":"Capivari Do Sul","localidad":"Capivari do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.14541639999999,"longitud":-50.5144807,"ranking":0},
{"id":14517,"nombre":"Capela de Santana","localidad":"Capela de Santana","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6803412,"longitud":-51.31853359999999,"ranking":0},
{"id":14354,"nombre":"Carlos Barbosa","localidad":"Carlos Barbosa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2932412,"longitud":-51.4992059,"ranking":0},
{"id":8472,"nombre":"Casca ","localidad":"Casca","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.568804822395705,"longitud":-51.976990654705915,"ranking":0},
{"id":8473,"nombre":"Caseiros","localidad":"Caseiros","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.270365046541496,"longitud":-51.688150964062764,"ranking":0},
{"id":5648,"nombre":"Caxias Do Sul","localidad":"Caxias do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.1632971,"longitud":-51.1726916,"ranking":0},
{"id":14484,"nombre":"Apanhador","localidad":"Caxias do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14418,"nombre":"Vila Seca","localidad":"Caxias do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0688724,"longitud":-50.972261,"ranking":0},
{"id":14421,"nombre":"Ana Rech","localidad":"Caxias do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.1072508,"longitud":-51.0905575,"ranking":0},
{"id":14329,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.8540479,"longitud":-52.8164246,"ranking":0},
{"id":8474,"nombre":"Cerro Largo","localidad":"Cerro Largo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.14571500978715,"longitud":-54.73836244318983,"ranking":0},
{"id":14386,"nombre":"Charrua","localidad":"Charrua","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.9533663,"longitud":-52.031783,"ranking":0},
{"id":5632,"nombre":"Cidreira","localidad":"Cidreira","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.174549978003483,"longitud":-50.20433305397238,"ranking":0},
{"id":8475,"nombre":"Ciriaco","localidad":"Ciríaco","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.343216620978648,"longitud":-51.8719489735028,"ranking":0},
{"id":14518,"nombre":"Colinas","localidad":"Colinas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3859346,"longitud":-51.86980980000001,"ranking":0},
{"id":10790,"nombre":"Condor","localidad":"Condor","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.20477054783489,"longitud":-53.48458283132478,"ranking":0},
{"id":8476,"nombre":"Coronel Barros","localidad":"Coronel Barros","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.381854782959795,"longitud":-54.06566834188614,"ranking":0},
{"id":5571,"nombre":"Coronel Bicaco ","localidad":"Coronel Bicaco","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.718240879555474,"longitud":-53.70726655715928,"ranking":0},
{"id":5542,"nombre":"Coxilha","localidad":"Coxilha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.12669684130565,"longitud":-52.295590080339856,"ranking":0},
{"id":14313,"nombre":"Crissiumal","localidad":"Crissiumal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.4984494,"longitud":-54.1018379,"ranking":0},
{"id":14508,"nombre":"David Canabarro","localidad":"David Canabarro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.388145053800887,"longitud":-51.84627952026936,"ranking":0},
{"id":14352,"nombre":"Dezesseis de Novembro","localidad":"Dezesseis de Novembro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.2262108,"longitud":-55.0486,"ranking":0},
{"id":5654,"nombre":"Dois Irmaos","localidad":"Dois Irmãos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.578807523061244,"longitud":-51.09496163395801,"ranking":0},
{"id":14519,"nombre":"Dois Lajeados","localidad":"Dois Lajeados","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.9833515,"longitud":-51.83688919999999,"ranking":0},
{"id":14335,"nombre":"Dom Pedrito","localidad":"Dom Pedrito","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.97662,"longitud":-54.67316,"ranking":0},
{"id":14327,"nombre":"Dona Francisca","localidad":"Dona Francisca","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6223928,"longitud":-53.3528482,"ranking":0},
{"id":14351,"nombre":"Doutor Mauricio Cardoso","localidad":"Doutor Maurício Cardoso","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.509104,"longitud":-54.3631245,"ranking":0},
{"id":14520,"nombre":"Doutor Ricardo","localidad":"Doutor Ricardo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0901946,"longitud":-51.9895229,"ranking":0},
{"id":14521,"nombre":"Encantado","localidad":"Encantado","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2398612,"longitud":-51.8699715,"ranking":0},
{"id":14347,"nombre":"Encruzilhada do Sul","localidad":"Encruzilhada do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.5434684,"longitud":-52.5209663,"ranking":0},
{"id":15239,"nombre":"Figueiras","localidad":"Encruzilhada do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.542588060502485,"longitud":-52.52026683050686,"ranking":0},
{"id":15352,"nombre":"Cordilheira","localidad":"Encruzilhada do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.542635416658282,"longitud":-52.52033925014655,"ranking":0},
{"id":14326,"nombre":"Entre Ijuis","localidad":"Entre-Ijuís","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.359576987070252,"longitud":-54.26848463558513,"ranking":0},
{"id":5043,"nombre":"Erechim","localidad":"Erechim","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.6472932,"longitud":-52.26610429999999,"ranking":0},
{"id":14389,"nombre":"Ernestina","localidad":"Ernestina","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5000792,"longitud":-52.5685516,"ranking":0},
{"id":10791,"nombre":"Erval Grande","localidad":"Erval Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.39175262266894,"longitud":-52.56974725518584,"ranking":0},
{"id":8488,"nombre":"Espumoso ","localidad":"Espumoso","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.73485173513751,"longitud":-52.838320289678755,"ranking":0},
{"id":14344,"nombre":"Estancia Velha","localidad":"Estância Velha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.654136,"longitud":-51.173817,"ranking":0},
{"id":14325,"nombre":"Esteio","localidad":"Esteio","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.8446,"longitud":-51.174,"ranking":0},
{"id":6836,"nombre":"Farroupilha","localidad":"Farroupilha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.234431942636387,"longitud":-51.3335018021062,"ranking":0},
{"id":14338,"nombre":"Faxinal do Soturno","localidad":"Faxinal do Soturno","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5803103,"longitud":-53.4432479,"ranking":0},
{"id":14522,"nombre":"Fazenda Vilanova","localidad":"Fazenda Vilanova","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5908841,"longitud":-51.824051,"ranking":0},
{"id":8484,"nombre":"Feliz ","localidad":"Feliz","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.451186445733473,"longitud":-51.30652040440197,"ranking":0},
{"id":14573,"nombre":"Flores da Cunha","localidad":"Flores da Cunha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0294976,"longitud":-51.1813404,"ranking":0},
{"id":14523,"nombre":"Fontoura Xavier","localidad":"Fontoura Xavier","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.9820815,"longitud":-52.3462605,"ranking":0},
{"id":14339,"nombre":"Formigueiro","localidad":"Formigueiro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.999356,"longitud":-53.49951170000001,"ranking":0},
{"id":7330,"nombre":"Frederico Westphalen","localidad":"Frederico Westphalen","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.359898506627232,"longitud":-53.39384434794237,"ranking":0},
{"id":6837,"nombre":"Garibaldi","localidad":"Garibaldi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.25745968843544,"longitud":-29.25745968843544,"ranking":0},
{"id":8490,"nombre":"Gentil","localidad":"Gentil","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.43166214534684,"longitud":-52.03734640781907,"ranking":0},
{"id":8469,"nombre":"Getulio Vargas","localidad":"Getúlio Vargas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.89442411806785,"longitud":-52.21946844237643,"ranking":0},
{"id":5986,"nombre":"Girua","localidad":"Giruá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.030455184297207,"longitud":-54.3609872,"ranking":0},
{"id":14391,"nombre":"Glorinha","localidad":"Glorinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.87923788577505,"longitud":-50.78654186038837,"ranking":0},
{"id":5652,"nombre":"Gramado","localidad":"Gramado","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.383621783649332,"longitud":-50.872305035591125,"ranking":1},
{"id":14524,"nombre":"Gramado Xavier","localidad":"Gramado Xavier","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2667894,"longitud":-52.5793579,"ranking":0},
{"id":5626,"nombre":"Gravatai","localidad":"Gravataí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.943514957598907,"longitud":-50.99327263723809,"ranking":0},
{"id":14525,"nombre":"Guabiju","localidad":"Guabiju","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5422626,"longitud":-51.69118169999999,"ranking":0},
{"id":14392,"nombre":"Guapore","localidad":"Guaporé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.849874408635422,"longitud":-51.897062422724694,"ranking":0},
{"id":14343,"nombre":"Guarani das Missoes","localidad":"Guarani das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1429819,"longitud":-54.5604624,"ranking":0},
{"id":5601,"nombre":"Horizontina","localidad":"Horizontina","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.625425124166934,"longitud":-54.306842873493274,"ranking":0},
{"id":14324,"nombre":"Hulha Negra","localidad":"Hulha Negra","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.4055438,"longitud":-53.871068,"ranking":0},
{"id":8486,"nombre":"Humaita","localidad":"Humaitá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.562422670378208,"longitud":-53.96929736025271,"ranking":0},
{"id":5982,"nombre":"Ibiaca","localidad":"Ibiaçá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.059435579342008,"longitud":-51.85867560256929,"ranking":0},
{"id":14317,"nombre":"Ibiraiaras","localidad":"Ibiraiaras","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.3709491,"longitud":-51.63637960000001,"ranking":0},
{"id":14393,"nombre":"Ibirapuita","localidad":"Ibirapuitã","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.6242478,"longitud":-52.512902,"ranking":0},
{"id":5566,"nombre":"Ibiruba","localidad":"Ibirubá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.630783520295836,"longitud":-53.08654559763524,"ranking":0},
{"id":5647,"nombre":"Igrejinha","localidad":"Igrejinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.575073913273442,"longitud":-50.793482120259014,"ranking":0},
{"id":14526,"nombre":"Ilopolis","localidad":"Ilópolis","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.9256619,"longitud":-52.1289949,"ranking":0},
{"id":5646,"nombre":"Imbe","localidad":"Imbé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.967102452506214,"longitud":-50.134858743629465,"ranking":0},
{"id":15294,"nombre":"Mariluz","localidad":"Imbé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.934433785670002,"longitud":-50.110834555867875,"ranking":0},
{"id":14355,"nombre":"Imigrante","localidad":"Imigrante","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3544835,"longitud":-51.7774893,"ranking":0},
{"id":15231,"nombre":"Ipe","localidad":"Ipê","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5617,"nombre":"Irai","localidad":"Iraí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.198005701761897,"longitud":-53.24970669823968,"ranking":0},
{"id":8477,"nombre":"Itacurubi ","localidad":"Itacurubi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.796920947688072,"longitud":-55.234133266328016,"ranking":0},
{"id":5044,"nombre":"Itaqui","localidad":"Itaqui","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.13977940066801,"longitud":-56.5409019722193,"ranking":0},
{"id":14395,"nombre":"Itati","localidad":"Itati","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.3799302,"longitud":-52.4565716,"ranking":0},
{"id":15268,"nombre":"Arroio Carvalho","localidad":"Itati","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5645,"nombre":"Ivoti","localidad":"Ivoti","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.589578404933835,"longitud":-51.16199817806505,"ranking":0},
{"id":14346,"nombre":"Jaguari","localidad":"Jaguari","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4962685,"longitud":-54.6917199,"ranking":0},
{"id":14323,"nombre":"Jaquirana","localidad":"Jaquirana","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.8841663,"longitud":-50.3582242,"ranking":0},
{"id":5045,"nombre":"Julho Castilho","localidad":"Julho Castilho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14465,"nombre":"Lagoao","localidad":"Lagoão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2207843,"longitud":-52.77165429999999,"ranking":0},
{"id":14527,"nombre":"Lagoa dos Tres Cantos","localidad":"Lagoa dos Três Cantos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.568546,"longitud":-52.8590019,"ranking":0},
{"id":8478,"nombre":"Lagoa Vermelha","localidad":"Lagoa Vermelha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.211577876247976,"longitud":-51.52346780833681,"ranking":0},
{"id":14341,"nombre":"Lavras do Sul ","localidad":"Lavras do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.8156122,"longitud":-53.8996388,"ranking":0},
{"id":14337,"nombre":"Lindolfo Collor","localidad":"Lindolfo Collor","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5959767,"longitud":-51.2103803,"ranking":0},
{"id":14397,"nombre":"Mampituba","localidad":"Mampituba","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2115282,"longitud":-49.936446,"ranking":0},
{"id":14353,"nombre":"Manoel Viana","localidad":"Manoel Viana","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5936831,"longitud":-55.4809779,"ranking":0},
{"id":15244,"nombre":"Morro Alto","localidad":"Maquiné","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.777169605405067,"longitud":-50.1741229327118,"ranking":0},
{"id":14399,"nombre":"Maquine","localidad":"Maquiné","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6816802,"longitud":-50.2069985,"ranking":0},
{"id":9597,"nombre":"Marau","localidad":"Marau","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.442435767802174,"longitud":-52.19785211521058,"ranking":0},
{"id":8470,"nombre":"Marcelino Ramos","localidad":"Marcelino Ramos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.462770861229252,"longitud":-51.90790633006941,"ranking":0},
{"id":15238,"nombre":"Douradilho","localidad":"Mariana Pimentel","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.373171812601253,"longitud":-51.43176853193928,"ranking":0},
{"id":14528,"nombre":"Marques de Souza","localidad":"Marques de Souza","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3276819,"longitud":-52.0938169,"ranking":0},
{"id":14401,"nombre":"Mato Castelhano","localidad":"Mato Castelhano","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.2779296,"longitud":-52.1943271,"ranking":0},
{"id":10795,"nombre":"Maximiliano de Almeida","localidad":"Maximiliano de Almeida","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.632193628415386,"longitud":-51.805859402524696,"ranking":0},
{"id":14356,"nombre":"Minas do Leao","localidad":"Minas do Leão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.1347986,"longitud":-52.0432486,"ranking":0},
{"id":14402,"nombre":"Miraguai","localidad":"Miraguaí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.4977015,"longitud":-53.6833993,"ranking":0},
{"id":5644,"nombre":"Montenegro","localidad":"Montenegro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.678261547072655,"longitud":-51.46609645956526,"ranking":0},
{"id":5621,"nombre":"Morro Redondo","localidad":"Morro Redondo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14414,"nombre":"Morro Reuter","localidad":"Morro Reuter","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.540076,"longitud":-51.0820257,"ranking":0},
{"id":14529,"nombre":"Mucum","localidad":"Muçum","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.1651967,"longitud":-51.8672883,"ranking":0},
{"id":8479,"nombre":"Muitos Capoes ","localidad":"Muitos Capões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.316731041109758,"longitud":-51.18468481805802,"ranking":0},
{"id":5563,"nombre":"Nao Me Toque","localidad":"Não-Me-Toque","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.458102,"longitud":-52.8202554,"ranking":0},
{"id":5615,"nombre":"Nonoai ","localidad":"Nonoai","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.36449954342295,"longitud":-52.77557842427788,"ranking":0},
{"id":9598,"nombre":"Nova Araca","localidad":"Nova Araçá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.662277768588005,"longitud":-51.747914486830474,"ranking":0},
{"id":9599,"nombre":"Nova Bassano","localidad":"Nova Bassano","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.732319513420062,"longitud":-51.70377269429071,"ranking":0},
{"id":7331,"nombre":"Nova Hartz","localidad":"Nova Hartz","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.583900362621677,"longitud":-50.90273583763649,"ranking":0},
{"id":14571,"nombre":"Nova Padua","localidad":"Nova Pádua","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0287818,"longitud":-51.3081748,"ranking":0},
{"id":5643,"nombre":"Nova Petropolis","localidad":"Nova Petrópolis","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3651790790863,"longitud":-51.097969889045615,"ranking":0},
{"id":5616,"nombre":"Nova Prata ","localidad":"Nova Prata","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-25.636238,"longitud":-53.345096,"ranking":0},
{"id":14413,"nombre":"Novo Cabrais","localidad":"Novo Cabrais","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.7317835,"longitud":-52.9466684,"ranking":0},
{"id":5642,"nombre":"Novo Hamburgo","localidad":"Novo Hamburgo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.68452610355817,"longitud":-51.13568454364463,"ranking":0},
{"id":14400,"nombre":"Novo Barreiro","localidad":"Novo Barreiro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.911659,"longitud":-53.1088248,"ranking":0},
{"id":10796,"nombre":"Paim Filho","localidad":"Paim Filho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7366,"nombre":"Palmeira das Missões","localidad":"Palmeira das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.891664634566233,"longitud":-53.31026128760505,"ranking":0},
{"id":8480,"nombre":"Palmitinho ","localidad":"Palmitinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.355448322782628,"longitud":-53.55484086300618,"ranking":0},
{"id":5540,"nombre":"Panambi","localidad":"Panambi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.298708789713118,"longitud":-53.498966452814535,"ranking":0},
{"id":7935,"nombre":"Pantano Grande","localidad":"Pantano Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.189259,"longitud":-52.371648,"ranking":0},
{"id":15237,"nombre":"Monte Castelo","localidad":"Pantano Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.319831450456977,"longitud":-52.3624826226856,"ranking":0},
{"id":15315,"nombre":"Capivarita","localidad":"Pantano Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.33697169573611,"longitud":-52.378853401243624,"ranking":0},
{"id":14398,"nombre":"Parai","localidad":"Paraí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5963334,"longitud":-51.7869511,"ranking":0},
{"id":14363,"nombre":"Paraiso do Sul","localidad":"Paraíso do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.7326626,"longitud":-53.1794557,"ranking":0},
{"id":14570,"nombre":"Pareci Novo","localidad":"Pareci Novo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6352829,"longitud":-51.4037717,"ranking":0},
{"id":5650,"nombre":"Parobe","localidad":"Parobé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.637958691926492,"longitud":-50.836731512634124,"ranking":0},
{"id":5046,"nombre":"Passo Fundo","localidad":"Passo Fundo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.2551,"longitud":-52.3952,"ranking":3},
{"id":5622,"nombre":"Pedro Osorio","localidad":"Pedro Osório","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14412,"nombre":"Pejucara","localidad":"Pejuçara","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.4227158,"longitud":-53.65651769999999,"ranking":0},
{"id":14411,"nombre":"Picada Cafe","localidad":"Picada Café","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4505524,"longitud":-51.1327487,"ranking":0},
{"id":14410,"nombre":"Pinhal","localidad":"Pinhal","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.511702823975302,"longitud":-53.21616260872607,"ranking":0},
{"id":14367,"nombre":"Pinheiro Machado","localidad":"Pinheiro Machado","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-31.578806,"longitud":-53.3811693,"ranking":0},
{"id":14530,"nombre":"Pinto Bandeira","localidad":"Pinto Bandeira","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.0277264,"longitud":-51.2223537,"ranking":0},
{"id":14396,"nombre":"Planalto","localidad":"Planalto","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.3271504,"longitud":-53.05654089999999,"ranking":0},
{"id":14409,"nombre":"Poco das Antas","localidad":"Poço das Antas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.2465111,"longitud":-50.2320687,"ranking":0},
{"id":14394,"nombre":"Pontao","localidad":"Pontão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.0580401,"longitud":-52.6791317,"ranking":0},
{"id":5641,"nombre":"Portao","localidad":"Portão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.692944951408926,"longitud":-51.23008913573504,"ranking":0},
{"id":8481,"nombre":"Porto Lucena","localidad":"Porto Lucena","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.854844982641456,"longitud":-55.01852526024592,"ranking":0},
{"id":14336,"nombre":"Porto Maua","localidad":"Porto Mauá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.5750568,"longitud":-54.6682688,"ranking":0},
{"id":14333,"nombre":"Porto Vera Cruz","localidad":"Porto Vera Cruz","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.734309,"longitud":-54.89882919999999,"ranking":0},
{"id":8482,"nombre":"Porto Xavier","localidad":"Porto Xavier","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.905392608825142,"longitud":-55.13766610783632,"ranking":0},
{"id":14531,"nombre":"Pouso Novo","localidad":"Pouso Novo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.1702862,"longitud":-52.2090761,"ranking":0},
{"id":14408,"nombre":"Progresso","localidad":"Progresso","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2451389,"longitud":-52.3295981,"ranking":0},
{"id":14370,"nombre":"Quarai","localidad":"Quaraí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.3849128,"longitud":-56.4458172,"ranking":0},
{"id":8489,"nombre":"Restinga Seca","localidad":"Restinga Seca","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.81318909777803,"longitud":-53.37553800119617,"ranking":0},
{"id":5655,"nombre":"Rio Grande","localidad":"Rio Grande","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-32.05997049795226,"longitud":-52.14898354122243,"ranking":0},
{"id":14390,"nombre":"Rio Pardo","localidad":"Rio Pardo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.9860607,"longitud":-52.3706539,"ranking":0},
{"id":5630,"nombre":"Riozinho","localidad":"Riozinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.640707205139655,"longitud":-50.45873038980907,"ranking":0},
{"id":14532,"nombre":"Roca Sales","localidad":"Roca Sales","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.2852292,"longitud":-51.8704882,"ranking":0},
{"id":5569,"nombre":"Rodeio Bonito","localidad":"Rodeio Bonito","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.469072410382104,"longitud":-53.16852253548356,"ranking":0},
{"id":5629,"nombre":"Rolante","localidad":"Rolante","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6532718976607,"longitud":-50.5802055434537,"ranking":0},
{"id":5606,"nombre":"Ronda Alta","localidad":"Ronda Alta","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.7829104689159,"longitud":-52.800467041810194,"ranking":0},
{"id":5609,"nombre":"Rondinha","localidad":"Rondinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.827486273313276,"longitud":-52.91058818055046,"ranking":0},
{"id":14314,"nombre":"Roque Gonzales","localidad":"Roque Gonzales","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1319671,"longitud":-55.02697240000001,"ranking":0},
{"id":5047,"nombre":"Rosario do Sul","localidad":"Rosário do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.246643158373768,"longitud":-54.91686255975384,"ranking":0},
{"id":5595,"nombre":"Saldanha Marinho","localidad":"Saldanha Marinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.3971578,"longitud":-53.0931609,"ranking":0},
{"id":14322,"nombre":"Salto do Jacui","localidad":"Salto do Jacuí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0815238,"longitud":-53.21150429999999,"ranking":0},
{"id":14320,"nombre":"Salvador Das Missoes","localidad":"Salvador das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1236204,"longitud":-54.8366001,"ranking":0},
{"id":14533,"nombre":"Salvador do Sul","localidad":"Salvador do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4349075,"longitud":-51.5074616,"ranking":0},
{"id":5052,"nombre":"Sananduva","localidad":"Sananduva","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.949216429532395,"longitud":-51.81325061171544,"ranking":0},
{"id":5604,"nombre":"Santa Barbara do Sul","localidad":"Santa Bárbara do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.365801230926667,"longitud":-53.25156537909044,"ranking":0},
{"id":12362,"nombre":"Santa Cecília do Sul","localidad":"Santa Cecília do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1647048,"longitud":-51.9343419,"ranking":0},
{"id":5051,"nombre":"Santa Cruz do Sul","localidad":"Santa Cruz do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.71910781244075,"longitud":-52.445640032495575,"ranking":0},
{"id":5083,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.70087505234614,"longitud":-53.79703184599159,"ranking":1},
{"id":14376,"nombre":"Santana da Boa Vista","localidad":"Santana da Boa Vista","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.8698381,"longitud":-53.11300379999999,"ranking":0},
{"id":8487,"nombre":"Santiago","localidad":"Santiago","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.188801327976428,"longitud":-54.85999284922233,"ranking":0},
{"id":5628,"nombre":"Santo Antonio Da Patrulha","localidad":"Santo Antônio da Patrulha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.831711364011365,"longitud":-50.525807294135134,"ranking":0},
{"id":14315,"nombre":"Santo Antonio das Missoes","localidad":"Santo Antônio das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5082378,"longitud":-55.2243612,"ranking":0},
{"id":15140,"nombre":"Santo Antonio do Planalto","localidad":"Santo Antônio do Planalto","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.395327941812198,"longitud":-52.69094022723063,"ranking":0},
{"id":5610,"nombre":"Santo Augusto","localidad":"Santo Augusto","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.865514491834663,"longitud":-53.78191000756645,"ranking":0},
{"id":14318,"nombre":"Santo Cristo","localidad":"Santo Cristo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.8266513,"longitud":-54.6623548,"ranking":0},
{"id":5049,"nombre":"Sao Borja","localidad":"São Borja","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.673499848525722,"longitud":-56.0013521095547,"ranking":0},
{"id":14509,"nombre":"Sao Domingos do Sul","localidad":"São Domingos do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.529084,"longitud":-51.88951429999999,"ranking":0},
{"id":14378,"nombre":"Sao Francisco de Assis","localidad":"São Francisco de Assis","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5572088,"longitud":-55.1249421,"ranking":0},
{"id":5640,"nombre":"Sao Francisco De Paula","localidad":"São Francisco de Paula","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.445140985077714,"longitud":-50.58060872866923,"ranking":0},
{"id":14489,"nombre":"Tainhas","localidad":"São Francisco de Paula","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.27616459999999,"longitud":-50.3130539,"ranking":0},
{"id":14490,"nombre":"Varzea do Cedro","localidad":"São Francisco de Paula","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.445042325699667,"longitud":-50.580502776802454,"ranking":0},
{"id":14491,"nombre":"Lajeado Grande","localidad":"São Francisco de Paula","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.0841127,"longitud":-50.623084,"ranking":0},
{"id":15242,"nombre":"Cazuza Ferreira","localidad":"São Francisco de Paula","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.93226678169052,"longitud":-50.6544626039402,"ranking":0},
{"id":14415,"nombre":"Sao Jeronimo","localidad":"São Jerônimo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.9548717,"longitud":-51.7220159,"ranking":0},
{"id":14388,"nombre":"Sao Joao da Urtiga","localidad":"São João da Urtiga","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.8203045,"longitud":-51.8274122,"ranking":0},
{"id":8491,"nombre":"Sao Jorge","localidad":"São Jorge","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.50421336494919,"longitud":-51.70119318444015,"ranking":0},
{"id":14387,"nombre":"Sao Jose do Herval","localidad":"São José do Herval","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.05273825617312,"longitud":-52.29041856331368,"ranking":0},
{"id":5076,"nombre":"Sao Jose do Ouro","localidad":"São José do Ouro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.772532506799855,"longitud":-51.59651679683268,"ranking":0},
{"id":14534,"nombre":"Sao Jose do Sul","localidad":"São José do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5337693,"longitud":-51.4872472,"ranking":0},
{"id":14382,"nombre":"Sao Jose dos Ausentes","localidad":"São José dos Ausentes","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.7483329,"longitud":-50.06397639999999,"ranking":0},
{"id":5050,"nombre":"Sao Leopoldo","localidad":"São Leopoldo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.76083306321816,"longitud":-51.149022991153856,"ranking":0},
{"id":5048,"nombre":"Sao Luiz Gonzaga","localidad":"São Luiz Gonzaga","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.41505705106397,"longitud":-54.96055128537606,"ranking":0},
{"id":6839,"nombre":"Sao Marcos","localidad":"São Marcos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.973490596228118,"longitud":-51.06923218905512,"ranking":0},
{"id":15241,"nombre":"Pedras Brancas","localidad":"São Marcos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.020596900994303,"longitud":-51.077165011936025,"ranking":0},
{"id":5603,"nombre":"Sao Martinho","localidad":"São Martinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.70808237431073,"longitud":-53.96753033903227,"ranking":0},
{"id":14385,"nombre":"Sao Miguel das Missoes","localidad":"São Miguel das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5457367,"longitud":-54.55247989999999,"ranking":0},
{"id":14349,"nombre":"Sao Nicolau","localidad":"São Nicolau","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1813695,"longitud":-55.26443140000001,"ranking":0},
{"id":14312,"nombre":"Sao Paulo das Missoes","localidad":"São Paulo das Missões","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.022072,"longitud":-54.9368049,"ranking":0},
{"id":14535,"nombre":"Sao Pedro da Serra","localidad":"São Pedro da Serra","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4211809,"longitud":-51.5120753,"ranking":0},
{"id":14316,"nombre":"Sao Pedro do Butia","localidad":"São Pedro do Butiá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.1266207,"longitud":-54.8896801,"ranking":0},
{"id":14403,"nombre":"Sao Pedro do Sul","localidad":"São Pedro do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6206699,"longitud":-54.1778534,"ranking":0},
{"id":14384,"nombre":"Sao Sebastiao do Cai","localidad":"São Sebastião do Caí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5913553,"longitud":-51.3741173,"ranking":0},
{"id":8468,"nombre":"Sao Sepe","localidad":"São Sepé","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.168995376608123,"longitud":-53.570491447900714,"ranking":0},
{"id":10793,"nombre":"Sao Valentim","localidad":"São Valentim","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.55599289243827,"longitud":-52.52598996908773,"ranking":0},
{"id":14380,"nombre":"Sao Vendelino","localidad":"São Vendelino","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3699743,"longitud":-51.3761984,"ranking":0},
{"id":14404,"nombre":"Sao Vicente do Sul ","localidad":"São Vicente do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6927794,"longitud":-54.678796,"ranking":0},
{"id":5639,"nombre":"Sapiranga","localidad":"Sapiranga","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.64272806443479,"longitud":-51.003453083864805,"ranking":0},
{"id":5638,"nombre":"Sapucaia Do Sul","localidad":"Sapucaia do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.823173523020248,"longitud":-51.1486102025265,"ranking":0},
{"id":5605,"nombre":"Sarandi","localidad":"Sarandi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.93881995815733,"longitud":-52.92289642210201,"ranking":0},
{"id":5612,"nombre":"Seberi","localidad":"Seberi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.485922275055213,"longitud":-53.39238155952705,"ranking":0},
{"id":5637,"nombre":"Segredo","localidad":"Segredo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.342075800333262,"longitud":-52.9792345462199,"ranking":0},
{"id":14311,"nombre":"Selbach","localidad":"Selbach","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.6298223,"longitud":-52.9509134,"ranking":0},
{"id":14379,"nombre":"Serafina Correa","localidad":"Serafina Corrêa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.7099768,"longitud":-51.9333898,"ranking":0},
{"id":5543,"nombre":"Sertao","localidad":"Sertão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.98503414715514,"longitud":-52.25758986277666,"ranking":0},
{"id":14310,"nombre":"Sobradinho ","localidad":"Sobradinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4177936,"longitud":-53.025451,"ranking":0},
{"id":14536,"nombre":"TabaI","localidad":"Tabaí","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6857779,"longitud":-51.7201132,"ranking":0},
{"id":5579,"nombre":"Tapejara","localidad":"Tapejara","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.07281588387532,"longitud":-52.015312058648036,"ranking":0},
{"id":5564,"nombre":"Tapera","localidad":"Tapera","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.61821634801732,"longitud":-52.870377476405494,"ranking":0},
{"id":14466,"nombre":"Tapes","localidad":"Tapes","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.6729008,"longitud":-51.4094545,"ranking":0},
{"id":5651,"nombre":"Taquara","localidad":"Taquara","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.654219041182117,"longitud":-50.776618301182225,"ranking":0},
{"id":14377,"nombre":"Taquari","localidad":"Taquari","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.7926124,"longitud":-51.8657109,"ranking":0},
{"id":14319,"nombre":"Taquarucu do Sul","localidad":"Taquaruçu do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.3956618,"longitud":-53.4671241,"ranking":0},
{"id":5584,"nombre":"Tenente Portela","localidad":"Tenente Portela","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.371912909267675,"longitud":-53.75501807967126,"ranking":0},
{"id":14374,"nombre":"Terra de Areia","localidad":"Terra de Areia","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.584888,"longitud":-50.068888,"ranking":0},
{"id":14488,"nombre":"Curumim","localidad":"Terra de Areia","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.6224238,"longitud":-49.9369903,"ranking":0},
{"id":8677,"nombre":"Teutonia","localidad":"Teutônia","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.468550953582383,"longitud":-51.810026495721566,"ranking":0},
{"id":14321,"nombre":"Tio Hugo","localidad":"Tio Hugo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5787535,"longitud":-52.5980123,"ranking":0},
{"id":14405,"nombre":"Toropi","localidad":"Toropi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4777639,"longitud":-54.2308392,"ranking":0},
{"id":14372,"nombre":"Tres Cachoeiras","localidad":"Três Cachoeiras","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4559558,"longitud":-49.9263882,"ranking":0},
{"id":5636,"nombre":"Tres Coroas","localidad":"Três Coroas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.516079179926027,"longitud":-50.77344319995251,"ranking":0},
{"id":5602,"nombre":"Tres de Maio","localidad":"Três de Maio","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.77820574792899,"longitud":-54.23495871351482,"ranking":0},
{"id":14368,"nombre":"Tres Forquilhas","localidad":"Três Forquilhas","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.5376923,"longitud":-50.0626356,"ranking":0},
{"id":5545,"nombre":"Tres Palmeiras","localidad":"Três Palmeiras","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.614665254378192,"longitud":-52.843247660632905,"ranking":0},
{"id":5587,"nombre":"Tres Passos ","localidad":"Três Passos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.45051689033489,"longitud":-53.94117426238499,"ranking":0},
{"id":8485,"nombre":"Trindade do Sul","localidad":"Trindade do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.523107853355054,"longitud":-52.89901748910634,"ranking":0},
{"id":5573,"nombre":"Tucunduva","localidad":"Tucunduva","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.657866163956307,"longitud":-54.445303768867454,"ranking":0},
{"id":10399,"nombre":"Tupancireta","localidad":"Tupanciretã","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.075171351792246,"longitud":-53.843688102544895,"ranking":0},
{"id":5987,"nombre":"Tuparendi","localidad":"Tuparendi","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.760428156484846,"longitud":-54.48030225072512,"ranking":0},
{"id":5984,"nombre":"Vacaria","localidad":"Vacaria","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5137507314623,"longitud":-50.948392996065905,"ranking":0},
{"id":14574,"nombre":"Vale Real ","localidad":"Vale Real","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.3976957,"longitud":-51.25354799999999,"ranking":0},
{"id":8483,"nombre":"Vanini ","localidad":"Vanini","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.47686274872405,"longitud":-51.84655766109435,"ranking":0},
{"id":5073,"nombre":"Venacio Aires","localidad":"Venâcio Aires","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5576,"nombre":"Veranopolis","localidad":"Veranópolis","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.9329765,"longitud":-51.54576549999999,"ranking":0},
{"id":14537,"nombre":"Vespasiano Correa","localidad":"Vespasiano Correa","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.066151,"longitud":-51.863007,"ranking":0},
{"id":14406,"nombre":"Viadutos","localidad":"Viadutos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.5731688,"longitud":-52.0214925,"ranking":0},
{"id":9600,"nombre":"Vila Flores","localidad":"Vila Flores","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.863251896039266,"longitud":-51.55302320000001,"ranking":0},
{"id":5624,"nombre":"Vila Maria","localidad":"Vila Maria","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.53374987178395,"longitud":-52.15163970713565,"ranking":0},
{"id":14407,"nombre":"Vila Nova do Sul","localidad":"Vila Nova do Sul","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.3438168,"longitud":-53.8842637,"ranking":0},
{"id":5635,"nombre":"Vista Alegre","localidad":"Vista Alegre","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.372269589158066,"longitud":-53.48899107518523,"ranking":0},
{"id":14538,"nombre":"Vista Alegre do Prata","localidad":"Vista Alegre do Prata","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.8079254,"longitud":-51.7880609,"ranking":0},
{"id":14309,"nombre":"Vista Gaucha","localidad":"Vista Gaúcha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-27.2906184,"longitud":-53.7017254,"ranking":0},
{"id":14539,"nombre":"Westfalia","localidad":"Westfalia","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.4321684,"longitud":-51.7704783,"ranking":0},
{"id":14366,"nombre":"Xangri-la","localidad":"Xangri-lá","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.8039492,"longitud":-50.0421755,"ranking":0},
{"id":6850,"nombre":"Agua Clara","localidad":"Água Clara","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.444763377410105,"longitud":-52.87477475837188,"ranking":0},
{"id":9219,"nombre":"Alcinópolis","localidad":"Alcinópolis","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.325899513144748,"longitud":-53.714110817507716,"ranking":0},
{"id":12329,"nombre":"Amambai","localidad":"Amambai","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.1159,"longitud":-55.22349999999999,"ranking":0},
{"id":6854,"nombre":"Anastacio","localidad":"Anastácio","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.481748454813392,"longitud":-55.80311812158349,"ranking":0},
{"id":7328,"nombre":"Entrada Anastacio","localidad":"Anastácio","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.481712411325717,"longitud":-55.803110935126476,"ranking":0},
{"id":9602,"nombre":"Anaurilandia","localidad":"Anaurilândia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.179338326743643,"longitud":-52.721030019318675,"ranking":0},
{"id":6855,"nombre":"Angelica","localidad":"Angélica","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.116715816803172,"longitud":-53.75229681609238,"ranking":0},
{"id":9220,"nombre":"Antônio João","localidad":"Antônio João","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.186624295513607,"longitud":-55.947656474546086,"ranking":0},
{"id":5588,"nombre":"Aparecida do Taboado","localidad":"Aparecida do Taboado","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.075936865906275,"longitud":-51.098932043059335,"ranking":0},
{"id":6857,"nombre":"Aquidauana","localidad":"Aquidauana","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.46466350148633,"longitud":-55.788059402375424,"ranking":0},
{"id":6859,"nombre":"Bandeirantes","localidad":"Bandeirantes","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.916018432979836,"longitud":-54.36770323164341,"ranking":0},
{"id":6860,"nombre":"Bataguassu","localidad":"Bataguassu","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.721082374154232,"longitud":-52.43105749262371,"ranking":0},
{"id":14790,"nombre":"Bataypora","localidad":"Batayporã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.2962171,"longitud":-53.2658946,"ranking":0},
{"id":9222,"nombre":"Bela Vista","localidad":"Bela Vista","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.10319120172851,"longitud":-56.52956731774529,"ranking":0},
{"id":6862,"nombre":"Bodoquena","localidad":"Bodoquena","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.548911739621136,"longitud":-56.671136470949705,"ranking":0},
{"id":9224,"nombre":"Bonito","localidad":"Bonito","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.129212770001345,"longitud":-56.49244944400822,"ranking":0},
{"id":10390,"nombre":"Brasilandia","localidad":"Brasilândia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.25147954085505,"longitud":-52.03411610519947,"ranking":0},
{"id":8637,"nombre":"Caarapo","localidad":"Caarapó","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.629487911403352,"longitud":-54.82020322168595,"ranking":0},
{"id":9228,"nombre":"Camapuã","localidad":"Camapuã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.5253747345455,"longitud":-54.04409212089652,"ranking":0},
{"id":9229,"nombre":"Caracol","localidad":"Caracol","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.013810403545122,"longitud":-57.03196332736451,"ranking":0},
{"id":9492,"nombre":"Cassilandia","localidad":"Cassilândia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.1183698,"longitud":-51.7316412,"ranking":0},
{"id":10682,"nombre":"Chapadao do Sul","localidad":"Chapadão do Sul","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.797258930213367,"longitud":-52.61449889216784,"ranking":0},
{"id":9230,"nombre":"Corguinho","localidad":"Corguinho","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.82648942256937,"longitud":-54.831749515818174,"ranking":0},
{"id":14883,"nombre":"Coronel Sapucaia","localidad":"Coronel Sapucaia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.27595611767067,"longitud":-55.53970365991191,"ranking":0},
{"id":6890,"nombre":"Corumba","localidad":"Corumbá","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.009864411600528,"longitud":-57.64547075114114,"ranking":0},
{"id":10391,"nombre":"Costa Rica","localidad":"Costa Rica","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.557151777423563,"longitud":-53.12441995008465,"ranking":0},
{"id":6891,"nombre":"Coxim","localidad":"Coxim","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.5128688457538,"longitud":-54.73963181922766,"ranking":0},
{"id":6894,"nombre":"Deodapolis","localidad":"Deodápolis","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14283,"nombre":"Dois Irmaos do Buriti","localidad":"Dois Irmãos do Buriti","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.691104528453014,"longitud":-55.28661919748268,"ranking":0},
{"id":6895,"nombre":"Douradina","localidad":"Douradina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.04100009890085,"longitud":-54.603373152059255,"ranking":0},
{"id":6898,"nombre":"Entroncamento Douradina","localidad":"Douradina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.041065118289477,"longitud":-54.603276191327126,"ranking":0},
{"id":6896,"nombre":"Dourados","localidad":"Dourados","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.226308,"longitud":-54.7905618,"ranking":0},
{"id":8634,"nombre":"Eldorado ","localidad":"Eldorado","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.791583763473177,"longitud":-54.28210887087395,"ranking":0},
{"id":6900,"nombre":"Fatima do Sul","localidad":"Fátima do Sul","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.37996334467437,"longitud":-54.50523541452175,"ranking":0},
{"id":9232,"nombre":"Figueirão","localidad":"Figueirão","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6901,"nombre":"Gloria de Dourados","localidad":"Glória de Dourados","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.422364927890612,"longitud":-54.232440086325965,"ranking":0},
{"id":9233,"nombre":"Guia Lopes da Laguna","localidad":"Guia Lopes da Laguna","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.460693588712356,"longitud":-56.11530212316178,"ranking":0},
{"id":12330,"nombre":"Iguatemi","localidad":"Iguatemi","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.674309,"longitud":-54.5603165,"ranking":0},
{"id":10392,"nombre":"Inocencia","localidad":"Inocência","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.731196283037296,"longitud":-51.925346344661335,"ranking":0},
{"id":7115,"nombre":"Itapora","localidad":"Itaporã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.079703788998557,"longitud":-54.79023987596158,"ranking":0},
{"id":7137,"nombre":"Itaquirai","localidad":"Itaquiraí","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.468850060307734,"longitud":-54.18919880279062,"ranking":0},
{"id":7116,"nombre":"Ivinhema","localidad":"Ivinhema","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.31177577397015,"longitud":-53.817094548019334,"ranking":0},
{"id":9883,"nombre":"Jaraguari","localidad":"Jaraguari","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.100096584476773,"longitud":-54.43744477418978,"ranking":0},
{"id":10387,"nombre":"Jardim","localidad":"Jardim","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.481043,"longitud":-56.148301,"ranking":0},
{"id":6897,"nombre":"Entroncamento  Jatei","localidad":"Jateí","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.480843128146734,"longitud":-54.30415020436225,"ranking":0},
{"id":7117,"nombre":"Jatei","localidad":"Jateí","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.480858445451418,"longitud":-54.304111590437046,"ranking":0},
{"id":8636,"nombre":"Juti ","localidad":"Juti","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.868925541563634,"longitud":-54.60290870268121,"ranking":0},
{"id":7118,"nombre":"Ladario","localidad":"Ladário","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.00365782583783,"longitud":-57.60158905401373,"ranking":0},
{"id":8640,"nombre":"Maracaju ","localidad":"Maracaju","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.635607975315935,"longitud":-55.15835152866014,"ranking":0},
{"id":6847,"nombre":"Miranda","localidad":"Miranda","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.237297214820657,"longitud":-56.37488387226816,"ranking":0},
{"id":7136,"nombre":"Mundo Novo","localidad":"Mundo Novo","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.93948947426471,"longitud":-23.93948947426471,"ranking":0},
{"id":8635,"nombre":"Navirai","localidad":"Naviraí","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.067568598985446,"longitud":-54.19340374357799,"ranking":3},
{"id":9237,"nombre":"Nioaque","localidad":"Nioaque","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.156613721294928,"longitud":-55.830133223139946,"ranking":0},
{"id":15338,"nombre":"Inicio da Serra","localidad":"Nioaque","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.156790269813822,"longitud":-55.830098282723775,"ranking":0},
{"id":7121,"nombre":"Nova Alvorada do Sul","localidad":"Nova Alvorada do Sul","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.4664121562655,"longitud":-54.38443952700624,"ranking":0},
{"id":7120,"nombre":"Nova Andradina","localidad":"Nova Andradina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.243975321580727,"longitud":-53.35250179618745,"ranking":0},
{"id":10393,"nombre":"Paraiso das Aguas","localidad":"Paraíso das Águas","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.02641110478899,"longitud":-53.00945678847328,"ranking":0},
{"id":5589,"nombre":"Paranaiba","localidad":"Paranaíba","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.680209846359936,"longitud":-51.191172387395774,"ranking":0},
{"id":12331,"nombre":"Paranhos","localidad":"Paranhos","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.8933905,"longitud":-55.4375364,"ranking":0},
{"id":7122,"nombre":"Pedro Gomes","localidad":"Pedro Gomes","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.10606046258212,"longitud":-54.54970952132446,"ranking":0},
{"id":8638,"nombre":"Ponta Pora","localidad":"Ponta Porã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.5600811,"longitud":-55.6967812,"ranking":5},
{"id":9238,"nombre":"Porto Murtinho","localidad":"Porto Murtinho","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.69929825890917,"longitud":-57.88169136071781,"ranking":0},
{"id":10389,"nombre":"Ribas do Rio Pardo","localidad":"Ribas do Rio Pardo","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.45491202882433,"longitud":-53.76747784417944,"ranking":0},
{"id":7128,"nombre":"Rio Brilhante","localidad":"Rio Brilhante","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.806537811332046,"longitud":-54.54113285237807,"ranking":0},
{"id":10388,"nombre":"Rio Negro","localidad":"Rio Negro","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.447740716456654,"longitud":-54.989165038345355,"ranking":0},
{"id":6851,"nombre":"Rio Verde de Mato Grosso","localidad":"Rio Verde de Mato Grosso","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-18.915139708649466,"longitud":-54.836966624394435,"ranking":0},
{"id":9239,"nombre":"Rochedo","localidad":"Rochedo","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.956599234794645,"longitud":-54.88640074232822,"ranking":0},
{"id":15337,"nombre":"Santa Rita do Pardo","localidad":"Santa Rita do Pardo","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.3021266,"longitud":-52.8280641,"ranking":0},
{"id":7130,"nombre":"Sao Gabriel do Oeste","localidad":"São Gabriel do Oeste","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-19.391617553203492,"longitud":-54.57060162785642,"ranking":0},
{"id":12332,"nombre":"Sete Quedas","localidad":"Sete Quedas","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.9792777,"longitud":-55.0396532,"ranking":0},
{"id":10394,"nombre":"Selviria","localidad":"Selvíria","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.366169553762187,"longitud":-51.42552643341464,"ranking":0},
{"id":7131,"nombre":"Sidrolandia","localidad":"Sidrolândia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.93489960368147,"longitud":-54.96059417388153,"ranking":0},
{"id":7132,"nombre":"Sonora","localidad":"Sonora","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-17.591676863551694,"longitud":-54.745835822779505,"ranking":0},
{"id":12333,"nombre":"Tacuru","localidad":"Tacuru","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-23.6379886,"longitud":-55.0228548,"ranking":0},
{"id":7134,"nombre":"Terenos","localidad":"Terenos","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.445737026626738,"longitud":-54.867309323607984,"ranking":0},
{"id":7135,"nombre":"Vicentina","localidad":"Vicentina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5599,"nombre":"Agua Boa","localidad":"Água Boa","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.043922712009852,"longitud":-52.15642002607033,"ranking":0},
{"id":6562,"nombre":"Alta Floresta","localidad":"Alta Floresta","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.891973931010082,"longitud":-56.08663097904699,"ranking":0},
{"id":5558,"nombre":"Alto Araguaia","localidad":"Alto Araguaia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-17.32150621520183,"longitud":-53.22385039659719,"ranking":0},
{"id":7420,"nombre":"Alto Boa Vista","localidad":"Alto Boa Vista","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.672078556965872,"longitud":-51.37549719381183,"ranking":0},
{"id":5556,"nombre":"Alto Garcas","localidad":"Alto Garças","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.951697643982754,"longitud":-53.526149387866475,"ranking":0},
{"id":5590,"nombre":"Alto Taquari","localidad":"Alto Taquari","provincia":"Mato Grosso","pais":"BRASIL","latitud":-17.827234728484438,"longitud":-53.28371590003314,"ranking":0},
{"id":10013,"nombre":"Apiacas","localidad":"Apiacás","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.56326828106456,"longitud":-57.39170713254924,"ranking":0},
{"id":9456,"nombre":"Araguaiana","localidad":"Araguaiana","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.728577825434114,"longitud":-51.835470078111996,"ranking":0},
{"id":9989,"nombre":"Araguainha","localidad":"Araguainha","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9990,"nombre":"Araputanga","localidad":"Araputanga","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.469760400396146,"longitud":-58.348732345360155,"ranking":0},
{"id":7419,"nombre":"Barra do Bugres","localidad":"Barra do Bugres","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.065420011878212,"longitud":-57.18689719730975,"ranking":0},
{"id":6567,"nombre":"Barra do Garcas","localidad":"Barra do Garças","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.8786658,"longitud":-52.29508269999999,"ranking":0},
{"id":9991,"nombre":"Bom Jesus do Araguaia","localidad":"Bom Jesus do Araguaia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.11548989424026,"longitud":-51.72960253825295,"ranking":0},
{"id":9594,"nombre":"Brasnorte","localidad":"Brasnorte","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6573,"nombre":"Caceres ","localidad":"Cáceres","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.10475368783638,"longitud":-57.681590797166066,"ranking":0},
{"id":9458,"nombre":"Campinapolis","localidad":"Campinápolis","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.545527441839688,"longitud":-52.796088435501396,"ranking":0},
{"id":9595,"nombre":"Campo Novo do Parecis","localidad":"Campo Novo do Parecis","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.6557978,"longitud":-57.9034169,"ranking":0},
{"id":9877,"nombre":"Campo Verde","localidad":"Campo Verde","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.5470185,"longitud":-55.1643511,"ranking":0},
{"id":14968,"nombre":"Campos de Julio","localidad":"Campos de Júlio","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.723019634369853,"longitud":-59.262653740576205,"ranking":0},
{"id":9459,"nombre":"Cana brava do Norte","localidad":"Canabrava do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.05328130509829,"longitud":-51.8310235066461,"ranking":0},
{"id":6574,"nombre":"Canarana","localidad":"Canarana","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.55684,"longitud":-52.27382,"ranking":0},
{"id":15101,"nombre":"Serra Dourada","localidad":"Canarana","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.7059721,"longitud":-52.0253191,"ranking":0},
{"id":9992,"nombre":"Carlinda","localidad":"Carlinda","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.969360788133173,"longitud":-55.8242790739602,"ranking":0},
{"id":14263,"nombre":"Castanheira","localidad":"Castanheira","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.1363699,"longitud":-58.61280730000001,"ranking":0},
{"id":14264,"nombre":"Chapada dos Guimaraes","localidad":"Chapada dos Guimarães","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.4625773,"longitud":-55.74788659999999,"ranking":0},
{"id":10014,"nombre":"Claudia","localidad":"Cláudia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.50127041082775,"longitud":-54.88519549270755,"ranking":0},
{"id":15520,"nombre":"Cocalinho","localidad":"Cocalinho","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.393256,"longitud":-51.00608399999999,"ranking":0},
{"id":8776,"nombre":"Colider","localidad":"Colíder","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.802344290199898,"longitud":-55.45890890587133,"ranking":0},
{"id":6575,"nombre":"Comodoro","localidad":"Comodoro","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.659228978552305,"longitud":-59.79127034969278,"ranking":0},
{"id":10041,"nombre":"Confresa","localidad":"Confresa","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.645547897966361,"longitud":-51.56810209402552,"ranking":0},
{"id":10604,"nombre":"Conquista D'Oeste","localidad":"Conquista D'Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.53765925447551,"longitud":-59.54603534929849,"ranking":0},
{"id":10605,"nombre":"Curvelandia","localidad":"Curvelândia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.609687257816224,"longitud":-57.919384122550746,"ranking":0},
{"id":9993,"nombre":"Diamantino","localidad":"Diamantino","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.405592776525474,"longitud":-56.435885568162014,"ranking":0},
{"id":10015,"nombre":"Dom Aquino","localidad":"Dom Aquino","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.807776354863043,"longitud":-54.93151682717806,"ranking":0},
{"id":10019,"nombre":"Feliz Natal","localidad":"Feliz Natal","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.37793553468926,"longitud":-54.94115276505784,"ranking":0},
{"id":9995,"nombre":"Figueirópolis D'Oeste","localidad":"Figueirópolis D'Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9466,"nombre":"General Carneiro","localidad":"General Carneiro","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.72822956603639,"longitud":-56.338184615802916,"ranking":0},
{"id":15273,"nombre":"Gloria D Oeste","localidad":"Glória D'Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.768023474637184,"longitud":-58.31110606082966,"ranking":0},
{"id":9227,"nombre":"Guaranta do Norte","localidad":"Guarantã do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.94594306130222,"longitud":-54.90848926759118,"ranking":0},
{"id":12353,"nombre":"Guiratinga","localidad":"Guiratinga","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.3427482,"longitud":-53.7777075,"ranking":0},
{"id":9996,"nombre":"Indiavai","localidad":"Indiavaí","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14941,"nombre":"Ipiranga do Norte","localidad":"Ipiranga do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.241723228966542,"longitud":-56.152351519411106,"ranking":0},
{"id":9997,"nombre":"Itanhanga","localidad":"Itanhangá","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.240742610742904,"longitud":-56.64397421540288,"ranking":0},
{"id":8777,"nombre":"Itauba","localidad":"Itaúba","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.00434001466071,"longitud":-55.24673643302954,"ranking":0},
{"id":10418,"nombre":"Itiquira","localidad":"Itiquira","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6577,"nombre":"Jaciara","localidad":"Jaciara","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.9528473935254,"longitud":-54.97697476289577,"ranking":0},
{"id":9998,"nombre":"Jangada","localidad":"Jangada","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.235127298879192,"longitud":-56.492565572335494,"ranking":0},
{"id":9999,"nombre":"Jauru","localidad":"Jauru","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.342260072421452,"longitud":-58.876774061771506,"ranking":0},
{"id":10000,"nombre":"Juara","localidad":"Juara","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.252773294462276,"longitud":-57.50823296962875,"ranking":0},
{"id":9596,"nombre":"Juina","localidad":"Juína","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.422250161143992,"longitud":-58.757954650732096,"ranking":0},
{"id":14265,"nombre":"Juruena","localidad":"Juruena","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.327018826368093,"longitud":-58.49779274249997,"ranking":0},
{"id":6585,"nombre":"Juscimeira","localidad":"Juscimeira","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10606,"nombre":"Lambari D'Oeste","localidad":"Lambari D'Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8778,"nombre":"Lucas do Rio Verde","localidad":"Lucas do Rio Verde","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.0784768,"longitud":-55.9187191,"ranking":0},
{"id":10012,"nombre":"Vila Bela da Santissima Trindade","localidad":"Vila Bela da Santíssima Trindade","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.009613904189921,"longitud":-59.95438573154301,"ranking":0},
{"id":10016,"nombre":"Marcelandia","localidad":"Marcelândia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.088193365641503,"longitud":-54.51573126025708,"ranking":0},
{"id":9467,"nombre":"Matupa","localidad":"Matupá","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.179112742728462,"longitud":-54.92454160286185,"ranking":0},
{"id":10001,"nombre":"Mirassol d Oeste","localidad":"Mirassol d'Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.679218754297326,"longitud":-58.093345796195734,"ranking":0},
{"id":9471,"nombre":"Nobres","localidad":"Nobres","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.728184647510155,"longitud":-56.33866133482666,"ranking":0},
{"id":10002,"nombre":"Nova Bandeirantes","localidad":"Nova Bandeirantes","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.8467254063239,"longitud":-57.814775184414664,"ranking":0},
{"id":10607,"nombre":"Nova Lacerda","localidad":"Nova Lacerda","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.474630748461236,"longitud":-59.58981161815949,"ranking":0},
{"id":8780,"nombre":"Nova Santa Helena ","localidad":"Nova Santa Helena","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.846210875152986,"longitud":-55.18724481436385,"ranking":0},
{"id":14988,"nombre":"Nova Brasilandia","localidad":"Nova Brasilândia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.931685489807109,"longitud":-54.97376026882257,"ranking":0},
{"id":10003,"nombre":"Nova Canaa do Norte ","localidad":"Nova Canaã do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.6384394,"longitud":-55.70618229999999,"ranking":0},
{"id":8779,"nombre":"Nova Mutum","localidad":"Nova Mutum","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.822769906643796,"longitud":-56.078908922396934,"ranking":0},
{"id":6586,"nombre":"Nova Olimpia","localidad":"Nova Olímpia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.77656310260932,"longitud":-57.28119134902954,"ranking":0},
{"id":14940,"nombre":"Nova Ubirata","localidad":"Nova Ubiratã","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.033249835961902,"longitud":-55.25373076035331,"ranking":0},
{"id":5598,"nombre":"Nova Xavantina","localidad":"Nova Xavantina","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.661441645535445,"longitud":-52.35892647642986,"ranking":0},
{"id":10017,"nombre":"Novo Mundo","localidad":"Novo Mundo","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10006,"nombre":"Novo Horizonte do Norte","localidad":"Novo Horizonte do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.394597310948887,"longitud":-57.318522306477,"ranking":0},
{"id":9473,"nombre":"Novo Sao Joaquim","localidad":"Novo São Joaquim","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.914316742234734,"longitud":-53.02365831919654,"ranking":0},
{"id":10007,"nombre":"Paranaita","localidad":"Paranaíta","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.673861435435386,"longitud":-56.473637220061086,"ranking":0},
{"id":6611,"nombre":"Paranatinga","localidad":"Paranatinga","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.420837689719615,"longitud":-54.04209896293379,"ranking":0},
{"id":10018,"nombre":"Novo Santo Antonio","localidad":"Novo Santo Antônio","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5552,"nombre":"Pedra Preta","localidad":"Pedra Preta","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.619504572675417,"longitud":-54.47221000278714,"ranking":0},
{"id":9474,"nombre":"Peixoto de Azevedo","localidad":"Peixoto de Azevedo","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.246931219996092,"longitud":-54.99037990264041,"ranking":0},
{"id":14989,"nombre":"Planalto da Serra","localidad":"Planalto da Serra","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.663638829251253,"longitud":-54.77536576736992,"ranking":0},
{"id":14279,"nombre":"Pontal do Araguaia","localidad":"Pontal do Araguaia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.8972233,"longitud":-52.24183300000001,"ranking":0},
{"id":14990,"nombre":"Ponte Branca","localidad":"Ponte Branca","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.7683707,"longitud":-52.83488209999999,"ranking":0},
{"id":7422,"nombre":"Pontes e Lacerda","localidad":"Pontes e Lacerda","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.2409986,"longitud":-59.3311102,"ranking":0},
{"id":9475,"nombre":"Porto Alegre do Norte","localidad":"Porto Alegre do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.875801697629807,"longitud":-51.632572675047335,"ranking":0},
{"id":10008,"nombre":"Porto dos Gauchos","localidad":"Porto dos Gaúchos","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.539084728350124,"longitud":-57.40715364637206,"ranking":0},
{"id":10009,"nombre":"Porto Esperidiao","localidad":"Porto Esperidião","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.855233371929712,"longitud":-58.46360322516861,"ranking":0},
{"id":5549,"nombre":"Poxoreu","localidad":"Poxoréu","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.833914336799953,"longitud":-54.39357308602795,"ranking":0},
{"id":5548,"nombre":"Primavera do Leste ","localidad":"Primavera do Leste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.566345165369071,"longitud":-54.29988384246826,"ranking":0},
{"id":5600,"nombre":"Querencia","localidad":"Querência","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.6072423,"longitud":-52.2177318,"ranking":0},
{"id":10023,"nombre":"Sao Jose dos Quatro Marcos","localidad":"São José dos Quatro Marcos","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.626401933846278,"longitud":-58.174886835231156,"ranking":0},
{"id":7423,"nombre":"Ribeirao Cascalheira","localidad":"Ribeirão Cascalheira","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.945981116452967,"longitud":-51.827497184559775,"ranking":0},
{"id":10024,"nombre":"Ribeiraozinho","localidad":"Ribeirãozinho","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.490776399904096,"longitud":-52.69150303308777,"ranking":0},
{"id":10608,"nombre":"Rio Branco","localidad":"Rio Branco","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.240490998777535,"longitud":-58.11577670306584,"ranking":0},
{"id":14939,"nombre":"Santa Carmem","localidad":"Santa Carmem","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.976973908013013,"longitud":-55.27878500894203,"ranking":0},
{"id":14992,"nombre":"Sao Jose do Povo","localidad":"São José do Povo","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.46423865759034,"longitud":-54.25477604752872,"ranking":0},
{"id":10022,"nombre":"Sao Jose do Rio Claro","localidad":"São José do Rio Claro","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.446070621046555,"longitud":-56.723343831664224,"ranking":0},
{"id":9481,"nombre":"Sao Jose do Xingu","localidad":"São José do Xingu","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.80327238862768,"longitud":-52.733576369953184,"ranking":0},
{"id":6595,"nombre":"Sao Pedro da Cipa","localidad":"São Pedro da Cipa","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.999516758143494,"longitud":-54.917639193248895,"ranking":0},
{"id":5550,"nombre":"Rondonopolis","localidad":"Rondonópolis","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.4528937,"longitud":-54.6609805,"ranking":0},
{"id":9476,"nombre":"Rosario Oeste","localidad":"Rosário Oeste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.84040085127028,"longitud":-56.4351583272257,"ranking":0},
{"id":10609,"nombre":"Salto do Ceu","localidad":"Salto do Céu","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.129905688809195,"longitud":-58.12636536274406,"ranking":0},
{"id":10011,"nombre":"Trivelato","localidad":"Santa Rita do Trivelato","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14991,"nombre":"Santo Antonio do Leste","localidad":"Santo Antônio do Leste","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.7683707,"longitud":-52.83488209999999,"ranking":0},
{"id":9479,"nombre":"Sao Felix do Araguaia","localidad":"São Félix do Araguaia","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.621915030686683,"longitud":-50.68056152142067,"ranking":0},
{"id":14969,"nombre":"Sapezal","localidad":"Sapezal","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.5401229,"longitud":-58.7934761,"ranking":0},
{"id":10042,"nombre":"Serra Nova Dourada","localidad":"Serra Nova Dourada","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.090486498284895,"longitud":-51.40128894951518,"ranking":0},
{"id":8753,"nombre":"Sinop","localidad":"Sinop","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.85567,"longitud":-55.50692,"ranking":0},
{"id":8781,"nombre":"Sorriso ","localidad":"Sorriso","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.5720584,"longitud":-55.72643369999999,"ranking":0},
{"id":10020,"nombre":"Tabapora","localidad":"Tabaporã","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.304369766472034,"longitud":-56.822232637987135,"ranking":0},
{"id":6593,"nombre":"Tangara da Serra","localidad":"Tangará da Serra","provincia":"Mato Grosso","pais":"BRASIL","latitud":-14.6237247,"longitud":-57.49029869999999,"ranking":0},
{"id":10010,"nombre":"Tapurah","localidad":"Tapurah","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.746223347395064,"longitud":-56.51305521028801,"ranking":0},
{"id":9485,"nombre":"Terra Nova do Norte","localidad":"Terra Nova do Norte","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8428,"nombre":"Torixoreu","localidad":"Torixoréu","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.19909744156503,"longitud":-52.55744862538748,"ranking":0},
{"id":10021,"nombre":"Uniao do Sul","localidad":"União do Sul","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10610,"nombre":"Vale de Sao Domingos","localidad":"Vale de São Domingos","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.296996078235038,"longitud":-59.06637669596554,"ranking":0},
{"id":6582,"nombre":"Varzea Grande","localidad":"Várzea Grande","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.640403074264473,"longitud":-56.16730758795912,"ranking":0},
{"id":10026,"nombre":"Vera","localidad":"Vera","provincia":"Mato Grosso","pais":"BRASIL","latitud":-12.295049176585232,"longitud":-55.29454011052642,"ranking":0},
{"id":8427,"nombre":"Vila Rica","localidad":"Vila Rica","provincia":"Mato Grosso","pais":"BRASIL","latitud":-10.024097169403738,"longitud":-51.11887065615502,"ranking":0},
{"id":10004,"nombre":"Nova Maringa","localidad":"Nova Maringá","provincia":"Mato Grosso","pais":"BRASIL","latitud":-13.024197242795426,"longitud":-57.0919018666025,"ranking":0},
{"id":10005,"nombre":"Nova Monte Verde","localidad":"Nova Monte Verde","provincia":"Mato Grosso","pais":"BRASIL","latitud":-9.97965080174367,"longitud":-57.4673991296689,"ranking":0},
{"id":7690,"nombre":"Abadiania ","localidad":"Abadiânia","provincia":"Goias","pais":"BRASIL","latitud":-16.194948361620032,"longitud":-48.70894182884418,"ranking":0},
{"id":6338,"nombre":"Acreuna","localidad":"Acreúna","provincia":"Goias","pais":"BRASIL","latitud":-17.39426519230717,"longitud":-50.37838707419584,"ranking":0},
{"id":6073,"nombre":"Agua Limpa","localidad":"Água Limpa","provincia":"Goias","pais":"BRASIL","latitud":-18.078020325496876,"longitud":-48.7639312523366,"ranking":0},
{"id":7649,"nombre":"Aguas Lindas de Goias","localidad":"Águas Lindas de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-15.714740169967977,"longitud":-48.28852635490593,"ranking":0},
{"id":6336,"nombre":"Alexania","localidad":"Alexânia","provincia":"Goias","pais":"BRASIL","latitud":-16.08831594581603,"longitud":-48.501141254798924,"ranking":0},
{"id":6339,"nombre":"Aloandia","localidad":"Aloândia","provincia":"Goias","pais":"BRASIL","latitud":-17.73027993020651,"longitud":-49.48111767393472,"ranking":0},
{"id":6292,"nombre":"Alto Paraiso","localidad":"Alto Paraíso de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-14.13471374012208,"longitud":-47.51956358253975,"ranking":0},
{"id":6294,"nombre":"Alvorada Do Norte","localidad":"Alvorada do Norte","provincia":"Goias","pais":"BRASIL","latitud":-14.488043299599358,"longitud":-46.49706664689732,"ranking":0},
{"id":8944,"nombre":"Amorinopolis","localidad":"Amorinópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.62422424960732,"longitud":-51.09320912857997,"ranking":0},
{"id":6303,"nombre":"Anapolis","localidad":"Anápolis","provincia":"Goias","pais":"BRASIL","latitud":-16.3229,"longitud":-48.946,"ranking":1},
{"id":6340,"nombre":"Aparecida de Goiania","localidad":"Aparecida de Goiânia","provincia":"Goias","pais":"BRASIL","latitud":-16.83409853332869,"longitud":-49.24652309466771,"ranking":0},
{"id":5574,"nombre":"Aparecida do Rio Doce","localidad":"Aparecida do Rio Doce","provincia":"Goias","pais":"BRASIL","latitud":-18.29434124796735,"longitud":-51.149388493187566,"ranking":0},
{"id":9491,"nombre":"Apore","localidad":"Aporé","provincia":"Goias","pais":"BRASIL","latitud":-18.964398711990196,"longitud":-51.92598558330705,"ranking":0},
{"id":6400,"nombre":"Aragarcas ","localidad":"Aragarças","provincia":"Goias","pais":"BRASIL","latitud":-15.897482720692784,"longitud":-52.241371006060305,"ranking":0},
{"id":6317,"nombre":"Aragoiania","localidad":"Aragoiânia","provincia":"Goias","pais":"BRASIL","latitud":-16.917039632720428,"longitud":-49.452475679886646,"ranking":0},
{"id":7696,"nombre":"Araguapaz","localidad":"Araguapaz","provincia":"Goias","pais":"BRASIL","latitud":-15.092347518516151,"longitud":-50.62809405189934,"ranking":0},
{"id":7698,"nombre":"Arenopolis ","localidad":"Arenópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.387136912330618,"longitud":-51.56088464394675,"ranking":0},
{"id":15523,"nombre":"Aruana","localidad":"Aruanã","provincia":"Goias","pais":"BRASIL","latitud":-14.9237127,"longitud":-51.08128610000001,"ranking":0},
{"id":9513,"nombre":"Aurilandia","localidad":"Aurilândia","provincia":"Goias","pais":"BRASIL","latitud":-16.678733543435904,"longitud":-50.46359623347335,"ranking":0},
{"id":15088,"nombre":"Baliza","localidad":"Baliza","provincia":"Goias","pais":"BRASIL","latitud":-16.1968387,"longitud":-52.5464776,"ranking":0},
{"id":14899,"nombre":"Barro Alto","localidad":"Barro Alto","provincia":"Goias","pais":"BRASIL","latitud":-14.973420479760895,"longitud":-48.918452121374756,"ranking":0},
{"id":6341,"nombre":"Bela Vista","localidad":"Bela Vista de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.97220045186009,"longitud":-48.95779807389758,"ranking":0},
{"id":7700,"nombre":"Bom Jardim de Goias","localidad":"Bom Jardim de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.20293962119337,"longitud":-52.17565440834137,"ranking":0},
{"id":6342,"nombre":"Bom Jesus","localidad":"Bom Jesus de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-18.21227794264619,"longitud":-49.73751821908102,"ranking":0},
{"id":10380,"nombre":"Bonfinopolis","localidad":"Bonfinópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.619447985406307,"longitud":-48.960086209847084,"ranking":0},
{"id":15522,"nombre":"Britania","localidad":"Britânia","provincia":"Goias","pais":"BRASIL","latitud":-15.2382839,"longitud":-51.1616359,"ranking":0},
{"id":6344,"nombre":"Buriti Alegre","localidad":"Buriti Alegre","provincia":"Goias","pais":"BRASIL","latitud":-18.13731209414213,"longitud":-49.043923927757056,"ranking":0},
{"id":14898,"nombre":"Cabeceiras","localidad":"Cabeceiras","provincia":"Goias","pais":"BRASIL","latitud":-15.8048493,"longitud":-46.9207832,"ranking":0},
{"id":6346,"nombre":"Cachoeira Alta","localidad":"Cachoeira Alta","provincia":"Goias","pais":"BRASIL","latitud":-18.757085853575134,"longitud":-50.94215841782216,"ranking":0},
{"id":9514,"nombre":"Cachoeira de Goias","localidad":"Cachoeira de Goiás","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6345,"nombre":"Cachoeira Dourada","localidad":"Cachoeira Dourada","provincia":"Goias","pais":"BRASIL","latitud":-18.494033690367182,"longitud":-49.47309213163332,"ranking":0},
{"id":6347,"nombre":"Cacu","localidad":"Caçu","provincia":"Goias","pais":"BRASIL","latitud":-18.563144355703038,"longitud":-51.13130188480806,"ranking":0},
{"id":5596,"nombre":"Caiaponia","localidad":"Caiapônia","provincia":"Goias","pais":"BRASIL","latitud":-16.965218791762343,"longitud":-51.814991987609815,"ranking":0},
{"id":6348,"nombre":"Caldas Novas","localidad":"Caldas Novas","provincia":"Goias","pais":"BRASIL","latitud":-17.757309,"longitud":-48.63124699999999,"ranking":0},
{"id":7703,"nombre":"Campinacu ","localidad":"Campinaçu","provincia":"Goias","pais":"BRASIL","latitud":-13.78725290076192,"longitud":-48.56750596025726,"ranking":0},
{"id":7660,"nombre":"Campinorte","localidad":"Campinorte","provincia":"Goias","pais":"BRASIL","latitud":-14.319453917660638,"longitud":-49.15561307693063,"ranking":0},
{"id":7707,"nombre":"Campo Limpo de Goias","localidad":"Campo Limpo de Goiás","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6306,"nombre":"Campos Belos","localidad":"Campos Belos","provincia":"Goias","pais":"BRASIL","latitud":-13.0341147,"longitud":-46.7672788,"ranking":0},
{"id":8864,"nombre":"Campos Verdes","localidad":"Campos Verdes","provincia":"Goias","pais":"BRASIL","latitud":-14.271509704287155,"longitud":-49.65774355008382,"ranking":0},
{"id":6349,"nombre":"Castelandia","localidad":"Castelândia","provincia":"Goias","pais":"BRASIL","latitud":-18.086441518524104,"longitud":-50.221812037227835,"ranking":0},
{"id":7652,"nombre":"Ceres ","localidad":"Ceres","provincia":"Goias","pais":"BRASIL","latitud":-15.30534779970573,"longitud":-49.602553045978674,"ranking":0},
{"id":9493,"nombre":"Chapadao do Céu","localidad":"Chapadão do Céu","provincia":"Goias","pais":"BRASIL","latitud":-18.394725887722856,"longitud":-52.66788036066015,"ranking":0},
{"id":14882,"nombre":"Cidade Ocidental","localidad":"Cidade Ocidental","provincia":"Goias","pais":"BRASIL","latitud":-16.10986205923207,"longitud":-47.932437616125775,"ranking":0},
{"id":6350,"nombre":"Corumbaiba","localidad":"Corumbaíba","provincia":"Goias","pais":"BRASIL","latitud":-18.133269471600734,"longitud":-48.5631435604363,"ranking":0},
{"id":4933,"nombre":"Cristalina","localidad":"Cristalina","provincia":"Goias","pais":"BRASIL","latitud":-16.7646442,"longitud":-47.6139529,"ranking":0},
{"id":6351,"nombre":"Cristianopolis","localidad":"Cristianópolis","provincia":"Goias","pais":"BRASIL","latitud":-17.200556172577635,"longitud":-48.70720829388694,"ranking":0},
{"id":8861,"nombre":"Crixas","localidad":"Crixás","provincia":"Goias","pais":"BRASIL","latitud":-14.5406965,"longitud":-49.9716467,"ranking":0},
{"id":10381,"nombre":"Crominia","localidad":"Cromínia","provincia":"Goias","pais":"BRASIL","latitud":-17.29016784592408,"longitud":-49.379078406537886,"ranking":0},
{"id":7816,"nombre":"Damolandia","localidad":"Damolândia","provincia":"Goias","pais":"BRASIL","latitud":-16.253298443494913,"longitud":-49.364893110977626,"ranking":0},
{"id":9515,"nombre":"Diorama","localidad":"Diorama","provincia":"Goias","pais":"BRASIL","latitud":-16.236097510513385,"longitud":-51.259154314949775,"ranking":0},
{"id":9516,"nombre":"Doverlandia","localidad":"Doverlândia","provincia":"Goias","pais":"BRASIL","latitud":-16.72242196559057,"longitud":-52.31272686119109,"ranking":0},
{"id":6352,"nombre":"Edealina","localidad":"Edealina","provincia":"Goias","pais":"BRASIL","latitud":-17.423971239709807,"longitud":-49.662183720812976,"ranking":0},
{"id":6353,"nombre":"Edeia ","localidad":"Edéia","provincia":"Goias","pais":"BRASIL","latitud":-17.338646244430745,"longitud":-49.926967837700545,"ranking":0},
{"id":7658,"nombre":"Estrela do Norte ","localidad":"Estrela do Norte","provincia":"Goias","pais":"BRASIL","latitud":-13.867984272252867,"longitud":-49.066693111812704,"ranking":0},
{"id":7711,"nombre":"Faina","localidad":"Faina","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9517,"nombre":"Fazenda Nova","localidad":"Fazenda Nova","provincia":"Goias","pais":"BRASIL","latitud":-16.182056547391856,"longitud":-50.78388840098072,"ranking":0},
{"id":7715,"nombre":"Firminopolis","localidad":"Firminópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.574528565201515,"longitud":-50.30063009893407,"ranking":0},
{"id":7653,"nombre":"Formosa","localidad":"Formosa","provincia":"Goias","pais":"BRASIL","latitud":-15.5506717,"longitud":-47.337704,"ranking":0},
{"id":7774,"nombre":"Formoso","localidad":"Formoso","provincia":"Goias","pais":"BRASIL","latitud":-13.655886051269531,"longitud":-48.8840161359615,"ranking":0},
{"id":10377,"nombre":"Gameleira de Goias","localidad":"Gameleira de Goiás","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6315,"nombre":"Divinopolis","localidad":"Divinópolis de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-13.292257053953765,"longitud":-46.3971773230665,"ranking":0},
{"id":7814,"nombre":"Goianapolis","localidad":"Goianápolis","provincia":"Goias","pais":"BRASIL","latitud":-16.49793901467973,"longitud":-49.01397166048025,"ranking":0},
{"id":7655,"nombre":"Goianesia","localidad":"Goianésia","provincia":"Goias","pais":"BRASIL","latitud":-15.319878688668842,"longitud":-49.11715044698406,"ranking":0},
{"id":7775,"nombre":"Goianira","localidad":"Goianira","provincia":"Goias","pais":"BRASIL","latitud":-16.497417014692026,"longitud":-49.424800984794786,"ranking":0},
{"id":7780,"nombre":"Goias","localidad":"Goiás","provincia":"Goias","pais":"BRASIL","latitud":-15.943454261106789,"longitud":-50.14097789334109,"ranking":0},
{"id":6355,"nombre":"Goiatuba","localidad":"Goiatuba","provincia":"Goias","pais":"BRASIL","latitud":-18.01058662239188,"longitud":-49.3685310874227,"ranking":0},
{"id":14713,"nombre":"Guapo","localidad":"Guapó","provincia":"Goias","pais":"BRASIL","latitud":-16.839204026457395,"longitud":-49.53694738236942,"ranking":0},
{"id":8868,"nombre":"Hidrolandia","localidad":"Hidrolandia","provincia":"Goias","pais":"BRASIL","latitud":-16.961478274884065,"longitud":-49.23035859791896,"ranking":0},
{"id":6318,"nombre":"Iaciara","localidad":"Iaciara","provincia":"Goias","pais":"BRASIL","latitud":-14.105203795881575,"longitud":-46.632493375835814,"ranking":0},
{"id":6359,"nombre":"Indiara","localidad":"Indiara","provincia":"Goias","pais":"BRASIL","latitud":-17.142732298207715,"longitud":-17.142732298207715,"ranking":0},
{"id":7781,"nombre":"Inhumas","localidad":"Inhumas","provincia":"Goias","pais":"BRASIL","latitud":-16.373204756177113,"longitud":-49.491283530687106,"ranking":0},
{"id":6319,"nombre":"Ipameri ","localidad":"Ipameri","provincia":"Goias","pais":"BRASIL","latitud":-17.716597534372458,"longitud":-48.1624649799505,"ranking":0},
{"id":8821,"nombre":"Ipiranga de Goias","localidad":"Ipiranga de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-15.179702810019572,"longitud":-49.67451085652095,"ranking":0},
{"id":6360,"nombre":"Ipora","localidad":"Iporá","provincia":"Goias","pais":"BRASIL","latitud":-16.442348268025334,"longitud":-51.12425875861012,"ranking":0},
{"id":7784,"nombre":"Israelandia","localidad":"Israelândia","provincia":"Goias","pais":"BRASIL","latitud":-16.319180303979206,"longitud":-50.90742635683335,"ranking":0},
{"id":6361,"nombre":"Itaberai","localidad":"Itaberaí","provincia":"Goias","pais":"BRASIL","latitud":-16.0229124,"longitud":-49.8096707,"ranking":0},
{"id":6409,"nombre":"Itaja","localidad":"Itajá","provincia":"Goias","pais":"BRASIL","latitud":-19.06821524727515,"longitud":-51.55351073036665,"ranking":0},
{"id":8856,"nombre":"Itapaci","localidad":"Itapaci","provincia":"Goias","pais":"BRASIL","latitud":-14.9523104,"longitud":-49.5516383,"ranking":0},
{"id":7786,"nombre":"Itapirapua","localidad":"Itapirapuã","provincia":"Goias","pais":"BRASIL","latitud":-15.822983062930007,"longitud":-50.6088972701053,"ranking":0},
{"id":6363,"nombre":"Itapuranga","localidad":"Itapuranga","provincia":"Goias","pais":"BRASIL","latitud":-15.561258913964053,"longitud":-49.94431630766061,"ranking":0},
{"id":6414,"nombre":"Itaruma","localidad":"Itarumã","provincia":"Goias","pais":"BRASIL","latitud":-18.768576383968565,"longitud":-18.768576383968565,"ranking":0},
{"id":7787,"nombre":"Itaucu","localidad":"Itauçu","provincia":"Goias","pais":"BRASIL","latitud":-16.204564324744087,"longitud":-49.59926119729844,"ranking":0},
{"id":5024,"nombre":"Itumbiara","localidad":"Itumbiara","provincia":"Goias","pais":"BRASIL","latitud":-18.4030557,"longitud":-49.2114443,"ranking":0},
{"id":9518,"nombre":"Ivolandia","localidad":"Ivolândia","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6365,"nombre":"Jaragua","localidad":"Jaraguá","provincia":"Goias","pais":"BRASIL","latitud":-15.756656872061807,"longitud":-49.33003468449495,"ranking":0},
{"id":5593,"nombre":"Jatai","localidad":"Jataí","provincia":"Goias","pais":"BRASIL","latitud":-17.912339559655866,"longitud":-51.72289701968372,"ranking":0},
{"id":9519,"nombre":"Jaupaci","localidad":"Jaupaci","provincia":"Goias","pais":"BRASIL","latitud":-16.182131338081657,"longitud":-50.95265377147935,"ranking":0},
{"id":6367,"nombre":"Joviania","localidad":"Joviânia","provincia":"Goias","pais":"BRASIL","latitud":-17.80785642056486,"longitud":-49.61741487333417,"ranking":0},
{"id":7819,"nombre":"Jussara","localidad":"Jussara","provincia":"Goias","pais":"BRASIL","latitud":-15.860462144249269,"longitud":-50.86849652048704,"ranking":0},
{"id":10382,"nombre":"Leopoldo de Bulhoes","localidad":"Leopoldo de Bulhões","provincia":"Goias","pais":"BRASIL","latitud":-16.62217326004266,"longitud":-48.740966929271664,"ranking":0},
{"id":4967,"nombre":"Luziania","localidad":"Luziânia","provincia":"Goias","pais":"BRASIL","latitud":-16.258887984967895,"longitud":-47.95927403161545,"ranking":0},
{"id":6402,"nombre":"Mairipotaba","localidad":"Mairipotaba","provincia":"Goias","pais":"BRASIL","latitud":-17.296350134075595,"longitud":-49.492040130998234,"ranking":0},
{"id":7659,"nombre":"Mara Rosa","localidad":"Mara Rosa","provincia":"Goias","pais":"BRASIL","latitud":-14.017463049948892,"longitud":-49.171899329539286,"ranking":0},
{"id":6374,"nombre":"Marzagao","localidad":"Marzagão","provincia":"Goias","pais":"BRASIL","latitud":-17.98191090188995,"longitud":-48.638470754811394,"ranking":0},
{"id":14583,"nombre":"Matrincha","localidad":"Matrinchã","provincia":"Goias","pais":"BRASIL","latitud":-15.432911,"longitud":-50.745938,"ranking":0},
{"id":6375,"nombre":"Maurilandia ","localidad":"Maurilândia","provincia":"Goias","pais":"BRASIL","latitud":-17.97583906576088,"longitud":-50.338453805984734,"ranking":0},
{"id":5591,"nombre":"Mineiros","localidad":"Mineiros","provincia":"Goias","pais":"BRASIL","latitud":-17.56579396986362,"longitud":-52.561433435779186,"ranking":0},
{"id":6329,"nombre":"Monte Alegre de Goias","localidad":"Monte Alegre de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-13.258879147921515,"longitud":-46.88910616050227,"ranking":0},
{"id":9520,"nombre":"Montes Claros de Goias","localidad":"Montes Claros de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.007873229247544,"longitud":-51.400488198609445,"ranking":0},
{"id":7793,"nombre":"Montividiu do Norte","localidad":"Montividiu do Norte","provincia":"Goias","pais":"BRASIL","latitud":-13.347808603412702,"longitud":-48.68803055871664,"ranking":0},
{"id":6331,"nombre":"Morrinhos","localidad":"Morrinhos","provincia":"Goias","pais":"BRASIL","latitud":-17.734289863829673,"longitud":-49.1011862949776,"ranking":0},
{"id":15521,"nombre":"Mossamedes","localidad":"Mossâmedes","provincia":"Goias","pais":"BRASIL","latitud":-16.1268734,"longitud":-50.2132963,"ranking":0},
{"id":7794,"nombre":"Mozarlandia ","localidad":"Mozarlândia","provincia":"Goias","pais":"BRASIL","latitud":-14.745802663280612,"longitud":-50.569814602812556,"ranking":0},
{"id":7795,"nombre":"Mundo Novo","localidad":"Mundo Novo","provincia":"Goias","pais":"BRASIL","latitud":-13.775402427553445,"longitud":-50.27825537025169,"ranking":0},
{"id":7796,"nombre":"Mutunopolis","localidad":"Mutunópolis","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7797,"nombre":"Nazario ","localidad":"Nazário","provincia":"Goias","pais":"BRASIL","latitud":-16.58436625873929,"longitud":-49.88443039988644,"ranking":0},
{"id":7813,"nombre":"Neropolis ","localidad":"Neropolis","provincia":"Goias","pais":"BRASIL","latitud":-16.402058688822937,"longitud":-49.220291455478595,"ranking":0},
{"id":8824,"nombre":"Nova America","localidad":"Nova América","provincia":"Goias","pais":"BRASIL","latitud":-15.021039392924978,"longitud":-49.89734720003542,"ranking":0},
{"id":7798,"nombre":"Nova Crixas","localidad":"Nova Crixás","provincia":"Goias","pais":"BRASIL","latitud":-14.096267753673729,"longitud":-50.33566916574025,"ranking":0},
{"id":8859,"nombre":"Nova Gloria","localidad":"Nova Glória","provincia":"Goias","pais":"BRASIL","latitud":-15.1494553891332,"longitud":-49.57659486723342,"ranking":0},
{"id":7800,"nombre":"Novo Planalto","localidad":"Novo Planalto","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6403,"nombre":"Orizona","localidad":"Orizona","provincia":"Goias","pais":"BRASIL","latitud":-17.03376554485941,"longitud":-48.30132264832336,"ranking":0},
{"id":9521,"nombre":"Palestina de Goias","localidad":"Palestina de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.745922462642373,"longitud":-51.52860025326981,"ranking":0},
{"id":12355,"nombre":"Palmeiras de Goias","localidad":"Palmeiras de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.8072067,"longitud":-49.9180102,"ranking":0},
{"id":8822,"nombre":"Palminopolis","localidad":"Palminópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.7963629,"longitud":-50.1653096,"ranking":0},
{"id":10383,"nombre":"Panama","localidad":"Panamá","provincia":"Goias","pais":"BRASIL","latitud":-18.174481450524983,"longitud":-49.35289062990561,"ranking":0},
{"id":9495,"nombre":"Paranaiguara","localidad":"Paranaiguara","provincia":"Goias","pais":"BRASIL","latitud":-18.91233888923762,"longitud":-50.65248348973675,"ranking":0},
{"id":10378,"nombre":"Perolandia","localidad":"Perolândia","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8826,"nombre":"Petrolina de Goias ","localidad":"Petrolina de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.106235947010735,"longitud":-49.335120101066686,"ranking":0},
{"id":8860,"nombre":"Pilar de Goias","localidad":"Pilar de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-14.7611424,"longitud":-49.5808822,"ranking":0},
{"id":6377,"nombre":"Piracanjuba","localidad":"Piracanjuba","provincia":"Goias","pais":"BRASIL","latitud":-17.307461081411326,"longitud":-49.02535807595702,"ranking":0},
{"id":5597,"nombre":"Piranhas","localidad":"Piranhas","provincia":"Goias","pais":"BRASIL","latitud":-16.433844847582943,"longitud":-51.81716023793523,"ranking":0},
{"id":14897,"nombre":"Pirenopolis","localidad":"Pirenópolis","provincia":"Goias","pais":"BRASIL","latitud":-15.851581009860812,"longitud":-48.96350798745438,"ranking":0},
{"id":10905,"nombre":"Pires do Rio","localidad":"Pires do Rio","provincia":"Goias","pais":"BRASIL","latitud":-17.3073789,"longitud":-48.28234639999999,"ranking":0},
{"id":7656,"nombre":"Planaltina","localidad":"Planaltina","provincia":"Goias","pais":"BRASIL","latitud":-15.43488388639002,"longitud":-47.61086037260678,"ranking":0},
{"id":8823,"nombre":"Pontalina","localidad":"Pontalina","provincia":"Goias","pais":"BRASIL","latitud":-17.5159608,"longitud":-49.446014,"ranking":0},
{"id":6381,"nombre":"Porteirao","localidad":"Porteirão","provincia":"Goias","pais":"BRASIL","latitud":-17.816610570009257,"longitud":-50.162544954042886,"ranking":0},
{"id":9497,"nombre":"Portelandia","localidad":"Portelândia","provincia":"Goias","pais":"BRASIL","latitud":-17.357506393183453,"longitud":-52.67866082556697,"ranking":0},
{"id":6332,"nombre":"Posse","localidad":"Posse","provincia":"Goias","pais":"BRASIL","latitud":-14.086223373611315,"longitud":-46.36655686801211,"ranking":0},
{"id":8867,"nombre":"Professor Jamil","localidad":"Professor Jamil","provincia":"Goias","pais":"BRASIL","latitud":-17.25404301948619,"longitud":-49.2435844043252,"ranking":0},
{"id":6382,"nombre":"Quirinopolis","localidad":"Quirinópolis","provincia":"Goias","pais":"BRASIL","latitud":-18.45099791443178,"longitud":-50.448701083660126,"ranking":0},
{"id":6333,"nombre":"Rialma","localidad":"Rialma","provincia":"Goias","pais":"BRASIL","latitud":-15.316232270555716,"longitud":-49.58141654669624,"ranking":0},
{"id":7663,"nombre":"Rianapolis","localidad":"Rianápolis","provincia":"Goias","pais":"BRASIL","latitud":-15.447106172417234,"longitud":-49.5073840436244,"ranking":0},
{"id":10379,"nombre":"Rio Quente","localidad":"Rio Quente","provincia":"Goias","pais":"BRASIL","latitud":-17.77517141045419,"longitud":-48.74952949953027,"ranking":0},
{"id":6417,"nombre":"Rio Verde","localidad":"Rio Verde","provincia":"Goias","pais":"BRASIL","latitud":-17.799834064696547,"longitud":-50.943225985078634,"ranking":0},
{"id":8854,"nombre":"Rubiataba","localidad":"Rubiataba","provincia":"Goias","pais":"BRASIL","latitud":-15.1596902,"longitud":-49.7996079,"ranking":0},
{"id":12361,"nombre":"Santa Barbara de Goias","localidad":"Santa Bárbara de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.5769393,"longitud":-49.6972599,"ranking":0},
{"id":6396,"nombre":"Santa Helena de Goias","localidad":"Santa Helena de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-17.81966200800301,"longitud":-50.59593627424413,"ranking":0},
{"id":5559,"nombre":"Santa Rita do Araguaia","localidad":"Santa Rita do Araguaia","provincia":"Goias","pais":"BRASIL","latitud":-17.330022,"longitud":-53.1999068,"ranking":0},
{"id":7657,"nombre":"Santa Tereza de Goias ","localidad":"Santa Tereza de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-13.713621604374131,"longitud":-49.01876488618929,"ranking":0},
{"id":8863,"nombre":"Santa Terezinha de Goias","localidad":"Santa Terezinha de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-14.4348570596526,"longitud":-49.70571739802494,"ranking":0},
{"id":6335,"nombre":"Sao Domingos","localidad":"São Domingos","provincia":"Goias","pais":"BRASIL","latitud":-13.400991507650136,"longitud":-46.31233696927697,"ranking":0},
{"id":7821,"nombre":"Sao Francisco de Goias ","localidad":"São Francisco de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-15.935556907915306,"longitud":-49.258545287656545,"ranking":0},
{"id":10148,"nombre":"Sao Joao d'Alianca","localidad":"São João d'Aliança","provincia":"Goias","pais":"BRASIL","latitud":-14.708607936059378,"longitud":-47.52308838145728,"ranking":0},
{"id":12365,"nombre":"Sao Joao da Parauna","localidad":"São João da Paraúna","provincia":"Goias","pais":"BRASIL","latitud":-16.8200569,"longitud":-50.4071694,"ranking":0},
{"id":6394,"nombre":"Sao Luis de Montes Belos ","localidad":"São Luís de Montes Belos","provincia":"Goias","pais":"BRASIL","latitud":-16.52806057062726,"longitud":-50.37944240473087,"ranking":0},
{"id":7688,"nombre":"Sao Luiz do Norte","localidad":"São Luíz do Norte","provincia":"Goias","pais":"BRASIL","latitud":-14.862857945890186,"longitud":-49.32716945605648,"ranking":0},
{"id":7661,"nombre":"Sao Patricio","localidad":"São Patrício","provincia":"Goias","pais":"BRASIL","latitud":-15.347863180155077,"longitud":-49.814864836757785,"ranking":0},
{"id":6399,"nombre":"Sao Simao","localidad":"São Simão","provincia":"Goias","pais":"BRASIL","latitud":-18.995739960190907,"longitud":-50.548505953075335,"ranking":0},
{"id":9500,"nombre":"Serranopolis","localidad":"Serranópolis","provincia":"Goias","pais":"BRASIL","latitud":-18.307415438913317,"longitud":-51.957343488438376,"ranking":0},
{"id":6404,"nombre":"Silvania","localidad":"Silvânia","provincia":"Goias","pais":"BRASIL","latitud":-16.660433947195024,"longitud":-48.6101458720994,"ranking":0},
{"id":10173,"nombre":"Teresina de Goias","localidad":"Teresina de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-13.77627293022788,"longitud":-47.264204826272994,"ranking":0},
{"id":7809,"nombre":"Terezópolis de Goias","localidad":"Terezópolis de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.459871280324485,"longitud":-49.0605749171384,"ranking":0},
{"id":8825,"nombre":"Trindade ","localidad":"Trindade","provincia":"Goias","pais":"BRASIL","latitud":-16.65090966327341,"longitud":-49.48976016438742,"ranking":0},
{"id":7811,"nombre":"Trombas","localidad":"Trombas","provincia":"Goias","pais":"BRASIL","latitud":-13.505529840356667,"longitud":-48.742931145320554,"ranking":0},
{"id":7812,"nombre":"Turvania ","localidad":"Turvânia","provincia":"Goias","pais":"BRASIL","latitud":-16.610349889974586,"longitud":-50.13460717853723,"ranking":0},
{"id":8862,"nombre":"Uirapuru","localidad":"Uirapuru","provincia":"Goias","pais":"BRASIL","latitud":-14.283471358759268,"longitud":-49.91970929896888,"ranking":0},
{"id":10384,"nombre":"Urutai","localidad":"Urutaí","provincia":"Goias","pais":"BRASIL","latitud":-17.46092164247893,"longitud":-48.20361766944816,"ranking":0},
{"id":6337,"nombre":"Valparaiso","localidad":"Valparaíso de Goiás","provincia":"Goias","pais":"BRASIL","latitud":-16.07601768972245,"longitud":-47.986344954313005,"ranking":0},
{"id":6405,"nombre":"Vianopolis ","localidad":"Vianópolis","provincia":"Goias","pais":"BRASIL","latitud":-16.745400577572006,"longitud":-48.514292673262794,"ranking":0},
{"id":10385,"nombre":"Vicentinopolis","localidad":"Vicentinópolis","provincia":"Goias","pais":"BRASIL","latitud":-17.73168652565763,"longitud":-49.804001114995295,"ranking":0},
{"id":4892,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.260288,"longitud":-58.112147,"ranking":8},
{"id":4893,"nombre":"Acuña","localidad":"Acuña","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1640,"nombre":"Rotonda Lisandro Olmos","localidad":"Lisandro Olmos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.989827,"longitud":-58.042152,"ranking":0},
{"id":7783,"nombre":"Lisandro Olmos","localidad":"Lisandro Olmos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9234181,"longitud":-57.9691294,"ranking":0},
{"id":9809,"nombre":"Lisandro Olmos Acceso","localidad":"Lisandro Olmos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5169,"nombre":"Aparecida","localidad":"Aparecida","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.8420363,"longitud":-45.23102000000001,"ranking":8},
{"id":5018,"nombre":"Taguatinga","localidad":"Taguatinga","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.8355459,"longitud":-48.0827047,"ranking":0},
{"id":5031,"nombre":"Nova Almeida","localidad":"Nova Almeida","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.054232502513685,"longitud":-40.19749040210077,"ranking":0},
{"id":5036,"nombre":"Angualasto","localidad":"Angualasto","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.0553977,"longitud":-69.174257,"ranking":7},
{"id":5037,"nombre":"Iglesia","localidad":"Iglesia","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.411007,"longitud":-69.223762,"ranking":149},
{"id":5038,"nombre":"Las Flores","localidad":"Las Flores","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.322031,"longitud":-69.212639,"ranking":57},
{"id":5039,"nombre":"Rodeo","localidad":"Rodeo","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.213876,"longitud":-69.143537,"ranking":227},
{"id":5040,"nombre":"Tudcum","localidad":"Tudcum","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.189444,"longitud":-69.271345,"ranking":41},
{"id":5041,"nombre":"Santo Angelo","localidad":"Santo Angelo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.30553,"longitud":-54.27346,"ranking":0},
{"id":7952,"nombre":"Helvecia","localidad":"Helvecia","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.098701,"longitud":-60.085377,"ranking":4},
{"id":5042,"nombre":"Gonzalez Moreno","localidad":"Gonzalez Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.558102456337146,"longitud":-63.37968648211897,"ranking":0},
{"id":8504,"nombre":"Gonzalez Moreno Acceso","localidad":"Gonzalez Moreno","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.54652493544152,"longitud":-63.37998812879214,"ranking":0},
{"id":8087,"nombre":"Cochabamba","localidad":"Cochabamba","provincia":"Cochabamba","pais":"BOLIVIA","latitud":-17.402614038130785,"longitud":-66.1578987826057,"ranking":0},
{"id":5084,"nombre":"San Isidro","localidad":"San Isidro","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5086,"nombre":"Mogi Guacu","localidad":"Mogi Guacu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.372628570258232,"longitud":-46.94058812493585,"ranking":0},
{"id":5089,"nombre":"Mogi Mirim","localidad":"Mogi Mirim","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.448019657166153,"longitud":-46.98107957839966,"ranking":0},
{"id":5090,"nombre":"Eleuterio","localidad":"Eleuterio","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.32810107626611,"longitud":-46.72331946783933,"ranking":0},
{"id":5096,"nombre":"Sertaozinho","localidad":"Sertaozinho","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.14023336099559,"longitud":-47.993808648275916,"ranking":1},
{"id":5098,"nombre":"Olegario Maciel","localidad":"Olegário Maciel","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.340698032804973,"longitud":-45.58480692505601,"ranking":0},
{"id":6252,"nombre":"Entroncamento Olegario Maciel","localidad":"Olegário Maciel","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.290984518761977,"longitud":-44.7679237689862,"ranking":0},
{"id":5099,"nombre":"Brasilinha","localidad":"Brasilinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-16.211730957422457,"longitud":-44.431974773289454,"ranking":0},
{"id":5100,"nombre":"Porto do Sapucai","localidad":"Porto do Sapucaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.255550969729573,"longitud":-45.79863613782561,"ranking":0},
{"id":5110,"nombre":"Pocinhos","localidad":"Pocinhos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.936088965564,"longitud":-46.42437392351027,"ranking":0},
{"id":5118,"nombre":"La Paloma","localidad":"La Paloma","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.129591,"longitud":-54.611556,"ranking":23},
{"id":5120,"nombre":"San Alberto","localidad":"San Alberto","provincia":"Alto Parana","pais":"Paraguay","latitud":-24.969107,"longitud":-54.924026,"ranking":2},
{"id":5132,"nombre":"Salto del Guaira","localidad":"Salto del Guairá","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.067397,"longitud":-54.304099,"ranking":110},
{"id":5133,"nombre":"Hernandarias","localidad":"Hernandarias","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.405005,"longitud":-54.62977,"ranking":37},
{"id":5134,"nombre":"Furnas","localidad":"Furnas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.682874456568435,"longitud":-46.33437390100379,"ranking":0},
{"id":5140,"nombre":"Douradinho","localidad":"Douradinho","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.749125196346846,"longitud":-45.75806457239057,"ranking":0},
{"id":5143,"nombre":"Katuete","localidad":"Katuete","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.244239,"longitud":-54.756138,"ranking":8},
{"id":5146,"nombre":"Puente Kyha","localidad":"Puente Kyha","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.1,"longitud":-54.783333,"ranking":10},
{"id":5150,"nombre":"Nueva Esperanza","localidad":"Nueva Esperanza","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.504610384842266,"longitud":-54.8515399575222,"ranking":0},
{"id":14232,"nombre":"Troncal 4","localidad":"Nueva Esperanza","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.6335909,"longitud":-54.6365543,"ranking":11},
{"id":5154,"nombre":"Itakyry","localidad":"Itakyry","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.396753,"longitud":-54.639992,"ranking":0},
{"id":5207,"nombre":"Espraiado","localidad":"Espraiado","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.687185071283164,"longitud":-44.054694385987375,"ranking":0},
{"id":5208,"nombre":"Barra Grande","localidad":"Barra Grande","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.18980178628082,"longitud":-45.31978266876514,"ranking":0},
{"id":5209,"nombre":"Paulino Paixao","localidad":"Paulino Paixão","provincia":"Minas Gerais","pais":"BRASIL","latitud":-34.611100248809876,"longitud":-58.42884421348572,"ranking":0},
{"id":5211,"nombre":"Ravena Acceso","localidad":"Ravena","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5212,"nombre":"Nova Aparecida","localidad":"Nova Aparecida","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5213,"nombre":"Antonio Dos Santos","localidad":"Antonio Dos Santos","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.796454267607277,"longitud":-43.562081146474696,"ranking":0},
{"id":5214,"nombre":"Rocas Novas Acceso","localidad":"Rocas Novas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5221,"nombre":"Barra Feliz","localidad":"Barra Feliz","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.964093116677372,"longitud":-43.46086407829541,"ranking":0},
{"id":5224,"nombre":"Joao Monlevade Acceso","localidad":"Joao Monlevade","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.840788287450962,"longitud":-43.19783617744789,"ranking":0},
{"id":5235,"nombre":"Jurumirim","localidad":"Jurumirim","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.13876718460414,"longitud":-42.67999974402151,"ranking":0},
{"id":5242,"nombre":"Cruz Vera","localidad":"Cruz Vera","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.506184337347452,"longitud":-45.68890148489784,"ranking":0},
{"id":5261,"nombre":"Morro da Pascoa","localidad":"Morro da Pascoa","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5277,"nombre":"Rio das Velhas","localidad":"Rio das Velhas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5282,"nombre":"Lajes","localidad":"Lajes","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5284,"nombre":"Bandeirinha","localidad":"Bandeirinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.004452075210192,"longitud":-44.17001666635506,"ranking":0},
{"id":5287,"nombre":"Amarantina","localidad":"Amarantina","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.319550237140252,"longitud":-43.694527346882616,"ranking":0},
{"id":5288,"nombre":"Amarantina Acesso","localidad":"Amarantina","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5289,"nombre":"Cachoeira do Campo","localidad":"Cachoeira do Campo","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.352272871719563,"longitud":-43.665953127339236,"ranking":0},
{"id":5290,"nombre":"Rodrigo Silva","localidad":"Rodrigo Silva","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.425554744295447,"longitud":-43.556586877829425,"ranking":0},
{"id":5292,"nombre":"Sete Cachoeiras","localidad":"Sete Cachoeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5295,"nombre":"Esmeril","localidad":"Esmeril","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5325,"nombre":"Silva Xavier","localidad":"Silva Xavier","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5327,"nombre":"Lagoa Bonita","localidad":"Lagoa Bonita","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5329,"nombre":"Acesso Furquim","localidad":"Furquim","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5335,"nombre":"Acesso Vau Acu","localidad":"Vau Açu","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5359,"nombre":"Santa Filomena","localidad":"Santa Filomena","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5379,"nombre":"Mendanha","localidad":"Mendanha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.11490771732263,"longitud":-43.51944033178607,"ranking":0},
{"id":5394,"nombre":"Trinta Reis","localidad":"Trinta Réis ","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5396,"nombre":"Pedro Lessa ","localidad":"Pedro Lessa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.60592873796646,"longitud":-43.38962787011506,"ranking":0},
{"id":5399,"nombre":"Cruzinha","localidad":"Cruzinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.283211300100923,"longitud":-42.352866908167236,"ranking":0},
{"id":5400,"nombre":"Conselheiro Mata","localidad":"Conselheiro Mata","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5401,"nombre":"Rodeador","localidad":"Rodeador","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5403,"nombre":"Senhora da Gloria","localidad":"Senhora da Glória","provincia":"Minas Gerais","pais":"BRASIL","latitud":-10.212950459235781,"longitud":-37.41881502139389,"ranking":0},
{"id":5404,"nombre":"Valo Fundo","localidad":"Valo Fundo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5412,"nombre":"Barao Ataliba Nogueira","localidad":"Barão Ataliba Nogueira","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.397648156983784,"longitud":-46.75874393939701,"ranking":0},
{"id":8296,"nombre":"Parador Las Peñas","localidad":"Las Peñas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.56462039743441,"longitud":-64.00298094729806,"ranking":1},
{"id":8955,"nombre":"Las Peñas","localidad":"Las Peñas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.5646426,"longitud":-64.0031116,"ranking":1},
{"id":14460,"nombre":"Guaymallen","localidad":"Guaymallen","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8955,"longitud":-68.8296,"ranking":0},
{"id":14461,"nombre":"Guaymallen (Tarde)","localidad":"Guaymallen","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.8955,"longitud":-68.8296,"ranking":0},
{"id":5414,"nombre":"El Alto","localidad":"Pedro Domingo Murillo","provincia":"La Paz","pais":"BOLIVIA","latitud":-16.489930083739775,"longitud":-68.20819066488417,"ranking":0},
{"id":7619,"nombre":"Godoy Cruz","localidad":" Godoy Cruz","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5416,"nombre":"Mundo Novo","localidad":"Mundo Novo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5421,"nombre":"Santa Terezinha de Minas ","localidad":"Santa Terezinha de Minas ","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10647,"nombre":"Entrada Santa Terezinha","localidad":"Santa Terezinha de Minas ","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9464,"nombre":"San Antonio de Arredondo","localidad":"San Antonio de Arredondo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.484181,"longitud":-64.52810149999999,"ranking":2},
{"id":5425,"nombre":"Acesso Melo Viana","localidad":"Melo Viana","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5426,"nombre":"Doutor Campolina","localidad":"Doutor Campolina","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5431,"nombre":"San Juan","localidad":"San Juan","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5454,"nombre":"Ramon Santos","localidad":"Ramon Santos","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5456,"nombre":"Skanka Charter","localidad":"Tres Picos","provincia":"Chubut","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5459,"nombre":"Futaleufu","localidad":"Futaleufu","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-43.17962855754019,"longitud":-71.86639735516928,"ranking":0},
{"id":5464,"nombre":"Tabay","localidad":"Tabay","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.3180164,"longitud":-58.28352690000001,"ranking":9},
{"id":5466,"nombre":"Colonia Oliveros","localidad":"Oliveros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8043,"nombre":"Oliveros","localidad":"Oliveros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5490,"nombre":"Barda Castillo","localidad":"Paso Barda","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5506,"nombre":"Iguazu","localidad":"Iguazu","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":5511,"nombre":"Tranquera","localidad":"Tranquera","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7825,"nombre":"San Carlos Centro","localidad":"San Carlos Centro","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.743789001589928,"longitud":-61.097172290271274,"ranking":0},
{"id":5582,"nombre":"Tres Lagoas","localidad":"Tres Lagoas","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.774595030294396,"longitud":-51.6984061467419,"ranking":0},
{"id":1553,"nombre":"El Tio","localidad":"El Tio","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.383993,"longitud":-62.828239,"ranking":10},
{"id":5725,"nombre":"Barroso","localidad":"Barroso","provincia":"Ceará","pais":"BRASIL","latitud":-3.813128236646621,"longitud":-38.50110573025988,"ranking":0},
{"id":5667,"nombre":"Timbo","localidad":"Timbó","provincia":"Ceará","pais":"BRASIL","latitud":-3.8806460226305854,"longitud":-38.60384086360696,"ranking":0},
{"id":5674,"nombre":"Barrento","localidad":"Barrento","provincia":"Ceará","pais":"BRASIL","latitud":-3.3112974888353635,"longitud":-39.51386656906224,"ranking":0},
{"id":5677,"nombre":"Baleia","localidad":"Baleia","provincia":"Ceará","pais":"BRASIL","latitud":-3.1488973753928615,"longitud":-39.440209383789245,"ranking":0},
{"id":5678,"nombre":"Almofala","localidad":"Almofala","provincia":"Ceará","pais":"BRASIL","latitud":-2.937698357162438,"longitud":-39.83248151131556,"ranking":0},
{"id":5762,"nombre":"Antonio Diogo","localidad":"Antônio Diogo","provincia":"Ceará","pais":"BRASIL","latitud":-4.314554715438174,"longitud":-38.74253454394092,"ranking":0},
{"id":5680,"nombre":"Oriente","localidad":"Oriente","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5681,"nombre":"Curralinho","localidad":"Curralinho","provincia":"Ceará","pais":"BRASIL","latitud":-4.695466583334604,"longitud":-38.40249150128249,"ranking":0},
{"id":5684,"nombre":"Marinheiros","localidad":"Marinheiros","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5685,"nombre":"Caio Prado","localidad":"Caio Prado","provincia":"Ceará","pais":"BRASIL","latitud":-4.6546414789144475,"longitud":-38.94330939289371,"ranking":0},
{"id":5688,"nombre":"Aracatiara","localidad":"Aracatiara","provincia":"Ceará","pais":"BRASIL","latitud":-2.8861126009552818,"longitud":-40.118577860363644,"ranking":0},
{"id":5687,"nombre":"Susto","localidad":"Susto","provincia":"Ceará","pais":"BRASIL","latitud":-4.340761996592654,"longitud":-38.72000195244792,"ranking":0},
{"id":5690,"nombre":"Ideal","localidad":"Ideal","provincia":"Ceará","pais":"BRASIL","latitud":-4.428515190075009,"longitud":-38.6717720683351,"ranking":0},
{"id":5689,"nombre":"Icarai","localidad":"Icaraí","provincia":"Ceará","pais":"BRASIL","latitud":-3.028214168090489,"longitud":-39.65023203917462,"ranking":0},
{"id":5691,"nombre":"Moitas","localidad":"Moitas","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5694,"nombre":"Patriarca","localidad":"Patriarca","provincia":"Ceará","pais":"BRASIL","latitud":-3.593116643784366,"longitud":-40.27423244802702,"ranking":0},
{"id":5693,"nombre":"Ladeira Grande ","localidad":"Ladeira Grande ","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5695,"nombre":"Boa Vista Castelao","localidad":"Boa Vista Castelão","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5696,"nombre":"Flecheiras ","localidad":"Flecheiras ","provincia":"Ceará","pais":"BRASIL","latitud":-3.2235617313539056,"longitud":-39.26997064398994,"ranking":0},
{"id":5697,"nombre":"Cachoeira","localidad":"Cachoeira","provincia":"Ceará","pais":"BRASIL","latitud":-4.069601913004456,"longitud":-38.75891127060728,"ranking":0},
{"id":5698,"nombre":"Parazinho","localidad":"Parazinho","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5704,"nombre":"Juritianha","localidad":"Juritianha","provincia":"Ceará","pais":"BRASIL","latitud":-2.9006389422722507,"longitud":-40.00295580024539,"ranking":0},
{"id":5711,"nombre":"Lagoinha","localidad":"Lagoinha","provincia":"Ceará","pais":"BRASIL","latitud":-23.08987254238696,"longitud":-45.19010729600897,"ranking":0},
{"id":5705,"nombre":"Volta do Rio","localidad":"Volta do Rio","provincia":"Ceará","pais":"BRASIL","latitud":-4.170700620777039,"longitud":-38.86881797264864,"ranking":0},
{"id":5712,"nombre":"Canudos","localidad":"Canudos","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5716,"nombre":"Nascente","localidad":"Nascente","provincia":"Ceará","pais":"BRASIL","latitud":-3.2749352163724437,"longitud":-39.905508730868405,"ranking":0},
{"id":5724,"nombre":"Pecem","localidad":"Pecem","provincia":"Ceará","pais":"BRASIL","latitud":-3.5501216137509783,"longitud":-38.81428688774597,"ranking":0},
{"id":5727,"nombre":"Cemoaba","localidad":"Cemoaba","provincia":"Ceará","pais":"BRASIL","latitud":-3.4661154045564744,"longitud":-39.42101811277558,"ranking":0},
{"id":5731,"nombre":"Torroes","localidad":"Torrões","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5732,"nombre":"Aranau","localidad":"Aranaú","provincia":"Ceará","pais":"BRASIL","latitud":-2.822230392253703,"longitud":-40.22522396341262,"ranking":0},
{"id":5733,"nombre":"Prea","localidad":"Preá","provincia":"Ceará","pais":"BRASIL","latitud":-2.8214104870504046,"longitud":-40.413496384653534,"ranking":0},
{"id":5734,"nombre":"Carvoeiro","localidad":"Carvoeiro","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5735,"nombre":"Sitio Alegre","localidad":"Sítio Alegre ","provincia":"Ceará","pais":"BRASIL","latitud":-3.3159466890077436,"longitud":-40.01953870992679,"ranking":0},
{"id":5738,"nombre":"Gualdrapas","localidad":"Gualdrapas","provincia":"Ceará","pais":"BRASIL","latitud":-3.4043522344775257,"longitud":-39.294587456775524,"ranking":0},
{"id":5740,"nombre":"Canaan","localidad":"Canaan","provincia":"Ceará","pais":"BRASIL","latitud":-3.21668350144263,"longitud":-39.359852636401286,"ranking":0},
{"id":5741,"nombre":"Guajiru","localidad":"Guajiru","provincia":"Ceará","pais":"BRASIL","latitud":-3.2370710426714693,"longitud":-39.24359880352142,"ranking":0},
{"id":5742,"nombre":"Mundau","localidad":"Mundaú","provincia":"Ceará","pais":"BRASIL","latitud":-3.180178435402332,"longitud":-39.373123153905944,"ranking":0},
{"id":5750,"nombre":"Mineirolandia","localidad":"Mineirolândia","provincia":"Ceará","pais":"BRASIL","latitud":-5.52576880504222,"longitud":-39.599774828336884,"ranking":0},
{"id":5755,"nombre":"Piranji","localidad":"Piranji","provincia":"Ceará","pais":"BRASIL","latitud":-4.694735385935906,"longitud":-38.698321470167194,"ranking":0},
{"id":5757,"nombre":"Juatama","localidad":"Juatama","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5758,"nombre":"Zorra","localidad":"Zorra","provincia":"Ceará","pais":"BRASIL","latitud":-5.870291131497053,"longitud":-39.5552634191111,"ranking":0},
{"id":5759,"nombre":"Cangati","localidad":"Cangati","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5760,"nombre":"Uruque","localidad":"Uruquê","provincia":"Ceará","pais":"BRASIL","latitud":-5.138273294828604,"longitud":-39.1812269338642,"ranking":0},
{"id":5761,"nombre":"Oiticica","localidad":"Oiticica","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5781,"nombre":"Boa Esperanca","localidad":"Boa Esperança","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.095612041924433,"longitud":-45.56164184897332,"ranking":0},
{"id":5787,"nombre":"Cambiasca","localidad":"Cambiasca","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.715404899586655,"longitud":-41.92564236498791,"ranking":0},
{"id":5789,"nombre":"Campo Grande","localidad":"Campo Grande","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.901561180262455,"longitud":-43.555081247415345,"ranking":1},
{"id":5791,"nombre":"Campos Novos","localidad":"Campos Novos","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.708434870109564,"longitud":-42.03252673294304,"ranking":0},
{"id":5798,"nombre":"Euclidelandia","localidad":"Euclidelândia","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5799,"nombre":"Farol de Sao Thome","localidad":"Farol de São Thomé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.04095921188451,"longitud":-41.05278063943771,"ranking":0},
{"id":5803,"nombre":"Jacone","localidad":"Jaconé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.934444754139793,"longitud":-42.628376086525236,"ranking":0},
{"id":5805,"nombre":"Jaguarembe","localidad":"Jaguarembé","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.728164231129014,"longitud":-41.99302046725546,"ranking":0},
{"id":5809,"nombre":"Laranjais","localidad":"Laranjais","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.75710658895879,"longitud":-42.16882276279666,"ranking":0},
{"id":5820,"nombre":"Manoel Ribeiro","localidad":"Manoel Ribeiro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.900537646244434,"longitud":-42.71138709994752,"ranking":0},
{"id":5831,"nombre":"Morro do Coco","localidad":"Morro do Coco","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.376388377379463,"longitud":-41.34554640544975,"ranking":0},
{"id":5855,"nombre":"Palmital","localidad":"Palmital","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.78784814469643,"longitud":-50.213106966741236,"ranking":0},
{"id":5857,"nombre":"Peclas","localidad":"Peclas","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5863,"nombre":"Pracinha","localidad":"Pracinha","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5864,"nombre":"Praia Seca","localidad":"Praia Seca","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5865,"nombre":"Pureza","localidad":"Pureza","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.587802594138243,"longitud":-21.587802594138243,"ranking":0},
{"id":5869,"nombre":"Raposo","localidad":"Raposo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.102010776266525,"longitud":-42.11585095583937,"ranking":0},
{"id":5877,"nombre":"Santo Eduardo","localidad":"Santo Eduardo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.225041613819535,"longitud":-21.225041613819535,"ranking":0},
{"id":5881,"nombre":"Jardim Bonifacio","localidad":"Jardim Bonifacio","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5892,"nombre":"Sao Joao do Paraiso ","localidad":"São João do Paraíso ","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.414769748374965,"longitud":-41.81337319713386,"ranking":0},
{"id":5894,"nombre":"Agua Sumida","localidad":"Água Sumida","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5897,"nombre":"Sao Jose do Ribeirao","localidad":"São José do Ribeirão","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.204287893000725,"longitud":-42.397599172421536,"ranking":0},
{"id":5899,"nombre":"Sao Martinha","localidad":"São Martinho","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5903,"nombre":"Sao Vicente de Paula","localidad":"São Vicente de Paula","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.724211341025946,"longitud":-42.25834616898124,"ranking":0},
{"id":5916,"nombre":"Serrinha","localidad":"Serrinha","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-11.669689257398977,"longitud":-39.00458808478456,"ranking":0},
{"id":5918,"nombre":"Trajano De Morais","localidad":"Trajano De Morais","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.06392322528547,"longitud":-42.06016162412848,"ranking":0},
{"id":5926,"nombre":"Morro Azul","localidad":"Morro Azul","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4758629766916,"longitud":-43.581994143360696,"ranking":0},
{"id":5933,"nombre":"Avelar","localidad":"Avelar","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.321355179573427,"longitud":-43.40393417835482,"ranking":0},
{"id":5936,"nombre":"Espigao","localidad":"Espigão","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.19455130669315,"longitud":-51.35446511612507,"ranking":0},
{"id":5947,"nombre":"Vila Operaria","localidad":"Vila Operária","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5949,"nombre":"Florinea","localidad":"Florínea","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.90070293535689,"longitud":-50.728113068600315,"ranking":0},
{"id":5953,"nombre":"Passa Tres","localidad":"Passa Três","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.696369247033918,"longitud":-44.00250452894197,"ranking":0},
{"id":5955,"nombre":"Frade","localidad":"Frade","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.959204882132216,"longitud":-44.43768122894197,"ranking":0},
{"id":5965,"nombre":"Gavea","localidad":"Gávea","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.97937411907488,"longitud":-43.231354835905925,"ranking":0},
{"id":5966,"nombre":"Madureira","localidad":"Madureira","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.877588166371414,"longitud":-43.33687905792152,"ranking":0},
{"id":5969,"nombre":"Centro","localidad":"Centro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.905496985325,"longitud":-43.17499177784732,"ranking":0},
{"id":5971,"nombre":"Idaiatuba","localidad":"Idaiatuba","provincia":"Sao Gabriel","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5972,"nombre":"Estacio","localidad":"Estácio","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.914272159096456,"longitud":-43.20623239056866,"ranking":0},
{"id":5973,"nombre":"Bonsucesso","localidad":"Bonsucesso","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.862243262035854,"longitud":-43.250539150329345,"ranking":0},
{"id":5977,"nombre":"Werneck","localidad":"Werneck","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.220072390715412,"longitud":-43.31584718476428,"ranking":0},
{"id":5983,"nombre":"Julio de Castilhos","localidad":"Júlio de Castilhos","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.213496303269167,"longitud":-53.68557567462296,"ranking":0},
{"id":5989,"nombre":"Alto da Serra","localidad":"Alto da Serra","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":5992,"nombre":"Armacao","localidad":"Armação","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.75078558133143,"longitud":-48.50991620438864,"ranking":0},
{"id":6010,"nombre":"Indios","localidad":"Índios","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.77297358898242,"longitud":-50.19825437378512,"ranking":0},
{"id":6029,"nombre":"Picarras","localidad":"Piçarras","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.77841097365782,"longitud":-48.684178531187,"ranking":0},
{"id":6031,"nombre":"Poco Grande","localidad":"Poço Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.92058444107244,"longitud":-26.92058444107244,"ranking":0},
{"id":6036,"nombre":"Rancho Grande","localidad":"Rancho Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.598824607096333,"longitud":-50.225556582502946,"ranking":0},
{"id":6038,"nombre":"Rio Bonito","localidad":"Rio Bonito","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.54533006814037,"longitud":-49.3350351270111,"ranking":0},
{"id":6046,"nombre":"Sao Cristovao","localidad":"São Cristóvão","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.150945552149288,"longitud":-50.3659887323427,"ranking":0},
{"id":6051,"nombre":"Serril","localidad":"Serril ","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.382633571538573,"longitud":-49.94647427803605,"ranking":0},
{"id":6052,"nombre":"Subida","localidad":"Subida","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.099220213970074,"longitud":-27.099220213970074,"ranking":0},
{"id":6060,"nombre":"Volta Grande","localidad":"Volta Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":-21.774437848384956,"longitud":-42.53414674427738,"ranking":0},
{"id":6061,"nombre":"Barracao","localidad":"Barracão","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.25670087080245,"longitud":-53.62833919096945,"ranking":0},
{"id":6074,"nombre":"Babilonia","localidad":"Babilônia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.50147640204867,"longitud":-46.637279632694536,"ranking":0},
{"id":6087,"nombre":"Honoropolis","localidad":"Honorópolis","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.56493054412097,"longitud":-50.013891566645285,"ranking":0},
{"id":6092,"nombre":"Miraporanga","localidad":"Miraporanga","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.987361041370416,"longitud":-48.37415688685582,"ranking":0},
{"id":6098,"nombre":"Jurumirim","localidad":"Jurumirim","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6100,"nombre":"Acioli","localidad":"Acioli ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.648661299535707,"longitud":-40.46260829839628,"ranking":0},
{"id":6104,"nombre":"Agua Boa","localidad":"Água Boa ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.760835624644997,"longitud":-40.29276150640505,"ranking":0},
{"id":6107,"nombre":"Agua Limpa","localidad":"Água Limpa","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.867639688070298,"longitud":-40.67168436527906,"ranking":0},
{"id":6111,"nombre":"Arace","localidad":"Aracê","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.393304122138595,"longitud":-41.00385461290553,"ranking":0},
{"id":6113,"nombre":"Araguaia","localidad":"Araguaia","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.492478919916042,"longitud":-40.771926153600056,"ranking":0},
{"id":6116,"nombre":"Barra do Riacho","localidad":"Barra do Riacho","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.827114901207448,"longitud":-40.060696008708014,"ranking":0},
{"id":6121,"nombre":"Valparaiso","localidad":"Valparaíso","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.60403465765292,"longitud":-46.519991871794616,"ranking":0},
{"id":6124,"nombre":"Xapetuba","localidad":"Xapetuba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.86424696069021,"longitud":-48.585563267438665,"ranking":0},
{"id":6128,"nombre":"Bela Vista","localidad":"Bela Vista","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6134,"nombre":"Boa Sorte","localidad":"Boa Sorte","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6135,"nombre":"Barbosas","localidad":"Barbosas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6136,"nombre":"Boa Vista","localidad":"Boa Vista","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.27285578128886,"longitud":-40.3008160055699,"ranking":0},
{"id":6141,"nombre":"Braco do Rio","localidad":"Braço do Rio","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.43169288975379,"longitud":-39.93354508785451,"ranking":0},
{"id":6143,"nombre":"Bicuiba","localidad":"Bicuíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.086375828336145,"longitud":-42.35610561973652,"ranking":0},
{"id":6144,"nombre":"Planalto do Sul","localidad":"Planalto do Sul","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.315509599992737,"longitud":-52.24611719978919,"ranking":0},
{"id":6145,"nombre":"Biguatinga","localidad":"Biguatinga","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6146,"nombre":"Boa Familia","localidad":"Boa Familia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.203949185869817,"longitud":-42.508859205549406,"ranking":0},
{"id":6147,"nombre":"Boa Ventura","localidad":"Boa Ventura","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.633250065119913,"longitud":-43.33368718299467,"ranking":0},
{"id":6148,"nombre":"Bom Jesus da Cachoeira","localidad":"Bom Jesus da Cachoeira","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.25891703868747,"longitud":-42.404198906855115,"ranking":0},
{"id":6150,"nombre":"Brumado","localidad":"Brumado","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6154,"nombre":"Ponte Branca","localidad":"Ponte Branca","provincia":"Sao Paulo","pais":"BRASIL","latitud":-16.768365472220296,"longitud":-52.834904028405056,"ranking":0},
{"id":6155,"nombre":"Ponto Alegre","localidad":"Ponto Alegre","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6156,"nombre":"Coqueiral","localidad":"Coqueiral","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.93064256820098,"longitud":-40.14622501727725,"ranking":0},
{"id":6157,"nombre":"Cristal","localidad":"Cristal","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6159,"nombre":"Acesso Paraju","localidad":"Paraju ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.316959051133338,"longitud":-40.803818030549905,"ranking":0},
{"id":6208,"nombre":"Paraju","localidad":"Paraju ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.31865361061608,"longitud":-40.80494642894197,"ranking":0},
{"id":6164,"nombre":"Primavera","localidad":"Primavera","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6167,"nombre":"Acesso Fartura","localidad":"Fartura","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.910052016472093,"longitud":-40.45729085075209,"ranking":0},
{"id":6170,"nombre":"Acesso Guararema","localidad":"Guararema","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6176,"nombre":"Guarani","localidad":"Guarani","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6181,"nombre":"Igrejinha","localidad":"Igrejinha","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6183,"nombre":"Itamira","localidad":"Itamira","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.253719572371587,"longitud":-40.513205671270896,"ranking":0},
{"id":6184,"nombre":"Itauninhas ","localidad":"Itauninhas ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.48910283282489,"longitud":-40.08885829893083,"ranking":0},
{"id":6185,"nombre":"Jacupemba","localidad":"Jacupemba","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.58534969664479,"longitud":-40.190139880961524,"ranking":0},
{"id":6188,"nombre":"Lajinha ","localidad":"Lajinha ","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6192,"nombre":"Corrego do Ouro","localidad":"Córrego do Ouro","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6195,"nombre":"Mascarenhas","localidad":"Mascarenhas","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.50574535524464,"longitud":-40.913717652120916,"ranking":0},
{"id":6196,"nombre":"Matilde","localidad":"Matilde","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.55404978652098,"longitud":-40.81511557143391,"ranking":0},
{"id":6203,"nombre":"Nestor Gomes","localidad":"Nestor Gomes","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.729013569127638,"longitud":-18.729013569127638,"ranking":0},
{"id":6209,"nombre":"Descarocador","localidad":"Descaroçador","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.175272099689142,"longitud":-21.175272099689142,"ranking":0},
{"id":6213,"nombre":"Pendanga ","localidad":"Pendanga ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.868107002895734,"longitud":-40.40542573185874,"ranking":0},
{"id":6215,"nombre":"Pequia","localidad":"Pequiá ","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6217,"nombre":"Piacu ","localidad":"Piaçu ","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.3397786530899,"longitud":-41.39295908041307,"ranking":0},
{"id":6221,"nombre":"Piracema ","localidad":"Piracema ","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6224,"nombre":"Timbo","localidad":"Timbó","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.277831389360845,"longitud":-51.505118895016246,"ranking":0},
{"id":6226,"nombre":"Ponto Alto","localidad":"Ponto Alto","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.28891293881319,"longitud":-40.81771340442757,"ranking":0},
{"id":6233,"nombre":"Rio Fundo","localidad":"Rio Fundo","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6236,"nombre":"Rio Preto","localidad":"Rio Preto","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6238,"nombre":"Entroncamento Itaim","localidad":"Itaim","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6247,"nombre":"Entroncamento Macuco","localidad":"Macuco","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.578814665031448,"longitud":-42.58366120420544,"ranking":0},
{"id":6249,"nombre":"Entroncamento Morro do Ferros","localidad":"Morro do Ferros","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6302,"nombre":"Morro do Ferro","localidad":"Morro do Ferros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.771027958228018,"longitud":-44.57917756300599,"ranking":0},
{"id":6250,"nombre":"Sayonara","localidad":"Sayonara","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.4787543083331,"longitud":-39.92460048869127,"ranking":0},
{"id":6259,"nombre":"Timbui","localidad":"Timbuí ","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6261,"nombre":"Entroncamento Tebas","localidad":"Tebas","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6262,"nombre":"Tres Barras","localidad":"Tres Barras","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6266,"nombre":"Terra Preta","localidad":"Terra Preta","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.25861855389642,"longitud":-46.5938737855013,"ranking":0},
{"id":6269,"nombre":"Vila Paulista","localidad":"Vila Paulista","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.66753780347675,"longitud":-40.79995450744923,"ranking":0},
{"id":6275,"nombre":"Vinhatico ","localidad":"Vinhático","provincia":"Espirito Santo","pais":"BRASIL","latitud":-18.19675327168911,"longitud":-40.265739332294864,"ranking":0},
{"id":6280,"nombre":"Luanda","localidad":"Luanda","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6299,"nombre":"Mata do Sino","localidad":"Mata do Sino","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6301,"nombre":"Mococa","localidad":"Mococa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.464895273499494,"longitud":-47.00024180619334,"ranking":0},
{"id":6322,"nombre":"Acarai","localidad":"Acaraí","provincia":"Bahia","pais":"BRASIL","latitud":-13.970653492864384,"longitud":-39.13515551097521,"ranking":0},
{"id":6323,"nombre":"Agua Comprida","localidad":"Água Comprida","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6324,"nombre":"Aguada Nova","localidad":"Aguada Nova","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6343,"nombre":"Brejo Bonito","localidad":"Brejo Bonito","provincia":"Goias","pais":"BRASIL","latitud":-18.150822196833904,"longitud":-49.65765304339882,"ranking":0},
{"id":6354,"nombre":"Fazenda Panama","localidad":"Fazenda Panama","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6362,"nombre":"Argolo","localidad":"Argolo","provincia":"Bahia","pais":"BRASIL","latitud":-17.897793454359864,"longitud":-40.01967861688442,"ranking":0},
{"id":6366,"nombre":"Bambu","localidad":"Bambu","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6368,"nombre":"Bananeira","localidad":"Bananeira","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6371,"nombre":"Marcelandia ","localidad":"Marcelândia ","provincia":"Goias","pais":"BRASIL","latitud":-17.722819785330977,"longitud":-48.90900229169359,"ranking":0},
{"id":6373,"nombre":"Bom Despacho","localidad":"Bom Despacho","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":1},
{"id":6376,"nombre":"Bonita","localidad":"Bonita","provincia":"Bahia","pais":"BRASIL","latitud":-12.441688905807315,"longitud":-40.11228058712834,"ranking":0},
{"id":6378,"nombre":"Bravo","localidad":"Bravo","provincia":"Bahia","pais":"BRASIL","latitud":-12.155293469202075,"longitud":-39.4579808092966,"ranking":0},
{"id":6387,"nombre":"Candido Sales","localidad":"Candido Sales","provincia":"Bahia","pais":"BRASIL","latitud":-15.501391620441229,"longitud":-41.235983596102635,"ranking":0},
{"id":6389,"nombre":"Cascavel","localidad":"Cascavel","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6392,"nombre":"Colonia","localidad":"Colônia","provincia":"Bahia","pais":"BRASIL","latitud":-15.262957978412935,"longitud":-39.14590751715237,"ranking":0},
{"id":6393,"nombre":"Cutia","localidad":"Cutia","provincia":"Bahia","pais":"BRASIL","latitud":-12.806450548290936,"longitud":-41.797574235442035,"ranking":0},
{"id":6398,"nombre":"Gameleira","localidad":"Gameleira","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6407,"nombre":"Genipapo","localidad":"Genipapo","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6410,"nombre":"Graciosa","localidad":"Graciosa","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6411,"nombre":"Guaibim","localidad":"Guaibim","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6423,"nombre":"Algodoes ","localidad":"Algodões","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.31701795992853,"longitud":-37.35165757262389,"ranking":0},
{"id":6424,"nombre":"Ico","localidad":"Icó","provincia":"Bahia","pais":"BRASIL","latitud":-6.406318604913439,"longitud":-38.8640401349896,"ranking":0},
{"id":6429,"nombre":"Bom Nome","localidad":"Bom Nome","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6435,"nombre":"Itabatan","localidad":"Itabatan","provincia":"Bahia","pais":"BRASIL","latitud":-18.008519371863134,"longitud":-39.86196963820202,"ranking":0},
{"id":6441,"nombre":"Jutai","localidad":"Jutaí","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.635280417959908,"longitud":-40.24029684083624,"ranking":0},
{"id":6444,"nombre":"Itaia","localidad":"Itaiá","provincia":"Bahia","pais":"BRASIL","latitud":-14.878630284172235,"longitud":-39.914852508618864,"ranking":0},
{"id":6456,"nombre":"Itatingui","localidad":"Itatingui ","provincia":"Bahia","pais":"BRASIL","latitud":-15.196619031742022,"longitud":-39.44107996709259,"ranking":0},
{"id":6457,"nombre":"Itatiaia ","localidad":"Itatiaia ","provincia":"Bahia","pais":"BRASIL","latitud":-11.425551114792558,"longitud":-39.887521157094355,"ranking":0},
{"id":6460,"nombre":"Itamarati","localidad":"Itamarati","provincia":"Bahia","pais":"BRASIL","latitud":-14.017122801931215,"longitud":-39.43283669478871,"ranking":0},
{"id":8503,"nombre":"La Leonesa Acceso","localidad":"La Leonesa","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.978969570577704,"longitud":-58.864723410993626,"ranking":0},
{"id":6463,"nombre":"Timorante","localidad":"Timorante","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.680218589793996,"longitud":-39.800494365226946,"ranking":0},
{"id":6466,"nombre":"Joao Amaro","localidad":"João Amaro","provincia":"Bahia","pais":"BRASIL","latitud":-12.797812827474967,"longitud":-40.35203371580542,"ranking":0},
{"id":6468,"nombre":"Lagoa Nova","localidad":"Lagoa Nova","provincia":"Bahia","pais":"BRASIL","latitud":-11.86245000412483,"longitud":-41.73420991630866,"ranking":0},
{"id":6475,"nombre":"Manguinho","localidad":"Manguinho","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6481,"nombre":"Moenda ","localidad":"Moenda ","provincia":"Bahia","pais":"BRASIL","latitud":-13.392930703729556,"longitud":-39.40048530090034,"ranking":0},
{"id":6484,"nombre":"Albuquerque Ne","localidad":"Albuquerque Né","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.002586402339489,"longitud":-37.41630480418324,"ranking":0},
{"id":6485,"nombre":"Algodao","localidad":"Algodão","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.894539419614587,"longitud":-36.18087402815648,"ranking":0},
{"id":6493,"nombre":"Caraibas","localidad":"Caraíbas ","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.635071100385632,"longitud":-39.708944653614296,"ranking":0},
{"id":6495,"nombre":"Carice","localidad":"Caricé ","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.5296385557562155,"longitud":-35.08518672491295,"ranking":0},
{"id":6502,"nombre":"Carneiro","localidad":"Carneiro","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.534792449393304,"longitud":-37.12546621551901,"ranking":0},
{"id":6500,"nombre":"Nova Vista","localidad":"Nova Vista","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6501,"nombre":"Olivenca","localidad":"Olivença","provincia":"Bahia","pais":"BRASIL","latitud":-14.950840867844775,"longitud":-39.00945723210086,"ranking":0},
{"id":6505,"nombre":"Paraguacu","localidad":"Paraguaçu","provincia":"Bahia","pais":"BRASIL","latitud":-21.54966702843589,"longitud":-45.73667250563733,"ranking":0},
{"id":6507,"nombre":"Grosso","localidad":"Grosso","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.525673122431284,"longitud":-37.282044266566196,"ranking":0},
{"id":6512,"nombre":"Ibo","localidad":"Ibó","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.620771674931687,"longitud":-8.620771674931687,"ranking":0},
{"id":6517,"nombre":"Posto da Mata","localidad":"Posto da Mata","provincia":"Bahia","pais":"BRASIL","latitud":-17.884343611052802,"longitud":-39.847768500917006,"ranking":0},
{"id":14788,"nombre":"Rio da Barra","localidad":"Rio da Barra","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.1667217,"longitud":-37.4674,"ranking":0},
{"id":6529,"nombre":"Sao Caetano","localidad":"São Caetano","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.323497886367159,"longitud":-36.13660578343242,"ranking":0},
{"id":6531,"nombre":"Rancho Alegre","localidad":"Rancho Alegre","provincia":"Bahia","pais":"BRASIL","latitud":-17.72630661196506,"longitud":-39.766945927525846,"ranking":0},
{"id":6533,"nombre":"Ribeirao","localidad":"Ribeirão","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6534,"nombre":"Rio Branco ","localidad":"Rio Branco","provincia":"Bahia","pais":"BRASIL","latitud":-15.253192480027423,"longitud":-39.44417296901284,"ranking":0},
{"id":6537,"nombre":"Rio do Meio ","localidad":"Rio do Meio ","provincia":"Bahia","pais":"BRASIL","latitud":-15.065809759010481,"longitud":-39.97041330755445,"ranking":0},
{"id":6541,"nombre":"Rio Verde","localidad":"Rio Verde","provincia":"Bahia","pais":"BRASIL","latitud":-14.620655204841132,"longitud":-43.74308231123397,"ranking":0},
{"id":6543,"nombre":"Varzinha ","localidad":"Varzinha","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6550,"nombre":"Salobrinho","localidad":"Salobrinho","provincia":"Bahia","pais":"BRASIL","latitud":-14.79791809817788,"longitud":-39.1772162472536,"ranking":0},
{"id":6554,"nombre":"Segredo","localidad":"Segredo","provincia":"Bahia","pais":"BRASIL","latitud":-12.014720818463806,"longitud":-41.66959358216203,"ranking":0},
{"id":6578,"nombre":"Vargito","localidad":"Vargito","provincia":"Bahia","pais":"BRASIL","latitud":-15.453423291325594,"longitud":-39.44411510448999,"ranking":0},
{"id":6579,"nombre":"Varzea Grande","localidad":"Várzea Grande","provincia":"Bahia","pais":"BRASIL","latitud":-15.587324767502183,"longitud":-56.16616942238646,"ranking":0},
{"id":6588,"nombre":"Pensao Seca","localidad":"Pensão Seca","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.8406111191553,"longitud":-55.1548719296545,"ranking":0},
{"id":6591,"nombre":"Santa Elvira","localidad":"Santa Elvira","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.16101321768049,"longitud":-54.79149542003676,"ranking":0},
{"id":6600,"nombre":"Amaniutuba","localidad":"Amaniutuba","provincia":"Ceará","pais":"BRASIL","latitud":-6.678312928327332,"longitud":-38.886215068208045,"ranking":0},
{"id":6602,"nombre":"Aprazivel","localidad":"Aprazivel","provincia":"Ceará","pais":"BRASIL","latitud":-3.7558058710279627,"longitud":-40.55547575970471,"ranking":0},
{"id":6606,"nombre":"Aracatiacu","localidad":"Aracatiaçu","provincia":"Ceará","pais":"BRASIL","latitud":-3.8771132475465118,"longitud":-40.02159603913899,"ranking":0},
{"id":6609,"nombre":"Aratama","localidad":"Aratama","provincia":"Ceará","pais":"BRASIL","latitud":-7.05816772539423,"longitud":-39.87784968760837,"ranking":0},
{"id":7102,"nombre":"Barra Nova","localidad":"Barra Nova","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6616,"nombre":"Barra Verde","localidad":"Barra Verde","provincia":"Ceará","pais":"BRASIL","latitud":-6.419237485498651,"longitud":-40.13341568808075,"ranking":0},
{"id":6617,"nombre":"Barreiras","localidad":"Barreiras","provincia":"Ceará","pais":"BRASIL","latitud":-6.326886789264762,"longitud":-39.32156768826476,"ranking":0},
{"id":6624,"nombre":"Bitupita","localidad":"Bitupitá","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6630,"nombre":"Cacimbinha","localidad":"Cacimbinha","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6633,"nombre":"Caipu","localidad":"Caipu","provincia":"Ceará","pais":"BRASIL","latitud":-6.633424416339286,"longitud":-39.326835228941974,"ranking":0},
{"id":6635,"nombre":"Campanario","localidad":"Campanário","provincia":"Ceará","pais":"BRASIL","latitud":-3.3687888928120526,"longitud":-40.725404373966924,"ranking":0},
{"id":6646,"nombre":"Catuana","localidad":"Catuana","provincia":"Ceará","pais":"BRASIL","latitud":-3.6854542581533347,"longitud":-38.912225494974344,"ranking":0},
{"id":6659,"nombre":"Farinha","localidad":"Farinha","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6677,"nombre":"Santa Cruz do Prata","localidad":"Santa Cruz do Prata","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.20391377653892,"longitud":-46.746508933402154,"ranking":0},
{"id":6678,"nombre":"Santa Quiteria","localidad":"Santa Quitéria","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6680,"nombre":"Santana do Capivari","localidad":"Santana do Capivari","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6681,"nombre":"Monjolinho de Minas","localidad":"Monjolinho de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.77685773881381,"longitud":-46.257742705339965,"ranking":0},
{"id":6684,"nombre":"Coite","localidad":"Coité ","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6691,"nombre":"Corrego","localidad":"Córrego","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6696,"nombre":"Cristais","localidad":"Cristais","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6699,"nombre":"Cruzeta","localidad":"Cruzeta","provincia":"Ceará","pais":"BRASIL","latitud":-6.573378986368131,"longitud":-39.86580698978883,"ranking":0},
{"id":6702,"nombre":"Dom Quintino","localidad":"Dom Quintino","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6714,"nombre":"Felizardo","localidad":"Felizardo","provincia":"Ceará","pais":"BRASIL","latitud":-6.834540910072284,"longitud":-38.74247309041137,"ranking":0},
{"id":6722,"nombre":"Sapucaia","localidad":"Sapucaia","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.995654607004585,"longitud":-42.91590977052284,"ranking":0},
{"id":6727,"nombre":"Vargem Grande","localidad":"Vargem Grande","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6731,"nombre":"Areia Branca","localidad":"Areia Branca","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.311233709150073,"longitud":-42.702763681521944,"ranking":0},
{"id":6735,"nombre":"Crenaque","localidad":"Crenaque","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6739,"nombre":"Santa Terezinha","localidad":"Santa Terezinha","provincia":"Paraíba","pais":"BRASIL","latitud":-6.853788143608148,"longitud":-35.495809446132895,"ranking":0},
{"id":6744,"nombre":"Grossos","localidad":"Grossos","provincia":"Ceará","pais":"BRASIL","latitud":-6.776545172156555,"longitud":-39.292802076307815,"ranking":0},
{"id":6747,"nombre":"Videu ","localidad":"Vidéu ","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6749,"nombre":"Iara","localidad":"Iara","provincia":"Ceará","pais":"BRASIL","latitud":-7.181352099420447,"longitud":-38.781494234252925,"ranking":0},
{"id":6752,"nombre":"Inhucu","localidad":"Inhuçu","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6756,"nombre":"Mata Redonda","localidad":"Mata Redonda","provincia":"Paraíba","pais":"BRASIL","latitud":-7.354346283271266,"longitud":-34.94978816994571,"ranking":0},
{"id":6759,"nombre":"Serrinha","localidad":"Serrinha","provincia":"Paraíba","pais":"BRASIL","latitud":-7.1565602352977145,"longitud":-38.43551752197068,"ranking":0},
{"id":6765,"nombre":"Iratinga","localidad":"Iratinga","provincia":"Ceará","pais":"BRASIL","latitud":-3.7363186716135264,"longitud":-39.547431029206805,"ranking":0},
{"id":6774,"nombre":"Jaibaras","localidad":"Jaibaras","provincia":"Ceará","pais":"BRASIL","latitud":-3.7870858643916674,"longitud":-40.49879929294403,"ranking":0},
{"id":6780,"nombre":"Penha Norte","localidad":"Penha Norte","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6781,"nombre":"Jua","localidad":"Juá","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6783,"nombre":"Sao Vitor","localidad":"São Vítor","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.820768519811534,"longitud":-41.68809622852038,"ranking":0},
{"id":6784,"nombre":"Lagoa Danta","localidad":"Lagoa d'Anta","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6789,"nombre":"Lima Campos","localidad":"Lima Campos","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6794,"nombre":"Macaoca","localidad":"Macaoca","provincia":"Ceará","pais":"BRASIL","latitud":-4.759737536426271,"longitud":-39.48234431931596,"ranking":0},
{"id":6798,"nombre":"Majorlandia","localidad":"Majorlândia","provincia":"Ceará","pais":"BRASIL","latitud":-4.554184495982531,"longitud":-37.67351233492223,"ranking":0},
{"id":6800,"nombre":"Sobragi","localidad":"Sobragi","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6801,"nombre":"Mangabeira","localidad":"Mangabeira","provincia":"Ceará","pais":"BRASIL","latitud":-6.7580017531373935,"longitud":-39.120512096586154,"ranking":0},
{"id":6808,"nombre":"Medeiros","localidad":"Medeiros","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6820,"nombre":"Mineiro ","localidad":"Mineiro ","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6827,"nombre":"Moreira","localidad":"Moreira","provincia":"Ceará","pais":"BRASIL","latitud":-3.7445137193742997,"longitud":-39.44181477810995,"ranking":0},
{"id":6829,"nombre":"Naraniu","localidad":"Naraniú","provincia":"Ceará","pais":"BRASIL","latitud":-6.662867111235427,"longitud":-39.256207082569446,"ranking":0},
{"id":6833,"nombre":"Nova Betania","localidad":"Nova Betânia","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6838,"nombre":"Santana do Livramento","localidad":"Santana do Livramento","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.880882,"longitud":-55.5100712,"ranking":0},
{"id":6844,"nombre":"Patos","localidad":"Patos","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6848,"nombre":"Pirapora","localidad":"Piraporã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.97671233500586,"longitud":-54.674010253474314,"ranking":0},
{"id":6849,"nombre":"Santa Luzia","localidad":"Santa Luzia","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6852,"nombre":"Agachi","localidad":"Agachi","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6853,"nombre":"Amandina","localidad":"Amandina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.387392241922534,"longitud":-53.59298851347072,"ranking":0},
{"id":6856,"nombre":"Anhandui","localidad":"Anhanduí","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.98775083255594,"longitud":-54.50581122707436,"ranking":0},
{"id":6858,"nombre":"Aroeira","localidad":"Aroeira","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6861,"nombre":"Bocaja","localidad":"Bocajá","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6863,"nombre":"Peixe Gordo","localidad":"Peixe Gordo","provincia":"Ceará","pais":"BRASIL","latitud":-5.222743358780473,"longitud":-38.20178895820544,"ranking":0},
{"id":6869,"nombre":"Pitombeira","localidad":"Pitombeira","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6872,"nombre":"Primavera","localidad":"Primavera","provincia":"Ceará","pais":"BRASIL","latitud":-3.710613573267544,"longitud":-38.82738914392687,"ranking":0},
{"id":6873,"nombre":"Quatiguaba","localidad":"Quatiguaba","provincia":"Ceará","pais":"BRASIL","latitud":-3.674631924650976,"longitud":-41.06745486148585,"ranking":0},
{"id":6874,"nombre":"Quatro Bocas","localidad":"Quatro Bocas","provincia":"Ceará","pais":"BRASIL","latitud":-3.5266051963094145,"longitud":-39.073043046142175,"ranking":0},
{"id":6876,"nombre":"Quixaba","localidad":"Quixabá","provincia":"Ceará","pais":"BRASIL","latitud":-4.9728414116890916,"longitud":-39.01634435674315,"ranking":0},
{"id":6877,"nombre":"Rafael Arruda","localidad":"Rafael Arruda","provincia":"Ceará","pais":"BRASIL","latitud":-3.835732589310572,"longitud":-40.66638461083196,"ranking":0},
{"id":6883,"nombre":"Sao Miguel","localidad":"São Miguel","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6884,"nombre":"Sao Nicolau","localidad":"São Nicolau","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6885,"nombre":"Sapo","localidad":"Sapó","provincia":"Ceará","pais":"BRASIL","latitud":-3.3822522607505547,"longitud":-40.15808204121582,"ranking":0},
{"id":6886,"nombre":"Cachoeirao","localidad":"Cachoeirão","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6887,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6888,"nombre":"Camisao","localidad":"Camisão","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.483883428025507,"longitud":-55.635031459124605,"ranking":0},
{"id":6889,"nombre":"Capim Branco","localidad":"Capim Branco","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6892,"nombre":"Cruzaltina","localidad":"Cruzaltina","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6893,"nombre":"Culturama","localidad":"Culturama","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.28754234964737,"longitud":-54.32831501218766,"ranking":0},
{"id":6899,"nombre":"Estaca","localidad":"Estaca","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6902,"nombre":"Guaicurus","localidad":"Guaicurus","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.523770661229182,"longitud":-54.599928879464954,"ranking":0},
{"id":6903,"nombre":"Indubrasil","localidad":"Indubrasil","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.478535911019566,"longitud":-54.75992001219547,"ranking":0},
{"id":6904,"nombre":"Ipezal","localidad":"Ipezal","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.053649031351828,"longitud":-54.068942396598906,"ranking":0},
{"id":6906,"nombre":"Serragem","localidad":"Serragem","provincia":"Ceará","pais":"BRASIL","latitud":-4.433785052991345,"longitud":-38.50710724598792,"ranking":0},
{"id":6907,"nombre":"Serrota","localidad":"Serrota","provincia":"Ceará","pais":"BRASIL","latitud":-3.1865375808136105,"longitud":-40.455893982808334,"ranking":0},
{"id":6909,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6910,"nombre":"Sucesso","localidad":"Sucesso","provincia":"Ceará","pais":"BRASIL","latitud":-4.943076787653653,"longitud":-40.53767627170244,"ranking":0},
{"id":6911,"nombre":"Taboleiro","localidad":"Taboleiro","provincia":"Ceará","pais":"BRASIL","latitud":-6.736887094546587,"longitud":-39.95604276038179,"ranking":0},
{"id":6913,"nombre":"Taperuaba","localidad":"Taperuaba","provincia":"Ceará","pais":"BRASIL","latitud":-4.075586851650476,"longitud":-39.96285688785293,"ranking":0},
{"id":6915,"nombre":"Triangulo","localidad":"Triângulo","provincia":"Ceará","pais":"BRASIL","latitud":-4.322445543730005,"longitud":-38.489378786844455,"ranking":0},
{"id":6916,"nombre":"Trussu","localidad":"Trussu","provincia":"Ceará","pais":"BRASIL","latitud":-6.0951076803537045,"longitud":-39.74119926041565,"ranking":0},
{"id":6918,"nombre":"Ubauna","localidad":"Ubaúna","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7110,"nombre":"Umarizeira","localidad":"Umarizeira","provincia":"Ceará","pais":"BRASIL","latitud":-6.568971607089245,"longitud":-39.318498409566025,"ranking":0},
{"id":6919,"nombre":"Umburanas","localidad":"Umburanas","provincia":"Ceará","pais":"BRASIL","latitud":-7.444479552075335,"longitud":-38.7245230875286,"ranking":0},
{"id":6924,"nombre":"Gadelha","localidad":"Gadelha","provincia":"Ceará","pais":"BRASIL","latitud":-6.4260932538076565,"longitud":-39.27525717748523,"ranking":0},
{"id":6925,"nombre":"Agua Verde ","localidad":"Água Verde ","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6926,"nombre":"Capristano","localidad":"Capristano","provincia":"Ceará","pais":"BRASIL","latitud":-4.4696784829170655,"longitud":-38.90145347069886,"ranking":0},
{"id":6929,"nombre":"Siupe","localidad":"Siupé","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6935,"nombre":"Barra Grande","localidad":"Barra Grande","provincia":"Piauí","pais":"BRASIL","latitud":-2.9175670681200203,"longitud":-41.40809360053387,"ranking":0},
{"id":6947,"nombre":"Coqueiro","localidad":"Coqueiro","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6951,"nombre":"Estaca Zero","localidad":"Estaca Zero","provincia":"Piauí","pais":"BRASIL","latitud":-5.758931532813843,"longitud":-42.616392479159906,"ranking":0},
{"id":6953,"nombre":"Gaturiano","localidad":"Gaturiano","provincia":"Piauí","pais":"BRASIL","latitud":-8.997144025261141,"longitud":-42.68280218937947,"ranking":0},
{"id":6955,"nombre":"Jacaranda","localidad":"Jacarandá","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6956,"nombre":"Jacare","localidad":"Jacaré ","provincia":"Piauí","pais":"BRASIL","latitud":-6.492659585640402,"longitud":-42.51159630946027,"ranking":0},
{"id":6961,"nombre":"Minador","localidad":"Minador","provincia":"Piauí","pais":"BRASIL","latitud":-5.6600107689392125,"longitud":-42.481372544290714,"ranking":0},
{"id":6964,"nombre":"Olinda","localidad":"Olinda","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6965,"nombre":"Palmeirinha","localidad":"Palmeirinha","provincia":"Piaui","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6966,"nombre":"Pinto","localidad":"Pinto","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6970,"nombre":"Sambaiba","localidad":"Sambaíba","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6971,"nombre":"Santiago","localidad":"Santiago","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":6973,"nombre":"Santa Teresa","localidad":"Santa Teresa","provincia":"Piauí","pais":"BRASIL","latitud":-5.010448020293806,"longitud":-42.64238525325882,"ranking":0},
{"id":6976,"nombre":"Ambrosio","localidad":"Ambrósio","provincia":"Piauí","pais":"BRASIL","latitud":-8.075624226313264,"longitud":-44.468880943027735,"ranking":0},
{"id":7008,"nombre":"Acougue","localidad":"Açougue","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7009,"nombre":"Alegre","localidad":"Alegre","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7011,"nombre":"Alto Brasil","localidad":"Alto Brasil","provincia":"Maranhão","pais":"BRASIL","latitud":-5.622189423182012,"longitud":-45.76729182636789,"ranking":0},
{"id":7013,"nombre":"Andirobal ","localidad":"Andirobal","provincia":"Maranhão","pais":"BRASIL","latitud":-3.7998213136081467,"longitud":-45.162482611488045,"ranking":0},
{"id":7016,"nombre":"Bananal ","localidad":"Bananal","provincia":"Maranhão","pais":"BRASIL","latitud":-5.6420476659877385,"longitud":-47.39819250629065,"ranking":0},
{"id":7017,"nombre":"Barro Duro ","localidad":"Barro Duro","provincia":"Maranhão","pais":"BRASIL","latitud":-2.8929373814158663,"longitud":-42.31174655884515,"ranking":0},
{"id":7021,"nombre":"Brandao ","localidad":"Brandão","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7022,"nombre":"Brejinho ","localidad":"Brejinho","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7025,"nombre":"Cajazeira ","localidad":"Cajazeira","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7027,"nombre":"Canaa","localidad":"Canaã","provincia":"Maranhão","pais":"BRASIL","latitud":-5.366902647062717,"longitud":-44.213510794674,"ranking":0},
{"id":7031,"nombre":"Clemente","localidad":"Clemente","provincia":"Maranhão","pais":"BRASIL","latitud":-5.438627547894228,"longitud":-45.03057656377835,"ranking":0},
{"id":7035,"nombre":"Colombo","localidad":"Colombo","provincia":"Maranhão","pais":"BRASIL","latitud":-3.39012974175285,"longitud":-44.49728295437207,"ranking":0},
{"id":7036,"nombre":"Coqueiro","localidad":"Coqueiro","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7038,"nombre":"Corrego Novo","localidad":"Córrego Novo","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7039,"nombre":"Descanco","localidad":"Descanço","provincia":"Maranhão","pais":"BRASIL","latitud":-6.096873367902855,"longitud":-43.054771701961855,"ranking":0},
{"id":7044,"nombre":"Fazendinha ","localidad":"Fazendinha ","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7046,"nombre":"Gigana ","localidad":"Gigana","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7050,"nombre":"Independência ","localidad":"Independência","provincia":"Maranhão","pais":"BRASIL","latitud":-4.449964512305479,"longitud":-44.378882914564784,"ranking":0},
{"id":7051,"nombre":"Inhumas ","localidad":"Inhumas","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7056,"nombre":"Lajeado ","localidad":"Lajeado","provincia":"Maranhão","pais":"BRASIL","latitud":-6.190275442726637,"longitud":-47.035016598874684,"ranking":0},
{"id":7058,"nombre":"Livramento","localidad":"Livramento","provincia":"Maranhão","pais":"BRASIL","latitud":-4.352450719812795,"longitud":-44.36977099814147,"ranking":0},
{"id":7064,"nombre":"Nazare","localidad":"Nazaré","provincia":"Maranhão","pais":"BRASIL","latitud":-5.4649826563373685,"longitud":-44.54952088589319,"ranking":0},
{"id":7065,"nombre":"Novo Bacabal","localidad":"Novo Bacabal","provincia":"Maranhão","pais":"BRASIL","latitud":-4.6872301290557035,"longitud":-46.982913697391204,"ranking":0},
{"id":7066,"nombre":"Novo Horizonte ","localidad":"Novo Horizonte","provincia":"Maranhão","pais":"BRASIL","latitud":-3.025149238567268,"longitud":-42.063175216567245,"ranking":0},
{"id":7067,"nombre":"Palestina ","localidad":"Palestina","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7068,"nombre":"Palmeiral","localidad":"Palmeiral","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7069,"nombre":"Palmeiras","localidad":"Palmeiras","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7077,"nombre":"Queimada ","localidad":"Queimada","provincia":"Maranhão","pais":"BRASIL","latitud":-2.30670763970101,"longitud":-45.27193615411701,"ranking":0},
{"id":7079,"nombre":"Sabonete","localidad":"Sabonete","provincia":"Maranhão","pais":"BRASIL","latitud":-5.594269311291071,"longitud":-45.7213824568096,"ranking":0},
{"id":7081,"nombre":"Sao Francisco","localidad":"São Francisco","provincia":"Maranhão","pais":"BRASIL","latitud":-2.5086511716774296,"longitud":-44.303343875270855,"ranking":0},
{"id":7083,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7086,"nombre":"Santo Antonio","localidad":"Santo Antônio","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7087,"nombre":"Sumauma","localidad":"Sumaúma","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7089,"nombre":"Triangulo","localidad":"Triângulo","provincia":"Maranhão","pais":"BRASIL","latitud":-4.910214261853610,"longitud":-44.36584374543015,"ranking":0},
{"id":7108,"nombre":"Alto Da Cruz","localidad":"Alto Da Cruz","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7109,"nombre":"Corrego","localidad":"Corrego","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7111,"nombre":"Valparaiso","localidad":"Valparaiso","provincia":"Goias","pais":"BRASIL","latitud":-16.07602284436553,"longitud":-47.98636104756173,"ranking":0},
{"id":7119,"nombre":"Montese","localidad":"Montese","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.03518871152659,"longitud":-54.74583664030996,"ranking":0},
{"id":7123,"nombre":"Piraputanga","localidad":"Piraputanga","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.478194728768344,"longitud":-55.5364206464194,"ranking":0},
{"id":7124,"nombre":"Porto Primavera","localidad":"Pôrto Primavera","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.508132468785597,"longitud":-53.018422096498256,"ranking":0},
{"id":7125,"nombre":"Porto Vilma","localidad":"Porto Vilma","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.076321003842924,"longitud":-54.191063458797856,"ranking":0},
{"id":7126,"nombre":"Presidente Castelo","localidad":"Presidente Castelo","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7127,"nombre":"Quebra Coco","localidad":"Quebra Côco","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.744124919515624,"longitud":-55.11932638515825,"ranking":0},
{"id":7129,"nombre":"Santa Olinda","localidad":"Santa Olinda","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.79819024788478,"longitud":-54.95517048391001,"ranking":0},
{"id":7133,"nombre":"Taunay","localidad":"Taunay","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7148,"nombre":"Lagoa Seca","localidad":"Lagoa Sêca","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7168,"nombre":"Alto Alegre","localidad":"Alto Alegre","provincia":"Parana","pais":"BRASIL","latitud":-22.897310914871962,"longitud":-51.88879813517169,"ranking":0},
{"id":7169,"nombre":"Alto do Amparo","localidad":"Alto do Amparo","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7183,"nombre":"Areia Branca dos Assis","localidad":"Areia Branca dos Assis","provincia":"Parana","pais":"BRASIL","latitud":-25.86862536554931,"longitud":-49.37010665544738,"ranking":0},
{"id":7190,"nombre":"Barro Preto","localidad":"Barro Prêto","provincia":"Parana","pais":"BRASIL","latitud":-24.167535555053032,"longitud":-50.302978499945695,"ranking":0},
{"id":7192,"nombre":"Bentopolis","localidad":"Bentópolis","provincia":"Parana","pais":"BRASIL","latitud":-22.93027133232541,"longitud":-51.71692773812934,"ranking":0},
{"id":7195,"nombre":"Bourbonia","localidad":"Bourbonia","provincia":"Parana","pais":"BRASIL","latitud":-24.181576602059618,"longitud":-52.22566074664348,"ranking":0},
{"id":7204,"nombre":"Campinho","localidad":"Campinho","provincia":"Parana","pais":"BRASIL","latitud":-25.45556591734533,"longitud":-49.524445545166394,"ranking":0},
{"id":7210,"nombre":"Cristo Rei","localidad":"Cristo Rei","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7212,"nombre":"Fiorópolis","localidad":"Fiorópolis","provincia":"Parana","pais":"BRASIL","latitud":-22.751970813193907,"longitud":-52.13677882570968,"ranking":0},
{"id":7224,"nombre":"Maira","localidad":"Mairá","provincia":"Parana","pais":"BRASIL","latitud":-22.700545172703652,"longitud":-51.64293455480734,"ranking":0},
{"id":7228,"nombre":"Mirasselva","localidad":"Mirasselva","provincia":"Parana","pais":"BRASIL","latitud":-22.968619613735118,"longitud":-51.48369357727585,"ranking":0},
{"id":7230,"nombre":"Nossa Senhora das Gracas","localidad":"Nossa Senhora das Gracas","provincia":"Parana","pais":"BRASIL","latitud":-22.91308203402632,"longitud":-51.79920752832496,"ranking":0},
{"id":7237,"nombre":"Porto Novo","localidad":"Pôrto Novo","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7243,"nombre":"Valencia ","localidad":"Valência","provincia":"Parana","pais":"BRASIL","latitud":-23.164630991468595,"longitud":-51.928649936475544,"ranking":0},
{"id":7263,"nombre":"Caetano Mendes","localidad":"Caetano Mendes","provincia":"Parana","pais":"BRASIL","latitud":-24.62462149788077,"longitud":-50.64156625743626,"ranking":0},
{"id":7271,"nombre":"Dorizon","localidad":"Dorizon","provincia":"Parana","pais":"BRASIL","latitud":-25.949036807883683,"longitud":-50.84851574396421,"ranking":0},
{"id":7276,"nombre":"Guaragi","localidad":"Guaragi","provincia":"Parana","pais":"BRASIL","latitud":-25.102269852655855,"longitud":-52.86729426274013,"ranking":0},
{"id":7277,"nombre":"Guarauna","localidad":"Guaraúna","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7294,"nombre":"Paz","localidad":"Paz","provincia":"Parana","pais":"BRASIL","latitud":-25.674396395041683,"longitud":-52.12431914658083,"ranking":0},
{"id":7308,"nombre":"Sao Luiz do Puruna","localidad":"São Luiz do Purunã","provincia":"Parana","pais":"BRASIL","latitud":-25.475835499303408,"longitud":-49.71580371795961,"ranking":0},
{"id":7310,"nombre":"Sede Alvorada","localidad":"Sede Alvorada","provincia":"Parana","pais":"BRASIL","latitud":-24.820208401192502,"longitud":-53.65556045178402,"ranking":0},
{"id":7315,"nombre":"Tronco","localidad":"Tronco","provincia":"Parana","pais":"BRASIL","latitud":-24.861272607002064,"longitud":-50.04275741787105,"ranking":0},
{"id":7316,"nombre":"Uvaia","localidad":"Uvaia","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7329,"nombre":"Costa Machado","localidad":"Costa Machado","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.59553145919956,"longitud":-46.89271554356235,"ranking":0},
{"id":7332,"nombre":"Bezerros","localidad":"Bezerros","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7333,"nombre":"Capia Novo","localidad":"Capia Novo","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7334,"nombre":"Roda Velha","localidad":"Roda Velha","provincia":"Bahia","pais":"BRASIL","latitud":-12.754558715572633,"longitud":-45.94258452123748,"ranking":0},
{"id":7340,"nombre":"Rio Abuna","localidad":"Rio Abunã","provincia":"Acre","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7344,"nombre":"Javi","localidad":"Javi","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7341,"nombre":"Batel","localidad":"Batel","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7345,"nombre":"Nestor Muniz","localidad":"Nestor Muniz","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7346,"nombre":"Porto Feliz","localidad":"Porto Feliz","provincia":"Bahia","pais":"BRASIL","latitud":-11.755445412362667,"longitud":-40.71273878312542,"ranking":0},
{"id":7347,"nombre":"Sao Sebastiao","localidad":"São Sebastiao","provincia":"Bahia","pais":"BRASIL","latitud":-12.509603609266158,"longitud":-38.49464219065134,"ranking":0},
{"id":7348,"nombre":"Jose de Alencar","localidad":"Jose de Alencar","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7349,"nombre":"Cafe da Linha","localidad":"Cafe da Linha","provincia":"Ceará","pais":"BRASIL","latitud":-7.295028517856401,"longitud":-39.036085193843135,"ranking":0},
{"id":7350,"nombre":"Ouro Preto","localidad":"Ouro Preto","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7351,"nombre":"Ponta da Serra","localidad":"Ponta da Serra","provincia":"Ceará","pais":"BRASIL","latitud":-7.118681706450853,"longitud":-39.42723700000309,"ranking":0},
{"id":7352,"nombre":"Saco","localidad":"Saco","provincia":"Ceará","pais":"BRASIL","latitud":-7.119578417090006,"longitud":-39.4269594998521,"ranking":0},
{"id":7353,"nombre":"Santa Teresa","localidad":"Santa Teresa","provincia":"Ceará","pais":"BRASIL","latitud":-5.867509920080231,"longitud":-40.571174885475365,"ranking":0},
{"id":7356,"nombre":"Volga","localidad":"Volga","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7357,"nombre":"Ibitu","localidad":"Ibitu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.61436292340955,"longitud":-48.763143987452956,"ranking":0},
{"id":7358,"nombre":"Cuiaba Paulista","localidad":"Cuiabá Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.282917911795764,"longitud":-52.10237211875866,"ranking":0},
{"id":7359,"nombre":"Campinal","localidad":"Campinal","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.90626534420338,"longitud":-47.07171771952142,"ranking":0},
{"id":5723,"nombre":"Jericoacoara ","localidad":"Vila de Jericoacoara","provincia":"Ceará","pais":"BRASIL","latitud":-2.7998994,"longitud":-40.510246,"ranking":0},
{"id":7363,"nombre":"Sao Bernardo","localidad":"São Bernardo","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.8432030279387,"longitud":-51.13365828173497,"ranking":0},
{"id":7365,"nombre":"Barra do Sahy","localidad":"Barra do Sahy","provincia":"Espíritu Santo","pais":"BRASIL","latitud":-19.876696859643207,"longitud":-40.086279621606074,"ranking":0},
{"id":7367,"nombre":"Baunilha","localidad":"Baunilha","provincia":"Espíritu Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7368,"nombre":"Bebedouro","localidad":"Bebedouro","provincia":"Espíritu Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7370,"nombre":"Carapina","localidad":"Carapina","provincia":"Espíritu Santo","pais":"BRASIL","latitud":-20.233499152593275,"longitud":-40.27479024690926,"ranking":0},
{"id":7373,"nombre":"Itapina","localidad":"Itapina","provincia":"Espíritu Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7375,"nombre":"Pedro do Rio","localidad":"Pedro do Rio","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7377,"nombre":"Sao Joao Do Sobrado","localidad":"São João Do Sobrado","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10059,"nombre":"Itaipava","localidad":"Itaipava ","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.386452252000414,"longitud":-43.132573896626134,"ranking":0},
{"id":7379,"nombre":"Sao Jose do Sobradinho","localidad":"São José do Sobradinho","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7386,"nombre":"Unamar","localidad":"Unamar","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.650296509624294,"longitud":-42.004207939409284,"ranking":0},
{"id":7389,"nombre":"Corrego do Ouro","localidad":"Corrego do Ouro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-16.296750615032,"longitud":-50.552783733033436,"ranking":0},
{"id":7390,"nombre":"Santa Cruz","localidad":"Santa Cruz","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7391,"nombre":"Santa Terezina","localidad":"Santa Terezina","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.321596483151797,"longitud":-40.35161801362212,"ranking":0},
{"id":7393,"nombre":"Boaventura","localidad":"Boaventura","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.31789675846663,"longitud":-41.62477415605632,"ranking":0},
{"id":7392,"nombre":"Vila Pontoes","localidad":"Vila Pontoes","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7394,"nombre":"Caxuxa","localidad":"Caxuxa","provincia":"Maranhão","pais":"BRASIL","latitud":-4.195258220649358,"longitud":-44.46425231134023,"ranking":0},
{"id":7396,"nombre":"Boa Sorte","localidad":"Boa Sorte","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7395,"nombre":"Encruzo","localidad":"Encruzo","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7397,"nombre":"Orosimbo","localidad":"Orosimbo","provincia":"Maranhão","pais":"BRASIL","latitud":-6.514789482808054,"longitud":-6.514789482808054,"ranking":0},
{"id":7398,"nombre":"São Domingos","localidad":"São Domingos","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7400,"nombre":"Sao Domingos do Maranhao","localidad":"Sao Domingos do Maranhão","provincia":"Maranhão","pais":"BRASIL","latitud":-5.589113805415538,"longitud":-44.375479250331466,"ranking":0},
{"id":7401,"nombre":"Vila Nova","localidad":"Vila Nova","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7402,"nombre":"Segredo","localidad":"Segredo","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7404,"nombre":"Cedro de Abate","localidad":"Cedro de Abaté","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.1484640760213,"longitud":-45.71124147999519,"ranking":0},
{"id":7405,"nombre":"Novo Sarandi","localidad":"Novo Sarandi","provincia":"Parana","pais":"BRASIL","latitud":-24.564032195590286,"longitud":-53.900841006324626,"ranking":0},
{"id":7406,"nombre":"Colonia Witmarsun","localidad":"Colonia Witmarsun","provincia":"Parana","pais":"BRASIL","latitud":-25.42405051076013,"longitud":-49.822288475719354,"ranking":0},
{"id":7411,"nombre":"Campo Grande do Sul","localidad":"Campo Grande do Sul","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7412,"nombre":"Sapucai","localidad":"Sapucaí","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.748210348487913,"longitud":-45.740781076712146,"ranking":0},
{"id":7414,"nombre":"Adhemar Barros","localidad":"Adhemar Barros","provincia":"Parana","pais":"BRASIL","latitud":-22.690472849800763,"longitud":-52.734946101697915,"ranking":0},
{"id":7415,"nombre":"Tres Barras","localidad":"Tres Barras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.518562457831834,"longitud":-43.45884446725097,"ranking":0},
{"id":7416,"nombre":"Barra do Cuiete","localidad":"Barra do Cuieté","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7417,"nombre":"Independencia","localidad":"Independencia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7418,"nombre":"Travessao","localidad":"Travessão","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12413,"nombre":"La Calera","localidad":"La Calera","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.35187019999999,"longitud":-64.3395816,"ranking":0},
{"id":7421,"nombre":"Boa Esperanca","localidad":"Boa Esperança","provincia":"Mato Grosso","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7427,"nombre":"Sao Jose do Gurupi ","localidad":"São José do Gurupi ","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7428,"nombre":"Morada Nova","localidad":"Morada Nova","provincia":"Pará","pais":"BRASIL","latitud":-5.212168810658891,"longitud":-49.044297462897454,"ranking":0},
{"id":7431,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Paraíba","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7432,"nombre":"Caja","localidad":"Caja","provincia":"Paraíba","pais":"BRASIL","latitud":-7.168107003766191,"longitud":-35.35040500490919,"ranking":0},
{"id":7438,"nombre":"Lagoa","localidad":"Lagoa","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7443,"nombre":"Cruzeiro do Nordeste","localidad":"Cruzeiro do Nordeste","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.362838018036305,"longitud":-37.27257641596308,"ranking":0},
{"id":7444,"nombre":"Gravata do Ibiapina","localidad":"Gravatá do Ibiapina","provincia":"Pernambuco","pais":"BRASIL","latitud":-7.858305355591761,"longitud":-36.09216173850631,"ranking":0},
{"id":7445,"nombre":"Mimoso","localidad":"Mimoso","provincia":"Pernambuco","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7448,"nombre":"Sitio dos Nunes","localidad":"Sitio dos Nunes","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.048274078138327,"longitud":-37.83643828113153,"ranking":0},
{"id":7449,"nombre":"Alto Alegre","localidad":"Alto Alegre","provincia":"Piauí","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":7450,"nombre":"Baixa da Carnauba","localidad":"Baixa da Carnauba","provincia":"Piauí","pais":"BRASIL","latitud":-3.09829434783857,"longitud":-41.78027091037289,"ranking":0},
{"id":7673,"nombre":"Gessler","localidad":"San Jeronimo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.874440224176677,"longitud":-61.126929993855065,"ranking":0},
{"id":7957,"nombre":"San Jeronimo Norte Acceso","localidad":"San Jeronimo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.617768190010608,"longitud":-61.096453935116486,"ranking":0},
{"id":2061,"nombre":"Ataliva","localidad":"Ataliva","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.99917060364659,"longitud":-61.41834329073564,"ranking":0},
{"id":7464,"nombre":"Tambillo Acceso","localidad":"Esmeralda","provincia":"Esmeralda","pais":"Perú","latitud":-8.076872355035782,"longitud":-78.08731304469224,"ranking":0},
{"id":7466,"nombre":"Reparticion Acceso","localidad":"Islay","provincia":"Islay","pais":"Perú","latitud":-16.52897940977484,"longitud":-71.78360099726456,"ranking":0},
{"id":7482,"nombre":"Cachendo Acceso","localidad":"Islay","provincia":"Islay","pais":"Perú","latitud":-16.972723534026432,"longitud":-71.78722345790943,"ranking":0},
{"id":7470,"nombre":"Ocaña Acceso","localidad":"Lucanas","provincia":"Lucanas","pais":"Perú","latitud":-14.398577272220779,"longitud":-74.8251512598568,"ranking":0},
{"id":7471,"nombre":"General Guemes","localidad":"General Guemes","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7476,"nombre":"Nazca","localidad":"Nazca","provincia":"Peru","pais":"Perú","latitud":-14.828183394520606,"longitud":-74.94239097361017,"ranking":0},
{"id":7483,"nombre":"Nazca Acceso","localidad":"Nazca","provincia":"Peru","pais":"Perú","latitud":-14.8204573,"longitud":-74.96919059999999,"ranking":0},
{"id":7479,"nombre":"Mulchen","localidad":"Region VII","provincia":"Region VII","pais":"CHILE","latitud":-37.72144241917309,"longitud":-72.24001458171169,"ranking":0},
{"id":7480,"nombre":"Mulchen Acceso","localidad":"Region VII","provincia":"Region VII","pais":"CHILE","latitud":-37.72117866800715,"longitud":-72.26417925147202,"ranking":0},
{"id":7489,"nombre":"Cabrero ","localidad":"Region VII","provincia":"Region VII","pais":"CHILE","latitud":-37.04111093926217,"longitud":-72.3765273179604,"ranking":0},
{"id":7484,"nombre":"Parador Palpa","localidad":"Palpa","provincia":"Palpa","pais":"Perú","latitud":-14.538673721810557,"longitud":-75.18388369142359,"ranking":0},
{"id":7485,"nombre":"Cruce Pisco","localidad":"Pisco","provincia":"Ica","pais":"Perú","latitud":-13.7131226,"longitud":-76.1529446,"ranking":0},
{"id":15545,"nombre":"Paracas","localidad":"Pisco","provincia":"Ica","pais":"Perú","latitud":-13.8342643,"longitud":-76.24925979999999,"ranking":0},
{"id":7487,"nombre":"Chincha Alta acceso","localidad":"Chincha Alta","provincia":"Chincha Alta","pais":"Perú","latitud":-13.415844729839982,"longitud":-76.14059274686737,"ranking":0},
{"id":7514,"nombre":"Villa Benjamin Araoz","localidad":"Burruyacu","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.55655726828446,"longitud":-64.79903913435784,"ranking":0},
{"id":7528,"nombre":"Taruca Pampa","localidad":"Burruyacu","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.573844618928675,"longitud":-64.83277756153485,"ranking":0},
{"id":7538,"nombre":"Macomitas","localidad":"Burruyacu","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.730481670466478,"longitud":-65.0107269571129,"ranking":0},
{"id":9209,"nombre":"El Chañar","localidad":"Burruyacu","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.755528486351952,"longitud":-65.06590007676195,"ranking":0},
{"id":10035,"nombre":"Mariño","localidad":"Burruyacu","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.73743715394362,"longitud":-65.03522600794597,"ranking":0},
{"id":7587,"nombre":"Durazno ","localidad":"Durazno","provincia":"Durazno","pais":"URUGUAY","latitud":-33.3871655,"longitud":-56.5244478,"ranking":4},
{"id":7595,"nombre":"San Marcial ","localidad":"Villa San Marcial","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.18043662994516,"longitud":-58.92960684415229,"ranking":0},
{"id":7596,"nombre":"Santa Anita","localidad":"Santa Anita","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7640,"nombre":"Chacritas","localidad":"Chacritas","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7608,"nombre":"Los Quirquinchos","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.383254,"longitud":-61.715126,"ranking":0},
{"id":7610,"nombre":"Godeken","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.40034501099683,"longitud":-61.84449023307045,"ranking":0},
{"id":7611,"nombre":"Beraveyu","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.34283603095216,"longitud":-61.86802031528774,"ranking":0},
{"id":7692,"nombre":"Sanford","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.145113726617055,"longitud":-61.278902664021295,"ranking":0},
{"id":7612,"nombre":"Chañar Ladeado","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.321447,"longitud":-62.035179,"ranking":0},
{"id":7670,"nombre":"Arteaga","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.091646582736765,"longitud":-61.7839823900379,"ranking":0},
{"id":7678,"nombre":"Los Molinos","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.1136578,"longitud":-61.7019320,"ranking":0},
{"id":7679,"nombre":"Sombrerito","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7681,"nombre":"Villada","localidad":"Caseros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.349923,"longitud":-61.445073,"ranking":0},
{"id":7615,"nombre":"Parador Vivero","localidad":"Roque Saenz Peña","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7616,"nombre":"Parador Salas","localidad":"Roque Saenz Peña","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7618,"nombre":"Guadalupe Norte","localidad":"Obligado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.936900771027993,"longitud":-59.560037079659054,"ranking":0},
{"id":7622,"nombre":"Las Amintas","localidad":"General Obligado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.27242947322062,"longitud":-59.76309919010387,"ranking":0},
{"id":8093,"nombre":"El Rabon","localidad":"General Obligado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.221067243422656,"longitud":-59.26418419080284,"ranking":0},
{"id":7627,"nombre":"Cabal","localidad":"La Capital","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7628,"nombre":"Emilia","localidad":"La Capital","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.05938718133357,"longitud":-60.74464566602328,"ranking":0},
{"id":7634,"nombre":"Guaviyu","localidad":"Guaviyu","provincia":"Guaviyu","pais":"URUGUAY","latitud":-31.840358859730554,"longitud":-57.886697952772565,"ranking":0},
{"id":7767,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7664,"nombre":"Cayasta","localidad":"Cayastá","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.20092,"longitud":-60.161646,"ranking":3},
{"id":7669,"nombre":"Rincon","localidad":"San Jose del Rincon","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.60408351143763,"longitud":-60.57583420513014,"ranking":0},
{"id":7672,"nombre":"Alejandra","localidad":"San Javier","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.9075847,"longitud":-59.8314792,"ranking":4},
{"id":10788,"nombre":"San Javier","localidad":"San Javier","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.57298,"longitud":-59.935594,"ranking":21},
{"id":7671,"nombre":"Colonia Pujol","localidad":"Colonia Pujol","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7680,"nombre":"Parador Juan Fco Tarragona","localidad":"Tarragona","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7902,"nombre":"Monte Redondo","localidad":"San Justo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.491201064450944,"longitud":-62.167626069093316,"ranking":0},
{"id":7933,"nombre":"Miramar","localidad":"San Justo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.916002,"longitud":-62.675052,"ranking":18},
{"id":7689,"nombre":"Villa Fontana","localidad":"Villa Fontana","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.89113739999999,"longitud":-63.11614419999999,"ranking":2},
{"id":7699,"nombre":"Villa Divisa de Mayo","localidad":"Chovet","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.54808881696918,"longitud":-61.6677926686466,"ranking":0},
{"id":7713,"nombre":"Mariano Boedo","localidad":"Mariano Boedo","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.109937328400537,"longitud":-58.48650207903165,"ranking":0},
{"id":10852,"nombre":"Mariano Boedo Acceso","localidad":"Mariano Boedo","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.015846,"longitud":-58.39542509999999,"ranking":0},
{"id":7730,"nombre":"Salliquello Acceso","localidad":"Saliquello","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.71793846105375,"longitud":-62.91338390941945,"ranking":0},
{"id":7739,"nombre":"Frontera Chile Chacalluta","localidad":"Peru","provincia":"Peru","pais":"Perú","latitud":-18.313789154974817,"longitud":-70.3140427748371,"ranking":0},
{"id":7755,"nombre":"Ica Acceso","localidad":"Ica","provincia":"Peru","pais":"Perú","latitud":-14.064082497336196,"longitud":-75.73318582426758,"ranking":0},
{"id":7763,"nombre":"Los Toldos","localidad":"Los Toldos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.99909389702483,"longitud":-61.036792138452725,"ranking":0},
{"id":10784,"nombre":"Los Toldos Acceso","localidad":"Los Toldos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.99361703221566,"longitud":-61.0111141204834,"ranking":8},
{"id":7769,"nombre":"Argentina ","localidad":"Aguirre","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.53356828855611,"longitud":-62.26599666561122,"ranking":0},
{"id":14540,"nombre":"Argentina Acceso","localidad":"Aguirre","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.5326141,"longitud":-62.26691329999999,"ranking":0},
{"id":7776,"nombre":"Majes","localidad":"Majes","provincia":"Arequipa","pais":"Perú","latitud":-16.353803606114806,"longitud":-72.188282392739,"ranking":0},
{"id":7805,"nombre":"San Carlos Sur","localidad":"San Carlos Sur","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.76176668832042,"longitud":-61.101426485345456,"ranking":0},
{"id":7823,"nombre":"San Carlos Norte","localidad":"San Carlos Norte","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.67302246489017,"longitud":-61.075628888621615,"ranking":0},
{"id":7848,"nombre":"Aguas Coloradas","localidad":"Aguas Coloradas","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10827,"nombre":"Aguas Coloradas Acceso","localidad":"Aguas Coloradas","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7855,"nombre":"Diego de Rojas","localidad":"Diego de Rojas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.027768,"longitud":-63.33849439999999,"ranking":1},
{"id":7862,"nombre":"Guer Aike","localidad":"Guer Aike","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7872,"nombre":"Cocais","localidad":"Cocais","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.854102241081478,"longitud":-43.444964250667574,"ranking":0},
{"id":7873,"nombre":"Dom Correa","localidad":"Dom Correa","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.05062528465959,"longitud":-42.17758953781769,"ranking":0},
{"id":8096,"nombre":"Caspi Corral","localidad":"Figueroa","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.389288838970387,"longitud":-63.55062637200655,"ranking":0},
{"id":7899,"nombre":"San Victor","localidad":"San Victor","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7923,"nombre":"Santa Maria ","localidad":"Colonia Santa Maria","provincia":"La Pampa","pais":"ARGENTINA","latitud":-37.49284089841161,"longitud":-64.22709762269126,"ranking":0},
{"id":7929,"nombre":"Nueva Roma","localidad":"Nueva Roma ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.52665091037277,"longitud":-62.61673334607246,"ranking":0},
{"id":7959,"nombre":"Vergara","localidad":"Vergara","provincia":"Treinta y Tres","pais":"URUGUAY","latitud":-32.9373556467238,"longitud":-53.93823601570847,"ranking":0},
{"id":7960,"nombre":"Placido Rosas","localidad":"Placido Rosas","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.75685963140019,"longitud":-53.72722159881358,"ranking":0},
{"id":7961,"nombre":"Pueblo Rincon","localidad":"Rincon ","provincia":"Treinta y Tres","pais":"URUGUAY","latitud":-32.83354155531314,"longitud":-53.832729838576086,"ranking":0},
{"id":7962,"nombre":"La charqueada","localidad":"La charqueada","provincia":"Treinta y Tres","pais":"URUGUAY","latitud":-33.20449467896524,"longitud":-53.804732702831146,"ranking":0},
{"id":7965,"nombre":"General Baldisera ","localidad":"General Baldisera","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.11962683962754,"longitud":-62.301628642888176,"ranking":0},
{"id":7970,"nombre":"Parque Luro Acceso ","localidad":"Parque Luro","provincia":"La Pampa","pais":"ARGENTINA","latitud":-36.91534756186982,"longitud":-64.28353668671359,"ranking":0},
{"id":7976,"nombre":"Guardia Escolta","localidad":"Guardia Escolta","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.991027306916024,"longitud":-62.12882328109711,"ranking":0},
{"id":7977,"nombre":"Fortin Inca","localidad":"Fortin Inca","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7984,"nombre":"Villa Serrana","localidad":"Villa Serrana","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.31557558574012,"longitud":-55.034308051529685,"ranking":0},
{"id":7985,"nombre":"Picada de las Bochas","localidad":"Picada de las Bochas","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.21725080463479,"longitud":-54.93942020861651,"ranking":0},
{"id":7987,"nombre":"Retamosa ","localidad":"Retamosa ","provincia":"Lavalleja","pais":"URUGUAY","latitud":-33.581589344012016,"longitud":-54.73217786557024,"ranking":0},
{"id":7989,"nombre":"Piraraja ","localidad":"Piraraja","provincia":"Lavalleja","pais":"URUGUAY","latitud":-33.74085482398442,"longitud":-54.75735820817556,"ranking":0},
{"id":7990,"nombre":"Mariscala","localidad":"Mariscala","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.04185036534728,"longitud":-54.77710184433728,"ranking":0},
{"id":14670,"nombre":"PLAZA 19 DE ABRIL","localidad":"Mariscala","provincia":"Lavalleja","pais":"URUGUAY","latitud":null,"longitud":null,"ranking":0},
{"id":7992,"nombre":"Campanero ","localidad":"Campanero","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.331743835804026,"longitud":-55.25146767830265,"ranking":0},
{"id":7993,"nombre":"Soca","localidad":"Soca","provincia":"Canelones","pais":"URUGUAY","latitud":-34.683466940151874,"longitud":-55.70464184102728,"ranking":0},
{"id":8006,"nombre":"Pilaga","localidad":"Pilaga","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.1740346,"longitud":-58.5318204,"ranking":0},
{"id":8007,"nombre":"Hilario","localidad":"Hilario ","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8008,"nombre":"Hilario Acceso","localidad":"Hilario ","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.89035048290264,"longitud":-58.62628142824115,"ranking":0},
{"id":8009,"nombre":"El Perdido","localidad":"El Perdido","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8010,"nombre":"Corralito","localidad":"Corralito","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8015,"nombre":"Los Chiriguanos","localidad":"Los Chiriguanos","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.094435866194114,"longitud":-61.46075965830024,"ranking":4},
{"id":8016,"nombre":"Bazan ","localidad":"Juan G. Bazán","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8017,"nombre":"Colonia Santa Rosa","localidad":"Colonia Santa Rosa","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8018,"nombre":"San Huberto","localidad":"San Huberto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8352866,"longitud":-64.99226759999999,"ranking":4},
{"id":8019,"nombre":"El Pantanillo","localidad":"El Pantanillo ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8702129,"longitud":-64.9908143,"ranking":0},
{"id":8020,"nombre":"Las Chacras","localidad":"Las Chacras","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":1},
{"id":9498,"nombre":"Las Chacras Acceso","localidad":"Las Chacras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.24243421331533,"longitud":-65.0300340053439,"ranking":0},
{"id":8021,"nombre":"San Antonio","localidad":"San Antonio","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.483516,"longitud":-64.529603,"ranking":1},
{"id":8022,"nombre":"Igarzabal Acceso","localidad":"Igarzabal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9894,"nombre":"Igarzabal","localidad":"Igarzabal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.77927274748527,"longitud":-62.613851993563436,"ranking":0},
{"id":8025,"nombre":"San Jose del Morro","localidad":"San Jose del Morro","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8027,"nombre":"Villa de la Quebrada Acceso","localidad":"Villa de la Quebrada","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.017018093562804,"longitud":-66.29319514035416,"ranking":0},
{"id":8283,"nombre":"Villa de la Quebrada ","localidad":"Villa de la Quebrada","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8380,"nombre":"San Francisco del Monte de Oro","localidad":"San Francisco del Monte de Oro","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.591419,"longitud":-66.127264,"ranking":18},
{"id":8029,"nombre":"San Francisco del Monte de Oro Acceso","localidad":"San Francisco del Monte de Oro","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.61598578451222,"longitud":-66.12844456703374,"ranking":0},
{"id":8034,"nombre":"Antonio Carboni Acceso","localidad":"Antonio Carboni ","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.20292060410979,"longitud":-59.35227621666512,"ranking":0},
{"id":3644,"nombre":"Simoca","localidad":"Simoca","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.260583,"longitud":-65.350418,"ranking":2},
{"id":8035,"nombre":"Simoca Acceso ","localidad":"Simoca","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.265860948818304,"longitud":-65.3498507794578,"ranking":0},
{"id":8301,"nombre":"Arauco","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.596979,"longitud":-72.105672,"ranking":0},
{"id":8302,"nombre":"Cañete","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.801,"longitud":-73.400964,"ranking":0},
{"id":8303,"nombre":"Contulmo","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-38.015122,"longitud":-73.228133,"ranking":0},
{"id":14817,"nombre":"Bio-Bio","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.7980518,"longitud":-73.4000461,"ranking":0},
{"id":8310,"nombre":"Penco","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.71115,"longitud":-72.974244,"ranking":0},
{"id":8311,"nombre":"Talcahuano","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.738798,"longitud":-73.109528,"ranking":0},
{"id":8312,"nombre":"Tome","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.606816,"longitud":-72.951766,"ranking":0},
{"id":8304,"nombre":"Dichato","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.548677,"longitud":-72.936364,"ranking":0},
{"id":8305,"nombre":"Lebu ","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.607559,"longitud":-73.656645,"ranking":0},
{"id":8306,"nombre":"Lirquen","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-36.711152,"longitud":-72.974216,"ranking":0},
{"id":8307,"nombre":"Los Angeles","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.452435,"longitud":-72.339588,"ranking":0},
{"id":8308,"nombre":"Lota","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.11274,"longitud":-73.151816,"ranking":0},
{"id":8309,"nombre":"Nacimiento","localidad":"Bio-Bio","provincia":"Región VIII - Bío bío","pais":"CHILE","latitud":-37.500371,"longitud":-72.685817,"ranking":0},
{"id":8042,"nombre":"Atahona","localidad":"Atahona","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.416884941942854,"longitud":-65.28263003030004,"ranking":0},
{"id":8044,"nombre":"Guiñazu","localidad":"Guiñazu","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.313335298393465,"longitud":-64.1758761763566,"ranking":0},
{"id":8045,"nombre":"Rotonda Sumalao","localidad":"Sumalao","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.46821745843758,"longitud":-65.73356462296267,"ranking":0},
{"id":8046,"nombre":"Casa de Piedra","localidad":"Casa de Piedra","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.603526813162556,"longitud":-65.51866055669983,"ranking":19},
{"id":8047,"nombre":"Cañada Doce","localidad":"Cañada Doce","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.881605675203186,"longitud":-58.085324116806895,"ranking":0},
{"id":8048,"nombre":"Apayerey","localidad":"Apayerey","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8049,"nombre":"Palma Sola","localidad":"Palma Sola","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.247600402000142,"longitud":-57.97740993090266,"ranking":0},
{"id":8050,"nombre":"Naick Neck ","localidad":"Naick Neck","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.2247707,"longitud":-58.1153179,"ranking":32},
{"id":8052,"nombre":"Laguna Blanca","localidad":"Laguna Blanca","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.131847,"longitud":-58.245697,"ranking":134},
{"id":8053,"nombre":"Buena Vista","localidad":"Buena Vista","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8055,"nombre":"Tacaagle","localidad":"Tacaagle","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.959637996314594,"longitud":-58.82013310193153,"ranking":0},
{"id":8057,"nombre":"General Manuel Belgrano","localidad":"General Manuel Belgrano","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.94200750930906,"longitud":-59.02714597155017,"ranking":0},
{"id":8059,"nombre":"Santa Elena Acceso","localidad":"Santa Elena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.8645719250014,"longitud":-57.51349302680218,"ranking":0},
{"id":8060,"nombre":"Pastoril","localidad":"Pastoril","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.6711425,"longitud":-58.2609156,"ranking":6},
{"id":8061,"nombre":"San Pablo","localidad":"San Pablo","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8062,"nombre":"Villa Gral. Guemes","localidad":"Villa Gral.Guemes","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.75657022251457,"longitud":-59.50127062376093,"ranking":0},
{"id":8063,"nombre":"Los Tres Reyes","localidad":"Los Tres Reyes","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8064,"nombre":"Pavao","localidad":"El Pavao ","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.936786049840347,"longitud":-59.58099891921124,"ranking":0},
{"id":8065,"nombre":"Colonia Juanita","localidad":"Colonia Juanita","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8066,"nombre":"La Primavera","localidad":"La Primavera","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.1934107,"longitud":-58.2504491,"ranking":0},
{"id":8069,"nombre":"Rotonda Jose Pedro Varela","localidad":"Jose Pedro Varela ","provincia":"Lavalleja","pais":"URUGUAY","latitud":-33.457779740000795,"longitud":-54.52520076405435,"ranking":0},
{"id":8099,"nombre":"José Pedro Varela","localidad":"Jose Pedro Varela ","provincia":"Lavalleja","pais":"URUGUAY","latitud":-33.45585756637597,"longitud":-54.528925116965596,"ranking":0},
{"id":8070,"nombre":"Barrio España","localidad":"Minas","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.38098004224704,"longitud":-55.26576747291865,"ranking":0},
{"id":8071,"nombre":"Barrio Las Palmas","localidad":"Minas","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.374069573534925,"longitud":-55.26964089157123,"ranking":0},
{"id":8072,"nombre":"Minas Hospital","localidad":"Minas","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.36864651927146,"longitud":-55.22739125713309,"ranking":0},
{"id":8094,"nombre":"Minas","localidad":"Minas","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.37503757882531,"longitud":-55.24014068964488,"ranking":0},
{"id":8074,"nombre":"Penitente","localidad":"Salto del Penitente","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.37286284072651,"longitud":-55.05361567314524,"ranking":0},
{"id":8077,"nombre":"Los Molles","localidad":"Molles","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.38040069459146,"longitud":-55.26481102125681,"ranking":0},
{"id":8089,"nombre":"Empalme Olmos","localidad":"Empalme Olmos","provincia":"Canelones","pais":"URUGUAY","latitud":-34.70095646073646,"longitud":-55.89583567318452,"ranking":0},
{"id":8098,"nombre":"Solis de Mataojo","localidad":"Solis de Mataojo","provincia":"Lavalleja","pais":"URUGUAY","latitud":-34.59897866426433,"longitud":-55.466365125815635,"ranking":0},
{"id":8100,"nombre":"Melo","localidad":"Melo","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.36463667653151,"longitud":-54.166129064819366,"ranking":0},
{"id":8105,"nombre":"Villa Independencia","localidad":"Villa Independencia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.45051309960582,"longitud":-64.51773762702942,"ranking":13},
{"id":8124,"nombre":"Mbocayaty","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.721747,"longitud":-56.409611,"ranking":0},
{"id":10046,"nombre":"Jose Fassardi","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.978208,"longitud":-56.121045,"ranking":0},
{"id":10047,"nombre":"General Eugenio Garay","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.968273,"longitud":-56.182101,"ranking":0},
{"id":12582,"nombre":"Tebicuary","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.7773658,"longitud":-56.6477233,"ranking":0},
{"id":12583,"nombre":"Almacen 50","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.7112178,"longitud":-56.181221,"ranking":0},
{"id":12593,"nombre":"Capi´i","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.685810995883603,"longitud":-55.85142614413946,"ranking":0},
{"id":12594,"nombre":"Cruce Capi´i","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.685414,"longitud":-55.852007,"ranking":0},
{"id":12595,"nombre":"Teniente Bogado","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.7330974,"longitud":-56.5909728,"ranking":0},
{"id":12596,"nombre":"Potrero del Carmen","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.723866,"longitud":-56.09911520000001,"ranking":0},
{"id":12598,"nombre":"Km 22","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.959797158471225,"longitud":-56.266508259067706,"ranking":0},
{"id":12601,"nombre":"Carlos Pfannl","localidad":"Guairá","provincia":"Guairá","pais":"Paraguay","latitud":-25.694927811528867,"longitud":-56.12885866919481,"ranking":0},
{"id":8137,"nombre":"Ampascachi","localidad":"Ampascachi","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8139,"nombre":"Payogastilla","localidad":"Payogastilla","provincia":"Salta","pais":"ARGENTINA","latitud":-25.712626073667984,"longitud":-66.00454348728915,"ranking":0},
{"id":8174,"nombre":"San Carlos","localidad":"San Carlos","provincia":"Salta","pais":"ARGENTINA","latitud":-25.8919226,"longitud":-65.9283250,"ranking":0},
{"id":8144,"nombre":"Yaguaron","localidad":"Yaguaron","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-32.561098662041886,"longitud":-53.38085350583591,"ranking":0},
{"id":8145,"nombre":"General Fotheringham","localidad":"General Fotheringham","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.32244120000001,"longitud":-63.8684559,"ranking":1},
{"id":8151,"nombre":"Santiago de Cotagaita","localidad":"Santiago de Cotagaita","provincia":"Santiago de Cotagaita","pais":"BOLIVIA","latitud":-20.816214043983553,"longitud":-65.66045802658073,"ranking":0},
{"id":9422,"nombre":"Molinari","localidad":"Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9425,"nombre":"Villa Yacoana","localidad":"Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.13382,"longitud":-64.481955,"ranking":0},
{"id":10764,"nombre":"Cañada Larga","localidad":"Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10879,"nombre":"Paraje Rio Seco","localidad":"Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.8010611,"longitud":-64.51519050000002,"ranking":0},
{"id":10880,"nombre":"Paraje Dolores","localidad":"Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8159,"nombre":"Pozo del Mortero","localidad":"Bermejo","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10039,"nombre":"Churiguano","localidad":"Bermejo","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14635,"nombre":"Pozo del Mortero Acceso","localidad":"Bermejo","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.3868091,"longitud":-61.05653209999999,"ranking":0},
{"id":8165,"nombre":"Santa Catalina","localidad":"Santa Catalina","provincia":"Soriano","pais":"URUGUAY","latitud":-33.79655119046724,"longitud":-57.4831258117424,"ranking":0},
{"id":8167,"nombre":"El Saladillo","localidad":"Coronel Pringles","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8176,"nombre":"Cerrillos","localidad":"Cerrillos","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8178,"nombre":"Coronel Moldes","localidad":"Coronel Moldes","provincia":"Salta","pais":"ARGENTINA","latitud":-25.28016646578529,"longitud":-65.47891087520408,"ranking":0},
{"id":8179,"nombre":"La Viña","localidad":"La Viña","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8208,"nombre":"Candia ","localidad":"Candia","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.89698183608762,"longitud":-47.985289787364316,"ranking":0},
{"id":8209,"nombre":"Capelinha","localidad":"Capelinha","provincia":"Sao Paulo","pais":"BRASIL","latitud":-17.69250024508373,"longitud":-42.517232705328695,"ranking":0},
{"id":8217,"nombre":"Ribeiro dos Santos ","localidad":"Ribeiro dos Santos","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.609061158749498,"longitud":-48.95357915529046,"ranking":0},
{"id":8269,"nombre":"Miraflores ","localidad":"General Güemes","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8271,"nombre":"Sarah","localidad":"Chapaleufú","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.04447353594651,"longitud":-63.690774568001174,"ranking":0},
{"id":9139,"nombre":"Sarah Acceso","localidad":"Chapaleufú","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.04437284456737,"longitud":-63.6904034124435,"ranking":0},
{"id":9194,"nombre":"Coronel Hilario","localidad":"Chapaleufú","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.024121555,"longitud":-63.91207132,"ranking":0},
{"id":8288,"nombre":"Cochrane","localidad":"Aysén","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-47.251064,"longitud":-72.573648,"ranking":0},
{"id":8289,"nombre":"Puerto Aysen","localidad":"Aysén","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-45.401003,"longitud":-72.689377,"ranking":0},
{"id":8290,"nombre":"Puerto Cisnes","localidad":"Aysén","provincia":"Región XI - Aysén","pais":"CHILE","latitud":-44.72962,"longitud":-72.682496,"ranking":0},
{"id":8297,"nombre":"Caldera","localidad":"Atacama","provincia":"Región III - Atacama","pais":"CHILE","latitud":-27.0700539,"longitud":-70.8238022,"ranking":0},
{"id":8298,"nombre":"Freirina","localidad":"Atacama","provincia":"Región III - Atacama","pais":"CHILE","latitud":-28.5086654,"longitud":-71.0809011,"ranking":0},
{"id":8299,"nombre":"Huasco","localidad":"Atacama","provincia":"Región III - Atacama","pais":"CHILE","latitud":-28.4642571,"longitud":-71.2225855,"ranking":0},
{"id":8300,"nombre":"Vallenar ","localidad":"Atacama","provincia":"Región III - Atacama","pais":"CHILE","latitud":-28.5726158,"longitud":-70.7659414,"ranking":0},
{"id":8321,"nombre":"Angol","localidad":"Araucania","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-37.800351,"longitud":-72.696562,"ranking":0},
{"id":8322,"nombre":"Lican Ray","localidad":"Araucania","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-39.489902,"longitud":-72.15447,"ranking":0},
{"id":8323,"nombre":"Victoria","localidad":"Araucania","provincia":"Región IX - Araucanía","pais":"CHILE","latitud":-38.232564,"longitud":-72.335992,"ranking":0},
{"id":8324,"nombre":"Calbuco","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.771531324391525,"longitud":-73.12712529163962,"ranking":0},
{"id":8325,"nombre":"Ancud","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.86760784424948,"longitud":-73.824858130401,"ranking":0},
{"id":8326,"nombre":"Castro","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-39.86204397882421,"longitud":-72.80965643462416,"ranking":0},
{"id":8327,"nombre":"Delcahue","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-42.37950628774639,"longitud":-73.64775960853432,"ranking":0},
{"id":8328,"nombre":"Llanquihue ","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.25811129420245,"longitud":-73.00553087259526,"ranking":0},
{"id":8329,"nombre":"Pelluco","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-41.486426057214416,"longitud":-72.90159814098429,"ranking":0},
{"id":8331,"nombre":"Quellon","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-43.11930769016767,"longitud":-73.6222656766784,"ranking":0},
{"id":9113,"nombre":"La Union","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-40.29271765198429,"longitud":-73.07204286771051,"ranking":0},
{"id":9187,"nombre":"Chonchi","localidad":"Los Lagos","provincia":"Región X -  Los Lagos","pais":"CHILE","latitud":-42.624131487839215,"longitud":-73.77282966880333,"ranking":0},
{"id":8332,"nombre":"Cameron","localidad":"Magallanes","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-53.626852,"longitud":-69.625732,"ranking":0},
{"id":8333,"nombre":"Cerrosombrero","localidad":"Magallanes","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-52.776968,"longitud":-69.292063,"ranking":0},
{"id":8334,"nombre":"Porvenir","localidad":"Magallanes","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-53.298368,"longitud":-70.368326,"ranking":0},
{"id":8335,"nombre":"Villa Tehuelches","localidad":"Magallanes","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-52.42801,"longitud":-71.412921,"ranking":0},
{"id":8340,"nombre":"Villa Alegre","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.52302517105295,"longitud":-71.68464711897842,"ranking":0},
{"id":8342,"nombre":"Yerbas Buenas","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.748844936302255,"longitud":-71.58790539056182,"ranking":0},
{"id":7467,"nombre":"Parral Acceso","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-36.1326496,"longitud":-71.80760719999999,"ranking":0},
{"id":8337,"nombre":"Curico","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-34.984013674236266,"longitud":-71.24567183842224,"ranking":0},
{"id":8338,"nombre":"Linares","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-35.84699501862369,"longitud":-71.58925268229072,"ranking":0},
{"id":8339,"nombre":"Vichuquen ","localidad":"Maule","provincia":"Region VII - Maule","pais":"CHILE","latitud":-34.84165272872133,"longitud":-72.02088108770853,"ranking":0},
{"id":8355,"nombre":"Santa Cruz","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.641423,"longitud":-71.37136,"ranking":0},
{"id":8356,"nombre":"San Vicente de Tagua Tagua","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.437972,"longitud":-71.078279,"ranking":0},
{"id":9129,"nombre":"San Pedro de Alcantara","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.769183672998324,"longitud":-71.8364628973884,"ranking":0},
{"id":8348,"nombre":"Chimbarongo","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.575415,"longitud":-70.985479,"ranking":0},
{"id":8349,"nombre":"Doñihue","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.231026,"longitud":-70.965569,"ranking":0},
{"id":8350,"nombre":"Machali","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.17461873072233,"longitud":-70.65780245498307,"ranking":0},
{"id":8351,"nombre":"Pichilemu","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.388946,"longitud":-72.015458,"ranking":0},
{"id":8352,"nombre":"Rancagua ","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.161796,"longitud":-70.727709,"ranking":0},
{"id":8354,"nombre":"San Fernando","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.585672,"longitud":-70.982114,"ranking":0},
{"id":8347,"nombre":"Chepica","localidad":"O'Higgins","provincia":"Región VI - O Higgins","pais":"CHILE","latitud":-34.630264,"longitud":-71.114629,"ranking":0},
{"id":8358,"nombre":"Camiña ","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-19.312847,"longitud":-69.426632,"ranking":0},
{"id":8359,"nombre":"Quebrada de Mamiña","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-19.923987519083543,"longitud":-69.51088189368156,"ranking":0},
{"id":8361,"nombre":"Tarapaca","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-20.272608,"longitud":-70.094314,"ranking":0},
{"id":9154,"nombre":"Belen","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-18.489469,"longitud":-69.526327,"ranking":0},
{"id":9189,"nombre":"Codpa","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-18.833384,"longitud":-69.743836,"ranking":0},
{"id":14698,"nombre":"Mamiña","localidad":"Tarapacá","provincia":"Región I - Tarapacá","pais":"CHILE","latitud":-20.0716626,"longitud":-69.21755790000002,"ranking":0},
{"id":10036,"nombre":"Santa Maria","localidad":"Santa Maria","provincia":"Catamarca","pais":"ARGENTINA","latitud":-26.682338228714936,"longitud":-66.04423954262613,"ranking":136},
{"id":8408,"nombre":"Tacuaral","localidad":"Tacuaral","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.871984989274296,"longitud":-57.795660471954335,"ranking":0},
{"id":8409,"nombre":"Puisoye","localidad":"Puisoye","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8410,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8411,"nombre":"Talaty","localidad":"Talaty","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.7608267,"longitud":-57.7346113,"ranking":0},
{"id":8412,"nombre":"Arboleda Acceso","localidad":"Arboleda","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8413,"nombre":"Carandaiti Acceso","localidad":"Carandaiti","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8415,"nombre":"Garabata Acceso","localidad":"Garabata","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8416,"nombre":"Las Maloyas Acceso","localidad":"Las Maloyas","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.5441813,"longitud":-57.91557330000001,"ranking":0},
{"id":8417,"nombre":"Loma Alta Acceso","localidad":"Loma Alta","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.4603848,"longitud":-56.05778480000001,"ranking":0},
{"id":8418,"nombre":"Lomas de Gonzalez Acceso","localidad":"Lomas de Gonzalez","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.506561899462437,"longitud":-58.572942321882124,"ranking":0},
{"id":8419,"nombre":"Lomas de Vallejos Acceso","localidad":"Lomas de Vallejos ","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.738184,"longitud":-57.919826,"ranking":7},
{"id":8420,"nombre":"Paso Florentin Acceso","localidad":"Paso Florentin","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.7453355,"longitud":-57.7811634,"ranking":0},
{"id":8421,"nombre":"Pueblito Espinosa Acceso","localidad":"Pueblito Espinosa","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8422,"nombre":"Rodeito Acceso","localidad":"Rodeito","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8423,"nombre":"Senador Mourao","localidad":"Senador Mourao","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.802001922793757,"longitud":-43.38352025280438,"ranking":0},
{"id":8424,"nombre":"Vargem Linda","localidad":"Vargem Linda","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.944046467041165,"longitud":-42.93685882273705,"ranking":0},
{"id":8452,"nombre":"Palmares","localidad":"Palmares","provincia":"Pará","pais":"BRASIL","latitud":-8.681562932416501,"longitud":-35.58472139235173,"ranking":0},
{"id":14811,"nombre":"Rio Vermelho","localidad":"Rio Vermelho","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8454,"nombre":"Maracaja","localidad":"Maracaja","provincia":"Pará","pais":"BRASIL","latitud":-28.861074291740547,"longitud":-49.45135607825837,"ranking":0},
{"id":8471,"nombre":"Boqueirao  ","localidad":"Boqueirão ","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.303338138620713,"longitud":-52.42829315519163,"ranking":0},
{"id":8120,"nombre":"General Artigas","localidad":"General Artigas","provincia":"Itapua","pais":"Paraguay","latitud":-26.926299,"longitud":-56.211399,"ranking":2},
{"id":7496,"nombre":"Saladillo","localidad":"Saladillo","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7498,"nombre":"El Sauce","localidad":"El Bordo","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7502,"nombre":"Arenal","localidad":"Arenal","provincia":"Salta","pais":"ARGENTINA","latitud":-25.92644384568767,"longitud":-65.10836591097147,"ranking":0},
{"id":7534,"nombre":"Quebracho Coto","localidad":"Quebracho Coto","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7729,"nombre":"Taco Ralo Acceso","localidad":"Taco Ralo","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.833656570539034,"longitud":-65.19058833840775,"ranking":0},
{"id":7535,"nombre":"Puente de Plata","localidad":"Puente de Plata ","provincia":"Salta","pais":"ARGENTINA","latitud":-25.950889608596444,"longitud":-64.71830363337074,"ranking":0},
{"id":8284,"nombre":"Balde de Escudero","localidad":"Balde de Escudero","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.091458195369746,"longitud":-65.56671594415964,"ranking":0},
{"id":7565,"nombre":"Tedin ","localidad":"Tedin","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7564,"nombre":"Cruz Quemada","localidad":"Cruz Quemada","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8285,"nombre":"La Totora","localidad":"La Totora","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7563,"nombre":"Mesitas","localidad":"Mesitas","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2877,"nombre":"Palomitas","localidad":"Palomitas","provincia":"Salta","pais":"ARGENTINA","latitud":-24.905805518607345,"longitud":-64.98214339465325,"ranking":0},
{"id":2876,"nombre":"Cabeza de Buey","localidad":"Cabeza de Buey","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7758,"nombre":"Lamadrid","localidad":"Lamadrid","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.645908662659433,"longitud":-65.24899852145394,"ranking":0},
{"id":8381,"nombre":"El Pueblito","localidad":"El Pueblito","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.283897969423958,"longitud":-67.15225620889456,"ranking":0},
{"id":8382,"nombre":"El Salado","localidad":"El Salado","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.302231684562297,"longitud":-67.24794989078579,"ranking":0},
{"id":7568,"nombre":"El Libano","localidad":"El Libano","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7569,"nombre":"Las Viboras ","localidad":"Las Viboras","provincia":"Salta","pais":"ARGENTINA","latitud":-25.04852913004301,"longitud":-64.640865923851,"ranking":0},
{"id":8383,"nombre":"Copacabana ","localidad":"Copacabana","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.176199796137222,"longitud":-67.48813575964515,"ranking":0},
{"id":8384,"nombre":"La Puntilla","localidad":"La Puntilla","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.6849150592201,"longitud":-66.99377804154332,"ranking":0},
{"id":8385,"nombre":"El Puesto","localidad":"El Puesto","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.95961771820017,"longitud":-67.63100192010272,"ranking":0},
{"id":2030,"nombre":"Urquiza","localidad":"Urquiza","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.9138655,"longitud":-60.5857848,"ranking":0},
{"id":8386,"nombre":"El Potrero","localidad":"El Potrero","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.507281696879677,"longitud":-66.32312347034423,"ranking":0},
{"id":8387,"nombre":"El Charquiadero","localidad":"El Charquiadero","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.425445967682403,"longitud":-65.99288731848227,"ranking":0},
{"id":8388,"nombre":"Alto de las Juntas","localidad":"Alto de las Juntas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.455910916726193,"longitud":-66.00856058845717,"ranking":0},
{"id":8389,"nombre":"Aconquija","localidad":"Aconquija","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.466652221389353,"longitud":-66.0107380933415,"ranking":10},
{"id":9166,"nombre":"Yunka Suma","localidad":"Aconquija","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2243,"nombre":"Charras Acceso","localidad":"Charras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0192196,"longitud":-64.0565633,"ranking":0},
{"id":7768,"nombre":"Charras","localidad":"Charras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.02312960515071,"longitud":-64.0457010269165,"ranking":2},
{"id":8390,"nombre":"El Alamito","localidad":"El Alamito","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.49302115553586,"longitud":-66.02199182258306,"ranking":0},
{"id":8391,"nombre":"Buena Vista","localidad":"Buena Vista","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.506479450668685,"longitud":-66.02329275896598,"ranking":0},
{"id":8286,"nombre":"El Mollar","localidad":"El Mollar","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.940661928888243,"longitud":-65.69567487939396,"ranking":15},
{"id":7570,"nombre":"Paso de la Cruz","localidad":"Paso de la Cruz","provincia":"Salta","pais":"ARGENTINA","latitud":-25.032672078570936,"longitud":-64.57046887358048,"ranking":0},
{"id":7571,"nombre":"Cruce del Anta","localidad":"Cruce del Anta","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8287,"nombre":"Amaicha del Valle","localidad":"Amaicha del Valle","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.592873504167198,"longitud":-65.91808731490575,"ranking":66},
{"id":8392,"nombre":"Leon Rouges","localidad":"Leon Rouges","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.220025828915077,"longitud":-65.52643736203741,"ranking":0},
{"id":3639,"nombre":"Manuel Ocampo","localidad":"Manuel Ocampo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.762317214894566,"longitud":-60.654848893039606,"ranking":0},
{"id":9121,"nombre":"Manuel Ocampo Acceso","localidad":"Manuel Ocampo","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.76203586148273,"longitud":-60.63098699147791,"ranking":0},
{"id":8083,"nombre":"Playadito","localidad":"Playadito","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8404,"nombre":"Santa Margarita","localidad":"Santa Margarita","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.3132094,"longitud":-61.5459515,"ranking":5},
{"id":8406,"nombre":"San Pedro de Guasayan Acceso","localidad":"San Pedro de Guasayan","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.957903743006167,"longitud":-65.1676762462219,"ranking":0},
{"id":8403,"nombre":"Bella Vista","localidad":"Bella Vista","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.034459,"longitud":-65.305333,"ranking":0},
{"id":10038,"nombre":"Bella Vista Acceso","localidad":"Bella Vista","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.03823872508829,"longitud":-65.29579361052375,"ranking":0},
{"id":8500,"nombre":"Paraje Tres Cerros","localidad":"Paraje Tres Cerros","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-48.143475,"longitud":-67.651686,"ranking":13},
{"id":7633,"nombre":"Baldecito","localidad":"Baldecito","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.858653938206412,"longitud":-65.8376667553683,"ranking":1},
{"id":8014,"nombre":"Ingeniero Faure","localidad":"Ingeniero Faure","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8013,"nombre":"Monte Lindo","localidad":"Monte Lindo","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7859,"nombre":"Los Huarpes","localidad":"Los Huarpes","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7765,"nombre":"General Lagos","localidad":"General Lagos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7888,"nombre":"Villa Espil","localidad":"Villa Espil","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.50535858857119,"longitud":-59.33829587723801,"ranking":0},
{"id":7893,"nombre":"La Invernada","localidad":"La Invernada","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.38254271172631,"longitud":-63.48806650308339,"ranking":0},
{"id":7896,"nombre":"San Gustavo","localidad":"San Gustavo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.690049178263088,"longitud":-59.394892597093126,"ranking":0},
{"id":7904,"nombre":"El Solar","localidad":"El Solar","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8085,"nombre":"Villa Urquiza","localidad":"Villa Urquiza","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.649955,"longitud":-60.376837,"ranking":0},
{"id":8086,"nombre":"Rio Branco","localidad":"Rio Branco","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.58102660757029,"longitud":-53.377130540009404,"ranking":0},
{"id":8146,"nombre":"Zaballa","localidad":"Zaballa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8147,"nombre":"Los Nogales","localidad":"Los Nogales","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.14022623671222,"longitud":-61.604191414069625,"ranking":0},
{"id":8148,"nombre":"San Jose de la Esquina","localidad":"San Jose de la Esquina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8149,"nombre":"Esquina de Crotto","localidad":"Esquina de Crotto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.30119252774745,"longitud":-57.38218680766339,"ranking":0},
{"id":8213,"nombre":"Arroyo Algodon","localidad":"Arroyo Algodon","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2040395,"longitud":-63.1625327,"ranking":3},
{"id":15383,"nombre":"Pastoreo","localidad":"Pastoreo","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.382914604835314,"longitud":-55.8327930413077,"ranking":0},
{"id":2870,"nombre":"Campo 9","localidad":"Juan Eulogio Estigarribia","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.37862,"longitud":-55.707862,"ranking":51},
{"id":8126,"nombre":"Juan E Estigarribia","localidad":"Juan Eulogio Estigarribia","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.37188,"longitud":-55.707262,"ranking":0},
{"id":12588,"nombre":"Campo 8","localidad":"Juan Eulogio Estigarribia","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.3777368,"longitud":-55.7205769,"ranking":0},
{"id":15382,"nombre":"Juan Emilio OLeary","localidad":"Juan Emilio OLeary","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.42233467462005,"longitud":-55.37789491887556,"ranking":0},
{"id":8127,"nombre":"Juan Leon Mallorquin","localidad":"Juan León Mallorquín","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.438796,"longitud":-55.258439,"ranking":8},
{"id":2255,"nombre":"Benjamin Gould Acceso","localidad":"Benjamin Gould","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.5607133,"longitud":-62.71896280000001,"ranking":0},
{"id":8101,"nombre":"Benjamin Gould","localidad":"Benjamin Gould","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.58792067605226,"longitud":-62.72830266040336,"ranking":0},
{"id":8128,"nombre":"Tavapy","localidad":"Tavapy","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.663599,"longitud":-54.99393,"ranking":0},
{"id":8103,"nombre":"Buchardo","localidad":"Buchardo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.7221223,"longitud":-63.509334,"ranking":12},
{"id":8131,"nombre":"Capitan Meza","localidad":"Capitan Meza","provincia":"Itapua","pais":"Paraguay","latitud":-26.763757,"longitud":-55.385321,"ranking":0},
{"id":12562,"nombre":"Desvio Capitan Meza","localidad":"Capitan Meza","provincia":"Itapua","pais":"Paraguay","latitud":-26.7637661,"longitud":-55.3853212,"ranking":0},
{"id":8106,"nombre":"Camilo Aldao","localidad":"Camilo Aldao","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.1248721,"longitud":-62.0940233,"ranking":0},
{"id":8107,"nombre":"Cavanagh","localidad":"Cavanagh ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.4790356,"longitud":-62.333825,"ranking":0},
{"id":8133,"nombre":"Yuty","localidad":"Yuty","provincia":"Caazapá","pais":"Paraguay","latitud":-26.613355,"longitud":-56.24915,"ranking":38},
{"id":8215,"nombre":"Cayuqueo","localidad":"Cayuqueo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.78120182928861,"longitud":-63.11696139537825,"ranking":0},
{"id":8134,"nombre":"Pirapo","localidad":"Pirapo","provincia":"Itapua","pais":"Paraguay","latitud":-26.858102,"longitud":-55.545319,"ranking":0},
{"id":8259,"nombre":"Colonia Almada","localidad":"Colonia Almada","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8135,"nombre":"Bermejo","localidad":"Bermejo","provincia":"Tarija","pais":"BOLIVIA","latitud":-22.74043576209953,"longitud":-64.33643540435409,"ranking":0},
{"id":8108,"nombre":"Colonia Bismarck","localidad":"Colonia Bismarck","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8109,"nombre":"Colonia Bremen","localidad":"Colonia Bremen","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.464289089907886,"longitud":-62.73129429923013,"ranking":0},
{"id":8110,"nombre":"Cruz Alta","localidad":"Cruz Alta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.00612203208562,"longitud":-61.815872253772945,"ranking":0},
{"id":8111,"nombre":"Guatimozin","localidad":"Guatimozín","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.46186068558903,"longitud":-62.4334230421664,"ranking":0},
{"id":8112,"nombre":"Idiazabal","localidad":"Idiazabal","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8114,"nombre":"La Cesira","localidad":"La Cesira","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8222,"nombre":"Las Mojarras","localidad":"Las Mojarras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.303994078961125,"longitud":-63.23404073270754,"ranking":0},
{"id":8115,"nombre":"Colonia Santa Maria","localidad":"Colonia Santa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.607092877150365,"longitud":-62.42832407671919,"ranking":0},
{"id":8138,"nombre":"El Carril","localidad":"El Carril","provincia":"Salta","pais":"ARGENTINA","latitud":-25.076598330939067,"longitud":-65.48887889293998,"ranking":0},
{"id":8116,"nombre":"Los Surgentes","localidad":"Los Surgentes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.9837263,"longitud":-62.0216849,"ranking":0},
{"id":7875,"nombre":"Mattaldi","localidad":"Mattaldi","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.4863654,"longitud":-64.1768278,"ranking":19},
{"id":8140,"nombre":"El Barrial","localidad":"El Barrial","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8227,"nombre":"Melo","localidad":"Melo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.34829037892673,"longitud":-63.442182540893555,"ranking":1},
{"id":8141,"nombre":"Animana","localidad":"Animana","provincia":"Salta","pais":"ARGENTINA","latitud":-25.969136315743693,"longitud":-65.94899189103906,"ranking":0},
{"id":8142,"nombre":"Santa Barbara","localidad":"Santa Barbara","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8143,"nombre":"Tres Cruces","localidad":"Tres Cruces","provincia":"Salta","pais":"ARGENTINA","latitud":-25.875745035525487,"longitud":-65.70636011790397,"ranking":0},
{"id":8180,"nombre":"Talapampa","localidad":"Talapampa","provincia":"Salta","pais":"ARGENTINA","latitud":-25.547576994337394,"longitud":-65.56681503020992,"ranking":0},
{"id":8229,"nombre":"Monte Buey","localidad":"Monte Buey","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":2742,"nombre":"Nicolas Bruzzone","localidad":"Nicolas Bruzzone","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.44030790390136,"longitud":-64.34200012501877,"ranking":0},
{"id":7910,"nombre":"Nicolas Bruzzone Acceso ","localidad":"Nicolas Bruzzone","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.43765980000001,"longitud":-64.3791538,"ranking":0},
{"id":8231,"nombre":"Ordoñez","localidad":"Ordoñez","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.84175463062959,"longitud":-62.87242917161466,"ranking":0},
{"id":8234,"nombre":"Pueblo Italiano","localidad":"Pueblo Italiano","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8239,"nombre":"Pacheco de Melo","localidad":"Pacheco de Melo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8243,"nombre":"Ramon Jose Carcano","localidad":"Ramon Jose Carcano","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.464682616543623,"longitud":-64.14360543274614,"ranking":0},
{"id":8248,"nombre":"Rosales","localidad":"Rosales ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.172046540433634,"longitud":-63.154604954007965,"ranking":0},
{"id":8926,"nombre":"Saladillo","localidad":"Saladillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.93505903294532,"longitud":-62.343435127470926,"ranking":0},
{"id":8253,"nombre":"San Joaquin","localidad":"San Joaquin","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8256,"nombre":"Trinchera","localidad":"Trinchera","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.948552235051725,"longitud":-62.821862707007774,"ranking":0},
{"id":8257,"nombre":"Ucacha","localidad":"Ucacha","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.041557,"longitud":-63.511971,"ranking":35},
{"id":8258,"nombre":"Villa Ascasubi ","localidad":"Villa Ascasubi","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.16305087648527,"longitud":-63.892676831407094,"ranking":0},
{"id":8261,"nombre":"Viamonte","localidad":"Viamonte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.74612028872971,"longitud":-63.09734571755609,"ranking":0},
{"id":8262,"nombre":"Villa Valeria","localidad":"Villa Valeria","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8175,"nombre":"Angastaco","localidad":"Angastaco","provincia":"Salta","pais":"ARGENTINA","latitud":-25.69106386896029,"longitud":-66.16695147387209,"ranking":0},
{"id":8173,"nombre":"San Francisco","localidad":"San Francisco","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8172,"nombre":"Arroyo del Medio","localidad":"Arroyo del Medio","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8171,"nombre":"Monte Veloz","localidad":"Monte Veloz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.46196002905591,"longitud":-57.28445595456464,"ranking":0},
{"id":8170,"nombre":"Pipinas","localidad":"Pipinas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.529803,"longitud":-57.328582,"ranking":0},
{"id":8169,"nombre":"Las Tahonas","localidad":"Las Tahonas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.3623922,"longitud":-57.3986407,"ranking":0},
{"id":8168,"nombre":"Alvarez Jonte","localidad":"Alvarez Jonte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.321933,"longitud":-57.454369,"ranking":0},
{"id":8166,"nombre":"El Chacho","localidad":"El Chacho","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8164,"nombre":"Santa Teresa Acceso","localidad":"Santa Teresa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.4340843884129,"longitud":-60.772742991564336,"ranking":0},
{"id":8163,"nombre":"Lapachito","localidad":"Lapachito ","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.16243805530151,"longitud":-59.3848304660615,"ranking":0},
{"id":10850,"nombre":"Lapachito Acceso","localidad":"Lapachito ","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.1608756,"longitud":-59.3914504,"ranking":6},
{"id":8162,"nombre":"Hickman","localidad":"Hickman","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8161,"nombre":"Pluma del Pato","localidad":"Pluma del Pato","provincia":"Salta","pais":"ARGENTINA","latitud":-23.370905521103413,"longitud":-63.09575050448244,"ranking":0},
{"id":8160,"nombre":"Laguna Yema","localidad":"Laguna Yema","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.250757,"longitud":-61.23713780000001,"ranking":4},
{"id":8158,"nombre":"Dragones","localidad":"Dragones","provincia":"Salta","pais":"ARGENTINA","latitud":-23.24864049950322,"longitud":-63.34035011187638,"ranking":0},
{"id":8157,"nombre":"San Ignacio","localidad":"San Ignacio","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.131308300,"longitud":-69.792709400,"ranking":0},
{"id":8153,"nombre":"Casa Grande","localidad":"Casa Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.154092451970058,"longitud":-64.47316312079178,"ranking":10},
{"id":8761,"nombre":"Hoteles Casa Grande","localidad":"Casa Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10097,"nombre":"Parador Casa Grande","localidad":"Casa Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.167629,"longitud":-64.475926,"ranking":0},
{"id":8102,"nombre":"Rio de Los Sauces","localidad":"Rio de los Sauces","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.5265222,"longitud":-64.5885749,"ranking":16},
{"id":8104,"nombre":"Cuesta Blanca","localidad":"Cuesta Blanca","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.485963,"longitud":-64.570016,"ranking":12},
{"id":8117,"nombre":"Bardas del Medio","localidad":"Bardas del Medio","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.726056953822386,"longitud":-68.16225888038377,"ranking":0},
{"id":2340,"nombre":"El Mojon","localidad":"El Mojon","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-26.093765798154337,"longitud":-64.31021878725618,"ranking":0},
{"id":8519,"nombre":"Colonia San Jose","localidad":"Colonia San Jose","provincia":"Jujuy","pais":"ARGENTINA","latitud":-23.38820553091573,"longitud":-65.34484374566863,"ranking":0},
{"id":8520,"nombre":"Pata Mora","localidad":"Pata Mora","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1600,"nombre":"Piedritas Acceso","localidad":"Piedritas","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.4448516,"longitud":-57.9852497,"ranking":0},
{"id":8523,"nombre":"Piedritas","localidad":"Piedritas","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.454738116532873,"longitud":-57.984642633162714,"ranking":0},
{"id":8525,"nombre":"Roversi","localidad":"Roversi","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-27.598636608160973,"longitud":-61.94418076847852,"ranking":0},
{"id":8526,"nombre":"Girardet","localidad":"Girardet","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":4691,"nombre":"Cerro Castillo","localidad":"Torres del Paine","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-51.257764,"longitud":-72.345616,"ranking":0},
{"id":8564,"nombre":"Laguna Amarga","localidad":"Torres del Paine","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-50.98112,"longitud":-72.76395,"ranking":0},
{"id":8565,"nombre":"Torres del Paine","localidad":"Torres del Paine","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-51.259265,"longitud":-72.346079,"ranking":0},
{"id":10901,"nombre":"Pudeto","localidad":"Torres del Paine","provincia":"Región XII - Magallanes","pais":"CHILE","latitud":-41.8787639,"longitud":-73.79400609999999,"ranking":0},
{"id":8566,"nombre":"La Linea Acceso","localidad":"Los Helechos","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8567,"nombre":"Los Helechos Acceso","localidad":"Los Helechos","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.54689769848931,"longitud":-55.0726473612156,"ranking":0},
{"id":8570,"nombre":"Panambi","localidad":"Panambi","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.721595933753225,"longitud":-54.91525976275287,"ranking":0},
{"id":8571,"nombre":"Parada Leis","localidad":"Parada Leis","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8578,"nombre":"Santa Rosa del Aguaray Acceso","localidad":"Santa Rosa del Aguaray","provincia":"San Pedro","pais":"Paraguay","latitud":-23.801972,"longitud":-56.503522,"ranking":0},
{"id":10043,"nombre":"Santa Rosa del Aguaray","localidad":"Santa Rosa del Aguaray","provincia":"San Pedro","pais":"Paraguay","latitud":-23.828572,"longitud":-56.519473,"ranking":61},
{"id":12392,"nombre":"Lopez Salinas","localidad":"Santa Rosa del Aguaray","provincia":"San Pedro","pais":"Paraguay","latitud":-23.833028,"longitud":-56.418623,"ranking":0},
{"id":8579,"nombre":"Pozo Colorado","localidad":"Pozo Colorado","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-23.493928,"longitud":-58.794259,"ranking":0},
{"id":8583,"nombre":"Laharrague","localidad":"Puerto Laharrague","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.53865048880427,"longitud":-54.72463271708205,"ranking":0},
{"id":8584,"nombre":"Londero Acceso","localidad":"Puerto Londero","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.372842909762838,"longitud":-54.40315413194603,"ranking":0},
{"id":8585,"nombre":"Londero","localidad":"Puerto Londero","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8599,"nombre":"Horqueta","localidad":"Horqueta","provincia":"Concepción","pais":"Paraguay","latitud":-23.340944,"longitud":-57.046067,"ranking":27},
{"id":8613,"nombre":"Ivailandia","localidad":"Ivailândia ","provincia":"Parana","pais":"BRASIL","latitud":-23.720185319732323,"longitud":-52.17884285564949,"ranking":0},
{"id":8639,"nombre":"Bataipora","localidad":"Bataiporã","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.296121851052582,"longitud":-53.26597846304009,"ranking":0},
{"id":8656,"nombre":"Traslado a La Cumbrecita","localidad":"La Cumbrecita","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":2},
{"id":10671,"nombre":"La Cumbrecita","localidad":"La Cumbrecita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.900681,"longitud":-64.773146,"ranking":0},
{"id":8658,"nombre":"Cachi","localidad":"Cachi","provincia":"Salta","pais":"ARGENTINA","latitud":-25.120019,"longitud":-66.16237439999999,"ranking":0},
{"id":8659,"nombre":"Salinas Grandes","localidad":"Salinas Grandes","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8676,"nombre":"Venancio Aires","localidad":"Venâncio Aires","provincia":"Río Grande del Sur","pais":"BRASIL","latitud":-29.61170796314786,"longitud":-52.18533809221607,"ranking":0},
{"id":8680,"nombre":"Liberacion","localidad":"Liberación","provincia":"San Pedro","pais":"Paraguay","latitud":-24.191494,"longitud":-56.435652,"ranking":3},
{"id":10044,"nombre":"Liberacion Acceso","localidad":"Liberación","provincia":"San Pedro","pais":"Paraguay","latitud":-24.191494,"longitud":-56.435652,"ranking":0},
{"id":8681,"nombre":"Lima","localidad":"Lima","provincia":"San Pedro","pais":"Paraguay","latitud":-23.880523,"longitud":-56.482609,"ranking":0},
{"id":8682,"nombre":"Nueva Germania","localidad":"Nueva Germania","provincia":"San Pedro","pais":"Paraguay","latitud":-23.911675,"longitud":-56.701338,"ranking":1},
{"id":8683,"nombre":"San Pedro del Ycuamandiyu","localidad":"San Pedro del Ycuamandiyu","provincia":"San Pedro","pais":"Paraguay","latitud":-24.094982,"longitud":-57.079831,"ranking":33},
{"id":8684,"nombre":"Pirahu","localidad":"Pirahu","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-23.656455,"longitud":-58.697237,"ranking":0},
{"id":8685,"nombre":"Benjamin Aceval","localidad":"Benjamin Aceval","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-24.98942,"longitud":-57.555301,"ranking":0},
{"id":8686,"nombre":"Naranjito","localidad":"Naranjito","provincia":"Itapua","pais":"Paraguay","latitud":-26.360267,"longitud":-55.241562,"ranking":0},
{"id":8692,"nombre":"Antequera ","localidad":"Antequera ","provincia":"San Pedro","pais":"Paraguay","latitud":-24.081172,"longitud":-57.204604,"ranking":2},
{"id":8699,"nombre":"Reta - Hotel Renelen (Los Narcisos y los claveles)","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.893505,"longitud":-60.346523,"ranking":0},
{"id":8701,"nombre":"Reta - Hotel Reta (Viejo marino y los girasoles)","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.894837,"longitud":-60.33543,"ranking":0},
{"id":8704,"nombre":"Reta - Camping (StellaMaris y Pensamientos)","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.89986,"longitud":-60.347757,"ranking":0},
{"id":8707,"nombre":"Reta - Ht San Francisco (Peoníasy Anémonas)","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.8951912,"longitud":-60.3314586,"ranking":0},
{"id":8710,"nombre":"Reta - Local Diferente","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.8972473,"longitud":-60.3425914,"ranking":0},
{"id":9823,"nombre":"Balneario Reta - Arco de Entrada ","localidad":"Reta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.896015,"longitud":-60.343597,"ranking":0},
{"id":8702,"nombre":"Oriente (Santa Marina 470)","localidad":"Oriente","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.750476903554826,"longitud":-60.60036478444773,"ranking":0},
{"id":8722,"nombre":"Olaeta","localidad":"Olaeta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.0455348,"longitud":-63.9064686,"ranking":0},
{"id":8723,"nombre":"Punta de Agua","localidad":"Punta de Agua","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.57608932687658,"longitud":-63.810941968463865,"ranking":0},
{"id":8725,"nombre":"Bengolea","localidad":"Bengolea","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.03048617185267,"longitud":-63.67344088835209,"ranking":2},
{"id":8727,"nombre":"Amancay","localidad":"Amancay","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.18451244679556,"longitud":-64.5763511704132,"ranking":0},
{"id":8729,"nombre":"Amboy","localidad":"Amboy","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.17434868044535,"longitud":-64.57394087524474,"ranking":0},
{"id":8731,"nombre":"San Ignacio","localidad":"San Ignacio","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8740,"nombre":"San Vicente","localidad":"San Vicente","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.8507515,"longitud":-65.4288365,"ranking":1},
{"id":8737,"nombre":"La Carolina","localidad":"La Carolina","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8734,"nombre":"Guardia Vieja","localidad":"Guardia Vieja","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8732,"nombre":"Rio Bamba","localidad":"Rio Bamba","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.0519515553822,"longitud":-63.73204504645503,"ranking":0},
{"id":8743,"nombre":"Palmar Grande","localidad":"Palmar Grande","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.941097,"longitud":-57.900324,"ranking":3},
{"id":8762,"nombre":"Hoteles Bialet Masse","localidad":"Bailet Masse","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.299249980799335,"longitud":-64.46749565612869,"ranking":0},
{"id":8767,"nombre":"Colonia Caroya","localidad":"Colonia Caroya","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.991189,"longitud":-64.097518,"ranking":66},
{"id":9408,"nombre":"Estación Caroya","localidad":"Colonia Caroya","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0208222,"longitud":-64.1141682,"ranking":0},
{"id":8774,"nombre":"General Paz","localidad":"General Paz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.425576,"longitud":-64.171097,"ranking":2},
{"id":8807,"nombre":"Aracatuba","localidad":"Araçatuba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.128213087972558,"longitud":-48.69215645332586,"ranking":0},
{"id":8808,"nombre":"Vila Sao Joao","localidad":"Vila São João","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.310385857539394,"longitud":-49.773507684899535,"ranking":0},
{"id":8809,"nombre":"Vila do Equador","localidad":"Vila do Equador","provincia":"Roraima","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8812,"nombre":"Villa Santa Cruz del Lago","localidad":"Villa Santa Cruz del Lago","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.369999,"longitud":-64.512721,"ranking":0},
{"id":8813,"nombre":"Mayu Sumaj","localidad":"Mayu Sumaj","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.474097,"longitud":-64.540805,"ranking":5},
{"id":8816,"nombre":"San Ramon","localidad":"San Ramón","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-27.027169,"longitud":-56.698831,"ranking":0},
{"id":8817,"nombre":"Baibiene","localidad":"Baibiene","provincia":"Corrientes","pais":"ARGENTINA","latitud":-29.59887733470523,"longitud":-58.126593104163746,"ranking":0},
{"id":8819,"nombre":"Saltinho","localidad":"Saltinho","provincia":"Parana","pais":"BRASIL","latitud":-26.605401345387026,"longitud":-53.058788463484206,"ranking":0},
{"id":6078,"nombre":"Gama","localidad":"Gama","provincia":"Distrito Federal","pais":"BRASIL","latitud":-16.02023344685904,"longitud":-48.06698677991679,"ranking":0},
{"id":8827,"nombre":"Rochedo","localidad":"Rochedo","provincia":"Goias","pais":"BRASIL","latitud":-17.390295204731,"longitud":-49.22766217715723,"ranking":0},
{"id":8828,"nombre":"Luzelandia","localidad":"Luzelândia","provincia":"Goias","pais":"BRASIL","latitud":-14.57984673441401,"longitud":-49.583458774610385,"ranking":0},
{"id":8829,"nombre":"Cedrolina","localidad":"Cedrolina","provincia":"Goias","pais":"BRASIL","latitud":-14.72482688397473,"longitud":-49.463661091224274,"ranking":0},
{"id":8830,"nombre":"Macaubas","localidad":"Macaúbas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.933821831105323,"longitud":-47.22775978781233,"ranking":0},
{"id":8831,"nombre":"Pindaiba ","localidad":"Pindaíba","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8832,"nombre":"Tapuirama","localidad":"Tapuirama","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.921743987558948,"longitud":-48.20532028132384,"ranking":0},
{"id":8833,"nombre":"Salitre de Minas","localidad":"Salitre de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.113096373678992,"longitud":-46.68072975421129,"ranking":0},
{"id":8834,"nombre":"Palmeiras","localidad":"Palmeiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8835,"nombre":"Barra do Guaicui","localidad":"Barra do Guaicui","provincia":"Minas Gerais","pais":"BRASIL","latitud":-17.201005930336873,"longitud":-44.81666605785563,"ranking":0},
{"id":8836,"nombre":"Celso Bueno","localidad":"Celso Bueno","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.914639139432825,"longitud":-47.3946421462433,"ranking":0},
{"id":8837,"nombre":"Monte Real","localidad":"Monte Real","provincia":"Parana","pais":"BRASIL","latitud":-23.23863058139398,"longitud":-50.120626072767635,"ranking":0},
{"id":8838,"nombre":"Harmonia","localidad":"Harmonia","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8840,"nombre":"Paulopolis","localidad":"Paulópolis","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.099200967951848,"longitud":-50.25079636440255,"ranking":0},
{"id":8841,"nombre":"Padre Nobrega","localidad":"Padre Nóbrega","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.155631240918723,"longitud":-50.01071583593723,"ranking":0},
{"id":8845,"nombre":"Ipaucu","localidad":"Ipauçu","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.053387430566595,"longitud":-49.62305722488566,"ranking":0},
{"id":8870,"nombre":"Jardim Paulista","localidad":"Jardim Paulista","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8871,"nombre":"Interlandia","localidad":"Interlândia","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":8927,"nombre":"Los Patos","localidad":"Los Patos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8929,"nombre":"Las Acequias","localidad":"Las Acequias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.2807765,"longitud":-63.97723879999999,"ranking":1},
{"id":10052,"nombre":"Las Acequias Acceso","localidad":"Las Acequias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.18111562415723,"longitud":-63.981558003954774,"ranking":0},
{"id":8940,"nombre":"La Escondida","localidad":"La Escondida","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.1056871,"longitud":-59.4460442,"ranking":0},
{"id":14233,"nombre":"La Escondida Acceso","localidad":"La Escondida","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.1050297,"longitud":-59.4538594,"ranking":0},
{"id":8945,"nombre":"El Milagro","localidad":"El Milagro","provincia":"San Luis","pais":"ARGENTINA","latitud":-35.68326232412789,"longitud":-65.91654561648397,"ranking":0},
{"id":8946,"nombre":"La Cumbre","localidad":"La Cumbre","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8947,"nombre":"La Cumbre","localidad":"La Cumbre","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8948,"nombre":"Ojo del Rio","localidad":"Ojo del Río","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.435303122067985,"longitud":-65.19987914214332,"ranking":0},
{"id":8949,"nombre":"Los Puquios","localidad":"Los Puquios","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8950,"nombre":"Saladillo","localidad":"Saladillo","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.20117876329618,"longitud":-65.85299305780282,"ranking":0},
{"id":8951,"nombre":"San Miguel","localidad":"San Miguel","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8954,"nombre":"San Francisco del Chañar","localidad":"San Francisco del Chañar","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.788445,"longitud":-63.942319,"ranking":29},
{"id":8957,"nombre":"Saldan","localidad":"Saldán","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.27379759999999,"longitud":-64.3372485,"ranking":0},
{"id":8958,"nombre":"Porton de Piedra","localidad":"Portón de Piedra","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.313069,"longitud":-64.297598,"ranking":0},
{"id":8972,"nombre":"Santa Rosa de Rio Primero","localidad":"Santa Rosa de Río Primero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1539332,"longitud":-63.4059691,"ranking":32},
{"id":9004,"nombre":"Tacuaritinga","localidad":"Tacuaritinga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.411392660372766,"longitud":-48.499200002708825,"ranking":0},
{"id":9008,"nombre":"Posto Pontilhao 300 (Guaranta)","localidad":"Posto Pontilhao 300 (Guaranta)","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.898979390535956,"longitud":-49.58952913202951,"ranking":0},
{"id":9019,"nombre":"Assu","localidad":"Assu","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.584863423356817,"longitud":-36.911497792204784,"ranking":0},
{"id":9022,"nombre":"Campo Grande","localidad":"Campo Grande","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.86330452406931,"longitud":-37.31211574846287,"ranking":0},
{"id":9027,"nombre":"Sao Rafael","localidad":"Sao Rafael","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-5.802839859374158,"longitud":-36.88700965401052,"ranking":0},
{"id":9030,"nombre":"Sao Jose do Mipibu","localidad":"Sao Jose do Mipibu","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":-6.071978873239928,"longitud":-35.23626194974372,"ranking":0},
{"id":9043,"nombre":"Colonia Cello Acceso","localidad":"Colonia Cello","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.433149860345054,"longitud":-61.835745708755056,"ranking":0},
{"id":10618,"nombre":"Colonia Cello","localidad":"Colonia Cello","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9053,"nombre":"Sierra y Laguna de los Padres","localidad":"Sierra de los Padres","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15413,"nombre":"Cajamarca","localidad":"Cajamarca","provincia":"Cajamarca","pais":"Perú","latitud":-7.165437348770438,"longitud":-78.5026019790199,"ranking":25},
{"id":15643,"nombre":"San Marcos","localidad":"Cajamarca","provincia":"Cajamarca","pais":"Perú","latitud":-8.1173827,"longitud":-79.0290495,"ranking":0},
{"id":15644,"nombre":"Cajabamba","localidad":"Cajamarca","provincia":"Cajamarca","pais":"Perú","latitud":-7.6196113,"longitud":-78.0471383,"ranking":0},
{"id":9073,"nombre":"San Antonio","localidad":"San Antonio","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.368376605219485,"longitud":-65.33405589476925,"ranking":0},
{"id":9076,"nombre":"Laplace Acceso","localidad":"Laplace","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9077,"nombre":"Maria Esther","localidad":"Maria Esther","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9081,"nombre":"Capioviciño","localidad":"Capioviciño","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.926593288353736,"longitud":-55.05991530606178,"ranking":0},
{"id":9094,"nombre":"20 de Junio","localidad":"20 de Junio","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.908115403065768,"longitud":-59.13724843312516,"ranking":0},
{"id":9095,"nombre":"25 de Febrero","localidad":"25 de Febrero","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9099,"nombre":"Comandante Fernandez","localidad":"Comandante Fernandez","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9103,"nombre":"La Guampita","localidad":"La Guampita","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.60139297640647,"longitud":-60.23238222683205,"ranking":0},
{"id":9107,"nombre":"La Picada","localidad":"La Picada","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.7247777,"longitud":-60.2977807,"ranking":0},
{"id":9114,"nombre":"Morse Acceso","localidad":"Morse","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.78025487116225,"longitud":-60.86695387347161,"ranking":0},
{"id":9116,"nombre":"Monteagudo","localidad":"Monteagudo","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.510816958913463,"longitud":-65.27732990995382,"ranking":0},
{"id":9119,"nombre":"Alemania","localidad":"Alemania","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9120,"nombre":"Matacos","localidad":"Matacos","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9125,"nombre":"Alfalfalito","localidad":"Alfalfalito","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9127,"nombre":"Alto Verde","localidad":"Alto Verde","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9128,"nombre":"Angelito","localidad":"Angelito","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.346157667,"longitud":-58.438024215,"ranking":0},
{"id":9130,"nombre":"Arbolito","localidad":"Arbolito","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.61288798725215,"longitud":-54.216275713830434,"ranking":0},
{"id":9131,"nombre":"Arroyo Quintana","localidad":"Arroyo Quintana","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9132,"nombre":"Av Callao y Av Corrientes","localidad":"Barrio Norte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.60440828047948,"longitud":-58.3923160036971,"ranking":0},
{"id":9133,"nombre":"Av Callao y Av Santa Fe","localidad":"Barrio Norte","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.595941844068896,"longitud":-58.39352972162393,"ranking":0},
{"id":9136,"nombre":"Av. San Martin y 9 de Julio ","localidad":"Riacho He He","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10851,"nombre":"Riacho He He","localidad":"Riacho He He","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.360971,"longitud":-58.281332,"ranking":35},
{"id":9146,"nombre":"Barbosa","localidad":"Barbosa","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9147,"nombre":"Barreal","localidad":"Calingasta","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.634756,"longitud":-69.475411,"ranking":455},
{"id":9174,"nombre":"Calingasta","localidad":"Calingasta","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.332089,"longitud":-69.416399,"ranking":255},
{"id":9148,"nombre":"Las Piedritas","localidad":"Las Piedritas","provincia":"Tucumán","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9149,"nombre":"Barreto","localidad":"Barreto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9150,"nombre":"Bartola","localidad":"Bartola","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9151,"nombre":"Los Talas","localidad":"Los Talas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.94923985467981,"longitud":-65.86465457187747,"ranking":0},
{"id":9152,"nombre":"Bartolo","localidad":"Bartolo","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9156,"nombre":"Bernardo Llaraou","localidad":"Bernardo Llaraou","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.02617004486896,"longitud":-63.58183485230583,"ranking":0},
{"id":9157,"nombre":"Birasol","localidad":"Birasol","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9159,"nombre":"Treinta y Tres","localidad":"Treinta y Tres","provincia":"Treinta y Tres","pais":"URUGUAY","latitud":-33.21989505998347,"longitud":-54.38837443889497,"ranking":0},
{"id":9161,"nombre":"Villa Maza","localidad":"Adolfo Alsina","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.1754353,"longitud":-62.7525151,"ranking":0},
{"id":9170,"nombre":"Brouchard","localidad":"Brouchard","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9171,"nombre":"Brown","localidad":"Brown","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9177,"nombre":"Caracollo","localidad":"Caracollo","provincia":"Oruro","pais":"BOLIVIA","latitud":-17.65086659708132,"longitud":-67.2096671905699,"ranking":0},
{"id":9221,"nombre":"Caraibeiras","localidad":"Caraibeiras","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.103610768146329,"longitud":-38.077555335814914,"ranking":0},
{"id":9223,"nombre":"Tabaratu","localidad":"Tabaratu","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.105275746525736,"longitud":-38.149894353372744,"ranking":0},
{"id":9231,"nombre":"Moraes de Almeida","localidad":"Moraes de Almeida","provincia":"Pará","pais":"BRASIL","latitud":-6.213529454407428,"longitud":-55.627931443215004,"ranking":0},
{"id":9266,"nombre":"Ipoema","localidad":"Ipoema","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.621481987087897,"longitud":-43.435029217288154,"ranking":0},
{"id":9279,"nombre":"Marilandia","localidad":"Marilândia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9281,"nombre":"Santo Antonio do Monte","localidad":"Santo Antonio do Monte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.084552538700247,"longitud":-45.29734489708389,"ranking":0},
{"id":9308,"nombre":"Piedade do Paraopeba","localidad":"Piedade do Paraopeba","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.165670627497406,"longitud":-44.02288518424021,"ranking":0},
{"id":9327,"nombre":"Senhora do Carmo","localidad":"Senhora do Carmo","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9407,"nombre":"Barrio Norte","localidad":"Barrio Norte","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9409,"nombre":"Sinsacate","localidad":"Sinsacate","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.942948,"longitud":-64.0932771,"ranking":0},
{"id":9410,"nombre":"Barranca Yaco","localidad":"Barranca Yaco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.860189872066968,"longitud":-64.10250866138695,"ranking":0},
{"id":9411,"nombre":"Empalme Ruta 60","localidad":"Empalme Ruta 60","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7879144,"longitud":-64.067217,"ranking":0},
{"id":9412,"nombre":"Los Mistoles","localidad":"Los Mistoles","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9849,"nombre":"Estacion los Mistoles","localidad":"Los Mistoles","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.62724489991456,"longitud":-63.886691061077414,"ranking":0},
{"id":9413,"nombre":"Simbolar","localidad":"Simbolar ","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.4756374,"longitud":-63.9850663,"ranking":2},
{"id":9423,"nombre":"Guayascate","localidad":"Guayascate","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.238898935536493,"longitud":-63.94339188833085,"ranking":0},
{"id":9424,"nombre":"La Toma","localidad":"La Toma","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.202374919743598,"longitud":-64.03354998833748,"ranking":0},
{"id":9427,"nombre":"Buen Retiro","localidad":"Buen Retiro","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":2},
{"id":9429,"nombre":"Escobas","localidad":"Escobas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.753596800878732,"longitud":-64.56887109355331,"ranking":2},
{"id":9430,"nombre":"El Carrizal","localidad":"El Carrizal","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9431,"nombre":"El Salto","localidad":"El Salto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.1616305278922,"longitud":-64.24380149428062,"ranking":0},
{"id":9438,"nombre":"Santa Cecilia","localidad":"Santa Cecilia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3582602,"longitud":-64.2276923,"ranking":0},
{"id":9439,"nombre":"El Retiro","localidad":"El Retiro","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9441,"nombre":"La Florida","localidad":"La Florida","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9448,"nombre":"El Perchel","localidad":"El Perchel","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9449,"nombre":"Caminiaga","localidad":"Caminiaga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.067896455373603,"longitud":-64.05080162093486,"ranking":0},
{"id":9450,"nombre":"Cerro Colorado","localidad":"Cerro Colorado","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9451,"nombre":"Jaime Peters","localidad":"Jaime Peters","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.40958629999999,"longitud":-64.540717,"ranking":0},
{"id":9452,"nombre":"El Almacen","localidad":"El Almacen","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.414689861016555,"longitud":-64.17342591930772,"ranking":0},
{"id":9453,"nombre":"El Puente","localidad":"El Puente","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.39908254720081,"longitud":-63.050738950735955,"ranking":0},
{"id":9454,"nombre":"El Valle","localidad":"El Valle","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9455,"nombre":"Villa del Lago","localidad":"Villa del Lago","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.368992657732683,"longitud":-64.52067259920213,"ranking":8},
{"id":9460,"nombre":"Parque Siquiman","localidad":"Parque Siquiman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.34485826923573,"longitud":-64.47566539041196,"ranking":2},
{"id":9463,"nombre":"KM 14","localidad":"KM 14","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9465,"nombre":"Espigao do Leste ","localidad":"Espigão do Leste ","provincia":"Mato Grosso","pais":"BRASIL","latitud":-11.432045918558156,"longitud":-52.191090526571735,"ranking":0},
{"id":9468,"nombre":"Horno de Cal","localidad":"Horno de Cal","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9469,"nombre":"KM 65","localidad":"KM 65","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9470,"nombre":"KM 70","localidad":"KM 70","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9472,"nombre":"Puesto Busto","localidad":"Puesto Busto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9477,"nombre":"Puesto Pedernera","localidad":"Puesto Pedernera","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9482,"nombre":"La Pampilla","localidad":"La Pampilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5980554,"longitud":-64.7098623,"ranking":0},
{"id":9483,"nombre":"KM 105 Acceso","localidad":"KM 105 Acceso","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9484,"nombre":"Niña Paula","localidad":"Niña Paula","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7473678,"longitud":-64.9330885,"ranking":0},
{"id":9489,"nombre":"Bajo de Ocanto ","localidad":"Bajo de Ocanto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.818089336228372,"longitud":-64.998848032162,"ranking":0},
{"id":9494,"nombre":"Cuesta Ciego","localidad":"Cuesta Ciego","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9499,"nombre":"Santo Antonio da Barra","localidad":"Santo Antonio da Barra","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9507,"nombre":"Pilolil","localidad":"Pilolil","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.641470800,"longitud":-70.944922000,"ranking":0},
{"id":9508,"nombre":"Pilar","localidad":"Pilar","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9539,"nombre":"Poxim","localidad":"Poxim","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9541,"nombre":"Primeros Pinos","localidad":"Primeros Pinos","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.868017206984966,"longitud":-70.5759180022133,"ranking":0},
{"id":9542,"nombre":"Porangaba","localidad":"Porangaba","provincia":"Alagoas","pais":"BRASIL","latitud":-9.490644130155165,"longitud":-36.20761579527813,"ranking":0},
{"id":9543,"nombre":"Pontal de Coruripe","localidad":"Pontal de Coruripe","provincia":"Alagoas","pais":"BRASIL","latitud":-10.124797107494,"longitud":-36.17573380286238,"ranking":0},
{"id":9544,"nombre":"Abarrancamento","localidad":"Abarrancamento","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9545,"nombre":"Andrade Pinto","localidad":"Andrade Pinto","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9546,"nombre":"Barão de Angra","localidad":"Barão de Angra","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.133575069752123,"longitud":-43.24775714568385,"ranking":0},
{"id":9547,"nombre":"Bemposta","localidad":"Bemposta","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9550,"nombre":"Manuel Duarte","localidad":"Manuel Duarte","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.085730947590935,"longitud":-43.55930818116173,"ranking":0},
{"id":9551,"nombre":"Marangatu","localidad":"Marangatu","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.58785256728284,"longitud":-42.22154598379785,"ranking":0},
{"id":9552,"nombre":"Posse","localidad":"Posse","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9554,"nombre":"Três Ilhas","localidad":"Três Ilhas","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9560,"nombre":"Branca de Atalaia","localidad":"Branca de Atalaia","provincia":"Alagoas","pais":"BRASIL","latitud":-9.495475764739199,"longitud":-36.08261964890597,"ranking":0},
{"id":9567,"nombre":"Fôlha Miúda","localidad":"Fôlha Miúda","provincia":"Alagoas","pais":"BRASIL","latitud":-9.701981644219089,"longitud":-36.7948293596378,"ranking":0},
{"id":9575,"nombre":"Luziapolis","localidad":"Luziapolis","provincia":"Alagoas","pais":"BRASIL","latitud":-9.904486939585174,"longitud":-36.22107748966472,"ranking":0},
{"id":9590,"nombre":"9 de Julio","localidad":"9 de Julio","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9591,"nombre":"Santa Catalina Acceso","localidad":"Santa Catalina","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.12190695719298,"longitud":-64.78097999233772,"ranking":0},
{"id":9592,"nombre":"Bajada Colorada","localidad":"Bajada Colorada","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.825602600,"longitud":-69.745910200,"ranking":0},
{"id":9593,"nombre":"Registro do Araguaia","localidad":"Registro do Araguaia","provincia":"Goias","pais":"BRASIL","latitud":-15.738905568375346,"longitud":-51.82576105626659,"ranking":0},
{"id":9604,"nombre":"Birigui","localidad":"Birigui","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9605,"nombre":"Nova Casa Verde","localidad":"Nova Casa Verde","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9612,"nombre":"Miritituba","localidad":"Miritituba","provincia":"Pará","pais":"BRASIL","latitud":-4.2935835232321296,"longitud":-55.96270362492419,"ranking":0},
{"id":9618,"nombre":"Porto Trombetas","localidad":"Porto Trombetas","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9620,"nombre":"Baguari","localidad":"Baguari","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.95807671444177,"longitud":-42.071873992634,"ranking":0},
{"id":3495,"nombre":"Elisa","localidad":"Las Colonias","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.6992257,"longitud":-61.04419550000001,"ranking":1},
{"id":9639,"nombre":"Cañada de Luque","localidad":"Cañada de Luque","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.734126476182382,"longitud":-63.724586963653564,"ranking":1},
{"id":9640,"nombre":"Chalacea","localidad":"Chalacea","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.762902478403966,"longitud":-63.559996907339965,"ranking":0},
{"id":9641,"nombre":"Las Arrias","localidad":"Las Arrias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.3773618,"longitud":-63.5953246,"ranking":32},
{"id":14546,"nombre":"Las Arrias Acceso","localidad":"Las Arrias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.3663607,"longitud":-63.59616219999999,"ranking":0},
{"id":9642,"nombre":"San Jose de las Salinas","localidad":"San José de las Salinas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.0076305,"longitud":-64.6249864,"ranking":0},
{"id":9643,"nombre":"Rayo Cortado","localidad":"Rayo Cortado","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.070583,"longitud":-63.819495,"ranking":2},
{"id":9644,"nombre":"Sauce Punco","localidad":"Sauce Punco","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9646,"nombre":"Chañar Viejo","localidad":"Chañar Viejo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.00862247381088,"longitud":-63.875946516021195,"ranking":0},
{"id":9647,"nombre":"La Quinta","localidad":"La Quinta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.063387105755325,"longitud":-63.151941362119366,"ranking":0},
{"id":9648,"nombre":"Alto de Flores","localidad":"Alto de Flores","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9649,"nombre":"La Encrucijada","localidad":"La Encrucijada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.170417855842057,"longitud":-63.420320446977875,"ranking":0},
{"id":9735,"nombre":"Quila Quina Acceso","localidad":"Quila Quina","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.18177600824611,"longitud":-71.3930690576311,"ranking":0},
{"id":9650,"nombre":"Puesto de Castro","localidad":"Puesto de Castro","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9651,"nombre":"Las Cortaderas","localidad":"Las Cortaderas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.482273968974475,"longitud":-64.1389263131535,"ranking":0},
{"id":9652,"nombre":"El Tabaquillo","localidad":"El Tabaquillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.76865840358867,"longitud":-64.9365900173213,"ranking":0},
{"id":9653,"nombre":"La Reina","localidad":"La Reina","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9654,"nombre":"Estacion Soto","localidad":"Estacion Soto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.84897387146735,"longitud":-65.0096757035021,"ranking":0},
{"id":9655,"nombre":"Inti Huasi","localidad":"Inti Huasi","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9656,"nombre":"La Rinconada","localidad":"La Rinconada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.182597988275344,"longitud":-62.94484673363182,"ranking":0},
{"id":9657,"nombre":"El Zapallar","localidad":"El Zapallar","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9658,"nombre":"Estafeta","localidad":"Estafeta","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9659,"nombre":"Tres Chorros","localidad":"Tres Chorros","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9664,"nombre":"Aguada Cecilio","localidad":"Aguada Cecilio","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.8465263,"longitud":-65.8498549,"ranking":5},
{"id":9665,"nombre":"Aguada de Guerra","localidad":"Aguada de Guerra","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.066655,"longitud":-68.385649,"ranking":8},
{"id":9666,"nombre":"Aguada Guzman","localidad":"Aguada Guzman","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9667,"nombre":"Canadon Chileno","localidad":"Cañadon Chileno","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.88760951413916,"longitud":-70.02349806456247,"ranking":0},
{"id":9668,"nombre":"Cerro de Policia","localidad":"Cerro de Policia","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9669,"nombre":"Mencue","localidad":"Mencué","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.42363,"longitud":-69.6145,"ranking":0},
{"id":9672,"nombre":"Clemente Onelli","localidad":"Clemente Onelli","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.245785,"longitud":-70.035069,"ranking":37},
{"id":9673,"nombre":"Nahuel Niyeu","localidad":"Nahuel Niyeu","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.505001,"longitud":-66.5627946,"ranking":12},
{"id":9675,"nombre":"Pilahue ","localidad":"Pilahue","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.54297940121711,"longitud":-69.7257151304505,"ranking":0},
{"id":9676,"nombre":"Pichileufu","localidad":"Pichileufú","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.1383903,"longitud":-70.8470186,"ranking":0},
{"id":9687,"nombre":"Pilca Viejo","localidad":"Pilca Viejo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.021546384690055,"longitud":-70.61429052281306,"ranking":6},
{"id":9678,"nombre":"Comallo Abajo","localidad":"Comallo Abajo","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9682,"nombre":"El Cuy","localidad":"EL CUY","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.9263398,"longitud":-68.3449009,"ranking":5},
{"id":9683,"nombre":"Emp. 405 La Bombilla","localidad":"La Bombilla","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9684,"nombre":"La Esperanza","localidad":"La Esperanza","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9685,"nombre":"Laguna Blanca","localidad":"Laguna Blanca","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.721577560266255,"longitud":-69.84127453558331,"ranking":0},
{"id":9686,"nombre":"Chasico","localidad":"Laguna de Chasico","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9688,"nombre":"Las Mellizas","localidad":"Las Mellizas","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9696,"nombre":"San Javier","localidad":"San Javier ","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-40.766274100,"longitud":-63.276572200,"ranking":0},
{"id":10099,"nombre":"San Javier Acceso","localidad":"San Javier ","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-32.55196940457551,"longitud":-58.02150335736166,"ranking":0},
{"id":9697,"nombre":"Tricaco","localidad":"Tricaco","provincia":"Rio Negro","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9706,"nombre":"Lucio V. Mansilla","localidad":"Lucio V. Mansilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.8059179,"longitud":-64.7108646,"ranking":0},
{"id":9707,"nombre":"Tres Arboles","localidad":"Tres Arboles","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9708,"nombre":"La Higuera","localidad":"La Higuera","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0130086,"longitud":-65.1027091,"ranking":0},
{"id":9709,"nombre":"Mesa de Mariano","localidad":"Mesa de Mariano","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9710,"nombre":"San Carlos Minas","localidad":"San Carlos Minas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1766174,"longitud":-65.10184029999999,"ranking":9},
{"id":9779,"nombre":"Ojo de Agua Carabajal","localidad":"San Carlos Minas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.176392197579634,"longitud":-65.10230504997516,"ranking":0},
{"id":9711,"nombre":"Paloma Pozo","localidad":"Paloma Pozo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9713,"nombre":"La Casilla","localidad":"La Casilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9714,"nombre":"La Cañada","localidad":"La Cañada","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9715,"nombre":"El Guanaco","localidad":"El Guanaco","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9716,"nombre":"Villa Candelaria","localidad":"Villa Candelaria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.815178,"longitud":-63.35453870000001,"ranking":0},
{"id":9717,"nombre":"Rio Seco","localidad":"Rio Seco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.0682207,"longitud":-63.10896580000001,"ranking":0},
{"id":9720,"nombre":"Gutemberg","localidad":"Rio Seco","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.720932,"longitud":-63.5145382,"ranking":0},
{"id":9718,"nombre":"Los Hoyos","localidad":"Los Hoyos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.802524707889273,"longitud":-63.62742931648089,"ranking":0},
{"id":9721,"nombre":"Piedras Anchas","localidad":"Piedras Anchas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.21588121034305,"longitud":-65.14100738041202,"ranking":0},
{"id":9722,"nombre":"Toro Muerto","localidad":"Toro Muerto","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9723,"nombre":"Salsacate","localidad":"Salsacate","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.320262556574413,"longitud":-65.08828639984131,"ranking":7},
{"id":9724,"nombre":"Taninga","localidad":"Taninga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.345848811259824,"longitud":-65.07984280586243,"ranking":1},
{"id":9725,"nombre":"Villa Viso","localidad":"Villa Viso","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3647681,"longitud":-65.10601199999999,"ranking":0},
{"id":9726,"nombre":"Chamico","localidad":"Chamico","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9727,"nombre":"Bajo de los Corrales","localidad":"Bajo de los Corrales","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4542935,"longitud":-65.1098894,"ranking":0},
{"id":9728,"nombre":"El Bañado Punilla","localidad":"El Bañado Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9729,"nombre":"Ambul","localidad":"Ambul","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4911614,"longitud":-65.05413589999999,"ranking":0},
{"id":9730,"nombre":"Alto Grande","localidad":"Alto Grande","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.828155419593642,"longitud":-64.07127113043809,"ranking":0},
{"id":9731,"nombre":"El Mirador","localidad":"El Mirador","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.58235838166223,"longitud":-65.11108217657046,"ranking":1},
{"id":9732,"nombre":"San Jeronimo de Punilla","localidad":"San Jerónimo de Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9733,"nombre":"El Faro","localidad":"El Faro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.654991790897114,"longitud":-65.09879569418814,"ranking":0},
{"id":9734,"nombre":"Los Azulejos","localidad":"Los Azulejos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9736,"nombre":"Quilca","localidad":"Quilca","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9737,"nombre":"Quiros","localidad":"Quiros","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.789524437927295,"longitud":-65.11053467530344,"ranking":8},
{"id":9738,"nombre":"Cholila","localidad":"Cholila","provincia":"Chubut","pais":"ARGENTINA","latitud":-42.51154168777245,"longitud":-71.43132197037313,"ranking":0},
{"id":9739,"nombre":"Rahue","localidad":"Rahue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.224686,"longitud":-70.563083,"ranking":0},
{"id":9740,"nombre":"Rincon Chico ","localidad":"Rincón Chico","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.075176800,"longitud":-68.580093400,"ranking":0},
{"id":9741,"nombre":"Rincon Grande","localidad":"Rincón Grande","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.075176800,"longitud":-68.580093400,"ranking":0},
{"id":9742,"nombre":"Contralmirante Cordero","localidad":"Contralmirante Cordero","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-38.7569781,"longitud":-68.1020808,"ranking":0},
{"id":9743,"nombre":"Sarmiento","localidad":"Sarmiento","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9745,"nombre":"Esquiu","localidad":"Esquiu","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.378342071175066,"longitud":-65.29093918894434,"ranking":27},
{"id":9747,"nombre":"General San Martin","localidad":"General San Martin","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9748,"nombre":"La Esquina","localidad":"La Esquina","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9758,"nombre":"Macho Negro","localidad":"Macho Negro","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.223609800,"longitud":-70.563812300,"ranking":0},
{"id":9761,"nombre":"Espinazo del Zorro","localidad":"Espinazo del Zorro","provincia":"Neuquén","pais":"ARGENTINA","latitud":-39.283227400,"longitud":-70.594561100,"ranking":0},
{"id":9764,"nombre":"San Pedro Norte","localidad":"San Pedro Norte","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.091022948256583,"longitud":-64.15480728531801,"ranking":0},
{"id":9765,"nombre":"Chuna Huasi","localidad":"Chuña Huasi","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9769,"nombre":"San Jeronimo","localidad":"San Jeronimo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.311663730492903,"longitud":-64.30258654649614,"ranking":0},
{"id":9768,"nombre":"Los Mogotes","localidad":"Los Mogotes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.84833699865,"longitud":-64.53546856885127,"ranking":0},
{"id":9770,"nombre":"Las Aguilillas","localidad":"Las Aguilillas","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9771,"nombre":"Cachi Yaco","localidad":"Cachi Yaco","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9773,"nombre":"Pozo Nuevo","localidad":"Pozo Nuevo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.575109376400583,"longitud":-64.10733517134337,"ranking":0},
{"id":9774,"nombre":"Puesto Nuevo","localidad":"Puesto Nuevo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9775,"nombre":"Capilla de Siton","localidad":"Capilla de Sitón","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.57239727863438,"longitud":-63.65290379401045,"ranking":0},
{"id":9777,"nombre":"El Pintado","localidad":"El Pintado","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9778,"nombre":"El Sauce","localidad":"El Sauce","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9781,"nombre":"La Estancia","localidad":"La Estancia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.779767272581008,"longitud":-64.54359722017266,"ranking":0},
{"id":9783,"nombre":"Ninalquin","localidad":"Ninalquin","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.178659413563278,"longitud":-65.17985470278046,"ranking":0},
{"id":9784,"nombre":"Guasapampa","localidad":"Guasapampa","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9785,"nombre":"Alto Sierra","localidad":"Alto Sierra","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.05505257,"longitud":-65.342597587,"ranking":0},
{"id":9787,"nombre":"Chacras Punilla","localidad":"Chacras Punilla","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.049704323280487,"longitud":-64.5500965728028,"ranking":0},
{"id":9788,"nombre":"Aguas de Ramon","localidad":"Aguas de Ramón","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9789,"nombre":"Totora Huasi ","localidad":"Totora Huasi","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9790,"nombre":"La Playa","localidad":"La Playa","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":1748,"nombre":"Agua de Oro","localidad":"Agua de Oro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.06377,"longitud":-64.30025,"ranking":42},
{"id":9792,"nombre":"El Bañado de Rosario de Saladillo","localidad":"Rosario del Saladillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.43131996341611,"longitud":-63.47417154557618,"ranking":0},
{"id":10763,"nombre":"Rosario de Saladillo","localidad":"Rosario del Saladillo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.430196150019743,"longitud":-63.474073193397935,"ranking":0},
{"id":9793,"nombre":"El Vence","localidad":"El Vence","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.366452965551833,"longitud":-63.63341500658205,"ranking":0},
{"id":9794,"nombre":"KM 22","localidad":"KM 22","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9795,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9796,"nombre":"El Manzano","localidad":"El Manzano","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0740621,"longitud":-64.2982387,"ranking":3},
{"id":9797,"nombre":"La Pampa","localidad":"La Pampa","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9798,"nombre":"La Porteña","localidad":"La Porteña","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.014727284100026,"longitud":-62.062647342681885,"ranking":1},
{"id":9799,"nombre":"Santa Cruz","localidad":"Santa Cruz","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.253224635658665,"longitud":-64.17357096121567,"ranking":0},
{"id":9800,"nombre":"Las Chilcas","localidad":"Las Chilcas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.312188200,"longitud":-58.739182200,"ranking":0},
{"id":9803,"nombre":"Real Audiencia","localidad":"Real Audiencia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.18766399334493,"longitud":-58.59325972701105,"ranking":0},
{"id":9804,"nombre":"San Francisco Bellocq","localidad":"San Francisco Bellocq","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9806,"nombre":"Casalins Acceso","localidad":"Casalins","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.3144494,"longitud":-58.55096169999999,"ranking":2},
{"id":9807,"nombre":"De la Canal Acceso","localidad":"De la Canal","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.13177392547891,"longitud":-59.10548805662363,"ranking":0},
{"id":9813,"nombre":"Copetonas","localidad":"Copetonas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9815,"nombre":"Vela Acceso","localidad":"Vela","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.49993721574176,"longitud":-59.374596711128845,"ranking":0},
{"id":9819,"nombre":"Dos Naciones","localidad":"Dos Naciones","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.765418313371875,"longitud":-58.64683454891238,"ranking":0},
{"id":9820,"nombre":"El Soldado","localidad":"El Soldado","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9822,"nombre":"Herrera Vegas","localidad":"Herrera Vegas","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.0859317181555,"longitud":-61.41193714992844,"ranking":0},
{"id":9824,"nombre":"Las Nutrias","localidad":"Las Nutrias","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9825,"nombre":"9 de Julio","localidad":"9 de Julio","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9826,"nombre":"Coronel R. Bunge Acceso","localidad":"Coronel R. Bunge","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9829,"nombre":"Coronel Martinez de Hoz","localidad":"Coronel Martinez de Hoz","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9833,"nombre":"Cristiano Muerto","localidad":"Cristiano Muerto","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9834,"nombre":"El Carretero","localidad":"El Carretero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9835,"nombre":"El Gaucho","localidad":"El Gaucho","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9837,"nombre":"Piedras Blancas","localidad":"Piedras Blancas","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9840,"nombre":"La Mudana","localidad":"La Mudana","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9850,"nombre":"Ojo de Agua Totox","localidad":"Minas","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9853,"nombre":"Acupe","localidad":"Acupe","provincia":"Bahia","pais":"BRASIL","latitud":-12.661534943145858,"longitud":-38.75225825479466,"ranking":0},
{"id":9854,"nombre":"Algodoes","localidad":"Algodões","provincia":"Bahia","pais":"BRASIL","latitud":-10.68204269184282,"longitud":-39.05340991934934,"ranking":0},
{"id":9856,"nombre":"Bom Jesus dos Pobres","localidad":"Bom Jesus dos Pobres","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":9857,"nombre":"Cabucu","localidad":"Cabuçu","provincia":"Bahia","pais":"BRASIL","latitud":-12.778740727265518,"longitud":-38.77174255378866,"ranking":0},
{"id":4088,"nombre":"Maria Auxiliadora","localidad":"Maria Auxiliadora","provincia":"Itapua","pais":"Paraguay","latitud":-26.531835,"longitud":-55.258914,"ranking":77},
{"id":9891,"nombre":"El Baldecito","localidad":"El Baldecito","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9905,"nombre":"Piedras Blancas","localidad":"Piedras Blancas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.184732,"longitud":-59.950182,"ranking":0},
{"id":9893,"nombre":"Cardenal Cagliero","localidad":"Cardenal Cagliero","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-40.59191216218682,"longitud":-62.83442445101284,"ranking":0},
{"id":9895,"nombre":"Colonia San Martín","localidad":"Colonia San Martín","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.9753023,"longitud":-62.3282734,"ranking":0},
{"id":9901,"nombre":"Hasenkamp Acceso","localidad":"Hasenkamp","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.576932095635645,"longitud":-59.89001274598496,"ranking":0},
{"id":9902,"nombre":"Hasenkamp","localidad":"Hasenkamp","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.508822,"longitud":-59.835858,"ranking":21},
{"id":9903,"nombre":"Conscripto Bernardi","localidad":"Conscripto Bernardi","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.0483435,"longitud":-59.0843306,"ranking":2},
{"id":9906,"nombre":"Pueblo Brugo","localidad":"Pueblo Brugo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.388157,"longitud":-60.063356,"ranking":8},
{"id":9907,"nombre":"Pueblo Brugo Acceso","localidad":"Pueblo Brugo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.38824780025241,"longitud":-60.06351155617624,"ranking":0},
{"id":9913,"nombre":"Maruda","localidad":"Marudá","provincia":"Pará","pais":"BRASIL","latitud":-0.6259195642864364,"longitud":-47.6368029153787,"ranking":0},
{"id":9930,"nombre":"El Chacay","localidad":"El Chacay","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.8922321,"longitud":-64.86720869999999,"ranking":0},
{"id":7887,"nombre":"La Tosquita","localidad":"La Tosquita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.819136,"longitud":-64.457675,"ranking":6},
{"id":9939,"nombre":"Lages Do Batata","localidad":"Lages Do Batata","provincia":"Bahia","pais":"BRASIL","latitud":-11.056661515559023,"longitud":-40.77405083563361,"ranking":0},
{"id":9938,"nombre":"Barra do Sabia","localidad":"Barra do Sabia","provincia":"Espirito Santo","pais":"BRASIL","latitud":-19.127750550850507,"longitud":-40.61014835890196,"ranking":0},
{"id":9985,"nombre":"Puerto Velaz","localidad":"Puerto Velaz","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.7217093,"longitud":-58.6827925,"ranking":0},
{"id":10849,"nombre":"Puerto Velaz Acceso","localidad":"Puerto Velaz","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.7217093,"longitud":-58.6827925,"ranking":0},
{"id":9984,"nombre":"Arroyo Zapiran Acceso","localidad":"Arroyo Zapiran","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.8664006,"longitud":-58.76447750000001,"ranking":0},
{"id":9986,"nombre":"Manantiales","localidad":"Manantiales","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.921461631257174,"longitud":-58.09856663084501,"ranking":0},
{"id":9994,"nombre":"Norberto de la Riestra","localidad":"Norberto de la Riestra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10025,"nombre":"Vale Dos Sonhos","localidad":"Vale Dos Sonhos","provincia":"Mato Grosso","pais":"BRASIL","latitud":-15.388591392298238,"longitud":-52.20088979171017,"ranking":0},
{"id":10028,"nombre":"Jardim Atlantico","localidad":"Jardim Atlantico","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10029,"nombre":"Jardim do Eden","localidad":"Jardim do Eden","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.069465362493244,"longitud":-50.16280174255371,"ranking":0},
{"id":10033,"nombre":"Sao Joao do Sul","localidad":"Sao Joao do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-29.22428911820967,"longitud":-49.81009550606647,"ranking":0},
{"id":10048,"nombre":"Numi","localidad":"Ñumi","provincia":"Guairá","pais":"Paraguay","latitud":-25.948625,"longitud":-56.32915,"ranking":1},
{"id":10125,"nombre":"Palmital","localidad":"Palmital","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10126,"nombre":"Planalto de Minas","localidad":"Planalto de Minas","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.246884783321377,"longitud":-43.60083764846278,"ranking":0},
{"id":10127,"nombre":"Sao Pedro dos Ferros","localidad":"Sao Pedro dos Ferros","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.16902997207768,"longitud":-42.5221678576886,"ranking":0},
{"id":10128,"nombre":"Miranda Acceso","localidad":"Miranda","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.5313115,"longitud":-59.1341948,"ranking":0},
{"id":10129,"nombre":"Egaña Acceso","localidad":"Egaña","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.849050270453034,"longitud":-58.98567720410546,"ranking":0},
{"id":10131,"nombre":"Villanueva","localidad":"Villanueva","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.675876,"longitud":-58.434895,"ranking":14},
{"id":10603,"nombre":"Villanueva Acceso","localidad":"Villanueva","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.6847192,"longitud":-58.4400788,"ranking":8},
{"id":10132,"nombre":"Ibañez","localidad":"Ibañez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.025922700,"longitud":-58.624207400,"ranking":0},
{"id":10134,"nombre":"Energia","localidad":"Energia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10828,"nombre":"Energia Acceso","localidad":"Energia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-38.5533278,"longitud":-59.3528048,"ranking":0},
{"id":10135,"nombre":"Hilario Ascasubi","localidad":"Hilario Ascasubi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.379339,"longitud":-62.638168,"ranking":0},
{"id":14471,"nombre":"Hilario Ascasubi Acceso","localidad":"Hilario Ascasubi","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.39172569999999,"longitud":-62.6296449,"ranking":0},
{"id":10136,"nombre":"Pradere","localidad":"Pradere","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-39.61856362830495,"longitud":-62.67907234729299,"ranking":0},
{"id":10137,"nombre":"Punta Bandera","localidad":"Punta Bandera","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":-50.313948054419576,"longitud":-72.79372722008182,"ranking":0},
{"id":10203,"nombre":"Palmeirinha","localidad":"Palmeirinha","provincia":"Parana","pais":"BRASIL","latitud":-25.24551622216311,"longitud":-51.54655217374257,"ranking":0},
{"id":10316,"nombre":"Caraguatai","localidad":"Caraguatai","provincia":"Bahia","pais":"BRASIL","latitud":-13.401802232341174,"longitud":-41.64385361775477,"ranking":0},
{"id":10373,"nombre":"Nordelta - Bahía Grande","localidad":"Nordelta","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10386,"nombre":"Sao Gabriel de Goias","localidad":"Sâo Gabriel de Goias","provincia":"Goias","pais":"BRASIL","latitud":-15.231550740090332,"longitud":-47.57429835431685,"ranking":0},
{"id":10403,"nombre":"Sao Luiz do Anaua","localidad":"São Luiz do Anaua","provincia":"Roraima","pais":"BRASIL","latitud":1.0124094734748599,"longitud":-60.0379354284578,"ranking":0},
{"id":10404,"nombre":"Joaquim Murtinho","localidad":"Joaquim Murtinho","provincia":"Parana","pais":"BRASIL","latitud":-23.498260321767663,"longitud":-49.92263999039155,"ranking":0},
{"id":10407,"nombre":"Guaianas","localidad":"Guaianás","provincia":"Sao Paulo","pais":"BRASIL","latitud":-22.309715896949147,"longitud":-48.89182074714456,"ranking":0},
{"id":10408,"nombre":"Tapara","localidad":"Tapará","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10409,"nombre":"Rancho Alegre","localidad":"Rancho Alegre","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10410,"nombre":"Sinimbu","localidad":"Sinimbu","provincia":"Alagoas","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10412,"nombre":"Lustosa","localidad":"Lustosa","provincia":"Bahia","pais":"BRASIL","latitud":-12.220448780938913,"longitud":-38.62786893074602,"ranking":0},
{"id":10413,"nombre":"Ubu","localidad":"Ubu","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10414,"nombre":"Sao Jose do Frade","localidad":"São José do Frade","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10415,"nombre":"Meaipe","localidad":"Meaípe","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.736355491217378,"longitud":-40.54061864665441,"ranking":0},
{"id":10416,"nombre":"Itaipava","localidad":"Itaipava","provincia":"Espirito Santo","pais":"BRASIL","latitud":-22.386501389178893,"longitud":-43.132506824166974,"ranking":0},
{"id":10417,"nombre":"Itaoca","localidad":"Itaoca","provincia":"Espirito Santo","pais":"BRASIL","latitud":-20.89715508507286,"longitud":-40.77711631217693,"ranking":0},
{"id":10419,"nombre":"Santa Rita do Ribeira","localidad":"Santa Rita do Ribeira","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10422,"nombre":"Tres Barras","localidad":"Três Barras","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-15.55995174351208,"longitud":-56.019506412732284,"ranking":0},
{"id":10423,"nombre":"Ceilandia","localidad":"Ceilândia","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.835522189579452,"longitud":-48.08270002045646,"ranking":0},
{"id":10424,"nombre":"Recanto das Emas","localidad":"Recanto das Emas","provincia":"Distrito Federal","pais":"BRASIL","latitud":-15.912750448771265,"longitud":-48.05553848353146,"ranking":0},
{"id":10425,"nombre":"Colonia Yabebiry","localidad":"Colonia Yabebiry","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.463306235201163,"longitud":-55.281755046724264,"ranking":0},
{"id":10426,"nombre":"Colonia Maria Magdalena","localidad":"Colonia Maria Magdalena","provincia":"Misiones","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10428,"nombre":"Iruya","localidad":"Iruya","provincia":"Salta","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":125},
{"id":10438,"nombre":"Esteros del Ibera","localidad":"Esteros del Ibera","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10439,"nombre":"Impenetrable Chaqueño","localidad":"Impenetrable Chaqueño","provincia":"Chaco","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10465,"nombre":"Abuna","localidad":"Abunã","provincia":"Rondônia","pais":"BRASIL","latitud":-9.699250217320323,"longitud":-65.36974885610582,"ranking":0},
{"id":10467,"nombre":"Jaci Parana","localidad":"Jaci Paraná","provincia":"Rondônia","pais":"BRASIL","latitud":-9.257745127900852,"longitud":-64.40487964519804,"ranking":0},
{"id":10468,"nombre":"Mutum Parana","localidad":"Mutum Paraná","provincia":"Rondônia","pais":"BRASIL","latitud":-9.291047637951573,"longitud":-64.54519999510451,"ranking":0},
{"id":10472,"nombre":"La Magdalena","localidad":"La Magdalena","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.08202896611183,"longitud":-57.51892857132928,"ranking":0},
{"id":10498,"nombre":"Quatro Bocas","localidad":"Quatro Bocas","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10500,"nombre":"Leandro Oviedo","localidad":"Leandro Oviedo","provincia":"Itapua","pais":"Paraguay","latitud":-26.742317,"longitud":-56.286693,"ranking":0},
{"id":10502,"nombre":"Nova Colina","localidad":"Nova Colina","provincia":"Roraima","pais":"BRASIL","latitud":0.5849608980866061,"longitud":-60.46167245159159,"ranking":0},
{"id":2741,"nombre":"Adelia Maria","localidad":"Adelia Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.1141017,"longitud":-64.3517467,"ranking":25},
{"id":10504,"nombre":"Inhuporanga","localidad":"Inhuporanga","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10579,"nombre":"Arraial d Ajuda","localidad":"Arraial d’Ajuda","provincia":"Bahia","pais":"BRASIL","latitud":-16.49093245309822,"longitud":-39.07449587548111,"ranking":0},
{"id":10580,"nombre":"Barra Velha","localidad":"Barra Velha","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10581,"nombre":"Barrolandia","localidad":"Barrolândia","provincia":"Bahia","pais":"BRASIL","latitud":-16.10472264362029,"longitud":-39.27454451506316,"ranking":0},
{"id":10582,"nombre":"Boca do Corrego","localidad":"Boca do Córrego","provincia":"Bahia","pais":"BRASIL","latitud":-15.871584362631282,"longitud":-39.134007758945565,"ranking":0},
{"id":10583,"nombre":"Caiubi","localidad":"Caiubi","provincia":"Bahia","pais":"BRASIL","latitud":-15.90888755562647,"longitud":-39.89506842205834,"ranking":0},
{"id":10584,"nombre":"Corumbau","localidad":"Corumbau","provincia":"Bahia","pais":"BRASIL","latitud":-16.935778134118777,"longitud":-39.16692699989209,"ranking":0},
{"id":10585,"nombre":"Cumuruxatiba","localidad":"Cumuruxatiba","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10586,"nombre":"Monte Alegre","localidad":"Monte Alegre","provincia":"Bahia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10587,"nombre":"Nova Alegria","localidad":"Nova Alegria","provincia":"Bahia","pais":"BRASIL","latitud":-16.870626487070655,"longitud":-39.91033453337791,"ranking":0},
{"id":10588,"nombre":"Trancoso","localidad":"Trancoso","provincia":"Bahia","pais":"BRASIL","latitud":-16.59283524077112,"longitud":-39.103538317665084,"ranking":0},
{"id":10593,"nombre":"Gomez Acceso","localidad":"Gomez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0818653,"longitud":-58.1453644,"ranking":10},
{"id":10592,"nombre":"Bosquecitos Acceso","localidad":"Bosquecitos","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.1031853,"longitud":-58.1842834,"ranking":0},
{"id":10590,"nombre":"Santa Isabel","localidad":"Santa Isabel","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.4700636,"longitud":-57.5006667,"ranking":5},
{"id":10597,"nombre":"Bonnement","localidad":"Bonnement","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.7161692,"longitud":-58.4630436,"ranking":0},
{"id":15455,"nombre":"Ayacucho","localidad":"Huamanga","provincia":"Ayacucho","pais":"Perú","latitud":-13.134234104049119,"longitud":-74.230388918073,"ranking":35},
{"id":10599,"nombre":"Arroyo Zapata","localidad":"Arroyo Zapata","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9883593,"longitud":-57.71696909999999,"ranking":0},
{"id":10600,"nombre":"Cañada de Arregui","localidad":"Cañada de Arregui","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0729069,"longitud":-57.53311919999999,"ranking":0},
{"id":10601,"nombre":"Casa Irrustipe","localidad":"C.Irrustipe","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.93761,"longitud":-57.87255999999999,"ranking":0},
{"id":10611,"nombre":"Tacuarendi","localidad":"Tacuarendí","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.4126898,"longitud":-59.2613066,"ranking":0},
{"id":10612,"nombre":"Zenon Pereyra","localidad":"Zenon Pereyra","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10613,"nombre":"Santa Felicitas","localidad":"Santa Felicitas","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10614,"nombre":"El Araza","localidad":"El Araza","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.132361273240882,"longitud":-59.946433858363115,"ranking":0},
{"id":10615,"nombre":"Fortin Olmos","localidad":"Fortin Olmos","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10616,"nombre":"Nicanor Molinas","localidad":"Nicanor Molinas","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.13003482855417,"longitud":-59.83181832143514,"ranking":0},
{"id":10617,"nombre":"San Antonio","localidad":"San Antonio","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10619,"nombre":"La Potasa","localidad":"La Potasa","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10621,"nombre":"Campo Hardy","localidad":"Campo Hardy","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.145892014,"longitud":-59.261952824,"ranking":0},
{"id":14596,"nombre":"La Zulema","localidad":"La Zulema","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.10407529999999,"longitud":-60.1127599,"ranking":0},
{"id":14597,"nombre":"Las Mercedes","localidad":"Las Mercedes","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.6738536,"longitud":-60.5056329,"ranking":0},
{"id":10628,"nombre":"Lagoa do Mato","localidad":"Lagoa do Mato","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10629,"nombre":"Santa Cruz do Banabuiu","localidad":"Santa Cruz do Banabuiu","provincia":"Ceará","pais":"BRASIL","latitud":-5.43963529111098,"longitud":-40.00467333785523,"ranking":0},
{"id":10630,"nombre":"Trevo Cruzeta","localidad":"Trevo Cruzeta","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10633,"nombre":"Las Lagunas","localidad":"Las Lagunas","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.62742950326465,"longitud":-65.54791626533309,"ranking":0},
{"id":10634,"nombre":"Potrerillo","localidad":"Potrerillo","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10632,"nombre":"Villa de Praga","localidad":"Villa de Praga","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.53336436382046,"longitud":-65.64579410811653,"ranking":0},
{"id":10631,"nombre":"Pozo Cavado","localidad":"Pozo Cavado","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10635,"nombre":"Villa General Roca","localidad":"Villa General Roca","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.66763816299271,"longitud":-66.4494594535756,"ranking":0},
{"id":10636,"nombre":"Agua Limpa","localidad":"Água Limpa","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10637,"nombre":"Agua Parada","localidad":"Água Parada","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10638,"nombre":"Albertao","localidad":"Albertao","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10639,"nombre":"Ano Bom","localidad":"Ano Bom","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10640,"nombre":"Aguas Verdes","localidad":"Aguas Verdes","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10641,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.71820727977673,"longitud":-46.02281545210904,"ranking":0},
{"id":10642,"nombre":"Caneleiras","localidad":"Caneleiras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.276934352511052,"longitud":-46.502714784344526,"ranking":0},
{"id":10643,"nombre":"Cascalho","localidad":"Cascalho","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10648,"nombre":"Gaspar Lopes","localidad":"Gaspar Lopes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.364281598454745,"longitud":-45.933956305862104,"ranking":0},
{"id":10649,"nombre":"Igrejinha","localidad":"Igrejinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10650,"nombre":"Lagoinha","localidad":"Lagoinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10651,"nombre":"Alto da Mata","localidad":"Alto da Mata","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10652,"nombre":"El Descanso","localidad":"El Descanso","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10653,"nombre":"Rio Quinto","localidad":"Río Quinto","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10654,"nombre":"La Petra","localidad":"La Petra","provincia":"San Luis","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10655,"nombre":"Boresca","localidad":"Boresca","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10656,"nombre":"Mumbuquinha","localidad":"Mumbuquinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10657,"nombre":"Petunia","localidad":"Petúnia","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":10658,"nombre":"Cabalango","localidad":"Cabalango","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3989,"longitud":-64.556216,"ranking":26},
{"id":10664,"nombre":"Pachaco","localidad":"Pachaco","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.296191201077505,"longitud":-69.08803939819336,"ranking":0},
{"id":10665,"nombre":"Tamberias","localidad":"Tamberias","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.45805,"longitud":-69.421539,"ranking":48},
{"id":10666,"nombre":"Tupeli","localidad":"Tupeli","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.8360051,"longitud":-68.3582397,"ranking":0},
{"id":10677,"nombre":"Cuatro Vientos","localidad":"Cuatro Vientos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10678,"nombre":"Onagoity","localidad":"Onagoity","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.77324010831655,"longitud":-63.67190064851677,"ranking":0},
{"id":10679,"nombre":"Pincen","localidad":"Pincen","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.8385248,"longitud":-63.91699500000001,"ranking":8},
{"id":10680,"nombre":"Italo","localidad":"Italo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.7904939,"longitud":-63.78130400000001,"ranking":20},
{"id":10681,"nombre":"Ranqueles","localidad":"Ranqueles","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10743,"nombre":"San Martin","localidad":"San Martín","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.412659535968906,"longitud":-65.67528457539785,"ranking":0},
{"id":10745,"nombre":"Campo de las Piedras","localidad":"Campo de las Piedras","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10748,"nombre":"El Pueblito","localidad":"El Pueblito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1171408,"longitud":-64.293446,"ranking":16},
{"id":10749,"nombre":"Los Molles","localidad":"Los Molles","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.959648358385184,"longitud":-65.01298950867493,"ranking":0},
{"id":9446,"nombre":"Chuña","localidad":"Chuña","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.4665481,"longitud":-64.6701609,"ranking":0},
{"id":10750,"nombre":"El Mangrullo","localidad":"El Mangrullo","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10751,"nombre":"Paso Grande","localidad":"Paso Grande","provincia":"San Luis","pais":"ARGENTINA","latitud":-32.877730406033145,"longitud":-65.63476679473416,"ranking":6},
{"id":10752,"nombre":"La Pampita","localidad":"La Pampita","provincia":"San Luis","pais":"ARGENTINA","latitud":-34.65343414190281,"longitud":-59.376066370526964,"ranking":0},
{"id":10754,"nombre":"Cabana","localidad":"Cabana","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.21520395796764,"longitud":-64.35039640335535,"ranking":0},
{"id":10755,"nombre":"Quebrada Honda","localidad":"Quebrada Honda","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10757,"nombre":"Colanchanga","localidad":"Colanchanga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.137725766034187,"longitud":-64.35671661185367,"ranking":0},
{"id":10758,"nombre":"Villa Silvina","localidad":"Villa Silvina","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10759,"nombre":"Pajas Blancas","localidad":"Pajas Blancas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.207856466048142,"longitud":-64.28426139847983,"ranking":0},
{"id":10756,"nombre":"Cerro de Oro","localidad":"Cerro de Oro","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10765,"nombre":"Villa Mercedes","localidad":"Villa Mercedes","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10767,"nombre":"Cañada Larga","localidad":"Altas Cumbres","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10772,"nombre":"El Fuertecito","localidad":"El Fuertecito","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4054662,"longitud":-62.9699365,"ranking":0},
{"id":10773,"nombre":"Fantasio","localidad":"Fantasio","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10774,"nombre":"La Virginia","localidad":"La Virginia","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10775,"nombre":"Los Chañaritos","localidad":"Los Chañaritos","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10776,"nombre":"Malvinas Argentinas","localidad":"Malvinas Argentinas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3744433,"longitud":-64.05341609999999,"ranking":1},
{"id":10777,"nombre":"Mi Granja","localidad":"Mi Granja","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.35841,"longitud":-64.004026,"ranking":7},
{"id":10778,"nombre":"Pedro Vivas","localidad":"Pedro Vivas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.368410199251464,"longitud":-63.52951181132335,"ranking":4},
{"id":10779,"nombre":"Playas de Oro","localidad":"Playas de Oro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.454654420399606,"longitud":-64.51132290894942,"ranking":0},
{"id":10780,"nombre":"Villa Garcia","localidad":"Villa Garcia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3352844,"longitud":-64.54986699999999,"ranking":17},
{"id":9434,"nombre":"Villa Gutierrez","localidad":"Villa Gutierrez","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.6710339,"longitud":-64.16167089999999,"ranking":0},
{"id":10782,"nombre":"Villa Muñoz","localidad":"Villa Muñoz","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":7},
{"id":10783,"nombre":"Villa Tulumba","localidad":"Villa Tulumba","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.396425,"longitud":-64.121974,"ranking":0},
{"id":10799,"nombre":"Larrechea","localidad":"Larrechea","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10801,"nombre":"Ojos de Agua","localidad":"Ojos de Agua","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-41.55797039999999,"longitud":-69.869308,"ranking":0},
{"id":4221,"nombre":"Bahia Manzano","localidad":"Bahia Manzano","provincia":"Neuquén","pais":"ARGENTINA","latitud":-40.795713,"longitud":-71.592257,"ranking":12},
{"id":10804,"nombre":"Potrero de Garay Acceso","localidad":"Potrero de Garay","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.792437,"longitud":-64.547014,"ranking":0},
{"id":12409,"nombre":"Potrero de Garay","localidad":"Potrero de Garay","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.77280459999999,"longitud":-64.53833829999999,"ranking":133},
{"id":3259,"nombre":"Nueva Atlantis","localidad":"Partido de La Costa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.762508,"longitud":-56.68080399999999,"ranking":0},
{"id":10815,"nombre":"Costa Esmeralda","localidad":"Partido de La Costa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-37.0035177,"longitud":-56.805174,"ranking":0},
{"id":10817,"nombre":"Costa Chica","localidad":"Partido de La Costa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.509682,"longitud":-56.7001499,"ranking":0},
{"id":10818,"nombre":"Costa del Este","localidad":"Partido de La Costa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6087864,"longitud":-56.68802280000001,"ranking":0},
{"id":10819,"nombre":"Costa Azul","localidad":"Partido de La Costa","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-36.6714258,"longitud":-56.6814299,"ranking":0},
{"id":1960,"nombre":"Mascasin","localidad":"Mascasin","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.3898792,"longitud":-66.9696742,"ranking":0},
{"id":1954,"nombre":"Real del Cadillo","localidad":"Real del Cadillo","provincia":"La Rioja","pais":"ARGENTINA","latitud":-31.3573184,"longitud":-66.81176549999999,"ranking":0},
{"id":1866,"nombre":"Caseros Acceso","localidad":"Caseros","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.469780438668096,"longitud":-58.478116393089294,"ranking":0},
{"id":10839,"nombre":"Los Chañaritos","localidad":"Los Chañaritos","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.3999266,"longitud":-65.2857082,"ranking":1},
{"id":2128,"nombre":"Monte Vera","localidad":"Monte Vera","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.6144344,"longitud":-60.6969658,"ranking":0},
{"id":10841,"nombre":"Porvenir","localidad":"Porvenir","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.924605,"longitud":-62.1935302,"ranking":0},
{"id":10845,"nombre":"Isthilart","localidad":"Isthilart","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.1677499,"longitud":-57.957205,"ranking":0},
{"id":10846,"nombre":"Pedro Funes","localidad":"Pedro Funes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.2605939,"longitud":-63.29077150000001,"ranking":0},
{"id":10853,"nombre":"Corralito","localidad":"Corralito","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.6839945,"longitud":-59.0687576,"ranking":0},
{"id":10854,"nombre":"Bartolome de las Casas","localidad":"Bartolome de las Casas","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":2},
{"id":10855,"nombre":"Colonia Aborigen","localidad":"Bartolome de las Casas","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.1859356,"longitud":-58.175622,"ranking":0},
{"id":10856,"nombre":"Bruchard","localidad":"Bruchard","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10857,"nombre":"Guaycolec","localidad":"Guaycolec","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.9810897,"longitud":-58.16034229999999,"ranking":0},
{"id":10858,"nombre":"San Hilario","localidad":"San Hilario","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.02999,"longitud":-58.6513953,"ranking":0},
{"id":9213,"nombre":"El Progreso","localidad":"El Progreso","provincia":"Formosa","pais":"ARGENTINA","latitud":-25.5067852,"longitud":-59.4073707,"ranking":0},
{"id":10860,"nombre":"ElProgreso","localidad":"El Progreso","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10863,"nombre":"La Paz","localidad":"Sierras Chicas","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10865,"nombre":"Rio Electrico","localidad":"Rio Electrico","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10866,"nombre":"El Pilar","localidad":"El Pilar","provincia":"Santa Cruz","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10868,"nombre":"Agua de las Palomas","localidad":"Agua de las Palomas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.6198408,"longitud":-66.1171798,"ranking":0},
{"id":10869,"nombre":"Sanchez de Loria","localidad":"Sanchez de Loria","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.6215199,"longitud":-68.5313221,"ranking":0},
{"id":10867,"nombre":"El Pucara","localidad":"El Pucara","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.5795652,"longitud":-66.3319253,"ranking":0},
{"id":10875,"nombre":"Las Talas","localidad":"Las Talas","provincia":"La Rioja","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10877,"nombre":"Lagunillas","localidad":"Lagunillas","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9842,"nombre":"Paraje Rio La Quebrada","localidad":"Rio La Quebrada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.14862,"longitud":-64.340428,"ranking":0},
{"id":10881,"nombre":"Pie de Palo","localidad":"Pie de Palo","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.6602472,"longitud":-68.22022969999999,"ranking":0},
{"id":10882,"nombre":"Caputo","localidad":"Caputo","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.7472463,"longitud":-68.0271085,"ranking":0},
{"id":10883,"nombre":"Control Forestal","localidad":"Control Forestal","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.7386247,"longitud":-67.9899556,"ranking":0},
{"id":10884,"nombre":"Lavalle","localidad":"Lavalle","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.7199469,"longitud":-68.5948736,"ranking":0},
{"id":10886,"nombre":"Quebrada de las Flores","localidad":"Quebrada de las Flores","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.5035172,"longitud":-67.6662231,"ranking":0},
{"id":10885,"nombre":"Mascasin","localidad":"Mascasin","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8514,"nombre":"Control San Carlos","localidad":"San Carlos","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.2551799,"longitud":-68.50962609999999,"ranking":0},
{"id":10887,"nombre":"San Carlos","localidad":"San Carlos","provincia":"San Juan","pais":"ARGENTINA","latitud":-32.0852932,"longitud":-68.4572093,"ranking":0},
{"id":10889,"nombre":"Agua Cercada","localidad":"Agua Cercada","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.8163048,"longitud":-67.35855409999999,"ranking":0},
{"id":10890,"nombre":"Astica","localidad":"Astica","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.953972,"longitud":-67.302636,"ranking":2},
{"id":10891,"nombre":"Chucuma","localidad":"Chucuma","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.073466,"longitud":-67.266862,"ranking":0},
{"id":10892,"nombre":"La Mesada","localidad":"La Mesada","provincia":"San Juan","pais":"ARGENTINA","latitud":-28.8695117,"longitud":-67.5677716,"ranking":0},
{"id":10893,"nombre":"Laguna Seca","localidad":"Laguna Seca","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.6001085,"longitud":-67.6172291,"ranking":0},
{"id":10894,"nombre":"Las Tumanas","localidad":"Las Tumanas","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.867267,"longitud":-67.321082,"ranking":20},
{"id":7383,"nombre":"La Punta","localidad":"La Punta","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.1808302,"longitud":-66.3097533,"ranking":0},
{"id":10910,"nombre":"Centeno","localidad":"Centeno","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10911,"nombre":"Albardon","localidad":"Albardon","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.4508462,"longitud":-68.518554,"ranking":17},
{"id":10912,"nombre":"La Cienaga","localidad":"La Cienaga","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10913,"nombre":"Huaco","localidad":"Huaco","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.157236135958403,"longitud":-68.48268884835952,"ranking":0},
{"id":10915,"nombre":"Pismanta","localidad":"Pismanta","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.280992,"longitud":-69.229055,"ranking":1},
{"id":10916,"nombre":"Villa Mercedes","localidad":"Villa Mercedes","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.149863,"longitud":-68.663539,"ranking":0},
{"id":8276,"nombre":"Tucunuco","localidad":"Tucunuco","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.6087508,"longitud":-68.6410246,"ranking":0},
{"id":10923,"nombre":"San Roque","localidad":"San Roque","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.2707699,"longitud":-68.6964389,"ranking":3},
{"id":10924,"nombre":"Tambory","localidad":"Tambory","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":-27.035213,"longitud":-56.674019,"ranking":0},
{"id":10935,"nombre":"Ilha do Mel","localidad":"Ilha do Mel","provincia":"Parana","pais":"BRASIL","latitud":-25.567493,"longitud":-48.357726,"ranking":0},
{"id":10936,"nombre":"Ambargasta","localidad":"Ambargasta","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.0504049,"longitud":-63.9553814,"ranking":0},
{"id":10937,"nombre":"Epulef Acceso","localidad":"Epulef","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.3336802,"longitud":-69.84957179999999,"ranking":0},
{"id":10938,"nombre":"Colan Conhue","localidad":"Colan Conhue","provincia":"Chubut","pais":"ARGENTINA","latitud":-43.2410129,"longitud":-69.93030949999999,"ranking":0},
{"id":12307,"nombre":"Tristan Suarez","localidad":"Tristan Suarez","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.881547,"longitud":-58.557436,"ranking":0},
{"id":1731,"nombre":"Granadero Baigorria","localidad":"Granadero Baigorria","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-32.8580967,"longitud":-60.7035763,"ranking":0},
{"id":12141,"nombre":"Sol de Julio","localidad":"Sol de Julio","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-29.5650766,"longitud":-63.45739709999999,"ranking":0},
{"id":8132,"nombre":"Luque","localidad":"Luque","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.270424,"longitud":-57.48516,"ranking":0},
{"id":12557,"nombre":"Ita Angu'a","localidad":"Luque","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.2394492,"longitud":-57.43003419999999,"ranking":0},
{"id":14242,"nombre":"Desvio Luque","localidad":"Luque","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.264512162448405,"longitud":-57.484341415776456,"ranking":0},
{"id":12236,"nombre":"Abai","localidad":"Abaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.0450174,"longitud":-55.9455554,"ranking":0},
{"id":12198,"nombre":"Bahia Negra","localidad":"Bahía Negra","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-20.2303585,"longitud":-58.16806380000001,"ranking":0},
{"id":15395,"nombre":"Puerto Diana","localidad":"Bahía Negra","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-20.255346799562677,"longitud":-58.16580947570644,"ranking":0},
{"id":12199,"nombre":"Capitan Carmelo Peralta","localidad":"Capitán Carmelo Peralta","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-21.6879725,"longitud":-57.9010267,"ranking":0},
{"id":12200,"nombre":"Fuerte Olimpo","localidad":"Fuerte Olimpo","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-21.0393528,"longitud":-57.8700972,"ranking":0},
{"id":12201,"nombre":"Terminal de Puerto Casado","localidad":"Puerto Casado","provincia":"Alto Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12202,"nombre":"Terminal de Doctor Raul Peña","localidad":"Doctor Raúl Peña","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12578,"nombre":"Cruce Raul Peña","localidad":"Doctor Raúl Peña","provincia":"Alto Parana","pais":"Paraguay","latitud":-26.1539081,"longitud":-55.27891,"ranking":0},
{"id":12203,"nombre":"Terminal de Domingo Martinez de Irala","localidad":"Domingo Martínez de Irala","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12204,"nombre":"Terminal de Iruña","localidad":"Iruña","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12567,"nombre":"Cruce Iruña","localidad":"Iruña","provincia":"Alto Parana","pais":"Paraguay","latitud":-26.1070588,"longitud":-55.0549579,"ranking":0},
{"id":12205,"nombre":"Terminal de Los Cedrales","localidad":"Los Cedrales","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12206,"nombre":"Terminal de Mbaracayu","localidad":"Mbaracayú","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12207,"nombre":" Minga Pora","localidad":"Minga Porã","provincia":"Alto Parana","pais":"Paraguay","latitud":-24.8727931,"longitud":-54.93124770000001,"ranking":0},
{"id":12208,"nombre":"Terminal de Naranjal","localidad":"Naranjal","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12566,"nombre":"Cruce Naranjal","localidad":"Naranjal","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.9747511,"longitud":-55.1800615,"ranking":0},
{"id":12209,"nombre":"Terminal de Ñacunday","localidad":"Ñacunday","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12210,"nombre":" Presidente Franco","localidad":"Presidente Franco","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.558758,"longitud":-54.623537,"ranking":0},
{"id":12211,"nombre":"San Cristobal","localidad":"San Cristóbal","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.8912758,"longitud":-55.4707228,"ranking":0},
{"id":12212,"nombre":"Terminal de Santa Fe del Paraná","localidad":"Santa Fe del Paraná","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12213,"nombre":"Terminal de Santa Rosa del Monday","localidad":"Santa Rosa del Monday","provincia":"Alto Parana","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12214,"nombre":"Yguazu","localidad":"Yguazú","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.466259,"longitud":-54.997015,"ranking":0},
{"id":12215,"nombre":"Bella Vista Norte","localidad":"Bella Vista","provincia":"Amambay","pais":"Paraguay","latitud":-22.12879,"longitud":-56.514303,"ranking":0},
{"id":12216,"nombre":"Capitan Bado","localidad":"Capitán Bado","provincia":"Amambay","pais":"Paraguay","latitud":-23.270779,"longitud":-55.542677,"ranking":0},
{"id":12217,"nombre":"Karapai","localidad":"Karapã'í","provincia":"Amambay","pais":"Paraguay","latitud":-23.598763,"longitud":-55.986684,"ranking":0},
{"id":12142,"nombre":"Terminal de Aregua","localidad":"Areguá","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12143,"nombre":"Terminal de Guarambare","localidad":"Guarambaré","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12144,"nombre":"Julian Augusto Saldivar","localidad":"Julián Augusto Saldívar","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.456249972860444,"longitud":-57.442635344647684,"ranking":0},
{"id":12145,"nombre":"Terminal de Lambare","localidad":"Lambaré","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14243,"nombre":"Cuatro Mojones","localidad":"Lambaré","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.3398454,"longitud":-57.5936902,"ranking":0},
{"id":12146,"nombre":"Terminal de Nueva Italia","localidad":"Nueva Italia","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12147,"nombre":"San Antonio","localidad":"San Antonio","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.4125855,"longitud":-57.5739864,"ranking":0},
{"id":12148,"nombre":"Terminal de Villa Elisa","localidad":"Villa Elisa","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12149,"nombre":"Villeta","localidad":"Villeta","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.5228219,"longitud":-57.55168380000001,"ranking":0},
{"id":12150,"nombre":"Terminal de Ypane","localidad":"Ypané","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12151,"nombre":"Borja","localidad":"Borja","provincia":"Guairá","pais":"Paraguay","latitud":-25.9499474,"longitud":-56.4947394,"ranking":0},
{"id":15366,"nombre":"Desvio Borja","localidad":"Borja","provincia":"Guairá","pais":"Paraguay","latitud":-25.953580581427072,"longitud":-56.49414768169707,"ranking":0},
{"id":12152,"nombre":"Terminal de Colonia Independencia","localidad":"Colonia Independencia","provincia":"Guairá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12153,"nombre":"Coronel Martinez","localidad":"Coronel Martínez","provincia":"Guairá","pais":"Paraguay","latitud":-25.7604949,"longitud":-56.61625979999999,"ranking":0},
{"id":12154,"nombre":"Terminal de Doctor Botrell","localidad":"Doctor Botrell","provincia":"Guairá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12155,"nombre":"Felix Perez Cardozo","localidad":"Félix Pérez Cardozo","provincia":"Guairá","pais":"Paraguay","latitud":-25.742599,"longitud":-56.522792,"ranking":0},
{"id":12156,"nombre":"Terminal de Itape","localidad":"Itapé","provincia":"Guairá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12157,"nombre":"Iturbe","localidad":"Iturbe","provincia":"Guairá","pais":"Paraguay","latitud":-26.0572265,"longitud":-56.48494849999999,"ranking":0},
{"id":15367,"nombre":"Desvio Iturbe","localidad":"Iturbe","provincia":"Guairá","pais":"Paraguay","latitud":-26.0525471545645,"longitud":-56.486213488898834,"ranking":0},
{"id":12158,"nombre":"Natalicio Talavera","localidad":"Natalicio Talavera","provincia":"Guairá","pais":"Paraguay","latitud":-25.6576184,"longitud":-56.3079441,"ranking":0},
{"id":12159,"nombre":"Paso Yobai","localidad":"Paso Yobái","provincia":"Guairá","pais":"Paraguay","latitud":-25.723357,"longitud":-56.0025796,"ranking":0},
{"id":12160,"nombre":"San Salvador","localidad":"San Salvador","provincia":"Guairá","pais":"Paraguay","latitud":-25.9438135,"longitud":-56.4715971,"ranking":0},
{"id":12161,"nombre":"Troche","localidad":"Troche","provincia":"Guairá","pais":"Paraguay","latitud":-25.6170957,"longitud":-56.2755069,"ranking":0},
{"id":12162,"nombre":"Yataity","localidad":"Yataity","provincia":"Guairá","pais":"Paraguay","latitud":-25.6879908,"longitud":-56.4691284,"ranking":0},
{"id":12179,"nombre":"Terminal de Santa Maria","localidad":"Santa María","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12180,"nombre":"Terminal de Santiago","localidad":"Santiago","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12181,"nombre":"Terminal de Yabebyry","localidad":"Yabebyry","provincia":"Misiones - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12252,"nombre":"Terminal de General Jose Maria Bruguez","localidad":"General José María Bruguez","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12253,"nombre":"Terminal de Nanawa","localidad":"Nanawa","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12254,"nombre":"Terminal de Puerto Pinasco","localidad":"Puerto Pinasco","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12255,"nombre":"Terminal de Tte. Esteban Martinez","localidad":"Tte. Esteban Martínez","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12256,"nombre":"Terminal de Tte. Irala Fernandez","localidad":"Tte. Irala Fernández","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12163,"nombre":"Terminal de Alto Vera","localidad":"Alto Verá","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12164,"nombre":"Terminal de Cambyreta","localidad":"Cambyretá","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12165,"nombre":"Terminal de Carlos Antonio Lopez","localidad":"Carlos Antonio López","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12166,"nombre":"Terminal de Fram","localidad":"Fram","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12167,"nombre":"Terminal de Itapua Poty","localidad":"Itapúa Poty","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12168,"nombre":"Terminal de Jesus","localidad":"Jesús","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12169,"nombre":"Terminal de La Paz","localidad":"La Paz","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12170,"nombre":"Terminal de Mayor Otaño","localidad":"Mayor Otaño","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12171,"nombre":"Natalio","localidad":"Natalio","provincia":"Itapua","pais":"Paraguay","latitud":-26.7584912,"longitud":-55.1365978,"ranking":0},
{"id":12172,"nombre":"Terminal de Nueva Alborada","localidad":"Nueva Alborada","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12173,"nombre":"Terminal de San Cosme y Damian","localidad":"San Cosme y Damián","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12174,"nombre":"Terminal de San Juan del Parana","localidad":"San Juan del Paraná","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12175,"nombre":"San Rafael del Parana","localidad":"San Rafael del Paraná","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12176,"nombre":"Terminal de Tomas Romero Pereira","localidad":"Tomás Romero Pereira","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12177,"nombre":"Terminal de Trinidad","localidad":"Trinidad","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12178,"nombre":"Terminal de Yatytay","localidad":"Yatytay","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12182,"nombre":"Alberdi","localidad":"Alberdi","provincia":"Neembucú","pais":"Paraguay","latitud":-26.1849296,"longitud":-58.1431608,"ranking":25},
{"id":12183,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Neembucú","pais":"Paraguay","latitud":-27.3355611,"longitud":-57.6394832,"ranking":0},
{"id":12184,"nombre":"Terminal de Desmochados","localidad":"Desmochados","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12185,"nombre":"Terminal de General Jose Eduvigis Diaz","localidad":"General José Eduvigis Díaz","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12186,"nombre":"Terminal de Guazu Cua","localidad":"Guazú Cuá","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12187,"nombre":"Terminal de Humaita","localidad":"Humaitá","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12188,"nombre":"Terminal de Isla Umbu","localidad":"Isla Umbú","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12189,"nombre":"Terminal de Laureles","localidad":"Laureles","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12190,"nombre":"Mayor Jose J. Martinez","localidad":"Mayor José J. Martínez","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12191,"nombre":"Terminal de Paso de Patria","localidad":"Paso de Patria","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12192,"nombre":"Pilar","localidad":"Pilar","provincia":"Neembucú","pais":"Paraguay","latitud":-26.8624512,"longitud":-58.2962009,"ranking":0},
{"id":12193,"nombre":"Terminal de San Juan Bautista del Ñeembucu","localidad":"San Juan Bautista del Ñeembucú","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12195,"nombre":"Terminal de Villa Franca","localidad":"Villa Franca","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12196,"nombre":"Villa Oliva","localidad":"Villa Oliva","provincia":"Neembucú","pais":"Paraguay","latitud":-26.0159182,"longitud":-57.85743050000001,"ranking":0},
{"id":12197,"nombre":"Terminal de Villalbin","localidad":"Villalbín","provincia":"Neembucú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12218,"nombre":"Terminal de Zanja Pyta","localidad":"Zanja Pytã","provincia":"Amambay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12219,"nombre":"Terminal de Filadelfia","localidad":"Filadelfia","provincia":"Boquerón","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12221,"nombre":"Terminal de Loma Plata","localidad":"Loma Plata","provincia":"Boquerón","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12220,"nombre":"Terminal de Mariscal Estigarribia","localidad":"Mariscal Estigarribia","provincia":"Boquerón","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":8125,"nombre":"Pastoreo - Dr Juan Manuel Frutos","localidad":"Doctor Juan Manuel Frutos","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.383483,"longitud":-55.827373,"ranking":7},
{"id":12222,"nombre":"Pastoreo -","localidad":"Doctor Juan Manuel Frutos","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12563,"nombre":"Cruce Pastoreo","localidad":"Doctor Juan Manuel Frutos","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.3996357,"longitud":-55.80964299999999,"ranking":0},
{"id":12223,"nombre":"Terminal de La Pastora","localidad":"La Pastora","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12224,"nombre":"Mariscal Francisco S Lopez","localidad":"Mcal. Francisco S. López","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12225,"nombre":"Terminal de Nueva Londres","localidad":"Nueva Londres","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12564,"nombre":"Desvio Nueva Londres","localidad":"Nueva Londres","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.4038745,"longitud":-56.5504818,"ranking":0},
{"id":12226,"nombre":"Terminal de Nueva Toledo","localidad":"Nueva Toledo","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12227,"nombre":"Terminal de Tres Corrales","localidad":"R. I. Tres Corrales","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12228,"nombre":"Terminal de Raul Arsenio Oviedo","localidad":"Raúl Arsenio Oviedo","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12229,"nombre":"Repatriacion","localidad":"Repatriación","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.5381576,"longitud":-55.9899882,"ranking":0},
{"id":12230,"nombre":"Terminal de San Joaquin","localidad":"San Joaquín","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12231,"nombre":"Simon Bolivar","localidad":"Simón Bolívar","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.0520712,"longitud":-56.3261285,"ranking":0},
{"id":12232,"nombre":"Terminal de Tembiapora","localidad":"Tembiaporá","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12233,"nombre":"Tres de Febrero","localidad":"Tres de Febrero","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.2471406,"longitud":-55.7766154,"ranking":0},
{"id":12234,"nombre":"Terminal de Vaqueria","localidad":"Vaquería","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12235,"nombre":"Terminal de Yhu","localidad":"Yhú","provincia":"Caaguazú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12237,"nombre":"Buena Vista","localidad":"Buena Vista","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1845856,"longitud":-56.0823274,"ranking":0},
{"id":12565,"nombre":"Cruce Buena Vista","localidad":"Buena Vista","provincia":"Caazapá","pais":"Paraguay","latitud":-26.181807,"longitud":-56.0826915,"ranking":0},
{"id":12238,"nombre":"Maciel","localidad":"Maciel","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1823406,"longitud":-56.4754241,"ranking":0},
{"id":12239,"nombre":"Tavai","localidad":"Tavaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.196583985807287,"longitud":-56.371077396683546,"ranking":0},
{"id":12558,"nombre":"Enramadita","localidad":"Tavaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.167752,"longitud":-55.66878000000001,"ranking":0},
{"id":12571,"nombre":"Atongue","localidad":"Tavaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1702641,"longitud":-55.65708979999999,"ranking":0},
{"id":12572,"nombre":"Ñu Pyahu","localidad":"Tavaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.2114443,"longitud":-55.4652808,"ranking":0},
{"id":12577,"nombre":"Yvy Aty","localidad":"Tavaí","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1785279,"longitud":-55.5805646,"ranking":0},
{"id":12240,"nombre":"Tres de Mayo","localidad":"Tres de Mayo","provincia":"Caazapá","pais":"Paraguay","latitud":-26.480787809239892,"longitud":-56.09650703471798,"ranking":0},
{"id":12241,"nombre":"Corpus Christi","localidad":"Corpus Christi","provincia":"Caazapá","pais":"Paraguay","latitud":-24.0784805,"longitud":-54.9413858,"ranking":0},
{"id":12242,"nombre":"Curuguaty","localidad":"Curuguaty","provincia":"Caazapá","pais":"Paraguay","latitud":-24.471508,"longitud":-55.691397,"ranking":0},
{"id":12243,"nombre":"Gral Francisco Caballero Alvarez","localidad":"Gral. Francisco Caballero Álvarez","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12244,"nombre":"Terminal de Itanara","localidad":"Itanará","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12245,"nombre":"Terminal de Maracana","localidad":"Maracaná","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12246,"nombre":"Terminal de Puerto Adela","localidad":"Puerto Adela","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12247,"nombre":"Terminal de Villa Ygatimi","localidad":"Villa Ygatimí","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12248,"nombre":"Yasy Cañy","localidad":"Yasy Cañy","provincia":"Caazapá","pais":"Paraguay","latitud":-24.52426513897833,"longitud":-55.88225841522217,"ranking":4},
{"id":12249,"nombre":"Yby Pyta","localidad":"Yby Pytã","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.414490548352322,"longitud":-55.39376392396608,"ranking":0},
{"id":12250,"nombre":"Terminal de Ybyrarovana","localidad":"Ybyrarovaná","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12251,"nombre":"Terminal de Ypejhu","localidad":"Ypejhú","provincia":"Caazapá","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12258,"nombre":"Terminal de Acahay","localidad":"Acahay","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12259,"nombre":"Escobar","localidad":"Escobar","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.6435375,"longitud":-57.0374819,"ranking":0},
{"id":12260,"nombre":"Gral. Bernardino Caballero","localidad":"General Bernardino Caballero","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.6945533,"longitud":-56.8628988,"ranking":0},
{"id":12261,"nombre":"Terminal de La Colmena","localidad":"La Colmena","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12262,"nombre":"Maria Antonia","localidad":"María Antonia","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-26.4330396,"longitud":-56.9011864,"ranking":0},
{"id":12263,"nombre":"Terminal de Mbuyapey","localidad":"Mbuyapey","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12265,"nombre":"Quyquyho","localidad":"Quyquyhó","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-26.2309322,"longitud":-56.9893935,"ranking":0},
{"id":12266,"nombre":"Sapucai","localidad":"Sapucai","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.6681345,"longitud":-56.9517252,"ranking":0},
{"id":12267,"nombre":"Terminal de Tebicuarymi","localidad":"Tebicuarymí","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12268,"nombre":"Terminal de Ybycui","localidad":"Ybycuí","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12269,"nombre":"Ybytymi","localidad":"Ybytymí","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-25.757154,"longitud":-56.8039322,"ranking":0},
{"id":12270,"nombre":"Capiibary","localidad":"Capiibary","provincia":"San Pedro","pais":"Paraguay","latitud":-24.738042289739823,"longitud":-56.024909019470215,"ranking":1},
{"id":12271,"nombre":"Chore","localidad":"Choré","provincia":"San Pedro","pais":"Paraguay","latitud":-24.1844138,"longitud":-56.5911217,"ranking":0},
{"id":12272,"nombre":"General Elizardo Aquino","localidad":"General Elizardo Aquino","provincia":"San Pedro","pais":"Paraguay","latitud":-24.4431661,"longitud":-56.8963876,"ranking":2},
{"id":12273,"nombre":"Guayaibi","localidad":"Guayaibí","provincia":"San Pedro","pais":"Paraguay","latitud":-24.525574,"longitud":-56.407463,"ranking":15},
{"id":12274,"nombre":"Itacurubi del Rosario","localidad":"Itacurubí del Rosario","provincia":"San Pedro","pais":"Paraguay","latitud":-24.5321527,"longitud":-56.8245044,"ranking":0},
{"id":12275,"nombre":"Terminal de San Estanislao","localidad":"San Estanislao","provincia":"San Pedro","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12276,"nombre":"Terminal de San Pablo","localidad":"San Pablo","provincia":"San Pedro","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12277,"nombre":"San Vicente Pancholo","localidad":"San Vicente Pancholo","provincia":"San Pedro","pais":"Paraguay","latitud":-23.9999677,"longitud":-56.1298129,"ranking":3},
{"id":12278,"nombre":"Unión","localidad":"Unión","provincia":"San Pedro","pais":"Paraguay","latitud":-24.810244,"longitud":-56.521094,"ranking":0},
{"id":12279,"nombre":"Veinticinco de Diciembre","localidad":"25 de Diciembre","provincia":"San Pedro","pais":"Paraguay","latitud":-24.8135328,"longitud":-56.7281346,"ranking":5},
{"id":12280,"nombre":"Villa del Rosario","localidad":"Villa del Rosario","provincia":"San Pedro","pais":"Paraguay","latitud":-24.4287193,"longitud":-57.12939679999999,"ranking":0},
{"id":12281,"nombre":"Yataity del Norte","localidad":"Yataity del Norte","provincia":"San Pedro","pais":"Paraguay","latitud":-24.852253,"longitud":-56.35183,"ranking":0},
{"id":12388,"nombre":"Felipe Matiauda","localidad":"Yataity del Norte","provincia":"San Pedro","pais":"Paraguay","latitud":-24.845001,"longitud":-56.355425,"ranking":0},
{"id":12282,"nombre":"Terminal de Yrybucua","localidad":"Yrybucuá","provincia":"San Pedro","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12283,"nombre":"Arroyito","localidad":"Arroyito","provincia":"Concepción","pais":"Paraguay","latitud":-31.42260176458467,"longitud":-63.04528295574244,"ranking":0},
{"id":12284,"nombre":"Belén","localidad":"Belén","provincia":"Concepción","pais":"Paraguay","latitud":-23.463775,"longitud":-57.264753,"ranking":0},
{"id":12285,"nombre":"Loreto","localidad":"Loreto","provincia":"Concepción","pais":"Paraguay","latitud":-23.275084,"longitud":-57.336791,"ranking":0},
{"id":12286,"nombre":"San Alfredo","localidad":"San Alfredo","provincia":"Concepción","pais":"Paraguay","latitud":-22.883745,"longitud":-57.404913,"ranking":0},
{"id":12287,"nombre":"Terminal de San Carlos del Apa","localidad":"San Carlos del Apa","provincia":"Concepción","pais":"Paraguay","latitud":-22.2586046,"longitud":-57.2953613,"ranking":0},
{"id":14646,"nombre":"San Carlos","localidad":"San Carlos del Apa","provincia":"Concepción","pais":"Paraguay","latitud":-22.243445232131634,"longitud":-57.28832519609379,"ranking":0},
{"id":12288,"nombre":"Terminal de San Lazaro","localidad":"San Lázaro","provincia":"Concepción","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12289,"nombre":"Sargento Jose Felix Lopez","localidad":"Sargento José Félix López","provincia":"Concepción","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12290,"nombre":"Terminal de Altos","localidad":"Altos","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12291,"nombre":" Arroyos y Esteros","localidad":"Arroyos y Esteros","provincia":"Cordillera","pais":"Paraguay","latitud":-25.0560221,"longitud":-57.0982862,"ranking":9},
{"id":12292,"nombre":"Terminal de Atyra","localidad":"Atyrá","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12293,"nombre":"Terminal de Caraguatay","localidad":"Caraguatay","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12294,"nombre":"Emboscada","localidad":"Emboscada","provincia":"Cordillera","pais":"Paraguay","latitud":-25.124793,"longitud":-57.342337,"ranking":4},
{"id":12295,"nombre":"Terminal de Isla Pucu","localidad":"Isla Pucú","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12296,"nombre":"Juan de Mena","localidad":"Juan de Mena","provincia":"Cordillera","pais":"Paraguay","latitud":-24.957743,"longitud":-56.763569,"ranking":0},
{"id":12297,"nombre":"Terminal de Loma Grande","localidad":"Loma Grande","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12298,"nombre":"Terminal de Mbocayaty del Yhaguy","localidad":"Mbocayaty del Yhaguy","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12299,"nombre":"Terminal de Nueva Colombia","localidad":"Nueva Colombia","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12300,"nombre":"Terminal de Piribebuy","localidad":"Piribebuy","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12576,"nombre":"Desvio Piribebuy","localidad":"Piribebuy","provincia":"Cordillera","pais":"Paraguay","latitud":-25.4663528,"longitud":-57.0478755,"ranking":0},
{"id":12301,"nombre":"Terminal de Primero de Marzo","localidad":"Primero de Marzo","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12302,"nombre":"Terminal de San Bernardino","localidad":"San Bernardino","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12303,"nombre":"Terminal de San Jose Obrero","localidad":"San José Obrero","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12304,"nombre":"Terminal de Santa Elena","localidad":"Santa Elena","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12305,"nombre":"Terminal de Tobati","localidad":"Tobatí","provincia":"Cordillera","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12306,"nombre":"Valenzuela","localidad":"Valenzuela","provincia":"Cordillera","pais":"Paraguay","latitud":-25.592399242006223,"longitud":-56.87148039321033,"ranking":0},
{"id":12308,"nombre":"Simbol","localidad":"Simbol","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.1005233,"longitud":-64.2103742,"ranking":0},
{"id":12317,"nombre":"Pavon Arriba","localidad":"Pavón Arriba","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.3154908,"longitud":-60.8235773,"ranking":0},
{"id":12319,"nombre":"Gobernador Lopez Acceso","localidad":"Gobernador López","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.666566,"longitud":-55.2131295,"ranking":0},
{"id":12320,"nombre":"Valle Hermoso","localidad":"Valle Hermoso","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.3861036,"longitud":-54.4663233,"ranking":0},
{"id":9589,"nombre":"Dos Hermanas","localidad":"Dos Hermanas","provincia":"Misiones","pais":"ARGENTINA","latitud":-26.2942276,"longitud":-53.7553914,"ranking":0},
{"id":12321,"nombre":"Tres Capones","localidad":"Tres Capones","provincia":"Misiones","pais":"ARGENTINA","latitud":-28.0065939,"longitud":-55.6054792,"ranking":0},
{"id":12350,"nombre":"Faxinal do Ceu","localidad":"Faxinal do Ceu","provincia":"Parana","pais":"BRASIL","latitud":-25.913627848511144,"longitud":-51.58123323336387,"ranking":0},
{"id":12358,"nombre":"Bonfim Paulista","localidad":"Bonfim Paulista","provincia":"Sao Paulo","pais":"BRASIL","latitud":-21.2654256,"longitud":-47.8189866,"ranking":0},
{"id":12359,"nombre":"Claudinapolis","localidad":"Claudinapolis","provincia":"Goias","pais":"BRASIL","latitud":-16.5641883,"longitud":-49.77087849999999,"ranking":0},
{"id":12369,"nombre":"Villa Mantero Acceso","localidad":"Villa Mantero","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.4112882,"longitud":-58.7455156,"ranking":0},
{"id":2911,"nombre":"San Jose Acceso","localidad":"San Jose","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.2195940048372,"longitud":-58.23753517919904,"ranking":0},
{"id":3094,"nombre":"Villa San Jose","localidad":"San Jose","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1922668,"longitud":-58.16429849999999,"ranking":0},
{"id":12373,"nombre":"San Jose","localidad":"San Jose","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.219262,"longitud":-58.2374032,"ranking":50},
{"id":15469,"nombre":"Termas de San Jose","localidad":"San Jose","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1922668,"longitud":-58.16429849999999,"ranking":0},
{"id":12382,"nombre":"Gobernador Sola","localidad":"Tala","provincia":"Entre Rios","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8688,"nombre":"Cecilio Baez Acceso","localidad":"Cecilio Baez","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.072533,"longitud":-56.250692,"ranking":0},
{"id":12385,"nombre":"Cecilio Baez","localidad":"Cecilio Baez","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.070437,"longitud":-56.242412,"ranking":0},
{"id":12386,"nombre":"Colonia Piray","localidad":"Colonia Piray","provincia":"Cordillera","pais":"Paraguay","latitud":-25.385414,"longitud":-56.715902,"ranking":0},
{"id":12387,"nombre":"Guaica Acceso","localidad":"Guaica","provincia":"San Pedro","pais":"Paraguay","latitud":-24.686873,"longitud":-56.47528,"ranking":0},
{"id":12389,"nombre":"Laurel","localidad":"Laurel","provincia":"Canindeyú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12390,"nombre":"Paso Barreto","localidad":"Paso Barreto","provincia":"Concepción","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":12393,"nombre":"Mboi Y","localidad":"Mboi-Y","provincia":"Presidente Hayes","pais":"Paraguay","latitud":-24.763609,"longitud":-56.590195,"ranking":0},
{"id":12394,"nombre":"Curupayty","localidad":"Curupayty","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.509445,"longitud":-54.615399,"ranking":0},
{"id":8122,"nombre":"Obligado","localidad":"Obligado","provincia":"Itapua","pais":"Paraguay","latitud":-27.059752,"longitud":-55.630101,"ranking":0},
{"id":9206,"nombre":"Edelira","localidad":"Edelira","provincia":"Itapua","pais":"Paraguay","latitud":-26.72229,"longitud":-55.34285,"ranking":0},
{"id":12560,"nombre":"Edelira 28","localidad":"Edelira","provincia":"Itapua","pais":"Paraguay","latitud":-26.7694174,"longitud":-55.2809147,"ranking":0},
{"id":12561,"nombre":"Desvio Edelira","localidad":"Edelira","provincia":"Itapua","pais":"Paraguay","latitud":-26.7728707,"longitud":-55.2769201,"ranking":1},
{"id":14254,"nombre":"Barrio San Pedro","localidad":"Barrio San Pedro","provincia":"San Pedro","pais":"Paraguay","latitud":-24.4149949,"longitud":-56.4143135,"ranking":3},
{"id":12410,"nombre":"Colonia Tirolesa","localidad":"Colonia Tirolesa","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.2366955,"longitud":-64.0668113,"ranking":0},
{"id":12411,"nombre":"Villa Esquiu","localidad":"Villa Esquiu","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.36870693598258,"longitud":-64.10982884500328,"ranking":0},
{"id":12412,"nombre":"Arroyo Maldonado","localidad":"Arroyo Maldonado","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":8038,"nombre":"Alcira Gigena Acceso","localidad":"Alcira Gigena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.7591745,"longitud":-64.32222589999999,"ranking":0},
{"id":12414,"nombre":"Alcira Gigena","localidad":"Alcira Gigena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.7551088,"longitud":-64.3390428,"ranking":109},
{"id":12417,"nombre":"Ciudad de America","localidad":"Ciudad de America","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7938613,"longitud":-64.5035152,"ranking":10},
{"id":12419,"nombre":"El Rastrero","localidad":"El Rastrero","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.6660937,"longitud":-63.5400345,"ranking":0},
{"id":12420,"nombre":"El Quebrachal","localidad":"El Quebrachal","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3362431,"longitud":-64.0787899,"ranking":0},
{"id":12418,"nombre":"Falda de Los Reartes","localidad":"Falda de Los Reartes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9183626,"longitud":-64.5758271,"ranking":4},
{"id":12421,"nombre":"Los Espinillos","localidad":"Los Espinillos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4601679,"longitud":-64.1076519,"ranking":23},
{"id":12422,"nombre":"Ruiz Diaz de Guzman","localidad":"Ruiz Diaz de Guzman","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.8707199,"longitud":-63.46193470000001,"ranking":0},
{"id":12430,"nombre":"Quatituba","localidad":"Quatituba","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12432,"nombre":"Laginha","localidad":"Laginha","provincia":"Rio Grande do Norte","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12436,"nombre":"Santa Luzia do Azul","localidad":"Santa Luzia do Azul","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12437,"nombre":"Cachoeirinha","localidad":"Cachoeirinha","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12438,"nombre":"Vila Monte Senir","localidad":"Vila Monte Senir","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12440,"nombre":"Governador Lacerda de Aguiar","localidad":"Governador Lacerda de Aguiar","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12441,"nombre":"Vila Nelita","localidad":"Vila Nelita","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12442,"nombre":"Santo Agostinho","localidad":"Santo Agostinho","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12443,"nombre":"Vila Luciene","localidad":"Vila Luciene","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12444,"nombre":"Vila Landinha","localidad":"Vila Landinha","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12445,"nombre":"Itaciba","localidad":"Itaciba","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12446,"nombre":"Santa Terezinha","localidad":"Santa Terezinha","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12447,"nombre":"Muritiba","localidad":"Muritiba","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12448,"nombre":"Imburana","localidad":"Imburana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12431,"nombre":"Novo Brasil","localidad":"Novo Brasil","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12449,"nombre":"Moacir Avidos","localidad":"Moacir Avidos","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12456,"nombre":"Vila Verde","localidad":"Vila Verde","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12452,"nombre":"Sao Francisco","localidad":"Sao Francisco","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12453,"nombre":"Arrependido","localidad":"Arrependido","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12454,"nombre":"Lagoa","localidad":"Lagoa","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12455,"nombre":"Lagoa Nova","localidad":"Lagoa Nova","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12457,"nombre":"Vargem Grande","localidad":"Vargem Grande","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12458,"nombre":"Santa Joana","localidad":"Santa Joana","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12459,"nombre":"Maria Ortiz","localidad":"Maria Ortiz","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12460,"nombre":"Nilson Balbino","localidad":"Nilson Balbino","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12461,"nombre":"Sao Lourenco","localidad":"Sao Lourenço","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12462,"nombre":"Rancho Fundo","localidad":"Rancho Fundo","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12465,"nombre":"Paraiso","localidad":"Paraiso","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12467,"nombre":"Acampamento","localidad":"Acampamento","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12470,"nombre":"Cotaxe","localidad":"Cotaxe","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12520,"nombre":"Entroncamento Cotaxe","localidad":"Cotaxe","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12471,"nombre":"Joassuba","localidad":"Joassuba","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12478,"nombre":"Alto Piao","localidad":"Alto Piao","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12484,"nombre":"Humaita","localidad":"Humaita","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12497,"nombre":"Sapucaia","localidad":"Sapucaia","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12504,"nombre":"Suissa","localidad":"Suissa","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12505,"nombre":"Rio Bonito","localidad":"Rio Bonito","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12540,"nombre":"Polso Alto","localidad":"Polso Alto","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12541,"nombre":"Vila dos Italianos","localidad":"Vila dos Italianos","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":12547,"nombre":"Santa Luzia do Norte","localidad":"Santa Luzia do Norte","provincia":"Espirito Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":2740,"nombre":"Dique Los Molinos","localidad":"Dique Los Molinos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.818458,"longitud":-64.50402489999999,"ranking":2},
{"id":12568,"nombre":"Boqueron","localidad":"Boqueron","provincia":"Boquerón","pais":"Paraguay","latitud":-26.2504982,"longitud":-56.242169,"ranking":0},
{"id":14644,"nombre":"La Patria","localidad":"Boqueron","provincia":"Boquerón","pais":"Paraguay","latitud":-21.3666344,"longitud":-61.5020628,"ranking":0},
{"id":12574,"nombre":"Amambay","localidad":"Amambay","provincia":"Amambay","pais":"Paraguay","latitud":-25.9312029,"longitud":-55.7809093,"ranking":0},
{"id":12585,"nombre":"San Luis","localidad":"San Luis","provincia":"Guairá","pais":"Paraguay","latitud":-25.9523267,"longitud":-56.108805,"ranking":0},
{"id":12586,"nombre":"Mbocayaty","localidad":"Mbocayaty","provincia":"Guairá","pais":"Paraguay","latitud":-25.714348,"longitud":-56.4076526,"ranking":0},
{"id":12602,"nombre":"Chacore","localidad":"Chacoré","provincia":"Guairá","pais":"Paraguay","latitud":-25.6381193183949,"longitud":-56.053151226480345,"ranking":0},
{"id":12603,"nombre":"Campiña Verde","localidad":"Campiña Verde","provincia":"Alto Parana","pais":"Paraguay","latitud":-25.732072563656175,"longitud":-55.043253975618164,"ranking":0},
{"id":12604,"nombre":"Desvio Hector L Vera","localidad":"Hector L Vera","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14220,"nombre":"Ara Vera","localidad":"Ara Verá","provincia":"Canindeyú","pais":"Paraguay","latitud":-23.92487009933869,"longitud":-55.96752700895752,"ranking":0},
{"id":14221,"nombre":"Pindoty Pora","localidad":"Pindoty Porá","provincia":"Canindeyú","pais":"Paraguay","latitud":-23.982033425030824,"longitud":-55.04185350340753,"ranking":0},
{"id":14223,"nombre":"Villa YgatimI","localidad":"Villa Ygatimí","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.120135,"longitud":-55.64118080000001,"ranking":0},
{"id":14222,"nombre":"Britez Cue","localidad":"Britez Cué","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.3018713,"longitud":-55.2934486,"ranking":0},
{"id":14224,"nombre":"Y'Hovy","localidad":"Y'Hovy","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.3039488,"longitud":-54.9976004,"ranking":0},
{"id":14225,"nombre":"Curuguaty","localidad":"Curuguaty","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.4710056,"longitud":-55.69142549999999,"ranking":37},
{"id":14226,"nombre":"Km 21","localidad":"Curuguaty","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.4715118287568,"longitud":-55.69140004700922,"ranking":3},
{"id":14227,"nombre":"Km 35","localidad":"Curuguaty","provincia":"Canindeyú","pais":"Paraguay","latitud":null,"longitud":null,"ranking":8},
{"id":14228,"nombre":"Cruce Curuguaty","localidad":"Curuguaty","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.471511,"longitud":-55.691398,"ranking":0},
{"id":14229,"nombre":"Cruce Ybyrarobana","localidad":"Ybyrarobana","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.2598933,"longitud":-55.138397,"ranking":8},
{"id":14236,"nombre":"Ara Pyahu","localidad":"Ara Pyahu","provincia":"San Pedro","pais":"Paraguay","latitud":-24.5902026,"longitud":-56.01065000000001,"ranking":0},
{"id":14237,"nombre":"Limoy","localidad":"Colonia Limoy","provincia":"Alto Parana","pais":"Paraguay","latitud":-24.8054068,"longitud":-54.8904834,"ranking":0},
{"id":14239,"nombre":"Sidepar","localidad":"Yasy Kañy","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.71733198376716,"longitud":-55.78713149296879,"ranking":0},
{"id":14240,"nombre":"Acepar","localidad":"Yasy Kañy","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.691563067782354,"longitud":-55.80711517149539,"ranking":0},
{"id":14241,"nombre":"Desvio Acepar","localidad":"Yasy Kañy","provincia":"Canindeyú","pais":"Paraguay","latitud":-24.533979,"longitud":-55.89236529999999,"ranking":0},
{"id":14246,"nombre":"Jejui Poty","localidad":"Jejui Poty","provincia":"San Pedro","pais":"Paraguay","latitud":-24.1568327,"longitud":-56.37036209999999,"ranking":0},
{"id":14247,"nombre":"Ñatiury Guazu","localidad":"Ñatiury Guazú","provincia":"Caaguazú","pais":"Paraguay","latitud":-24.929411494777465,"longitud":-56.31940665170519,"ranking":0},
{"id":14252,"nombre":"Campo Aceval","localidad":"Campo Aceval","provincia":"Presidente Hayes","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14253,"nombre":"Cerro Cora","localidad":"Cerro Corá","provincia":"Amambay","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14255,"nombre":"Colonia Yoaijhu","localidad":"Colonia Yoaijhu","provincia":"San Pedro","pais":"Paraguay","latitud":-23.887551841401187,"longitud":-55.43939250296818,"ranking":0},
{"id":14256,"nombre":"Colonia Naranjito","localidad":"Colonia Naranjito","provincia":"San Pedro","pais":"Paraguay","latitud":-24.0039555,"longitud":-56.2950561,"ranking":0},
{"id":8690,"nombre":"Tacuati","localidad":"Tacuatí","provincia":"San Pedro","pais":"Paraguay","latitud":-23.454948,"longitud":-56.737241,"ranking":0},
{"id":14257,"nombre":"Santa Clara","localidad":"Santa Clara","provincia":"San Pedro","pais":"Paraguay","latitud":-24.2453425,"longitud":-56.4268533,"ranking":0},
{"id":14258,"nombre":"Jhugua Rey","localidad":"Jhugua Rey","provincia":"San Pedro","pais":"Paraguay","latitud":-24.366667,"longitud":-56.71666699999999,"ranking":0},
{"id":14259,"nombre":"Vaca Jhu","localidad":"Colonia Vaca Jhu","provincia":"San Pedro","pais":"Paraguay","latitud":-24.5899722,"longitud":-56.61676379999999,"ranking":0},
{"id":14260,"nombre":"Bertoni","localidad":"Colonia Moises Bertoni","provincia":"San Pedro","pais":"Paraguay","latitud":-26.36649352094388,"longitud":-56.43346174602521,"ranking":0},
{"id":12194,"nombre":"Tacuara","localidad":"Tacuara","provincia":"San Pedro","pais":"Paraguay","latitud":-23.576335228243597,"longitud":-57.14281816972652,"ranking":1},
{"id":14261,"nombre":"Cruce Bella Vista","localidad":"Cruce Bella Vista","provincia":"Concepción","pais":"Paraguay","latitud":-22.7206316,"longitud":-56.2766293,"ranking":0},
{"id":14268,"nombre":"Arroyo Burgos","localidad":"Arroyo Burgos","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.4947568,"longitud":-59.6094291,"ranking":0},
{"id":14269,"nombre":"Aldea Brasilera","localidad":"Aldea Brasilera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.8889556,"longitud":-60.5824987,"ranking":0},
{"id":14270,"nombre":"Aldea Brasilera Acceso","localidad":"Aldea Brasilera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.8907203,"longitud":-60.5821033,"ranking":0},
{"id":14271,"nombre":"Antonio Tomas","localidad":"Antonio Tomas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.42467569999999,"longitud":-60.04572640000001,"ranking":0},
{"id":14273,"nombre":"Colonia Rivadavia","localidad":"Colonia Rivadavia","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.3248062,"longitud":-61.0485954,"ranking":0},
{"id":14272,"nombre":"Colonia Crespo","localidad":"Colonia Crespo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.6835183,"longitud":-60.2323324,"ranking":0},
{"id":14274,"nombre":"Colonia Nueva","localidad":"Colonia Nueva","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.9046585,"longitud":-58.00004,"ranking":0},
{"id":14275,"nombre":"Sir Leonard","localidad":"Sir Leonard","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.3892767,"longitud":-59.50249700000001,"ranking":0},
{"id":14276,"nombre":"Spatzenkuter","localidad":"Spatzenkuter","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.9457092,"longitud":-60.585154,"ranking":0},
{"id":14277,"nombre":"Strobel","localidad":"Strobel","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.05407030000001,"longitud":-60.610845,"ranking":0},
{"id":1844,"nombre":"Rincon de Nogoya","localidad":"Rincon de Nogoya","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.7793403,"longitud":-59.91005699999999,"ranking":0},
{"id":1880,"nombre":"Rocamora Acceso","localidad":"Rocamora","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.343363465536314,"longitud":-58.97142505724334,"ranking":0},
{"id":1877,"nombre":"Herrera Acceso","localidad":"Herrera","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.4370371,"longitud":-58.645803,"ranking":2},
{"id":1860,"nombre":"Gobernador Sola Acceso","localidad":"Gobernador Sola","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3701501,"longitud":-59.36203560000001,"ranking":2},
{"id":1838,"nombre":"Betbeder","localidad":"Betbeder","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.372267,"longitud":-59.928603,"ranking":0},
{"id":7841,"nombre":"Betbeder Acceso","localidad":"Betbeder","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3562147,"longitud":-59.92041469999999,"ranking":0},
{"id":1795,"nombre":"Aranguren","localidad":"Aranguren","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.2449543,"longitud":-60.16089950000001,"ranking":0},
{"id":12377,"nombre":"Aranguren Acceso","localidad":"Aranguren","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.2459546,"longitud":-60.1623847,"ranking":0},
{"id":1840,"nombre":"Hernandez Acceso","localidad":"Hernandez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.32891738775125,"longitud":-60.014169216156006,"ranking":0},
{"id":12380,"nombre":"Hernandez","localidad":"Hernandez","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.3382272,"longitud":-60.0330944,"ranking":0},
{"id":1813,"nombre":"Aldea San Antonio","localidad":"Aldea San Antonio","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.6169246,"longitud":-58.70397089999999,"ranking":0},
{"id":1814,"nombre":"Aldea San Juan Acceso","localidad":"Aldea San Juan","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.6971975,"longitud":-58.76891260000001,"ranking":0},
{"id":1819,"nombre":"Escriña Acceso","localidad":"Escriña","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.5942524,"longitud":-58.90481519999999,"ranking":0},
{"id":1845,"nombre":"Camps","localidad":"Estación Camps","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.1110956,"longitud":-60.229601,"ranking":0},
{"id":12379,"nombre":"Maria Luisa Acceso","localidad":"Aldea María Luisa","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.4058599,"longitud":-64.7842811,"ranking":0},
{"id":2997,"nombre":"El Salto","localidad":"El Salto","provincia":"Mendoza","pais":"ARGENTINA","latitud":-32.9537879,"longitud":-69.2784954,"ranking":9},
{"id":4418,"nombre":"San Esteban","localidad":"San Esteban","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.925242,"longitud":-64.527188,"ranking":22},
{"id":14288,"nombre":"Las Ovejas","localidad":"Las Ovejas","provincia":"Neuquén","pais":"ARGENTINA","latitud":-36.9896495,"longitud":-70.7547328,"ranking":0},
{"id":14287,"nombre":"Andacollo","localidad":"Andacollo","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.1828965,"longitud":-70.6725415,"ranking":0},
{"id":14289,"nombre":"Ramon Castro","localidad":"Ramon Castro","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.8640973,"longitud":-69.744444,"ranking":0},
{"id":14290,"nombre":"Chorriaca","localidad":"Chorriaca","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.9378,"longitud":-70.1006,"ranking":0},
{"id":14299,"nombre":"Poco Grande","localidad":"Poço Grande","provincia":"Bahia","pais":"BRASIL","latitud":-11.254421,"longitud":-39.1075201,"ranking":0},
{"id":14298,"nombre":"Tapuio","localidad":"Tapuio","provincia":"Bahia","pais":"BRASIL","latitud":-11.226717904209963,"longitud":-39.17711494832567,"ranking":0},
{"id":14300,"nombre":"Varzea Da Pedra","localidad":"Varzea Da Pedra","provincia":"Bahia","pais":"BRASIL","latitud":-11.2129439,"longitud":-39.2398468,"ranking":0},
{"id":14302,"nombre":"Alto Do Rio Doce","localidad":"Alto Do Rio Doce","provincia":"Minas Gerais","pais":"BRASIL","latitud":-21.0238444,"longitud":-43.4163503,"ranking":0},
{"id":14301,"nombre":"Cipotanea","localidad":"Cipotanea","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.9047379,"longitud":-43.365492,"ranking":0},
{"id":14304,"nombre":"Pataiba","localidad":"Pataiba","provincia":"Bahia","pais":"BRASIL","latitud":-11.6965971,"longitud":-38.7423606,"ranking":0},
{"id":1667,"nombre":"Arraga","localidad":"Arraga","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.0507792,"longitud":-64.22456749999999,"ranking":0},
{"id":14306,"nombre":"Arraga Acceso","localidad":"Arraga","provincia":"Santiago del Estero","pais":"ARGENTINA","latitud":-28.0547307,"longitud":-64.2213254,"ranking":0},
{"id":14308,"nombre":"Pinare","localidad":"Pinare","provincia":"Bahia","pais":"BRASIL","latitud":-13.9388375,"longitud":-39.1145342,"ranking":0},
{"id":14307,"nombre":"Serra Grande","localidad":"Serra Grande","provincia":"Bahia","pais":"BRASIL","latitud":-14.464932,"longitud":-39.040534,"ranking":0},
{"id":14424,"nombre":"Malagueño","localidad":"Malagueño","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4661981,"longitud":-64.3586854,"ranking":74},
{"id":14478,"nombre":"Los Aromos","localidad":"Los Aromos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7358549,"longitud":-64.43757699999999,"ranking":9},
{"id":15547,"nombre":"Camino 60 Cuadras Km 16","localidad":"Santa Maria","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5823858,"longitud":-64.14750699999999,"ranking":0},
{"id":14427,"nombre":"Sierra de Oro","localidad":"Sierra de Oro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4611712,"longitud":-64.4177678,"ranking":42},
{"id":14428,"nombre":"Falda del Carmen","localidad":"Falda del Carmen","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.58616608029939,"longitud":-64.45854232195603,"ranking":24},
{"id":14429,"nombre":"Falda Cañete","localidad":"Falda Cañete","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5253723,"longitud":-64.4567288,"ranking":1},
{"id":14558,"nombre":"Falda Cañete Acceso","localidad":"Falda Cañete","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5376686,"longitud":-64.4527248,"ranking":0},
{"id":14430,"nombre":"El Brete","localidad":"El Brete","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.6731965,"longitud":-64.8766037,"ranking":0},
{"id":14431,"nombre":"Media Naranja","localidad":"Media Naranja","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.64252219999999,"longitud":-64.9296215,"ranking":0},
{"id":14435,"nombre":"El Durazno","localidad":"El Durazno","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.1707789,"longitud":-64.7700393,"ranking":0},
{"id":14436,"nombre":"Sagrada Familia","localidad":"Sagrada Familia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.2891854,"longitud":-63.44607509999999,"ranking":0},
{"id":15548,"nombre":"Tala Canada","localidad":"Los Gigantes","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14437,"nombre":"Los Gigantes","localidad":"Los Gigantes","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3795313,"longitud":-64.7703793,"ranking":7},
{"id":14438,"nombre":"Río Yuspe","localidad":"Rio Yuspe","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.20885577302834,"longitud":-64.48575496673584,"ranking":0},
{"id":14441,"nombre":"San Geronimo","localidad":"San Geronimo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3464986,"longitud":-64.946041,"ranking":0},
{"id":14444,"nombre":"Villa San Nicolas","localidad":"Villa San Nicolas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.4358413,"longitud":-64.4498124,"ranking":104},
{"id":14445,"nombre":"Panaholma","localidad":"Panaholma","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.632911,"longitud":-65.0552679,"ranking":0},
{"id":14446,"nombre":"Puesto del Gallo","localidad":"Puesto del Gallo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.60190759999999,"longitud":-64.97686019999999,"ranking":0},
{"id":2942,"nombre":"La Serranita","localidad":"La Serranita","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7356776,"longitud":-64.4557202,"ranking":56},
{"id":14459,"nombre":"Piedra de Agua","localidad":"Piedra de Agua","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":10766,"nombre":"La Posta","localidad":"La Posta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.5645459,"longitud":-63.5161625,"ranking":3},
{"id":14595,"nombre":"La Posta Acceso","localidad":"La Posta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.570864,"longitud":-63.51591819999999,"ranking":0},
{"id":14474,"nombre":"La Balsa","localidad":"La Balsa","provincia":"Rio Negro","pais":"ARGENTINA","latitud":-39.1565056,"longitud":-66.7884456,"ranking":4},
{"id":9928,"nombre":"Sebastian Elcano","localidad":"Sebastian Elcano","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.160854,"longitud":-63.5943,"ranking":5},
{"id":9415,"nombre":"Sarmiento Acceso","localidad":"Sarmiento","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7772953,"longitud":-64.0829281,"ranking":0},
{"id":9433,"nombre":"Sarmiento","localidad":"Sarmiento","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7734774,"longitud":-64.1072652,"ranking":4},
{"id":9927,"nombre":"Alpa Corral","localidad":"Alpa Corral","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.692079,"longitud":-64.721159,"ranking":14},
{"id":9847,"nombre":"Canteras El Sauce","localidad":"Canteras El Sauce","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.4975729,"longitud":-64.5002547,"ranking":0},
{"id":9418,"nombre":"Santa Elena","localidad":"Santa Elena","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.1182849,"longitud":-63.843039,"ranking":3},
{"id":9845,"nombre":"Villa Cerro Azul","localidad":"Cerro Azul","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0847007,"longitud":-64.3109027,"ranking":0},
{"id":7906,"nombre":"Saturnino Laspiur","localidad":"Saturnino Laspiur","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.7022027,"longitud":-62.4796199,"ranking":6},
{"id":9428,"nombre":"Charbonier","localidad":"Charbonier","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.7776913,"longitud":-64.543624,"ranking":0},
{"id":9419,"nombre":"Churqui Cañada","localidad":"Churqui Cañada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.173246,"longitud":-63.9275646,"ranking":0},
{"id":9776,"nombre":"Cienaga de Coro","localidad":"Cienaga de Coro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0451212,"longitud":-65.2928639,"ranking":0},
{"id":14476,"nombre":"Quebracho Ladeado","localidad":"Quebracho Ladeado","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2577985,"longitud":-65.031286,"ranking":0},
{"id":7646,"nombre":"San Basilio","localidad":"San Basilio","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.4989551,"longitud":-64.3149139,"ranking":13},
{"id":9767,"nombre":"Rumi Huasi","localidad":"Rumi Huasi","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0361359,"longitud":-65.2194444,"ranking":0},
{"id":7903,"nombre":"Quebracho Herrado","localidad":"Quebracho Herrado","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.5491957,"longitud":-62.224544,"ranking":4},
{"id":8265,"nombre":"Pampayasta","localidad":"Pampayasta","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2488633,"longitud":-63.64993679999999,"ranking":0},
{"id":8724,"nombre":"Ciudad Parque","localidad":"Ciudad Parque","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.90693929999999,"longitud":-64.5543113,"ranking":0},
{"id":7581,"nombre":"Colonia Prosperidad ","localidad":"Colonia Prosperidad","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6312317,"longitud":-62.362696,"ranking":6},
{"id":4406,"nombre":"Devoto","localidad":"Devoto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.407781,"longitud":-62.306598,"ranking":18},
{"id":4411,"nombre":"La Francia","localidad":"La Francia","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.405418,"longitud":-62.628574,"ranking":1},
{"id":2154,"nombre":"Costa Sacate","localidad":"Costa Sacate","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6463198,"longitud":-63.75613540000001,"ranking":0},
{"id":9417,"nombre":"El Rodeo","localidad":"El Rodeo","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.1719499,"longitud":-63.86276289999999,"ranking":0},
{"id":8979,"nombre":"Las Albahacas","localidad":"Las Albahacas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.89652,"longitud":-64.840603,"ranking":1},
{"id":9801,"nombre":"Las Oscuras","localidad":"Las Oscuras","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6373243,"longitud":-65.31969649999999,"ranking":0},
{"id":9925,"nombre":"San Bartolomé","localidad":"San Bartolome","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.474058,"longitud":-64.202684,"ranking":0},
{"id":2248,"nombre":"Nono","localidad":"Nono","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.797054,"longitud":-65.005767,"ranking":66},
{"id":2251,"nombre":"Las Tapias Acceso","localidad":"Las Tapias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9535178,"longitud":-65.143737,"ranking":0},
{"id":9933,"nombre":"Las Tapias","localidad":"Las Tapias","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.959961771553594,"longitud":-65.09403396428392,"ranking":25},
{"id":9802,"nombre":"Los Cerrillos","localidad":"Los Cerrillos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.9730459,"longitud":-65.44266689999999,"ranking":0},
{"id":9443,"nombre":"Villa Quilino","localidad":"Villa Quilino","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.211543,"longitud":-64.492582,"ranking":0},
{"id":9416,"nombre":"San Pedro Acceso","localidad":"San Pedro","provincia":"Córdoba","pais":"ARGENTINA","latitud":-33.6901985,"longitud":-59.6768585,"ranking":0},
{"id":7682,"nombre":"Villa Concepcion del Tio","localidad":"Villa Concepcion del Tio","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.3221629,"longitud":-62.8140508,"ranking":0},
{"id":14493,"nombre":"Napenay Acceso","localidad":"Napenay","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.7382147,"longitud":-60.6254616,"ranking":0},
{"id":9772,"nombre":"Tosno","localidad":"Tosno","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0629404,"longitud":-65.3807101,"ranking":0},
{"id":7905,"nombre":"Las Varas","localidad":"Las Varas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.80215889999999,"longitud":-62.61673819999999,"ranking":0},
{"id":14499,"nombre":"Puerto Diana","localidad":"Puerto Diana","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-20.2553222,"longitud":-58.1664451,"ranking":0},
{"id":14497,"nombre":"Centinela","localidad":"Centinela","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-21.9392999,"longitud":-58.9522424,"ranking":0},
{"id":14498,"nombre":"Toro Pampa","localidad":"Toro Pampa","provincia":"Alto Paraguay","pais":"Paraguay","latitud":-21.0245849,"longitud":-58.48451610000001,"ranking":0},
{"id":9204,"nombre":"De la Serna","localidad":"De la Serna","provincia":"Córdoba","pais":"ARGENTINA","latitud":-34.3779261,"longitud":-64.69195719999999,"ranking":0},
{"id":9838,"nombre":"Cruz de Caña","localidad":"Cruz de Caña","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.0666695,"longitud":-64.9499969,"ranking":0},
{"id":9435,"nombre":"Avellaneda","localidad":"Avellaneda","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.5940947,"longitud":-64.205201,"ranking":1},
{"id":10744,"nombre":"Las Masitas","localidad":"Las Masitas","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.2703814,"longitud":-63.55333289999999,"ranking":0},
{"id":14505,"nombre":"Codihue","localidad":"Codihue","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.46298,"longitud":-70.49746619999999,"ranking":0},
{"id":14506,"nombre":"Hualcupen","localidad":"Hualcupen","provincia":"Neuquén","pais":"ARGENTINA","latitud":-37.97042,"longitud":-70.6449696,"ranking":0},
{"id":14507,"nombre":"Huarenchenque","localidad":"Huarenchenque","provincia":"Neuquén","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9421,"nombre":"Villa Bustos","localidad":"Villa Bustos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.267689767253877,"longitud":-64.48786868558435,"ranking":1},
{"id":9848,"nombre":"Villa Los Altos","localidad":"Villa Los Altos","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1392042,"longitud":-64.3129628,"ranking":0},
{"id":9780,"nombre":"Cañada de Corral","localidad":"Cañada de Corral","provincia":"Córdoba","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9719,"nombre":"San Pedro de Gutemberg","localidad":"San Pedro de Gutemberg","provincia":"Córdoba","pais":"ARGENTINA","latitud":-29.69726,"longitud":-63.55942,"ranking":0},
{"id":7892,"nombre":"Punta del Agua","localidad":"Punta del Agua","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.5756428,"longitud":-63.81076969999999,"ranking":0},
{"id":7877,"nombre":"La Colorada","localidad":"La Quebrada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.148874289414263,"longitud":-64.33085768180051,"ranking":0},
{"id":2022,"nombre":"Nikizanga","localidad":"Nikizanga","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.5035172,"longitud":-67.6662231,"ranking":0},
{"id":1694,"nombre":"Macapillo","localidad":"Macapillo","provincia":"Salta","pais":"ARGENTINA","latitud":-25.385915,"longitud":-64.0028639,"ranking":0},
{"id":1811,"nombre":"Aldea Asuncion Acceso","localidad":"Aldea Asuncion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.8261039,"longitud":-59.2346172,"ranking":0},
{"id":3297,"nombre":"Aldea Asuncion","localidad":"Aldea Asuncion","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-32.8238582,"longitud":-59.23818779999999,"ranking":0},
{"id":10673,"nombre":"Candonga","localidad":"Candonga","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.1140348,"longitud":-64.36592759999999,"ranking":0},
{"id":14543,"nombre":"Berabevu","localidad":"Berabevu","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.340919,"longitud":-61.862404,"ranking":0},
{"id":14542,"nombre":"Juncal","localidad":"Juncal","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.7160246,"longitud":-61.0447739,"ranking":0},
{"id":2065,"nombre":"Josefina","localidad":"Josefina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.4062507,"longitud":-61.99198999999999,"ranking":0},
{"id":9045,"nombre":"Josefina Acceso","localidad":"Josefina","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-31.43374,"longitud":-61.99914,"ranking":0},
{"id":14544,"nombre":"Juan Llenera","localidad":"Juan Llenera","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.2781374,"longitud":-65.6136652,"ranking":0},
{"id":14547,"nombre":"San Antonio de Obligado","localidad":"San Antonio de Obligado","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.3724466,"longitud":-59.2653098,"ranking":1},
{"id":14548,"nombre":"Villa Elena","localidad":"Villa Elena","provincia":"San Luis","pais":"ARGENTINA","latitud":-33.2799101,"longitud":-66.3119,"ranking":0},
{"id":14549,"nombre":"Guayquiraro","localidad":"Guayquiraro","provincia":"Corrientes","pais":"ARGENTINA","latitud":-30.3086756,"longitud":-59.54427199999999,"ranking":0},
{"id":786,"nombre":"Drabble Acceso","localidad":"Drabble","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9406495,"longitud":-62.7708799,"ranking":0},
{"id":14588,"nombre":"Drabble","localidad":"Drabble","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.9190747,"longitud":-62.7644476,"ranking":0},
{"id":14550,"nombre":"Villa Gobernador Gálvez","localidad":"Villa Gobernador Galvez","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-33.0255203,"longitud":-60.6337608,"ranking":0},
{"id":14555,"nombre":"San Antonio de los Cobres","localidad":"San Antonio de los Cobres","provincia":"Salta","pais":"ARGENTINA","latitud":-24.226206,"longitud":-66.3177445,"ranking":0},
{"id":14554,"nombre":"Santa Rosa de Tastil","localidad":"Santa Rosa de Tastil","provincia":"Salta","pais":"ARGENTINA","latitud":-24.4490119,"longitud":-65.9530636,"ranking":0},
{"id":14556,"nombre":"Arroyo Malabrigo","localidad":"Arroyo Malabrigo","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-29.44997889999999,"longitud":-59.7532389,"ranking":0},
{"id":4202,"nombre":"Alta Italia","localidad":"Alta Italia","provincia":"La Pampa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":7726,"nombre":"Alta Italia Acceso","localidad":"Alta Italia","provincia":"La Pampa","pais":"ARGENTINA","latitud":-35.32016,"longitud":-64.1179046,"ranking":0},
{"id":2124,"nombre":"Huanqueros Acceso","localidad":"Huanqueros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-30.010184,"longitud":-61.21465199999999,"ranking":0},
{"id":10620,"nombre":"Huanqueros","localidad":"Huanqueros","provincia":"Santa Fe","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14559,"nombre":"Los Gutierrez","localidad":"Los Gutierrez","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.7925635,"longitud":-65.1329111,"ranking":0},
{"id":14585,"nombre":"Manuel Jose Garcia","localidad":"Manuel Jose Garcia","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.7276718,"longitud":-59.5374899,"ranking":0},
{"id":14586,"nombre":"Gomez de La Vega","localidad":"Gomez de La Vega","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-35.0663013,"longitud":-58.169139,"ranking":0},
{"id":14590,"nombre":"Cebila Acceso","localidad":"Cebila","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.67705369999999,"longitud":-66.3961395,"ranking":0},
{"id":14589,"nombre":"Cebila","localidad":"Cebila","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.7137763,"longitud":-66.3780985,"ranking":0},
{"id":14591,"nombre":"Larraburre","localidad":"Larraburre","provincia":"Formosa","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14593,"nombre":"Montiel","localidad":"Montiel","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.490762,"longitud":-59.0951823,"ranking":0},
{"id":10747,"nombre":"Empalme Ongamira","localidad":"Ongamira","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.79205319999999,"longitud":-64.5377322,"ranking":0},
{"id":14605,"nombre":"Valle Apua","localidad":"Valle Apua","provincia":"Paraguarí - Paraguay","pais":"Paraguay","latitud":-26.0672454,"longitud":-57.2478756,"ranking":0},
{"id":14606,"nombre":"Curuñai","localidad":"Curuñai ","provincia":"Itapua","pais":"Paraguay","latitud":null,"longitud":null,"ranking":0},
{"id":14607,"nombre":"Salado","localidad":"Salado","provincia":"Chaco","pais":"ARGENTINA","latitud":-27.5282116,"longitud":-59.1421691,"ranking":0},
{"id":14609,"nombre":"Capiz Bajo","localidad":"Capiz Bajo","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3320,"nombre":"Agua Nueva","localidad":"Agua Nueva","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.9690875,"longitud":-69.1512122,"ranking":0},
{"id":3837,"nombre":"Agua Nueva Acceso","localidad":"Agua Nueva","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":3331,"nombre":"La Guevarina","localidad":"La Guevarina","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.825106,"longitud":-67.918769,"ranking":0},
{"id":9198,"nombre":"Los Berros Acceso","localidad":"Los Berros","provincia":"Mendoza","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":9111,"nombre":"Punta del Agua","localidad":"Punta del Agua","provincia":"Mendoza","pais":"ARGENTINA","latitud":-35.5279023,"longitud":-68.07647279999999,"ranking":0},
{"id":2026,"nombre":"Alfonso Acceso","localidad":"Alfonso","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-33.9309287,"longitud":-60.81008600000001,"ranking":0},
{"id":14613,"nombre":"La Yesera","localidad":"La Yesera","provincia":"Salta","pais":"ARGENTINA","latitud":-26.1303549,"longitud":-65.9036295,"ranking":0},
{"id":14615,"nombre":"Piloto Avila","localidad":"Piloto Avila","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.7410556,"longitud":-59.5613928,"ranking":0},
{"id":14616,"nombre":"El Cimarron","localidad":"El Cimarron","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.9732293,"longitud":-58.9964107,"ranking":0},
{"id":14618,"nombre":"Rio Cochuna","localidad":"Rio Cochuna","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.322048,"longitud":-65.8922011,"ranking":0},
{"id":14619,"nombre":"Las Lenguas","localidad":"Las Lenguas","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.1918472,"longitud":-65.5367223,"ranking":0},
{"id":14620,"nombre":"Rio Ibicuycito","localidad":"Rio Ibicuycito","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-33.8348792,"longitud":-58.888638,"ranking":0},
{"id":14621,"nombre":"San Borgita","localidad":"San Borgita","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.4926958,"longitud":-56.09326720000001,"ranking":0},
{"id":14622,"nombre":"Palo a Pique","localidad":"Palo a Pique","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.4256432,"longitud":-58.9071656,"ranking":0},
{"id":10051,"nombre":"General Isidro Resquin","localidad":"General Isidro Resquin","provincia":"San Pedro","pais":"Paraguay","latitud":-24.062457,"longitud":-56.451517,"ranking":8},
{"id":14623,"nombre":"El Carmen","localidad":"El Carmen","provincia":"Salta","pais":"ARGENTINA","latitud":-25.636626,"longitud":-66.17305569999999,"ranking":0},
{"id":14624,"nombre":"Las Abritas","localidad":"Las Abritas","provincia":"Salta","pais":"ARGENTINA","latitud":-25.6878502,"longitud":-65.6972924,"ranking":0},
{"id":14627,"nombre":"Estacas","localidad":"Estacas","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-30.5897191,"longitud":-59.2685284,"ranking":0},
{"id":14628,"nombre":"Tohue","localidad":"Tohue","provincia":"Formosa","pais":"ARGENTINA","latitud":-26.3643835,"longitud":-58.32991909999999,"ranking":0},
{"id":14630,"nombre":"Cañada de Rio Pinto","localidad":"Cañada de Rio Pinto","provincia":"Córdoba","pais":"ARGENTINA","latitud":-30.77331539999999,"longitud":-64.2171288,"ranking":1},
{"id":10030,"nombre":"Nova Tramandai","localidad":"Nova Tramandai","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-30.0208325,"longitud":-50.2038466,"ranking":0},
{"id":1613,"nombre":"Campo Nuevo","localidad":"Campo Nuevo","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.82193909347827,"longitud":-60.515755666546085,"ranking":0},
{"id":14636,"nombre":"El Zapallar","localidad":"El Zapallar","provincia":"Chaco","pais":"ARGENTINA","latitud":-26.5382588,"longitud":-59.3394785,"ranking":0},
{"id":14637,"nombre":"La Balsa","localidad":"La Balsa","provincia":"Entre Rios","pais":"ARGENTINA","latitud":-31.6803532,"longitud":-60.34965690000001,"ranking":0},
{"id":14638,"nombre":"Ingeniero Silveyra","localidad":"Ingeniero Silveyra","provincia":"Buenos Aires","pais":"ARGENTINA","latitud":-34.5246266,"longitud":-60.19891269999999,"ranking":0},
{"id":14639,"nombre":"Agua Dulce","localidad":"Agua Dulce","provincia":"Córdoba","pais":"ARGENTINA","latitud":-32.2714158,"longitud":-64.6214281,"ranking":0},
{"id":14640,"nombre":"Puesto Ramona","localidad":"Puesto Ramona","provincia":"Formosa","pais":"ARGENTINA","latitud":-24.9584301,"longitud":-58.91584280000001,"ranking":0},
{"id":14641,"nombre":"Yaguarete","localidad":"Yaguarete","provincia":"Santa Fe","pais":"ARGENTINA","latitud":-28.3083659,"longitud":-59.2645756,"ranking":0},
{"id":14642,"nombre":"La Cruz","localidad":"La Cruz","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.6337526,"longitud":-64.8811521,"ranking":0},
{"id":1968,"nombre":"Plottier","localidad":"Plottier","provincia":"Neuquén","pais":"ARGENTINA","latitud":-38.955776,"longitud":-68.217676,"ranking":233},
{"id":14647,"nombre":"Toledo","localidad":"Toledo","provincia":"Canelones","pais":"URUGUAY","latitud":-34.73687,"longitud":-56.09377,"ranking":0},
{"id":14649,"nombre":"Santa Rosa","localidad":"Toledo","provincia":"Canelones","pais":"URUGUAY","latitud":-34.4972377,"longitud":-56.04075940000001,"ranking":0},
{"id":14648,"nombre":"Sauce","localidad":"Sauce","provincia":"Canelones","pais":"URUGUAY","latitud":-34.6501454,"longitud":-56.0654736,"ranking":0},
{"id":14650,"nombre":"San Bautista","localidad":"San Bautista","provincia":"Canelones","pais":"URUGUAY","latitud":-34.4373813,"longitud":-55.9639468,"ranking":0},
{"id":14651,"nombre":"Castellanos","localidad":"Castellanos","provincia":"Canelones","pais":"URUGUAY","latitud":-34.3783949,"longitud":-55.9553284,"ranking":0},
{"id":14652,"nombre":"San Ramon","localidad":"San Ramon","provincia":"Canelones","pais":"URUGUAY","latitud":-34.2888035,"longitud":-55.9574864,"ranking":0},
{"id":14653,"nombre":"Tupambae","localidad":"Tupambaé","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.83727289999999,"longitud":-54.759625,"ranking":0},
{"id":14654,"nombre":"Cerro de las Cuentas","localidad":"Cerro de las Cuentas","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.6253966,"longitud":-54.5912872,"ranking":0},
{"id":14655,"nombre":"Fraile Muerto","localidad":"Fraile Muerto","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.5148476,"longitud":-54.5239061,"ranking":0},
{"id":14656,"nombre":"Ramon Trigo","localidad":"Ramón Trigo","provincia":"Cerro Largo","pais":"URUGUAY","latitud":-32.3490032,"longitud":-54.63747799999999,"ranking":0},
{"id":14657,"nombre":"Colonia Suiza","localidad":"Colonia Suiza Nueva Helvecia","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.3133367,"longitud":-57.2339856,"ranking":0},
{"id":14658,"nombre":"Riachuelo","localidad":"Riachuelo","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.4225499,"longitud":-57.73246160000001,"ranking":0},
{"id":14659,"nombre":"Boca del Rosario","localidad":"Boca del Rosario","provincia":"Departamento de Colonia","pais":"URUGUAY","latitud":-34.4351067,"longitud":-57.33413820000001,"ranking":0},
{"id":14660,"nombre":"Parque Miramar","localidad":"Ciudad de la Costa","provincia":"Canelones","pais":"URUGUAY","latitud":-34.8653468,"longitud":-56.0308019,"ranking":0},
{"id":14661,"nombre":"Sarandi Grande","localidad":"Sarandi Grande","provincia":"Florida","pais":"URUGUAY","latitud":-33.7247147,"longitud":-56.32850879999999,"ranking":0},
{"id":14662,"nombre":"Cerro Chato","localidad":"Cerro Chato","provincia":"Florida","pais":"URUGUAY","latitud":-33.1013157,"longitud":-55.1325831,"ranking":0},
{"id":14663,"nombre":"Chamizo","localidad":"Chamizo","provincia":"Florida","pais":"URUGUAY","latitud":-34.2441606,"longitud":-55.9171822,"ranking":0},
{"id":14665,"nombre":"San Gabriel","localidad":"San Gabriel","provincia":"Florida","pais":"URUGUAY","latitud":-34.03687,"longitud":-55.8859667,"ranking":0},
{"id":14664,"nombre":"Capilla del Sauce","localidad":"Estación Capilla del Sauce","provincia":"Florida","pais":"URUGUAY","latitud":-33.467103,"longitud":-55.6335486,"ranking":0},
{"id":14667,"nombre":"Sarandi del Yi","localidad":"Sarandí del Yi","provincia":"Durazno","pais":"URUGUAY","latitud":-33.3339374,"longitud":-55.6240636,"ranking":0},
{"id":14666,"nombre":"Blanquillo","localidad":"Blanquillo","provincia":"Durazno","pais":"URUGUAY","latitud":-32.888317178617356,"longitud":-55.617005005679786,"ranking":0},
{"id":14668,"nombre":"Carmen","localidad":"Villa del Carmen","provincia":"Durazno","pais":"URUGUAY","latitud":-33.2371214,"longitud":-56.0081163,"ranking":0},
{"id":14669,"nombre":"Carlos Reyles","localidad":"Carlos Reyles ","provincia":"Durazno","pais":"URUGUAY","latitud":-33.0569127,"longitud":-56.47718510000001,"ranking":0},
{"id":14674,"nombre":"Portezuelo","localidad":"Portezuelo","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8659286,"longitud":-55.141006,"ranking":0},
{"id":14678,"nombre":"Aigua","localidad":"Aiguá","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.2041773,"longitud":-54.7611254,"ranking":0},
{"id":14679,"nombre":"Cebollati","localidad":"Cebollati","provincia":"Rocha","pais":"URUGUAY","latitud":-33.2713579,"longitud":-53.7948353,"ranking":0},
{"id":14681,"nombre":"Florida","localidad":"Florida","provincia":"Florida","pais":"URUGUAY","latitud":-34.1006258,"longitud":-56.21639769999999,"ranking":0},
{"id":14682,"nombre":"Joanico","localidad":"Joanico","provincia":"Canelones","pais":"URUGUAY","latitud":-34.87616390000001,"longitud":-56.1447133,"ranking":0},
{"id":14683,"nombre":"La Capuera","localidad":"La Capuera","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8645311,"longitud":-55.13808419999999,"ranking":0},
{"id":14684,"nombre":"Lascano","localidad":"Lascano","provincia":"Rocha","pais":"URUGUAY","latitud":-33.6792673,"longitud":-54.2112501,"ranking":0},
{"id":14685,"nombre":"Santa Clara de Olimar","localidad":"Santa Clara de Olimar","provincia":"Treinta y Tres","pais":"URUGUAY","latitud":-32.9214595,"longitud":-54.9412469,"ranking":0},
{"id":14686,"nombre":"Caraguata","localidad":"Caraguatá","provincia":"Tacuarembó","pais":"URUGUAY","latitud":-32.2403766,"longitud":-54.9796112,"ranking":0},
{"id":14687,"nombre":"Rafael Perazza","localidad":"Rafael Perazza","provincia":"San José","pais":"URUGUAY","latitud":-34.5269955,"longitud":-56.7979289,"ranking":0},
{"id":2720,"nombre":"La Radial","localidad":"Radial","provincia":"San José","pais":"URUGUAY","latitud":-34.5513676,"longitud":-56.7597085,"ranking":0},
{"id":14688,"nombre":"Vichadero","localidad":"Vichadero","provincia":"Rivera","pais":"URUGUAY","latitud":-31.7792066,"longitud":-54.6915524,"ranking":0},
{"id":14689,"nombre":"Tranqueras","localidad":"Tranqueras","provincia":"Rivera","pais":"URUGUAY","latitud":-31.1902799,"longitud":-55.7694836,"ranking":0},
{"id":14690,"nombre":"Ocean Park","localidad":"Ocean Park","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8691661,"longitud":-55.1147834,"ranking":0},
{"id":14691,"nombre":"Pueblo Eden","localidad":"Pueblo Edén","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.6285796,"longitud":-55.05556259999999,"ranking":0},
{"id":14692,"nombre":"Villa Militar","localidad":"Villa Militar","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8663298,"longitud":-55.08331519999999,"ranking":0},
{"id":14693,"nombre":"Punta Negra","localidad":"Punta Negra","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8919989,"longitud":-55.226508,"ranking":0},
{"id":14694,"nombre":"Velazquez","localidad":"Velazquez","provincia":"Rocha","pais":"URUGUAY","latitud":-34.0346829,"longitud":-54.2802126,"ranking":0},
{"id":14696,"nombre":"Punta Colorada","localidad":"Punta Colorada","provincia":"Maldonado","pais":"URUGUAY","latitud":-34.8944382,"longitud":-55.2503611,"ranking":0},
{"id":3747,"nombre":"Miraflores","localidad":"Miraflores","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14700,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.139909008993474,"longitud":-66.20280233575744,"ranking":0},
{"id":14701,"nombre":"Pipanaco","localidad":"Pipanaco","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14702,"nombre":"Mollecito","localidad":"Mollecito","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.78803234569673,"longitud":-66.19480327822247,"ranking":0},
{"id":4448,"nombre":"Cerro Negro","localidad":"Cerro Negro","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.259591667377645,"longitud":-67.13715675449806,"ranking":38},
{"id":4401,"nombre":"Aimogasta","localidad":"Aimogasta","provincia":"La Rioja","pais":"ARGENTINA","latitud":-28.5593524,"longitud":-66.8045586,"ranking":112},
{"id":14703,"nombre":"Valle Viejo","localidad":"Valle Viejo","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14815,"nombre":"Aeropuerto de Catamarca","localidad":"Valle Viejo","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.5918843,"longitud":-65.75790049999999,"ranking":0},
{"id":14863,"nombre":"Tres Puentes","localidad":"Valle Viejo","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.448396089975617,"longitud":-65.72608589824559,"ranking":0},
{"id":14704,"nombre":"La Bajada","localidad":"La Bajada","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3693235,"longitud":-65.6202318,"ranking":0},
{"id":14705,"nombre":"Monte Potrero","localidad":"Monte Potrero","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.1821253,"longitud":-65.6625937,"ranking":0},
{"id":14706,"nombre":"El Abra","localidad":"El Abra","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.078916,"longitud":-65.4333465,"ranking":0},
{"id":1535,"nombre":"Tapso","localidad":"Tapso","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.4043247,"longitud":-65.1037819,"ranking":53},
{"id":14707,"nombre":"Icaño","localidad":"Icaño","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.9194646,"longitud":-65.3302203,"ranking":58},
{"id":14708,"nombre":"La Rocha","localidad":"La Rocha","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.9294382,"longitud":-65.7562825,"ranking":0},
{"id":14709,"nombre":"El Cerrito","localidad":"El Cerrito","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.3999266,"longitud":-65.2857082,"ranking":0},
{"id":14768,"nombre":"La Horqueta","localidad":"La Horqueta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.5125245,"longitud":-65.37095300000001,"ranking":0},
{"id":14769,"nombre":"Las Peñas","localidad":"Las Peñas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.475391,"longitud":-65.5120213,"ranking":0},
{"id":14710,"nombre":"Mutquin","localidad":"Mutquin","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3136493,"longitud":-66.1513203,"ranking":0},
{"id":14714,"nombre":"Alto da Serra","localidad":"Alto da Serra","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14715,"nombre":"Bacaxa","localidad":"Bacaxa","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.8937017,"longitud":-42.4677689,"ranking":0},
{"id":14716,"nombre":"Barra do Itabapoana","localidad":"Barra do Itabapoana","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.1422986,"longitud":-41.7399116,"ranking":0},
{"id":14717,"nombre":"Barraca","localidad":"Barraca","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14721,"nombre":"Boca do Mato","localidad":"Boca do Mato","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.4158949,"longitud":-42.6199096,"ranking":0},
{"id":14723,"nombre":"Enseada","localidad":"Enseada","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.2271064,"longitud":-48.5079848,"ranking":1},
{"id":14728,"nombre":"Marco Divisorio","localidad":"Marco Divisorio","provincia":"Sao Paulo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14733,"nombre":"Ibipera","localidad":"Ibipera","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14731,"nombre":"Sao Sebastiao do Tocantins","localidad":"Sao Sebastiao do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14734,"nombre":"Itapocu","localidad":"Itapocu","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14735,"nombre":"Sao Salvador Do Tocantins","localidad":"Sao Salvador Do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14737,"nombre":"Manilha","localidad":"Manilha","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.7711503,"longitud":-42.9218147,"ranking":0},
{"id":14739,"nombre":"Monnerat","localidad":"Monnerat","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.0763498,"longitud":-42.4053004,"ranking":0},
{"id":14736,"nombre":"Sao Bento Do Tocantins","localidad":"Sao Bento Do Tocantins","provincia":"Tocantins","pais":"BRASIL","latitud":-6.029371065910482,"longitud":-47.920764500322456,"ranking":0},
{"id":14741,"nombre":"Nereu Ramos","localidad":"Nereu Ramos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.45929906436114,"longitud":-49.175041369883765,"ranking":0},
{"id":14746,"nombre":"Ponte Queimada","localidad":"Ponte Queimada","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14747,"nombre":"Residencia Fuck","localidad":"Residencia Fuck","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.6299009,"longitud":-50.2460542,"ranking":0},
{"id":14748,"nombre":"Rio Dourado","localidad":"Rio Dourado","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14749,"nombre":"Sampaio Correa","localidad":"Sampaio Correa","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.8625487,"longitud":-42.6006802,"ranking":0},
{"id":14751,"nombre":"Tijuquinhas","localidad":"Tijuquinhas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.4236764,"longitud":-48.62413220000001,"ranking":0},
{"id":14752,"nombre":"Valao do Barro","localidad":"Valao do Barro","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14753,"nombre":"Verao Vermelho","localidad":"Verao Vermelho","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.66394862357262,"longitud":-42.00577025748355,"ranking":0},
{"id":14758,"nombre":"Visconde do Imbe","localidad":"Visconde do Imbe","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.0675913,"longitud":-42.1596511,"ranking":0},
{"id":14755,"nombre":"La Bella","localidad":"La Bella","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14757,"nombre":"Monte Redondo","localidad":"Monte Redondo","provincia":"Catamarca","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":14762,"nombre":"Boqueirao do Cesario","localidad":"Boqueirao do Cesario","provincia":"Ceará","pais":"BRASIL","latitud":-4.591473,"longitud":-38.21247899999999,"ranking":0},
{"id":14764,"nombre":"Catavento","localidad":"Catavento","provincia":"Ceará","pais":"BRASIL","latitud":-6.461087099999999,"longitud":-38.8557018,"ranking":0},
{"id":14763,"nombre":"Cascudo","localidad":"Cascudo","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14765,"nombre":"Jatoba","localidad":"Jatoba","provincia":"Ceará","pais":"BRASIL","latitud":-3.8054143,"longitud":-38.6389805,"ranking":0},
{"id":14766,"nombre":"Mata Grande","localidad":"Mata Grande","provincia":"Paraíba","pais":"BRASIL","latitud":-7.40189,"longitud":-38.479481,"ranking":0},
{"id":14770,"nombre":"El Cercado","localidad":"El Cercado","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.3131568,"longitud":-65.4883763,"ranking":0},
{"id":14771,"nombre":"Rio de la Dorada","localidad":"Rio de la Dorada","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.28255309999999,"longitud":-65.4814658,"ranking":0},
{"id":14772,"nombre":"El Aybal","localidad":"El Aybal","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.1097046,"longitud":-65.36288449999999,"ranking":0},
{"id":14773,"nombre":"La Quinta","localidad":"La Quinta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.0451007,"longitud":-65.3521461,"ranking":0},
{"id":14774,"nombre":"El Jumeal","localidad":"El Jumeal","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.4228108,"longitud":-65.4967204,"ranking":0},
{"id":14775,"nombre":"Pie del Medano","localidad":"Pie del Medano","provincia":"Catamarca","pais":"ARGENTINA","latitud":-26.998504,"longitud":-66.25432479999999,"ranking":0},
{"id":14776,"nombre":"Manantiales","localidad":"Manantiales","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.1430217,"longitud":-65.4972626,"ranking":0},
{"id":14777,"nombre":"El Alto","localidad":"El Alto","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3108851,"longitud":-65.364383,"ranking":0},
{"id":14778,"nombre":"Guayamba","localidad":"Guayamba","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3431818,"longitud":-65.3997469,"ranking":0},
{"id":14779,"nombre":"Tintigasta","localidad":"Tintigasta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3753068,"longitud":-65.4407294,"ranking":0},
{"id":14780,"nombre":"Los Morteros","localidad":"Los Morteros","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.4469071,"longitud":-65.1779167,"ranking":0},
{"id":14785,"nombre":"Anquincila","localidad":"Anquincila","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.74630819999999,"longitud":-65.5466718,"ranking":0},
{"id":14786,"nombre":"Ancasti ","localidad":"Ancasti","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.81241769999999,"longitud":-65.5009793,"ranking":0},
{"id":14787,"nombre":"Los Morteros","localidad":"Ancasti","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.5903997,"longitud":-65.6251599,"ranking":0},
{"id":14784,"nombre":"El Taco","localidad":"El Taco","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.69626569999999,"longitud":-65.5918926,"ranking":0},
{"id":14781,"nombre":"Arcozelo","localidad":"Arcozelo","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14782,"nombre":"Conservatoria","localidad":"Conservatoria ","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14783,"nombre":"Engenheiro Passos","localidad":"Engenheiro Passos","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.50043691706777,"longitud":-44.67450061540428,"ranking":0},
{"id":14800,"nombre":"Canelones","localidad":"Canelones","provincia":"Canelones","pais":"URUGUAY","latitud":-34.52419714903492,"longitud":-56.2817356700363,"ranking":0},
{"id":14804,"nombre":"Las Tejas","localidad":"Las Tejas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.658714,"longitud":-65.7834258,"ranking":0},
{"id":14806,"nombre":"Singuil","localidad":"Singuil","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.7987329,"longitud":-65.865445,"ranking":0},
{"id":14805,"nombre":"Colana","localidad":"Colana","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.3626327,"longitud":-66.1601196,"ranking":0},
{"id":14807,"nombre":"Las Chacritas","localidad":"Las Chacritas","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.6887257,"longitud":-65.9236671,"ranking":0},
{"id":14808,"nombre":"Isla Larga","localidad":"Isla Larga","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.2321616,"longitud":-65.7588456,"ranking":0},
{"id":14809,"nombre":"Dos Pocitos","localidad":"Dos Pocitos","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.0726245,"longitud":-65.2689965,"ranking":0},
{"id":14814,"nombre":"Rio de las Pavas Acceso","localidad":"Rio de las Pavas","provincia":"Jujuy","pais":"ARGENTINA","latitud":-24.4522417,"longitud":-65.2083617,"ranking":0},
{"id":14816,"nombre":"La Parada","localidad":"La Parada","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.9243341,"longitud":-65.2969226,"ranking":0},
{"id":14818,"nombre":"Los Varela","localidad":"Los Varela","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.9311299,"longitud":-65.8719409,"ranking":1},
{"id":14819,"nombre":"Los Narvaez","localidad":"Los Narvaez","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.637405,"longitud":-65.9592013,"ranking":0},
{"id":14821,"nombre":"Agua Colorada","localidad":"Agua Colorada","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.5618231,"longitud":-65.7804802,"ranking":0},
{"id":14820,"nombre":"La Puerta","localidad":"La Puerta","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.1563949,"longitud":-65.7932835,"ranking":0},
{"id":14822,"nombre":"El Portezuelo","localidad":"El Portezuelo","provincia":"Catamarca","pais":"ARGENTINA","latitud":-28.470322409141428,"longitud":-65.63481546931811,"ranking":0},
{"id":14824,"nombre":"Umarituba","localidad":"Umarituba","provincia":"Ceará","pais":"BRASIL","latitud":-3.6684223,"longitud":-38.979701,"ranking":0},
{"id":14826,"nombre":"Gado dos Ferros","localidad":"Gado dos Ferros","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14827,"nombre":"Monguba","localidad":"Monguba","provincia":"Ceará","pais":"BRASIL","latitud":-3.93084,"longitud":-38.60858,"ranking":0},
{"id":14828,"nombre":"Pai Joao","localidad":"Pai João","provincia":"Ceará","pais":"BRASIL","latitud":-4.4528661,"longitud":-39.0077506,"ranking":0},
{"id":14825,"nombre":"La Brea","localidad":"La Brea","provincia":"Catamarca","pais":"ARGENTINA","latitud":-29.189153566815136,"longitud":-65.16064653213505,"ranking":0},
{"id":14830,"nombre":"El Bolson","localidad":"El Bolson","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.903383412011866,"longitud":-65.88079300522618,"ranking":0},
{"id":14831,"nombre":"Condor Huasi","localidad":"Condor Huasi","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.495686080015645,"longitud":-67.09573811582698,"ranking":0},
{"id":14832,"nombre":"Alumbrera","localidad":"Alumbrera","provincia":"Catamarca","pais":"ARGENTINA","latitud":-27.526224156010684,"longitud":-66.0163837223778,"ranking":0},
{"id":14836,"nombre":"Labrea","localidad":"Labrea","provincia":"Amazonas","pais":"BRASIL","latitud":-7.270167934404084,"longitud":-64.78906622202828,"ranking":0},
{"id":14838,"nombre":"Nage","localidad":"Nage","provincia":"Bahia","pais":"BRASIL","latitud":-12.727924367161432,"longitud":-38.93425254262547,"ranking":0},
{"id":14859,"nombre":"Santa Rosa","localidad":"Santa Rosa","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14860,"nombre":"Orozimbo","localidad":"Orozimbo","provincia":"Maranhão","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14861,"nombre":"Cana Brava","localidad":"Cana Brava","provincia":"Maranhão","pais":"BRASIL","latitud":-3.0745953,"longitud":-42.23674099999999,"ranking":0},
{"id":14862,"nombre":"Santa Gertrudes","localidad":"Santa Gertrudes","provincia":"Paraíba","pais":"BRASIL","latitud":-6.9493053,"longitud":-37.3956906,"ranking":0},
{"id":14869,"nombre":"Boa Sorte","localidad":"Boa Sorte","provincia":"Minas Gerais","pais":"BRASIL","latitud":-15.337861,"longitud":-42.155212,"ranking":0},
{"id":14870,"nombre":"Sarandi","localidad":"Sarandi","provincia":"Goias","pais":"BRASIL","latitud":-18.3611561,"longitud":-49.36930580000001,"ranking":0},
{"id":14871,"nombre":"Aguas das Pedras","localidad":"Aguas das Pedras","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14872,"nombre":"Atirado","localidad":"Atirado","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14873,"nombre":"Indapolis","localidad":"Indapolis","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.212752893256962,"longitud":-54.650179944516736,"ranking":0},
{"id":14878,"nombre":"Messianopolis","localidad":"Messianopolis","provincia":"Goias","pais":"BRASIL","latitud":-16.4902996,"longitud":-50.6932409,"ranking":0},
{"id":14890,"nombre":"Itamira","localidad":"Itamira","provincia":"Bahia","pais":"BRASIL","latitud":-11.715565843330245,"longitud":-38.229684959546475,"ranking":0},
{"id":14917,"nombre":"Taquaralto","localidad":"Taquaralto","provincia":"Tocantins","pais":"BRASIL","latitud":-10.3291695,"longitud":-48.2984582,"ranking":0},
{"id":14918,"nombre":"Cajazeiras","localidad":"Cajazeiras","provincia":"Pará","pais":"BRASIL","latitud":-4.9642652,"longitud":-49.4483404,"ranking":0},
{"id":14919,"nombre":"Alacilandia","localidad":"Alacilandia","provincia":"Pará","pais":"BRASIL","latitud":-8.2130539,"longitud":-49.61399429999999,"ranking":0},
{"id":14922,"nombre":"Vila Vargas","localidad":"Vila Vargas","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.13359985254962,"longitud":-54.61413943361879,"ranking":0},
{"id":14920,"nombre":"Vila Sao Pedro","localidad":"Vila Sao Pedro","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":14921,"nombre":"Salobra","localidad":"Salobra","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-20.2020505,"longitud":-56.5442348,"ranking":0},
{"id":14928,"nombre":"Laranja Azeda","localidad":"Laranja Azeda","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.432557747746028,"longitud":-54.782729689336264,"ranking":0},
{"id":14924,"nombre":"Formosa","localidad":"Formosa","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.085488227389916,"longitud":-54.506577337611176,"ranking":0},
{"id":14929,"nombre":"Faxinal dos Ribeiros","localidad":"Faxinal dos Ribeiros","provincia":"Parana","pais":"BRASIL","latitud":-24.000272821474635,"longitud":-51.32910199824566,"ranking":0},
{"id":14930,"nombre":"Guarapuavinha","localidad":"Guarapuavinha","provincia":"Parana","pais":"BRASIL","latitud":-25.521907896987855,"longitud":-51.13437468922686,"ranking":0},
{"id":14925,"nombre":"Paranareal","localidad":"Paranareal","provincia":"Parana","pais":"BRASIL","latitud":-22.8311448,"longitud":-52.1729824,"ranking":0},
{"id":14926,"nombre":"Ponta Aguda","localidad":"Ponta Aguda","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9005776,"longitud":-49.04728739999999,"ranking":0},
{"id":14927,"nombre":"Serrote do Boi","localidad":"Serrote do Boi","provincia":"Pernambuco","pais":"BRASIL","latitud":-8.7807114,"longitud":-38.48267999999999,"ranking":0},
{"id":15006,"nombre":"Icara","localidad":"Icará","provincia":"Parana","pais":"BRASIL","latitud":-23.172758,"longitud":-51.6363464,"ranking":0},
{"id":15007,"nombre":"Vítor Hugo","localidad":"Vítor Hugo","provincia":"Espíritu Santo","pais":"BRASIL","latitud":-20.4205234,"longitud":-40.878688,"ranking":0},
{"id":15008,"nombre":"Banabuiu","localidad":"Banabuiú","provincia":"Ceará","pais":"BRASIL","latitud":-5.306287733857271,"longitud":-38.920180375160314,"ranking":0},
{"id":15009,"nombre":"Lagoa de Sao Joao","localidad":"Lagoa de Sao Joao","provincia":"Ceará","pais":"BRASIL","latitud":-4.4030776,"longitud":-38.7255532,"ranking":0},
{"id":15010,"nombre":"Cidade Nova","localidad":"Cidade Nova","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15011,"nombre":"Badenfurt","localidad":"Badenfurt","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.882656,"longitud":-49.14091699999999,"ranking":0},
{"id":15012,"nombre":"Bela Alianca","localidad":"Bela Alianca","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1897575,"longitud":-49.5910108,"ranking":0},
{"id":15019,"nombre":"Arnopolis","localidad":"Arnópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.6164026,"longitud":-49.4364344,"ranking":0},
{"id":15020,"nombre":"Aterrado Torto","localidad":"Aterrado Torto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2670547,"longitud":-49.8819533,"ranking":0},
{"id":15017,"nombre":"Amola Faca","localidad":"Amola Faca","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15018,"nombre":"Araca","localidad":"Araçá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.5752163,"longitud":-50.6172171,"ranking":0},
{"id":15021,"nombre":"Bom Sucesso","localidad":"Bom Sucesso","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9847918,"longitud":-51.324814,"ranking":0},
{"id":15022,"nombre":"ClaraIba","localidad":"Claraíba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2074045,"longitud":-48.8976511,"ranking":0},
{"id":15023,"nombre":"Dez de Novembro","localidad":"Dez de Novembro","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15024,"nombre":"Encruzilhada","localidad":"Encruzilhada","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.528276,"longitud":-51.3817859,"ranking":0},
{"id":15025,"nombre":"Fragosos","localidad":"Fragosos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.1729337,"longitud":-49.39228929999999,"ranking":0},
{"id":15026,"nombre":"Goiabeira","localidad":"Goiabeira","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15027,"nombre":"Herciliopolis","localidad":"Herciliópolis","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.8148968,"longitud":-51.50353,"ranking":0},
{"id":15028,"nombre":"Idamar","localidad":"Idamar","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15029,"nombre":"Ipomeia","localidad":"Ipoméia","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9371878,"longitud":-51.10562669999999,"ranking":0},
{"id":15030,"nombre":"Itaio","localidad":"Itaió","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4755001,"longitud":-49.8699112,"ranking":0},
{"id":15031,"nombre":"Lageadinho","localidad":"Lageadinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15032,"nombre":"Marombas","localidad":"Marombas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.3316151,"longitud":-50.764551,"ranking":0},
{"id":15033,"nombre":"Palmares","localidad":"Palmares","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.327663216292066,"longitud":-50.92497225056733,"ranking":0},
{"id":15034,"nombre":"Pirabeiraba","localidad":"Pirabeiraba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.206171,"longitud":-48.9084744,"ranking":0},
{"id":15035,"nombre":"Poco Preto","localidad":"Poço Preto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.291366573888688,"longitud":-50.88054112449859,"ranking":0},
{"id":15036,"nombre":"Queçaba","localidad":"Queçaba","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.747215429538308,"longitud":-48.92916506497388,"ranking":0},
{"id":15037,"nombre":"Rondinha","localidad":"Rondinha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.3047719,"longitud":-49.88268559999999,"ranking":0},
{"id":15310,"nombre":"Santa Lucia","localidad":"Santa Lúcia","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1005849,"longitud":-53.2097932,"ranking":0},
{"id":15039,"nombre":"Figueira","localidad":"Figueira","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.945576,"longitud":-42.165002,"ranking":0},
{"id":15040,"nombre":"Serrinha","localidad":"Serrinha","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15041,"nombre":"Rancho Alegre","localidad":"Rancho Alegre","provincia":"Goias","pais":"BRASIL","latitud":-17.6329571,"longitud":-49.1757261,"ranking":0},
{"id":15042,"nombre":"Cruzeiro do Norte","localidad":"Cruzeiro do Norte","provincia":"Goias","pais":"BRASIL","latitud":-13.4997576,"longitud":-49.6497158,"ranking":0},
{"id":15043,"nombre":"Nortelandia","localidad":"Nortelândia","provincia":"Goias","pais":"BRASIL","latitud":-14.9584592,"longitud":-49.3618882,"ranking":0},
{"id":15044,"nombre":"Mata Azul ","localidad":"Mata Azul","provincia":"Goias","pais":"BRASIL","latitud":-13.110583079023769,"longitud":-48.60806646277005,"ranking":0},
{"id":15045,"nombre":"Pau Terra ","localidad":"Pau Terra","provincia":"Goias","pais":"BRASIL","latitud":-14.077909822645218,"longitud":-48.96997079973809,"ranking":0},
{"id":15046,"nombre":"Piloandia","localidad":"Piloândia","provincia":"Goias","pais":"BRASIL","latitud":-16.3683885,"longitud":-50.7721199,"ranking":0},
{"id":15047,"nombre":"Tataira","localidad":"Tataíra","provincia":"Goias","pais":"BRASIL","latitud":-13.0802146,"longitud":-49.97599229999999,"ranking":0},
{"id":15048,"nombre":"São Gabriel de Baunilha","localidad":"São Gabriel de Baunilha","provincia":"Espíritu Santo","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15049,"nombre":"Tabuleta","localidad":"Tabuleta","provincia":"Piauí","pais":"BRASIL","latitud":-5.124656,"longitud":-42.7997356,"ranking":0},
{"id":15069,"nombre":"Marajoara","localidad":"Marajoara","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15070,"nombre":"Bom Jardim","localidad":"Bom Jardim","provincia":"Pará","pais":"BRASIL","latitud":-4.2765084,"longitud":-55.9990787,"ranking":0},
{"id":15065,"nombre":"Riacho Grande","localidad":"Riacho Grande","provincia":"Paraíba","pais":"BRASIL","latitud":-7.723750742986501,"longitud":-38.031503846072994,"ranking":0},
{"id":15078,"nombre":"Vacas Gordas","localidad":"Vacas Gordas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.1210384,"longitud":-49.6530418,"ranking":0},
{"id":15077,"nombre":"Taquara Verde","localidad":"Taquara Verde","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.7656479,"longitud":-51.208889,"ranking":0},
{"id":15079,"nombre":"Vargem Grande","localidad":"Vargem Grande","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.462698,"longitud":-48.449805,"ranking":0},
{"id":15080,"nombre":"Vila Formosa","localidad":"Vila Formosa","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15081,"nombre":"Vila Nova","localidad":"Vila Nova","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.28696107803768,"longitud":-48.90261934350155,"ranking":0},
{"id":15082,"nombre":"Vila Santana","localidad":"Vila Santana","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.7092018,"longitud":-48.7503542,"ranking":0},
{"id":15083,"nombre":"Maratá","localidad":"Maratá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.5832848,"longitud":-52.6408943,"ranking":0},
{"id":15084,"nombre":"Frederico Wastner","localidad":"Frederico Wastner","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4044425,"longitud":-52.7879014,"ranking":0},
{"id":15087,"nombre":"Baia Alta","localidad":"Baía Alta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.8556488,"longitud":-52.0315513,"ranking":0},
{"id":15066,"nombre":"Guara","localidad":"Guará","provincia":"Parana","pais":"BRASIL","latitud":-25.3752236,"longitud":-51.2845569,"ranking":0},
{"id":15067,"nombre":"Relogio","localidad":"Relógio","provincia":"Parana","pais":"BRASIL","latitud":-25.272783,"longitud":-51.0995072,"ranking":0},
{"id":15068,"nombre":"Tres Pinheiros","localidad":"Três Pinheiros","provincia":"Parana","pais":"BRASIL","latitud":-25.54685655396768,"longitud":-54.54691781843431,"ranking":0},
{"id":15072,"nombre":"Jangada do Sul","localidad":"Jangada do Sul","provincia":"Parana","pais":"BRASIL","latitud":-26.396973,"longitud":-51.281301,"ranking":0},
{"id":15074,"nombre":"Pinheirinho","localidad":"Pinheirinho","provincia":"Parana","pais":"BRASIL","latitud":-25.5238048,"longitud":-49.2953602,"ranking":0},
{"id":15076,"nombre":"Santana","localidad":"Santana","provincia":"Parana","pais":"BRASIL","latitud":-25.9441679,"longitud":-51.2603963,"ranking":0},
{"id":15085,"nombre":"Campo do Meio","localidad":"Campo do Meio","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.324576542008497,"longitud":-52.04688629880207,"ranking":0},
{"id":15086,"nombre":"Cruzaltinha","localidad":"Cruzaltinha","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.2871769,"longitud":-51.9606047,"ranking":0},
{"id":15075,"nombre":"Rio Preto do Sul","localidad":"Rio Preto do Sul","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.2187959,"longitud":-49.61468259999999,"ranking":0},
{"id":15071,"nombre":"Alto Forcacao","localidad":"Alto Forcação","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.7508671,"longitud":-49.6123185,"ranking":0},
{"id":15073,"nombre":"Moema","localidad":"Moema","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.5526482,"longitud":-49.80555930000001,"ranking":0},
{"id":15064,"nombre":"Belvedere","localidad":"Belvedere","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.70865,"longitud":-43.73673,"ranking":0},
{"id":15091,"nombre":"Infusao","localidad":"Infusão","provincia":"Goias","pais":"BRASIL","latitud":-13.3087659,"longitud":-49.8094045,"ranking":0},
{"id":15090,"nombre":"Jatoba","localidad":"Jatobá","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-21.6068148,"longitud":-53.1248292,"ranking":0},
{"id":15107,"nombre":"Barreiro","localidad":"Barreiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.974276430117573,"longitud":-44.021148666696504,"ranking":0},
{"id":15092,"nombre":"Visconde de Maua","localidad":"Visconde de Maua","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.331982333284433,"longitud":-44.54110868530937,"ranking":0},
{"id":15108,"nombre":"Agro Cafeeira","localidad":"Agro Cafeeira","provincia":"Parana","pais":"BRASIL","latitud":-25.1941723,"longitud":-53.9414318,"ranking":0},
{"id":15109,"nombre":"Guataparema","localidad":"Guataparema","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.53894656637241,"longitud":-53.52074969292074,"ranking":0},
{"id":15110,"nombre":"Humaitá","localidad":"Humaitá","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.474909260154988,"longitud":-49.01413671481414,"ranking":0},
{"id":15165,"nombre":"Alegre do Marco","localidad":"Alegre do Marco","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.6392233,"longitud":-52.368932,"ranking":0},
{"id":15166,"nombre":"Alto Tigre","localidad":"Alto Tigre","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0595036,"longitud":-53.4801945,"ranking":0},
{"id":15167,"nombre":"Barra Verde","localidad":"Barra Verde","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1888895,"longitud":-51.4648699,"ranking":0},
{"id":15168,"nombre":"Bracatinga","localidad":"Bracatinga","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15170,"nombre":"Cachimbo","localidad":"Cachimbo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1817207,"longitud":-51.91906969999999,"ranking":0},
{"id":15163,"nombre":"Itaparica","localidad":"Itaparica","provincia":"Pernambuco","pais":"BRASIL","latitud":-9.1759187,"longitud":-38.2579838,"ranking":0},
{"id":15164,"nombre":"Lagoado","localidad":"Lagoado","provincia":"Piauí","pais":"BRASIL","latitud":-10.5030881,"longitud":-44.0412615,"ranking":0},
{"id":15171,"nombre":"Estrada Velha","localidad":"Estrada Velha","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.7004789,"longitud":-48.7782247,"ranking":0},
{"id":15172,"nombre":"Fernando Machado","localidad":"Fernando Machado","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9446583,"longitud":-52.6738735,"ranking":0},
{"id":15169,"nombre":"Britador","localidad":"Britador","provincia":"Parana","pais":"BRASIL","latitud":-23.9142045,"longitud":-50.187153,"ranking":0},
{"id":15173,"nombre":"Imasa","localidad":"Imasa","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.64146728625674,"longitud":-49.10581924252714,"ranking":0},
{"id":15175,"nombre":"Lomba Alta","localidad":"Lomba Alta","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.7312727,"longitud":-49.3644574,"ranking":0},
{"id":15174,"nombre":"Liberata","localidad":"Liberata","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0717761,"longitud":-50.898916,"ranking":0},
{"id":15176,"nombre":"Nova Rodeio","localidad":"Nova Rodeio","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15177,"nombre":"Pericó","localidad":"Pericó","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.1618192,"longitud":-49.7562436,"ranking":0},
{"id":15178,"nombre":"Pintadinho","localidad":"Pintadinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.3356452,"longitud":-51.0487618,"ranking":0},
{"id":15183,"nombre":"Ijuizinho","localidad":"Ijuizinho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.5375078,"longitud":-54.0515631,"ranking":0},
{"id":15185,"nombre":"Rio da Praia","localidad":"Rio da Praia","provincia":"Parana","pais":"BRASIL","latitud":-25.94756436024068,"longitud":-48.59806513327486,"ranking":0},
{"id":15116,"nombre":"Nova Esperanca","localidad":"Nova Esperança","provincia":"Minas Gerais","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15184,"nombre":"Bonsucesso","localidad":"Bonsucesso","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15182,"nombre":"Sao Bras","localidad":"São Brás","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15181,"nombre":"Tres Voltas","localidad":"Três Voltas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.4266912,"longitud":-52.9663804,"ranking":0},
{"id":15180,"nombre":"Tres Arvores","localidad":"Três Árvores","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.728125,"longitud":-50.03750669999999,"ranking":0},
{"id":15179,"nombre":"Sumidor","localidad":"Sumidor","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15138,"nombre":"Abaete dos Mendes","localidad":"Abaete dos Mendes","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.147386046490066,"longitud":-46.12995573600433,"ranking":0},
{"id":15139,"nombre":"Alexandrita","localidad":"Alexandrita","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.700974648111174,"longitud":-50.455200399735,"ranking":0},
{"id":15145,"nombre":"Sao Joao do Manhuacu","localidad":"Sao Joao do Manhuacu","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.388886474643005,"longitud":-42.15828377057463,"ranking":0},
{"id":15202,"nombre":"Itararé","localidad":"Itararé","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.7131655,"longitud":-50.5308479,"ranking":0},
{"id":15205,"nombre":"Rio Canoas","localidad":"Rio Canoas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.6919132,"longitud":-48.83463889999999,"ranking":0},
{"id":15206,"nombre":"Rio das Pedras","localidad":"Rio das Pedras","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15207,"nombre":"Rio de Traz","localidad":"Rio de Tráz","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2753305,"longitud":-49.9980031,"ranking":0},
{"id":15203,"nombre":"Lageado","localidad":"Lageado","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.146226,"longitud":-49.2544481,"ranking":0},
{"id":15204,"nombre":"Lencol","localidad":"Lençol","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.2501494,"longitud":-49.4557339,"ranking":0},
{"id":15208,"nombre":"Santo Antonio","localidad":"Santo Antônio","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.501510769239655,"longitud":-48.51471489804729,"ranking":0},
{"id":15209,"nombre":"Sao Leonardo","localidad":"São Leonardo","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.6722004,"longitud":-49.2026692,"ranking":0},
{"id":15210,"nombre":"São Roque","localidad":"São Roque","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.2922655,"longitud":-52.0110827,"ranking":0},
{"id":15211,"nombre":"Rio dos Patos","localidad":"Rio dos Patos","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.9174392,"longitud":-50.7371765,"ranking":0},
{"id":15194,"nombre":"Rio da Areia ","localidad":"Rio da Areía","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15192,"nombre":"Riozinho","localidad":"Riozinho","provincia":"Parana","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15193,"nombre":"Rondinha","localidad":"Rondinha","provincia":"Parana","pais":"BRASIL","latitud":-25.44742467119939,"longitud":-49.479150293754735,"ranking":0},
{"id":15195,"nombre":"Rio das Antas","localidad":"Rio das Antas","provincia":"Parana","pais":"BRASIL","latitud":-24.9193798,"longitud":-53.5362381,"ranking":0},
{"id":15189,"nombre":"Palmeirinha","localidad":"Palmeirinha","provincia":"Goias","pais":"BRASIL","latitud":-14.021718,"longitud":-48.6139397,"ranking":0},
{"id":15188,"nombre":"Ribeiro","localidad":"Ribeiro","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.9849827,"longitud":-49.484779,"ranking":0},
{"id":15186,"nombre":"Pedrao","localidad":"Pedrão","provincia":"Minas Gerais","pais":"BRASIL","latitud":-22.3177552,"longitud":-45.4250118,"ranking":0},
{"id":15187,"nombre":"Penha","localidad":"Penha","provincia":"Minas Gerais","pais":"BRASIL","latitud":-20.725232470484105,"longitud":-46.62012299194341,"ranking":0},
{"id":15190,"nombre":"Sao Raimundo","localidad":"São Raimundo","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15191,"nombre":"Jacare","localidad":"Jacaré","provincia":"Pará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15199,"nombre":"Barreto","localidad":"Barreto","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-29.8699334,"longitud":-51.7112232,"ranking":0},
{"id":15200,"nombre":"Rincao de Sao Pedro","localidad":"Rincão de São Pedro","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.3884542,"longitud":-55.1070189,"ranking":0},
{"id":15201,"nombre":"Vila Assis","localidad":"Vila Assis","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-5.2384887,"longitud":-35.5861435,"ranking":0},
{"id":15196,"nombre":"Rio Pinheiro","localidad":"Rio Pinheiro","provincia":"Parana","pais":"BRASIL","latitud":-26.3123369,"longitud":-52.5925761,"ranking":0},
{"id":15197,"nombre":"Roseira","localidad":"Roseira","provincia":"Parana","pais":"BRASIL","latitud":-25.51505707585058,"longitud":-49.12077283161393,"ranking":0},
{"id":15198,"nombre":"Registro","localidad":"Registro","provincia":"Parana","pais":"BRASIL","latitud":-25.485502,"longitud":-48.69835519999999,"ranking":0},
{"id":15213,"nombre":"Sao Mateus","localidad":"São Mateus","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.61354737378451,"longitud":-46.47642978294741,"ranking":0},
{"id":15214,"nombre":"Antonio Bezerra","localidad":"Antônio Bezerra","provincia":"Ceará","pais":"BRASIL","latitud":-3.7392867,"longitud":-38.5965152,"ranking":0},
{"id":15215,"nombre":"Theodoro de Oliveira","localidad":"Theodoro de Oliveira","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15216,"nombre":"Resplendor","localidad":"Resplendor","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-21.6300712,"longitud":-41.03400269999999,"ranking":0},
{"id":15217,"nombre":"Lajeadinho","localidad":"Lajeadinho","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15218,"nombre":"Santa Cruz","localidad":"Santa Cruz","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15219,"nombre":"Aguas Brancas","localidad":"Águas Brancas","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.954498,"longitud":-49.579573,"ranking":0},
{"id":15220,"nombre":"Brilhante","localidad":"Brilhante","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.003561,"longitud":-48.80544200000001,"ranking":0},
{"id":15221,"nombre":"Campo Alto","localidad":"Campo Alto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.7463479,"longitud":-50.407805,"ranking":0},
{"id":15222,"nombre":"Cordilheira","localidad":"Cordilheira","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1024857,"longitud":-53.6859473,"ranking":0},
{"id":15223,"nombre":"Planalto","localidad":"Planalto","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.1602571,"longitud":-51.86512920000001,"ranking":0},
{"id":15224,"nombre":"Rincao Torcido","localidad":"Rincão Torcido","provincia":"Santa Catarina","pais":"BRASIL","latitud":-28.850890088259128,"longitud":-49.25497052154173,"ranking":0},
{"id":15225,"nombre":"Sao Lourenco","localidad":"São Lourenço","provincia":"Santa Catarina","pais":"BRASIL","latitud":-26.36331608245374,"longitud":-52.865185631632556,"ranking":0},
{"id":15227,"nombre":"Ferro Velho","localidad":"Ferro Velho","provincia":"Maranhão","pais":"BRASIL","latitud":-4.203346799999999,"longitud":-46.138869,"ranking":0},
{"id":15232,"nombre":"Boa Vista","localidad":"Boa Vista","provincia":"Mato Grosso","pais":"BRASIL","latitud":-16.215559,"longitud":-54.7778942,"ranking":0},
{"id":15234,"nombre":"Continental","localidad":"Continental","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":-22.2175983,"longitud":-54.787364,"ranking":0},
{"id":15245,"nombre":"Rio das Pedras","localidad":"Rio das Pedras","provincia":"Minas Gerais","pais":"BRASIL","latitud":-18.6793224,"longitud":-48.5164829,"ranking":0},
{"id":15246,"nombre":"Nova Itueta","localidad":"Nova Itueta","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.39604275147505,"longitud":-41.22665343153829,"ranking":0},
{"id":15247,"nombre":"Aurora","localidad":"Aurora","provincia":"Parana","pais":"BRASIL","latitud":-23.3077469614501,"longitud":-51.17765957370208,"ranking":0},
{"id":15248,"nombre":"Sao Lourenco","localidad":"São Lourenço","provincia":"Alagoas","pais":"BRASIL","latitud":-9.477066598083166,"longitud":-35.859554084358976,"ranking":0},
{"id":15253,"nombre":"Carajas","localidad":"Carajás","provincia":"Pará","pais":"BRASIL","latitud":-6.067804265844842,"longitud":-50.061439963310846,"ranking":0},
{"id":15255,"nombre":"Boa Vista","localidad":"Boa Vista","provincia":"Ceará","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15257,"nombre":"Campos Verdes","localidad":"Campos Verdes","provincia":"Pará","pais":"BRASIL","latitud":-4.3453829,"longitud":-55.7836449,"ranking":0},
{"id":15260,"nombre":"Boicucanga","localidad":"Boiçucanga","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.7829174,"longitud":-45.6218413,"ranking":0},
{"id":15263,"nombre":"Porto Seguro","localidad":"Caraiva","provincia":"Bahia","pais":"BRASIL","latitud":-16.43701318580763,"longitud":-39.069481625896614,"ranking":0},
{"id":15267,"nombre":"Bandeirantes D Oste","localidad":"Bandeirantes D'Oeste","provincia":"Sao Paulo","pais":"BRASIL","latitud":-20.610905318941374,"longitud":-50.810338337008254,"ranking":0},
{"id":15304,"nombre":"Horizonte","localidad":"Horizonte","provincia":"Parana","pais":"BRASIL","latitud":-26.589965,"longitud":-51.573684,"ranking":0},
{"id":15305,"nombre":"Santa Barbara","localidad":"Santa Bárbara","provincia":"Parana","pais":"BRASIL","latitud":-23.589966224652006,"longitud":-50.76222264755453,"ranking":0},
{"id":15308,"nombre":"Fazendinha","localidad":"Fazendinha","provincia":"Goias","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15306,"nombre":"Tingui","localidad":"Tingüi","provincia":"Santa Catarina","pais":"BRASIL","latitud":-25.37604210362261,"longitud":-49.22426877951912,"ranking":0},
{"id":15307,"nombre":"Santa Rita","localidad":"Santa Rita","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.0825639,"longitud":-48.900355,"ranking":0},
{"id":15281,"nombre":"Piata","localidad":"Cabrália","provincia":"Bahia","pais":"BRASIL","latitud":-13.150751380811776,"longitud":-41.775457614600604,"ranking":0},
{"id":15282,"nombre":"Arneiroz","localidad":"Cachoeira de Fora","provincia":"Ceará","pais":"BRASIL","latitud":-6.192300270572084,"longitud":-40.15202650989459,"ranking":0},
{"id":15312,"nombre":"Povinho Velho","localidad":"Povinho Velho","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":-28.243116674436955,"longitud":-52.26729432312477,"ranking":0},
{"id":15309,"nombre":"Santa Clara","localidad":"Santa Clara","provincia":"Santa Catarina","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15311,"nombre":"Vista Alegre","localidad":"Vista Alegre","provincia":"Santa Catarina","pais":"BRASIL","latitud":-27.7601146,"longitud":-50.3753889,"ranking":0},
{"id":15313,"nombre":"Sao Jose","localidad":"São José","provincia":"Ceará","pais":"BRASIL","latitud":-7.2269159261029445,"longitud":-39.34302666605278,"ranking":0},
{"id":15314,"nombre":"Porto Morrinho","localidad":"Porto Morrinho","provincia":"Mato Grosso do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15340,"nombre":"Manguinhos","localidad":"Buzios","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.77047033540502,"longitud":-41.91142055362597,"ranking":0},
{"id":15341,"nombre":"Ferradura","localidad":"Buzios","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.7690304,"longitud":-41.8876085,"ranking":0},
{"id":15342,"nombre":"Joao Fernandes","localidad":"Buzios","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.747113432436326,"longitud":-41.87901583023011,"ranking":0},
{"id":15344,"nombre":"Largo do Machado","localidad":"Largo do Machado","provincia":"Rio de Janeiro","pais":"BRASIL","latitud":-22.9310196,"longitud":-43.1785652,"ranking":0},
{"id":15346,"nombre":"Rincao","localidad":"Rincão","provincia":"Rio Grande do Sul","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15349,"nombre":"Bras","localidad":"Bras","provincia":"Sao Paulo","pais":"BRASIL","latitud":-23.545648,"longitud":-46.608185,"ranking":0},
{"id":15347,"nombre":"Perobal","localidad":"São Jorge do Iguaçu","provincia":"Parana","pais":"BRASIL","latitud":-23.894688293413328,"longitud":-53.40861123116761,"ranking":0},
{"id":4679,"nombre":"Riachuelo","localidad":"Riachuelo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.5809603,"longitud":-58.7405488,"ranking":0},
{"id":15354,"nombre":"Riachuelo Acceso","localidad":"Riachuelo","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.58250985669337,"longitud":-58.740283308094085,"ranking":0},
{"id":1575,"nombre":"El Sombrero","localidad":"El Sombrero","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.702433,"longitud":-58.760957,"ranking":1},
{"id":1606,"nombre":"El Sombrero Acceso","localidad":"El Sombrero","provincia":"Corrientes","pais":"ARGENTINA","latitud":-27.70219829151972,"longitud":-58.77134827348669,"ranking":2},
{"id":15355,"nombre":"Arroyito Acceso","localidad":"Arroyito","provincia":"Corrientes","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15356,"nombre":"Naranjito","localidad":"Naranjito","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.9205478,"longitud":-58.27665499999999,"ranking":0},
{"id":15358,"nombre":"Tres Bocas","localidad":"Tres Bocas","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.354299355521565,"longitud":-57.56427298805806,"ranking":0},
{"id":15359,"nombre":"Taruma","localidad":"Taruma","provincia":"Caazapá","pais":"Paraguay","latitud":-25.9151634,"longitud":-55.801118,"ranking":0},
{"id":15360,"nombre":"Tupa Renda","localidad":"Tupa Renda","provincia":"Departamento Central - Paraguay","pais":"Paraguay","latitud":-25.242197825239053,"longitud":-57.58253862024576,"ranking":0},
{"id":15361,"nombre":"Santa Cecilia","localidad":"Santa Cecilia","provincia":"Guairá","pais":"Paraguay","latitud":-25.7888321,"longitud":-56.1621694,"ranking":0},
{"id":15362,"nombre":"Melgarejo","localidad":"Melgarejo","provincia":"Guairá","pais":"Paraguay","latitud":-25.718553528950107,"longitud":-56.23132519445589,"ranking":0},
{"id":15363,"nombre":"San Francisco","localidad":"San Francisco","provincia":"Caazapá","pais":"Paraguay","latitud":-26.29785722098862,"longitud":-56.069333520406815,"ranking":0},
{"id":15364,"nombre":"Lima","localidad":"Lima","provincia":"Caazapá","pais":"Paraguay","latitud":-25.831749502214333,"longitud":-55.82428021469577,"ranking":0},
{"id":15365,"nombre":"San Carlos","localidad":"San Carlos","provincia":"Caazapá","pais":"Paraguay","latitud":-26.1340963338298,"longitud":-55.797269248924884,"ranking":0},
{"id":15368,"nombre":"Corazón de María","localidad":"Corazón de Maria","provincia":"Caazapá","pais":"Paraguay","latitud":-26.644309223778777,"longitud":-55.14924944316073,"ranking":0},
{"id":15369,"nombre":"Concepcion-Mi","localidad":"Concepcion-Mi","provincia":"Guairá","pais":"Paraguay","latitud":-25.993116819701882,"longitud":-56.33585375526891,"ranking":0},
{"id":15370,"nombre":"Caazapa-Mi","localidad":"Caazapa-Mi","provincia":"Caazapá","pais":"Paraguay","latitud":-25.886865697218564,"longitud":-56.49322042053542,"ranking":0},
{"id":15371,"nombre":"San Agustin","localidad":"San Agustin","provincia":"Caazapá","pais":"Paraguay","latitud":-26.223089175207697,"longitud":-56.12794588314732,"ranking":0},
{"id":15372,"nombre":"3 de Noviembre","localidad":"3 de Noviembre","provincia":"Caaguazú","pais":"Paraguay","latitud":-25.621728340912842,"longitud":-55.75196064809838,"ranking":0},
{"id":15373,"nombre":"Fatima ","localidad":"Fatima","provincia":"Caazapá","pais":"Paraguay","latitud":-26.242479842014895,"longitud":-56.16403827919343,"ranking":0},
{"id":15374,"nombre":"Vera Cue","localidad":"Vera Cue","provincia":"Caazapá","pais":"Paraguay","latitud":-26.329237685165445,"longitud":-56.09516699786843,"ranking":0},
{"id":15375,"nombre":"Ayala Cue","localidad":"Ayala Cue","provincia":"Caazapá","pais":"Paraguay","latitud":-26.497840251314265,"longitud":-56.113462189406704,"ranking":0},
{"id":15376,"nombre":"Colonia Siberia","localidad":"Colonia Siberia","provincia":"Itapua","pais":"Paraguay","latitud":-27.149986897558456,"longitud":-56.21924870861424,"ranking":0},
{"id":15377,"nombre":"Torres Cue","localidad":"Torres Cue","provincia":"Caazapá","pais":"Paraguay","latitud":-25.81583873551119,"longitud":-55.95666719378555,"ranking":0},
{"id":15378,"nombre":"Cerrito","localidad":"Cerrito","provincia":"Caazapá","pais":"Paraguay","latitud":-26.21003288763462,"longitud":-55.85514615692069,"ranking":0},
{"id":15379,"nombre":"San Miguel","localidad":"San Miguel","provincia":"Caazapá","pais":"Paraguay","latitud":-26.526577245265745,"longitud":-56.22159854319483,"ranking":0},
{"id":15417,"nombre":"Comas","localidad":"Comas","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15618,"nombre":"Tarica","localidad":"Huaraz","provincia":"Ancash","pais":"Perú","latitud":-9.3940652,"longitud":-77.5761328,"ranking":1000},
{"id":15396,"nombre":"Huaraz","localidad":"Huaraz","provincia":"Ancash","pais":"Perú","latitud":-9.53525926608631,"longitud":-77.53170298362367,"ranking":35},
{"id":15486,"nombre":"Carhuaz","localidad":"Huaraz","provincia":"Ancash","pais":"Perú","latitud":-9.282404,"longitud":-77.6476462,"ranking":0},
{"id":15487,"nombre":"Yungay","localidad":"Huaraz","provincia":"Ancash","pais":"Perú","latitud":-9.5197528,"longitud":-77.5316835,"ranking":0},
{"id":15599,"nombre":"Huaraz (BOULEVARD PASTORITA HUARACINA)","localidad":"Huaraz","provincia":"Ancash","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15402,"nombre":"Los Organos","localidad":"Los Organos","provincia":"Piura","pais":"Perú","latitud":-4.181436776426944,"longitud":-81.13150328792582,"ranking":25},
{"id":15403,"nombre":"Mancora","localidad":"Mancora","provincia":"Piura","pais":"Perú","latitud":-4.106433007697964,"longitud":-81.04836087289077,"ranking":25},
{"id":15408,"nombre":"Sullana","localidad":"Sullana","provincia":"Piura","pais":"Perú","latitud":-4.911570210147478,"longitud":-80.69708554463803,"ranking":30},
{"id":15585,"nombre":"Sullana 1","localidad":"Sullana","provincia":"Piura","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15586,"nombre":"Sullana 2","localidad":"Sullana","provincia":"Piura","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15407,"nombre":"Zorritos","localidad":"Zorritos","provincia":"Tumbes","pais":"Perú","latitud":-3.6805526030930076,"longitud":-80.67751622089573,"ranking":25},
{"id":15405,"nombre":"Punta Sal","localidad":"Punta Sal","provincia":"Tumbes","pais":"Perú","latitud":-3.986382990081839,"longitud":-80.96903866838804,"ranking":25},
{"id":15400,"nombre":"Chimbote","localidad":"Chimbote","provincia":"Ancash","pais":"Perú","latitud":-9.104319165186876,"longitud":-78.55776207142988,"ranking":25},
{"id":15580,"nombre":"Chimbador","localidad":"Chimbote","provincia":"Ancash","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15399,"nombre":"Lambayeque","localidad":"Lambayeque","provincia":"Lambayeque","pais":"Perú","latitud":-6.695996734808647,"longitud":-79.90360588188116,"ranking":25},
{"id":15401,"nombre":"Ferreñafe","localidad":"Ferreñafe","provincia":"Lambayeque","pais":"Perú","latitud":-6.641177603264869,"longitud":-79.79351745430237,"ranking":25},
{"id":15412,"nombre":"Puno","localidad":"Puno","provincia":"Puno","pais":"Perú","latitud":-15.843714468970852,"longitud":-70.01732397261748,"ranking":35},
{"id":15651,"nombre":"Terminal Terrestre Ilave","localidad":"Puno","provincia":"Puno","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15411,"nombre":"Copacabana","localidad":"Copacabana","provincia":"La Paz","pais":"BOLIVIA","latitud":-16.1655296956143,"longitud":-69.07680271653318,"ranking":0},
{"id":15415,"nombre":"Celendin","localidad":"Celendin","provincia":"Cajamarca","pais":"Perú","latitud":-6.870489429541726,"longitud":-78.14454151209405,"ranking":25},
{"id":15414,"nombre":"Lima - Ventanilla","localidad":"Ventanilla","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15419,"nombre":"Barranca","localidad":"Barranca","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-10.752500245195924,"longitud":-77.75845810872336,"ranking":10},
{"id":15480,"nombre":"Pativilca","localidad":"Barranca","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-10.689631,"longitud":-77.7852127,"ranking":0},
{"id":15418,"nombre":"Chancay","localidad":"Chancay","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.496395771595942,"longitud":-77.21028251090326,"ranking":25},
{"id":15420,"nombre":"Chavin de Huantar","localidad":"Chavin de Huantar","provincia":"Ancash","pais":"Perú","latitud":-9.585429244947639,"longitud":-77.17636893562384,"ranking":25},
{"id":15421,"nombre":"Culluchaca","localidad":"Culluchaca","provincia":"Ancash","pais":"Perú","latitud":-12.875253124003702,"longitud":-74.22172985224492,"ranking":25},
{"id":15423,"nombre":"Huari","localidad":"Huari","provincia":"Ancash","pais":"Perú","latitud":-9.346467665158105,"longitud":-77.16890259823522,"ranking":25},
{"id":15424,"nombre":"Huaura","localidad":"Huaura","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.073167446969764,"longitud":-77.5984831007699,"ranking":25},
{"id":15481,"nombre":"Huacho","localidad":"Huaura","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.1090299,"longitud":-77.59544280000001,"ranking":0},
{"id":15425,"nombre":"Huaytuna","localidad":"Huaytuna","provincia":"Ancash","pais":"Perú","latitud":-9.359177502593267,"longitud":-77.0813230356156,"ranking":25},
{"id":15426,"nombre":"Masin","localidad":"Masin","provincia":"Ancash","pais":"Perú","latitud":-9.36575842724457,"longitud":-77.09663408087809,"ranking":25},
{"id":15427,"nombre":"Pomachaca","localidad":"Pomachaca","provincia":"Ancash","pais":"Perú","latitud":-9.394390481717046,"longitud":-77.14265875150475,"ranking":25},
{"id":15428,"nombre":"Rahuapampa","localidad":"Rahuapampa","provincia":"Ancash","pais":"Perú","latitud":-9.359101237022642,"longitud":-77.07858797586933,"ranking":25},
{"id":15429,"nombre":"San Marcos","localidad":"San Marcos","provincia":"Ancash","pais":"Perú","latitud":-9.555562932264392,"longitud":-77.20825407421823,"ranking":25},
{"id":15430,"nombre":"Succha","localidad":"Succha","provincia":"Ancash","pais":"Perú","latitud":-9.822945651490448,"longitud":-77.64961319604397,"ranking":25},
{"id":15431,"nombre":"Uranchacra","localidad":"Uranchacra","provincia":"Ancash","pais":"Perú","latitud":-9.43625313801889,"longitud":-77.168595210848,"ranking":25},
{"id":15432,"nombre":"Yunguilla","localidad":"Yunguilla","provincia":"Ancash","pais":"Perú","latitud":-9.27569902661105,"longitud":-76.99933603763411,"ranking":25},
{"id":15422,"nombre":"Huacaybamba","localidad":"Huacaybamba","provincia":"Huanuco","pais":"Perú","latitud":-9.095005001029813,"longitud":-76.83684217171665,"ranking":25},
{"id":15434,"nombre":"Piuroj","localidad":"Piuroj","provincia":"Ancash","pais":"Perú","latitud":-9.188089596183946,"longitud":-76.96177765259212,"ranking":25},
{"id":15433,"nombre":"Palca","localidad":"Palca","provincia":"Ancash","pais":"Perú","latitud":-9.329890,"longitud":-77.038211,"ranking":25},
{"id":15438,"nombre":"Saltos del Mocona","localidad":"Saltos del Mocona","provincia":"Misiones","pais":"ARGENTINA","latitud":-27.153682433818037,"longitud":-53.90121102107788,"ranking":6},
{"id":15439,"nombre":"Triple Frontera","localidad":"Triple Frontera","provincia":"Misiones","pais":"ARGENTINA","latitud":-25.594375382713356,"longitud":-54.59053408872104,"ranking":0},
{"id":15444,"nombre":"Nova Mutum","localidad":"Nova Mutum","provincia":"Rondônia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15445,"nombre":"Vista Alegre do Abunã","localidad":"Vista Alegre do Abunã","provincia":"Rondônia","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15446,"nombre":"Extrema","localidad":"Extrema","provincia":"Acre","pais":"BRASIL","latitud":null,"longitud":null,"ranking":0},
{"id":15447,"nombre":"Diques y Miradores","localidad":"Diques y Miradores","provincia":"San Juan","pais":"ARGENTINA","latitud":-31.52087341683313,"longitud":-68.818293722783,"ranking":0},
{"id":15448,"nombre":"Diques y Quebradas","localidad":"Diques y Quebradas","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15449,"nombre":"Observatorio Astronomico","localidad":"Observatorio Astronomico","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15450,"nombre":"Parque Nacional Talampaya","localidad":"Parque Nacional Talampaya","provincia":"San Juan","pais":"ARGENTINA","latitud":-29.7828279,"longitud":-67.8365882,"ranking":0},
{"id":15451,"nombre":"Valle de La Luna","localidad":"Valle de La Luna","provincia":"San Juan","pais":"ARGENTINA","latitud":-30.163677365482275,"longitud":-67.84363778621756,"ranking":0},
{"id":15452,"nombre":"Valles Iglesianos","localidad":"Valles Iglesianos","provincia":"San Juan","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15476,"nombre":"Yauya","localidad":"Yauya","provincia":"Ancash","pais":"Perú","latitud":-8.990812799999999,"longitud":-77.2915824,"ranking":0},
{"id":15475,"nombre":"Piscobamba","localidad":"Piscobamba","provincia":"Ancash","pais":"Perú","latitud":-8.863391499999999,"longitud":-77.35786139999999,"ranking":0},
{"id":15478,"nombre":"Pachas","localidad":"Pachas","provincia":"Huanuco","pais":"Perú","latitud":-9.7029221,"longitud":-76.8515324,"ranking":0},
{"id":15477,"nombre":"La Union","localidad":"La Union","provincia":"Huanuco","pais":"Perú","latitud":-9.826386,"longitud":-76.8015951,"ranking":0},
{"id":15479,"nombre":"Tantamayo","localidad":"Tantamayo","provincia":"Huanuco","pais":"Perú","latitud":-9.3931349,"longitud":-76.7184454,"ranking":0},
{"id":15482,"nombre":"Casma","localidad":"Casma","provincia":"Ancash","pais":"Perú","latitud":-9.4744763,"longitud":-78.2961491,"ranking":0},
{"id":15483,"nombre":"Huarmey","localidad":"Huarmey","provincia":"Ancash","pais":"Perú","latitud":-10.0705002,"longitud":-78.1554295,"ranking":0},
{"id":15484,"nombre":"Viru","localidad":"Viru","provincia":"La Libertad","pais":"Perú","latitud":-8.4150818,"longitud":-78.75519369999999,"ranking":0},
{"id":15640,"nombre":"Chao","localidad":"Viru","provincia":"La Libertad","pais":"Perú","latitud":-8.5406213,"longitud":-78.67578759999999,"ranking":0},
{"id":15489,"nombre":"Chepen","localidad":"Chepen","provincia":"La Libertad","pais":"Perú","latitud":-7.220404,"longitud":-79.4335899,"ranking":0},
{"id":15490,"nombre":"Ciudad de Dios","localidad":"San Juan de Miraflores","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-7.3055086,"longitud":-79.4795298,"ranking":0},
{"id":15491,"nombre":"Guadalupe","localidad":"Pacasmayo","provincia":"La Libertad","pais":"Perú","latitud":-7.243624199999999,"longitud":-79.4706667,"ranking":0},
{"id":15512,"nombre":"Pacasmayo","localidad":"Pacasmayo","provincia":"La Libertad","pais":"Perú","latitud":-7.397103,"longitud":-79.56704640000001,"ranking":0},
{"id":15492,"nombre":"Jaen","localidad":"Jaen","provincia":"Cajamarca","pais":"Perú","latitud":-5.7159073,"longitud":-78.8036066,"ranking":0},
{"id":15498,"nombre":"Sao Jose de Almeida","localidad":"Sao Jose de Almeida","provincia":"Minas Gerais","pais":"BRASIL","latitud":-19.436139,"longitud":-43.829654,"ranking":0},
{"id":15504,"nombre":"Huancavelica","localidad":"Huancavelica","provincia":"Huancavelica","pais":"Perú","latitud":-12.7821406,"longitud":-74.9915224,"ranking":0},
{"id":15666,"nombre":"Izcuchaca","localidad":"Huancavelica","provincia":"Huancavelica","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15505,"nombre":"Huancayo","localidad":"Huancayo","provincia":"Junín","pais":"Perú","latitud":-12.0678202,"longitud":-75.20954069999999,"ranking":15},
{"id":15507,"nombre":"Jauja","localidad":"Jauja","provincia":"Junín","pais":"Perú","latitud":-11.7717116,"longitud":-75.4919501,"ranking":0},
{"id":15508,"nombre":"Huanuco","localidad":"Huanuco","provincia":"Huanuco","pais":"Perú","latitud":-9.935889399999999,"longitud":-76.243398,"ranking":1000},
{"id":15510,"nombre":"Palcamayo","localidad":"Tarma","provincia":"Junín","pais":"Perú","latitud":-11.2959871,"longitud":-75.7726914,"ranking":0},
{"id":15559,"nombre":"Tarma","localidad":"Tarma","provincia":"Junín","pais":"Perú","latitud":-11.420414,"longitud":-75.6932832,"ranking":0},
{"id":15511,"nombre":"Paiján","localidad":"Ascope","provincia":"La Libertad","pais":"Perú","latitud":-7.7319326,"longitud":-79.3029814,"ranking":0},
{"id":15518,"nombre":"Ica","localidad":"Ica","provincia":"Ica","pais":"Perú","latitud":-14.0747821,"longitud":-75.7339009,"ranking":200},
{"id":15519,"nombre":"Marcara","localidad":"Carhuaz","provincia":"Ancash","pais":"Perú","latitud":-9.3227894,"longitud":-77.6040682,"ranking":0},
{"id":15620,"nombre":"Tinco","localidad":"Carhuaz","provincia":"Ancash","pais":"Perú","latitud":-9.2687429,"longitud":-77.6763283,"ranking":1000},
{"id":15524,"nombre":"Tingo Maria","localidad":"Tingo Maria","provincia":"Huanuco","pais":"Perú","latitud":-9.3021865,"longitud":-76.0051457,"ranking":20},
{"id":5413,"nombre":"Desaguadero","localidad":"Desaguadero ","provincia":"Puno","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15535,"nombre":"Yurimaguas","localidad":"Yurimaguas","provincia":"Loreto","pais":"Perú","latitud":-5.8987494,"longitud":-76.1112773,"ranking":100},
{"id":15537,"nombre":"Bagua Grande","localidad":"Utcubamba","provincia":"Amazonas","pais":"Perú","latitud":-5.7517865,"longitud":-78.4474899,"ranking":0},
{"id":15538,"nombre":"Chachapoyas","localidad":"Chachapoyas","provincia":"Amazonas","pais":"Perú","latitud":-6.2309866,"longitud":-77.8629247,"ranking":0},
{"id":15539,"nombre":"Moyobamba","localidad":"Moyobamba","provincia":"San Martin","pais":"Perú","latitud":-6.045147099999999,"longitud":-76.97033689999999,"ranking":0},
{"id":15540,"nombre":"Pedro Ruiz","localidad":"Bongará","provincia":"Amazonas","pais":"Perú","latitud":-5.9434971,"longitud":-77.9785034,"ranking":0},
{"id":15541,"nombre":"Rioja","localidad":"Rioja","provincia":"San Martin","pais":"Perú","latitud":-6.059927,"longitud":-77.168212,"ranking":0},
{"id":15542,"nombre":"San Ignacio","localidad":"San Ignacio","provincia":"Cajamarca","pais":"Perú","latitud":-5.1460594,"longitud":-79.0003013,"ranking":0},
{"id":15543,"nombre":"Talara","localidad":"Talara","provincia":"Piura","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15549,"nombre":"Lozada","localidad":"Lozada","provincia":"Córdoba","pais":"ARGENTINA","latitud":-31.6490206,"longitud":-64.0877098,"ranking":15},
{"id":15550,"nombre":"Aguaytia","localidad":"Padre Abad","provincia":"Ucayali","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15551,"nombre":"Neshuya ","localidad":"Padre Abad","provincia":"Ucayali","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15553,"nombre":"La Merced","localidad":"La Merced","provincia":"Junín","pais":"Perú","latitud":-11.0534855,"longitud":-75.32340769999999,"ranking":0},
{"id":15554,"nombre":"Chupaca","localidad":"Chupaca","provincia":"Junín","pais":"Perú","latitud":-12.06174,"longitud":-75.28800919999999,"ranking":0},
{"id":15555,"nombre":"Concepcion","localidad":"Concepcion","provincia":"Junín","pais":"Perú","latitud":-11.9222137,"longitud":-75.31889149999999,"ranking":0},
{"id":15556,"nombre":"Oroya","localidad":"Oroya","provincia":"Junín","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15557,"nombre":"San Jeronimo de Tunan","localidad":"San Jeronimo de Tunan","provincia":"Junín","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15558,"nombre":"San Ramon","localidad":"San Ramon","provincia":"Junín","pais":"Perú","latitud":-11.1239257,"longitud":-75.3560437,"ranking":0},
{"id":15560,"nombre":"Huaycan","localidad":"Huaycan","provincia":"Departamento Lima - Perú","pais":"Perú","latitud":-11.9993936,"longitud":-76.8378807,"ranking":0},
{"id":15561,"nombre":"Ollantaytambo","localidad":"Urubamba","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15562,"nombre":"Urubamba","localidad":"Urubamba","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15566,"nombre":"Yucay","localidad":"Urubamba","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15567,"nombre":"Pisac","localidad":"Pisac","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15568,"nombre":"Campo Verde","localidad":"Coronel Portillo","provincia":"Ucayali","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15574,"nombre":"Estancia Pichi Ciego","localidad":"Pichi Ciego","provincia":"Mendoza","pais":"ARGENTINA","latitud":-33.6226033,"longitud":-68.0659573,"ranking":0},
{"id":15575,"nombre":"Nacunan","localidad":"Nacunan","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.0451736,"longitud":-67.9561772,"ranking":0},
{"id":15576,"nombre":"Aristides Villanueva","localidad":"Aristides Villanueva","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.2677407,"longitud":-67.8986585,"ranking":0},
{"id":15577,"nombre":"Guadales","localidad":"Guadales","provincia":"Mendoza","pais":"ARGENTINA","latitud":-34.4808421,"longitud":-67.8720239,"ranking":0},
{"id":15595,"nombre":"Andahuaylas","localidad":"Andahuaylas","provincia":"Apurímac","pais":"Perú","latitud":-13.6588733,"longitud":-73.3911948,"ranking":1000},
{"id":15664,"nombre":"Chicmo","localidad":"Andahuaylas","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15668,"nombre":"Pampachiri","localidad":"Andahuaylas","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15674,"nombre":"Talavera","localidad":"Andahuaylas","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15596,"nombre":"Sicuani","localidad":"Canchis","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":4100,"nombre":"Santa Barbara","localidad":"Santa Barbara","provincia":"Corrientes","pais":"ARGENTINA","latitud":-28.45571752438358,"longitud":-56.12566685341986,"ranking":0},
{"id":15601,"nombre":"Tocache","localidad":"Tocache","provincia":"San Martin","pais":"Perú","latitud":-6.5015653,"longitud":-76.364541,"ranking":0},
{"id":15602,"nombre":"Bellavista","localidad":"Bellavista","provincia":"San Martin","pais":"Perú","latitud":-7.0580124,"longitud":-76.5906585,"ranking":0},
{"id":15606,"nombre":"Campanilla","localidad":"Campanilla","provincia":"San Martin","pais":"Perú","latitud":-7.483052100000001,"longitud":-76.6495655,"ranking":0},
{"id":15603,"nombre":"Picota","localidad":"Picota","provincia":"San Martin","pais":"Perú","latitud":-6.920206299999999,"longitud":-76.33300849999999,"ranking":0},
{"id":15604,"nombre":"Sacanche","localidad":"Sacanche","provincia":"San Martin","pais":"Perú","latitud":-7.0699612,"longitud":-76.71356229999999,"ranking":0},
{"id":15605,"nombre":"San Hilarion","localidad":"San Hilarion","provincia":"San Martin","pais":"Perú","latitud":-6.9990671,"longitud":-76.4425047,"ranking":0},
{"id":15536,"nombre":"Juanjui","localidad":"Mariscal Cáceres","provincia":"San Martin","pais":"Perú","latitud":-7.177126199999999,"longitud":-76.7371309,"ranking":100},
{"id":15609,"nombre":"Abancay","localidad":"Abancay ","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15665,"nombre":"Curahuasi","localidad":"Abancay ","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15610,"nombre":"Ilo","localidad":"Ilo","provincia":"Moquegua","pais":"Perú","latitud":-13.6394754,"longitud":-72.88532649999999,"ranking":0},
{"id":15614,"nombre":"Catac","localidad":"Recuay","provincia":"Ancash","pais":"Perú","latitud":-9.8085668,"longitud":-77.4275979,"ranking":1000},
{"id":15617,"nombre":"Recuay","localidad":"Recuay","provincia":"Ancash","pais":"Perú","latitud":-9.7237043,"longitud":-77.4573463,"ranking":1000},
{"id":15619,"nombre":"Ticapampa","localidad":"Recuay","provincia":"Ancash","pais":"Perú","latitud":-9.7588536,"longitud":-77.44368589999999,"ranking":1000},
{"id":15615,"nombre":"Mancos","localidad":"Yungay","provincia":"Ancash","pais":"Perú","latitud":-9.1912509,"longitud":-77.7133326,"ranking":1000},
{"id":15616,"nombre":"Ranrahirca","localidad":"Yungay","provincia":"Ancash","pais":"Perú","latitud":-9.174306099999999,"longitud":-77.7222604,"ranking":1000},
{"id":15621,"nombre":"Tingua","localidad":"Yungay","provincia":"Ancash","pais":"Perú","latitud":-9.224075,"longitud":-77.68637939999999,"ranking":1000},
{"id":15622,"nombre":"Yungay","localidad":"Yungay","provincia":"Ancash","pais":"Perú","latitud":-9.1398448,"longitud":-77.7448627,"ranking":1000},
{"id":15485,"nombre":"Caraz","localidad":"Huaylas","provincia":"Ancash","pais":"Perú","latitud":-9.525228199999999,"longitud":-77.5278328,"ranking":0},
{"id":15506,"nombre":"Huanta","localidad":"Huanta","provincia":"Ayacucho","pais":"Perú","latitud":-12.9452613,"longitud":-74.2452606,"ranking":1000},
{"id":15398,"nombre":"Chiclayo","localidad":"Chiclayo","provincia":"Lambayeque","pais":"Perú","latitud":-6.77891657187859,"longitud":-79.83531372557484,"ranking":40},
{"id":15624,"nombre":"Colalao del Valle","localidad":"Colalao del Valle","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.3629386,"longitud":-65.9575108,"ranking":0},
{"id":15625,"nombre":"Tolombon","localidad":"Tolombon","provincia":"Salta","pais":"ARGENTINA","latitud":-26.1826712,"longitud":-65.93980859999999,"ranking":0},
{"id":15626,"nombre":"Santa Lucia","localidad":"Santa Lucia","provincia":"Tucumán","pais":"ARGENTINA","latitud":-27.0985854,"longitud":-65.53703039999999,"ranking":0},
{"id":15627,"nombre":"San Jose","localidad":"San Jose","provincia":"Catamarca","pais":"ARGENTINA","latitud":-26.8022022,"longitud":-66.0677518,"ranking":0},
{"id":15629,"nombre":"Fuerte Quemado","localidad":"Fuerte Quemado","provincia":"Catamarca","pais":"ARGENTINA","latitud":-26.6266661,"longitud":-66.0484572,"ranking":0},
{"id":15631,"nombre":"Las Carreras","localidad":"Las Carreras","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.9309446,"longitud":-65.7754373,"ranking":0},
{"id":15630,"nombre":"Ovejeria","localidad":"Ovejeria","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.8636577,"longitud":-65.74121389999999,"ranking":0},
{"id":15628,"nombre":"La Angostura","localidad":"La Angostura","provincia":"Tucumán","pais":"ARGENTINA","latitud":-26.9452045,"longitud":-65.6687905,"ranking":0},
{"id":15637,"nombre":"Mollendo","localidad":"Islay","provincia":"Arequipa","pais":"Perú","latitud":-17.0131171,"longitud":-72.0223768,"ranking":0},
{"id":15646,"nombre":"Hornocal","localidad":"Hornocal","provincia":"Jujuy","pais":"ARGENTINA","latitud":null,"longitud":null,"ranking":0},
{"id":15652,"nombre":"Yunguyo","localidad":"Yunguyo","provincia":"Puno","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15653,"nombre":"Chivay","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6362339,"longitud":-71.6021001,"ranking":0},
{"id":15654,"nombre":"Yanque","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6495285,"longitud":-71.6588975,"ranking":0},
{"id":15655,"nombre":"Achoma","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6620946,"longitud":-71.7001037,"ranking":0},
{"id":15656,"nombre":"Maca","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6407985,"longitud":-71.768732,"ranking":0},
{"id":15657,"nombre":"Pinchollo","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6151743,"longitud":-71.83909489999999,"ranking":0},
{"id":15658,"nombre":"Canon del Colca","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6123701,"longitud":-71.9069689,"ranking":0},
{"id":15659,"nombre":"Cabanaconde","localidad":"Caylloma","provincia":"Arequipa","pais":"Perú","latitud":-15.6221689,"longitud":-71.98066229999999,"ranking":0},
{"id":15669,"nombre":"Puquio","localidad":"Lucanas","provincia":"Ayacucho","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15662,"nombre":"Callebamba","localidad":"Chincheros","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15663,"nombre":"Chincheros","localidad":"Chincheros","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15670,"nombre":"Uripa","localidad":"Chincheros","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15661,"nombre":"Ahuayro","localidad":"Chincheros","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15667,"nombre":"Ocros","localidad":"Ocros","provincia":"Ancash","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15671,"nombre":"Chalhuanca","localidad":"Aymaraes","provincia":"Apurímac","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15672,"nombre":"Urcos","localidad":"Quispicanchi","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0},
{"id":15673,"nombre":"Quillabamba","localidad":"La Convencion","provincia":"Cuzco","pais":"Perú","latitud":null,"longitud":null,"ranking":0}];

export default data;