import * as React from "react";

const Error = () => {
  return (
    <>
        Ups, parece que esa ruta es incorrecta... — <strong>404</strong>
    </>
  );
}; 

export default Error;
