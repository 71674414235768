import React from 'react'

const BusRowEmpty = () => {
  return (
    <ul>
        <li className="bus-vacio"></li>
        <li className="bus-vacio"></li>
        <li className="bus-vacio"></li>
        <li className="bus-vacio"></li>
    </ul>
  )
}

export default BusRowEmpty