import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SearchContext } from '../context/SearchContext'

const ListItem = ({servicio,tramo}) => {

    const {comprar, fancyDate} = useContext(SearchContext);

    const getHoursDiff = (a,b) => {
        return Math.abs( (a-b) / 1000 /60 /60 );
    }

    return (
            <div className="list-item box_general wow fadeIn">
                <div className="row">
                    <div className="col-lg-2 col-md-12 item-company-name">
                        <h6>{servicio.transportista.nombre}</h6>
                    </div>
                    <div className="col-lg col-6 item-data">
                        <p>{fancyDate(servicio.fechaHoraSalida)}</p>
                    </div>
                    <div className="col-lg col-6 item-data">
                        <p>{ fancyDate(servicio.fechaHoraLlegada)}</p>
                    </div>
                    <div className="col-lg col-6 item-data">
                        <p>{servicio.clase}</p>
                    </div>
                    <div className="col-lg col-6 item-data">
                        <small>
                            Duracion: {Math.floor(getHoursDiff( new Date(servicio.fechaHoraLlegada), new Date(servicio.fechaHoraSalida)))} hs.<br />
                            Butacas: {servicio.butacas}<br/>
                            {/* <a href="#0" data-bs-toggle="modal" data-bs-target="#routeModal">Recorrido</a> */}
                        </small>
                    </div>
                    <div className="col-lg-2 col-md-12 item-price">
                        <div>
                            <h6><small>$</small>{servicio.precio + servicio.serviceCharge}</h6>
                            <small>Por Persona</small><br />
                            {/* <Link to={"/taquilla/" + servicio.codigoServicio + "/taquilla/"} className="btn_1 small">Comprar</Link>                         */}
                            <button onClick={ () => comprar(servicio,tramo) } className="btn_1 small">Comprar </button>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
							<small><b>Tarifa incluye impuestos y cargo por servicio. </b></small>
				</div>
                <hr className="mb-3" />
            </div>
    )
}

export default ListItem