import React from 'react'

const BusRowXXOX = ({a,b,c,getAvailableClass,tooglePassangerModal,modalClick}) => {
  return (
    <ul>                                                
        <li className={getAvailableClass(a)}><a href="#" {...tooglePassangerModal(a) } onClick={()=> modalClick(a)}>{a}</a></li>
        <li className={getAvailableClass(b)}><a href="#" {...tooglePassangerModal(b) } onClick={()=> modalClick(b)}>{b}</a></li>
        <li className="bus-vacio"></li>
        <li className={getAvailableClass(c)}><a href="#" {...tooglePassangerModal(c) } onClick={()=> modalClick(c)}>{c}</a></li>
    </ul>

  )
}

export default BusRowXXOX