import React, { useContext, useState } from 'react'
import Taquilla from './Taquilla'
import { SearchContext } from '../context/SearchContext'
import { UserContext } from '../context/UserContext';

import context from 'react-bootstrap/esm/AccordionContext';

const ChangePass = (e) => {
    //e.preventDefault();
    const {        
        setStep,         
        loading,setLoading,                
    } = useContext(SearchContext);
    
    const { userName } = useContext(UserContext);

    const [showMsg, setShowMsg] = useState('');

    const [passFormValues,setPassFormValues] = useState({
        OldPass: '',
        Pass1: '',
        Pass2: ''
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPassFormValues({ ...passFormValues, [name]: value });        
    };
    
    const handleChangePassword = async (e) => {       
        
        e.preventDefault();
        if (passFormValues.Pass1 !== '' && passFormValues.Pass2 !== '' &&
            passFormValues.Pass1 === passFormValues.Pass2 ){

                setLoading(true);
                try {
                    const response = await fetch( process.env.REACT_APP_API + `/setcustomerpass/${userName}/${passFormValues.OldPass}/${passFormValues.Pass1}`, {
                        headers: {
                            "Content-type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "X-Requested-With",
                        },
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                          
                            }
                    )});
                    const data = await response.json();    
                    
                    console.log(data);
                    setShowMsg('Su contaseña se ha actualizado');
        
                } catch (error) {
                    setShowMsg('Datos incorrectos');                    
                }
                setLoading(false);
        }
        else{
            setShowMsg('Las contraseñas son requeridas y deben coincidir.');
        }        
    }

    return (
        <>

            <div id="results">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Perfil de usuario</h4>
                        </div>
                    </div>	
                </div>
            </div>            

            <div className="container margin_60">
                
                <div className="row">
                

                    <div className="col-xl-12 col-lg-12 passenger-container">
                        <div className="box_general_3 cart">
                            <div className="form_title mb-4">
                                <h3><strong className="container-icon-trim"><i className="icon-right-2"></i></strong>  
                                    Actualización de contraseña
                                </h3>                                
                            </div>

                            <form onSubmit={handleChangePassword}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Contraseña actual</label>
                                    <input type="password" className="form-control" id="OldPass" name='OldPass' 
                                    placeholder="Password" onChange={handleInputChange}
                                    value={passFormValues.OldPass} />                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Nueva contraseña</label>
                                    <input type="password" className="form-control" id="Pass1" name='Pass1' 
                                    placeholder="Password" min={8} onChange={handleInputChange}
                                    value={passFormValues.Pass1} />                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Repetir contraseña</label>
                                    <input type="password" className="form-control" id="Pass2" name='Pass2' 
                                    placeholder="Password" onChange={handleInputChange}
                                    value={passFormValues.Pass2} />
                                </div>
                                <button type="submit" className="btn btn-primary" >Cambiar contraseña</button>
                            </form>                            

                            <hr />
                            {/* <!--End step --> */}
                            { showMsg &&

                                <div className="alert alert-info text-center" role="alert">
                                <p className="mb-0">
                                    {showMsg}                                    
                                </p>
                            </div>
                            }
                            
                        </div>
                    </div>
                    {/* <!-- /col --> */}
                    
                </div>
                {/* <!-- /row --> */}
                
                <div className="alert alert-warning mt-4" role="alert" style={{display:"none"}}>
                    <div className="row">
                        <div className="col text-center d-flex align-items-center justify-content-center">
                            <i className="icon-warning-empty"></i>
                        </div>
                        <div className="col-11">
                            "Para saber los detalles de PreViaje ingresa al siguiente link" https://previaje.gob.ar/como-funciona .
                            Los requisitos sanitarios de ingreso a cada territorio debido al Covid-19 están determinados por las autoridades competentes, que pueden variar en el tiempo. Es responsabilidad del pasajero corroborar los requerimientos con la autoridad de cada jurisdicción antes del viaje. La ausencia de los requisitos es responsabilidad exclusiva del pasajero.
                        </div>
                    </div>
                </div>
                {/* <!-- /footer warning --> */}
                {/* <!-- /PASSENGER --> */}

                <hr className="separator-passengers" />
   
                

            </div>         
            

            <div className="box_general_3 mt-4">
                
                <div className="row">
   

                </div>
            </div>

        </>    
    )
}

export default ChangePass