import React, { useContext, useState } from 'react'
import data from '../paradas';
import { SearchContext } from '../context/SearchContext';


const SearchBox = (props) => {    
    const {
        origen, setOrigen,
        destino, setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId
    } = useContext(SearchContext);
    
    const [value,setValue] = useState("");
    const [showOptions,setShowOptions] = useState(true);
    
    const handleChange = (event) =>{
        setValue(event.target.value);   
        setShowOptions(true);
    }

    const handleParadaClick = (nombre) => {        
        setShowOptions(false); // una ves seleccionado, no queremos mostrar el combo.
        setValue(nombre);                
        const parada_id= (data.filter((p) => p.nombre === nombre  )[0].id);
        if (props.type=="origen"){
            setOrigen(nombre);
            setOrigenId(parada_id);
        }
        else {
            setDestino(nombre);
            setDestinoId(parada_id);
        }        
    }

    return (
            <>
                <div className="search-inner">
                    <label className={props.labelClass} htmlFor="name_contact">{ props.title}</label>
                    <input type="text" className="form-control" value={value} onChange={ handleChange }  required="required" /> 
                </div>
                <div className="dropdown" style={{position:"fixed"}} >
                        {
                            data.filter((p) => {
                                const search = value.toLocaleLowerCase();
                                const parada = p.nombre.toLocaleLowerCase();
                                const localidad = p.localidad.toLocaleLowerCase();
                                //const provincia = p.provincia.toLocaleLowerCase(); //Se va la performance al tacho.

                                return (                                    
                                    search  //Se busca algo
                                    && showOptions // Queremos mostrar el combo
                                    && (parada.includes(search) || localidad.includes(search) )                                    
                                );
                            })
                            .map((p) => (
                                <div key={p.id} className="dropdown-row" style={{color:"black"}} onClick={ () => handleParadaClick(p.nombre)  }>                                                            
                                    {p.nombre} - {p.localidad} - {p.provincia}
                            </div>                                                      
                        ))}
                </div>            
            </>
    )
}

export default SearchBox