import React from 'react'

const Footer = () => {
  return (
    <>
    <footer>
        <div className="container">
          <div className="row">
              <div className="col-md-12">
              <div id="copy" style={{textAlign:"center"}}>Copyright © 2023 Travelclick</div>
              </div>
          </div>
        </div>
    </footer>
	
    <div id="toTop"></div>
	
	<script src="js/jquery-3.6.1.min.js"></script>
	<script src="js/common_scripts.min.js"></script>
	<script src="js/functions.js"></script>
    </>

  )
}

export default Footer