import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { UserContext } from '../context/UserContext';
import { SearchContext } from '../context/SearchContext';
import { useNavigate } from 'react-router-dom';



const LoginPage = () => {
  const navigate = useNavigate();
  const {userName,setUserName, userLoggedIn,setUserLoggedIn, getUserToken}= useContext(UserContext);
  const {step,setStep,setListaPasajeros} = useContext(SearchContext);

  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');  
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassRecovery, setShowPassRecovery] = useState(false);
  const [email, setEmail] = useState('');
  
  if(userLoggedIn){

    setStep('paso1');
    setListaPasajeros({
      ida: [],
      vuelta: []
    });

    navigate('/');
    
  } 

  const handleSubmitLogin= async (event) => {
    event.preventDefault();
    console.log(`User: ${user} \nPass: XXXX`) 

    let token = await getUserToken(user, pass);

    console.log('token2 ' + token);
    
    if (token !== undefined) {
      setLoading(true); // login successful
      let currentToken = token; //await  getUserToken();
      sessionStorage.setItem('currentToken', currentToken);
      sessionStorage.setItem('tokenTimestamp', Date.now());
      console.log('Login succesful stored. TimeStamp:'+ sessionStorage.getItem('tokenTimestamp')+ ' Token:' + sessionStorage.getItem('currentToken') );      
      setUserLoggedIn(true);
      setUserName(user);
      setLoading(false);
      //window.location = "/";
      setLoginFailed(false);
      navigate('/');
    }
    else{
      console.log('Login failed');
      setLoginFailed(true);
    }
  }; 

  const handleSubmitRecovery= async (event) => {
    event.preventDefault();    
      
    if (email !== '' ){
          setLoading(true);
          try {
              const response = await fetch( process.env.REACT_APP_API + `/passrecovery/${email}`, {
                  headers: {
                      "Content-type": "application/json;charset=UTF-8",
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Headers": "X-Requested-With",
                  },
                  method: 'POST',
                  body: JSON.stringify(
                      {
                                    
                      }
              )});
              const data = await response.json();    
              
              console.log(data);
              alert('Email enviado satisfactoriamente');
              //setShowMsg('Su contaseña se ha actualizado');
  
          } catch (error) {
              alert('Su email no está registrado.');
              //setShowMsg('Datos incorrectos');                    
          }
          setLoading(false);
      }
      else{
          //setShowMsg('Las contraseñas son requeridas y deben coincidir.');
      }        
  }; 

  
  return (
    <div >

      <div className="layer"></div>
      { loading &&
        <div id="preloader">
          <div data-loader="circle-side"></div>
        </div>        
        }
          
        <Header />
                
        <main>
          <div id="hero_login">
            <div className="container margin_60_35">
              <div id="login">
                <h1>Por favor ingrese a la Plataforma</h1>
                <div className="box_form">
                  { !showPassRecovery && 
                    <form onSubmit={handleSubmitLogin}>
                    <div className="form-group">
                      <input type="text" className="form-control" 
                      placeholder="Usuario" id="user" name="user"
                      onChange={(event) => {setUser(event.target.value)} }  />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" 
                      placeholder="Contraseña" name="password" id="password"
                      onChange={(event)=> {setPass(event.target.value) }}
                      />
                    </div>
                      <a href="#0" onClick={() => setShowPassRecovery(true)}>
                        <small>¿Olvidó su contraseña?</small>
                        </a>
                    {loginFailed && 
                    <p className="alert alert-danger" >
                      Login incorrecto
                    </p>
                    }

                    <div className="form-group text-center add_top_20">
                      <input className="btn_1 medium" type="submit" value="Ingresar"/>
                    </div>
                  </form>
                  }

                  { showPassRecovery && 
                    <form onSubmit={handleSubmitRecovery}>
                      <div className="form-group">
                        <input type="email" required="required" className="form-control" 
                        placeholder="Email" id="email" name="email"
                        onChange={(event) => {setEmail(event.target.value)} }  />
                      </div>
                      <a href="#0" onClick={() => setShowPassRecovery(false)}>
                        <small>Volver al login.</small>
                      </a>                      

                      <div className="form-group text-center add_top_20">
                        <input className="btn_1 medium" type="submit" value="Enviar email"/>
                      </div>
                    </form>
                  }

                </div>
                <p className="text-center link_bright">
                  ¿Tiene problemas para ingresar o quiere registrarse? <br />
                  <a href="http://travelclick.com.ar/consultas/"><strong>Contáctenos</strong></a></p>
              </div>
              
            </div>
          </div>
        </main>
        
        
        <Footer />
        
    </div>
  );
}

export default LoginPage;
